import React, { ReactNode } from 'react'
import SkeletonForm from './SkeletonLoader/SkeletonForm';
import SkeletonTable from './SkeletonLoader/SkeletonTable';
import SkeletonGrid from './SkeletonLoader/SkeletonGrid';
import SkeletonForm2 from './SkeletonLoader/SkeletonForm2';
import SkeletonForm3 from './SkeletonLoader/SkeletonForm3';
// import Placeholder from 'react-bootstrap/Placeholder';
// import Alert from 'react-bootstrap/Alert';
// import { Placeholder, Message } from 'rsuite'
// import loaderImg from "./loader.gif";
// import Test from '../pages/Test/Test';

interface LoadingProps {
  loading: boolean;
  error?: boolean;
  children: ReactNode;
  SkeletonType: 'Form' | 'Table' | 'Grid' |'Form2' | 'Form3'
}

const Loading : React.FC<LoadingProps> = ({loading ,error , children , SkeletonType})=> {
  // const cloneButton = React.cloneElement( children , {disabled:true} , "Loading..." );
  // const elementType= children?.type?.render?.displayName ;

  const renderHandler = ()=>{
    return (
      <>
      {loading ? (
        <>
        {
          SkeletonType === 'Form' ? <SkeletonForm/> :
          SkeletonType === 'Form2' ? <SkeletonForm2/> :
          SkeletonType === 'Form3' ? <SkeletonForm3/> :
          SkeletonType === 'Table' ? <SkeletonTable/> :
          SkeletonType === 'Grid' ? <SkeletonGrid/> : "Loading..."
        }
        </>
            
      ) : error ? (

        <p>
          {error}
        </p>


      ) : (
      children
      )}
      </>
    );
    
    
  }

  return renderHandler();
}

export default Loading
