import React, { memo } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import ConfirmDeleteDialog from '../../../components/Alert/ConfirmDeleteDialog';
import { DeleteAprovalMember, GetApprovalMemberByGroupId } from '../../../store/Ame/AprovalMemberSlice';
import CustomAlert from '../../../components/Alert/CustomAlert.tsx';


const ListApprovalMember = ({data}) => {

  const { t, i18n } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();

      // Handele Delete Member
  const handleDeleteMember = (item)=>{
      dispatch(DeleteAprovalMember(item.id)).unwrap()
    .then(() => {
        dispatch(GetApprovalMemberByGroupId(id))
        CustomAlert({ action: "Delete" });
    })
    .catch((error) => {
      console.log(error);
      CustomAlert({ action: "Error" });
    });
    }

  return (
    <table className="table">
              <thead className="table-dark">
                <tr>
                  <th scope="col">{t('Member')}</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {data.map((item, idx) => (
                  <tr key={item.id}>
                    <td className="align-middle">
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </td>
                    <td className="align-middle">
                      <ConfirmDeleteDialog
                           onConfirm={()=>handleDeleteMember(item)}
                            title="Are you sure?"
                            message="This action cannot be undone."
                        />

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
  )
}

export default memo(ListApprovalMember)