import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import   ConfirmDeleteDialog  from '../../../../components/Alert/ConfirmDeleteDialog';
import { DeleteAuthResponsibilityUser, GetResponsibilityUserByUserId } from '../../../../store/NTX/Security/responsibilityUserSlice';

function ResponsibilityUserTableList({ResponsibilityUserList}) {
    
  const { userId } = useParams();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

          // Handele Delete Member
  const handleDelete = (item)=>{
    dispatch(DeleteAuthResponsibilityUser(item.id)).unwrap()
  .then(() => {
    dispatch(GetResponsibilityUserByUserId(userId))
    
  })
  .catch((error) => {
    console.log(error);
    alert('Error')
  });
  }

  return (
<>
<table className="table">
              <thead className="table-dark">
                <tr>
                  {/* <th scope="col">الرقم</th> */}
                  <th scope="col">{t('Responsibility')}</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>

                { 
                ResponsibilityUserList?.table.map((item, idx) => (
                  <tr key={item.id}>
                    {/* <td className="align-middle">{++idx}</td> */}
                    <td className="align-middle">
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </td>
                    <td className="align-middle">
                      {/* <button onClick={()=> handleDelete(item)} type="button" className="btn btn-outline-danger">
                        حذف
                      </button> */}
                      <ConfirmDeleteDialog
                           onConfirm={()=>handleDelete(item)}
                            title="Are you sure?"
                            message="This action cannot be undone."
                        />

                        
                      


                    </td>
                  </tr>
                ))
                }
                
              </tbody>
            </table>
</>
  )
}

export default ResponsibilityUserTableList
