import React, { useMemo, useRef, useState } from "react";
import { memo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardGroup } from "../../../../components/card/card";
import Loading from "../../../../components/Loading";
import { getLocations } from "../../../../store/HR/HRSetting/locationSlice";
import Loader from "../../../../components/Loader/Loader.js";
import useGetLocations from "../../../../hooks/HRSetting/use-get-locations";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import AddLocation from "./AddLocation";

const Location = () => {
  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  //// Get Locations from redux
  const dispatch = useDispatch();

  const Navigate = useNavigate()

  const { Locations, loading, error } = useGetLocations();

  // useEffect(() => {
  //   if (!Locations) {
  //     dispatch(getLocations());
  //   }
  // }, [dispatch, Locations]);

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active">{t("Locations")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Locations")}</h1>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error} SkeletonType="Grid">
          <CardBody>
            {/* <Loader loading={loading} /> */}
            <div className="tab-content p-4">
              <div className="tab-pane fade show active">
                <div className="row">
                  <div className="col-7 col-md-6 d-flex justify-content-start">
                    <div className="input-group mb-4">
                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: "10px" }}
                          >
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            //   onChange={handleSearch}
                            placeholder={t("Search") + " ..."}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5 col-md-6 text-end">
                    <a
                      href="#/"
                      className="btn btn-outline-theme"
                      data-bs-toggle="modal"
                      data-bs-target="#modalAddLocation"
                      onClick={() => setShow(true)}
                    >
                      <i className="fa fa-plus-circle fa-fw me-1"></i>
                      {t("Add Location")}
                    </a>
                  </div>
                </div>

                {/* //  START TABLE   */}

                <div className="mb-5">
                  <div className="row">
                    {Locations.map((item) => (
                      <div className="col-xl-4" key={item.id}>
                        <Card className="m-2">
                          <div className="m-1 bg-white bg-opacity-15">
                            <iframe
                              title="Youtube"
                              src={`http://www.google.com/maps/embed/v1/place?q=${item.latitude},${item.longitude}&key=AIzaSyCG3Kt_jO8o7x67EsdV9RuTJP5IxSEwmeo`}
                              style={{
                                border: 0,
                                width: "100%",
                                height: "10rem",
                              }}
                              allowFullScreen
                            ></iframe>
                            <div className="list-group list-group-flush">
                              <div className="list-group-item d-flex">
                                <div className="w-30px h-40px d-flex align-items-center justify-content-center">
                                  <i className="fas fa-lg fa-fw me-2 fa-location-arrow text-theme fs-1"></i>
                                  {/* <i className="fa-lg me-2 bi bi-buildings-fill text-theme fs-1 "></i> */}
                                </div>
                                <div className="flex-fill px-3">
                                  <div className="fw-bold">
                                    {i18n.language === "ar"
                                      ? item.name2
                                      : item.name}
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-outline-theme me-1"
                                  data-toggle="tooltip"
                                  title="تعديل بيانات الموقع"
                                  onClick={() =>
                                    Navigate(`${item.id}/Edit`)
                                  }
                                >
                                  <i className="fas fa-edit fa-lg"></i>
                                </button>
                                {/* <div className="text-nowrap">
                                  <div className="dropdown dropdown-icon ms-auto">
                                    <a
                                      href="#/"
                                      className="text-white"
                                      data-bs-toggle="dropdown"
                                    >
                                      <i className="fa fa-ellipsis-h"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <Link
                                        to={`${item.id}/Edit`} //  HRsettings/Location/:id/Edit
                                        className="dropdown-item"
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#modalEdit"
                                        // onClick={()=> Navigate(`${item.id}/Edit`)}
                                      >
                                        تعديل
                                      </Link>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Loading>
      </Card>

      <ModalComponent title={t("Add Location")} id="modalAddLocation">
        {Show && <AddLocation />}
      </ModalComponent>
    </>
  );
};

export default memo(Location);
