/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { AppSettings } from "../../../../config/app-settings.js";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody } from "../../../../components/card/card.jsx";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import {
  AddContractLease,
  GetContractLease,
} from "../../../../store/SU/contractLeaseSlice.js";
import useGetOrganizations from "../../../../hooks/HR/use-get-organizations.js";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import { AddContractSchema } from "../../../ValidationForm/validationSchema.js";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import Installments from "../Installments/Installments.js";
import RealEstateListContract from "../RealEstateListContract/RealEstateListContract.js";
import AttachmentList from "../Attachment/AttachmentList.js";
import Swal from "sweetalert2";
import Attachment from "../../../Attachments/Attachment.jsx";

const AddContracts = () => {
  const { Organizations } = useGetOrganizations();

  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [installments, setInstallments] = useState([]);
  const [tableDataRealEstate, setTableDataRealEstate] = useState([]);
  const [attachments, setAttachments] = useState([]);

  // Get Total Installments and tableDataRealEstate
  const TotalInstallments = installments
    ?.filter((f) => f.isInstallment === true)
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.installmentValue,
      0
    );
  const TotalRealEstate = tableDataRealEstate?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.annualRent,
    0
  );
  //____________________________________________

  const formik = useFormik({
    initialValues: {
      organization_id: "",
      contract_status: 0,
      code: "",
      // waterMeter: "",
      contrcat_date: "",
      purpose: "",
      annual_rent: "",
      amount_insurance: "",
      contract_period: "",
      period_from_date: "",
      period_to_date: "",
      payment_type: "",
      // amount_water: 0,
      amount_services: "",
      fees_office: "",
      // electricity_meter: "",
      description: "",
      installment_value: 0,
      represented: "",
      automatic_renewal: true,
      installment_count: "",
      currency_id: "",
      exchange_rate: 0,
      vat_rate: 0,
      vat_amount: "", // نسبة الضريبة
      ejar_code: "",
      //____ غير موجود في الريكويست ولكن لبعض الحسابات الخاصة بالفورم___
      ContractPeriodNumber: "",
      VAT: 15,
      Total: "",
      filterWater: 0,

      Attachment: "",
      IsAddAttachment: false,
    },
    validationSchema: AddContractSchema(t),

    onSubmit: (values) => {
      if (parseFloat(TotalInstallments) > parseFloat(values.Total)) {
        Swal.fire("مجموع الأقساط اكبر من الاجمالي");
        formik.setSubmitting(false);
        return;
      }

      if (parseFloat(TotalRealEstate) !== parseFloat(values.Total)) {
        Swal.fire("مجموع ايجار العقارات لا يساوي مبلغ العقد");
        formik.setSubmitting(false);
        return;
      }

      const formData = new FormData();

      formData.append("ExtraInformationId", "");
      formData.append("OrganizationId", values.organization_id);
      formData.append("ContractStatus", values.contract_status);
      formData.append("Code", values.code.toString());
      formData.append("EjarCode", values.ejar_code.toString());
      formData.append("ContrcatDate", values.contrcat_date);
      formData.append("Purpose", values.purpose);
      formData.append("AnnualRent", values.annual_rent);
      formData.append("AmountInsurance", values.amount_insurance);
      formData.append("ContractPeriod", values.contract_period);
      formData.append("PeriodFromDate", values.period_from_date);
      formData.append("PeriodToDate", values.period_to_date);
      formData.append("PaymentType", values.payment_type);
      formData.append("AmountServices", values.amount_services);
      formData.append("FeesOffice", values.fees_office);
      formData.append("Description", values.description);
      formData.append("InstallmentValue", values.installment_count);
      formData.append("Represented", values.represented);
      // formData.append("BusinessGroupId", null);
      formData.append("AutomaticRenewal", values.automatic_renewal);
      formData.append("InstallmentCount", values.installment_count);
      formData.append("CurrencyId", "");
      formData.append("ExchangeRate", values.exchange_rate);
      formData.append("VatRate", values.vat_rate);
      formData.append("VatAmount", values.vat_amount);

      formData.append("IsAddAttachment", attachments?.length > 0);

      for (let i = 0; i < attachments?.length; i++) {
        formData.append("Attachment", attachments[i]);
      }

      // attachments.forEach((item, index) => {
      //   formData.append(`Attachments[${index}].Attachment`, item.attachment[0]);
      //   formData.append(`Attachments[${index}].Description`, item.description);
      // });

      installments.forEach((item, index) => {
        formData.append(
          `ContractInstallmentList[${index}].contractLeaseId`,
          ""
        );
        formData.append(
          `ContractInstallmentList[${index}].installmentNo`,
          item.installmentNo
        );
        formData.append(
          `ContractInstallmentList[${index}].installmentDate`,
          item.installmentDate
        );
        formData.append(
          `ContractInstallmentList[${index}].installmentValue`,
          item.installmentValue
        );
        formData.append(`ContractInstallmentList[${index}].collectingId`, "");
        formData.append(
          `ContractInstallmentList[${index}].installmentDateForm`,
          item.installmentDateForm
        );
        formData.append(
          `ContractInstallmentList[${index}].installmentDateTo`,
          item.installmentDateTo
        );
        formData.append(
          `ContractInstallmentList[${index}].description`,
          item.description || ""
        );
        formData.append(
          `ContractInstallmentList[${index}].contractInstallmentType`,
          item.contractInstallmentType === "تأمين"
            ? 4
            : item.contractInstallmentType === "خدمات"
            ? 2
            : item.contractInstallmentType === "سعي المكتب"
            ? 3
            : 1
        );
        formData.append(
          `ContractInstallmentList[${index}].subTotal`,
          item.installmentValue + item.vatAmount
        );
        formData.append(
          `ContractInstallmentList[${index}].vatRate`,
          item.vatRate
        );
        formData.append(
          `ContractInstallmentList[${index}].vatAmount`,
          item.vatAmount
        );
      });

      // Add contractLeaseRealestateList
      tableDataRealEstate.forEach((item, index) => {
        formData.append(
          `ContractLeaseRealestateList[${index}].contractLeaseId`,
          ""
        );
        formData.append(
          `ContractLeaseRealestateList[${index}].realEstateId`,
          item.realEstateId
        );
        formData.append(
          `ContractLeaseRealestateList[${index}].annualRent`,
          item.annualRent
        );
        formData.append(
          `ContractLeaseRealestateList[${index}].electricityMeter`,
          item.electricityMeter
        );
        formData.append(
          `ContractLeaseRealestateList[${index}].waterMeter`,
          item.waterMeter
        );
      });

      //____________________________________________
      dispatch(AddContractLease(formData))
        .unwrap()
        .then((res) => {
          console.log(res);
          if (res.succeeded === true) {
            CustomAlert({ action: "Add", msg: "تم إضافة العقد بنجاج" });
            formik.setSubmitting(false);
            Navigate(-1, { replace: true });
            dispatch(GetContractLease());
            return;
          }
          CustomAlert({ action: "Error", msg: res.message });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  //______________________________________________________________________________________________________

  useEffect(() => {
    const startDate = new Date(formik.values.period_from_date);
    const contractPeriod = parseInt(formik.values.contract_period);
    const contractPeriodNumber = parseInt(formik.values.ContractPeriodNumber);

    if (
      !isNaN(startDate.getTime()) &&
      !isNaN(contractPeriod) &&
      !isNaN(contractPeriodNumber)
    ) {
      // Calculate the End Date based on the Contract Period and ContractPeriodNumber
      const endDate = new Date(startDate);

      // eslint-disable-next-line default-case
      switch (contractPeriod) {
        case 1: // Year
          endDate.setFullYear(startDate.getFullYear() + contractPeriodNumber);
          break;
        case 2: // Month
          endDate.setMonth(startDate.getMonth() + contractPeriodNumber);
          break;
        case 3: // Week
          endDate.setDate(startDate.getDate() + contractPeriodNumber * 7);
          break;
        case 4: // Day
          endDate.setDate(startDate.getDate() + contractPeriodNumber);
          break;
      }

      // Decrease one day from the End Date
      endDate.setDate(endDate.getDate() - 1);

      // Format the End Date as yyyy-mm-dd
      const formattedEndDate = endDate.toISOString().split("T")[0];

      // Update the End Date field in the formik values
      formik.setFieldValue("period_to_date", formattedEndDate);
    } else {
      // If any of the inputs are invalid, clear the End Date field
      formik.setFieldValue("period_to_date", "");
    }
  }, [
    formik.values.period_from_date,
    formik.values.contract_period,
    formik.values.ContractPeriodNumber,
  ]);

  //_________________
  const handleAmountChange = (e) => {
    const amount = parseFloat(e.target.value);
    const vatPercent = parseFloat(formik.values.VAT);

    if (!isNaN(amount) && !isNaN(vatPercent)) {
      const vatAmount = (amount * vatPercent) / 100;
      const total = amount + vatAmount;

      formik.setFieldValue("vat_amount", vatAmount.toFixed(2));
      formik.setFieldValue("Total", total.toFixed(2));
    } else {
      // Handle invalid input if needed
      formik.setFieldValue("vat_amount", "");
      formik.setFieldValue("Total", "");
    }

    formik.handleChange(e);
  };

  //___________حساب عدد الأقساط ________________
  const calculateInstallmentCount = () => {
    let contractPeriodNumber = parseInt(formik.values.ContractPeriodNumber, 10);
    if (isNaN(contractPeriodNumber)) {
      contractPeriodNumber = 1; // Default to 1 if ContractPeriodNumber is not a valid number.
    }

    let contractPeriodInMonths = 1; // Default to 1 month

    if (formik.values.contract_period === "1") {
      // If contract_period is '1' (Year)
      contractPeriodInMonths = 12 * contractPeriodNumber;
    } else if (formik.values.contract_period === "2") {
      // If contract_period is '2' (Month)
      contractPeriodInMonths = contractPeriodNumber;
    } else if (formik.values.contract_period === "3") {
      // If contract_period is '3' (Week)
      contractPeriodInMonths = contractPeriodNumber / 4.33; // Approximate number of weeks in a month
    } else if (formik.values.contract_period === "4") {
      // If contract_period is '4' (Day)
      contractPeriodInMonths = contractPeriodNumber / 30.44; // Approximate number of days in a month
    }

    const paymentType = parseInt(formik.values.payment_type, 10);

    if (!isNaN(paymentType) && paymentType > 0) {
      let installmentCount = 0;

      switch (paymentType) {
        case 1:
          installmentCount = contractPeriodInMonths;
          break;
        case 2:
          installmentCount = contractPeriodInMonths / 2;
          break;
        case 3:
          installmentCount = contractPeriodInMonths / 3;
          break;
        case 4:
          installmentCount = contractPeriodInMonths / 4;
          break;
        case 6:
          installmentCount = contractPeriodInMonths / 6;
          break;
        case 12:
          installmentCount = contractPeriodInMonths / 12;
          break;
        default:
          break;
      }

      formik.setFieldValue("installment_count", installmentCount);
    }
  };
  useEffect(() => {
    calculateInstallmentCount();
  }, [
    formik.values.contract_period,
    formik.values.ContractPeriodNumber,
    formik.values.payment_type,
    formik.values.annual_rent,
  ]);
  // Calculate installment_value whenever Total or installment_count changes
  // _________________ حساي قيمة كل قسط
  useEffect(() => {
    if (formik.values.Total && formik.values.installment_count) {
      const installmentValue =
        formik.values.Total / formik.values.installment_count;
      formik.setFieldValue("installment_value", installmentValue);
    }
  }, [
    formik.values.Total,
    formik.values.installment_count,
    formik.values.annual_rent,
  ]);

  //____________ توليد الأقساط Generation Installment ____________________

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Note: Months are zero-based, so we add 1
    const year = date.getFullYear();

    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  };

  const handleclick = (e) => {
    const contractStartDate = new Date(formik.values.period_from_date);
    const contractEndDate = new Date(formik.values.period_to_date);
    const countOfInstallment = formik.values.installment_count;
    const totalContractValue = formik.values.Total;
    const vatRate = formik.values.VAT; // Assuming 15% VAT, change this as needed
    const insuranceValue = formik.values.amount_insurance; // Fixed insurance premium value
    const servicesValue = formik.values.amount_services; // Fixed premium services value
    const AmountWater = formik.values.amount_water; //
    const Fees_office = formik.values.fees_office; //
    const installmentValue = Math.ceil(formik.values.installment_value);
    const contractDuration = contractEndDate - contractStartDate;
    const installmentDuration = contractDuration / countOfInstallment;
    const VATValue = (installmentValue * vatRate) / 100;

    const installmentsArray = [];

    // Fixed installments
    installmentsArray.push({
      installmentNo: 1,
      installmentDate: formatDate(contractStartDate),
      installmentValue: insuranceValue,
      vatRate: vatRate,
      vatAmount: (insuranceValue * vatRate) / 100,
      contractInstallmentType: "تأمين",
      installmentDateForm: formatDate(contractStartDate),
      installmentDateTo: formatDate(contractStartDate),
      description: ".",
      isInstallment: false,
    });

    // debugger
    installmentsArray.push({
      installmentNo: 1,
      installmentDate: formatDate(contractStartDate),
      installmentValue: servicesValue,
      vatRate: vatRate,
      vatAmount: (servicesValue * vatRate) / 100,
      contractInstallmentType: "خدمات",
      installmentDateForm: formatDate(contractStartDate),
      installmentDateTo: formatDate(contractStartDate),
      description: ".",
      isInstallment: false,
    });

    installmentsArray.push({
      installmentNo: 1,
      installmentDate: formatDate(contractStartDate),
      installmentValue: Fees_office,
      vatRate: vatRate,
      vatAmount: (servicesValue * vatRate) / 100,
      contractInstallmentType: "سعي المكتب",
      installmentDateForm: formatDate(contractStartDate),
      installmentDateTo: formatDate(contractStartDate),
      description: ".",
      isInstallment: false,
    });

    //  debugger

    let currentDate = new Date(contractStartDate);

    for (let i = 0; i < countOfInstallment; i++) {
      let value = installmentValue;
      if (i === countOfInstallment - 1) {
        const totallist = installmentsArray
          ?.filter((f) => f.isInstallment === true)
          ?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.installmentValue,
            0
          );
        value = formik.values.Total - totallist;
      }
      // debugger
      const installment = {
        installmentNo: i + 1,
        installmentDate: formatDate(currentDate),
        installmentValue: value,
        vatRate: vatRate,
        vatAmount: VATValue,
        contractInstallmentType: "قسط عادي",
        installmentDateForm: formatDate(currentDate),
        installmentDateTo: formatDate(
          new Date(currentDate.getTime() + installmentDuration)
        ),
        isInstallment: true,
        description: ".",
      };

      installmentsArray.push(installment);

      currentDate = new Date(currentDate.getTime() + installmentDuration);
    }

    setInstallments(installmentsArray);
  };

  var List = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Contracts",
      link: "/SU/Contracts",
    },
    {
      name: "Add_Contract",
      link: null,
    },
  ];

  //_______________

  // const handleImageUpload = (event) => {
  //   formik.setFieldValue("Attachment", event.currentTarget.files[0]);
  //   formik.setFieldValue("IsAddAttachment", true);
  // };

  return (
    <>
      <Breadcrumb BreadcrumbList={List} PageName="Add_Contract" />
      <hr className="mb-3" />

      <CardBody>
        <div className="row">
          {/* <Form onSubmit={formik.handleSubmit}> */}
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="mb-5">
                    <h4>
                      <i className="bi bi-pencil-square fa-lg  text-theme"></i>{" "}
                      البيانات الأساسية
                    </h4>
                    <p> </p>
                    <Card>
                      <CardBody>
                        <div className="row">
                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">رقم العقد</label>
                              <Form.Control
                                className="form-control"
                                type="number"
                                name="code"
                                onChange={formik.handleChange}
                                value={formik.values.code || ""}
                                isInvalid={
                                  formik.touched.code && formik.errors.code
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.code}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">تاريخ العقد</label>
                              <Form.Control
                                className="form-control"
                                type="date"
                                name="contrcat_date"
                                onChange={formik.handleChange}
                                value={formik.values.contrcat_date || ""}
                                isInvalid={
                                  formik.touched.contrcat_date &&
                                  formik.errors.contrcat_date
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.contrcat_date}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">{t('Branch')}</label>
                              <Form.Select
                                className="form-select"
                                name="organization_id"
                                onChange={formik.handleChange}
                                value={formik.values.organization_id}
                                isInvalid={
                                  formik.touched.organization_id &&
                                  formik.errors.organization_id
                                }
                              >
                                <option>
                                  {i18n.language === "ar" ? "إختر" : "Choose"}
                                </option>
                                {Organizations &&
                                  Organizations?.filter(
                                    (f) => f.type === "150"
                                  ).map((item, idx) => (
                                    <option key={++idx} value={item.id}>
                                      {i18n.language === "ar"
                                        ? item.name2
                                        : item.name}
                                    </option>
                                  ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.organization_id}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">حالة العقد</label>
                              <Form.Select
                                className="form-select"
                                name="contract_status"
                                onChange={formik.handleChange}
                                value={formik.values.contract_status}
                                isInvalid={
                                  formik.touched.contract_status &&
                                  formik.errors.contract_status
                                }
                              >
                                <option>
                                  {i18n.language === "ar" ? "إختر" : "Choose"}
                                </option>
                                <option value={1}>ساري</option>
                                <option value={0}>منتهي</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.contract_status}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">
                                رقم العقد في نظام ايجار
                              </label>
                              <Form.Control
                                className="form-control"
                                type="number"
                                name="ejar_code"
                                onChange={formik.handleChange}
                                value={formik.values.ejar_code || ""}
                                isInvalid={
                                  formik.touched.ejar_code &&
                                  formik.errors.ejar_code
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.ejar_code}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">
                                تاريخ بدء التأجير
                              </label>
                              <Form.Control
                                className="form-control"
                                type="date"
                                name="period_from_date"
                                onChange={formik.handleChange}
                                value={formik.values.period_from_date}
                                isInvalid={
                                  formik.touched.period_from_date &&
                                  formik.errors.period_from_date
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.period_from_date}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-2">
                            <div className="form-group mb-3">
                              <label className="form-label">مدة العقد</label>
                              <Form.Select
                                className="form-select"
                                name="contract_period"
                                onChange={formik.handleChange}
                                value={formik.values.contract_period}
                                isInvalid={
                                  formik.touched.contract_period &&
                                  formik.errors.contract_period
                                }
                              >
                                <option>
                                  {i18n.language === "ar" ? "إختر" : "Choose"}
                                </option>
                                <option value="1">
                                  {i18n.language === "ar" ? "سنة" : "Year"}
                                </option>
                                <option value="2">
                                  {i18n.language === "ar" ? "شهر" : "Month"}
                                </option>
                                <option value="3">
                                  {i18n.language === "ar" ? "اسبوع" : "Week"}
                                </option>
                                <option value="4">
                                  {i18n.language === "ar" ? "يوم" : "Day"}
                                </option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.contract_period}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-1">
                            <div className="form-group mb-3">
                              <label className="form-label">.</label>
                              <Form.Control
                                className="form-control"
                                type="text"
                                name="ContractPeriodNumber"
                                onChange={formik.handleChange}
                                value={formik.values.ContractPeriodNumber || ""}
                                isInvalid={
                                  formik.touched.ContractPeriodNumber &&
                                  formik.errors.ContractPeriodNumber
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.ContractPeriodNumber}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">
                                تاريخ نهاية العقد
                              </label>
                              <Form.Control
                                className="form-control"
                                type="date"
                                name="period_to_date"
                                onChange={formik.handleChange}
                                value={formik.values.period_to_date || ""}
                                isInvalid={
                                  formik.touched.period_to_date &&
                                  formik.errors.period_to_date
                                }
                                readOnly
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.period_to_date}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>

                  <div className="mb-5">
                    <h4>
                      <i className="fas fa-lg fa-fw me-2 fa-money-bill-alt text-theme"></i>{" "}
                      البيانات المالية
                    </h4>
                    <p></p>
                    <Card>
                      <CardBody>
                        <div className="row">
                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">مبلغ الإيجار</label>
                              <Form.Control
                                className="form-control"
                                type="number"
                                name="annual_rent"
                                // onChange={formik.handleChange}
                                onChange={handleAmountChange}
                                value={formik.values.annual_rent || ""}
                                isInvalid={
                                  formik.touched.annual_rent &&
                                  formik.errors.annual_rent
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.annual_rent}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">VAT %</label>
                              <Form.Control
                                className="form-control"
                                type="text"
                                name="VAT"
                                // onChange={formik.handleChange}
                                onChange={handleAmountChange}
                                value={formik.values.VAT}
                                readOnly
                                isInvalid={
                                  formik.touched.VAT && formik.errors.VAT
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.VAT}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">مبلغ الضريبة</label>
                              <Form.Control
                                className="form-control"
                                type="text"
                                name="vat_amount"
                                // onChange={formik.handleChange}
                                onChange={handleAmountChange}
                                value={formik.values.vat_amount}
                                readOnly
                                isInvalid={
                                  formik.touched.vat_amount &&
                                  formik.errors.vat_amount
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.vat_amount}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">الإجمالي</label>
                              <Form.Control
                                className="form-control"
                                type="text"
                                name="Total"
                                // onChange={formik.handleChange}
                                onChange={handleAmountChange}
                                value={formik.values.Total}
                                readOnly
                                isInvalid={
                                  formik.touched.Total && formik.errors.Total
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.Total}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">الدفع كلـ</label>
                              <Form.Select
                                className="form-select"
                                name="payment_type"
                                onChange={formik.handleChange}
                                value={formik.values.payment_type}
                                isInvalid={
                                  formik.touched.payment_type &&
                                  formik.errors.payment_type
                                }
                              >
                                <option>
                                  {i18n.language === "ar" ? "إختر" : "Choose"}
                                </option>

                                <option value="1">
                                  {i18n.language === "ar"
                                    ? "كل شهر"
                                    : "Every month"}
                                </option>

                                <option value="2">
                                  {i18n.language === "ar"
                                    ? "كل شهرين"
                                    : "Every two months"}
                                </option>

                                <option value="3">
                                  {i18n.language === "ar"
                                    ? "كل ثلاثة أشهر"
                                    : "Every three months"}
                                </option>

                                <option value="4">
                                  {i18n.language === "ar"
                                    ? "كل أربعة أشهر"
                                    : "Every four months "}
                                </option>

                                <option value="6">
                                  {i18n.language === "ar"
                                    ? "كل ستة أشهر"
                                    : "Every six months"}
                                </option>

                                <option value="12">
                                  {i18n.language === "ar"
                                    ? "كل سنة"
                                    : "Every year"}
                                </option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.payment_type}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">عدد الأقساط</label>
                              <Form.Control
                                className="form-control"
                                type="number"
                                name="installment_count"
                                onChange={formik.handleChange}
                                value={formik.values.installment_count || ""}
                                isInvalid={
                                  formik.touched.installment_count &&
                                  formik.errors.installment_count
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.installment_count}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">قيمة القسط</label>
                              <Form.Control
                                className="form-control"
                                type="number"
                                readOnly
                                name="installment_value"
                                onChange={formik.handleChange}
                                value={formik.values.installment_value || ""}
                                isInvalid={
                                  formik.touched.installment_value &&
                                  formik.errors.installment_value
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.installment_value}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">تجديد تلقائي</label>
                              <Form.Check
                                style={{ margin: "10px 20px" }}
                                type="switch"
                                label=""
                                name="automatic_renewal"
                                value={formik.values.automatic_renewal || ""}
                                checked={formik.values.automatic_renewal}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>

                  <div className="mb-5">
                    <h4>
                      <i className="bi bi-tools text-theme"></i> الخدمات
                    </h4>
                    <p> </p>
                    <Card>
                      <CardBody>
                        <div className="row">
                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">مبلغ الخدمات</label>
                              <Form.Control
                                className="form-control"
                                type="number"
                                name="amount_services"
                                onChange={formik.handleChange}
                                value={formik.values.amount_services || ""}
                                isInvalid={
                                  formik.touched.amount_services &&
                                  formik.errors.amount_services
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.amount_services}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">مبلغ التأمين</label>
                              <Form.Control
                                className="form-control"
                                type="number"
                                name="amount_insurance"
                                onChange={formik.handleChange}
                                value={formik.values.amount_insurance || ""}
                                isInvalid={
                                  formik.touched.amount_insurance &&
                                  formik.errors.amount_insurance
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.amount_insurance}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">سعي المكتب</label>
                              <Form.Control
                                className="form-control"
                                type="number"
                                name="fees_office"
                                onChange={formik.handleChange}
                                value={formik.values.fees_office || ""}
                                isInvalid={
                                  formik.touched.fees_office &&
                                  formik.errors.fees_office
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.fees_office}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">المستأجر</label>
                              <Form.Control
                                className="form-control"
                                type="text"
                                name="represented"
                                onChange={formik.handleChange}
                                value={formik.values.represented || ""}
                                isInvalid={
                                  formik.touched.represented &&
                                  formik.errors.represented
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.represented}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">
                                الغرض من الايجار
                              </label>
                              <Form.Control
                                className="form-control"
                                type="text"
                                name="purpose"
                                onChange={formik.handleChange}
                                value={formik.values.purpose || ""}
                                isInvalid={
                                  formik.touched.purpose &&
                                  formik.errors.purpose
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.purpose}
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="form-group mb-3">
                              <label className="form-label">{t("Phone")}</label>
                              <Form.Control
                                className="form-control"
                                type="textarea"
                                name="description"
                                onChange={formik.handleChange}
                                value={formik.values.description || ""}
                              />
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>

                  <div className="mb-5">
                    <h4>
                      <i className="fas fa-lg fa-fw me-2 fa-building text-theme"></i>
                      العقارات
                    </h4>
                    <p> </p>
                    <Card>
                      <CardBody>
                        <RealEstateListContract
                          tableDataRealEstate={tableDataRealEstate}
                          setTableDataRealEstate={setTableDataRealEstate}
                          Total={formik.values.Total}
                        />
                      </CardBody>
                    </Card>
                  </div>

                  <div className="mb-5">
                    <h4>
                      <i className="bi bi-cash-stack fa-lg  text-theme"></i>{" "}
                      الدفعات
                    </h4>
                    <p> </p>
                    <Card>
                      <CardBody>
                        <button
                          type="button"
                          className="btn me-1 btn-default mb-1"
                          onClick={() => handleclick()}
                          disabled={!formik.isValid}
                        >
                          {t("InstallmentsGeneration")}
                        </button>

                        <Installments
                          installments={installments}
                          setInstallments={setInstallments}
                        />
                      </CardBody>
                    </Card>
                  </div>

                  {/* <div className="accordion mb-5">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          <h4 className="me-3">
                            <i className="bi bi-cash-stack fa-lg  text-theme"></i>{" "}
                            الدفعات
                          </h4>
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="mb-5">
                            <Card>
                              <CardBody>
                            <button
                              type="button"
                              className="btn me-1 btn-default mb-1"
                              onClick={() => handleclick()}
                              disabled={!formik.isValid}
                            >
                              {t("InstallmentsGeneration")}
                            </button>

                            <Installments
                              installments={installments}
                              setInstallments={setInstallments}
                            />
                            </CardBody>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="mb-5">
                    <h4>
                      <i className="fa-lg fa-fw me-2 bi bi-paperclip text-theme"></i>
                      المرفقات
                    </h4>
                    <Card>
                      <CardBody>
                        <div className="row ">
                          <div className="col-xl-6 mx-auto">
                            <AttachmentList
                              attachments={attachments}
                              setAttachments={setAttachments}
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  {/* <div className="mb-4">

                  <Attachment setAttachments={setAttachments} />
                  </div> */}

                  


                </div>
              </div>
            </div>
          </div>

          <div className="text-center">
            <button
              disabled={formik.isSubmitting}
              type="button"
              className="btn  btn-lg  me-1 btn-theme mb-1"
              onClick={formik.handleSubmit}
            >
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fa fa-save fa-fw me-1"></i>
              )}
              {t("Button.Save")}
            </button>
            <button
              type="button"
              className=" btn me-1  btn-lg  btn-default mb-1"
              onClick={() => Navigate("/SU/Contracts", { replace: true })}
            >
              <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
            </button>
          </div>
          {/* </Form> */}
        </div>

        <hr className="mb-0" />
      </CardBody>
    </>
  );
};

export default AddContracts;
