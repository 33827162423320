import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetDynamicForm } from "../../../store/NTX/FlexValue/Form/formSlice";
import AmeRequests from "../../Ame/Requests/AmeRequests";

const HRRequests = () => {
  const { t, i18n } = useTranslation();

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  //__________________________________________________
  const { DynamicFormList, loading } = useSelector((state) => state.Forms);
  useEffect(() => {
    dispatch(GetDynamicForm());
  }, [dispatch]);
  //_____________________________________________________
  var FormList = DynamicFormList?.filter(
    (f) =>
      f.dynamicFormTypesId === 1
       &&
      f.moduleId === "0a6c6b89-1d52-4eaa-8d2c-010c74b1f6e0"
  );

 

  return (
    <div>

<div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#/">{t('Home')}</a>
              </li>
              <li className="breadcrumb-item active">طلبات الموارد البشرية</li>
            </ul>
            <h1 className="page-header mb-0">طلبات الموارد البشرية</h1>
          </div>
        </div>



      <AmeRequests FormList={FormList}  />
    </div>
  );
};

export default HRRequests;
