import React from "react";
import Papa from "papaparse";
import Pagination from "../../../../components/Pagination/Pagination.jsx";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUsers } from "../../../../store/NTX/Security/usersSlice.js";
import { Card, CardBody } from "../../../../components/card/card.jsx";
import ModalComponent from "../../../../components/Modal/ModalComponent.jsx";
import AddUser from "./AddUser.js";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import Loader from "../../../../components/Loader/Loader.js";

function Users() {
  // const pathName  = useLocation();
  // console.log(pathName);

  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { Users, loading, error } = useSelector((state) => state.Users);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  // console.log(Users);

  const filteredSearch = Users?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.usersName?.toLowerCase().includes(searchLowerCase) ||
      item.refName?.toLowerCase().toString().includes(searchLowerCase) ||
      item.refName2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);






  // report to xls
  const handleReport = () => {
    const csv = Papa.unparse({
      fields: ["usersName", "refName" , "refName2" , "typeName"],
      data: currentData,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "User Management",
      link: null,
    },
  ];
  return (
    <>

      <Breadcrumb BreadcrumbList={BcrumbList} PageName="User Management" />
      <Card>
        <Loader loading={loading}/>
          <div className="tab-content p-4">
            <div className="tab-pane fade show active">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e)=> setSearch(e.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end">
                  <a
                    href="#/"
                    className="btn me-1 btn-outline-theme"
                    data-bs-toggle="modal"
                    data-bs-target="#modalAddUsers"
                  >
                    <i className="fa fa-plus-circle fa-fw me-1"></i>{t('Add User')}
                  </a>
                  <button
                    onClick={handleReport}
                    className="btn btn-outline-default text-nowrap rounded-2"
                    tabIndex={0}
                    aria-controls="datatable"
                    type="button"
                  >
                    <span>
                      <i className="fa fa-file-excel fa-fw me-1" /> Export CSV
                    </span>
                  </button>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col" >
                        {t('User Name')}
                      </th>
                      <th scope="col" >
                        {t('Employee Name')}
                      </th>
                      <th scope="col" >
                        is Active
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <Loading loading={loading} error={error}> */}
                    {currentData.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">{item.usersName}</td>
                        <td className="align-middle">
                          {i18n.language === "en"
                            ? item.refName2
                            : item.refName}
                        </td>
                        <td className="align-middle">
                          {/* {item.isActive ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">not Active</span>} */}
                          {item.isActive ? (
                            <span className="badge d-block rounded-0 pt-5px w-70px bg-success text-theme-900">
                              Active
                            </span>
                          ) : (
                            <span className="badge d-block rounded-0 pt-5px w-70px bg-white bg-opacity-25">
                              not Active
                            </span>
                          )}
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-outline-lime"
                              onClick={() => Navigate(`${item.id}/Edit`)}
                            >
                              <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                              {t('Button.Edit')}
                            </button>

                            <button
                              type="button"
                              className="btn btn-outline-lime"
                              onClick={() =>
                                Navigate(`${item.id}/ResponsibilityUser`)
                              }
                            >
                              <i className="fas fa-lg fa-fw me-2 fa-tasks"></i>
                               {t('Responsibilities')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-lime"
                              onClick={() =>
                                Navigate(`${item.id}/ExtraResponsibilities`)
                              }
                            >
                              <i className="fas fa-lg fa-fw me-2 fa-tasks"></i>
                               صلاحيات إضافيـــــة
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {/* </Loading> */}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </div>

      </Card>
      <ModalComponent title={t('Add User')} id="modalAddUsers">
      <AddUser/>
    </ModalComponent>
    </>
  );
}

export default Users;
