import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { GetNtxPeoplesList } from "../../../store/NTX/Peoples/peoplesSlice";
import { GetEquipmentsModelList } from "../../../store/FLEET/EquipmentsModel/equipmentsmodelSlice";
import { GetEngineStatues } from "../../../store/lookupSlice";
import useGetOrganizations from "../../../hooks/HR/use-get-organizations";
import { useFormik } from "formik";
import {
  GetEquipmentsById,
  GetEquipmentsList,
  UpdateEquipments,
} from "../../../store/FLEET/Equipments/equipmentsSlice";
import { useNavigate, useParams } from "react-router-dom";
import CustomAlert from "../../../components/Alert/CustomAlert";
import Breadcrumb from "../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../components/card/card";
import { EquipmentsSchema } from "../../ValidationForm/validationSchema";
import FileList from "../../Attachments/FileList";
import Loader from "../../../components/Loader/Loader";

const EditEquipments = () => {
  const { Id } = useParams();
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetNtxPeoplesList());
    dispatch(GetEquipmentsModelList()) ;
    dispatch(GetEngineStatues());
    if (Id) {
      dispatch(GetEquipmentsById(Id));
    }
  }, []);
  
  const { EquipmentsById , loading } = useAppSelector((state) => state.Equipments);
  const { PeoplesList } = useAppSelector((state) => state.Peoples);
  const { EquipmentsModelList } = useAppSelector(
    (state) => state.EquipmentsModel
  );
  const { EngineStatues } = useAppSelector((state) => state.Lookup);
  const { Organizations } = useGetOrganizations();

  const formik = useFormik({
    initialValues: {
      id: EquipmentsById ? EquipmentsById.id : "",
      name: EquipmentsById ? EquipmentsById.name : "",
      name2: EquipmentsById ? EquipmentsById.name2 : "",
      description: EquipmentsById ? EquipmentsById.description : "",
      fleetModelId: EquipmentsById ? EquipmentsById.fleetModelId : "",
      plateNo: EquipmentsById ? EquipmentsById.plateNo : "",
      serialNo: EquipmentsById ? EquipmentsById.serialNo : "",
      chassisNo: EquipmentsById ? EquipmentsById.chassisNo : "",
      engineStatus: EquipmentsById ? EquipmentsById.engineStatus : "",
      code: EquipmentsById ? EquipmentsById.code : "",
      barcode: EquipmentsById ? EquipmentsById.barcode : "",
      odometer: EquipmentsById ? EquipmentsById.odometer : "",
      organizationId: EquipmentsById ? EquipmentsById.organizationId : "",
      serviceDate: EquipmentsById ? EquipmentsById.serviceDate : "",
      dateOfExpiry: EquipmentsById ? EquipmentsById.dateOfExpiry : "",
      purchasePrice: EquipmentsById ? EquipmentsById.purchasePrice : "",
      ownerId: EquipmentsById ? EquipmentsById.ownerId : "",
      lastmaintenanceDate: EquipmentsById
        ? EquipmentsById.lastmaintenanceDate
        : "",
      driverId: EquipmentsById ? EquipmentsById.driverId : "",
      Img: "",
      IsUpdateIMG: false,
    },
    enableReinitialize: true,
    validationSchema: EquipmentsSchema(t),
    onSubmit: (values) => {
      console.log(values);

      const formData = new FormData();
      formData.append("Id", values.id || "");
      formData.append("Name", values.name || "");
      formData.append("Name2", values.name2 || "");
      formData.append("Description", values.description || "");
      formData.append("FleetModelId", values.fleetModelId || "");
      formData.append("PlateNo", values.plateNo || "");
      formData.append("SerialNo", values.serialNo || "");
      formData.append("ChassisNo", values.chassisNo || "");
      formData.append("EngineStatus", values.engineStatus || "");
      formData.append("Code", values.code || "");
      formData.append("Barcode", values.barcode || "");
      // formData.append("Odometer", values.odometer.toString());
      formData.append("Odometer", values.odometer ? values.odometer.toString() : "");
      formData.append("OrganizationId", values.organizationId || "");
      formData.append("ServiceDate", values.serviceDate || "");
      formData.append("DateOfExpiry", values.dateOfExpiry || "");
      // formData.append("PurchasePrice", values.purchasePrice.toString());
      formData.append("PurchasePrice", values.purchasePrice ? values.purchasePrice.toString() : "");
      formData.append("OwnerId", values.ownerId || "");
      formData.append("LastmaintenanceDate", values.lastmaintenanceDate || "");
      formData.append("Img", values.Img);
      formData.append("IsUpdateIMG", values.IsUpdateIMG.toString());

      dispatch(UpdateEquipments(formData))
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: "Edit" });
            Navigate("/FLEET/Equipments", { replace: true });
            dispatch(GetEquipmentsList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  const handleImageUpload = (event: any) => {
    formik.setFieldValue("Img", event.currentTarget.files[0]);
    formik.setFieldValue("IsUpdateIMG", true);
  };

 

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Assets_and_Equipment",
      link: "/FLEET/Equipments",
    },
    {
      name: "Edit",
      link: null,
    },
  ];
  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Edit" />

      <Card>
        <CardBody>
          <Loader loading={loading}/>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Arabic Name")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2}
                    isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t("English Name")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={!!(formik.touched.name && formik.errors.name)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label"> {t("Model")}</label>
                  <Form.Select
                    className="form-select"
                    name="fleetModelId"
                    onChange={formik.handleChange}
                    value={formik.values.fleetModelId || ""}
                    isInvalid={
                      !!(
                        formik.touched.fleetModelId &&
                        formik.errors.fleetModelId
                      )
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {EquipmentsModelList &&
                      EquipmentsModelList?.filter(
                        (f) => f.parentId !== null
                      ).map((item, idx) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                  {formik.errors.fleetModelId &&
                    formik.touched.fleetModelId && (
                      <div>{formik.errors.fleetModelId}</div>
                    )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Plate_Number")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="plateNo"
                    onChange={formik.handleChange}
                    value={formik.values.plateNo}
                    isInvalid={
                      !!(formik.touched.plateNo && formik.errors.plateNo)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.plateNo}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Serial_Number")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="serialNo"
                    onChange={formik.handleChange}
                    value={formik.values.serialNo}
                    isInvalid={
                      !!(formik.touched.serialNo && formik.errors.serialNo)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.serialNo}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Chassis_Number")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="chassisNo"
                    onChange={formik.handleChange}
                    value={formik.values.chassisNo}
                    isInvalid={
                      !!(formik.touched.chassisNo && formik.errors.chassisNo)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.chassisNo}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label"> {t("Engine_Status")}</label>
                  <Form.Select
                    className="form-select"
                    name="engineStatus"
                    onChange={formik.handleChange}
                    value={formik.values.engineStatus || ""}
                    isInvalid={
                      !!(
                        formik.touched.engineStatus &&
                        formik.errors.engineStatus
                      )
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {EngineStatues &&
                      EngineStatues.map((item: any, idx) =>
                        item.list.map((list: any) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === "ar"
                              ? list.meaning2
                              : list.meaning}
                          </option>
                        ))
                      )}
                  </Form.Select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t('AssetNumber')}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="code"
                    onChange={formik.handleChange}
                    value={formik.values.code}
                    isInvalid={!!(formik.touched.code && formik.errors.code)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.code}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Barcode")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="barcode"
                    onChange={formik.handleChange}
                    value={formik.values.barcode}
                    isInvalid={
                      !!(formik.touched.barcode && formik.errors.barcode)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.barcode}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Odometer")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="odometer"
                    onChange={formik.handleChange}
                    value={formik.values.odometer}
                    isInvalid={
                      !!(formik.touched.odometer && formik.errors.odometer)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.odometer}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label"> {t("Department")}</label>
                  <Form.Select
                    className="form-select"
                    name="organizationId"
                    onChange={formik.handleChange}
                    value={formik.values.organizationId || ""}
                    isInvalid={
                      !!(
                        formik.touched.organizationId &&
                        formik.errors.organizationId
                      )
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {Organizations &&
                      Organizations.map((item: any, idx: number) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("ServiceDate")}</label>
                  <Form.Control
                    className="form-control"
                    type="date"
                    name="serviceDate"
                    onChange={formik.handleChange}
                    value={formik.values.serviceDate}
                    isInvalid={
                      !!(
                        formik.touched.serviceDate && formik.errors.serviceDate
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.serviceDate}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("dateOfExpiry")}</label>
                  <Form.Control
                    className="form-control"
                    type="date"
                    name="dateOfExpiry"
                    onChange={formik.handleChange}
                    value={formik.values.dateOfExpiry}
                    isInvalid={
                      !!(
                        formik.touched.dateOfExpiry &&
                        formik.errors.dateOfExpiry
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.dateOfExpiry}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Purchase_Price")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="purchasePrice"
                    onChange={formik.handleChange}
                    value={formik.values.purchasePrice}
                    isInvalid={
                      !!(
                        formik.touched.purchasePrice &&
                        formik.errors.purchasePrice
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.purchasePrice}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label"> {t("Owner")}</label>
                  <Form.Select
                    className="form-select"
                    name="ownerId"
                    onChange={formik.handleChange}
                    value={formik.values.ownerId || ""}
                    isInvalid={
                      !!(formik.touched.ownerId && formik.errors.ownerId)
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {PeoplesList &&
                      PeoplesList?.filter((f) => f.typeCode === "OWNERS").map(
                        (item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === "ar" ? item.name2 : item.name}
                          </option>
                        )
                      )}
                  </Form.Select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">
                    {t("LastMaintenanceDate")}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="date"
                    name="lastmaintenanceDate"
                    onChange={formik.handleChange}
                    value={formik.values.lastmaintenanceDate}
                    isInvalid={
                      !!(
                        formik.touched.lastmaintenanceDate &&
                        formik.errors.lastmaintenanceDate
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.lastmaintenanceDate}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Image_of_Equipment')}</label>
                  <Form.Control
                    className="form-control"
                    type="file"
                    name="Img"
                    placeholder=" أختر صورة"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e)}
                    isInvalid={!!(formik.touched.Img && formik.errors.Img)}
                  />
                  <FileList
                    Attachments={
                      EquipmentsById?.image ? [EquipmentsById.image] : []
                    }
                  />
                </div>
              </div>

              <div className="col-xl-5">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Note")}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />
                  <div></div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-2"></i>
                )}
                {t("Save")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate("/FLEET/Equipments", { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default EditEquipments;
