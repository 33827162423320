import { useFormik } from 'formik';
import React from 'react'
import Form from "react-bootstrap/Form";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { editGrade } from '../../../../store/HR/HRSetting/jobgradeSlice.js';
import { useTranslation } from 'react-i18next';
import useGradeDetails from '../../../../hooks/HRSetting/use-grade-details.js';
import { formJob } from '../../../ValidationForm/validationSchema.js';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card.jsx';
import  Loader  from "../../../../components/Loader/Loader.js";
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx';
 

const EditJobGrade = () => {

 
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

    const { loading , error , Grade } = useGradeDetails();

    const dispatch = useDispatch()

    const formik = useFormik({
      initialValues: {
        name2: Grade ? Grade.name2 : "",
        name:  Grade ? Grade.name : "",
        comments:  Grade ? Grade.comments : "",
       
      },
      enableReinitialize : true,
      validationSchema: formJob(t),
      onSubmit: (values) => {
        console.log(values );
        dispatch(
          editGrade({
            id : Grade.id,
            name2: values.name2,
            name: values.name,
            comments: values.comments,
          })
        )
          .unwrap()
          .then(() => {
            CustomAlert({ action: "Edit" });
            Navigate(-1 , {replace:true}) 
            
          })
          .catch((error) => {
            console.log(error);
            CustomAlert({ action: "Error" });
          });
    }
    })



  return (
    <>
    <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t('Home')}</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/">{t('Grade')}</a>
            </li>
            <li className="breadcrumb-item active">{t('Edit Grade')}</li>
          </ul>
          <h1 className="page-header mb-0">{t('Edit Grade')}</h1>
        </div>

       
      </div>

     
      <Card>
      <Loader loading={loading}/>
        <CardBody>
        <Form onSubmit={formik.handleSubmit} >
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlSelect1">
            {t('Arabic Name')}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name || ""}
              isInvalid={!!formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>
      
     
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlSelect1">
            {t('English Name')}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2 || ""}
              isInvalid={!!formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name2}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>
      <div className="row">
      <div className="col-xl-12">
        <div className="form-group mb-3">
          <label className="form-label" htmlFor="exampleFormControlSelect1">
          {t('Note')} 
          </label>
          <Form.Control
              className="form-control"
              as="textarea" rows={3}
              name="comments"
              onChange={formik.handleChange}
              value={formik.values.comments ||""}
              isInvalid={!!formik.errors.comments}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.comments}
            </Form.Control.Feedback>
          <div>
          
        </div>
        </div>
      </div>
     

    </div>

    
    <div className="text-center">
      <button type="submit" className="btn me-1 btn-theme mb-1">
      <i className="fa fa-save fa-fw me-1"></i> {formik.isSubmitting ? '...' : t('Button.Save') }
      </button>
      <button type="button" className=" btn me-1 btn-default mb-1" onClick={()=> Navigate (-1 , {replace:true}) } >
      <i className="fa fa-power-off fa-fw me-1"></i>  {t('Cancel')}
        </button>
          </div>  
    </Form>
        </CardBody>
      </Card>
   
  </>
  )
}

export default EditJobGrade