import React, { memo, useEffect, useState } from "react";
import * as yup from "yup"; //
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useLookupModule from "../../../../../../hooks/Lookups/use-Lookup-Module";
import {
  GetReportSourceList,
  GetTableViewName,
} from "../../../../../../store/NTX/Reports/reportSlice";
import { DataIcons } from "../../../../../../assets/icons";
import { useSelector } from "react-redux";

const Step1 = ({ setTableViewName, setCheckValidationStep1, setDataStep1 }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { Module } = useLookupModule();

  // const [tableViewList, setTableViewList] = useState([]);
  const [hasExtraInformation, setHasExtraInformation] = useState(false);
  const [hasExtraInformationRecord, setHasExtraInformationRecord] = useState(false);
  const [hasExtraInformationType, setHasExtraInformationType] = useState(false);
  const [tableId, setTableId] = useState(null);
  console.log(tableId);
  console.log(hasExtraInformationType);
  console.log(hasExtraInformationRecord);
  console.log(hasExtraInformation);

  // useEffect(() => {
  //   dispatch(GetTableViewName({ query: "SELECT 	name FROM 	sys.views " }))
  //     .unwrap()
  //     .then((res) => {
  //       setTableViewList(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [dispatch]);

  useEffect(() => {
    dispatch(GetReportSourceList());
  }, [dispatch]);
  // console.log();
  const { ReportSourceList } = useSelector((state) => state.Reports);
  // console.log(ReportSourceList);

  //_______________________________________________
  // Define a validation schema using yup
  const validationSchema = yup.object().shape({
    name: yup.string().required(t("Name is required")),
    name2: yup.string().required(t("Name2 is required")),
    moduleId: yup.string().required(t("Module is required")),
    tableName: yup.string().required(t("Table Name is required")),
    pageSize: yup.string().required(t("page Size is required")),
  });

  const [formData, setFormData] = useState({
    name: "",
    name2: "",
    moduleId: "",
    tableName: "",
    pageSize: "",
    icon: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const areAllFieldsFilled = () => {
    try {
      validationSchema.validateSync(formData, { abortEarly: false });
      return true; // All fields are filled
    } catch (error) {
      return false; // Some field is empty
    }
  };

  useEffect(() => {
    setCheckValidationStep1(areAllFieldsFilled());
    setDataStep1(formData);
  }, [formData]);

  // console.log(formData);
  return (
    <>
      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Arabic Name")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              value={formData.name2}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("English Name")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Application")}</label>
            <Form.Select
              className="form-select"
              name="moduleId"
              value={formData.moduleId}
              onChange={handleInputChange}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Module &&
                Module.map((item, idx) => (
                  <option key={++idx} value={item.id}>
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Data_Source")}</label>
            <Form.Select
              className="form-select mb-3"
              name="tableName"
              value={formData.tableName}
              onChange={(e) => {
                let dataset = e.target.selectedOptions[0].dataset;
                handleInputChange(e);
                setTableViewName(e.target.value);
                setTableId(dataset.tableId || null);
                setHasExtraInformation(dataset.hasExtraInformation);
                setHasExtraInformationRecord(dataset.hasExtraInformationRecord);
                setHasExtraInformationType(dataset.hasExtraInformationType);
              }}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {ReportSourceList &&
                ReportSourceList?.map((item, idx) => (
                  <option
                    key={item.id}
                    value={item.viewName}
                    data-table-id={item.tableId}
                    data-has-extra-information={item.hasExtraInformation}
                    data-has-extra-information-record={item.hasExtraInformationRecord}
                    data-has-extra-information-type={item.hasExtraInformationType}
                  >
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Layout")}</label>
            <Form.Select
              className="form-select mb-3"
              name="pageSize"
              value={formData.pageSize}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              <option value="Landscape">Landscape</option>
              <option value="Portrait">Portrait</option>
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-4" style={{ zIndex: 0 }}>
          <div className="form-group mb-3">
            <label className="form-label">{t("Icon")}</label>

            <Select
              classNamePrefix="react-select"
              // classNamePrefix="select"
              isSearchable={true}
              required
              options={DataIcons}
              onChange={(option) =>
                setFormData({ ...formData, icon: option.value })
              }
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Data_Source2")}</label>
            <Form.Select
              className="form-select mb-3"
              value={formData.tableName}
              onChange={(e) => {
                handleInputChange(e);
                setTableViewName(e.target.value);
              }}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {
                hasExtraInformation === true ? 
              <option value="2">
                {i18n.language === "ar" ? "بيانات اضافية" : "Extra Information"}
              </option> : null
              }
              {
                hasExtraInformationRecord=== true && 
              <option value="3">
                {i18n.language === "ar" ? "سجلات اضافية" : "Extra Information Record"}
              </option>
              }
              {
                hasExtraInformationType=== true && 
              <option value="4">
                {i18n.language === "ar" ? "أنواع اضافية" : "Extra Information Type"}
              </option>
              }
             
            </Form.Select>
          </div>
        </div>


      </div>
    </>
  );
};
export default memo(Step1);
