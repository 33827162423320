import React, { useEffect, useState } from "react";
import CustomAlert from "../../../../components/Alert/CustomAlert";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  GetFleetCategory,
  GetFleetFuelType,
  GetFleetMotionVector,
} from "../../../../store/lookupSlice";
import useLookupNationality from "../../../../hooks/Lookups/use-Lookup-Nationality";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetEquipmentsModelById,
  GetEquipmentsModelList,
  UpdateEquipmentsModel,
} from "../../../../store/FLEET/EquipmentsModel/equipmentsmodelSlice";
import { Form } from "react-bootstrap";
import Select from "react-select";

import CreatableSelect from "react-select/creatable";

import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../../components/card/card";
import { EquipmentsModelSchema } from "../../../ValidationForm/validationSchema";
import FileList from "../../../Attachments/FileList";
import Loader from "../../../../components/Loader/Loader";

const EditModel = () => {
  const Navigate = useNavigate();
  const { id } = useParams();

  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetFleetCategory());
    dispatch(GetFleetFuelType());
    dispatch(GetFleetMotionVector());
    dispatch(GetEquipmentsModelList());

    if (id) {
      dispatch(GetEquipmentsModelById(id));
    }
  }, []);
  const { EquipmentsModelList } = useAppSelector(
    (state) => state.EquipmentsModel
  );

  const { FleetCategory, FleetFuelType, FleetMotionVector } = useAppSelector(
    (state) => state.Lookup
  );
  const { EquipmentsModelListById, loading } = useAppSelector(
    (state) => state.EquipmentsModel
  );

  const { dataNATIONALITY } = useLookupNationality();

  const formik = useFormik({
    initialValues: {
      id: EquipmentsModelListById ? EquipmentsModelListById.id : "",
      name: EquipmentsModelListById ? EquipmentsModelListById.name : "",
      name2: EquipmentsModelListById ? EquipmentsModelListById.name2 : "",
      description: EquipmentsModelListById
        ? EquipmentsModelListById.description
        : "",

      parentId: EquipmentsModelListById ? EquipmentsModelListById.parentId : "",

      manufYear: EquipmentsModelListById
        ? EquipmentsModelListById.manufYear
        : "",
      barcode: EquipmentsModelListById ? EquipmentsModelListById.barcode : "",
      catagory: EquipmentsModelListById ? EquipmentsModelListById.catagory : "",
      manufCountry: EquipmentsModelListById
        ? EquipmentsModelListById.manufCountry
        : "",
      seatsNo: EquipmentsModelListById ? EquipmentsModelListById.seatsNo : "",
      doorsNo: EquipmentsModelListById ? EquipmentsModelListById.doorsNo : "",
      fuelType: EquipmentsModelListById ? EquipmentsModelListById.fuelType : "",
      motionVector: EquipmentsModelListById
        ? EquipmentsModelListById.motionVector
        : "",
      horsepower: EquipmentsModelListById
        ? EquipmentsModelListById.horsepower
        : "",
      horsepowerTax: EquipmentsModelListById
        ? EquipmentsModelListById.horsepowerTax
        : "",
      Img: "",
      IsUpdateIMG: false,
    },
    enableReinitialize: true,
    validationSchema: EquipmentsModelSchema(t),
    onSubmit: (values) => {
      const formData = new FormData();

      formData.append("Id", values.id);
      formData.append("Name", values.name);
      formData.append("Name2", values.name2);
      formData.append("Description", values.description);
      formData.append("ParentId", values.parentId);
      formData.append("ManufYear", values.manufYear);
      formData.append("Barcode", values.barcode);
      formData.append("Catagory", values.catagory);
      formData.append("ManufCountry", values.manufCountry);
      formData.append("SeatsNo", values.seatsNo.toString());
      formData.append("DoorsNo", values.doorsNo.toString());
      formData.append("FuelType", values.fuelType);
      formData.append("MotionVector", values.motionVector);
      formData.append("Horsepower", values.horsepower.toString());
      formData.append("HorsepowerTax", values.horsepowerTax.toString());
      formData.append("Img", values.Img);
      formData.append("IsUpdateIMG", values.IsUpdateIMG.toString());

      dispatch(UpdateEquipmentsModel(formData))
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: "Add" });
            Navigate("/FLEETSettings/Model", { replace: true });
            dispatch(GetEquipmentsModelList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  const handleImageUpload = (event: any) => {
    formik.setFieldValue("Img", event.currentTarget.files[0]);
    formik.setFieldValue("IsUpdateIMG", true);
  };
  //______________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Manufacturers",
      link: "/FLEET/Model",
    },
    {
      name: "Edit",
      link: null,
    },
  ];

  const [defaultValue, setDefaultValue] = useState(null);
  useEffect(() => {

    const defaultOption = dataNATIONALITY?.find(
      (f: any) => f?.value === formik?.values?.manufCountry
    );
    setDefaultValue(defaultOption);
    
  }, [  formik?.values?.manufCountry]);
  console.log(defaultValue);
  

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="EditModel" />

      <Card>
        <CardBody>
          <Loader loading={loading} />
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Arabic Name")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2 || ""}
                    isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("English Name")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name || ""}
                    isInvalid={!!(formik.touched.name && formik.errors.name)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Manufacturer")}</label>
                  <Form.Select
                    className="form-select"
                    name="parentId"
                    onChange={formik.handleChange}
                    value={formik.values.parentId || ""}
                    isInvalid={
                      !!(formik.touched.parentId && formik.errors.parentId)
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {EquipmentsModelList &&
                      EquipmentsModelList?.filter(
                        (f) => f.parentId === null
                      ).map((item, idx) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                  {formik.errors.parentId && formik.touched.parentId && (
                    <div>{formik.errors.parentId}</div>
                  )}
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Catagory")}</label>
                  <Form.Select
                    className="form-select"
                    name="catagory"
                    onChange={formik.handleChange}
                    value={formik.values.catagory || ""}
                    isInvalid={
                      !!(formik.touched.catagory && formik.errors.catagory)
                    }
                  >
                    <option key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {FleetCategory &&
                      FleetCategory.map((item: any, idx) =>
                        item.list.map((list: any) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === "ar"
                              ? list.meaning2
                              : list.meaning}
                          </option>
                        ))
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.catagory}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Transmission")}</label>
                  <Form.Select
                    className="form-select"
                    name="motionVector"
                    onChange={formik.handleChange}
                    value={formik.values.motionVector || ""}
                    isInvalid={
                      !!(
                        formik.touched.motionVector &&
                        formik.errors.motionVector
                      )
                    }
                  >
                    <option key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {FleetMotionVector &&
                      FleetMotionVector.map((item: any, idx) =>
                        item.list.map((list: any) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === "ar"
                              ? list.meaning2
                              : list.meaning}
                          </option>
                        ))
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.motionVector}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">
                    {t("Year_of_Manufacture")}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="manufYear"
                    onChange={formik.handleChange}
                    value={formik.values.manufYear || ""}
                    isInvalid={
                      !!(formik.touched.manufYear && formik.errors.manufYear)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.manufYear}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">
                    {t("State_of_Manufacture")}
                  </label>
                  <Select
                    classNamePrefix="react-select"
                    isRtl
                    isSearchable={true}
                    options={dataNATIONALITY}
                    onChange={(option: any) =>
                      formik.setFieldValue("manufCountry", option.value)
                    }
                    placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                    value={dataNATIONALITY?.find(
                      (f: any) => f?.value === formik?.values?.manufCountry
                    )}
                  />
                  {formik.errors.manufCountry &&
                    formik.touched.manufCountry && (
                      <div style={{ color: "red" }}>
                        {formik.errors.manufCountry}
                      </div>
                    )}
                </div>
              </div>


              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Type_of_Fuel")}</label>
                  <Form.Select
                    className="form-select"
                    name="fuelType"
                    onChange={formik.handleChange}
                    value={formik.values.fuelType || ""}
                    isInvalid={
                      !!(formik.touched.fuelType && formik.errors.fuelType)
                    }
                  >
                    <option key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {FleetFuelType &&
                      FleetFuelType.map((item: any, idx) =>
                        item.list.map((list: any) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === "ar"
                              ? list.meaning2
                              : list.meaning}
                          </option>
                        ))
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.fuelType}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Barcodes")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="barcode"
                    onChange={formik.handleChange}
                    value={formik.values.barcode || ""}
                    isInvalid={
                      !!(formik.touched.barcode && formik.errors.barcode)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.barcode}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Number_of_Seats")}</label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="seatsNo"
                    onChange={formik.handleChange}
                    value={formik.values.seatsNo || ""}
                    isInvalid={
                      !!(formik.touched.seatsNo && formik.errors.seatsNo)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.seatsNo}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Number_of_Doors")}</label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="doorsNo"
                    onChange={formik.handleChange}
                    value={formik.values.doorsNo || ""}
                    isInvalid={
                      !!(formik.touched.doorsNo && formik.errors.doorsNo)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.doorsNo}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Horsepower")}</label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="horsepower"
                    onChange={formik.handleChange}
                    value={formik.values.horsepower || ""}
                    isInvalid={
                      !!(formik.touched.horsepower && formik.errors.horsepower)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.horsepower}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Horsepower_Tax")}</label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="horsepowerTax"
                    onChange={formik.handleChange}
                    value={formik.values.horsepowerTax || ""}
                    isInvalid={
                      !!(
                        formik.touched.horsepowerTax &&
                        formik.errors.horsepowerTax
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.horsepowerTax}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Image")}</label>
                  <Form.Control
                    className="form-control"
                    type="file"
                    name="Img"
                    placeholder=" أختر صورة"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e)}
                    isInvalid={!!(formik.touched.Img && formik.errors.Img)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Img}
                  </Form.Control.Feedback>

                  <FileList
                    Attachments={
                      EquipmentsModelListById?.image
                        ? [EquipmentsModelListById.image]
                        : []
                    }
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Note")}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={3}
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description || ""}
                  />
                  <div></div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                // disabled={fo/rmik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-2"></i>
                )}
                {t("Save")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1)}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default EditModel;
