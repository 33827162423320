import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { getData, postData } from "../../../Axios/axiosData";
import { Parameters } from "./reportModels";
import { ntxAPI } from "../../../../API/axios-custom";




export const GetParametersByReportId = createAsyncThunk(
    "ReportData/GetParametersByReportId",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
  
      try {
        // const data = await getData(`Reports/GeParametersByReportId?ReportId=${id}`);
        const {data} = await ntxAPI.get(`Reports/GeParametersByReportId?ReportId=${id}` );
        return data.data;
      } catch (error : any) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
  )


  
export const GetReportsData = createAsyncThunk(
    "ReportData/GetReportsData",
    async (item : object, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        // const data = await postData("Reports/GetReportsData" , item);
        const {data} = await ntxAPI.post( "Reports/GetReportsData", item)
        return data;
      } catch (error : any) {
        // console.error(error);
        return rejectWithValue(error.message);
      }
    }
  )
  
export const GetReportsChartData = createAsyncThunk(
    "ReportData/GetReportsChartDataSingel",
    async (item : object, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      // console.log(JSON.stringify(item));
      
      try {
        // const data = await postData("Reports/GetReportsChartData" , item);
        const {data} = await ntxAPI.post("Reports/GetReportsChartData" , item)
        return data;
      } catch (error : any) {
        // console.error(error);
        return rejectWithValue(error.message);
      }
    }
  )



interface ParameterState {
    loading: boolean;
    error: string | null;
    ParameterList: Parameters | null ;
    ReportsDataList : object | null
  }
  
  const initialState = {
    loading: false,
    error: null,
    ParameterList : null,
    ReportsDataList : null
  } as ParameterState


  const reportdataSlice = createSlice({
    name: "ReportData",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
        .addCase(GetParametersByReportId.pending, (state, action) => {
          state.loading = true;
          if (state.ParameterList) {
            state.ParameterList.parameterList = [];
          }
        })
        .addCase(GetParametersByReportId.fulfilled, (state, action: PayloadAction<Parameters>) => {
          state.loading = false;
          state.ParameterList = action.payload;
        })
        .addCase(GetParametersByReportId.rejected, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        })
        

        .addCase(GetReportsData.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(GetReportsData.fulfilled, (state, action:PayloadAction<any> ) => {
          state.loading = false;
          state.ReportsDataList = action.payload;
        })
        .addCase(GetReportsData.rejected, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export default reportdataSlice.reducer;