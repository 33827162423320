import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axios-custom";

export const GetInstallmentUnPayment = createAsyncThunk(
  "InstallmentPayment/GetInstallmentUnPayment",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(
        "RealState/GetContractInstallmentUnPaymentList"
      );
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetInstallmentPayment = createAsyncThunk(
  "InstallmentPayment/GetInstallmentPayment",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(
        "RealState/GetContractInstallmentPaymentList"
      );
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const InsertInstallmentPayment = createAsyncThunk(
  "InstallmentPayment/AddInstallmentPayment",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post(
        "RealState/AddContractInstallmentPayment",
        item
      );
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetInstallmentPaymentById = createAsyncThunk(
  "InstallmentPayment/GetInstallmentPaymentById",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(
        `RealState/GetContractInstallmentPaymentById?Id=${id}`
      );
      return data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const DeleteInstallmentPayment = createAsyncThunk(
  "InstallmentPayment/DeleteInstallmentPayment",
  async (id , thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post(
        `RealState/DeleteSuContractInstallmentPayment?ContractInstallmentPaymentId=${id}`);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);



const initialState = {
  InstallmentUnPaymentList: [],
  InstallmentPaymentList: [],
  InstallmentPaymentById: null,
  loading: false,
  loading2: false,
  error: null,
};

const installmentPaymentSlice = createSlice({
  name: "InstallmentPayment",
  initialState,
  reducers: {},
  extraReducers: {
    // Get List
    [GetInstallmentUnPayment.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetInstallmentUnPayment.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.InstallmentUnPaymentList = payload;
    },
    [GetInstallmentUnPayment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


    // Get List
    [GetInstallmentPayment.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetInstallmentPayment.fulfilled]: (state, { payload }) => {
      state.loading = false;
      if(payload.succeeded === true){
        state.InstallmentPaymentList = payload.data;
      }
    },
    [GetInstallmentPayment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


    // Get List By Id
    [GetInstallmentPaymentById.pending]: (state) => {
      state.loading2 = true;
      state.error = null;
      state.InstallmentPaymentById = null;
    },
    [GetInstallmentPaymentById.fulfilled]: (state, { payload }) => {
      state.loading2 = false;
      state.InstallmentPaymentById = payload;
    },
    [GetInstallmentPaymentById.rejected]: (state, action) => {
      state.loading2 = false;
      state.error = action.payload;
    },
  },
});

export default installmentPaymentSlice.reducer;
