import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";

// import { getData, postData } from "../../Axios/axiosData";

export const GetAbsenceAttendanceType = createAsyncThunk(
  "Absence/GetAbsenceAttendanceType",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {                         
      // const data = await getData(`Persons/GetAbsenceAttendanceTypeList?PersonId=${id}`);
      const {data} = await ntxAPI.get( `Persons/GetAbsenceAttendanceTypeList?PersonId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const AddAbsenceAttendance = createAsyncThunk(
  "Absence/AddAbsenceAttendance",
  async (item , thunkAPI) => {
    const { rejectWithValue } = thunkAPI;


    try {
      // const data = await postData(`Persons/AddAbsenceAttendance` , item);
      const {data} = await ntxAPI.post( `Persons/AddAbsenceAttendance`, item)
      // console.log(data);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetAbsenceAttendance = createAsyncThunk(
    "Absence/GetAbsenceAttendance",
    async (PersonId, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await getData(`Persons/GetAbsenceAttendanceByPersonId?PersonId=${PersonId}`);
        const {data} = await ntxAPI.get(`Persons/GetAbsenceAttendanceByPersonId?PersonId=${PersonId}` );
        return data
      } catch (error) {

        console.error(error);
        return rejectWithValue(error.message);
      }
    }
);

export const DeleteAbsenceAttendance = createAsyncThunk(
    "Absence/DeleteAbsenceAttendance",
    async (Id, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await postData(`Persons/DeleteAbsenceAttendance?Id=${Id}`);
        const {data} = await ntxAPI.post(`Persons/DeleteAbsenceAttendance?Id=${Id}`)
        return data;
      } catch (error) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
);


export const GetAbsenceAttendanceView = createAsyncThunk(
  "Absence/GetAbsenceAttendanceView",
  async (Id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Persons/GetAbsenceAttendanceById?AbsenceAttendanceId=${Id}`);
      const {data} = await ntxAPI.get(`Persons/GetAbsenceAttendanceById?AbsenceAttendanceId=${Id}` );
      return data
    } catch (error) {

      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);





const initialState = {
  AbsenceAttendanceList: [],
  AbsenceAttendanceTypeList: [],
  AbsenceAttendanceViewList: [],
  loading: false,
  error: null,
};

const AbsenceSlice = createSlice({
  name: "Absence",
  initialState,
  reducers: {

  },
  extraReducers: {
    /////// get Assignment by id
    [GetAbsenceAttendanceType.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetAbsenceAttendanceType.fulfilled]: (state, action) => {
      state.loading = false;
      state.AbsenceAttendanceTypeList = action.payload;
    },
    [GetAbsenceAttendanceType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    ///////AddAbsenceAttendance
    [AddAbsenceAttendance.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [AddAbsenceAttendance.fulfilled]: (state, action) => {
      state.loading = false;
      // state.AbsenceAttendanceList.push(action.payload);
    },
    [AddAbsenceAttendance.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

      ///////Get Absence Attendance
    [GetAbsenceAttendance.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetAbsenceAttendance.fulfilled]: (state, action) => {
      state.loading = false;
      state.AbsenceAttendanceList = action.payload;
    },
    [GetAbsenceAttendance.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

        ///////Delete
        [DeleteAbsenceAttendance.pending]: (state) => {
            state.loading = true;
            state.error = null;
          },
          [DeleteAbsenceAttendance.fulfilled]: (state, action) => {
            state.loading = false;
            state.records = state.AbsenceAttendanceList.historyAttendances?.filter((el) => el.id !== action.payload);
          },
          [DeleteAbsenceAttendance.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
          },



                ///////Get Absence Attendance View
    [GetAbsenceAttendanceView.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.AbsenceAttendanceViewList = [];
    },
    [GetAbsenceAttendanceView.fulfilled]: (state, action) => {
      state.loading = false;
      state.AbsenceAttendanceViewList = action.payload;
    },
    [GetAbsenceAttendanceView.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

  },
});

export default AbsenceSlice.reducer;
