import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ntxAPI } from "../../API/axios-custom";




 export const GetAmeConditionUsageListByRuleId = createAsyncThunk("AmeConditionUsage/GetAmeConditionUsageListByRuleId", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

   
    try {
      // const data = await getData(`AmeSetting/GetAmeConditionUsageListByRuleId?RuleId=${id}`);
      const {data} = await ntxAPI.get( `AmeSetting/GetAmeConditionUsageListByRuleId?RuleId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const insertAmeConditionUsage = createAsyncThunk("AmeConditionUsage/insertAmeConditionUsage", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    console.log(JSON.stringify(item));

    try {
      // const data = await postData("AmeSetting/AddAmeConditionUsage", item);
      const {data} = await ntxAPI.post("AmeSetting/AddAmeConditionUsage" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const DeleteAmeConditionUsage = createAsyncThunk("AmeConditionUsage/DeleteAmeConditionUsage", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;


    try {
      // const data = await postData(`AmeSetting/DeleteAmeConditionUsage?Id=${id}`);
      const {data} = await ntxAPI.post(`AmeSetting/DeleteAmeConditionUsage?Id=${id}` )
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });




  const initialState = { ConditionUsageListByRuleId: [], loading: false, error: null };

  const AmeConditionUsageSlice = createSlice({
    name: "AmeConditionUsage",
    initialState,
    reducers: {
    },
    extraReducers: {

      /////// get ame condition list                
      [GetAmeConditionUsageListByRuleId.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetAmeConditionUsageListByRuleId.fulfilled]: (state, action) => {
        state.loading = false;
        state.ConditionUsageListByRuleId = action.payload;
      },
      [GetAmeConditionUsageListByRuleId.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },


       //// insert AmeConditionUsage
      [insertAmeConditionUsage.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [insertAmeConditionUsage.fulfilled]: (state, action) => {
        state.loading = false;
        state.ConditionUsageListByRuleId.push(action.payload);
      },
      [insertAmeConditionUsage.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

      /// Delete 
      [DeleteAmeConditionUsage.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [DeleteAmeConditionUsage.fulfilled]: (state, action) => {
        state.loading = false;
        state.ConditionUsageListByRuleId = state.ConditionUsageListByRuleId?.filter((el) => el.id !== action.payload)
      },
      [DeleteAmeConditionUsage.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
   
    },
  });
  
  export default AmeConditionUsageSlice.reducer;