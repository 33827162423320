import React from 'react'

function ModalComponent({id, title, children}) {
  return (
    <div className="modal fade" id={id}>
    <div className="modal-dialog modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div className="modal-body">
          {children}
        </div>
      </div>
    </div>
  </div>
  )
}

export default ModalComponent