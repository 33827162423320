import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AmeRequests from "../../Ame/Requests/AmeRequests";
import { GetDynamicForm } from "../../../store/NTX/FlexValue/Form/formSlice";
import Breadcrumb from "../../../components/Shared/Breadcrumb";

const FLEETRequest = () => {
  const { t, i18n } = useTranslation();

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  //__________________________________________________
  const { DynamicFormList, loading } = useSelector((state) => state.Forms);
  useEffect(() => {
    dispatch(GetDynamicForm());
  }, [dispatch]);
  //_____________________________________________________
  var FormList = DynamicFormList?.filter(
    (f) =>
      f.dynamicFormTypesId === 1
       &&
      f.moduleId === "0a6c6b89-1d52-4eaa-8d2c-040c74b1f6e0" // Fleet Medule
  );

 

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "MaintenanceRequests",
      link: null,
    },
  ];

  return (
    <div>

<Breadcrumb BreadcrumbList={BcrumbList} PageName="MaintenanceRequests" />

      <AmeRequests FormList={FormList}  />
    </div>
  );
};

export default FLEETRequest;
