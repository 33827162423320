import React from "react";
import Form from "react-bootstrap/Form";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Card, CardBody } from "../../../../components/card/card";
import { useTranslation } from "react-i18next";

function DaysCard({ dayName, day, formik, touched, errors }) {
  const { values, handleChange } = formik;
  const dayValues = values[day];

  console.log(values);

  // Access touched and errors for specific fields
  const dayTouched = touched && touched[day];
  const dayError = errors && errors[day];
  //

  const { t, i18n } = useTranslation();
  return (
    <div>
      <Card className="m-2">
        <CardHeader className="fw-bold small bg-theme bg-opacity-25">
          {dayName}
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-xl-6">
              <div className="input-group mb-3 ">
                <span className="input-group-text">
                {t('One fingerprint')}
                </span>
                <Form.Control
                  type="time"
                  className="form-control"
                  name={`${day}.fingerprintTime1`}
                  value={dayValues.fingerprintTime1 || ""}
                  onChange={handleChange}
                  // isInvalid={dayError?.fingerprintTime1 && dayTouched?.fingerprintTime1}
                />
                <Form.Control.Feedback type="invalid">
                  {dayError?.fingerprintTime1}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="input-group mb-3 ">
                <span className="input-group-text">
                {t('Tow fingerprint')}
                </span>
                <Form.Control
                  type="time"
                  className="form-control"
                  name={`${day}.fingerprintTime2`}
                  value={dayValues.fingerprintTime2 || ""}
                  onChange={handleChange}
                  // isInvalid={dayError?.fingerprintTime2 && dayTouched?.fingerprintTime2 }
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="input-group mb-3 ">
                <span className="input-group-text">
                {t('Three fingerprint')}
                </span>
                <Form.Control
                  type="time"
                  className="form-control"
                  name={`${day}.fingerprintTime3`}
                  value={dayValues.fingerprintTime3 || ""}
                  onChange={handleChange}
                  // isInvalid={dayError?.fingerprintTime3 && dayTouched?.fingerprintTime3 }
                />
              </div>
            </div>

            <div className="col-xl-6">
              <div className="input-group mb-3 ">
                <span className="input-group-text">
                {t('Four fingerprint')}
                </span>
                <Form.Control
                  type="time"
                  className="form-control"
                  name={`${day}.fingerprintTime4`}
                  value={dayValues.fingerprintTime4 || ""}
                  onChange={handleChange}
                  // isInvalid={dayError?.fingerprintTime4 && dayTouched?.fingerprintTime4 }
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="input-group mb-3 ">
                <span className="input-group-text">
                {t('Allow In')}
                </span>
                <Form.Control
                  type="time"
                  className="form-control"
                  name={`${day}.allowIn`}
                  value={dayValues.allowIn || ""}
                  onChange={handleChange}
                  // isInvalid={dayError?.allowIn && dayTouched?.allowIn }
                />
              </div>
            </div>

            <div className="col-xl-6">
              <div className="input-group mb-3 ">
                <span className="input-group-text">
                {t('Allow Out')}
                </span>
                <Form.Control
                  type="time"
                  className="form-control"
                  name={`${day}.allowOut`}
                  value={dayValues.allowOut || ""}
                  onChange={handleChange}
                  // isInvalid={dayError?.allowOut && dayTouched?.allowOut }
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">
                {t('Status of permanence')}
                </label>
                <Form.Select
                  className="form-select"
                  name={`${day}.countFingerprint`}
                  value={dayValues.countFingerprint || ""}
                  onChange={handleChange}
                  // isInvalid={dayError?.countFingerprint && dayTouched?.countFingerprint }
                >
                  {/* <option value="">
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option> */}
                  <option key={4} value={0}>
                    {i18n.language === 'ar' ? "إجــازة" : "Off"}
                  </option>
                  <option key={1} value={1}>
                     {i18n.language === 'ar' ? "بصمة" : "One Fingerprint"}
                  </option>
                  <option key={2} value={2}>
                    
                   {i18n.language === 'ar' ? "بصمتــان" : "Tow Fingerprint"}
                   </option>
                  <option key={3} value={3}>
                    
                   {i18n.language === 'ar' ? "أربع بصمات" : "Four Fingerprint"}
                  </option>
                </Form.Select>
              </div>
            </div>

            <div className="col-xl-3">
              <div className="form-group mb-3">
                <label className="form-label">
                {t('Extra day')}
                </label>
                <Form.Check
                  style={{ margin: "10px 20px" }}
                  type="switch"
                  id="custom-switch"
                  label=""
                  name={`${day}.isExtraDay`}
                  value={dayValues.isExtraDay || ""}
                  checked={dayValues.isExtraDay || ""}
                  onChange={handleChange}
                />
              </div>
            </div>


            <div className="col-xl-3">
              <div className="form-group mb-3">
                <label className="form-label">
                {t('Flexible')}
                </label>
                <Form.Check
                  style={{ margin: "10px 20px" }}
                  type="switch"
                  id="custom-switch"
                  label=""
                  name={`${day}.isFlexible`}
                  value={dayValues.isFlexible || ""}
                  checked={dayValues.isFlexible || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {
            dayValues.isFlexible && 
                      <div className="row">
            <div className="col-xl-6">
              <div className="input-group mb-3 ">
                <span className="input-group-text">
                {t('start Flexible')}
                </span>
                <Form.Control
                  type="time"
                  className="form-control"
                  name={`${day}.startFlexible`}
                  value={dayValues.startFlexible || ""}
                  onChange={handleChange}
                  // isInvalid={dayError?.allowIn && dayTouched?.allowIn }
                />
              </div>
            </div>

            <div className="col-xl-6">
              <div className="input-group mb-3 ">
                <span className="input-group-text">
                {t('end Flexible')}
                </span>
                <Form.Control
                  type="time"
                  className="form-control"
                  name={`${day}.endFlexible`}
                  value={dayValues.endFlexible || ""}
                  onChange={handleChange}
                  // isInvalid={dayError?.allowOut && dayTouched?.allowOut }
                />
              </div>
            </div>
          </div>

          }

        </CardBody>
      </Card>
    </div>
  );
}

export default DaysCard;
