import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import {
  GetReportById,
  GetTableViewName,
  UpdatNtxReports,
  cleanReportsListById,
} from "../../../../../store/NTX/Reports/reportSlice";
import BuilderQuerys from "./Step2/BuilderQuerys";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../../components/card/card";
import Step3 from "./Step3/Step3";
import Step2 from "./Step2/Step2";
import Step1 from "./Sterp1/Step1";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomAlert from "../../../../../components/Alert/CustomAlert";
import { useSelector } from "react-redux";
import ConfirmEditDialog from "../../../../../components/Alert/ConfirmEditDialog";
import Loader from "../../../../../components/Loader/Loader";

const UpdateReport = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tableViewName, setTableViewName] = useState("");
  const [fieldsName, setFieldsName] = useState([]);
  const [params, setParams] = useState([]);

  //_____________________ States Check Validation  _____________________
  const [checkValidationStep1, setCheckValidationStep1] = useState(false);
  const [checkValidationStep2, setCheckValidationStep2] = useState(false);
  const [checkValidationStep3, setCheckValidationStep3] = useState(false);
  //_____________________ States Check Validation  _____________________

  const [DataStep1, setDataStep1] = useState({}); // for submit
  const [Query, setQuery] = useState(""); // for submit  from step2
  const [queryBuilderReact, setQueryBuilderReact] = useState(""); // for submit  from step2
  const [DataStep2, setDataStep2] = useState([]); // for submit

  const [DataStep3, setDataStep3] = useState([]); // for submit
  // console.log(checkValidationStep2)
  // console.log(DataStep1);
  // console.log(DataStep2);
  // console.log(queryBuilderReact);
  // console.log(DataStep4);
  // console.log(Query);

  //___ Start Steps Wiserd___________
  const [currentStep, setCurrentStep] = useState(1);
  const handleNextStep = () => {
    if (currentStep === 1) {
      if (checkValidationStep1) {
        setCurrentStep(2);
        
        dispatch(
          GetTableViewName({
            query: `SELECT TABLE_NAME ,COLUMN_NAME as name ,(case when DATA_TYPE in ('smallint','int','float','decimal','numeric','bigint') then 'number' when DATA_TYPE in ('date','datetime') then 'date' when DATA_TYPE in  ('bit') then 'checkbox' when DATA_TYPE in ('time','') then 'time' else 'text' end )inputType FROM INFORMATION_SCHEMA.COLUMNS WHERE TABLE_NAME = N'${tableViewName}'`,
          })
        )
          .unwrap()
          .then((res) => {
            let x = res.map((item) => ({
              name: item.name,
              label: item.name,
              inputType: item.inputType,
              defaultValue: "",
            }));

            let y = x.map((item) => {
              if (item.inputType === "text") {
                item.defaultValue = "_";
              }
              return item;
            });
            // console.log(y);

            setFieldsName(x);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        alert("أكمل جميع الحقول");
        return;
      }
    } else if (currentStep === 2) {
      if (checkValidationStep2) {
        setCurrentStep(3);
      } else {
        alert("أكمل جميع البيانات");
        return;
      }
    } else if (currentStep === 3) {
      ////// No Thing
    }
  };

  const handlePreviousStep = () => {
    if (currentStep === 2) {
      // Move from step 2 to step 1
      setCurrentStep(1);
    } else if (currentStep === 3) {
      // Move from step 3 to step 2
      setCurrentStep(2);
    } else if (currentStep === 4) {
      // Move from step 4 to step 3
      setCurrentStep(3);
    }
    // You can add more conditions for additional steps if needed
  };
  //___ End Steps Wiserd___________

  const { id } = useParams();
  useEffect(() => {
    dispatch(cleanReportsListById());
    dispatch(GetReportById(id));
  }, []);
  const { ReportsListById, loading } = useSelector((state) => state.Reports);
  // console.log(ReportsListById);

  //___ Submit and post to server___________
  const SaveUpdate = (e) => {
    // e.preventDefault();
    if (checkValidationStep3) {
      setIsSubmitting(true)
      dispatch(
        UpdatNtxReports({
          id: ReportsListById.id,
          name: DataStep1.name,
          name2: DataStep1.name2,
          moduleId: DataStep1.moduleId,
          tableName: DataStep1.tableName,
          query: Query,
          description: "description",
          pageType: DataStep1.pageSize,
          reportType: "Type1",
          queryBuilderReact: queryBuilderReact,
          icon: DataStep1.icon,
          reportColumnsList: DataStep3.map((item) => ({
            id: item.id,
            name: item.name,
            name2: item.name2,
            dataType: item.dataType,
            sqlName: item.sqlName,
            sequenceNum: item.sequenceNum,
          })),
          reportParamerersList: DataStep2.map((item) => ({
            id: item.id,
            paramName: item.paramName,
            paramValue: item.paramValue,
            flexValueSetId: item.flexValueSetId,
            isStatic: item.isStatic,
            lable: item.lable,
            lable2: item.lable, // lable2 = lable  based on hamed say
            sequenceNum: item.sequenceNum,
            isVisible: item.isVisible,
            hasIn: item.hasIn,
            endWith: item.endWith,
            beginWith: item.beginWith,
            requiredFlag: item.requiredFlag,
            language: parseInt(item.language),
          })),
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            Navigate("/RPT/Reports", { replace: true });
            CustomAlert({ action: "Edit" });
            setIsSubmitting(false)
          } else {
            CustomAlert({ action: "Error" });
            setIsSubmitting(false)
          }
        })
        .catch((error) => {
          CustomAlert({ action: "Error" });
          setIsSubmitting(false)
          console.log(error);
        });
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="">{t("Home")}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to=""
                onClick={() => Navigate("/NTX/Reports", { replace: true })}
              >
                {t("Reports_Management")}
              </Link>
            </li>
            <li className="breadcrumb-item active">{t("Add_Reports")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Edit_Reports")}</h1>
        </div>
      </div>

      {/* //_________________________________________________ */}

      <Card>
        <CardHeader>
          <div className="nav-wizards-container" dir="ltr">
            <nav className="nav nav-wizards-1 mb-2">
              {/* <div className="nav-item col">
                <Link
                  className={`nav-link ${currentStep === 4 ? "active" : ""}`}
                >
                  <div className="nav-no">4</div>
                  <div className="nav-text">Last step</div>
                </Link>
              </div> */}

              <div className="nav-item col">
                <Link
                  className={`nav-link ${
                    currentStep === 3 || currentStep === 4 ? "active" : ""
                  }`}
                >
                  <div className="nav-no">3</div>
                  <div className="nav-text">{t("Report_columns")}</div>
                </Link>
              </div>

              <div className="nav-item col">
                <Link
                  className={`nav-link ${
                    currentStep === 2 || currentStep === 3 || currentStep === 4
                      ? "active"
                      : ""
                  }  `}
                >
                  <div className="nav-no">2</div>
                  <div className="nav-text">{t("Report_variants")}</div>
                </Link>
              </div>

              <div className="nav-item col">
                <Link
                  className={`nav-link ${
                    currentStep === 1 ||
                    currentStep === 2 ||
                    currentStep === 3 ||
                    currentStep === 4
                      ? "active"
                      : ""
                  }`}
                >
                  <div className="nav-no">1</div>
                  <div className="nav-text">{t("Report_information")}</div>
                </Link>
              </div>
            </nav>
          </div>
        </CardHeader>

        <CardBody>
          <Loader loading={loading} />
          {/* ______________ Step 1 _______________ */}

          <div style={{ display: currentStep === 1 ? "block" : "none" }}>
            <Step1
              setTableViewName={setTableViewName}
              setCheckValidationStep1={setCheckValidationStep1}
              setDataStep1={setDataStep1}
              DataById={ReportsListById}
            />
            {/* <p>All fields filled: {checkValidationStep1 ? "true" : "false"}</p> */}
          </div>

          {/* ______________ Step 2 _______________ */}

          <div style={{ display: currentStep === 2 ? "block" : "none" }}>
            <div className="row">
              <div className="col-xl-2"></div>

              <div className="col-xl-8">
                {fieldsName && Object.keys(ReportsListById)?.length > 0 && (
                  <BuilderQuerys
                    fields={fieldsName}
                    setParams={setParams}
                    setQuery={setQuery}
                    setQueryBuilderReact={setQueryBuilderReact}
                    queryBuilderReact={ReportsListById?.queryBuilderReact}
                  />
                )}
              </div>
              <div className="col-xl-2"></div>
            </div>
            <hr />
            <div className="row">
              <div className="col-xl-12">
                {params.length > 0 &&
                  ReportsListById?.reportParamerersList?.length > 0 && (
                    <Step2
                      params={params}
                      fields={fieldsName}
                      setCheckValidationStep2={setCheckValidationStep2}
                      setDataStep2={setDataStep2}
                      ReportParamerersById={
                        ReportsListById?.reportParamerersList
                      }
                    />
                  )}
              </div>
            </div>
          </div>

          {/* ______________ Step 3 _______________ */}

          {/* <div style={{ display: currentStep === 3 ? "block" : "none" }}>
            <Step3
              params={params}
              fields={fieldsName}
              setCheckValidationStep3={setCheckValidationStep3}
              setDataStep3={setDataStep3}
            />
          </div>
           */}
          {/* ______________ Step 3 _______________ */}

          <div style={{ display: currentStep === 3 ? "block" : "none" }}>
            {fieldsName && Object.keys(ReportsListById)?.length > 0 && (
              <Step3
                fields={fieldsName}
                setCheckValidationStep3={setCheckValidationStep3}
                setDataStep3={setDataStep3}
                DataById={ReportsListById.reportColumnsList}
              />
            )}
            {/* <p>All fields filled: {checkValidationStep4 ? "true" : "false"}</p> */}
          </div>
        </CardBody>

        <hr className="mb-3" style={{zIndex:"-1"}} />
          <div className="text-center mb-3">
            <div className="btn-group btn-group-lg">
              <button
                type="button"
                className="btn btn-secondary mb-1"
                onClick={handlePreviousStep}
              >
                <i className="fas fa-lg fa-fw me-2 fa-arrow-alt-circle-right"></i>
                {t("Previous")}
              </button>
              <button
                type="button"
                className="btn btn-light  mb-1"
                onClick={() => Navigate("/RPT/Reports", { replace: true })}
              >
                {t("Cancel")}
              </button>
              <button
                type="submit"
                className="btn me-1 btn-theme mb-1"
                onClick={handleNextStep}
                disabled={currentStep === 3}
              >
                {currentStep === 4 ? t("Button.Save") : t("Next")}
                {currentStep !== 4 ? (
                  <i
                    className="fas fa-lg fa-fw me-0 fa-arrow-alt-circle-left"
                    style={{ padding: "0px 12px 0px 0px" }}
                  ></i>
                ) : (
                  <i
                    className="far fa-lg fa-fw me-2 fa-save"
                    style={{ padding: "0px 12px 0px 0px" }}
                  ></i>
                )}
              </button>
              {currentStep === 3 && (
              
              !isSubmitting && (
                 <div className="btn-group-lg" >
                  <ConfirmEditDialog
                    onConfirm={SaveUpdate}
                    title="هل أنت متــأكد ؟ "
                    message="لا يمكن التراجع عن هذا الإجــراء."
                    btnText={t("Edit")}
                  />
                </div>
              )

              )}
            </div>
          </div>

      </Card>
    </div>
  );
};

export default UpdateReport;
