/* eslint-disable no-duplicate-case */
/* eslint-disable default-case */
 
import React, { useEffect } from "react";
  
 
import { useDispatch, useSelector } from "react-redux";
 
 
import Loader from "../../../components/Loader/Loader";
import {
  
  cleanTransactionHistoryList,
  GetTransactionHistoryWithValueById,
} from "../../../store/Ame/ameRequestSlice";
import { Card, CardBody } from "../../../components/card/card";
 
import HistoryList from "./HistoryList";
 
import ContentRequest from "./ContentRequest/ContentRequest.jsx";
import HeaderRequest from "./HeaderRequest/HeaderRequest.jsx";
import ActionRequestForm from "./ActionRequest/ActionRequest.js";
import Breadcrumb from "../../../components/Shared/Breadcrumb";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ViewRequests = () => {
 
  const { TransactionHistoryId } = useParams();
  const dispatch = useDispatch();

  const {t} = useTranslation()

  //

  useEffect(() => {
    dispatch(cleanTransactionHistoryList());
    dispatch(GetTransactionHistoryWithValueById(TransactionHistoryId));
  }, []);
  const data = useSelector(
    (state) => state.AmeRequest.TransactionHistoryWithValueList
  );
  const loading = useSelector((state) => state.AmeRequest.loading);
  
  //________________________________
  var BcrumbList = [
    {
      name: t('Home'),
      link: "/",
    },
    {
      name: "selfService",
      link: null,
    },
    {
      name: "selfService",
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="_" />
      <Card>
        <CardBody>
          <Loader loading={loading}/>
          
          <HeaderRequest data={data} />

          <ContentRequest data={data} />

          <div className=" col-xl-10 mx-auto bg-inverse bg-opacity-10 rounded-4 pt-3">

          <ActionRequestForm data={data} />
          </div>

          <div className="row mt-3">
            <div className="col-lg-12">
              {data.history && <HistoryList List={data.history} />}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ViewRequests;
