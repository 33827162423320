import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "./i18n";
import "./scss/stylesAR.scss";


// import reportWebVitals from './reportWebVitals';
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/index";
import AppRoute from './config/app-route.jsx';

var lng : string = localStorage.language === 'ar' ? 'AR':'EN';

// require(`./scss/styles${lng}.scss`);



// const language = localStorage.language ?? 'ar';
// if (language === 'ar') {
//   require('./scss/stylesAR.scss');
// } else {
//   require('./scss/stylesEN.scss');
// }

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
  
root.render(
  // <React.StrictMode>
  <Provider store={store}>
     {/* <PersistGate loading={null} persistor={persistor}> */}
      <RouterProvider router={AppRoute} />
     {/* </PersistGate> */}
</Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
