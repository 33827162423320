import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";
// import { getPayrolls } from "../../../../store/Payroll/PayrollSlice.js";
import { useTranslation } from "react-i18next";

import AddExtraInformationForm from "./AddExtraInformationDynamicType.js";
import { GetDynamicForm } from "../../../../store/NTX/FlexValue/Form/formSlice.js";
import Pagination from "../../../../components/Pagination/Pagination.jsx";
// import useLookupModule from "../../../hooks/Lookups/use-Lookup-Module";
import { Card } from "../../../../components/card/card";
import ModalComponent from "../../../../components/Modal/ModalComponent";

// يستقبل الجدول الجاي منه مع الموديول والأسم
const ExtraInformationDynamicType = ({ moduleId, TableId, Name }) => {
  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  //__________________________________________________
  const { DynamicFormList, loading } = useSelector((state) => state.Forms);
  console.log(DynamicFormList);
  useEffect(() => {
    dispatch(GetDynamicForm());
  }, [dispatch]);
  // console.log(DynamicFormList);
  var DynamicFormListExtraInformation = DynamicFormList?.filter(
    (f) => f.dynamicFormTypesId === 4
  );
  //_____________________________________________________
  // جلب بيانات الموديول من الستور ووضع الخيار الاول من السلكت في
  // التخزين المحلي وايضا لفلترة البيانات حسب لموديول تلقائياً
  const [selectedValue, setSelectedValue] = useState("");

  // const { Module } = useLookupModule();

  // useEffect(() => {
  //     if (Module.length) {
  //       setSelectedValue(Module[0].id);
  //     }
  //   }, [Module]);

  //    function handleSelectChange(event) {
  //   setSelectedValue(event.target.value);
  // }
  //
  //  useEffect(() => {
  //      localStorage.setItem('moduleId', selectedValue);
  //    }, [selectedValue]);
  //filter data by <select>
  const filteredDataByModuleId = DynamicFormListExtraInformation?.filter(
    (item) => item.moduleId === moduleId
  );
  //_________________________________________________________________________________________________

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredDataByModuleId
    ?.filter((item) => item.name2.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return b[sortBy] > a[sortBy] ? 1 : -1;
      }
    })
    .slice(indexOfFirstData, indexOfLastData);

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active">
              {t("Extra Information Forms")}
            </li>
          </ul>
          <h1 className="page-header mb-0">{Name}</h1>
        </div>
      </div>
      <Card>
        <Loader loading={loading} />
        <div className="tab-content p-4">
          <div className="tab-pane fade show active">
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-4">
                  <div className="flex-fill position-relative">
                    <div className="input-group mb-4">
                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: "10px" }}
                          >
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            placeholder={t("Search")}
                            value={search}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5 col-md-6 text-end">
                <a
                  href="#/"
                  className="btn btn-outline-theme"
                  data-bs-toggle="modal"
                  data-bs-target="#modalAddForm"
                  onClick={() => setShow(true)}
                  // hidden={hiddenAddForm}
                >
                  <i className="fa fa-plus-circle fa-fw me-1"></i>
                  {t("Add Form")}
                </a>
              </div>
            </div>

            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th scope="col" onClick={() => handleSort("Name")}>
                      {t("Type")}
                    </th>
                    <th scope="col" onClick={() => handleSort("Name")}>
                      {t("Icon")}
                    </th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </td>
                      <td className="align-middle">
                        <i className={item.icon}></i>
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            //  hidden={hiddenAddFormColumn}
                            onClick={() =>
                              Navigate(`/NTX/Forms/${item.id}/AddFormColumns`)
                            }
                            className="btn btn-outline-lime"
                          >
                            <i className="fab fa-lg fa-fw me-2 fa-elementor"></i>
                            {t("fields Form")}
                          </button>

                          <button
                            type="button"
                            // hidden={hiddenEditForm}
                            onClick={() => Navigate(`${item.id}/Edit`)}
                            className="btn btn-outline-lime"
                          >
                            <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                            {t("Button.Edit")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              filteredData={DynamicFormListExtraInformation}
              // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
              dataPerPage={dataPerPage}
              handlePagination={handlePagination}
              currentPage={currentPage}
            />
          </div>
        </div>
      </Card>

      <ModalComponent title={`اضافة ${Name}`} id="modalAddForm">
        {Show && (
          <AddExtraInformationForm moduleId={moduleId} TableId={TableId} />
        )}
      </ModalComponent>
    </>
  );
};

export default ExtraInformationDynamicType;
