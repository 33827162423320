import React from 'react'
import ContentLoader from 'react-content-loader'

const SkeletonGrid = (props) => {
  return (
    <ContentLoader
    // width={800}
    // height={575}
    viewBox="1 50 700 424"
    // backgroundColor='var(--bs-accordion-active-bg)'
    backgroundColor="#7170701c"
    foregroundColor="#808080"
    // {...props} d
  >
<rect x="10" y="65" rx="4" ry="4" width="679" height="20" />

    <rect x="12" y="100" rx="2" ry="2" width="222" height="130" />
    <rect x="240" y="100" rx="2" ry="2" width="222" height="130" />
    <rect x="467" y="100" rx="2" ry="2" width="222" height="130" />
    <rect x="12" y="250" rx="2" ry="2" width="222" height="130" />
    <rect x="240" y="250" rx="2" ry="2" width="222" height="130" />
    <rect x="468" y="250" rx="2" ry="2" width="222" height="130" />
    <circle cx="286" cy="412" r="12" />
    <circle cx="319" cy="412" r="12" />
    <circle cx="353" cy="412" r="12" />
    <rect x="378" y="400" rx="0" ry="0" width="52" height="24" />
    <rect x="210" y="400" rx="0" ry="0" width="52" height="24" />
    <circle cx="210" cy="412" r="12" />
    <circle cx="428" cy="412" r="12" />
  </ContentLoader>
  )
}

export default SkeletonGrid
