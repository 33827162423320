import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ntxAPI } from "../../../../API/axios-custom";
import SkeletonLoader from "../../../../components/SkeletonLoader/SkeletonLine.js";

const TypeT = ({ item, handleSelectChange  , value  }) => {
  const { t, i18n } = useTranslation();
  const [TableDataList , setTableDataList] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function GetTableData() {
      try {
        const { data } = await ntxAPI.get(
          // `NtxSetting/GetDataByTabelId?TabelId=${item.tableId}`
          `NtxSetting/GetDataByTabelId?TabelId=${item?.tableId}&LookupType=${item?.lookupType}`
        );

        var DataList = data.map((item) => ({
          value: item.id,
          label: i18n.language === "ar" ? item.name2 : item.name,
        }));

        setTableDataList(DataList);
        setIsLoading(false);  
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);  
      }
    }

    GetTableData();
  }, [item.tableId]);




  return (
    <>
      <div className="form-group mb-3">
        <div className="row">
                  <label className="form-label">
          {i18n.language === "ar" ? item.lableText2 : item.lableText}
        </label>
        </div>
        <div className="row">

        {isLoading ? ( 
          <SkeletonLoader/>
        ) : (
         (

          <Select
            classNamePrefix="react-select"
            isSearchable={true}
            options={TableDataList}
            name={item.dynamic_form_column_id }
            required={item.requiredFlag}
            onChange={handleSelectChange}
            placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            value={TableDataList?.find(f => f.value === value)}
            // defaultValue={TableDataList.find(f => f.value === value)}
          />
          )
        )}
      </div></div>
    </>
  );
};

export default TypeT

// import React, { useEffect, useState } from "react";
// import Select from "react-select";
// import { useTranslation } from "react-i18next";
// import useGetDataByTableId from "../../../hooks/NTX/useGetDataByTableId";
// import { ntxAPI } from "../../../API/axios-custom";

// const OrderTypeT = ({ item, handleSelectChange, value }) => {
//   const { t, i18n } = useTranslation();

//   // const { TableDataList } = useGetDataByTableId(item.tableId);
//   const [TableDataList , setTableDataList] = useState([])

//   useEffect(() => {
//     async function GetTableData() {
//       const { data } = await ntxAPI.get(
//         `NtxSetting/GetDataByTabelId?TabelId=${item.tableId}`
//       );
//        var DataList =   data.map((item) => ({
//         value: item.id,
//         label: i18n.language === "ar" ? item.name2 : item.name,
//       }));
//       setTableDataList(DataList);
//     }
//     GetTableData()
//   }, [item]);

//   // const defaultValue = TableDataList.find((item) => item.value === value);
//   console.log(TableDataList.find((item) => item.value === value));

//   return (
//     <>
//       <div className="form-group mb-3">
//         <label className="form-label">
//           {i18n.language === "ar" ? item.lableText2 : item.lableText}
//         </label>

//         { TableDataList.find((item) => item.value === value) && (
//           <Select
//             classNamePrefix="react-select"
//             isSearchable={true}
//             options={TableDataList}
//             name={item.id}
//             required={item.requiredFlag}
//             onChange={handleSelectChange}
//             placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
//             defaultValue={TableDataList.find((item) => item.value === value)}
//           />
//         )}
//       </div>
//     </>
//   );
// };

// export default OrderTypeT;
