import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../components/card/card.jsx";
import { AppSettings } from "../../../../../config/app-settings.js";
import PerfectScrollbar from "react-perfect-scrollbar";
import AddExtraRecord from "../Add/AddExtraRecord.js";
import UpdateExtraRecord from "../Update/UpdateExtraRecord.js";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/Loader/Loader.js";

const TestComponent = ({
  FormRecordWithValueList,
  ReferenceId,
  setRefreshData,
}) => {
  const { t, i18n } = useTranslation();
  const [extraInformationId, setExtraInformationId] = useState(null);
  const [formId, setFormId] = useState(null);
  const [formName, setFormName] = useState("");
  const Navigate = useNavigate();
  const context = useContext(AppSettings);
  const [isAddNew, setIsAddNew] = useState(false);
  const { loadingRecordWithValueList } = useSelector(
    (state) => state.ExtraInformation
  );

  const handleclickNode = (tree, node) => {
    setExtraInformationId(node.extraInformationId);
    setFormName(i18n.language === "ar" ? tree?.name2 : tree?.name);
    setIsAddNew(false);
    handleSidebarMobile()
  };
  const handleclickTree = (tree) => {
    setFormId(tree?.id);
    setFormName(i18n.language === "ar" ? tree?.name2 : tree?.name);
    setIsAddNew(true);
  };
  //_______________________________________________________________________________________

  useEffect(() => {
    var fileHasSubNodes = document.querySelectorAll(".file-node.has-sub");

    fileHasSubNodes.forEach((node) => {
      var fileArrow = node.querySelector(".file-link > .file-arrow");

      fileArrow.addEventListener("click", function (event) {
        event.preventDefault();
        node.classList.toggle("expand");
      });
    });

    var fileInfoNodes = document.querySelectorAll(".file-node");

    fileInfoNodes.forEach((node) => {
      var fileInfo = node.querySelector(".file-link > .file-info");

      fileInfo.addEventListener("click", function (event) {
        event.preventDefault();
        fileInfoNodes.forEach((otherNode) => {
          if (otherNode !== node) {
            otherNode.classList.remove("selected");
          }
        });
        node.classList.add("expand");
        node.classList.add("selected");
      });
    });

    context.setAppContentFullHeight(true);

    return function cleanUp() {
      context.setAppContentFullHeight(false);
    };

    // eslint-disable-next-line
  }, [FormRecordWithValueList]);

  //____ handle Sidebar Mobile _______
  const sidebarRef = useRef(null);
  const [isToggled, setIsToggled] = useState(false);
  const handleSidebarMobile = () => {
    const sidebarElement = sidebarRef.current;
    if(localStorage.language === 'en'){
    sidebarElement.style.left = isToggled ? '0px' : '';
    setIsToggled(!isToggled);
    }else {
    sidebarElement.style.right = isToggled ? '0px' : '';
    setIsToggled(!isToggled);
    }

  };

  
  return (
    <>
      <div className="d-flex flex-column" style={{ height: "90%" }}>
        <Card className="flex-1 m-0 d-flex flex-column overflow-hidden rounded-0">
          <Loader loading={loadingRecordWithValueList} />
          <CardHeader className="fw-bold d-flex ">
            <span className="flex-grow-1 ">السجلات الإضافية</span>
            <span className="flex-grow-1 ">{formName}</span>
            <div className="flex-grow-2 ">
              <button
                type="button"
                className="btn btn-sm btn-default"
                onClick={() => Navigate(-1, { replace: true })}
              >
                {t('Back')}
                <i className="fa ms-2 fa-arrow-left"></i>
              </button>
            </div>
          </CardHeader>
          <CardBody className="p-0 flex-1 overflow-hidden">
            <div className="file-manager h-100">
              <div className="file-manager-container">
                
                <div ref={sidebarRef} className="file-manager-sidebar">
                  <div className="file-manager-sidebar-mobile-toggler">
                    <button
                      type="button"
                      className="btn"
                      onClick={handleSidebarMobile}
                    >
                      <i className="far fa-lg fa-folder"></i>
                    </button>
                  </div>
                  <div className="file-manager-sidebar-content">
                    <PerfectScrollbar className="h-100 p-3">
                      <div className="file-tree mb-3">
                        {FormRecordWithValueList.map((tree, idx) => (
                          <Fragment key={tree.id}>
                            <div className="d-flex mb-2 justify-content-between bg-inverse bg-opacity-10 rounded-2">
                              <div className="file-node has-sub ">
                                <Link to="" className="file-link">
                                  <span className="file-arrow"></span>
                                  <span className="file-info">
                                    <span className="file-icon">
                                      <i className="fa fa-folder fa-lg text-warning"></i>
                                    </span>
                                    <span className="file-text">
                                      {tree.name2}
                                    </span>
                                  </span>
                                </Link>

                                <div className="file-tree">
                                  {tree?.recordValues?.map((node, idx) => (
                                    <div
                                      className="file-node"
                                      key={node?.extraInformationId}
                                      onClick={() =>
                                        handleclickNode(tree, node)
                                      }
                                    >
                                      <Link to="" className="file-link">
                                        <span className="file-arrow"></span>
                                        <span className="file-info">
                                          <span className="file-icon">
                                            <i className={tree?.icon}></i>
                                          </span>
                                          <span className="file-text">
                                            {node.value}
                                          </span>
                                        </span>
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <Link
                                className="pt-1"
                                onClick={() => handleclickTree(tree)}
                              >
                                <i className="fas fa-lg fa-fw  fs-4 bi bi-plus-square-fill"></i>
                              </Link>
                            </div>
                          </Fragment>
                        ))}
                      </div>
                    </PerfectScrollbar>
                  </div>
                </div>
 
                <div className="file-manager-content d-flex flex-column">
                  <div className="flex-1 overflow-hidden">
                    <PerfectScrollbar className="h-100 p-0">
                      <div className="p-3">
                        {isAddNew && formId !== null && (
                          <AddExtraRecord
                            FormId={formId}
                            ReferenceId={ReferenceId}
                            setRefreshData={setRefreshData}
                          />
                        )}

                        {!isAddNew && extraInformationId !== null && (
                          <UpdateExtraRecord
                            extraInformationId={extraInformationId}
                            ReferenceId={ReferenceId}
                            setRefreshData={setRefreshData}
                          />
                        )}
                      </div>
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default TestComponent;

// <div className="d-flex justify-content-between bg-inverse bg-opacity-10 rounded-2">

// <Link
//   to=""
//   className="file-link "
//   onClick={() => handleclickTree(tree)}
// >
//   <span className="file-arrow"></span>
//   <span className="file-info">
// 	<span className="file-icon">
// 	  <i className="fa fa-folder fa-lg text-warning"></i>
// 	</span>
// 	<span className="file-text">
// 	  {tree.name2}
// 	</span>
//   </span>
// </Link>

// <Link
//   to=""
//   className="pt-1"
//   onClick={() => setIsAddNew(true)}
// >
//   <i className="fas fa-lg fa-fw me-1 bi bi-plus-square-fill"></i>
// </Link>

// </div>
