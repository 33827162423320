import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteAmeConditionUsage, GetAmeConditionUsageListByRuleId } from '../../../../store/Ame/ameConditionUsageSlice';
import ConfirmDeleteDialog from '../../../../components/Alert/ConfirmDeleteDialog';
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx';


function AmeConditionUsageList({data}) {


    const { ruleId } = useParams();
    
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const Navigate = useNavigate();
  
  
  
  
      // Handele Delete AmeCondition
      const handleDeleteConditionUsage = (item)=>{

          dispatch(DeleteAmeConditionUsage(item.id)).unwrap()
        .then(() => {
            dispatch(GetAmeConditionUsageListByRuleId(ruleId));
            CustomAlert({ action: "Delete" });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: "Error" });
        });
      }

  



  return (
    <>
    <table className="table">
      <thead className="table-dark">
        <tr>
          {/* <th scope="col">الرقم</th> */}
          <th scope="col"> {t('Name')}</th>
          <th scope="col"></th>
        </tr>
      </thead>

      <tbody>
        {data.map((item, idx) => (
          <tr key={item.id}>
            {/* <td className="align-middle">{++idx}</td> */}
            <td className="align-middle">

              {i18n.language === "ar" ? item.name2 : item.name}
            </td>
            <td className="align-middle">



              <ConfirmDeleteDialog
                           onConfirm={()=>handleDeleteConditionUsage(item)}
                            title="Are you sure?"
                            message="This action cannot be undone."
                        />



            </td>
          </tr>
        ))}
      </tbody>
    </table>

</>
  )
}

export default AmeConditionUsageList
