
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ToastAlertProps {
  type: "info" | "success" | "warn" | "error";
  title: string;
  position?: any;
  autoClose?: number;
  hideProgressBar?: boolean;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
  draggable?: boolean;
  progress?: undefined;
}

export default function ToastAlert(props: ToastAlertProps) {
    
  switch (props.type) {
    case "info":
      toast.info(props.title, {
        position: props.position ?? "top-center",
        autoClose: props.autoClose ?? 1000,
        hideProgressBar: props.hideProgressBar ?? false,
        closeOnClick: props.closeOnClick ?? true,
        pauseOnHover: props.pauseOnHover ?? true,
        draggable: props.draggable ?? true,
        progress: props.progress ?? undefined,
        theme: localStorage.appMode === "light" ? "light" : "dark",
      });
      break;
    case "success":
      toast.success(props.title, {
        position: props.position ?? "top-center",
        autoClose: props.autoClose ?? 1000,
        hideProgressBar: props.hideProgressBar ?? false,
        closeOnClick: props.closeOnClick ?? true,
        pauseOnHover: props.pauseOnHover ?? true,
        draggable: props.draggable ?? true,
        progress: props.progress ?? undefined,
        theme: localStorage.appMode === "light" ? "light" : "dark",
      });
      break;
    case "error":
      toast.error(props.title, {
        position: props.position ?? "top-center",
        autoClose: props.autoClose ?? 1000,
        hideProgressBar: props.hideProgressBar ?? false,
        closeOnClick: props.closeOnClick ?? true,
        pauseOnHover: props.pauseOnHover ?? true,
        draggable: props.draggable ?? true,
        progress: props.progress ?? undefined,
        theme: localStorage.appMode === "light" ? "light" : "dark",
      });
      break;
    case "warn":
      toast.warn( props.title, {
        position: props.position ?? "top-center",
        autoClose: props.autoClose ?? 2000,
        hideProgressBar: props.hideProgressBar ?? false,
        closeOnClick: props.closeOnClick ?? true,
        pauseOnHover: props.pauseOnHover ?? true,
        draggable: props.draggable ?? true,
        progress: props.progress ?? undefined,
        theme: localStorage.appMode ===  "light" ? "light" : "dark",
        });
      break;
    default:
      toast( props.title , {
        position: props.position ?? "top-center",
        autoClose: props.autoClose ?? 1000,
        hideProgressBar: props.hideProgressBar ?? false,
        closeOnClick: props.closeOnClick ?? true,
        pauseOnHover: props.pauseOnHover ?? true,
        draggable: props.draggable ?? true,
        progress: props.progress ?? undefined,
        theme: localStorage.appMode ===  "light" ? "light" : "dark",
        })
  }

}