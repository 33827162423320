import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import Form from "react-bootstrap/Form";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useLookupNationality from '../../../../../hooks/Lookups/use-Lookup-Nationality';
import useGetOrganizations from '../../../../../hooks/HR/use-get-organizations';
import { editLocation } from '../../../../../store/HR/HRSetting/locationSlice';
import { formLocation } from '../../../../ValidationForm/validationSchema';
import { useNavigate } from 'react-router-dom';
import CustomAlert from '../../../../../components/Alert/CustomAlert.tsx';
import { Card, CardBody } from '../../../../../components/card/card';
import Map from '../../../../../components/Map/Map';
import useGetLocations from '../../../../../hooks/HRSetting/use-get-locations';
import Loading from '../../../../../components/Loading';

function EditFormLocation({Location , loading}) {
  const Navigate = useNavigate();
     //////////////////////////// state  for Map
  const [center, setCenter] = useState({ lat: parseFloat(Location.latitude), lng: parseFloat(Location.longitude) });
  const [lat, setLat] = useState(center.lat);
  const [lng, setLng] = useState(center.lng);
 //////////////////////////// state  for Map

 const { Locations } = useGetLocations();
  const { Organizations } = useGetOrganizations();
  const dataOrg = Organizations?.filter((f) => f.type === "130");
  ///////////////////
    const { NATIONALITY } = useLookupNationality();
    const { t, i18n } = useTranslation();
  
    const dispatch = useDispatch();
    
    const formik = useFormik({
      initialValues: {
        name: Location ? Location.name : "",           
        name2: Location ? Location.name2 : "",
        businessGroupId: Location ? Location.businessGroupId : "",
        description: Location ? Location.description : "",
        shipToLocationId: Location ? Location.shipToLocationId : "",
        shipToSiteFlag: Location ? Location.shipToSiteFlag :"",
        receivingSiteFlag: Location ? Location.receivingSiteFlag :"",
        billToSiteFlag: Location ? Location.billToSiteFlag :"",
        country: Location ? Location.country : "",
        addressLine: Location ? Location.addressLine : "",
        townOrCity: Location ? Location.townOrCity : "",
        postalCode: Location ? Location.postalCode : "",
        region: Location ? Location.region : "",
        telephoneNumber: Location ? Location.telephoneNumber : "",
        latitude: Location ? Location.latitude : "",
        longitude: Location ? Location.longitude : "",
      },

      enableReinitialize : true,
      validationSchema : formLocation(t),
  
      onSubmit: (values, { resetForm }) => {
        values.latitude = lat.toString()
        values.longitude = lng.toString()
  
        dispatch(
          editLocation({
            id: Location?.id ,
        name: values.name,
        name2: values.name2,
        businessGroupId: values.businessGroupId,
        description: values.description,
        shipsToLocationId:  null,            /// نحتاجها في المبيعات 
        shipToSiteFlag: null,                    // نحتاجها في المبيعات 
        receivingSiteFlag: null,                 // نحتاجها في المبيعات 
        billToSiteFlag: null,                     // نحتاجها في المبيعات 
        country: values.country,
        addressLine: values.addressLine,
        townOrCity: values.townOrCity,
        postalCode: values.postalCode,
        region: values.region,
        telephoneNumber: values.telephoneNumber,
        latitude: values.latitude,
        longitude: values.longitude,
  
        })
        )
          .unwrap()
          .then(() => {
            CustomAlert({ action: "Edit" });
            Navigate(-1)
          })
          .catch((error) => {
            console.log(error);
            CustomAlert({ action: "Error" });
          });
      },
    });


    useEffect(() => {
      return () => {
        formik.resetForm();
      };
    }, []);

  return (
    <Loading loading={loading} SkeletonType="Form2">

    
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("Arabic Name")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name2"
                  onChange={formik.handleChange}
                  value={formik.values.name2 || ""}
                  isInvalid={formik.touched.name2 && formik.errors.name2}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name2}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("English Name")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name || ""}
                  isInvalid={formik.touched.name && formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("Latitude")}</label>

                <Form.Control
                  className="form-control"
                  type="text"
                  value={lat || 0}
                  name="latitude"
                  onChange={(e) => setLat(parseFloat(e.target.value))}
                  readOnly
                  isInvalid={formik.errors.latitude}
                />
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("Longitude")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  value={lng || 0}
                  onChange={(e) => setLng(parseFloat(e.target.value))}
                  name="longitude"
                  readOnly
                  isInvalid={!!formik.errors.longitude}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">Business Group</label>
                <Form.Select
                  className="form-select"
                  name="businessGroupId"
                  onChange={formik.handleChange}
                  value={formik.values.businessGroupId || ""}
                  isInvalid={
                    formik.touched.businessGroupId &&
                    formik.errors.businessGroupId
                  }
                >
                  <option key={null}>
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  {Organizations &&
                    dataOrg.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.businessGroupId}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">ship To Location</label>
                <Form.Select
                  className="form-select"
                  name="shipToLocationId"
                  onChange={formik.handleChange}
                  value={formik.values.shipToLocationId || ""}
                  isInvalid={
                    formik.touched.shipToLocationId &&
                    formik.errors.shipToLocationId
                  }
                >
                  <option key={null}>
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  {Locations &&
                    Locations.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </div>
          </div>

          <div className="row" style={{marginTop : "24px"}}>

            <div className="col-xl-4">
              <div className="form-group mb-3">
              <label className="form-label">
               ship To Site Flag
                </label>
                <Form.Check
                style={{ margin: "10px 40px" }}
                  type="checkbox"
                  // label="ship To Site Flag "
                  name="shipToSiteFlag"
                  onChange={formik.handleChange}
                  value={formik.values.shipToSiteFlag || ""}
                  isInvalid={!!formik.errors.shipToSiteFlag}
                />
              </div>
            </div>

            <div className="col-xl-4">
              <div className="form-group mb-3">
              <label className="form-label">
                 bill To Site Flag
                </label>
                <Form.Check
                style={{ margin: "10px 40px" }}
                type="checkbox"
                  // label="bill To Site Flag"
                  name="billToSiteFlag"
                  onChange={formik.handleChange}
                  value={formik.values.billToSiteFlag || ""}
                  isInvalid={!!formik.errors.billToSiteFlag}
                />
              </div>
            </div>

            <div className="col-xl-4">
              <div className="form-group mb-3">
              <label className="form-label">
                receiving Site Flag
                </label>
                <Form.Check
                style={{ margin: "10px 40px" }}
                type="checkbox"
                  // label="receiving Site Flag"
                  name="receivingSiteFlag"
                  onChange={formik.handleChange}
                  value={formik.values.receivingSiteFlag || ""}
                  isInvalid={!!formik.errors.receivingSiteFlag}
                />
              </div>
            </div>

          </div>
        </div>

        <div className="col-xl-6">
          <Card>
            <CardBody>
              <Map
                center={center}
                lat={lat}
                lng={lng}
                setCenter={setCenter}
                setLat={setLat}
                setLng={setLng}
              />
            </CardBody>
          </Card>
        </div>
      </div>


      <div className="row" style={{marginTop : "10px"}}>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Address")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="addressLine"
              onChange={formik.handleChange}
              value={formik.values.addressLine || ""}
              isInvalid={
                formik.touched.addressLine && formik.errors.addressLine
              }
            />
            {/* <Form.Control.Feedback type="invalid">
            {formik.errors.NATIONAL_IDENTIFIER}
          </Form.Control.Feedback> */}
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Country")}</label>
            <Form.Select
              className="form-select"
              name="country"
              onChange={formik.handleChange}
              value={formik.values.country || ""}
              isInvalid={formik.touched.country && formik.errors.country}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {NATIONALITY.map((item, idx) =>
                item.list.map((list) => (
                  <option key={++idx} value={list.lookupCode}>
                    {i18n.language === "ar" ? list.meaning : list.meaning2}
                  </option>
                ))
              )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {/* {formik.errors.MARITAL_STATUS} */}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("City")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="townOrCity"
              onChange={formik.handleChange}
              value={formik.values.townOrCity || ""}
              isInvalid={formik.touched.townOrCity && formik.errors.townOrCity}
            />
            {/* <Form.Control.Feedback type="invalid">
            {formik.errors.NATIONAL_IDENTIFIER}
          </Form.Control.Feedback> */}
          </div>
        </div>

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("Postal Code")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="postalCode"
                onChange={formik.handleChange}
                value={formik.values.postalCode || ""}
                isInvalid={
                  formik.touched.postalCode && formik.errors.postalCode
                }
              />
              {/* <Form.Control.Feedback type="invalid">
            {formik.errors.NATIONAL_IDENTIFIER}
          </Form.Control.Feedback> */}
            </div>
          </div>

      </div>
      
      <div className="row">



          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("Region")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="region"
                onChange={formik.handleChange}
                value={formik.values.region || ""}
                isInvalid={formik.touched.region && formik.errors.region}
              />
              {/* <Form.Control.Feedback type="invalid">
            {formik.errors.NATIONAL_IDENTIFIER}
          </Form.Control.Feedback> */}
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("Mobile Number")}</label>
              <Form.Control
                className="form-control"
                type="text"
                // placeholder="الهوية"
                name="telephoneNumber"
                onChange={formik.handleChange}
                value={formik.values.telephoneNumber || ""}
                isInvalid={
                  formik.touched.telephoneNumber &&
                  formik.errors.telephoneNumber
                }
              />
              {/* <Form.Control.Feedback type="invalid">
            {formik.errors.NATIONAL_IDENTIFIER}
          </Form.Control.Feedback> */}
            </div>
          </div>
                  <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Note")}</label>
            <Form.Control
              className="form-control"
              as="textarea"
              rows={1}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description || ""}
            />

            <div></div>
          </div>
        </div>
        </div>

      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-theme mb-1"
          id="SaveSubmit"
        >
          <i className="fa fa-save fa-fw me-1"></i>{" "}
          {formik.isSubmitting ? "..." : t("Button.Save")}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          onClick={()=> Navigate(-1 , {replace : true})}
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
      </div>
    </Form>
     </Loading>
  )
}

export default EditFormLocation
