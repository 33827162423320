import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import { Card, CardBody } from "../../../../components/card/card";
import {
  GetPayProposal,
  insertPayProposal,
  cleanPayProposalList,
} from "../../../../store/HR/Payroll/payProposalSlice";
import { useState } from "react";
import { AddPayProposalSchema } from "../../../ValidationForm/validationSchema";
import PayProposalTableList from "./PayProposalTableList";
import Loader from "../../../../components/Loader/Loader";

const AddPayProposal = () => {
  const { assignmentId } = useParams();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  //___________________________________________________________
  useEffect(() => {
    dispatch(cleanPayProposalList());
    dispatch(GetPayProposal(assignmentId));
  }, [dispatch, assignmentId]);
  const { PayProposalList, loading } = useSelector(
    (state) => state.PayProposal
  );

  //_________________________________________________________________

  const formik = useFormik({
    initialValues: {
      changeDate: "",
      proposalReason: "",
      proposedSalaryN: "",
      comments: "",
    },

    validationSchema: AddPayProposalSchema(t),

    onSubmit: (values) => {
      // console.log(values);

      dispatch(
        insertPayProposal({
          assignmentId: assignmentId,
          // businessGroupId: null,
          changeDate: formik.values.changeDate,
          proposalReason: formik.values.proposalReason,
          proposedSalaryN: parseFloat(formik.values.proposedSalaryN),
          comments: formik.values.comments,
          //dateTo: null
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: "Add" });
          dispatch(cleanPayProposalList());
          dispatch(GetPayProposal(assignmentId));
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  //______________________________________________

  function handleKeyPress(event) {
    const allowedChars = /^[0-9.]+$/;
    if (
      !allowedChars.test(event.key) ||
      (event.key === "." && event.target.value.includes("."))
    ) {
      event.preventDefault();
    }
  }

  var personName = localStorage.personName;
  var personName2 = localStorage.personName2;

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item">
              <a onClick={() => Navigate(-1, { replace: true })} href="#/">
                إدخالات الراتب{" "}
              </a>
            </li>
            <li className="breadcrumb-item active">
              {" "}
              {t("Button.Basic_salary")}
            </li>
          </ul>
          <h1 className="page-header mb-0">
            {" "}
            {t("Button.Basic_salary")} {"  "}{" "}
            {i18n.language === "ar" ? personName2 : personName}{" "}
          </h1>
        </div>
      </div>

      <Card>
        <Loader loading={loading} />
        <CardBody>

              <Form onSubmit={formik.handleSubmit}>
                <div className="row">

                  <div className="col-xl-3">
                    <div className="form-group mb-3">
                      <label className="form-label">{t("Salary Value")}</label>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="proposedSalaryN"
                        onChange={formik.handleChange}
                        // onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        value={formik.values.proposedSalaryN}
                        isInvalid={
                          formik.touched.proposedSalaryN &&
                          formik.errors.proposedSalaryN
                        }
                      />

                      <Form.Control.Feedback type="invalid">
                        {formik.errors.proposedSalaryN}
                      </Form.Control.Feedback>
                    </div>
                  </div>

                  <div className="col-xl-3">
                    <div className="form-group mb-3">
                      <label className="form-label">{t("Change Date")}</label>
                      <Form.Control
                        className="form-control"
                        type="date"
                        name="changeDate"
                        onChange={formik.handleChange}
                        value={formik.values.changeDate}
                        isInvalid={
                          formik.touched.changeDate && formik.errors.changeDate
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.changeDate}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                                    <div className="col-xl-6">
                    <div className="form-group mb-3">
                      <label className="form-label">{t("Reason")}</label>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="proposalReason"
                        onChange={formik.handleChange}
                        value={formik.values.proposalReason}
                        isInvalid={
                          formik.touched.proposalReason &&
                          formik.errors.proposalReason
                        }
                      />

                      <Form.Control.Feedback type="invalid">
                        {formik.errors.proposalReason}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </div>

                <div className="row">

                </div>

                <div className="row">
                  <div className="col-xl-12">
                    <div className="form-group mb-3">
                      <label className="form-label">{t("Note")}</label>
                      <Form.Control
                        className="form-control"
                        type="texterea"
                        name="comments"
                        onChange={formik.handleChange}
                        value={formik.values.comments}
                        isInvalid={
                          formik.touched.comments && formik.errors.comments
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="btn me-1 btn-theme mb-1"
                    id="SaveSubmit"
                  >
                    <i className="fa fa-save fa-fw me-1"></i>{" "}
                    {formik.isSubmitting ? "..." : t("Save")}
                  </button>
                  <button
                    type="button"
                    className=" btn me-1 btn-default mb-1"
                    onClick={() => Navigate(-1, { replace: true })}
                  >
                    <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
                  </button>
                </div>
              </Form>


          <hr className="mb-4" />

          <div className="row">
            <div className="col-lg-12">
              <PayProposalTableList PayProposalList={PayProposalList} />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AddPayProposal;
