import * as Funection from '../Helper/Functions'
import * as Permission from '../Helper/Permission'
import * as PermissionType from '../Helper/PermissionType'




const Menu = [

 


//HR
{
System:"HR",
isActive: false,
menu :[
{ is_header: true, title: "Navigation"},
   {
    path: "dashboard",
    icon: "fas fa-home",
    title: "Dashboard",
  },

  {
    path: "HR/Employees",
    icon: "bi bi-people",
    title: "employees",
    isActive: true,

  },

//
  {
    path: "/Org",
    icon: "bi bi-people",
    title: "Organizations",
    children: [
 
{
        path: "/Org/Departments",
        title: "List_Organizations",
        isActive: true, 
      },

      {
        path: "/Org/OrganizationStructure",
        title: "Organizational_Structure",
        isActive: true  ,
      },

    ],
  },


  {
    path: "/HRsettings",
    icon: "bi bi-people",
    title: "hrSettings",
    children: [
      
      {
        path: "HRsettings/Job",
        title: "Jobs",
        isActive: true,
      },
      {
        path: "HRsettings/JobGrade",
        title: "Job grades",
        isActive: true,
      },
      {
        path: "HRsettings/Location",
        title: "Locations",
         
        isActive: true,
      },
      {
        path: "HRsettings/Position",
        title: "Positions",
         
        isActive: true,
      },
      {
        path: "HRsettings/StaffGroup",
        title: "Staff group",
         
        isActive: true,
      },



    ],
  },






  {
    path: "/RAQ",
    icon: "far fa-clock",
    title: "attendancePlan",
    children: [
      {
        path: "RAQ/RotationPlan",
        title: "Rotation plan",  
        isActive: true,
      },
      // {
      //   path: "RAQ/AttendancAnalysis",
      //   title: "تحليل الحضــور",
         
      //   isActive: true,
      //   SYSName: "AttendancAnalysis",
      //   Permissions: [
        
      //   ],
      // },
    ],
  },
  


  {
    path: "/Pay",
    icon: "fas fa-money-bill-alt",
    title: "Payroll Management",
    children: [
      {
        path: "Pay/payrolls",
        title: "Payrolls Settings",
        isActive: true,
      },


      {
        path: "Pay/PayrollElement",
        title: "Payroll Elements",
        isActive: true,
      },


	  {
        path: "Pay/payrollsRun",
        title: "Run Payrolls",
        isActive: true,
      },
{
        path: "Pay/QuickPay",
        title: "مسير راتب افرادي ",
        isActive: true,
      },

      
      {
        path: "Pay/PayrollElementEntry",
        title: "Payroll Element Entry",
        isActive: true,
      },
    ],
  },

  {
    path: "HRAme/",
    icon: "far fa-user-circle",
    title: "selfService",
    children: [
      {
        path: "HRAme/Requests",
        title: "New Request",
        isActive: true,
      },
      {
        path: "SelfService/MyRequests",
        title: "My Requests",
        isActive: true,
      },
    ],
  },


  {
    path: "HR/Reports",
    icon: "bi bi-people",
    title: "Reports",
    isActive: true,
  },
  // {
  //   path: "HR/Test",
  //   icon: "bi bi-people",
  //   title: "TEST",
  //   isActive: true,
  // },
]
},













//NTX
{
  System:"NTX",
  isActive: false,
  menu :[
  { is_header: true, title: "Navigation" },
     {
      path: "dashboard",
      icon: "fas fa-home",
      title: "Dashboard",
      
    },

  
    {
      path: "/NTX",
      icon: "bi bi-code-square",
      title: "System Lists and Forms",
      children: [
        // {
        //   path: "NTX/Forms",
        //   title: "نماذج الخدمة الذاتية",
           
        //   isActive: true,
        //   SYSName: "Form",
        //   Permissions: [
       
        //     { isActive: true, SYSName: "EditForm", isIndex: false},
        //     { isActive: true, SYSName: "AddForm"},
        //     { isActive: true, SYSName: "AddFormColumn", isIndex: false, },
        //     { isActive: true,  SYSName: "EditFormColumn",isIndex: false, },
        //     { isActive: true,   SYSName: "DeleteFormColumn", isIndex: false,  },
           
        //   ],
        // },
        {
          path: "NTX/ExtraInformationForm",
          title: "نماذج البيانات الإضافية",
          isActive: true,
        },

        {
          path: "NTX/ExtraRecordsForm",
          title: "نماذج السجلات الإضافية",
          isActive: true,
        },
  
       
        {
          path: "NTX/ListSystem",
          title: "System Lists",
           
          isActive: true,
        },
      ],
    },


  

    {
      path: "/Security",
      icon: "bi bi-person-fill-lock",
      title: "User Management",
      children: [
   
       
   {
          path: "Security/Users",
          title: "users",
          isActive: true,
        },
        {
          path: "Security/Responsibilities",
          title: "Responsibilities",
          isActive: true,
        },
      ],
    },


    {
      path: "/RPT",
      icon: "bi bi-clipboard2-data-fill",
      title: "Reports",
      children: [

       
   {
          path: "RPT/Reports",
          title: "Reports_Management",
          isActive: true,
   },       
      ],
    },
    {
      path: "NTX/Lookup",
      icon: "bi bi-list-task",
      title: "GeneralSystemLists",
      isActive: true,
    },
  
  ] 
},
 




















//AME
  {
    System:"AME",
    isActive: true,
    menu :[
    { is_header: true, title: "Navigation"},
       {
        path: "dashboard",
        icon: "fas fa-home",
        title: "Dashboard",
      },
    
      {
        path: "/NTX",
        icon: "bi bi-gear",
        title: "نماذج الطلبات",
        children: [
          {
            path: "NTX/Forms",
            title: "نماذج الطلبات",
            isActive: true,
          },
        ],
      },

      
      // {
      //   path: "/Ame/selfService",
      //   icon: "far fa-user-circle",
      //   title: "selfService",
      //   children: [
      //     {
      //       path: "SelfService/Requests",
      //       title: "New Request",
      //       isActive: true,
      //       SYSName: "Requests",
      //       Permissions: [],
      //     },
      //     {
      //       path: "SelfService/MyRequests",
      //       title: "My Requests",
      //       isActive: true,
      //       SYSName: "MyRequests",
      //       Permissions: [],
      //     },
      //   ],
      // },
  

      {
        path: "/Ame",
        icon: "far fa-user-circle",
        title: "Approval Management",
        children: [
    {
    path: "Ame/RequestSetting",
    title: "Request settings",
    isActive: true,

  },

  {
    path: "Ame/ApprovalGroup",
    title: "Approval Group",
    isActive: true,
  },
]}
    ]
  },


















  //SU
  {
    System:"SU",
    isActive: true,
    menu :[
    { is_header: true, title: "Navigation"},
       {
        path: "dashboard",
        icon: "fas fa-home",
        title: "Dashboard",
      },

       {
        path: "/SU/Contracts",
        icon: "bi bi-newspaper",
        title: "RealEstateContracts",
        // isActive : Funection.HasPermission(Permission.RealEstateContracts,PermissionType.Index)
        isActive : true
      },

      {
        path: "/SU/RealEstate",
        icon: "bi bi-buildings",
        title: "RealEstate",
        isActive : true
      },

       {
        path: "/SU/SU_ExtraInformationDynamicType",
        icon: "bi bi-building-gear",
        title: "Types_Real_Estate",
        isActive : true
      },


      {
        path: "/SU/FollowPayments",
        icon: "bi bi-building-check",
        title: "متابعة الإيجارات",
        children: [
          {
            path: "/SU/FollowPayments/InstallmentUnPayment",
            title: "دفع الأقساط",
            isActive: true,
          },
          {
            path: "/SU/FollowPayments/InstallmentPayment",
            title: "عرص الأقساط المدفوعة",
            isActive: true,
          },
        ],
      },



      {
        path: "/SUSettings",
        icon: "bi bi-gear",
        title: "Settings",
        children: [

          {
            path: "SUSettings/Branches",
            // icon: "bi bi-sign-intersection-y-fill",
            title: "الفروع",
            isActive : true
          },

          {
            path: "SUSettings/Locations",
            title: "Locations",
            isActive: true,
          },

        ],
      },

      

      {
        path: "SU/Reports",
        icon: "bi bi-people",
        title: "Reports",
        isActive: true,
      },
    ]
  },













  
//FLEET
{
  System:"FLEET",
  isActive: false,
  menu :[
  { is_header: true, title: "Navigation" },
     {
      path: "dashboard",
      icon: "fas fa-home",
      title: "Dashboard",
    },


    {
      path: "FLEET/Equipments",
      icon: "fas fa-fw fa-car", 
      title: "Assets_and_Equipment",
      isActive: true,
    },
  



    {
      path: "/FLEETSettings",
      icon: "bi bi-gear",
      title: "Settings",
      children: [
        {
          path: "FLEETSettings/Manufacturers",
          title: "Manufacturers",
          isActive: true,

        },


        {
          path: "FLEETSettings/Model",
          title: "Model",
          isActive: true,

        },
        {
          path: "FLEETSettings/FleetElementScan",
          title: "InspectionItems",
          isActive: true,

        },
        {
          path: "FLEETSettings/WorkShop",
          title: "Workshops",
          isActive: true,
        },
        {
          path: "FLEETSettings/Locations",
          title: "Locations",
          isActive: true,
        },
        {
          path: "FLEETSettings/Suppliers",
          title: "Suppliers",
          isActive: true,
  
        },
        {
          path: "FLEETSettings/Drivers",
          title: "Drivers",
          isActive: true,
        },
        {
          path: "FLEETSettings/Owners",
          title: "Owners",
          isActive: true,
        },
      ],
    },
 


    {
      path: "FLEET/Maintenance/",
      icon: "bi bi-tools",
      title: "Maintenance",
      children: [
        {
          path: "FLEET/Maintenance/Requests",
          title: "New Request",
          isActive: true,
        },
        {
          path: "FLEET/Maintenance/MaintenanceOrder",
          title: "MaintenanceOrders",
          isActive: true,

        },
        {
          path: "SelfService/MyRequests",
          title: "My Requests",
          isActive: true,
        },
      ],
    },

    

    {
      path: "FLEET/EquipmentInspection",
      icon: "bi bi-gear-wide-connected",
      title: "EquipmentInspection",
      isActive: true,
    },
    {
      path: "FLEET/Reports",
      icon: "bi bi-clipboard2-data-fill",
      title: "Reports",
      isActive: true,
    },

  ] 
},
 








  


 
];



export default Menu;
