import React from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

function ConfirmDeleteDialog(props) {

console.log(props.iconType);
  const {t} = useTranslation()


  const handleConfirm = () => {
    Swal.fire({
      title: props.title,
      text: props.message,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        props.onConfirm();
        // Swal.fire(

        //     {
        //         timer: 1000,
        //         icon: 'success',
        //         title: 'Deleted!',
        //         text: 'Your file has been deleted.',
                   
        //           },
        //   )
      }
    })
  };

  return (

    <>
    {
      props.iconType === 2 ? (
        <button
        type="button"
        className="btn btn-outline-danger btn-lg m-0"
        onClick={handleConfirm}
      >
        <i className="fas fa-trash-alt fa-fw fa-lg"></i>
      </button>
      ): (
        <button onClick={handleConfirm} type="button" className="btn btn-outline-danger">
        <i className="fas fa-trash-alt fa-fw me-1 "></i>
          {t('Button.Delete')}
      </button>
      )
    }
    </>


  );
}

export default ConfirmDeleteDialog;