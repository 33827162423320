import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';


const DOUGHNUTCHART = () => {
    useEffect(() => {
        var themeColor = (getComputedStyle(document.body).getPropertyValue('--bs-theme')).trim();
        var themeColorRgb = (getComputedStyle(document.body).getPropertyValue('--bs-theme-rgb')).trim();
        var gray900 = (getComputedStyle(document.body).getPropertyValue('--bs-gray-900')).trim();
        var inverse = (getComputedStyle(document.body).getPropertyValue('--bs-inverse')).trim();
        var inverseRgb = (getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb')).trim();
            
        new Chart(document.getElementById('doughnutChart'), {
          type: 'doughnut',
          data: {
            labels: [' NVR Takhassusi', 'NVR King Abdul Azziz', 'NVC Head Office'],
            datasets: [{
              data: [300, 50, 100],
              backgroundColor: ['rgba('+ themeColorRgb +', .25)', 'rgba('+ inverseRgb +', .2)', 'rgba('+ themeColorRgb +', .5)'],
              hoverBackgroundColor: [themeColor, inverse, gray900],
              borderWidth: 0
            }]
          }
        });
        
        // eslint-disable-next-line
      }, []);
  return (
    <div>
    <canvas id="doughnutChart"></canvas>
  </div>
  )
}

export default DOUGHNUTCHART