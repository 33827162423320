import React from 'react'

import ConfirmDeleteDialog from '../../../../components/Alert/ConfirmDeleteDialog';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteAbsenceAttendance, GetAbsenceAttendance } from '../../../../store/HR/Person/absenceSlice';
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx';


function AbsenceList({data}) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const Navigate = useNavigate()
    const { personId } = useParams();

  
      // Handele Delete AmeCondition
      const DeleteAbsence = (item)=>{
          dispatch(DeleteAbsenceAttendance(item.id)).unwrap()
        .then(() => {
            dispatch(GetAbsenceAttendance(personId));
        })
        .catch((error) => {
          CustomAlert({ action: "Delete" });
          console.log(error);
        });
        }

        
  return (
    <>
    <table className="table">
      <thead className="table-dark">
        <tr>
          <th scope="col">{t('Absence_Type')}</th>
          <th scope="col">{t('Start Date')}</th>
          <th scope="col">{t('End_Date')}</th>
          <th scope="col">{t('Number of days')}</th>
          <th scope="col"></th>
        </tr>
      </thead>

      <tbody>
        { data.map((item, idx) => (
          <tr key={item.id}>
            <td className="align-middle">
              {i18n.language === "ar" ? item.absenceName2 : item.absenceName}
            </td>
            <td className="align-middle">{item.dateStart}</td>
            <td className="align-middle">{item.dateEnd}</td>
            <td className="align-middle">{item.absenceDays}</td>
            <td className="align-middle">
            <div className="btn-group">
                <ConfirmDeleteDialog
                  onConfirm={()=>DeleteAbsence(item)}
                  title="Are you sure?"
                  message="This action cannot be undone."
                />

            <button onClick={()=> Navigate(`/HR/Employees/absence/${item.id}/ViewAbsence`) } type="button" className="btn btn-outline-theme">
            <i className="fas fa-lg fa-fw me-2 fa-info-circle"></i>
                View
              </button>
            </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>

</>
  )
}

export default AbsenceList
