import { useFormik } from "formik";
import React, { useEffect, useContext, useState } from "react";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {  Navigate, useNavigate } from "react-router-dom";
import { AppSettings } from "../../config/app-settings";
import { LoginAction } from "../../store/Login/loginSlice";
import Logo from "./Logo";


function Login() {
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);

  const [data, setData] = useState();

  //__________________________________________________________________________
  // const { loading, Token, error } = useSelector((state) => state.userLogin)
  const Token = localStorage.getItem("Token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //_____________________________________________________________________________________

  // useEffect(() => {
  //   if (Token) {
  //     const decodedToken = jwt_decode(Token);
  //     const currentTime = Date.now() / 1000; // Convert to seconds

  //     if (decodedToken.exp < currentTime) {
  //       localStorage.removeItem("Token");
  //       localStorage.removeItem("RefrechToken");
  //       localStorage.removeItem("Expiration");
  //       window.location.href = "/login";
  //       // Token is expired, redirect to login page
  //       // setRedirect(true);
  //     } else {
  //       navigate("/dashboard");
  //       //   window.location.href = '/dashboard';

  //       // Token is valid, redirect to main page
  //       // navigate('/', { replace: true });
  //     }

  //     navigate("/dashboard");
  //   }
  // }, [navigate, Token]);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },

    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),

    onSubmit: (values, { setErrors }) => {
      console.log(values);

      dispatch(
        LoginAction({
          username: values.username,
          password: values.password,
        })
      )
        .unwrap()
        .then(() => {
          setRedirect(true);
          //   Navigate('/', { replace: true });
        })
        .catch((error) => {
          console.log(error);
          formik.setSubmitting(false);
          if (error && error === "Unauthorized") {
            setErrors({ login: "تحقق من بيانات الدخول" });
          } else if (error && error === "NetworkError") {
            setErrors({ login: "لا يوجد اتصال بالسيرفر" });
          }
        });
    },
  });

  if (Token) {
    return <Navigate to="/dashboard" />;
  }


  localStorage.setItem("language", "ar");
  localStorage.setItem("appMode", "dark");
  localStorage.setItem("appTheme", "theme-info");
  document.documentElement.setAttribute("data-bs-theme", "dark");
  // document.documentElement.style.setProperty('--main-color', '#ff5733');

  return (
    <>
      {formik.errors.login && (
        <div className="alert alert-warning">{formik.errors.login}</div>
      )}

      <div className="login">
        <div className="login-content">

        <div style={{marginBottom : "40px" }} >
          <Logo themeClass={localStorage.appTheme ?? "theme-info"} />
        </div>
        <hr/>
          <Form onSubmit={formik.handleSubmit}>
            <h2 className="text-center">تسجيل الدخول  Sign In</h2>
            <div className="text-white text-opacity-50 text-center mb-4">
              
            </div>
            <div className="mb-3">
              <label className="form-label">
               اسم المستخدم User Name <span className="text-danger">*</span>
              </label>
              {/* <input type="text" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" /> */}
              <Form.Control
              style={{textAlign : 'center'}}
                className="form-control form-control-lg bg-white bg-opacity-5"
                type="text"
                name="username"
                onChange={formik.handleChange}
                value={formik.values.username}
                isInvalid={formik.touched.username && formik.errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.username}
              </Form.Control.Feedback>
            </div>

            <div className="mb-3">
              <div className="d-flex">
                <label className="form-label">
                 كلمة المرور Password <span className="text-danger">*</span>
                </label>
                {/* <a
                  href="#/"
                  className="ms-auto text-white text-decoration-none text-opacity-50"
                >
                  Forgot password?
                </a> */}
              </div>
              {/* <input type="password" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" /> */}

              <Form.Control
              style={{textAlign : 'center'}}
                className="form-control form-control-lg bg-white bg-opacity-5"
                type="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                isInvalid={formik.touched.password && formik.errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
            </div>

            <div className="mb-3">
              {/* <div className="form-check">
							<input className="form-check-input" type="checkbox" id="customCheck1" />
							<label className="form-check-label" htmlFor="customCheck1">Remember me</label>
						</div> */}
            </div>
            <button
              type="submit"
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? <div className="spinner-border text-primary spinner-border-sm "></div> : "تسجيل الدخول"}
            </button>
            {/* <div className="text-center text-white text-opacity-50">
              Don't have an account yet?{" "}
              <Link to="/pages/register">Sign up</Link>.
            </div> */}
          </Form>
        </div>
      </div>
    </>
  );
}

export default Login;

// disabled={formik.isSubmitting}
// {formik.isSubmitting ? '...' : 'حفظ'}
