import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import useLookupTitle from "../../../hooks/Lookups/use-Lookup-Title";
import { useTranslation } from "react-i18next";
import useJobDetails from "../../../hooks/HRSetting/use-job-details";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import { formOrganization } from "../../ValidationForm/validationSchema";
import { insertOrganization } from "../../../store/HR/Organizations/organizationSlice";
import { getLocations } from "../../../store/HR/HRSetting/locationSlice";
import useLookupOrgType from "../../../hooks/Lookups/use-Lookup-OrgType";
import useLookupOrgClass from "../../../hooks/Lookups/use-Lookup-OrgClass";
import CustomAlert from "../../../components/Alert/CustomAlert.tsx";

const AddDepartment = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  /// Get  Lookup
  const { ORG_TYPE } = useLookupOrgType();
  const { dataORGCLASS } = useLookupOrgClass();

  //// Get Locations from redux
  const { Locations } = useSelector((state) => state.Locations);
  useEffect(() => {
    dispatch(getLocations());
  }, [dispatch]);

  /////////////////////////////////////////

  const formik = useFormik({
    initialValues: {
      // ID: "",
      name2: "",
      name: "",
      locationId: "",
      externalFlag: false,
      dateFrom: "",
      description: "",
      type: props.type ?? "",
      organizationInformation: props.type === "150" ? "HR_ORG" : "",
    },

    validationSchema: formOrganization(t),

    onSubmit: (values) => {
      console.log(values);

      dispatch(
        insertOrganization({
          businessGroupId: null,
          locationId: values.locationId,
          dateFrom: values.dateFrom,
          name: values.name,
          name2: values.name2,
          externalFlag: values.externalFlag,
          description: values.description,
          type: values.type,
          organizationInformation: values.organizationInformation,
          dateTo: null,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          document.getElementById("closeModalDepartment").click();
          CustomAlert({ action: "Add" });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
      formik.setSubmitting(false);
    },
  });

  // handel multiSelect to this data ship (value1,value2,value3,value4)
  const handleMultiSelectChange = (selectedOptions) => {
    const formattedOptions = selectedOptions
      .map((option) => option.value)
      .join(",");
    formik.setFieldValue("organizationInformation", formattedOptions);
  };

  console.log(formik.values);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Arabic Name")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={formik.touched.name2 && formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name2}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("English Name")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={formik.touched.name && formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("location")}</label>
            <Form.Select
              className="form-select"
              name="locationId"
              onChange={formik.handleChange}
              value={formik.values.locationId}
              isInvalid={formik.touched.locationId && formik.errors.locationId}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Locations.map((item, idx) => (
                <option key={++idx} value={item.id}>
                  {i18n.language === "ar" ? item.name2 : item.name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.locationId}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Internal or external")}</label>
            <Form.Check
              style={{ margin: "10px 20px" }}
              className="form-check"
              type="checkbox"
              id="custom-switch"
              // label= {t("Internal or external")}
              name="externalFlag"
              onChange={formik.handleChange}
              value={formik.values.externalFlag}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Type")}</label>
            <Form.Select
              disabled={props.type !== undefined}
              className="form-select"
              name="type"
              onChange={formik.handleChange}
              value={formik.values.type}
              isInvalid={formik.touched.type && formik.errors.type}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {ORG_TYPE.map((item) =>
                item.list.map((list) => (
                  <option key={list.lookupCode} value={list.lookupCode}>
                    {i18n.language === "ar" ? list.meaning2 : list.meaning}
                  </option>
                ))
              )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.type}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Start Date")}</label>
            <Form.Control
              type="date"
              id="START_DATE"
              className="form-control"
              name="dateFrom"
              onChange={formik.handleChange}
              value={formik.values.dateFrom}
              isInvalid={formik.touched.dateFrom && formik.errors.dateFrom}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.dateFrom}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label">{t("Note")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </div>
        </div>
      </div>

      {props.type === undefined && (
        <div className="col-xl-12">
          <label className="form-label">
            {t("Classification")} <span className="text-danger">*</span>
          </label>
          <Select
            required
            isMulti
            options={dataORGCLASS}
            classNamePrefix="react-select"
            name="organizationInformation"
            onChange={handleMultiSelectChange}
            //  value={formik.values.organizationInformation}
          />
        </div>
      )}
      <hr />
      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-theme mb-1"
          id="SaveSubmit"
        >
          {formik.isSubmitting ? (
            <div className="spinner-border spinner-border-sm me-2"></div>
          ) : (
            <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
          )}
          {t("Add")}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalDepartment"
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
        {/* <button
          type="button"
          onClick={formik.resetForm}
          className="btn me-1 btn btn-danger mb-1"
        >
          <i className="fa fa-trash-alt fa-fw me-1"></i>
          {t("Clear")}
        </button> */}
      </div>
    </Form>
  );
};

export default AddDepartment;
