import React, { useEffect  } from 'react'

const TreeBuilder = ({parentId , List , All ,  setOrgData}) => {

    var hasNotChildren = List?.filter(f=> f.childCount === 0)
    var hasChildren = List?.filter(f=> f.childCount > 0)
    
  return (
    <>
      <div className="folder">
        {hasNotChildren.map(( item ) => (      // 
          <p style={{ cursor: 'pointer' }} key={item.organizationIdChild} onClick={()=> setOrgData(item)} >{item.childName}</p>
        ))}


        {hasChildren.map((itembg , idx) => (
            <details open="open" key={itembg.organizationIdChild}>
              <summary   onClick={()=> setOrgData(itembg)}>{itembg.childName}</summary>
              <TreeBuilder
                All={All}
                parentId={itembg.organizationIdChild}
                List={All?.filter((item) => {
                  return (
                    item.organizationIdParent === itembg.organizationIdChild &&
                    item.organizationIdChild !== item.organizationIdParent
                  );
                })}
                setOrgData={setOrgData}
              />
            </details>
        ))}
        
      </div>
    </>
  );
}

export default TreeBuilder