import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { getEmployees } from "../../store/HR/Person/employeeSlice";
import { useTranslation } from "react-i18next";

const useGetPersons = ()=>{

 const {i18n} = useTranslation()
    const dispatch = useDispatch();

    const {Employees , loading , error} = useSelector(state => state.Employees)

    useEffect(()=>{
      if(Employees?.length === 0){
        dispatch(getEmployees())
      }
    },[])

    // const dataTablesFormList =
    // TablesFormList &&
    // TablesFormList.map((item) => ({
      // value: item.id,
      // label: i18n.language === "ar" ? item.name2 : item.name,
    // }));

    const dataEmployees = Employees && Employees.map((item) => ({
      value: item.id,
      label: i18n.language === "ar" ? item.name2 : item.name,
    }))
    
    return { Employees , dataEmployees , loading , error } ;
};

export default useGetPersons ;