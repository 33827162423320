import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
// import axios from "axios";
// import variables from "../../APIURL/variables";
// import { getData, postData } from "../../Axios/axiosData";



const initialState = { FlexValueSetList: [], loading: false, error: null , TablesFormList:[]  };

  export const GetFlexValueSetList = createAsyncThunk("FlexValueSet/GetFlexValueSetList", async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("FlexValueSet/GetFlexValueSetList");
      const {data} = await ntxAPI.get("FlexValueSet/GetFlexValueSetList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const GetTablesForm = createAsyncThunk("FlexValueSet/GetTablesForm", async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("DynamicForm/GetTablesFormList");
      const {data} = await ntxAPI.get( "DynamicForm/GetTablesFormList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const insertFlexValueSet = createAsyncThunk("FlexValueSet/insertFlexValueSet", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    // debugger
    try {
      const {data} = await ntxAPI.post("FlexValueSet/AddFlexValueSet" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });




  const FlexValueSetSlice = createSlice({
    name: "FlexValueSet",
    initialState,
    reducers: { },
    extraReducers: {
      /////// get FlexValueSetList
      [GetFlexValueSetList.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetFlexValueSetList.fulfilled]: (state, action) => {
        state.loading = false;
        state.FlexValueSetList = action.payload;
      },
      [GetFlexValueSetList.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

          /////// get Tables
          [GetTablesForm.pending]: (state) => {
            state.loading = true;
            state.error = null;
          },
          [GetTablesForm.fulfilled]: (state, action) => {
            state.loading = false;
            state.TablesFormList = action.payload;
          },
          [GetTablesForm.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
          },

          /////// Add FlexValueSetList
          [insertFlexValueSet.pending]: (state) => {
            state.loading = true;
            state.error = null;
          },
          [insertFlexValueSet.fulfilled]: (state, action) => {
            state.loading = false;
            state.FlexValueSetList.push(action.payload)
          },
          [insertFlexValueSet.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
          },
        
        
    
    },
  });

  export default FlexValueSetSlice.reducer;