import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';


const POLARCHART = () => {
    useEffect(() => {
        var themeColorRgb = (getComputedStyle(document.body).getPropertyValue('--bs-theme-rgb')).trim();
        var gray800Rgb = (getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb')).trim();
        var gray500Rgb = (getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb')).trim();
        var gray300Rgb = (getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb')).trim();
        var inverseRgb = (getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb')).trim();
        
        new Chart(document.getElementById('polarAreaChart'), {
          type: 'polarArea',
          data: {
            datasets: [{
              data: [11, 16, 7, 3, 14],
              backgroundColor: ['rgba('+ themeColorRgb +', .5)', 'rgba('+ inverseRgb +', .2)' +', .5)', 'rgba('+ gray300Rgb+', .5)', 'rgba('+ gray500Rgb +', .5)', 'rgba('+ gray800Rgb +', .5)'],
              borderWidth: 0
            }],
            labels: ['NVC Head Office', 'SLE', 'NVR Takhassusi', 'NVR WAHA', 'SLE-Riyadh']
          }
        });
        
        // eslint-disable-next-line
      }, []);
  return (
    <div>
    <canvas id="polarAreaChart"></canvas>
  </div>
  )
}

export default POLARCHART