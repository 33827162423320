import { useFormik } from "formik";
import React from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import {
  AddDynamicForm,
} from "../../../../store/NTX/FlexValue/Form/formSlice";
// import {
//   DynamicFormSchema,
//   formJob,
// } from "../../ValidationForm/validationSchema";
import { useTranslation } from "react-i18next";

import CustomAlert from "../.././../../components/Alert/CustomAlert.tsx";
import { DataIcons } from "../../../../assets/icons";
import useGetStaticFormList from "../../../../hooks/Forms/use-get-StaticForm";
// import {
//   GetTablesForm,
// } from "../../../store/FlexValue/flexValueSetSlice";
import useGetExtraInformationRecordTablesForm from "../../../../hooks/Forms/useGetExtraInformationRecordTablesForm";
import useLookupModule from "../../../../hooks/Lookups/use-Lookup-Module";
import { DynamicFormRecordSchema, DynamicFormSchema } from "../../../ValidationForm/validationSchema";

const AddExtraRecordsForm = ({ Idmodul }) => {
  const { StaticFormList } = useGetStaticFormList();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { Module } = useLookupModule();
 const { dataExtraInformationRecordTablesFormList} = useGetExtraInformationRecordTablesForm()
  


  const formik = useFormik({
    initialValues: {
      isStatic: false,
      name2: "",
      name: "",
      icon: "",
      moduleId: "",
      dynamicFormTypesId : 3, // 3 Extra Records
      tableId : ""
    },
    validationSchema: DynamicFormRecordSchema(t),
    onSubmit: (values) => {
      console.log(values);

      dispatch(
        AddDynamicForm({
          name2: values.name2,
          name: values.name,
          moduleId: values.moduleId,
          icon: values.icon,
          isStatic: values.isStatic,
          dynamicFormTypesId : 3,// 3 Extra Records
          tableId : values.tableId
        })
      )
        .unwrap()
        .then(() => {
          document.getElementById("closeModalForm").click();
          formik.resetForm();
          CustomAlert({ action: "Add" });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });




  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Arabic Name")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={formik.touched.name2 && formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("English Name")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={formik.touched.name && formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name2}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Application')}</label>
            <Form.Select
              className="form-select"
              name="moduleId"
              value={formik.values.moduleId}
              onChange={formik.handleChange}
              isInvalid={formik.touched.moduleId && formik.errors.moduleId}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Module &&
                Module.map((item, idx) => (
                  <option key={++idx} value={item.id}>
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.moduleId}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">

      <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('Icon')}</label>

            <Select
              classNamePrefix="react-select"
              // classNamePrefix="select"
              isSearchable={true}
              // required
              options={DataIcons}
              onChange={(option) => formik.setFieldValue("icon", option.value)}
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
              {formik.errors.icon && formik.touched.icon && (
                <div style={{ color: "red" }}>{formik.errors.icon}</div>
              )}
          </div>
        </div>



        <div className="col-xl-6">
      <div className="form-group mb-3">
        <label className="form-label">
          Tables
        </label>
        <Select
              classNamePrefix="react-select"
              // classNamePrefix="select"
              isSearchable
              // required
              options={dataExtraInformationRecordTablesFormList}
              onChange={(option) => formik.setFieldValue("tableId", option.value)}
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
              {formik.errors.tableId && formik.touched.tableId && (
                <div style={{ color: "red" }}>{formik.errors.tableId}</div>
              )}
      </div>
        </div>
        
   




      </div>

      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-theme mb-1"
        >
          <i className="fa fa-save fa-fw me-1"></i> {t("Save")}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalForm"
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
        <button
          type="button"
          onClick={formik.resetForm}
          className="btn me-1 btn btn-danger mb-1"
        >
          <i className="fa fa-trash-alt fa-fw me-1"></i>
          {t("Clear")}
        </button>
      </div>
    </Form>
  );
};

export default AddExtraRecordsForm;
