import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../../components/card/card";
// import Loader from '../../../../../components/Loader/Loader';
// import { GetAbsenceAttendanceView } from '../../../../../store/Person/absenceSlice';
import ViewAbsence from "./ViewAbsence";

const Index = () => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                href="#/"
                onClick={() => Navigate("/HR/Employees", { replace: true })}
              >
                {t("Employees")}
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/" onClick={() => Navigate(-1, { replace: true })}>
                {t("Absence")}
              </a>
            </li>
            <li className="breadcrumb-item active">
              {t("Details of Absence")}
            </li>
          </ul>
          <h1 className="page-header mb-0">
            {/* {FormId ? t('Leave Request') : t('Absence')} */}
            {/* {isAme ? "": (i18n.language === "ar" ? data.name2 : data.name)} */}
            {t("Details of Absence")}
          </h1>
        </div>
      </div>

      <Card>
        <CardBody>
          <ViewAbsence />

          <div className="text-center">
            <button
              type="button"
              className=" btn me-1 btn-outline-default mb-1"
              onClick={() => Navigate(-1, { replace: true })}
            >
              <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
            </button>
          </div>
        </CardBody>
      </Card>

      <div className="mb-3"></div>

      <div />
    </>
  );
};

export default Index;
