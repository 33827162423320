import { replace, useFormik } from "formik";
import React, { useEffect } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import CustomAlert from "../../../components/Alert/CustomAlert";
import useGetPersons from "../../../hooks/HR/use-get-persons";
import {
  UpdatNtxPeoples,
  AddNtxPeoples,
  GetNtxPeoplesByID,
  cleanPeoplesById,
} from "../../../store/NTX/Peoples/peoplesSlice";
import { useNavigate, useParams, NavigateFunction } from "react-router-dom";
import { NtxPeopleSchema } from "../../ValidationForm/validationSchema";
import Loader from "../../../components/Loader/Loader";

interface Props {
  fieldList?: string[];
  typeCode: string;

  isUpdate: boolean;
}

const NtxPeoples: React.FC<Props> = (props) => {
  const { Id } = useParams();
  // const navigate = useNavigate()
  const navigate: NavigateFunction = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { dataEmployees, loading: personLoading } = useGetPersons();

  useEffect(() => {
    dispatch(cleanPeoplesById());
    if (Id && props.isUpdate) {
      dispatch(GetNtxPeoplesByID(Id));
    }
  }, [Id]);

  const { PeoplesById, loading } = useAppSelector((state) => state.Peoples);

  const formik = useFormik({
    initialValues: {
      id: PeoplesById?.id ?? null,
      name: PeoplesById?.name ?? "",
      name2: PeoplesById?.name2 ?? "",
      personId: PeoplesById?.personId ?? null,
      typeCode: props.typeCode,
      customerNumber: PeoplesById?.customerNumber ?? "",
      parentId: PeoplesById?.parentId ?? "",
      shipToLocationId: PeoplesById?.shipToLocationId ?? "",
      billToLocationId: PeoplesById?.billToLocationId ?? "",
      termsId: PeoplesById?.termsId ?? "",
      setOfBooksId: PeoplesById?.setOfBooksId ?? "",
      creditLimit: PeoplesById?.creditLimit ?? 0,
      paymentPriority: PeoplesById?.paymentPriority ?? "",
      invoiceCurrencyCode: PeoplesById?.invoiceCurrencyCode ?? "",
      paymentCurrencyCode: PeoplesById?.paymentCurrencyCode ?? "",
      invoiceAmountLimit: PeoplesById?.invoiceAmountLimit ?? "",
      paymentMethodLookupCode: PeoplesById?.paymentMethodLookupCode ?? "",
      bankAccountName: PeoplesById?.bankAccountName ?? "",
      bankAccountNum: PeoplesById?.bankAccountNum ?? "",
      bankNumber: PeoplesById?.bankNumber ?? "",
      bankAccountType: PeoplesById?.bankAccountType ?? "",
      vatCode: PeoplesById?.vatCode ?? 0,
      tradingName: PeoplesById?.tradingName ?? "",
      workReference: PeoplesById?.workReference ?? "",
      companyRegistrationNumber: PeoplesById?.companyRegistrationNumber ?? "",
      nationalInsuranceNumber: PeoplesById?.nationalInsuranceNumber ?? "",
      verificationNumber: PeoplesById?.verificationNumber ?? "",
      email: PeoplesById?.email ?? "",
      phone: PeoplesById?.phone ?? "",
      fax: PeoplesById?.fax ?? "",
      mobile: PeoplesById?.mobile ?? "",
      address: PeoplesById?.address ?? "",
      description: PeoplesById?.description ?? "",
      extraInformationId: PeoplesById?.extraInformationId ?? "",
    },
    enableReinitialize: true,
    validationSchema: NtxPeopleSchema(t),
    onSubmit: (values) => {
      const requestBody = {
        id: values.id,
        name: values.name,
        name2: values.name2,
        personId: values.personId,
        typeCode: values.typeCode,
        customerNumber: values.customerNumber,
        // parentId: values.parentId ,
        parentId: null,

        shipToLocationId: values.shipToLocationId || null,
        billToLocationId: values.billToLocationId || null,
        termsId: values.termsId || null,
        setOfBooksId: values.setOfBooksId || null,
        creditLimit: values.creditLimit,
        paymentPriority: values.paymentPriority || null,
        invoiceCurrencyCode: values.invoiceCurrencyCode,
        paymentCurrencyCode: values.paymentCurrencyCode,
        invoiceAmountLimit: values.invoiceAmountLimit || null,
        paymentMethodLookupCode: values.paymentMethodLookupCode,
        bankAccountName: values.bankAccountName,
        bankAccountNum: values.bankAccountNum,
        bankNumber: values.bankNumber,
        bankAccountType: values.bankAccountType,
        vatCode: values.vatCode,
        tradingName: values.tradingName,
        workReference: values.workReference,
        companyRegistrationNumber: values.companyRegistrationNumber,
        nationalInsuranceNumber: values.nationalInsuranceNumber,
        verificationNumber: values.verificationNumber,
        email: values.email,
        phone: values.phone,
        fax: values.fax,
        mobile: values.mobile,
        address: values.address,
        description: values.description,
        extraInformationId: values.extraInformationId || null,
      };

      const actionToDispatch = props.isUpdate
        ? UpdatNtxPeoples(requestBody)
        : AddNtxPeoples(requestBody);

      dispatch(actionToDispatch)
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            if (props.isUpdate) {
              CustomAlert({ action: "Edit" });
            } else {
              document.getElementById("closeModalForm")?.click();
              formik.resetForm();
              CustomAlert({ action: "Add" });
            }
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  var fieldList = ["typeCode", "customerNumber", "personId"];
  const FieldExist = {
    name: true,
    name2: true,
    typeCode: "",
    personId: fieldList?.some((x) => x === "personId"),
    customerNumber: fieldList?.some((x) => x === "customerNumber"),
    parentId: fieldList.some((x) => x === "parentId"),
    shipToLocationId: fieldList?.some((x) => x === "shipToLocationId"),
    billToLocationId: fieldList?.some((x) => x === "billToLocationId"),
    termsId: fieldList?.some((x) => x === "termsId"),
    setOfBooksId: fieldList?.some((x) => x === "setOfBooksId"),
    creditLimit: fieldList?.some((x) => x === "creditLimit"),
    paymentPriority: fieldList?.some((x) => x === "paymentPriority"),
    invoiceCurrencyCode: fieldList?.some((x) => x === "invoiceCurrencyCode"),
    paymentCurrencyCode: fieldList?.some((x) => x === "paymentCurrencyCode"),
    invoiceAmountLimit: fieldList?.some((x) => x === "invoiceAmountLimit"),
    paymentMethodLookupCode: fieldList?.some(
      (x) => x === "paymentMethodLookupCode"
    ),
    bankAccountName: fieldList?.some((x) => x === "bankAccountName"),
    bankAccountNum: fieldList?.some((x) => x === "bankAccountNum"),
    bankNumber: fieldList?.some((x) => x === "bankNumber"),
    bankAccountType: fieldList?.some((x) => x === "bankAccountType"),
    vatCode: fieldList?.some((x) => x === "vatCode"),
    tradingName: fieldList?.some((x) => x === "tradingName"),
    workReference: fieldList?.some((x) => x === "workReference"),
    companyRegistrationNumber: fieldList?.some(
      (x) => x === "companyRegistrationNumber"
    ),
    nationalInsuranceNumber: fieldList?.some(
      (x) => x === "nationalInsuranceNumber"
    ),
    verificationNumber: fieldList?.some((x) => x === "verificationNumber"),
    email: "",
    phone: "",
    fax: "",
    mobile: "",
    address: "",
    description: "",
    extraInformationId: "",
  };

  return (
    <div>
      <Loader loading={loading} />
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("Arabic Name")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name2"
                onChange={formik.handleChange}
                value={formik.values.name2}
                isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("English Name")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={!!(formik.touched.name && formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name2}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("Employee Name")}</label>

              {(!props.isUpdate || PeoplesById?.personId === null) && (
                <Select
                  classNamePrefix="react-select"
                  isLoading={personLoading}
                  isSearchable={true}
                  isClearable
                  name="personId"
                  options={dataEmployees}
                  onChange={(option: any) => {
                    formik.setFieldValue(
                      "personId",
                      option === null ? null : option.value
                    );
                  }}
                  placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                />
              )}
              {dataEmployees?.find(
                (f: any) => f?.value === PeoplesById?.personId
              ) && (
                <Select
                  classNamePrefix="react-select"
                  isLoading={personLoading}
                  isSearchable={true}
                  isClearable
                  name="personId"
                  options={dataEmployees}
                  onChange={(option: any) => {
                    formik.setFieldValue(
                      "personId",
                      option === null ? null : option.value
                    );
                  }}
                  placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                  defaultValue={dataEmployees?.find(
                    (f: any) => f?.value === PeoplesById?.personId
                  )}
                />
              )}

              {formik.errors.personId && formik.touched.personId && (
                <div style={{ color: "red" }}>{formik.errors.personId}</div>
              )}
            </div>
          </div>

          {FieldExist.parentId && (
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">parentId</label>
                <Form.Select
                  className="form-select"
                  name="parentId"
                  onChange={formik.handleChange}
                  value={formik.values.parentId}
                  isInvalid={
                    !!(formik.errors.parentId && formik.touched.parentId)
                  }
                >
                  <option value="">
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.parentId}
                </Form.Control.Feedback>
              </div>
            </div>
          )}

          {false && (
            <>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">customerNumber</label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="customerNumber"
                    onChange={formik.handleChange}
                    value={formik.values.customerNumber}
                    isInvalid={
                      !!(
                        formik.touched.customerNumber &&
                        formik.errors.customerNumber
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.customerNumber}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">shipToLocationId</label>
                  <Form.Select
                    className="form-select"
                    name="shipToLocationId"
                    onChange={formik.handleChange}
                    value={formik.values.shipToLocationId || ""}
                    isInvalid={
                      !!(
                        formik.errors.shipToLocationId &&
                        formik.touched.shipToLocationId
                      )
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.shipToLocationId}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">billToLocationId</label>
                  <Form.Select
                    className="form-select"
                    name="billToLocationId"
                    onChange={formik.handleChange}
                    value={formik.values.billToLocationId || ""}
                    isInvalid={
                      !!(
                        formik.errors.billToLocationId &&
                        formik.touched.billToLocationId
                      )
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.billToLocationId}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">termsId</label>
                  <Form.Select
                    className="form-select"
                    name="termsId"
                    onChange={formik.handleChange}
                    value={formik.values.termsId}
                    isInvalid={
                      !!(formik.errors.termsId && formik.touched.termsId)
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.termsId}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">setOfBooksId</label>
                  <Form.Select
                    className="form-select"
                    name="setOfBooksId"
                    onChange={formik.handleChange}
                    value={formik.values.setOfBooksId}
                    isInvalid={
                      !!(
                        formik.errors.setOfBooksId &&
                        formik.touched.setOfBooksId
                      )
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.setOfBooksId}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">creditLimit</label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="creditLimit"
                    onChange={formik.handleChange}
                    value={formik.values.creditLimit}
                    isInvalid={
                      !!(
                        formik.touched.creditLimit && formik.errors.creditLimit
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.creditLimit}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">paymentPriority</label>
                  <Form.Select
                    className="form-select"
                    name="paymentPriority"
                    onChange={formik.handleChange}
                    value={formik.values.paymentPriority}
                    isInvalid={
                      !!(
                        formik.errors.paymentPriority &&
                        formik.touched.paymentPriority
                      )
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.paymentPriority}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">invoiceCurrencyCode</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="invoiceCurrencyCode"
                    onChange={formik.handleChange}
                    value={formik.values.invoiceCurrencyCode}
                    isInvalid={
                      !!(
                        formik.touched.invoiceCurrencyCode &&
                        formik.errors.invoiceCurrencyCode
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.invoiceCurrencyCode}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">paymentCurrencyCode</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="paymentCurrencyCode"
                    onChange={formik.handleChange}
                    value={formik.values.paymentCurrencyCode}
                    isInvalid={
                      !!(
                        formik.touched.paymentCurrencyCode &&
                        formik.errors.paymentCurrencyCode
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.paymentCurrencyCode}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">invoiceAmountLimit</label>
                  <Form.Select
                    className="form-select"
                    name="invoiceAmountLimit"
                    onChange={formik.handleChange}
                    value={formik.values.invoiceAmountLimit}
                    isInvalid={
                      !!(
                        formik.errors.invoiceAmountLimit &&
                        formik.touched.invoiceAmountLimit
                      )
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.invoiceAmountLimit}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">paymentMethodLookupCode</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="paymentMethodLookupCode"
                    onChange={formik.handleChange}
                    value={formik.values.paymentMethodLookupCode}
                    isInvalid={
                      !!(
                        formik.touched.paymentMethodLookupCode &&
                        formik.errors.paymentMethodLookupCode
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.paymentMethodLookupCode}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">bankAccountName</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="bankAccountName"
                    onChange={formik.handleChange}
                    value={formik.values.bankAccountName}
                    isInvalid={
                      !!(
                        formik.touched.bankAccountName &&
                        formik.errors.bankAccountName
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.bankAccountName}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">bankAccountNum</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="bankAccountNum"
                    onChange={formik.handleChange}
                    value={formik.values.bankAccountNum}
                    isInvalid={
                      !!(
                        formik.touched.bankAccountNum &&
                        formik.errors.bankAccountNum
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.bankAccountNum}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">bankNumber</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="bankNumber"
                    onChange={formik.handleChange}
                    value={formik.values.bankNumber}
                    isInvalid={
                      !!(formik.touched.bankNumber && formik.errors.bankNumber)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.bankNumber}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">bankAccountType</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="bankAccountType"
                    onChange={formik.handleChange}
                    value={formik.values.bankAccountType}
                    isInvalid={
                      !!(
                        formik.touched.bankAccountType &&
                        formik.errors.bankAccountType
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.bankAccountType}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">vatCode</label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="vatCode"
                    onChange={formik.handleChange}
                    value={formik.values.vatCode}
                    isInvalid={
                      !!(formik.touched.vatCode && formik.errors.vatCode)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.vatCode}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">tradingName</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="tradingName"
                    onChange={formik.handleChange}
                    value={formik.values.tradingName}
                    isInvalid={
                      !!(
                        formik.touched.tradingName && formik.errors.tradingName
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.tradingName}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">workReference</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="workReference"
                    onChange={formik.handleChange}
                    value={formik.values.workReference}
                    isInvalid={
                      !!(
                        formik.touched.workReference &&
                        formik.errors.workReference
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.workReference}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">
                    companyRegistrationNumber
                  </label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="companyRegistrationNumber"
                    onChange={formik.handleChange}
                    value={formik.values.companyRegistrationNumber}
                    isInvalid={
                      !!(
                        formik.touched.companyRegistrationNumber &&
                        formik.errors.companyRegistrationNumber
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.companyRegistrationNumber}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">nationalInsuranceNumber</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="nationalInsuranceNumber"
                    onChange={formik.handleChange}
                    value={formik.values.nationalInsuranceNumber}
                    isInvalid={
                      !!(
                        formik.touched.nationalInsuranceNumber &&
                        formik.errors.nationalInsuranceNumber
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.nationalInsuranceNumber}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">verificationNumber</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="verificationNumber"
                    onChange={formik.handleChange}
                    value={formik.values.verificationNumber}
                    isInvalid={
                      !!(
                        formik.touched.verificationNumber &&
                        formik.errors.verificationNumber
                      )
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.verificationNumber}
                  </Form.Control.Feedback>
                </div>
              </div>
            </>
          )}

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("Email")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                isInvalid={!!(formik.touched.email && formik.errors.email)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("Phone")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                isInvalid={!!(formik.touched.phone && formik.errors.phone)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("Fax")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="fax"
                onChange={formik.handleChange}
                value={formik.values.fax}
                isInvalid={!!(formik.touched.fax && formik.errors.fax)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.fax}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">{t("Mobile Number")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="mobile"
                onChange={formik.handleChange}
                value={formik.values.mobile}
                isInvalid={!!(formik.touched.mobile && formik.errors.mobile)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.mobile}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("Address")}</label>
              <Form.Control
                className="form-control"
                type="text"
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                isInvalid={!!(formik.touched.address && formik.errors.address)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.address}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("Note")}</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={1}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              <div></div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-2"></i>
            )}
            {t("Save")}
          </button>
          <button
            type="button"
            className="btn me-1 btn-default mb-1"
            data-bs-dismiss={props.isUpdate ? null : "modal"}
            onClick={props.isUpdate ? () => navigate(-1) : undefined}
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default NtxPeoples;
