import React, { useEffect, useState } from "react";

import Chart from "react-apexcharts";

// import { Card, CardBody } from "../card/card";

function Single_chart({ chartData }) {
  const [lineChartOptions, setLineChartOptions] = useState(
    getLineChartOptions()
  );

  const [data, setData] = useState();

  const Fundata = () => {
    if (chartData.type === "radialBar") {
      const max = Math.max(...chartData.data);
      const data = chartData?.data.map((a) => Math.ceil((a / max) * 100));
      setData(data);
      return data;
    } else {
      setData(chartData?.data);
    }
  };

  useEffect(() => {
    Fundata();
  }, []);

  // var lineChartData = [
  // 	{ name: 'High - 2021', data: [28, 29, 33, 36, 32, 32, 33]	},
  // 	{ name: 'Mid - 2021', data: [20, 19, 25, 30, 17, 20, 23]	},
  // 	{ name: 'Low - 2021', data: [12, 11, 14, 18, 17, 13, 13] }
  // ];

  function getLineChartOptions() {
    var themeColorRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme-rgb")
      .trim();
    var themeFont = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-font-family")
      .trim();
    var indigoRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-indigo-rgb")
      .trim();
    var pinkRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-pink-rgb")
      .trim();
    var orangeRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-warning-rgb")
      .trim();
    var inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    var inverseRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse-rgb")
      .trim();

    return {
      chart: { toolbar: { show: true } },

      plotOptions:
        chartData.type === "radialBar"
          ? {
              radialBar: {
                offsetY: -10,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                  margin: 5,
                  size: "30%",
                  background: "transparent",
                  image: undefined,
                },
                track: { background: "rgba(" + inverseRgb + ", .25)" },
                dataLabels: { name: { show: false }, value: { show: false } },
              },
            }
          : {},

      dataLabels: {
        dropShadow: { enabled: true, top: 1, left: 1, blur: 1, opacity: 1 },
      },
      stroke: { show: false },

      //colors: [ 'rgba('+ pinkRgb +', .75)',  'rgba('+ orangeRgb +', .75)',  'rgba('+ themeColorRgb +', .75)', 'rgba('+ inverseRgb +', .5)',  'rgba('+ indigoRgb +', .75)'],
      labels: chartData.labels,
      legend: { fontFamily: themeFont, labels: { colors: inverse } },
      title: {
        text: chartData.title,
        align: "center",
        style: {
          fontSize: "20px",
          fontWeight: "bold",
          fontFamily: themeFont,
          color: inverse,
        },
      },
      legend: {
        show: true,
        //floating: true,
        //position: 'left',
        //offsetX: 140,
        //	offsetY: 120,
        labels: { useSeriesColors: true },
        markers: { size: 0 },
        formatter: function (seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
        },
        itemMargin: { horizontal: 1 },
        fontFamily: themeFont,
      },
    };
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="row">
          <div className="col-xl-12">
            <div id="apexChartLineChart" className="mb-5">
              <Chart
                type={chartData.type}
                options={lineChartOptions}
                series={data}
              />
            </div>
            {/* 							
							<div id="apexChartColumnChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>COLUMN CHART</h6>
										<Chart type="bar" options={columnChartOptions} series={columnChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code2}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartAreaChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>AREA CHART</h6>
										<Chart type="area" options={areaChartOptions} series={areaChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code3}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartBarChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>BAR CHART</h6>
										<Chart type="bar" options={barChartOptions} series={barChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code4}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartMixedChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>MIXED CHART</h6>
										<Chart type="line" options={mixedChartOptions} series={mixedChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code5}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartCandlestickChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>CANDLESTICK CHART</h6>
										<Chart type="candlestick" options={candlestickChartOptions} series={candlestickChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code6}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartBubbleChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>BUBBLE CHART</h6>
										<Chart type="bubble" options={bubbleChartOptions} series={bubbleChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code7}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartScatterChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>SCATTER CHART</h6>
										<Chart type="scatter" options={scatterChartOptions} series={scatterChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code8}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartHeatmapChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>HEATMAP CHART</h6>
										<Chart type="heatmap" options={heatmapChartOptions} series={heatmapChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code9}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartPieChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>PIE CHART</h6>
										<Chart type="pie" options={pieChartOptions} series={pieChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code10}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartRadialBarChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>RADIAL BAR CHART</h6>
										<Chart type="radialBar" options={radialBarChartOptions} series={radialBarChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code11}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartRadarChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>RADAR CHART</h6>
										<Chart type="radar" options={radarChartOptions} series={radarChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code12}</Highlight>
									</div>
								</Card>
							</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Single_chart;
