import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  addPayroll,
  GetPaymentMethod,
} from "../../../../store/HR/Payroll/PayrollSlice";
import { FormAddPayrollSchema } from "../../../ValidationForm/validationSchema";
import CustomAlert from "../../../../components/Alert/CustomAlert";
import useGetPaymentMethod from "../../../../hooks/Payrolls/use-Get-PaymentMethod";




export const AddPayroll = () => {
  const dispatch = useDispatch();
  // Translations
  const { t, i18n } = useTranslation();



  const { PaymentMethodList } = useGetPaymentMethod()

  const formik = useFormik({
    initialValues: {
      name: "",
      name2: "",
      startDate: "",
      endDate: "",
      paymentMethodId: "",
      businessGroupId: null, ///////// ???
      numberOfYears: "",
      periodType: "",
      cutOffDate: "",
    },
    // validationSchema: FormAddPayrollSchema(t),
    onSubmit: (values) => {
      console.log(values);

      dispatch(
        addPayroll({
          name: values.name,
          name2: values.name2,
          startDate: values.startDate,
          endDate: values.endDate,
          paymentMethodId: values.paymentMethodId,
          businessGroupId: values.businessGroupId,
          numberOfYears: values.numberOfYears,
          periodType: values.periodType,
          cutOffDate: values.cutOffDate,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          document.getElementById("closeModalButton").click();
          CustomAlert({action: "Add"})

        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({action: "Info"})
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Arabic Name")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={!!formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">
              {/* {formik.errors.NATIONAL_IDENTIFIER} */}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("English Name")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={!!formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {/* {formik.errors.NATIONAL_IDENTIFIER} */}
            </Form.Control.Feedback>
          </div>
        </div>
                <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Type of duration")}</label>
            <Form.Select
              className="form-select"
              id="exampleFormControlSelect1"
              name="periodType"
              onChange={formik.handleChange}
              value={formik.values.periodType}
              isInvalid={!!formik.errors.periodType}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              <option value={"M"}>
                {" "}
                {i18n.language === "ar" ? "شهري" : "Monthly"}{" "}
              </option>
              <option value={"W"}>
                {" "}
                {i18n.language === "ar" ? "أسبوعي" : "Weekly"}{" "}
              </option>
            </Form.Select>

            <Form.Control.Feedback type="invalid">
              {/* {formik.errors.TITLE} */}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Start Date")}</label>
            <Form.Control
              type="date"
              className="form-control"
              name="startDate"
              onChange={formik.handleChange}
              value={formik.values.startDate}
              isInvalid={!!formik.errors.startDate}
            />

            <div>
              <Form.Control.Feedback type="invalid">
                {/* {formik.errors.START_DATE} */}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Date_To")}</label>
            <Form.Control
              type="date"
              className="form-control"
              name="endDate"
              onChange={formik.handleChange}
              value={formik.values.endDate}
              isInvalid={!!formik.errors.endDate}
            />

            <div>
              <Form.Control.Feedback type="invalid">
                {/* {formik.errors.START_DATE} */}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">cutOffDate</label>
            <Form.Control
              type="date"
              className="form-control"
              name="cutOffDate"
              onChange={formik.handleChange}
              value={formik.values.cutOffDate}
              isInvalid={!!formik.errors.cutOffDate}
            />

            <div>
              <Form.Control.Feedback type="invalid">
                {/* {formik.errors.START_DATE} */}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>


      </div>

      <div className="row">
      <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Number of years")}</label>
            <Form.Control
              className="form-control"
              type="number"
              name="numberOfYears"
              onChange={formik.handleChange}
              value={formik.values.numberOfYears}
              isInvalid={!!formik.errors.numberOfYears}
            />
            <Form.Control.Feedback type="invalid">
              {/* {formik.errors.NATIONAL_IDENTIFIER} */}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Payment method")}</label>
            <Form.Select
              className="form-select"
              id="exampleFormControlSelect1"
              name="paymentMethodId"
              onChange={formik.handleChange}
              value={formik.values.paymentMethodId}
              isInvalid={!!formik.errors.paymentMethodId}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {PaymentMethodList.map((item, idx) => (
                <option key={++idx} value={item.id}>
                  {i18n.language === "ar" ? item.name : item.name2}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {/* {formik.errors.TITLE} */}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Department")}</label>
            <Form.Select
              className="form-select"
              id="exampleFormControlSelect1"
              // name="TITLE"
              // onChange={formik.handleChange}
              // value={formik.values.TITLE}
              // isInvalid={!!formik.errors.TITLE}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              <option>كشف الرواتب الافتراضي للمزايا المتقدمة</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {/* {formik.errors.TITLE} */}
            </Form.Control.Feedback>
          </div>
        </div>

      </div>

      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-theme mb-1"
        >
          <i className="fa fa-save fa-fw me-1"></i>{" "}
          {formik.isSubmitting ? "..." : t('Button.Save')}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalButton"
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
        <button
          type="button"
          onClick={formik.resetForm}
          className="btn me-1 btn btn-danger mb-1"
        >
          <i className="fa fa-trash-alt fa-fw me-1"></i>
          {t("Clear")}
        </button>
      </div>
    </Form>
  );
};
