import * as Yup from "yup";



const createImgValidation = () => {
  return Yup.mixed()
  .notRequired()
  .test('fileSize', 'File size must be less than 1 MB', (value) => !value || value.size <= 1000000)
  .test('fileType', 'Only PNG and JPEG images are allowed', (value) =>
    !value || ['image/png', 'image/jpeg'].includes(value.type)
  )
};







export const formSchema = (t) => {
  return Yup.object().shape({
    NAME2: Yup.string().required(() => t("Required")),
    NAME: Yup.string().required(() => t("Required")),
    NATIONAL_IDENTIFIER: Yup.number()
      .typeError("must be a number")
      .required(() => t("Required"))
      .max(9999999999, "must be no more than 10 digits"),
    START_DATE: Yup.date().required(() => t("Required")),
    DATE_OF_BIRTH: Yup.date().required(() => t("Required")),
    MARITAL_STATUS: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    NATIONALITY: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    TITLE: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    COUNTRY_OF_BIRTH: Yup.string()
      // .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    TOWN_OF_BIRTH: Yup.string().required("Required"),
    GENDER: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    Religion: Yup.string().required(() => t("Required")),
  });
};

export const EditPersonSchema = (t) => {
  return Yup.object().shape({
    NAME2: Yup.string().required(() => t("Required")),
    NAME: Yup.string().required(() => t("Required")),
    NATIONAL_IDENTIFIER: Yup.number()
      .typeError("must be a number")
      .required(() => t("Required"))
      .max(9999999999, "must be no more than 10 digits"),
    START_DATE: Yup.date().required(() => t("Required")),
    DATE_OF_BIRTH: Yup.date().required(() => t("Required")),
    MARITAL_STATUS: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    NATIONALITY: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    TITLE: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    COUNTRY_OF_BIRTH: Yup.string()
      // .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    TOWN_OF_BIRTH: Yup.string().required("Required"),
    GENDER: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    Religion: Yup.string().required(() => t("Required")),

    // persoN_IMG: Yup.mixed()
    // .test(
    //   'fileSize',
    //   'File size must be less than 1 MB',
    //   (value) => value && value.size <= 1000000
    // )
    // .test(
    //   'fileType',
    //   'Only PNG and JPEG images are allowed',
    //   (value) =>
    //     value && ['image/png', 'image/jpeg'].includes(value.type)
    // ),
  });
};

export const AssignmentFormSchema = (t) => {
  return Yup.object().shape({
    gradeId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required("Required"),

    positionId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required("Required"),

    jobId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required("Required"),

    assignmentStatusTypeId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required("Required"),

    payrollId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required("Required"),

    locationId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required("Required"),

    supervisorId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required("Required"),

    organizationId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required("Required"),

    personGroupId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required("Required"),

    startDate: Yup.date()
      .required(() => t("Required"))
      .typeError(() => t("Invalid date format")),

    // endDate: Yup.date()
    // .required(() => t("Required"))
    // .typeError(() => t("Invalid date format"))
    // // .min(Yup.ref('dateStart'), () => t("يجب ان يكون أكبر ....")),
  });
};

export const formJob = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      // .min(5, "الاسم صغير جدا | the name is too short")
      // .max(250, "الاسم كبير جدا | the name is too long")
      .required(() => t("Required")),
    name: Yup.string()
      // .min(5, "الاسم صغير جدا | the name is too long")
      // .max(50, "الاسم كبير جدا | the name is too long")
      .required(() => t("Required")),
    comments: Yup.string(),
    // .min(2, "الاسم صغير جدا | the name is too long")
    // .max(50, "الاسم كبير جدا | the name is too long")
    // .required(() => t("Required")),
  });
};

export const formGpoupEmp = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      // .min(5, "الاسم صغير جدا | the name is too short")
      // .max(250, "الاسم كبير جدا | the name is too long")
      .required(() => t("Required")),
    name: Yup.string()
      // .min(5, "الاسم صغير جدا | the name is too long")
      // .max(50, "الاسم كبير جدا | the name is too long")
      .required(() => t("Required")),

    businessGroupId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(),

    comments: Yup.string(),
    // .min(2, "الاسم صغير جدا | the name is too long")
    // .max(50, "الاسم كبير جدا | the name is too long")
    // .required(() => t("Required")),
  });
};

export const formOrganization = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t("Required")),
    name2: Yup.string().required(() => t("Required")),
    locationId: Yup.string().required(() => t("Required")),
    type: Yup.string().required(() => t("Required")),
    dateFrom: Yup.date()
      .required(() => t("Required"))
      .typeError(() => t("Invalid Date")),
  });
};

export const formLocation = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .min(5, "الاسم صغير جدا | the name is too short")
      .max(250, "الاسم كبير جدا | the name is too long")
      .required(() => t("Required")),
    name: Yup.string()
      .min(5, "الاسم صغير جدا | the name is too long")
      .max(50, "الاسم كبير جدا | the name is too long")
      .required(() => t("Required")),

    businessGroupId: Yup.string().required(() => t("Required")),

    shipToLocationId: Yup.string().required(() => t("Required")),

    country: Yup.string().required(() => t("Required")),
  });
};

export const formPosition = (t) => {
  return Yup.object().shape({
    jobId: Yup.string()
      .required(() => t("Required"))
      .notOneOf(["إختر", "Choose"], "Required"),
    organizationId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),

    locationId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),

    name: Yup.string().required(() => t("Required")),

    name2: Yup.string().required(() => t("Required")),
  });
};

export const FormPayrollElement = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t("Required")),

    name2: Yup.string().required(() => t("Required")),

    currencyCode: Yup.string().required(() => t("Required")),

    classificationId: Yup.string().required(() => t("Required")),

    uom: Yup.string().required(() => t("Required")),
  });
};

export const FormAddPayrollSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t("Required")),

    name2: Yup.string().required(() => t("Required")),

    // businessGroupId: Yup.string()
    // .notOneOf(["إختر", "Choose"], "Required")
    // .required(() => t("Required")),

    paymentMethodId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),

    periodType: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),

    startDate: Yup.date().required(() => t("Required")),

    endDate: Yup.date().required(() => t("Required")),

    numberOfYears: Yup.number().required(() => t("Required")),
  });
};

export const DynamicFormSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .required(() => t("Required")),
    name: Yup.string().required(() => t("Required")),

    //  urlStaticForm: Yup.string()
    //     .required(() => t("Required")),

    id: Yup.string().when("isStatic", {
      is: true,
      then: ()=> Yup.string().required(() => t("Required")),
    }),
  });
};



export const DynamicFormRecordSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      .required(() => t("Required")),
    name: Yup.string().required(() => t("Required")),

    // tableId: Yup.string().required(() => t("Required")),
    icon: Yup.string().required(() => t("Required")),

    tableId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    moduleId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),

  });
};


export const EditDynamicFormSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string()
      // .min(5, "الاسم صغير جدا | the name is too short")
      // .max(250, "الاسم كبير جدا | the name is too long")
      .required(() => t("Required")),
    name: Yup.string().required(() => t("Required")),
  });
};

export const AddFlexValueSetSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string().required(() => t("Required")),
    name: Yup.string().required(() => t("Required")),
    // tableName: Yup.string()
    // .notOneOf(["إختر", "Choose"], "Required")
    // .required(() => t("Required")),
  });
};

export const AddFormColumnSchema = (t) => {
  return Yup.object().shape({
    lable: Yup.string().required(() => t("Required")),
    lable2: Yup.string().required(() => t("Required")),
    sequenceNum: Yup.number().required(() => t("Required")),
    flexValueSetId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
  });
};

export const AddFlexValueDynamicSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string().required(() => t("Required")),
    name: Yup.string().required(() => t("Required")),

    tableId: Yup.string().when("radioValue", {
      is: "T",
      then: ()=> Yup.string().required(() => t("Required")),
    }),

    lookupType: Yup.string().when("radioValue", {
      is: "L",
      then: ()=> Yup.string().required(() => t("Required")),
    }),

    lookupCode: Yup.string().when("tableId", {
      is: value => ["045a58ed-782f-4b4f-a33e-4ffcee9e80f3","6121dd5c-aa4c-4832-a561-3fdfd0e15ba4"].includes(value),
      then: ()=> Yup.string().required(() => t("Required")),
    }),
  });
};

export const formAddUserSchema = (t) => {
  return Yup.object().shape({
    usersName: Yup.string().required(() => t("Required")),

    emailAddress: Yup.string().email(() => t("ايمبل غير صالح")),

    referenceId: Yup.string().required(() => t("Required")),

    usersMobile: Yup.string().matches(
      /^([+][0-9]{1,3}[-\s]?|[0][-\s]?|[0-9]{2,4}[-\s]?)[0-9]{7,14}$/,
      () => t("رقم جوال غير صالح")
    ),
    userTypeId: Yup.string()
      .required(() => t("Required"))
      .notOneOf(["إختر", "Choose"], "Required"),

    // startDate: Yup.date()
    // .required(() => t("Required"))
    // .typeError(() => t("Invalid date format")),

    // endDate: Yup.date()
    // .required(() => t("Required"))
    // .typeError(() => t("Invalid date format"))
    // .min(Yup.ref('startDate'), () => t("يجب ان يكون أكبر ....")),
  });
};

export const formResponsibilitySchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string().required(() => t("Required")),
    name: Yup.string().required(() => t("Required")),

    icon: Yup.string().notOneOf(["إختر", "Choose"], "Required").required(),
  });
};

export const AddPayProposalSchema = (t) => {
  return Yup.object().shape({
    proposedSalaryN: Yup.number()
      .typeError("Please enter a valid number")
      .required("Number is required"),

    proposalReason: Yup.string().required(() => t("Required")),

    changeDate: Yup.string().required(() => t("Required")),
  });
};

export const AddPayrollElementEntrySchema = (t) => {
  return Yup.object().shape({
    elementLinkId: Yup.string().required(() => t("Required")),

    // elementTypeId: Yup.string()
    // .required(() => t("Required"))
    // .notOneOf(["إختر", "Choose"], "Required"),

    screenEntryValue: Yup.string().required(() => t("Required")),

    startDate: Yup.date()
      .required(() => t("Required"))
      .typeError(() => t("Invalid date format")),

    endDate: Yup.date().when("isRecurring", {
      is: "false",
      then: Yup.date()
        .required(() => t("Required"))
        .typeError(() => t("Invalid date format"))
        .min(Yup.ref("startDate"), () => t("يجب ان يكون أكبر ....")),
    }),
  });
};

export const formAbsenseSchema = (t) => {
  return Yup.object().shape({
    authorisingPersonId: Yup.string().required(() => t("Required")),

    replacementPersonId: Yup.string().required(() => t("Required")),

    // absenceAttendanceTypeId: Yup.string().required("نوع الغياب مطلوب"), // Example validation rule for GUID select field

    absenceAttendanceTypeId: Yup.string().required(() => t("Required")),

    dateStart: Yup.date()
      .required(() => t("Required"))
      .typeError(() => t("Invalid date format")),

    dateEnd: Yup.date()
      .required(() => t("Required"))
      .typeError(() => t("Invalid date format"))
      .min(Yup.ref("dateStart"), () => t("يجب ان يكون أكبر ....")),
  });
};

export const formRaqeebSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t("Required")),

    name2: Yup.string().required(() => t("Required")),

    count_Fingerprint: Yup.string().required(() => t("Required")),

    allowIn: Yup.string().required(() => t("Required")),

    allowOut: Yup.string().required(() => t("Required")),

    startDate: Yup.date()
      .required(() => t("Required"))
      .typeError(() => t("Invalid date format")),

    endDate: Yup.date()
      .required(() => t("Required"))
      .typeError(() => t("Invalid date format"))
      .min(Yup.ref("startDate"), () => t("يجب ان يكون أكبر ....")),
  });
};
export const AddFlexValueSchema = (t) => {
  return Yup.object().shape({
    name2: Yup.string().required(() => t("Required")),
    name: Yup.string().required(() => t("Required")),
    parentId: Yup.string().required(() => t("Required")),

    // .notOneOf(["إختر", "Choose"], "Required"),
  });
};

export const AddRealEstateSchema = (t) => {
  return Yup.object().shape({
    // latitude: Yup.string().required('Latitude is required'),
    // longitude: Yup.string().required('Longitude is required'),
    name: Yup.string().required(() => t("Required")),
    name2: Yup.string().required(() => t("Required")),

    code: Yup.string(),

    annual_rent: Yup.number()
      .typeError("Capacity must be a valid number")
      .required(() => t("Required")),

    watermeter: Yup.string().required(() => t("Required")),

    gascounter: Yup.string().required(() => t("Required")),

    electricitymeter: Yup.string().required(() => t("Required")),

    attitudesmeter: Yup.string().required(() => t("Required")),

    number_meter: Yup.number()
      .typeError("Capacity must be a valid number")
      .required(() => t("Required")),

    // capacity: Yup.number().required(() => t("Required")),

    capacity: Yup.number()
      .typeError("Capacity must be a valid number")
      .required(() => t("Required")),

    // description: Yup.string(),
    // country: Yup.string(),
    // address_line: Yup.string(),
    // town_or_city: Yup.string(),
    // postal_code: Yup.string(),
    // region: Yup.string(),
    // telephone_number: Yup.string(),
    // extraInformationId: Yup.string(),
    realEstateTypeId: Yup.string().required(() => t("Required")),
    // organizationId: Yup.string().required(() => t("Required")),

    parent_id: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),


    organizationId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
  });
};

export const AddLookupSchema = (t) => {
  return Yup.object().shape({
    meaning2: Yup.string().required(() => t("Required")),
    meaning: Yup.string().required(() => t("Required")),
    lookupCode: Yup.string().required(() => t("Required")),
    lookupType: Yup.string().required(() => t("Required")),
    description: Yup.string().required(() => t("Required")),
    // enabledFlag: Yup.string().required(() => t("Required")),Boolean
  });
};

export const AddContractSchema = (t) => {
  return Yup.object().shape({

    organization_id:  Yup.string().notOneOf(["إختر", "Choose"], "Required").required(() => t("Required")),
    contract_status:  Yup.string().notOneOf(["إختر", "Choose"], "Required").required(() => t("Required")),
  
    code: Yup.string().required(() => t("Required")),

    contrcat_date: Yup.date().required(() => t("Required")),

    purpose: Yup.string().required(() => t("Required")),

    annual_rent: Yup.number().required(() => t("Required")),

    amount_insurance: Yup.number().required(() => t("Required")),
    contract_period: Yup.string().required(() => t("Required")),
    period_from_date: Yup.date().required(() => t("Required")),
    period_to_date: Yup.date().required(() => t("Required")),

    payment_type:  Yup.string().notOneOf(["إختر", "Choose"], "Required").required(() => t("Required")),

    amount_services: Yup.number().required(() => t("Required")),
    fees_office: Yup.number().required(() => t("Required")),
    // description: Yup.string().required(() => t("Required")),
    // installment_value: Yup.number().required(() => t("Required")),
    represented: Yup.string().required(() => t("Required")),
    installment_count: Yup.number().required(() => t("Required")),
    // currency_id: Yup.string().required(() => t("Required")),
    // exchange_rate: Yup.number().required(() => t("Required")),
    // vat_rate: Yup.number().required(() => t("Required")),
    // vat_amount: Yup.string().required(() => t("Required")),
    // ejar_code: Yup.string().required(() => t("Required")),
  });
}






// Fleet System
// export const formAddModelFLeetSchema = (t) => {
//   return Yup.object().shape({
//     name: Yup.string().required(() => t("Required")),

//     name2: Yup.string().required(() => t("Required")),

//     seatsNo: Yup.number().required(() => t("Required")),
//     doorsNo: Yup.number().required(() => t("Required")),
//     horsepower: Yup.number().required(() => t("Required")),
//     horsepowerTax: Yup.number().required(() => t("Required")),


    
//     motionVector: Yup.string()
//       .required(() => t("Required"))
//       .notOneOf(["إختر", "Choose"], "Required"),
//     fuelType: Yup.string()
//       .required(() => t("Required"))
//       .notOneOf(["إختر", "Choose"], "Required"),
//     manufCountry: Yup.string()
//       .required(() => t("Required"))
//       .notOneOf(["إختر", "Choose"], "Required"),
//     parentId: Yup.string()
//       .required(() => t("Required"))
//       .notOneOf(["إختر", "Choose"], "Required"),

//     catagory: Yup.string()
//       .required(() => t("Required"))
//       .notOneOf(["إختر", "Choose"], "Required"),



// manufYear: Yup.string().required(() => t("Required")),
// barcode: Yup.string().required(() => t("Required")),


//   });
// };


export const AddEquipmentsModelFLeetSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t("Required")),

    name2: Yup.string().required(() => t("Required")),

  });
};

export const AddWorkShopFLeetSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t("Required")),

    name2: Yup.string().required(() => t("Required")),

  });
};

export const EquipmentsSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t("Required")),
    chassisNo: Yup.string().required(() => t("Required")),
    name2: Yup.string().required(() => t("Required")),
    code: Yup.string().required(() => t("Required")),
    // barcode: Yup.string().required(() => t("Required")),
    serviceDate: Yup.string().required(() => t("Required")),
    dateOfExpiry: Yup.string().required(() => t("Required")),
    // lastmaintenanceDate: Yup.string().required(() => t("Required")),

    plateNo: Yup.string().required(() => t("Required")),
      serialNo: Yup.string().required(() => t("Required")),
      odometer: Yup.number()
      .typeError("must be a number")
      .required(() => t("Required"))
      .max(9999999999, "must be no more than 10 digits"),
      purchasePrice: Yup.number()
      .typeError("must be a number")
      // .required(() => t("Required"))
      .max(9999999999, "must be no more than 10 digits"),

    fleetModelId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
      organizationId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),

      engineStatus: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
      // ownerId: Yup.string()
      // .notOneOf(["إختر", "Choose"], "Required")
      // .required(() => t("Required")),
  });
};



export const EquipmentsAssignmentSchema = (t) => {
  return Yup.object().shape({
    deliveryDateFrom: Yup.string().required(() => t("Required")),
    // deliveryDateTo: Yup.string().required(() => t("Required")),

    peopleId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),

  });
};



export const EquipmentRoutesSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t("Required")),
    name2: Yup.string().required(() => t("Required")),
    startTime: Yup.string().required(() => t("Required")),
    endTime: Yup.string().required(() => t("Required")),



  });
};




export const EquipmentsOdometerSchema = (t) => {
  return Yup.object().shape({
    odometer: Yup.string().required(() => t("Required")),
    readDate: Yup.string().required(() => t("Required")),

    peopleId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),

  });
};
export const NtxPeopleSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t("Required")),
    name2: Yup.string().required(() => t("Required")),

  });
};




export const EquipmentsWorkRequestSchema = (t) => {
  return Yup.object().shape({

    requestCode: Yup.string().required(() => t("Required")),
    requestDate: Yup.string().required(() => t("Required")),
    requestTitle: Yup.string().required(() => t("Required")),
    personId: Yup.string().required(() => t("Required")),
    // source: Yup.string().required(() => t("Required")),

    phone: Yup.number()
    .typeError("must be a number")
    .required(() => t("Required")),

    priority: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    requestType: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    organizationId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    fleetEquipmentsId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),

  });
};



export const EquipmentsModelSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t("Required")),
    name2: Yup.string().required(() => t("Required")),
    barcode: Yup.string().required(() => t("Required")),


    parentId: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    manufYear: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    catagory: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    manufCountry: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    fuelType: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    motionVector: Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    horsepowerTax: Yup.number()
    .typeError("must be a number"),
    horsepower: Yup.number()
    .typeError("must be a number"),
    doorsNo: Yup.number()
    .typeError("must be a number")
    .required(() => t("Required")),
    seatsNo: Yup.number()
    .typeError("must be a number")
    .required(() => t("Required")),

    Img : createImgValidation()

  });
};










 





export const EquipmentsWorkOrderSchema = (t) => {
  return Yup.object().shape({
    orderCode: Yup.string().required(() => t("Required")),
    startCompletionSugg: Yup.string().required(() => t("Required")),
    endCompletionSugg: Yup.string().required(() => t("Required")),

    totalItems: Yup.number()
    .typeError("must be a number")
    .required(() => t("Required")),
    totalAsset: Yup.number()
    .typeError("must be a number")
    .required(() => t("Required")),
    otherAmounts: Yup.number()
    .typeError("must be a number")
    .required(() => t("Required")),

        fleetEquipmentsId: Yup.string()
    .notOneOf(["إختر", "Choose"], "Required")
    .required(() => t("Required")),

    workshopId: Yup.string().when("isInternal", {
      is: "1",
      then: ()=> Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required"))
    }),


    supplierId: Yup.string().when("isInternal", {
      is: "0",
      then: ()=> Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required")),
    }),
  });
};

export const UpdateWorkOrderSchema = (t) => {
  return Yup.object().shape({
     dateCompletion: Yup.string().required(() => t("Required")),
     startDateExecution: Yup.string().required(() => t("Required")),
     noteCompletion: Yup.string().required(() => t("Required")),
     endDateExecution: Yup.string().required(() => t("Required")),


    totalBudgetActual: Yup.number()
    .typeError("must be a number")
    .required(() => t("Required")),
    otherAmountsActual: Yup.number()
    .typeError("must be a number")
    .required(() => t("Required")),
    totalAssetActual: Yup.number()
    .typeError("must be a number")
    .required(() => t("Required")),
     totalItemsActual: Yup.number()
    .typeError("must be a number")
    .required(() => t("Required")),

 

 


    
  });
};



 

export const InstallmentPaymentSchema = (t) => {
  return Yup.object().shape({
    paymentDate: Yup.string().required(() => t("Required")),

    PaymentMethodId: Yup.string()
    .notOneOf(["إختر", "Choose"], "Required")
    .required(() => t("Required")),

    bankReference: Yup.string().when("PaymentMethodId", {
      is: (val) => val !== "05fe55ee-ade9-4b4b-85f9-c1dbacf40a6b",
      then: ()=> Yup.string()
      .required(() => t("Required")),
    }),

    // currencyId: Yup.string()
    // .notOneOf(["إختر", "Choose"], "Required")
    // .required(() => t("Required")),

    bankId: Yup.string().when("PaymentMethodId", {
      is: (val) => val !== "05fe55ee-ade9-4b4b-85f9-c1dbacf40a6b",
      then: ()=> Yup.string()
      .notOneOf(["إختر", "Choose"], "Required")
      .required(() => t("Required"))
    }),

  });
};





export const AddFleetElementSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().required(() => t("Required")),
    name2: Yup.string().required(() => t("Required")),

    description: Yup.string().required(() => t("Required")),
    description2: Yup.string().required(() => t("Required")),
    sort: Yup.string().required(() => t("Required")),


    
    catagory: Yup.string()
    .notOneOf(["إختر", "Choose"], "Required")
    .required(() => t("Required")),
    priority: Yup.string()
    .notOneOf(["إختر", "Choose"], "Required")
    .required(() => t("Required")),


  });
};

 

export const AddFleetInspectionSchema = (t) => {
  return Yup.object().shape({
    scanningDate: Yup.string().required(() => t("Required")),
    // description: Yup.string().required(() => t("Required")),

    driverId: Yup.string()
    .notOneOf(["إختر", "Choose"], "Required")
    .required(() => t("Required")),


    details: Yup.array().of(
      Yup.object({
        value : Yup.boolean().required(() => t("Required")),
      })
    )
    


  });
};