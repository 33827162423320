import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import { Card, CardBody } from "../../../../components/card/card";
import {
  AddResponsibilityUser,
  GetResponsibilityUserByUserId,
} from "../../../../store/NTX/Security/responsibilityUserSlice";
import ResponsibilityUserTableList from "./ResponsibilityUserTableList";
import Loader from "../../../../components/Loader/Loader";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";

function ResponsibilityUser() {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { userId } = useParams();
  const dispatch = useDispatch();

  const { ResponsibilityUserList, loading } = useSelector(
    (state) => state.ResponsibilityUser
  );
  useEffect(() => {
    dispatch(GetResponsibilityUserByUserId(userId));
  }, []);

  const formik = useFormik({
    initialValues: {
      userId: "",
      responsibilityId: "",
      isActive: true,
    },

    validationSchema: Yup.object().shape({
      responsibilityId: Yup.string()
        .notOneOf(["إختر", "Choose"], "Required")
        .required(() => t("Required")),
    }),
    onSubmit: (values) => {
      console.log(values);

      dispatch(
        AddResponsibilityUser({
          userId: userId,
          responsibilityId: values.responsibilityId,
          isActive: values.isActive,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: "Add" });
          dispatch(GetResponsibilityUserByUserId(userId));
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: "Error" });
          formik.setSubmitting(false);
        });
    },
  });

  //_______________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "users",
      link: "/Security/Users",
    },
    {
      name: "User Responsibility",
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="User Responsibility" />

      <Card>
        <CardBody>
          <Loader loading={loading} />
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Responsibility")}</label>
                  <Form.Select
                    className="form-select"
                    name="responsibilityId"
                    onChange={formik.handleChange}
                    value={formik.values.responsibilityId}
                    isInvalid={
                      formik.touched.responsibilityId &&
                      formik.errors.responsibilityId
                    }
                  >
                    <option value={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {ResponsibilityUserList &&
                      ResponsibilityUserList?.dropDown?.map((item, idx) => (
                        <option key={++idx} value={item.id}>
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.responsibilityId}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Active")}</label>
                  <Form.Check
                    style={{ margin: "10px 20px" }}
                    type="switch"
                    id="custom-switch"
                    label=""
                    name="isActive"
                    onChange={formik.handleChange}
                    value={formik.values.isActive}
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn me-1 btn-outline-theme mb-1"
                disabled={formik.isSubmitting}
              >
          {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t("Add")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-outline-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>

          <hr className="mb-4" />

          <div className="row">
            <div className="col-lg-12">
              {Object.keys(ResponsibilityUserList)?.length > 0 && (
                <ResponsibilityUserTableList
                  ResponsibilityUserList={ResponsibilityUserList}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default ResponsibilityUser;

// <table className="table">
// <thead className="table-dark">
//   <tr>
//     <th scope="col">الرقم</th>
//     <th scope="col">القيمة</th>
//     <th scope="col"></th>
//   </tr>
// </thead>

// <tbody>

//   {
//   ResponsibilityUserList?.table.map((item, idx) => (
//     <tr key={item.id}>
//       <td className="align-middle">{++idx}</td>
//       <td className="align-middle">
//         {i18n.language === "ar" ? item.name2 : item.name}
//       </td>
//       <td className="align-middle">
//         {/* <button onClick={()=> handleDeleteMember(item)} type="button" className="btn btn-outline-danger">
//           حذف
//         </button> */}
//         {/* <ConfirmDialog
//              onConfirm={()=>handleDeleteMember(item)}
//               title="Are you sure?"
//               message="This action cannot be undone."
//           /> */}

//       </td>
//     </tr>
//   ))
//   }

// </tbody>
// </table>
