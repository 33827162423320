

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
import { ScanElement } from "./fleetScanModels";




export const  GetScanningElementList = createAsyncThunk(
    "FleetScan/GetScanningElementList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Fleet/GetScanningElementList');

        return data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

export const  GetScanningElementById = createAsyncThunk(
    "FleetScan/GetScanningElementById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetScanningElementById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const AddScanningElement = createAsyncThunk('FleetScan/AddScanningElement',
   async (item: any , {rejectWithValue , dispatch} ) => {

    // debugger
    try {
      const {data} = await ntxAPI.post( "Fleet/AddScanningElement" , item)  
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


  export const UpdatScanningElement = createAsyncThunk('FleetScan/UpdatScanningElement',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/UpdatScanningElement" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });

  

  
const initialState: {
    ScanElementList : ScanElement[]
    ScanElement? : ScanElement
    loading: boolean;
    loadingById: boolean;
    error: any | null;
  } = {
    ScanElementList: [],
    loading: false,
    loadingById: false,
    error: null,
  };


  

const fleetScanSlice = createSlice({
    name: "FleetScan",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(AddScanningElement.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(AddScanningElement.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        if(action?.payload?.succeeded === true){
            state.ScanElementList.push(action.payload?.data); 
        }
      })
      .addCase(AddScanningElement.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetScanningElementList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetScanningElementList.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        if(action.payload.succeeded === true){
            state.ScanElementList = action.payload.data;
        }
      })
      .addCase(GetScanningElementList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



    .addCase(GetScanningElementById.pending, (state, action) => {
        state.loadingById = true;
      })
      .addCase(GetScanningElementById.fulfilled, (state, action: PayloadAction<ScanElement> ) => {
        state.loadingById = false;
        state.ScanElement = action.payload;
      })
      .addCase(GetScanningElementById.rejected, (state, action: PayloadAction<any>) => {
        state.loadingById = false;
        state.error = action.payload;
      })

      
    },
  });
  
  export default fleetScanSlice.reducer;