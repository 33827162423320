import React, { memo, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useLookupModule from "../../../../../../hooks/Lookups/use-Lookup-Module";
import { GetTableViewName } from "../../../../../../store/NTX/Reports/reportSlice";
import Select from "react-select";
import {DataIcons} from "../../../../../../assets/icons"
import * as yup from "yup"; //

const Step1 = ({ setTableViewName, setCheckValidationStep1 , setDataStep1 ,DataById }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { Module } = useLookupModule();
  const [tableViewList, setTableViewList] = useState([]);



  useEffect(() => {
    dispatch(GetTableViewName({ query: "SELECT 	name FROM 	sys.views " }))
      .unwrap()
      .then((res) => {
        setTableViewList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  //_______________________________________________
  // Define a validation schema using yup
  const validationSchema = yup.object().shape({
    name: yup.string().required(t("Name is required")),
    name2: yup.string().required(t("Name2 is required")),
    moduleId: yup.string().required(t("Module is required")),
    tableName: yup.string().required(t("Table Name is required")),
    pageSize: yup.string().required(t("page Size is required")),
  });
  const [formData, setFormData] = useState({
    name: "",
    name2: "",
    moduleId: "",
    tableName: "",
    pageSize: "",
    icon: "",
  });

  useEffect(() => {
    setFormData({
     name: DataById.name,
    name2: DataById.name2,
    moduleId: DataById.moduleId,
    tableName: DataById.tableName,
    pageSize: DataById.pageType,
    icon: DataById.icon,
    })
    setTableViewName(DataById.tableName);
  }, [DataById]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to check if all fields are filled
  const areAllFieldsFilled = () => {
    try {
      validationSchema.validateSync(formData, { abortEarly: false });
      return true; // All fields are filled
    } catch (error) {
      return false; // Some field is empty
    }
  };

  useEffect(() => {
    setCheckValidationStep1(areAllFieldsFilled());
    setDataStep1(formData)
  }, [formData]);
  

  // console.log(formData);
  return (
    <>
      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Arabic Name')}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              value={formData.name2 || ""}
              onChange={handleInputChange}
              autoComplete="false"
            />
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('English Name')}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              value={formData.name || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Application')}</label>
            <Form.Select
              className="form-select"
              name="moduleId"
              value={formData.moduleId || ""}
              onChange={handleInputChange}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Module &&
                Module.map((item, idx) => (
                  <option key={++idx} value={item.id}>
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
      </div>
 
      <div className="row">


        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Data_Source')}</label>
            <Form.Select
              className="form-select mb-3"
              name="tableName"
              value={formData.tableName || ""}
              onChange={(e) => {
                handleInputChange(e);
                setTableViewName(e.target.value);
              }}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {tableViewList &&
                tableViewList.map((item, idx) => (
                  <option key={++idx} value={item.name}>
                    {i18n.language === "ar" ? item.name : item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Layout')}</label>
            <Form.Select
              className="form-select mb-3"
              name="pageSize"
              value={formData.pageSize || ""}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              <option value="Landscape">   
               Landscape
              </option>
              <option value="Portrait">
               Portrait
              </option>
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Icon')}</label>

            <Select
              classNamePrefix="react-select"
              isSearchable={true}
              required
              options={DataIcons}
              onChange={(option) => setFormData({...formData, icon: option.value})}
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
              value={DataIcons?.filter(f => f.value === DataById?.icon)[0]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default memo(Step1) ;
