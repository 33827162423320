import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../../components/card/card";
import Loader from "../../../../../components/Loader/Loader";
import Pagination from "../../../../../components/Pagination/Pagination";
import {
  GetAssignmentActionList,
  GetPayrollActionList,
} from "../../../../../store/HR/Payroll/runPayrollSlice";

const AssignmentAction = () => {
  const { PayrollActionId } = useParams();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.runPayroll);
  const data = useSelector((state) => state.runPayroll.AssignmentActionList);

  console.log(data);
  useEffect(() => {
    dispatch(GetAssignmentActionList(PayrollActionId));
  }, []);

  //________________________________

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = data
    ?.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()))
    .slice(indexOfFirstData, indexOfLastData);

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  // report to xls
  const handleReport = () => {
    const csv = Papa.unparse({
      fields: ["id", "name"],
      data: data,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  //_______________________

  const handleOpenTab = (item) => {
    localStorage.setItem("personName2", item.name2);
    localStorage.setItem("personName", item.name);
    const newWindow = window.open(
      `/Report/${item.id}/PayrollForPerson`,
      "_blank",
      "width=1000,height=800"
    );
    newWindow.focus();
  };

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item">
              <a onClick={() => Navigate(-1, { replace: true })} href="#/">
                مسيــر الرواتب
              </a>
            </li>

            <li className="breadcrumb-item active">عرض المسيرات</li>
          </ul>
          <h1 className="page-header mb-0">
            {i18n.language === "ar"
              ? localStorage.PayrollName2
              : localStorage.PayrollName}{" "}
            {localStorage.MonthName}
          </h1>
        </div>
      </div>

      <Card>
        <CardBody>
          <Loader loading={loading} />
          <div className="tab-content p-4">
            <div className="tab-pane fade show active">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={handleSearch}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end">
                  <div className="btn-group">
                  <button
                onClick={() => Navigate(-1, { replace: true })}
                type="button"
                className=" btn me-1 btn-outline-default"
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
                    <button
                      type="button"
                      onClick={() => {
                        const newWindow = window.open(
                          `/Report/${PayrollActionId}/PayrollForAll`,
                          "_blank",
                          "width=1300,height=700"
                        );
                        newWindow.focus();
                      }}
                      className="btn btn-outline-lime"
                    >
                      <i className="fas fa-lg fa-fw me-2 fa-print"></i>
                      طباعة الكل
                    </button>
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">الأسم</th>
                      <th scope="col">الوظيفة</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      currentData.map((item) => (
                        <tr key={item.id}>
                          <td className="align-middle">
                            {i18n.language === "ar" ? item.name2 : item.name}
                          </td>
                          <td className="align-middle">
                            {i18n.language === "ar"
                              ? item.jobName2
                              : item.jobName}
                          </td>
                          <td>
                            <div className="btn-group">
                              <button
                                type="button"
                                // onClick={() => Navigate(`${item.id}/PayrollData`)}
                                // onClick={() => openInNewTab(`/Report/${item.id}/PayrollData`)}
                                // onClick={() => window.open( `/Report/${item.id}/PayrollForPerson`, '_blank')}
                                onClick={() =>handleOpenTab(item) }
                                className="btn btn-outline-lime"
                              >
                                <i className="fas fa-lg fa-fw me-2 fa-print"></i>
                                طباعة
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={data}
                // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                dataPerPage={dataPerPage}
                handlePagination={handlePagination}
                currentPage={currentPage}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AssignmentAction;
