import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ntxAPI } from "../../../../API/axios-custom";
import SkeletonLoader from "../../../../components/SkeletonLoader/SkeletonLine";

const TypeD = ({ item, handleSelectChange , value}) => {
  const { t, i18n } = useTranslation();
 

    const [valueSetList , setValueSetList] = useState([])

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      async function GetTableData() {
        try {
          const { data } = await ntxAPI.get(
            `FlexValueSet/GetFlexValueList?FlexValueSetId=${item.valueSetId}`
          );
          let DataList = data.map((item) => ({
            value: item.id,
            label: i18n.language === "ar" ? item.name2 : item.name,
          }));
          
          setValueSetList(DataList);
          setIsLoading(false);  
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);  
        }
      }
  
      GetTableData();
    }, [item.valueSetId]);
    

  return (
    <>
      <div className="form-group mb-3">
        <label className="form-label">
        {i18n.language === "ar" ? item.lableText2 : item.lableText}
        </label>

        <div className="row">
                  {isLoading ? ( 
          <SkeletonLoader/>
        ) : (
          (
           <Select
          classNamePrefix="react-select"
          isSearchable={true}
          options={valueSetList}
          name={item.dynamic_form_column_id }
          required={item.requiredFlag}
          onChange={handleSelectChange}
          placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
          value={valueSetList?.find(f => f.value === value)}
          // defaultValue={valueSetList.find(f => f.value === value)}
        />
  
        )
        )}
        </div>



      </div>
    </>
  );
};

export default TypeD;
