import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { getData, postData } from "../../Axios/axiosData";
import { ntxAPI } from "../../../API/axios-custom";

export const AddOrganizationStructure = createAsyncThunk(
  "OrganizationStructure/AddOrganizationStructure",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Organization/AddOrganizationStructure" , item);
      const {data} = await ntxAPI.post("Organization/AddOrganizationStructure" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetOrganizationStructure = createAsyncThunk(
  "OrganizationStructure/GetOrganizationStructure",
  async (item, thunkAPI) => {
    const { rejectWithValue  } = thunkAPI;


    try {
      // const data = await getData("Organization/GetOrganizationStructureList");
      const {data} = await ntxAPI.get( "Organization/GetOrganizationStructureList");
      return data;
    } catch (error) {
      console.error(error);
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetOrganizationWithoutStructure = createAsyncThunk(
  "OrganizationStructure/GetOrganizationWithoutStructure",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    console.log(item);

    try {
      // const data = await getData("Organization/GetOrganizationListWithoutStructure");
      const {data} = await ntxAPI.get("Organization/GetOrganizationListWithoutStructure" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  OrgStructureList: [],
  loading: false,
  error: null,
  OrgStructureListById: [],
  OrganizationListWithoutStructure: [],
};


const OrganizationStructureSlice = createSlice({
  name: "OrganizationStructure",
  initialState,
  reducers: {},
  extraReducers: {
    ///// get all Organizations
    [GetOrganizationStructure.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetOrganizationStructure.fulfilled]: (state, action) => {
      state.loading = false;
      state.OrgStructureList = action.payload;
    },
    [GetOrganizationStructure.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get one Org by id
    // [getOrganization.pending]: (state) => {
    //   state.loading = true;
    //   state.error = null;
    // },
    // [getOrganization.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.Organization = action.payload;
    // },
    // [getOrganization.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },

    ///////insert
    [AddOrganizationStructure.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [AddOrganizationStructure.fulfilled]: (state, action) => {
      state.loading = false;
      state.OrgStructureList.push(action.payload);
    },
    [AddOrganizationStructure.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


    /// Get Organization Without Structure 
    [GetOrganizationWithoutStructure.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetOrganizationWithoutStructure.fulfilled]: (state, action) => {
      state.loading = false;
      state.OrganizationListWithoutStructure = action.payload;
    },
    [GetOrganizationWithoutStructure.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default OrganizationStructureSlice.reducer;
