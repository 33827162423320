
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import variables from "../../../APIURL/variables";
// import { getData, postData } from "../../Axios/axiosData";
import { ntxAPI } from "../../../API/axios-custom";



export const GetUserTypes = createAsyncThunk("Users/GetUserTypes", async (_, thunkAPI) => {
    const { rejectWithValue   } = thunkAPI;
    
    try {
      // const data = await getData("Security/GetAuthUserTypesList");
      const {data} = await ntxAPI.get("Security/GetAuthUserTypesList" );
      return data;
    } catch (error) {
      console.log("rejectWithValue");
      return rejectWithValue(error.message);
    }
  });


export const getUsers = createAsyncThunk("Users/getUsers", async (_, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;
  

  try {
    // const data = await getData("Security/GetAuthUserList");
    const {data} = await ntxAPI.get("Security/GetAuthUserList" );
    return data;
  } catch (error) {
    console.log("rejectWithValue");
    return rejectWithValue(error.message);
  }
});

export const AddAuthUser = createAsyncThunk("Users/AddAuthUser", async (item, thunkAPI) => {
    const { rejectWithValue  } = thunkAPI;

    try {
      // const data = await postData("Security/AddAuthUser", item);
      const {data} = await ntxAPI.post("Security/AddAuthUser" , item)
      return data;
    } catch (error) {
      console.log("rejectWithValue");
      return rejectWithValue(error.message);
    }
  });

  export const getUserById = createAsyncThunk("Users/getUserById", async (id, thunkAPI) => {
    const { rejectWithValue ,getState  } = thunkAPI;

    try {
      // const data = await getData(`Security/GetAuthUserById?Id=${id}`);
      const {data} = await ntxAPI.get(`Security/GetAuthUserById?Id=${id}` );
      return data;
    } catch (error) {
      console.log("rejectWithValue");
      return rejectWithValue(error.message);
    }
  });

  export const UpdateAuthUser = createAsyncThunk("Users/UpdateAuthUser", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;


    try {
      // const data = await postData("Security/UpdateAuthUser", item);
      const {data} = await ntxAPI.post("Security/UpdateAuthUser" , item)
      return data;
    } catch (error) {
      console.log("rejectWithValue");
      return rejectWithValue(error.message);
    }
  });


  export const GetUserFunctionList = createAsyncThunk("Users/GetUserFunctionList", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    // try {
    //   // console.log(id);
    //   const res = await axios.get(variables.API_URL + `Security/GetUserFunctionListbyUserId?UserId=${id}` , {
    //     headers: {
    //       "Content-Type": "application/json ; charset=UTF-8",
    //       Authorization: `Bearer ${localStorage.Token}`,
    //     },
    //   });
    //   return res.data;
    // } catch (error) {
    //   console.log(error);
    //   return rejectWithValue(error.message);
    // }
    try {
      // const data = await getData(`Security/GetUserFunctionListbyUserId?UserId=${id}`);
      const {data} = await ntxAPI.get( `Security/GetUserFunctionListbyUserId?UserId=${id}`);
      return data;
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.message);
    }
  });


  export const AddUpdateUserFunction = createAsyncThunk("Users/AddUpdateUserFunction", async (item, thunkAPI) => {
    const { rejectWithValue  } = thunkAPI;

   
    try {
      // const data = await postData("Security/AddUpdateUserFunction", item);
      const {data} = await ntxAPI.post("Security/AddUpdateUserFunction" , item)
      return data;
    } catch (error) {
      console.log("rejectWithValue");
      return rejectWithValue(error.message);
    }
  });



  const initialState = { Users: [], UserFunctionList: [] , loading: false, error: null, User: [] , UserTypesList:[] };

const UserSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
   
  },
  extraReducers: {

     /////// get User Types
     [GetUserTypes.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetUserTypes.fulfilled]: (state, action) => {
        state.loading = false;
        state.UserTypesList = action.payload;
      },
      [GetUserTypes.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
   
    /////// get all Users
    [getUsers.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.Users = action.payload;
    },
    [getUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

     /////insert
    [AddAuthUser.pending]: (state) => {
      // state.loading = true;
      state.error = null;
    },
    [AddAuthUser.fulfilled]: (state, action) => {
      // state.loading = false;
      state.Users.push(action.payload);
    },
    [AddAuthUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


    // get one User by id
    [getUserById.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.User = [];
    },
    [getUserById.fulfilled]: (state, action) => {
      state.loading = false;
      state.User = action.payload;
    },
    [getUserById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


        // Get User Function List
        [GetUserFunctionList.pending]: (state) => {
          state.loading = true;
          state.error = null;
          state.UserFunctionList  = [];
        },
        [GetUserFunctionList.fulfilled]: (state, action) => {
          state.loading = false;
          state.UserFunctionList = action.payload;
        },
        [GetUserFunctionList.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },
   
    // edit 
    // [editJob.pending]: (state) => {
    //   state.loading = true;
    //   state.error = null;
    // },
    // [editJob.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.Job = action.payload;
    // },
    // [editJob.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },
  },
});

export default UserSlice.reducer;
