import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../../../components/Shared/Breadcrumb";
import TestComponent from "../../../../ExtraInformation/ExtraRecordsDynamicForm/BuilderExtraRecord/Tabs/TestComponent";
import { AppSettings } from "../../../../../config/app-settings";
import { useParams } from "react-router-dom";
import { GetFormRecordWithValue } from "../../../../../store/ExtraInformation/extraInformationSlice";

const ExtraRecordFormPerson = () => {
  const context = useContext(AppSettings);
  const { t, i18n } = useTranslation();
  const { PersonId } = useParams(); // ReferenceId
  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(true);
  //__________________________________________________
  const tableId = "cd5aa419-0e0b-48fe-a66f-f004d97964a2"; // جدول الموظفين

  useEffect(() => {
    if (refreshData) {
      dispatch(
        GetFormRecordWithValue({
          TableId: tableId,
          ReferenceId: PersonId,
        })
      );
      setRefreshData(false);
    }
  }, [refreshData]);
  const { FormRecordWithValueList, loading } = useSelector(
    (state) => state.ExtraInformation
  );

  useEffect(() => {
    context.setAppContentFullHeight(true);
    return function cleanUp() {
      context.setAppContentFullHeight(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Employees",
      link: "/HR/Employees",
    },
    {
      name:
        i18n.language === "ar"
          ? localStorage.ReferenceName2
          : localStorage.ReferenceName,
      link: null,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb BreadcrumbList={BcrumbList} />
      {/* PageName="السجلات الإضافية" */}

      <TestComponent
        setRefreshData={setRefreshData}
        FormRecordWithValueList={FormRecordWithValueList}
        ReferenceId={PersonId}
      />

    </Fragment>
  );
};

export default ExtraRecordFormPerson;
