/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import ViewFile from "../../../Attachments/ViewFile";
import { useTranslation } from "react-i18next";

const Attachment = (props) => {
  const [attachments, setAttachments] = useState([]);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState();
  const fileInputRef = useRef(null);

  const {t} = useTranslation()

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles);
  };

  const handleAddButtonClick = () => {
    if(files && description){
    const selectedFiles = Array.from(files || []);
    const newAttachment = {
      attachment: selectedFiles.map((file) => file), 
      description,
    };

    setAttachments([...attachments, newAttachment]);
    // setAttachments([
    //   ...attachments,
    //   ...selectedFiles.map((file) => ({ file, description })),
    // ]);
 
    setFiles([]);
    setDescription("");
    fileInputRef.current.value = null; 
     }
  };
 

  const onDeleteFile = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };


  useEffect(()=>{
    props.setAttachments(attachments)
  },[attachments])

  return (
    <div className="bg-inverse bg-opacity-10 rounded-4 pt-1">
      <div className="row">
        <div className="col-xl-5">
          <div className="form-group mb-3">
            <label className="form-label">{t('Attachment')}</label>
            <Form.Control
              className="form-control"
              type="file"
                // multiple
              onChange={(e) => handleFileChange(e)}
              ref={fileInputRef}
            />
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('Description')}</label>
            <Form.Control
              className="form-control"
              type="text"
              onChange={(e) => setDescription(e.target.value)}
              value={description || ""}
            />
          </div>
        </div>
        <div className="col-xl-1 pt-4">
          <button type="button" onClick={handleAddButtonClick} className="btn btn-primary">
          <i className="far fa-lg fa-fw fa-plus"></i>
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-9 mx-auto">
         
          <div className="table-responsive rounded-3">
            <table className="table  table-sm table-bordered">
              <thead className="bg-inverse bg-opacity-10 ">
                <tr>
                  <th className="pt-2 pb-2" style={{width : "50%"}}>المرفق</th>
                  <th className="pt-2 pb-2" style={{width : "45%"}}>الوصف</th>
                  <th className="pt-2 pb-2" style={{width : "5%"}}></th>
                </tr>
              </thead>

              <tbody>
                {attachments &&
                  attachments.map((item, index) => (
                    <tr key={index}>
                      <td className="align-middle" style={{width : "50%"}}>
                        {
                          item.attachment.map((file , fileidx) =>(
                            <ViewFile Attachment={file} key={fileidx} />
                          ) )
                        }
                        
                      </td>
                      <td className="align-middle" style={{width : "45%"}}>{item.description}</td>
                      <td className="align-middle" style={{width : "5%"}}>
                        <Link
                          to=""
                          style={{ color: "red", zIndex: "1000" }}
                          className="position-relative"
                          onClick={() => onDeleteFile(index)}
                        >
                          <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                {/* {attachments &&
                  attachments.map((item, index) => (
                    <tr key={index}>
                      <td className="align-middle" style={{width : "50%"}}>
                        <ViewFile Attachment={item.file} />
                      </td>
                      <td className="align-middle" style={{width : "45%"}}>{item.description}</td>
                      <td className="align-middle" style={{width : "5%"}}>
                        <Link
                          to=""
                          style={{ color: "red", zIndex: "1000" }}
                          className="position-relative"
                          onClick={() => onDeleteFile(index)}
                        >
                          <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                        </Link>
                      </td>
                    </tr>
                  ))} */}
              </tbody>
            </table>
          </div> 
        </div>
      </div>


    </div>
  );
};

export default Attachment;
