import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "../../../../components/card/card";
import Pagination from "../../../../components/Pagination/Pagination";
import Loader from "../../../../components/Loader/Loader";
import Loading from "../../../../components/Loading";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomAlert from "../../../../components/Alert/CustomAlert";
import {
  DeleteRealEstateAssignment,
  GetPersonListToAccommodationByRealEstateId,
} from "../../../../store/SU/realEstateSlice";
import ConfirmDeleteDialog from "../../../../components/Alert/ConfirmDeleteDialog";

const In = ({ List, loading }) => {
  const { Id } = useParams();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();

  const filteredSearch = List?.slice()?.filter((emp) => {
    const searchLowerCase = search.toLowerCase();
    return (
      emp.NAME2?.toLowerCase().includes(searchLowerCase) ||
      emp.NAME?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //____________________________________
  const [isSubmittibg, setIsSubmittibg] = useState(true);
  const dispatch = useDispatch();
  const handleDelete = async (RealEstateAssignmentId) => {
    //  debugger;
    await dispatch(DeleteRealEstateAssignment(RealEstateAssignmentId))
      .unwrap()
      .then((res) => {
        //  debugger;
        if (res.succeeded === true) {
          CustomAlert({ action: "Add" , Title: "تمت عملية الإستبعاد"  , msg:""});
          dispatch(GetPersonListToAccommodationByRealEstateId(Id));
          setIsSubmittibg(true);
          return;
        }
        setIsSubmittibg(true);
        CustomAlert({ action: "Error", msg: res.message });
      })
      .catch((error) => {
        setIsSubmittibg(true);
        CustomAlert({ action: "Error" });
      });
  };

  return (
    <div>
      <Card>
        <CardBody>
          <>
            <Loading loading={loading} SkeletonType="Table">
              <div className="row">
                <div className="col-5 col-md-6 text-start">المسكنين</div>
                <div className="col-7 col-md-6 d-flex justify-content-end">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t("Name")}</th>
                      <th scope="col">{t("Department")}</th>
                      <th scope="col">{t("Religion")}</th>
                      <th scope="col">{t("Nationality")}</th>
                      <th scope="col">{t("Distance")}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={index}>
                        <td className="align-middle">
                          {i18n.language === "en" ? item.NAME : item.NAME2}
                        </td>
                        <td className="align-middle">
                          {i18n.language === "en"
                            ? item.ORGANIZATION
                            : item.ORGANIZATION2}
                        </td>
                        <td className="align-middle">
                          {i18n.language === "en"
                            ? item.RELIGIONS
                            : item.RELIGIONS2}
                        </td>
                        <td className="align-middle">
                          {i18n.language === "en"
                            ? item.NATIONALITY
                            : item.NATIONALITY2}
                        </td>
                        <td className="align-middle">{item.DISTANCE}</td>
                        <td className="align-middle">
                          {/* <Link
                            style={{ color: "red" }}
                            onClick={() => handleDelete(item.REAL_ESTATE_ASSIGNMENT_ID)}
                          >
                            <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                          </Link> */}
                          <ConfirmDeleteDialog
                            onConfirm={() =>
                              handleDelete(item.REAL_ESTATE_ASSIGNMENT_ID)
                            }
                            title={`هل أنت متأكد من استبعاد هذا الموظف `}
                            // message="لا يمكن التراجع عن هذا الإجــراء."
                            iconType={2}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </Loading>
          </>
        </CardBody>
      </Card>
    </div>
  );
};

export default In;
