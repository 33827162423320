
// HR 
export const Positions = "Positions"
export const Grades= "Grades"
export const Employees= "Employees"
export const Jobs= "Jobs"
export const Assignments= "Assignments"
export const PayrollElement= "PayrollElement"
export const Payroll= "Payroll"
export const QuickPay= "QuickPay"
export const payrollSetting= "payrollSetting"
export const PayrollElementlink= "PayrollElementlink"
export const PayrollElementEntry= "PayrollElementEntry"
export const Dashboard= "Dashboard"
export const Absence= "Absence"
export const Locations= "Locations"
export const Departments= "Departments"
export const Organizationalstructure= "Organizationalstructure"
export const StaffGroup= "StaffGroup"
export const RotationPlan= "RotationPlan"
export const ExtraInformation= "ExtraInformation"

// AME

export const Conditions= "Conditions"
export const ApprovalGroups= "ApprovalGroups"
export const RequestSetting= "RequestSetting"
export const Rules= "Rules"
export const Requests= "Requests"
export const MyRequests= "MyRequests"





//SU
export const RealEstateContracts = "RealEstateContracts"



