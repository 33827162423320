import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import useGetPersons from "../../../hooks/HR/use-get-persons";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  GetFleetRequestType,
  GetLookupPRIORITY,
} from "../../../store/lookupSlice";
import useGetOrganizations from "../../../hooks/HR/use-get-organizations";
import { AddEquipmentsWorkRequests } from "../../../store/FLEET/EquipmentsWorkRequests/equipmentsWorkRequestSlice";
import CustomAlert from "../../../components/Alert/CustomAlert";
import { GetEquipmentsList } from "../../../store/FLEET/Equipments/equipmentsSlice";
import { EquipmentsWorkRequestSchema } from "../../ValidationForm/validationSchema";
import useGetEquipmentsList from "../../../hooks/FLeet/useGetEquipmentsList";

interface Props {
  isAme?: boolean;
  setReferenceId?: React.Dispatch<React.SetStateAction<string>>;
}

const WorkRequestForm: React.FC<Props> = (props) => {
  // const isAme = props.isAme !== undefined ? props.isAme : false;

  const { dataEmployees, loading } = useGetPersons();
  const { Id } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();

  useEffect(() => {
    dispatch(GetFleetRequestType());
    dispatch(GetLookupPRIORITY());
  }, []);
  const { FleetRequestType, PRIORITY } = useAppSelector(
    (state) => state.Lookup
  );

  const { EquipmentsList} = useGetEquipmentsList()

  const { Organizations } = useGetOrganizations();

  const formik = useFormik({
    initialValues: {
      fleetEquipmentsId: Id,
      requestCode: "",
      requestDate: "",
      requestTitle: "",
      requestType: "",
      customerId: null,
      personId: "",
      priority: "",
      source: "",
      odometer: 0,
      phone: "",
      email: "",
      description: "",
      organizationId: "",
      isAme: props.isAme,
    },
    validationSchema: EquipmentsWorkRequestSchema(t),
    onSubmit: async (values) => {
      console.log(values);

      dispatch(
        AddEquipmentsWorkRequests({
          fleetEquipmentsId: values.fleetEquipmentsId,
          requestCode: values.requestCode,
          requestDate: values.requestDate,
          requestTitle: values.requestTitle,
          requestType: values.requestType,
          customerId: null,
          personId: values.personId,
          priority: values.priority,
          source: values.source,
          odometer: values.odometer,
          phone: values.phone,
          email: values.email,
          description: values.description,
          organizationId: values.organizationId,
          isAme: values.isAme,
        })
      )
        .unwrap()
        .then(async (res) => {
          if (res?.succeeded === true) {
            if (props.setReferenceId) {
              await props.setReferenceId(res?.data?.id);
            }
            if (!props.isAme) {
              CustomAlert({ action: "Add" });
              formik.resetForm();
            }
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <div>
      {/* <Form onSubmit={formik.handleSubmit}> */}
      <div className="row">
        
        <div className="col-xl-2">
          <div className="form-group mb-3">
            <label className="form-label">{t("RequestNumber")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="requestCode"
              onChange={formik.handleChange}
              value={formik.values.requestCode}
              isInvalid={
                !!(formik.touched.requestCode && formik.errors.requestCode)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.requestCode}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-3">
          
          <div className="form-group mb-3">
            <label className="form-label">{t("Equipment")}</label>
            <Form.Select
              className="form-select"
              name="fleetEquipmentsId"
              onChange={formik.handleChange}
              value={formik.values.fleetEquipmentsId}
              isInvalid={
                !!(
                  formik.touched.fleetEquipmentsId &&
                  formik.errors.fleetEquipmentsId
                )
              }
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {EquipmentsList &&
                EquipmentsList.map((item: any, idx: number) => (
                  <option key={++idx} value={item.id}>
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.fleetEquipmentsId}
            </Form.Control.Feedback>
          </div>
        </div>


        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("RequestDescription")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="requestTitle"
              onChange={formik.handleChange}
              value={formik.values.requestTitle}
              isInvalid={
                !!(formik.touched.requestTitle && formik.errors.requestTitle)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.requestTitle}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("TypeMaintenance")}</label>
            <Form.Select
              className="form-select"
              name="requestType"
              onChange={formik.handleChange}
              value={formik.values.requestType}
              isInvalid={
                !!(formik.touched.requestType && formik.errors.requestType)
              }
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {FleetRequestType &&
                FleetRequestType.map((item: any, idx) =>
                  item.list.map((list: any) => (
                    <option key={++idx} value={list.lookupCode}>
                      {i18n.language === "ar" ? list.meaning2 : list.meaning}
                    </option>
                  ))
                )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.requestType}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("RequestDate")}</label>
            <Form.Control
              className="form-control"
              type="date"
              name="requestDate"
              onChange={formik.handleChange}
              value={formik.values.requestDate}
              isInvalid={
                !!(formik.touched.requestDate && formik.errors.requestDate)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.requestDate}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Employee Name")}</label>
            <Select
              classNamePrefix="react-select"
              isLoading={loading}
              isSearchable={true}
              isClearable
              name="personId"
              options={dataEmployees}
              onChange={(option: any) => {
                formik.setFieldValue(
                  "personId",
                  option === null ? null : option.value
                );
              }}
              // onChange={(option : any ) =>
              //   formik.setFieldValue("personId", option.value)
              // }

              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
            {formik.errors.personId && formik.touched.personId && (
              <div style={{ color: "red" }}>{formik.errors.personId}</div>
            )}
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label"> {t("Branch")}</label>
            <Form.Select
              className="form-select"
              name="organizationId"
              onChange={formik.handleChange}
              value={formik.values.organizationId || ""}
              isInvalid={
                !!(
                  formik.touched.organizationId && formik.errors.organizationId
                )
              }
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Organizations &&
                Organizations?.filter((f: any) => f.type === "150").map(
                  (item: any, idx: number) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </option>
                  )
                )}
            </Form.Select>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Odometer")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="odometer"
              onChange={formik.handleChange}
              value={formik.values.odometer}
              isInvalid={!!(formik.touched.odometer && formik.errors.odometer)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.odometer}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label"> {t("Priority")} </label>
            <Form.Select
              className="form-select"
              name="priority"
              onChange={formik.handleChange}
              value={formik.values.priority}
              isInvalid={!!(formik.touched.priority && formik.errors.priority)}
            >
              <option key={null}>
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {PRIORITY &&
                PRIORITY.map((item: any, idx) =>
                  item.list.map((list: any) => (
                    <option key={++idx} value={list.lookupCode}>
                      {i18n.language === "ar" ? list.meaning2 : list.meaning}
                    </option>
                  ))
                )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.priority}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t("Source")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="source"
              onChange={formik.handleChange}
              value={formik.values.source}
              isInvalid={!!(formik.touched.source && formik.errors.source)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.source}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Mobile Number")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="phone"
              onChange={formik.handleChange}
              value={formik.values.phone}
              isInvalid={!!(formik.touched.phone && formik.errors.phone)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.phone}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">{t("Email")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              isInvalid={!!(formik.touched.email && formik.errors.email)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Note")}</label>
            <Form.Control
              className="form-control"
              as="textarea"
              rows={1}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
            <div></div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="button"
          className="btn me-1 btn-theme mb-1"
          onClick={formik.submitForm}
        >
          {/* <i className="fa fa-save fa-fw me-1"></i> {t("Save")} */}
          {formik.isSubmitting ? (
            <div className="spinner-border spinner-border-sm me-2"></div>
          ) : (
            <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
          )}
          {t("Add")}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          onClick={() => Navigate(-1)}
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
      </div>
      {/* </Form> */}
    </div>
  );
};

export default WorkRequestForm;
