import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
// import { fetchData, getData, postData } from "../../Axios/axiosData";


const initialState = { RotationPlanList: [], RotationPlanListById:[] ,loading: false, error: null  };


export const AddRotationPlan = createAsyncThunk(
  "Raqeeb/AddRotationPlan",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
 
    try {
      // const data = await postData('RAQ/AddRotationPlan' , item);
      const {data} = await ntxAPI.post('RAQ/AddRotationPlan' , item)
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);


export const GetRotationPlan = createAsyncThunk(
    "Raqeeb/GetRotationPlan",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
 
      try {
        // const data = await getData('RAQ/GetRotationPlanList');
        const {data} = await ntxAPI.get('RAQ/GetRotationPlanList' );
        return data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );


  export const GetRotationPlanById = createAsyncThunk(
    "Raqeeb/GetRotationPlanById",
    async (id, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
    
      try {
        // const data = await getData(`RAQ/GetRotationPlanById?Id=${id}`, thunkAPI );
        const {data} = await ntxAPI.get(`RAQ/GetRotationPlanById?Id=${id}` );
        return data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );

  export const UpdateRotationPlan = createAsyncThunk(
    "Raqeeb/UpdateRotationPlan",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      console.log(JSON.stringify(item));
    
      try {
        // const data = await postData('RAQ/UpdateRotationPlan' , item);
        const {data} = await ntxAPI.post('RAQ/UpdateRotationPlan' , item)
        return data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );







const raqeebSlice = createSlice({
  name: "Raqeeb",
  initialState,
  reducers: {  },
  extraReducers: {
    //  Get Rotation Plan List
    [GetRotationPlan.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetRotationPlan.fulfilled]: (state, {payload}) => {
      state.loading = false;
      state.RotationPlanList = payload;
    },
    [GetRotationPlan.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get one Rotation plan by id
    [GetRotationPlanById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetRotationPlanById.fulfilled]: (state, action) => {
      state.loading = false;
      state.RotationPlanListById = action.payload;
    },
    [GetRotationPlanById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    ///////insert
    [AddRotationPlan.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [AddRotationPlan.fulfilled]: (state, action) => {
      state.loading = false;
      state.RotationPlanList.push(action.payload);
    },
    [AddRotationPlan.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Edit 
    [UpdateRotationPlan.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [UpdateRotationPlan.fulfilled]: (state, action) => {
      state.loading = false;
      state.RotationPlanListById = action.payload;
    },
    [UpdateRotationPlan.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default raqeebSlice.reducer;
