import React, { useEffect, useRef } from "react";
import { Card, CardBody } from "../../../../../../components/card/card";

// import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
// import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
// import "datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5.min.css";
// import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";

// import "./cssDataTable/buttons.bootstrap5.css"
// import "./cssDataTable/dataTables.bootstrap5.css"
// import "./cssDataTable/fixedColumns.bootstrap5.css"
// import "./cssDataTable/responsive.bootstrap5.css"

localStorage.language === 'ar' ? require('./cssDataTable/buttons.bootstrap5.css')  : require('datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css') ;
localStorage.language === 'ar' ? require('./cssDataTable/dataTables.bootstrap5.css')  : require('datatables.net-bs5/css/dataTables.bootstrap5.min.css') ;
localStorage.language === 'ar' ? require('./cssDataTable/fixedColumns.bootstrap5.css')  : require('datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5.min.css') ;
localStorage.language === 'ar' ? require('./cssDataTable/responsive.bootstrap5.css')  : require('datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css') ;

const $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs5");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis.min.js");
require("datatables.net-buttons/js/buttons.flash.min.js");
require("datatables.net-buttons/js/buttons.html5.min.js");
require("datatables.net-buttons/js/buttons.print.min.js");
require("datatables.net-buttons-bs5");
require("datatables.net-responsive");
require("datatables.net-responsive-bs5");
require("datatables.net-fixedcolumns");
require("datatables.net-fixedcolumns-bs5");

const DataTableViewer = ({ DataReport }) => {
  const Tableheaders = Object.keys(DataReport[0]);
  // console.log( JSON.stringify(DataReport) );
  const tableRef = useRef();
  useEffect(() => {
    $(tableRef.current).DataTable({
      dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
      lengthMenu: [10, 20, 30, 40, 50],
      responsive: true,
      data: DataReport,
      columns: Tableheaders?.map((header) => ({ data: header })), // Map columns to data properties
      buttons: [
        { extend: "print", className: "btn btn-outline-default btn-sm ms-2" },
        { extend: "csv", className: "btn btn-outline-default btn-sm" , charset: 'UTF-8' , bom:true},
      ],
    });

    return function cleanUp() {
      $(".dataTables_wrapper table").DataTable().destroy(true);
    };

    // eslint-disable-next-line
  }, []);


  return (
    <div style={{marginTop : "20px"}}>
        <table ref={tableRef} className="table table-bordered text-nowrap w-100 ">
          <thead>
            <tr>
              { Tableheaders?.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>

          <tbody></tbody>
        </table>
    </div>

  );
};

export default DataTableViewer;

// {
//     Object.keys(DataReport[0]).map((header , index) =>(
//         <th key={index}>{header}</th>
//     ))
// }
