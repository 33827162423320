import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import variables from "../APIURL/variables";


const ntxAPI = axios.create({
  // baseURL : "http://localhost:8089/api/",
  baseURL: variables.API_URL,
  headers: {
    Authorization: `Bearer  ${localStorage.Token}`,
  },
});

let isRefreshing = false;
let refreshQueue = [];

ntxAPI.interceptors.request.use(async (req) => {
  req.headers.Authorization = `Bearer ${localStorage.Token}`;
  const user = jwt_decode(localStorage.Token);
  const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

  if (!isExpired) {
    return req;
  }

  // debugger;
  if (!isRefreshing) {
    isRefreshing = true;

    try {
      const refreshedToken = await axios.post(
        variables.API_URL + "Authenticate/refresh-token",
        {
          accessToken: localStorage.Token,
          refreshToken: localStorage.RefrechToken,
        },
        {
          headers: {
            "Content-Type": "application/json ; charset=UTF-8",
            Authorization: `Bearer ${localStorage.Token}`,
          },
        }
      );
      // debugger;
      console.log(refreshedToken.data.refreshToken);

      localStorage.setItem("Token", refreshedToken.data.token);
      localStorage.setItem("RefrechToken", refreshedToken.data.refreshToken);

      req.headers.Authorization = `Bearer ${refreshedToken.data.token}`;
      // Reset the refreshing flag
      isRefreshing = false;
      // Resolve all queued requests with the new token
      refreshQueue.forEach((resolve) => resolve(refreshedToken.data.token));
      refreshQueue = [];
    } catch {
      // هنا ضع ما اذا كان جلب التوكن الجديد قد فشل تجده في الأسفل
      localStorage.removeItem("Token");
      localStorage.removeItem("RefrechToken");
      window.location.href = '/login'; 

    }
  } else {
    // If a refresh is already in progress, add the request to the queue
    // and return a promise that will be resolved with the new token
    // when it's available
    const tokenPromise = new Promise((resolve) => {
      refreshQueue.push((newToken) => {
        req.headers.Authorization = `Bearer ${newToken}`;
        resolve(req);
      });
    });

    return tokenPromise;
  }

  return req;
});

export { ntxAPI };

