import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { TagsInput } from "react-tag-input-component";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../../../components/card/card";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  GetParametersByReportId,
  GetReportsChartData,
  GetReportsData,
} from "../../../../../../store/NTX/Reports/ReportData/reportdataSlice";
import TypeT from "./TypeT";
import TypeD from "./TypeD";
import Loader from "../../../../../../components/Loader/Loader";
import TabsReports from "../TabsReports/TabsReports";
import Breadcrumb from "../../../../../../components/Shared/Breadcrumb";

const ReportsBuilder: React.FC = () => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ReportId } = useParams<{ ReportId: string }>();
  const [formData, setFormData] = useState<any>({});
  const [DataReport, setDataReport] = useState<any[]>([]);
  // const [DataChartReport, setDataChartReport] = useState<object>();
  const [IsExecut, setIsExecut] = useState<boolean>(false);
  const [IsAlert, setIsAlert] = useState<boolean>(false);
  const [DataChartReport, setDataChartReport] = useState<any[]>([]);

  useEffect(() => {
    if (ReportId) {
      dispatch(GetParametersByReportId(ReportId)); // Pass the ReportId as an argument
    }
  }, [ReportId, dispatch]);

  const { ParameterList, loading } = useAppSelector(
    (state) => state.ReportData
  );

  // console.log( JSON.stringify (ParameterList?.chartList    ) );

  const sortedList = ParameterList?.parameterList
    ?.slice()
    .sort((a, b) => a.sequnce - b.sequnce);

  // console.log(sortedList);

  const handleInputChange = (e: any) => {
    const { name, type, value, checked } = e.target;
    if (type === "checkbox") {
      const checkboxValue = checked ? "1" : "0";
      setFormData({ ...formData, [name]: checkboxValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsExecut(true);

    let List = sortedList?.map((item, index) => ({
      id: item.id,
      value: formData[item.id],
    }));

    const Data = {
      reportQuery: {
        reportId: ReportId,
        parameterList: List?.filter((f) => f?.value?.trim() !== "").map(
          (item) => ({
            id: item.id,
            value: item.value,
          })
        ),
      },
    };

    try {
      let resDataReport;
      resDataReport = await dispatch(GetReportsData(Data));
      if (resDataReport.payload.succeeded === true) {
        setDataReport(resDataReport.payload.data.reportData);
      } else if (resDataReport.payload.succeeded === false) {
      }

      if (ParameterList && ParameterList?.chartList?.length > 0) {
        const responsePromises = ParameterList?.chartList.map(async (item) => {
          const Request = {
            reportChartId: item.id,
            parameterList: List?.filter((f) => f?.value?.trim() !== "").map(
              (param) => ({
                id: param.id,
                value: param.value,
              })
            ),
          };

          const res = await dispatch(GetReportsChartData(Request));
          return res.payload.data; // Return the response data
        });
        const responses = await Promise.all(responsePromises);

        setDataChartReport(responses);
      }

      setIsExecut(false);
    } catch (error) {
      setIsExecut(false);
      // setIsSubmitting(false);
      console.error("An error occurred while submitting the form:", error);
    }
  };

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Reports",
      link: `/${localStorage.CurrentSystem}/Reports`,
    },
  ];



  

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName={
          i18n.language === "ar" ? ParameterList?.name2 : ParameterList?.name
        }
      />

      <Card>
        <Loader loading={loading}/>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <div className="row">
              {ParameterList &&
                // eslint-disable-next-line array-callback-return
                sortedList?.map((item, index) => {
                  if (item.hasIn === false) {
                    switch (item.dataType) {
                      case "DATE":
                        return (
                          <div key={item.id} className="col-xl-6">
                            <div className="form-group mb-3">
                              <label className="form-label">
                                {i18n.language === "ar"
                                  ? item.lableText2
                                  : item.lableText}
                              </label>
                              <Form.Control
                                className="form-control"
                                required={item.requiredFlag}
                                type="Date"
                                name={item.id}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        );
                      case "NUMBER":
                        return (
                          <div key={item.id} className="col-xl-6">
                            <div className="form-group mb-3">
                              <label className="form-label">
                                {i18n.language === "ar"
                                  ? item.lableText2
                                  : item.lableText}
                              </label>
                              <Form.Control
                                className="form-control"
                                required={item.requiredFlag}
                                type="number"
                                name={item.id}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        );
                      case "TEXT":
                        return (
                          <div key={item.id} className="col-xl-6">
                            <div className="form-group mb-3">
                              <label className="form-label">
                                {i18n.language === "ar"
                                  ? item.lableText2
                                  : item.lableText}
                              </label>
                              <Form.Control
                                className="form-control"
                                required={item.requiredFlag}
                                type="text"
                                name={item.id}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        );
                      case "TIME":
                        return (
                          <div key={item.id} className="col-xl-6">
                            <div className="form-group mb-3">
                              <label className="form-label">
                                {i18n.language === "ar"
                                  ? item.lableText2
                                  : item.lableText}
                              </label>
                              <Form.Control
                                className="form-control"
                                required={item.requiredFlag}
                                type="time"
                                name={item.id}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        );
                      case "BOOLEAN":
                        return (
                          <div key={item.id} className="col-xl-6">
                            <div className="form-group mb-3">
                              <label className="form-label">
                                {i18n.language === "ar"
                                  ? item.lableText2
                                  : item.lableText}
                              </label>
                              <Form.Check
                                style={{ margin: "10px 20px" }}
                                type="switch"
                                // label={
                                //   i18n.language === "ar" ? item.lable2 : item.lable
                                // }
                                name={item.id}
                                defaultChecked={false}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        );
                      case "T":
                        return (
                          <div key={item.id} className="col-xl-6">
                            <TypeT
                              item={item}
                              formData={formData}
                              setFormData={setFormData}
                            />
                          </div>
                        );
                      case "D":
                        return (
                          <div key={item.id} className="col-xl-6">
                            <TypeD
                              item={item}
                              formData={formData}
                              setFormData={setFormData}
                            />
                          </div>
                        );
                    }
                  } else if (item.hasIn === true) {
                    return (
                      <div key={item.id} className="col-xl-6">
                        <div className="form-group mb-3">
                          <label className="form-label">
                            {i18n.language === "ar"
                              ? item.lableText2
                              : item.lableText}
                          </label>
                          <TagsInput
                            key={item.id}
                            // value={tags}
                            // value={}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                [item.id]: e
                                  .map((item: any) => `'${item}'`)
                                  .join(","),
                              })
                            }
                            // onChange={(e) => console.log(e)}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
            </div>

            <div className="text-center">
              <button
                type="submit"
                // onClick={(e)=>handleParentSubmit(e)}
                className="btn me-1 btn-outline-theme mb-1"
                disabled={IsExecut}
              >
                <i className="bi bi-caret-left-fill me-1"></i> عرض التقرير
              </button>

              <button
                type="button"
                className=" btn me-1 btn-outline-default mb-1"
                onClick={() => Navigate(-1)}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>

          <div className="text-center">
            {IsExecut && <div className="spinner-grow text-success"></div>}
          </div>
        </CardBody>
        {/* {IsAlert && 

          //   DataReport.length === 0 &&
          //   <div className="alert alert-success" style={{ marginTop: "20px" }}>
          //   <div className="text-center">
          //     No Data
          //   </div>
          // </div>

          } */}

        <div className="mb-3" />

        {(DataChartReport?.length > 0 || DataReport?.length > 0) && (
          <TabsReports
            DataReport={DataReport}
            chartList={ParameterList?.chartList}
            DataChartReport={DataChartReport}
          />
        )}
      </Card>
    </>
  );
};

export default ReportsBuilder;

// await Promise.all(
//   (ParameterList?.chartList || []).map(async (item) => {
//     const Request = {
//       reportChartId: item.id,
//       parameterList: List?.filter((f) => f?.value?.trim() !== "").map((item) => ({
//         id: item.id,
//         value: item.value,
//       })),
//     };

//     const res = await dispatch(GetReportsChartData(Request));
//     console.log(res.payload.data);

//   })
// );
