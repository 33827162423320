/* eslint-disable no-duplicate-case */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import {
  cleanTransactionHistoryList,
  GetTransactionHistoryWithValueById,
} from "../../../store/Ame/ameRequestSlice";
import { Card, CardBody } from "../../../components/card/card";
import useGetPersons from "../../../hooks/HR/use-get-persons";
import ViewStaticForm from "../ViewRequests/ContentRequest/ViewStaticForm";
import BuilderValue from "../ViewRequests/ContentRequest/BuilderValue";
import HistoryList from "../ViewRequests/HistoryList";

const OnlyViewRequests = () => {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { TransactionHistoryId } = useParams();
  const dispatch = useDispatch();
  const { Employees } = useGetPersons();
  //

  useEffect(() => {
    dispatch(cleanTransactionHistoryList());
    dispatch(GetTransactionHistoryWithValueById(TransactionHistoryId));
  }, []);
  const data = useSelector(
    (state) => state.AmeRequest.TransactionHistoryWithValueList
  );
  console.log(data);
  const loading = useSelector((state) => state.AmeRequest.loading);
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                href="#/"
                onClick={() => Navigate("/Requests", { replace: true })}
              >
                {t("Home")}
              </a>
            </li>
            <li className="breadcrumb-item">
              <a
                href="#/"
                onClick={() => Navigate("/Requests", { replace: true })}
              >
                {t("selfService")}
              </a>
            </li>
            {/* <li className="breadcrumb-item active">{i18n.language === 'ar' ? NameRequestAr : NameRequestEN}</li> */}
          </ul>
          <h1 className="page-header mb-0">عرض الطلب</h1>
        </div>
      </div>
      <Card>
        <CardBody>
          <Loader loading={loading} />

          {Object.keys(data)?.length ? (
            <>
              <div className="row">
                <div className="col-xl-6">
                  <div className="input-group flex-nowrap m-2">
                    <span className="input-group-text">نوع الطلب</span>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={
                        i18n.language === "en"
                          ? data?.requestName
                          : data?.requestName2
                      }
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="input-group flex-nowrap m-2">
                    <span className="input-group-text">رقم الطلب</span>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={data?.requestNumber}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-xl-6">
                  <div className="input-group flex-nowrap m-2">
                    <span className="input-group-text">صاحب الطلب</span>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={
                        i18n.language === "en"
                          ? data?.selectPersonName
                          : data?.selectPersonName2
                      }
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="input-group flex-nowrap m-2">
                    <span className="input-group-text">تاريخ الطلب</span>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={data?.dateCreated}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                {data.is_Static && (
                  <ViewStaticForm
                    id={data.dynamicFormId}
                    ReferenceId={data.staticRefrenceId}
                  />
                )}

                <div className="col-xl-2"></div>

                <div className="col-xl-8">
                  {data.values && <BuilderValue data={data.values} />}
                </div>

                <div className="col-xl-2"></div>
              </div>
            </>
          ) : (
            ""
          )}
          {/* <hr className="mb-4" /> */}
          <div className="row">
            <div className="col-lg-12">
              {data.history && <HistoryList List={data.history} />}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default OnlyViewRequests;
