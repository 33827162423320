import { configureStore } from "@reduxjs/toolkit";

import Employees from "./HR/Person/employeeSlice";
import Absence from "./HR/Person/absenceSlice";
import Lookup from "./lookupSlice";
import Grades from "./HR/HRSetting/jobgradeSlice";
import Jobs from "./HR/HRSetting/jobSlice";
import Positions from "./HR/HRSetting/positionSlice";
import Organizations from "./HR/Organizations/organizationSlice";
import OrganizationStructure from "./HR/Organizations/OrganizationStructureSice";

import Locations from "./HR/HRSetting/locationSlice";
import Payrolls from "./HR/Payroll/PayrollSlice";
import runPayroll from "./HR/Payroll/runPayrollSlice";
import PyrollElement from "./HR/Payroll/payrollElementSlice";
import PayProposal from "./HR/Payroll/payProposalSlice";
import PayElementEntry from "./HR/Payroll/payrollElementEntrySlice";
import Groups from "./HR/HRSetting/groupEmpSlice";
import Assignments from "./HR/Person/assignmentSlice";
import Forms from "./NTX/FlexValue/Form/formSlice";
import FormColumns from "./NTX/FlexValue/Form/FormColumnSlice"
import FlexValueSet from "./NTX/FlexValue/flexValueSetSlice"
import FlexValue from "./NTX/FlexValue/FlixValueSlice"
import Ame from "./Ame/ameSlice"
import ApprovalGroup from "./Ame/approvalGroupSlice"
import ApprovalMember from "./Ame/AprovalMemberSlice"
import AmeRule from "./Ame/ameRuleSlice"
import AmeConditionUsage from "./Ame/ameConditionUsageSlice"
import AmeActionRule from "./Ame/ameActionRuleSlice"
import AmeRequest from "./Ame/ameRequestSlice"

import Users from "./NTX/Security/usersSlice"
import Responsibility from "./NTX/Security/responsibilitySlice"
import ResponsibilityUser from "./NTX/Security/responsibilityUserSlice"
import ResponsibilityFunction from "./NTX/Security/responsibilityFunctionSlice"
//
// import { moduleApi } from "./moduleApi";

import userLogin from "./Login/loginSlice"
import Raqeeb from "./HR/Raqeeb/raqeebSlice";

import ExtraInformation from "./ExtraInformation/extraInformationSlice"

import RealState from "./SU/realEstateSlice"
import Contracts from "./SU/contractLeaseSlice"
import DashboardSU from "./SU/dashboardSUSlice"
import InstallmentPayment from "./SU/installmentPaymentSlice"
import Reports from "./NTX/Reports/reportSlice"
import ReportData from "./NTX/Reports/ReportData/reportdataSlice"
import ReportShape from "./NTX/Reports/ReportShape/reportShapeSlice"
import Peoples  from "./NTX/Peoples/peoplesSlice";
import LookupData from "./NTX/Lookup/lookupsSlice"

import WorkShop  from "./FLEET/Workshop/workshopSlice";
import EquipmentsModel  from "./FLEET/EquipmentsModel/equipmentsmodelSlice";
import Equipments  from "./FLEET/Equipments/equipmentsSlice";
import EquipmentsAssignment  from "./FLEET/EquipmentsAssignment/equipmentsAssignmentSlice";
import EquipmentsOdometer  from "./FLEET/EquipmentsOdometer/equipmentsOdometerSlice";
import EquipmentsWorkRequest  from "./FLEET/EquipmentsWorkRequests/equipmentsWorkRequestSlice";
import  EquipmentsRoutes from "./FLEET/EquipmentsRoutes/equipmentsRoutesSlice";
import  EquipmentsWorkOrder from "./FLEET/EquipmentsWorkOrder/equipmentsworkorderSlice";
import  AssignmentRoutes from "./FLEET/AssignmentRoutes/assignmentroutesSlice";
import  FleetScan from "./FLEET/FleetScan/fleetScanSlice";
import  EquipmentInspection from "./FLEET/EquipmentInspection/equipmentInspectionSlice";




export const store = configureStore({
  reducer: {
    Employees,
    Assignments,
    Absence,
    Lookup,
    LookupData,
    Grades,
    Jobs,
    Positions,
    Organizations,
    OrganizationStructure,
    Locations,
    Payrolls,
    runPayroll,
    PyrollElement,
    PayProposal,
    PayElementEntry,
    Groups,
    //////// 
    ExtraInformation,
    Forms,
    FlexValueSet,
    FlexValue,
    FormColumns,
    Ame,
    ApprovalGroup,
    ApprovalMember,
    AmeRule,
    AmeConditionUsage,
    AmeActionRule,
    AmeRequest,
    Users,
    Responsibility,
    ResponsibilityUser,
    ResponsibilityFunction,
    //
    userLogin,
    //
    Raqeeb,

    // SU
    RealState,
    Contracts,
    InstallmentPayment,
    DashboardSU,
    // Reports
    Reports,
    ReportData,
    ReportShape,
    Peoples,

    // Fleet //
    WorkShop,
    EquipmentsModel,
    Equipments,
    EquipmentsAssignment,
    EquipmentsOdometer,
    EquipmentsWorkRequest,
    EquipmentsRoutes,
    EquipmentsWorkOrder,
    AssignmentRoutes,
    FleetScan,
    EquipmentInspection
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
});


// const persistor = persistStore(store);
// export { store, persistor };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch