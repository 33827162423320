import React, { useEffect } from "react";

// import Select from "react-dropdown-select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useLookupOrgType from "../../../../hooks/Lookups/use-Lookup-OrgType";

import { useNavigate } from "react-router-dom";
import useLookupOrgClass from "../../../../hooks/Lookups/use-Lookup-OrgClass";
import useOrganizationDetails from "../../../../hooks/HR/use-organization-details";

// import { EditOrganization, getOrganization } from '../../../../store/HR/Organizations/organizationSlice';
import { getLocations } from "../../../../store/HR/HRSetting/locationSlice";
import EditFormDepartments from "./EditFormDepartments";

const EditDepartments = () => {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { Organization, loading } = useOrganizationDetails();

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/" onClick={() => Navigate(-1, { replace: true })}>
                {t("Home")}
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/" onClick={() => Navigate(-1, { replace: true })}>
                {t("Departments")}
              </a>
            </li>
            <li className="breadcrumb-item active">{t("Button.Edit")} </li>
          </ul>
          <h1 className="page-header mb-0">{t("Edit Department")}</h1>
        </div>
      </div>

      <EditFormDepartments Organization={Organization} loading={loading} />
    </>
  );
};

export default EditDepartments;
