import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetFlexValueSetList } from "../../../../store/NTX/FlexValue/flexValueSetSlice";
import Pagination from "../../../../components/Pagination/Pagination";
import Loader from "../../../../components/Loader/Loader";
import { Card } from "../../../../components/card/card";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import AddFlexValueSet from "./FlexValueSet/AddFlexValueSet";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";

const ListSystem = () => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { FlexValueSetList, loading } = useSelector(
    (state) => state.FlexValueSet
  );

  useEffect(() => {
    dispatch(GetFlexValueSetList());
  }, []);

  const filteredSearch = FlexValueSetList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: t("System Lists"),
      link: null,
    },
  ];


  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t("System Lists")} />

      <Card>
        <div className="tab-content p-4">
          <div className="tab-pane fade show active">
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-4">
                  <div className="flex-fill position-relative">
                    <div className="input-group mb-4">
                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: "10px" }}
                          >
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            placeholder={t("Search")}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5 col-md-6 text-end">
                <a
                  href="#/"
                  className="btn btn-outline-theme"
                  data-bs-toggle="modal"
                  data-bs-target="#modalAddFlexValueSet"
                  onClick={() => setShow(true)}
                >
                  <i className="fa fa-plus-circle fa-fw me-1"></i>
                  {t("Add New List")}
                </a>
              </div>
            </div>

            <Loader loading={loading} />
            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th scope="col" style={{width: "33.3%"}}>{t("Name")}</th>
                    <th scope="col" style={{width: "33.3%"}}>{t("Type")}</th>

                    <th scope="col" style={{width: "33.3%"}}></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </td>
                      <td className="align-middle">
                      {item.formatType === "T"
                          ? "جدول في النظام"
                          : item.formatType === "D"
                          ? "قائمة ديناميكية"
                          : item.formatType === "L"
                          ? "قائمة نظام ثابته"
                          : "حقل نظام"}
                      </td>
                      <td>
                        {item.formatType === "D" && (
                          <div className="btn-group">
                            <button
                              type="button"
                              //  hidden={hiddenAddFlexValue}
                              onClick={() =>
                                Navigate(`${item.id}/AddFlexValue`)
                              }
                              className="btn btn-outline-lime"
                            >
                              <i className="fab fa-lg fa-fw me-2 fa-elementor"></i>
                              {t("Values")}
                            </button>
                            <button
                              type="button"
                              //  hidden={hiddenAddFlexValue}
                              onClick={() => Navigate(`${item.id}/AddSubLists`)}
                              className="btn btn-outline-lime"
                            >
                              <i className="fab fa-lg fa-fw me-2 fa-elementor"></i>
                              {t("Sub-Lists")}
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              filteredData={filteredSearch}
              dataPerPage={dataPerPage}
              handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
              currentPage={currentPage}
            />

            {/* </CardBody> */}
          </div>
        </div>
      </Card>
      <ModalComponent title={t("Add New List")} id="modalAddFlexValueSet">
        {show && <AddFlexValueSet />}
      </ModalComponent>
    </>
  );
};

export default ListSystem;
