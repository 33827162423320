import React from "react";
import { Link } from "react-router-dom";

 
const iconMapping = {
  ".jpg": "far fa-lg fa-fw fa-image",
  ".JPG": "far fa-lg fa-fw fa-image",
  ".png": "far fa-lg fa-fw fa-image",
  ".jpeg": "far fa-lg fa-fw fa-image",
  ".pdf": "far fa-lg fa-fw fa-file-pdf",
  ".txt": "fa fa-file-text-o",
  ".doc": "fa fa-file-word-o",
  // Add more extensions and icons as needed
};
// uploadedFiles, onDeleteFile,
const FileList = ({  Attachments  }) => {
  const handleIconClick = (filePath) => {
    if (filePath) {
      window.open(filePath, "_blank");
    }
  };


  return (
    <div style={{ marginTop: "10px" }}>
      <div className="list-group" >
        {Attachments?.map((file, index) => (
          <div
            className="list-group-item d-flex align-items-center"
            key={index}
          >
            <div
              className="w-40px h-40px d-flex align-items-center justify-content-center bg-gradient-orange text-white rounded-2 ms-n1"
              onClick={() => handleIconClick(file?.filePath)}
              style={{ cursor: "pointer" , minWidth: "40px", minHeight:"40px"  }}
            >
              <i className={iconMapping[file?.extensionFile]}></i>
            </div>
            <div className="flex-fill px-3">
              <div
               className="fw-bold"
               style={{
                whiteSpace :"nowrap" ,
                overflow : "hidden",
                textOverflow : "ellipsis",
                maxWidth : "50%"
               }} >
                 {/* {file?.fileName} */}
                 {file.description}
                </div>
              <div className="small text-inverse text-opacity-50">
                {`${(file?.size / 1024).toFixed(2)} KB`}
              </div>
            </div>

          </div>
        ))}
      </div>
    </div>
  );
};

export default FileList;

// <div key={index} className="file-item">
//   <i className="fas fa-file fa-lg"></i>
//   <span>{file.name}</span>
// <Link style={{ color: "red" }} onClick={() => onDeleteFile(index)}>
//   <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
// </Link>
// </div>
