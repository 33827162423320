import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { insertResponsibility } from "../../../../store/NTX/Security/responsibilitySlice";
// import { insertResponsibility } from "../../../../store/NTX/Security/responsibilitySlice";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import { formResponsibilitySchema } from "../../../ValidationForm/validationSchema";
import { DataIcons } from "../../../../assets/icons";
import useLookupModule from "../../../../hooks/Lookups/use-Lookup-Module";

function AddResponsibility() {
  const { t, i18n } = useTranslation();

  const { Module } = useLookupModule();

  const dispatch = useDispatch();

  ///
  const formik = useFormik({
    initialValues: {
      name: "",
      name2: "",
      description: "",
      moduleId: "",
      icon: "",
    },

    validationSchema: formResponsibilitySchema(t),

    onSubmit: (values) => {
      console.log(values);

      dispatch(
        insertResponsibility({
          name: values.name,
          name2: values.name2,
          description: values.description,
          moduleId: values.moduleId,
          icon: values.icon,

          startDate: "2023-03-17T20:18:33.538Z",
          endDate: "2023-03-17T20:18:33.538Z",
        })
      )
        .unwrap()
        .then(() => {
          CustomAlert({ action: "Add" });
          document.getElementById("closeModalResponsibility").click();
          formik.resetForm();
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Arabic Name")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={formik.touched.name2 && formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name2}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("English Name")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={formik.touched.name && formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Application")}</label>
            <Form.Select
              className="form-select"
              name="moduleId"
              value={formik.values.moduleId}
              onChange={formik.handleChange}
              isInvalid={formik.touched.moduleId && formik.errors.moduleId}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Module &&
                Module.map((item, idx) => (
                  <option key={++idx} value={item.id}>
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.moduleId}
            </Form.Control.Feedback>
          </div>
        </div>
      

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Icon")}</label>

            <Select
              classNamePrefix="react-select"
              isSearchable={true}
              required
              options={DataIcons}
              onChange={(option) => formik.setFieldValue("icon", option.value)}
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
          </div>
        </div>

        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label">ملاحظات</label>
            <Form.Control
              className="form-control"
              as="textarea"
              rows={3}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
        </div>
      </div>
      </div>

      <div className="text-center">
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-theme mb-1"
        >
          {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t("Add")}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalResponsibility"
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
      </div>
    </Form>
  );
}

export default AddResponsibility;
