import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../../components/card/card.jsx";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useGetPersons from "../../../../hooks/HR/use-get-persons.js";
import useAbsenceType from "../../../../hooks/Lookups/use-Absence-Type.js";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { AddAbsenceAttendance } from "../../../../store/HR/Person/absenceSlice.js";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import { formAbsenseSchema } from "../../../ValidationForm/validationSchema.js";
import AbsenceList from "./AbsenceList.js";
import { useEffect } from "react";
import { GetAbsenceAttendance } from "../../../../store/HR/Person/absenceSlice";
import Loader from "../../../../components/Loader/Loader";

const AddAbsence = ({ personId, isAme = false, setReferenceId }) => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  // ________ جلب البيانات الى الصفحة_____________
  const { Employees } = useGetPersons();
  var id = isAme === true ? localStorage.personId :personId
  const { AbsenceAttendanceTypeList } = useAbsenceType(id);

  useEffect(() => {
    if (personId) {
      // لا يتم تنفيذها الا اذا كانت من شاشة الموظفين
      dispatch(GetAbsenceAttendance(personId));
    }
  }, [dispatch, personId]);
  const { AbsenceAttendanceList: data, loading } = useSelector(
    (state) => state.Absence
  );

  // _______________________________________

  const formik = useFormik({
    initialValues: {
      absenceAttendanceTypeId: "",
      personId: personId,
      authorisingPersonId: "",
      replacementPersonId: "",
      absenceDays: "",
      dateEnd: "",
      // dateNotification: "",
      dateStart: "",
      comments: "",
    },

    validationSchema: formAbsenseSchema(t),

    onSubmit: async (values) => {
      // console.log( isAme && "from Ame");

      try {
        const response = await dispatch(
          AddAbsenceAttendance({
            absenceAttendanceTypeId: values.absenceAttendanceTypeId,
            personId: isAme ? null : personId,
            authorisingPersonId: values.authorisingPersonId,
            replacementPersonId: values.replacementPersonId,
            absenceDays: values.absenceDays,
            dateEnd: values.dateEnd,
            dateNotification: null,
            dateStart: values.dateStart,
            comments: values.comments,
            isAme: isAme,
          })
        );
        // debugger
        if (response?.payload?.succeeded === true) {
          // اذا كان البوست من شاشة الغياب تظهر رسالة النجاح واذا كان من شاشة الخدمة الذاتية لا تظهر
          if (!isAme) {
            CustomAlert({ action: "Add" });
            dispatch(GetAbsenceAttendance(personId));
            return
          }
          await setReferenceId(response.payload.data);
        } else{
          CustomAlert({ action: "Error", msg:response?.payload?.message  });
          formik.resetForm()
        }
      } catch (error) {
        console.log(error);
        formik.setSubmitting(false);
        CustomAlert({ action: "Error" });
      }
    },
  });

  // ________________ حساب عدد الأيام بناءً على الفرق بين التاريخين____________

  const handleDateFromChange = (event) => {
    calculateDateDifference(event.target.value, formik.values.dateEnd);
    formik.setFieldValue("dateStart", event.target.value);
  };

  const handleDateToChange = (event) => {
    calculateDateDifference(formik.values.dateStart, event.target.value);
    formik.setFieldValue("dateEnd", event.target.value);
  };

  const calculateDateDifference = (from, to) => {
    // Convert time difference into days
    const date1 = new Date(from);
    const date2 = new Date(to);
    // Calculate time difference in milliseconds
    const timeDifference = Math.abs(date2 - date1);
    // Convert time difference into days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    formik.setFieldValue("absenceDays", daysDifference + 1);

    // setDateDifference(daysDifference);
  };
  //______________________________________________________________________________

  return (
    <div className="row">
      {!isAme && <Loader loading={loading} />}

      {/* <Form onSubmit={formik.handleSubmit}> */}
      <div className="row">
        <div className="col-xl-3"></div>
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Absence_Type")}</label>
            <Form.Select
              className="form-select"
              name="absenceAttendanceTypeId"
              onChange={formik.handleChange}
              value={formik.values.absenceAttendanceTypeId}
              isInvalid={
                formik.touched.absenceAttendanceTypeId &&
                formik.errors.absenceAttendanceTypeId
              }
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {AbsenceAttendanceTypeList &&
                AbsenceAttendanceTypeList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.absenceAttendanceTypeId}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-3"></div>
      </div>

      <div className="row">
        <div className="col-xl-5">
          <div className="input-group mb-3 ">
            <span className="input-group-text">{t("Start Date")}</span>

            <Form.Control
              type="date"
              className="form-control"
              name="dateStart"
              onChange={(e) => handleDateFromChange(e)}
              value={formik.values.dateStart}
              isInvalid={formik.touched.dateStart && formik.errors.dateStart}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.dateStart}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-5">
          <div className="input-group mb-3">
            <span className="input-group-text">{t("Date_TO")}</span>
            <Form.Control
              type="date"
              className="form-control"
              name="dateEnd"
              onChange={(e) => handleDateToChange(e)}
              value={formik.values.dateEnd}
              isInvalid={formik.touched.dateEnd && formik.errors.dateEnd}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.dateEnd}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-2">
          <div className="input-group c">
            <span className="input-group-text">{t("Days")}</span>

            <input
              className="form-control"
              type="text"
              value={formik.values.absenceDays}
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Authorising_Person")}</label>
            <Select
              classNamePrefix="react-select"
              isSearchable={true}
              name="authorisingPersonId"
              options={Employees.map((item) => ({
                value: item.id,
                label: i18n.language === "ar" ? item.name2 : item.name,
              }))}
              onChange={(option) =>
                formik.setFieldValue("authorisingPersonId", option.value)
              }
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
            {formik.errors.authorisingPersonId &&
              formik.touched.authorisingPersonId && (
                <div style={{ color: "red" }}>
                  {formik.errors.authorisingPersonId}
                </div>
              )}
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("replacement")}</label>
            <Select
              classNamePrefix="react-select"
              isSearchable={true}
              name="replacementPersonId"
              options={Employees.map((item) => ({
                value: item.id,
                label: i18n.language === "ar" ? item.name2 : item.name,
              }))}
              onChange={(option) =>
                formik.setFieldValue("replacementPersonId", option.value)
              }
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
            {formik.errors.replacementPersonId &&
              formik.touched.replacementPersonId && (
                <div style={{ color: "red" }}>
                  {formik.errors.replacementPersonId}
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label">{t("Notes")}</label>
            <Form.Control
              className="form-control"
              as="textarea"
              rows={3}
              name="comments"
              onChange={formik.handleChange}
              value={formik.values.comments}
            />

            <div></div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <button
          // onClick={ (e)=>handleSubmit(e)}
          disabled={formik.isSubmitting}
          type="button"
          className="btn me-1 btn-theme mb-1"
          onClick={formik.submitForm}
        >
          <i className="fa fa-save fa-fw me-1"></i>
          {formik.isSubmitting ? "Saving..." : t("Button.Save")}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          onClick={() => Navigate(-1, { replace: true })}
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
      </div>

      {/* </Form> */}

      {!isAme && (
        <>
          <div className="mb-3"></div>
          {/* <hr className="mb-4" /> */}
          <div className="row">
            <div className="col-lg-12">
              {data.historyAttendances && (
                <AbsenceList data={data.historyAttendances} />
              )}
            </div>
          </div>
        </>
      )}

      <div className="mb-3"></div>

      <div />
    </div>
  );
};

export default AddAbsence;
