
import React from "react";
import { useTranslation } from "react-i18next";

const Pagination = ({ filteredData, dataPerPage, handlePagination, currentPage }) => {

  // console.log(dataPerPage);

  const { t, i18n } = useTranslation();
  // Calculate the range of pages to display
  const range = 1; // Number of buttons to show on either side of the current page
  let start = currentPage - range;
  let end = currentPage + range;
  if (start < 1) {
    end += 1 - start;
    start = 1;
  }
  if (end > Math.ceil(filteredData?.length / dataPerPage)) {
    start -= end - Math.ceil(filteredData?.length / dataPerPage);
    end = Math.ceil(filteredData?.length / dataPerPage);
  }
  const pageNumbers = [];
  for (let i = start; i <= end; i++) {
    if(i >= 1) {
      pageNumbers.push(i);
    }
  }

  // Don't render pagination buttons if there is only one page
  if (Math.ceil(filteredData?.length / dataPerPage) === 1 || filteredData?.length === 0) {
    return (
      <div className="text-center">
        {t("Showing")} {filteredData?.length} {t("entries")}
      </div>
    );
  }

  return (
    <>
      <div className="d-md-flex align-items-center">
        <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
          {t("Showing")} {filteredData?.length} {t("entries")}
        </div>
        <ul className="pagination mb-0 justify-content-center">
          <li className="page-item">
            <button
              onClick={() => handlePagination(currentPage - 1)}
              className="page-link"
              disabled={currentPage === 1}
            >
              {t("Previous")}
            </button>
          </li>
          {start > 1 && (
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => handlePagination(start - 1)}
                href="#/"
              >
                &hellip;
              </a>
            </li>
          )}
          {pageNumbers.map((number) => (
            <li className="page-item" key={number}>
              <a
                className={number === currentPage ? "page-link active" : "page-link"}
                onClick={() => handlePagination(number)}
                href="#/"
              >
                {number}
              </a>
            </li>
          ))}
          {end < Math.ceil(filteredData?.length / dataPerPage) && (
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => handlePagination(end + 1)}
                href="#/"
              >
                &hellip;
              </a>
            </li>
          )}
          <li className="page-item">
            <button
              onClick={() => handlePagination(currentPage + 1)}
              className="page-link"
              disabled={currentPage === Math.ceil(filteredData?.length / dataPerPage)}
            >
              {t("Next")}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Pagination;