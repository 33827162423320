import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "../../../../components/card/card";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader.js";
import { GetRealEstateById } from "../../../../store/SU/realEstateSlice";
import UpdateRealEstateForm from "./UpdateRealEstateForm";

import Breadcrumb from "../../../../components/Shared/Breadcrumb";

const UpdateRealEstate = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { Id } = useParams();

  useEffect(() => {
    dispatch(GetRealEstateById(Id));
  }, [dispatch, Id]);
  const { RealEstateById, loading } = useSelector((state) => state.RealState);

  //_____________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "RealEstate",
      link: "/SU/RealEstate",
    },
    {
      name: "Edit",
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Update_Real_Estate" />

      <Card>
        <Loader loading={loading} />

        <CardBody>
          {Object.keys(RealEstateById)?.length > 0 && (
            <UpdateRealEstateForm
              RealEstateById={RealEstateById}
              loading={loading}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default UpdateRealEstate;
