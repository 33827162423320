import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetAbsenceAttendanceType } from "../../store/HR/Person/absenceSlice";
 


const useAbsenceType = (Id)=>{

    const dispatch = useDispatch();
    // const { personId} = useParams();
    
    
    useEffect(()=>{
        dispatch(GetAbsenceAttendanceType(Id));
      },[dispatch , Id])

    const { AbsenceAttendanceTypeList } = useSelector((state) => state.Absence); 

    return { AbsenceAttendanceTypeList } ;
};

export default useAbsenceType ;