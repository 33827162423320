import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { getData, postData } from "../../Axios/axiosData";
import { ntxAPI } from "../../../API/axios-custom";

const initialState = { Positions: [], loading: false, error: null, Position: [] };

export const insertPosition = createAsyncThunk(
  "Positions/insertPosition",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData( "HRSetting/AddPosition" , item);
      const {data} = await ntxAPI.post("HRSetting/AddPosition" , item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getPositions = createAsyncThunk("Positions/getPositions", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    // const data = await getData("HRSetting/GetPositionList");
    const {data} = await ntxAPI.get("HRSetting/GetPositionList");
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const getPosition = createAsyncThunk("Positions/getPosition", async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    // const data = await getData(`HRSetting/GetPositionById?Id=${id}`);
    const {data} = await ntxAPI.get(`HRSetting/GetPositionById?Id=${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const editPosition = createAsyncThunk(
  "Positions/editPosition",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData( "HRSetting/UpdatePosition" , item);
      const {data} = await ntxAPI.post("HRSetting/UpdatePosition" , item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);




const PositionSlice = createSlice({
  name: "Positions",
  initialState,
  reducers: { },
  extraReducers: {
    ///// get all Positions
    [getPositions.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPositions.fulfilled]: (state, action) => {
      state.loading = false;
      state.Positions = action.payload;
    },
    [getPositions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    
    ///////insert
    [insertPosition.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [insertPosition.fulfilled]: (state, action) => {
      state.loading = false;
      state.Positions.push(action.payload);
    },
    [insertPosition.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // edit 
    [editPosition.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [editPosition.fulfilled]: (state, action) => {
      state.loading = false;
      state.Position = action.payload;
    },
    [editPosition.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    ///// get one Position by ID
    [getPosition.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPosition.fulfilled]: (state, action) => {
      state.loading = false;
      state.Position = action.payload;
    },
    [getPosition.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default PositionSlice.reducer;
