
export const DataIcons = [
    { value:'fas fa-lg fa-fw me-2 fa-address-book', label: <><i className='fas fa-lg fa-fw me-2 fa-address-book'></i>address-book</>},
  { value:'fas fa-lg fa-fw me-2 fa-address-card', label: <><i className='fas fa-lg fa-fw me-2 fa-address-card'></i>address-card</>},
  { value:'fas fa-lg fa-fw me-2 fa-adjust', label: <><i className='fas fa-lg fa-fw me-2 fa-adjust'></i>adjust</>},
  { value:'fas fa-lg fa-fw me-2 fa-align-center', label: <><i className='fas fa-lg fa-fw me-2 fa-align-center'></i>align-center</>},
  { value:'fas fa-lg fa-fw me-2 fa-align-justify', label: <><i className='fas fa-lg fa-fw me-2 fa-align-justify'></i>align-justify</>},
  { value:'fas fa-lg fa-fw me-2 fa-align-left', label: <><i className='fas fa-lg fa-fw me-2 fa-align-left'></i>align-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-align-right', label: <><i className='fas fa-lg fa-fw me-2 fa-align-right'></i>align-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-ambulance', label: <><i className='fas fa-lg fa-fw me-2 fa-ambulance'></i>ambulance</>},
  { value:'fas fa-lg fa-fw me-2 fa-american-sign-language-interpreting', label: <><i className='fas fa-lg fa-fw me-2 fa-american-sign-language-interpreting'></i>american-sign-language-interpreting</>},
  { value:'fas fa-lg fa-fw me-2 fa-anchor', label: <><i className='fas fa-lg fa-fw me-2 fa-anchor'></i>anchor</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-double-down', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-double-down'></i>angle-double-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-double-left', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-double-left'></i>angle-double-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-double-right', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-double-right'></i>angle-double-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-double-up', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-double-up'></i>angle-double-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-down', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-down'></i>angle-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-left', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-left'></i>angle-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-right', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-right'></i>angle-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-angle-up', label: <><i className='fas fa-lg fa-fw me-2 fa-angle-up'></i>angle-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-archive', label: <><i className='fas fa-lg fa-fw me-2 fa-archive'></i>archive</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-alt-circle-down', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-alt-circle-down'></i>arrow-alt-circle-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-alt-circle-left', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-alt-circle-left'></i>arrow-alt-circle-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-alt-circle-right', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-alt-circle-right'></i>arrow-alt-circle-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-alt-circle-up', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-alt-circle-up'></i>arrow-alt-circle-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-circle-down', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-circle-down'></i>arrow-circle-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-circle-left', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-circle-left'></i>arrow-circle-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-circle-right', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-circle-right'></i>arrow-circle-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-circle-up', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-circle-up'></i>arrow-circle-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-down', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-down'></i>arrow-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-left', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-left'></i>arrow-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-right', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-right'></i>arrow-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrow-up', label: <><i className='fas fa-lg fa-fw me-2 fa-arrow-up'></i>arrow-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrows-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-arrows-alt'></i>arrows-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrows-alt-h', label: <><i className='fas fa-lg fa-fw me-2 fa-arrows-alt-h'></i>arrows-alt-h</>},
  { value:'fas fa-lg fa-fw me-2 fa-arrows-alt-v', label: <><i className='fas fa-lg fa-fw me-2 fa-arrows-alt-v'></i>arrows-alt-v</>},
  { value:'fas fa-lg fa-fw me-2 fa-assistive-listening-systems', label: <><i className='fas fa-lg fa-fw me-2 fa-assistive-listening-systems'></i>assistive-listening-systems</>},
  { value:'fas fa-lg fa-fw me-2 fa-asterisk', label: <><i className='fas fa-lg fa-fw me-2 fa-asterisk'></i>asterisk</>},
  { value:'fas fa-lg fa-fw me-2 fa-at', label: <><i className='fas fa-lg fa-fw me-2 fa-at'></i>at</>},
  { value:'fas fa-lg fa-fw me-2 fa-audio-description', label: <><i className='fas fa-lg fa-fw me-2 fa-audio-description'></i>audio-description</>},
  { value:'fas fa-lg fa-fw me-2 fa-backward', label: <><i className='fas fa-lg fa-fw me-2 fa-backward'></i>backward</>},
  { value:'fas fa-lg fa-fw me-2 fa-balance-scale', label: <><i className='fas fa-lg fa-fw me-2 fa-balance-scale'></i>balance-scale</>},
  { value:'fas fa-lg fa-fw me-2 fa-ban', label: <><i className='fas fa-lg fa-fw me-2 fa-ban'></i>ban</>},
  { value:'fas fa-lg fa-fw me-2 fa-barcode', label: <><i className='fas fa-lg fa-fw me-2 fa-barcode'></i>barcode</>},
  { value:'fas fa-lg fa-fw me-2 fa-bars', label: <><i className='fas fa-lg fa-fw me-2 fa-bars'></i>bars</>},
  { value:'fas fa-lg fa-fw me-2 fa-bath', label: <><i className='fas fa-lg fa-fw me-2 fa-bath'></i>bath</>},
  { value:'fas fa-lg fa-fw me-2 fa-battery-empty', label: <><i className='fas fa-lg fa-fw me-2 fa-battery-empty'></i>battery-empty</>},
  { value:'fas fa-lg fa-fw me-2 fa-battery-full', label: <><i className='fas fa-lg fa-fw me-2 fa-battery-full'></i>battery-full</>},
  { value:'fas fa-lg fa-fw me-2 fa-battery-half', label: <><i className='fas fa-lg fa-fw me-2 fa-battery-half'></i>battery-half</>},
  { value:'fas fa-lg fa-fw me-2 fa-battery-quarter', label: <><i className='fas fa-lg fa-fw me-2 fa-battery-quarter'></i>battery-quarter</>},
  { value:'fas fa-lg fa-fw me-2 fa-battery-three-quarters', label: <><i className='fas fa-lg fa-fw me-2 fa-battery-three-quarters'></i>battery-three-quarters</>},
  { value:'fas fa-lg fa-fw me-2 fa-bed', label: <><i className='fas fa-lg fa-fw me-2 fa-bed'></i>bed</>},
  { value:'fas fa-lg fa-fw me-2 fa-beer', label: <><i className='fas fa-lg fa-fw me-2 fa-beer'></i>beer</>},
  { value:'fas fa-lg fa-fw me-2 fa-bell', label: <><i className='fas fa-lg fa-fw me-2 fa-bell'></i>bell</>},
  { value:'fas fa-lg fa-fw me-2 fa-bell-slash', label: <><i className='fas fa-lg fa-fw me-2 fa-bell-slash'></i>bell-slash</>},
  { value:'fas fa-lg fa-fw me-2 fa-bicycle', label: <><i className='fas fa-lg fa-fw me-2 fa-bicycle'></i>bicycle</>},
  { value:'fas fa-lg fa-fw me-2 fa-binoculars', label: <><i className='fas fa-lg fa-fw me-2 fa-binoculars'></i>binoculars</>},
  { value:'fas fa-lg fa-fw me-2 fa-birthday-cake', label: <><i className='fas fa-lg fa-fw me-2 fa-birthday-cake'></i>birthday-cake</>},
  { value:'fas fa-lg fa-fw me-2 fa-blind', label: <><i className='fas fa-lg fa-fw me-2 fa-blind'></i>blind</>},
  { value:'fas fa-lg fa-fw me-2 fa-bold', label: <><i className='fas fa-lg fa-fw me-2 fa-bold'></i>bold</>},
  { value:'fas fa-lg fa-fw me-2 fa-bolt', label: <><i className='fas fa-lg fa-fw me-2 fa-bolt'></i>bolt</>},
  { value:'fas fa-lg fa-fw me-2 fa-bomb', label: <><i className='fas fa-lg fa-fw me-2 fa-bomb'></i>bomb</>},
  { value:'fas fa-lg fa-fw me-2 fa-book', label: <><i className='fas fa-lg fa-fw me-2 fa-book'></i>book</>},
  { value:'fas fa-lg fa-fw me-2 fa-bookmark', label: <><i className='fas fa-lg fa-fw me-2 fa-bookmark'></i>bookmark</>},
  { value:'fas fa-lg fa-fw me-2 fa-braille', label: <><i className='fas fa-lg fa-fw me-2 fa-braille'></i>braille</>},
  { value:'fas fa-lg fa-fw me-2 fa-briefcase', label: <><i className='fas fa-lg fa-fw me-2 fa-briefcase'></i>briefcase</>},
  { value:'fas fa-lg fa-fw me-2 fa-bug', label: <><i className='fas fa-lg fa-fw me-2 fa-bug'></i>bug</>},
  { value:'fas fa-lg fa-fw me-2 fa-building', label: <><i className='fas fa-lg fa-fw me-2 fa-building'></i>building</>},
  { value:'fas fa-lg fa-fw me-2 fa-bullhorn', label: <><i className='fas fa-lg fa-fw me-2 fa-bullhorn'></i>bullhorn</>},
  { value:'fas fa-lg fa-fw me-2 fa-bullseye', label: <><i className='fas fa-lg fa-fw me-2 fa-bullseye'></i>bullseye</>},
  { value:'fas fa-lg fa-fw me-2 fa-bus', label: <><i className='fas fa-lg fa-fw me-2 fa-bus'></i>bus</>},
  { value:'fas fa-lg fa-fw me-2 fa-calculator', label: <><i className='fas fa-lg fa-fw me-2 fa-calculator'></i>calculator</>},
  { value:'fas fa-lg fa-fw me-2 fa-calendar', label: <><i className='fas fa-lg fa-fw me-2 fa-calendar'></i>calendar</>},
  { value:'fas fa-lg fa-fw me-2 fa-calendar-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-calendar-alt'></i>calendar-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-calendar-check', label: <><i className='fas fa-lg fa-fw me-2 fa-calendar-check'></i>calendar-check</>},
  { value:'fas fa-lg fa-fw me-2 fa-calendar-minus', label: <><i className='fas fa-lg fa-fw me-2 fa-calendar-minus'></i>calendar-minus</>},
  { value:'fas fa-lg fa-fw me-2 fa-calendar-plus', label: <><i className='fas fa-lg fa-fw me-2 fa-calendar-plus'></i>calendar-plus</>},
  { value:'fas fa-lg fa-fw me-2 fa-calendar-times', label: <><i className='fas fa-lg fa-fw me-2 fa-calendar-times'></i>calendar-times</>},
  { value:'fas fa-lg fa-fw me-2 fa-camera', label: <><i className='fas fa-lg fa-fw me-2 fa-camera'></i>camera</>},
  { value:'fas fa-lg fa-fw me-2 fa-camera-retro', label: <><i className='fas fa-lg fa-fw me-2 fa-camera-retro'></i>camera-retro</>},
  { value:'fas fa-lg fa-fw me-2 fa-car', label: <><i className='fas fa-lg fa-fw me-2 fa-car'></i>car</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-down', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-down'></i>caret-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-left', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-left'></i>caret-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-right', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-right'></i>caret-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-square-down', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-square-down'></i>caret-square-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-square-left', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-square-left'></i>caret-square-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-square-right', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-square-right'></i>caret-square-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-square-up', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-square-up'></i>caret-square-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-caret-up', label: <><i className='fas fa-lg fa-fw me-2 fa-caret-up'></i>caret-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-cart-arrow-down', label: <><i className='fas fa-lg fa-fw me-2 fa-cart-arrow-down'></i>cart-arrow-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-cart-plus', label: <><i className='fas fa-lg fa-fw me-2 fa-cart-plus'></i>cart-plus</>},
  { value:'fas fa-lg fa-fw me-2 fa-certificate', label: <><i className='fas fa-lg fa-fw me-2 fa-certificate'></i>certificate</>},
  { value:'fas fa-lg fa-fw me-2 fa-chart-area', label: <><i className='fas fa-lg fa-fw me-2 fa-chart-area'></i>chart-area</>},
  { value:'fas fa-lg fa-fw me-2 fa-chart-bar', label: <><i className='fas fa-lg fa-fw me-2 fa-chart-bar'></i>chart-bar</>},
  { value:'fas fa-lg fa-fw me-2 fa-chart-line', label: <><i className='fas fa-lg fa-fw me-2 fa-chart-line'></i>chart-line</>},
  { value:'fas fa-lg fa-fw me-2 fa-chart-pie', label: <><i className='fas fa-lg fa-fw me-2 fa-chart-pie'></i>chart-pie</>},
  { value:'fas fa-lg fa-fw me-2 fa-check', label: <><i className='fas fa-lg fa-fw me-2 fa-check'></i>check</>},
  { value:'fas fa-lg fa-fw me-2 fa-check-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-check-circle'></i>check-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-check-square', label: <><i className='fas fa-lg fa-fw me-2 fa-check-square'></i>check-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-circle-down', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-circle-down'></i>chevron-circle-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-circle-left', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-circle-left'></i>chevron-circle-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-circle-right', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-circle-right'></i>chevron-circle-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-circle-up', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-circle-up'></i>chevron-circle-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-down', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-down'></i>chevron-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-left', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-left'></i>chevron-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-right', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-right'></i>chevron-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-chevron-up', label: <><i className='fas fa-lg fa-fw me-2 fa-chevron-up'></i>chevron-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-child', label: <><i className='fas fa-lg fa-fw me-2 fa-child'></i>child</>},
  { value:'fas fa-lg fa-fw me-2 fa-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-circle'></i>circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-circle-notch', label: <><i className='fas fa-lg fa-fw me-2 fa-circle-notch'></i>circle-notch</>},
  { value:'fas fa-lg fa-fw me-2 fa-clipboard', label: <><i className='fas fa-lg fa-fw me-2 fa-clipboard'></i>clipboard</>},
  { value:'fas fa-lg fa-fw me-2 fa-clock', label: <><i className='fas fa-lg fa-fw me-2 fa-clock'></i>clock</>},
  { value:'fas fa-lg fa-fw me-2 fa-clone', label: <><i className='fas fa-lg fa-fw me-2 fa-clone'></i>clone</>},
  { value:'fas fa-lg fa-fw me-2 fa-closed-captioning', label: <><i className='fas fa-lg fa-fw me-2 fa-closed-captioning'></i>closed-captioning</>},
  { value:'fas fa-lg fa-fw me-2 fa-cloud', label: <><i className='fas fa-lg fa-fw me-2 fa-cloud'></i>cloud</>},
  { value:'fas fa-lg fa-fw me-2 fa-code', label: <><i className='fas fa-lg fa-fw me-2 fa-code'></i>code</>},
  { value:'fas fa-lg fa-fw me-2 fa-code-branch', label: <><i className='fas fa-lg fa-fw me-2 fa-code-branch'></i>code-branch</>},
  { value:'fas fa-lg fa-fw me-2 fa-coffee', label: <><i className='fas fa-lg fa-fw me-2 fa-coffee'></i>coffee</>},
  { value:'fas fa-lg fa-fw me-2 fa-cog', label: <><i className='fas fa-lg fa-fw me-2 fa-cog'></i>cog</>},
  { value:'fas fa-lg fa-fw me-2 fa-cogs', label: <><i className='fas fa-lg fa-fw me-2 fa-cogs'></i>cogs</>},
  { value:'fas fa-lg fa-fw me-2 fa-columns', label: <><i className='fas fa-lg fa-fw me-2 fa-columns'></i>columns</>},
  { value:'fas fa-lg fa-fw me-2 fa-comment', label: <><i className='fas fa-lg fa-fw me-2 fa-comment'></i>comment</>},
  { value:'fas fa-lg fa-fw me-2 fa-comment-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-comment-alt'></i>comment-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-comments', label: <><i className='fas fa-lg fa-fw me-2 fa-comments'></i>comments</>},
  { value:'fas fa-lg fa-fw me-2 fa-compass', label: <><i className='fas fa-lg fa-fw me-2 fa-compass'></i>compass</>},
  { value:'fas fa-lg fa-fw me-2 fa-compress', label: <><i className='fas fa-lg fa-fw me-2 fa-compress'></i>compress</>},
  { value:'fas fa-lg fa-fw me-2 fa-copy', label: <><i className='fas fa-lg fa-fw me-2 fa-copy'></i>copy</>},
  { value:'fas fa-lg fa-fw me-2 fa-copyright', label: <><i className='fas fa-lg fa-fw me-2 fa-copyright'></i>copyright</>},
  { value:'fas fa-lg fa-fw me-2 fa-credit-card', label: <><i className='fas fa-lg fa-fw me-2 fa-credit-card'></i>credit-card</>},
  { value:'fas fa-lg fa-fw me-2 fa-crop', label: <><i className='fas fa-lg fa-fw me-2 fa-crop'></i>crop</>},
  { value:'fas fa-lg fa-fw me-2 fa-crosshairs', label: <><i className='fas fa-lg fa-fw me-2 fa-crosshairs'></i>crosshairs</>},
  { value:'fas fa-lg fa-fw me-2 fa-cube', label: <><i className='fas fa-lg fa-fw me-2 fa-cube'></i>cube</>},
  { value:'fas fa-lg fa-fw me-2 fa-cubes', label: <><i className='fas fa-lg fa-fw me-2 fa-cubes'></i>cubes</>},
  { value:'fas fa-lg fa-fw me-2 fa-cut', label: <><i className='fas fa-lg fa-fw me-2 fa-cut'></i>cut</>},
  { value:'fas fa-lg fa-fw me-2 fa-database', label: <><i className='fas fa-lg fa-fw me-2 fa-database'></i>database</>},
  { value:'fas fa-lg fa-fw me-2 fa-deaf', label: <><i className='fas fa-lg fa-fw me-2 fa-deaf'></i>deaf</>},
  { value:'fas fa-lg fa-fw me-2 fa-dollar-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-dollar-sign'></i>dollar-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-dot-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-dot-circle'></i>dot-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-download', label: <><i className='fas fa-lg fa-fw me-2 fa-download'></i>download</>},
  { value:'fas fa-lg fa-fw me-2 fa-edit', label: <><i className='fas fa-lg fa-fw me-2 fa-edit'></i>edit</>},
  { value:'fas fa-lg fa-fw me-2 fa-eject', label: <><i className='fas fa-lg fa-fw me-2 fa-eject'></i>eject</>},
  { value:'fas fa-lg fa-fw me-2 fa-ellipsis-h', label: <><i className='fas fa-lg fa-fw me-2 fa-ellipsis-h'></i>ellipsis-h</>},
  { value:'fas fa-lg fa-fw me-2 fa-ellipsis-v', label: <><i className='fas fa-lg fa-fw me-2 fa-ellipsis-v'></i>ellipsis-v</>},
  { value:'fas fa-lg fa-fw me-2 fa-envelope', label: <><i className='fas fa-lg fa-fw me-2 fa-envelope'></i>envelope</>},
  { value:'fas fa-lg fa-fw me-2 fa-envelope-open', label: <><i className='fas fa-lg fa-fw me-2 fa-envelope-open'></i>envelope-open</>},
  { value:'fas fa-lg fa-fw me-2 fa-envelope-square', label: <><i className='fas fa-lg fa-fw me-2 fa-envelope-square'></i>envelope-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-eraser', label: <><i className='fas fa-lg fa-fw me-2 fa-eraser'></i>eraser</>},
  { value:'fas fa-lg fa-fw me-2 fa-euro-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-euro-sign'></i>euro-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-exchange-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-exchange-alt'></i>exchange-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-exclamation', label: <><i className='fas fa-lg fa-fw me-2 fa-exclamation'></i>exclamation</>},
  { value:'fas fa-lg fa-fw me-2 fa-exclamation-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-exclamation-circle'></i>exclamation-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-exclamation-triangle', label: <><i className='fas fa-lg fa-fw me-2 fa-exclamation-triangle'></i>exclamation-triangle</>},
  { value:'fas fa-lg fa-fw me-2 fa-expand', label: <><i className='fas fa-lg fa-fw me-2 fa-expand'></i>expand</>},
  { value:'fas fa-lg fa-fw me-2 fa-expand-arrows-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-expand-arrows-alt'></i>expand-arrows-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-external-link-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-external-link-alt'></i>external-link-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-external-link-square-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-external-link-square-alt'></i>external-link-square-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-eye', label: <><i className='fas fa-lg fa-fw me-2 fa-eye'></i>eye</>},
  { value:'fas fa-lg fa-fw me-2 fa-eye-dropper', label: <><i className='fas fa-lg fa-fw me-2 fa-eye-dropper'></i>eye-dropper</>},
  { value:'fas fa-lg fa-fw me-2 fa-eye-slash', label: <><i className='fas fa-lg fa-fw me-2 fa-eye-slash'></i>eye-slash</>},
  { value:'fas fa-lg fa-fw me-2 fa-fast-backward', label: <><i className='fas fa-lg fa-fw me-2 fa-fast-backward'></i>fast-backward</>},
  { value:'fas fa-lg fa-fw me-2 fa-fast-forward', label: <><i className='fas fa-lg fa-fw me-2 fa-fast-forward'></i>fast-forward</>},
  { value:'fas fa-lg fa-fw me-2 fa-fax', label: <><i className='fas fa-lg fa-fw me-2 fa-fax'></i>fax</>},
  { value:'fas fa-lg fa-fw me-2 fa-female', label: <><i className='fas fa-lg fa-fw me-2 fa-female'></i>female</>},
  { value:'fas fa-lg fa-fw me-2 fa-fighter-jet', label: <><i className='fas fa-lg fa-fw me-2 fa-fighter-jet'></i>fighter-jet</>},
  { value:'fas fa-lg fa-fw me-2 fa-file', label: <><i className='fas fa-lg fa-fw me-2 fa-file'></i>file</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-file-alt'></i>file-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-archive', label: <><i className='fas fa-lg fa-fw me-2 fa-file-archive'></i>file-archive</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-audio', label: <><i className='fas fa-lg fa-fw me-2 fa-file-audio'></i>file-audio</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-code', label: <><i className='fas fa-lg fa-fw me-2 fa-file-code'></i>file-code</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-excel', label: <><i className='fas fa-lg fa-fw me-2 fa-file-excel'></i>file-excel</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-image', label: <><i className='fas fa-lg fa-fw me-2 fa-file-image'></i>file-image</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-pdf', label: <><i className='fas fa-lg fa-fw me-2 fa-file-pdf'></i>file-pdf</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-powerpoint', label: <><i className='fas fa-lg fa-fw me-2 fa-file-powerpoint'></i>file-powerpoint</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-video', label: <><i className='fas fa-lg fa-fw me-2 fa-file-video'></i>file-video</>},
  { value:'fas fa-lg fa-fw me-2 fa-file-word', label: <><i className='fas fa-lg fa-fw me-2 fa-file-word'></i>file-word</>},
  { value:'fas fa-lg fa-fw me-2 fa-film', label: <><i className='fas fa-lg fa-fw me-2 fa-film'></i>film</>},
  { value:'fas fa-lg fa-fw me-2 fa-filter', label: <><i className='fas fa-lg fa-fw me-2 fa-filter'></i>filter</>},
  { value:'fas fa-lg fa-fw me-2 fa-fire', label: <><i className='fas fa-lg fa-fw me-2 fa-fire'></i>fire</>},
  { value:'fas fa-lg fa-fw me-2 fa-fire-extinguisher', label: <><i className='fas fa-lg fa-fw me-2 fa-fire-extinguisher'></i>fire-extinguisher</>},
  { value:'fas fa-lg fa-fw me-2 fa-flag', label: <><i className='fas fa-lg fa-fw me-2 fa-flag'></i>flag</>},
  { value:'fas fa-lg fa-fw me-2 fa-flag-checkered', label: <><i className='fas fa-lg fa-fw me-2 fa-flag-checkered'></i>flag-checkered</>},
  { value:'fas fa-lg fa-fw me-2 fa-flask', label: <><i className='fas fa-lg fa-fw me-2 fa-flask'></i>flask</>},
  { value:'fas fa-lg fa-fw me-2 fa-folder', label: <><i className='fas fa-lg fa-fw me-2 fa-folder'></i>folder</>},
  { value:'fas fa-lg fa-fw me-2 fa-folder-open', label: <><i className='fas fa-lg fa-fw me-2 fa-folder-open'></i>folder-open</>},
  { value:'fas fa-lg fa-fw me-2 fa-font', label: <><i className='fas fa-lg fa-fw me-2 fa-font'></i>font</>},
  { value:'fas fa-lg fa-fw me-2 fa-forward', label: <><i className='fas fa-lg fa-fw me-2 fa-forward'></i>forward</>},
  { value:'fas fa-lg fa-fw me-2 fa-frown', label: <><i className='fas fa-lg fa-fw me-2 fa-frown'></i>frown</>},
  { value:'fas fa-lg fa-fw me-2 fa-futbol', label: <><i className='fas fa-lg fa-fw me-2 fa-futbol'></i>futbol</>},
  { value:'fas fa-lg fa-fw me-2 fa-gamepad', label: <><i className='fas fa-lg fa-fw me-2 fa-gamepad'></i>gamepad</>},
  { value:'fas fa-lg fa-fw me-2 fa-gavel', label: <><i className='fas fa-lg fa-fw me-2 fa-gavel'></i>gavel</>},
  { value:'fas fa-lg fa-fw me-2 fa-gem', label: <><i className='fas fa-lg fa-fw me-2 fa-gem'></i>gem</>},
  { value:'fas fa-lg fa-fw me-2 fa-genderless', label: <><i className='fas fa-lg fa-fw me-2 fa-genderless'></i>genderless</>},
  { value:'fas fa-lg fa-fw me-2 fa-gift', label: <><i className='fas fa-lg fa-fw me-2 fa-gift'></i>gift</>},
  { value:'fas fa-lg fa-fw me-2 fa-glass-martini', label: <><i className='fas fa-lg fa-fw me-2 fa-glass-martini'></i>glass-martini</>},
  { value:'fas fa-lg fa-fw me-2 fa-globe', label: <><i className='fas fa-lg fa-fw me-2 fa-globe'></i>globe</>},
  { value:'fas fa-lg fa-fw me-2 fa-graduation-cap', label: <><i className='fas fa-lg fa-fw me-2 fa-graduation-cap'></i>graduation-cap</>},
  { value:'fas fa-lg fa-fw me-2 fa-h-square', label: <><i className='fas fa-lg fa-fw me-2 fa-h-square'></i>h-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-lizard', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-lizard'></i>hand-lizard</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-paper', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-paper'></i>hand-paper</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-peace', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-peace'></i>hand-peace</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-point-down', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-point-down'></i>hand-point-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-point-left', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-point-left'></i>hand-point-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-point-right', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-point-right'></i>hand-point-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-point-up', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-point-up'></i>hand-point-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-pointer', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-pointer'></i>hand-pointer</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-rock', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-rock'></i>hand-rock</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-scissors', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-scissors'></i>hand-scissors</>},
  { value:'fas fa-lg fa-fw me-2 fa-hand-spock', label: <><i className='fas fa-lg fa-fw me-2 fa-hand-spock'></i>hand-spock</>},
  { value:'fas fa-lg fa-fw me-2 fa-handshake', label: <><i className='fas fa-lg fa-fw me-2 fa-handshake'></i>handshake</>},
  { value:'fas fa-lg fa-fw me-2 fa-hashtag', label: <><i className='fas fa-lg fa-fw me-2 fa-hashtag'></i>hashtag</>},
  { value:'fas fa-lg fa-fw me-2 fa-hdd', label: <><i className='fas fa-lg fa-fw me-2 fa-hdd'></i>hdd</>},
  { value:'fas fa-lg fa-fw me-2 fa-heading', label: <><i className='fas fa-lg fa-fw me-2 fa-heading'></i>heading</>},
  { value:'fas fa-lg fa-fw me-2 fa-headphones', label: <><i className='fas fa-lg fa-fw me-2 fa-headphones'></i>headphones</>},
  { value:'fas fa-lg fa-fw me-2 fa-heart', label: <><i className='fas fa-lg fa-fw me-2 fa-heart'></i>heart</>},
  { value:'fas fa-lg fa-fw me-2 fa-heartbeat', label: <><i className='fas fa-lg fa-fw me-2 fa-heartbeat'></i>heartbeat</>},
  { value:'fas fa-lg fa-fw me-2 fa-history', label: <><i className='fas fa-lg fa-fw me-2 fa-history'></i>history</>},
  { value:'fas fa-lg fa-fw me-2 fa-home', label: <><i className='fas fa-lg fa-fw me-2 fa-home'></i>home</>},
  { value:'fas fa-lg fa-fw me-2 fa-hospital', label: <><i className='fas fa-lg fa-fw me-2 fa-hospital'></i>hospital</>},
  { value:'fas fa-lg fa-fw me-2 fa-hourglass', label: <><i className='fas fa-lg fa-fw me-2 fa-hourglass'></i>hourglass</>},
  { value:'fas fa-lg fa-fw me-2 fa-hourglass-end', label: <><i className='fas fa-lg fa-fw me-2 fa-hourglass-end'></i>hourglass-end</>},
  { value:'fas fa-lg fa-fw me-2 fa-hourglass-half', label: <><i className='fas fa-lg fa-fw me-2 fa-hourglass-half'></i>hourglass-half</>},
  { value:'fas fa-lg fa-fw me-2 fa-hourglass-start', label: <><i className='fas fa-lg fa-fw me-2 fa-hourglass-start'></i>hourglass-start</>},
  { value:'fas fa-lg fa-fw me-2 fa-i-cursor', label: <><i className='fas fa-lg fa-fw me-2 fa-i-cursor'></i>i-cursor</>},
  { value:'fas fa-lg fa-fw me-2 fa-id-badge', label: <><i className='fas fa-lg fa-fw me-2 fa-id-badge'></i>id-badge</>},
  { value:'fas fa-lg fa-fw me-2 fa-id-card', label: <><i className='fas fa-lg fa-fw me-2 fa-id-card'></i>id-card</>},
  { value:'fas fa-lg fa-fw me-2 fa-image', label: <><i className='fas fa-lg fa-fw me-2 fa-image'></i>image</>},
  { value:'fas fa-lg fa-fw me-2 fa-images', label: <><i className='fas fa-lg fa-fw me-2 fa-images'></i>images</>},
  { value:'fas fa-lg fa-fw me-2 fa-inbox', label: <><i className='fas fa-lg fa-fw me-2 fa-inbox'></i>inbox</>},
  { value:'fas fa-lg fa-fw me-2 fa-indent', label: <><i className='fas fa-lg fa-fw me-2 fa-indent'></i>indent</>},
  { value:'fas fa-lg fa-fw me-2 fa-industry', label: <><i className='fas fa-lg fa-fw me-2 fa-industry'></i>industry</>},
  { value:'fas fa-lg fa-fw me-2 fa-info', label: <><i className='fas fa-lg fa-fw me-2 fa-info'></i>info</>},
  { value:'fas fa-lg fa-fw me-2 fa-info-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-info-circle'></i>info-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-italic', label: <><i className='fas fa-lg fa-fw me-2 fa-italic'></i>italic</>},
  { value:'fas fa-lg fa-fw me-2 fa-key', label: <><i className='fas fa-lg fa-fw me-2 fa-key'></i>key</>},
  { value:'fas fa-lg fa-fw me-2 fa-keyboard', label: <><i className='fas fa-lg fa-fw me-2 fa-keyboard'></i>keyboard</>},
  { value:'fas fa-lg fa-fw me-2 fa-language', label: <><i className='fas fa-lg fa-fw me-2 fa-language'></i>language</>},
  { value:'fas fa-lg fa-fw me-2 fa-laptop', label: <><i className='fas fa-lg fa-fw me-2 fa-laptop'></i>laptop</>},
  { value:'fas fa-lg fa-fw me-2 fa-leaf', label: <><i className='fas fa-lg fa-fw me-2 fa-leaf'></i>leaf</>},
  { value:'fas fa-lg fa-fw me-2 fa-lemon', label: <><i className='fas fa-lg fa-fw me-2 fa-lemon'></i>lemon</>},
  { value:'fas fa-lg fa-fw me-2 fa-level-down-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-level-down-alt'></i>level-down-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-level-up-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-level-up-alt'></i>level-up-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-life-ring', label: <><i className='fas fa-lg fa-fw me-2 fa-life-ring'></i>life-ring</>},
  { value:'fas fa-lg fa-fw me-2 fa-lightbulb', label: <><i className='fas fa-lg fa-fw me-2 fa-lightbulb'></i>lightbulb</>},
  { value:'fas fa-lg fa-fw me-2 fa-link', label: <><i className='fas fa-lg fa-fw me-2 fa-link'></i>link</>},
  { value:'fas fa-lg fa-fw me-2 fa-lira-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-lira-sign'></i>lira-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-list', label: <><i className='fas fa-lg fa-fw me-2 fa-list'></i>list</>},
  { value:'fas fa-lg fa-fw me-2 fa-list-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-list-alt'></i>list-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-list-ol', label: <><i className='fas fa-lg fa-fw me-2 fa-list-ol'></i>list-ol</>},
  { value:'fas fa-lg fa-fw me-2 fa-list-ul', label: <><i className='fas fa-lg fa-fw me-2 fa-list-ul'></i>list-ul</>},
  { value:'fas fa-lg fa-fw me-2 fa-location-arrow', label: <><i className='fas fa-lg fa-fw me-2 fa-location-arrow'></i>location-arrow</>},
  { value:'fas fa-lg fa-fw me-2 fa-lock', label: <><i className='fas fa-lg fa-fw me-2 fa-lock'></i>lock</>},
  { value:'fas fa-lg fa-fw me-2 fa-lock-open', label: <><i className='fas fa-lg fa-fw me-2 fa-lock-open'></i>lock-open</>},
  { value:'fas fa-lg fa-fw me-2 fa-long-arrow-alt-down', label: <><i className='fas fa-lg fa-fw me-2 fa-long-arrow-alt-down'></i>long-arrow-alt-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-long-arrow-alt-left', label: <><i className='fas fa-lg fa-fw me-2 fa-long-arrow-alt-left'></i>long-arrow-alt-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-long-arrow-alt-right', label: <><i className='fas fa-lg fa-fw me-2 fa-long-arrow-alt-right'></i>long-arrow-alt-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-long-arrow-alt-up', label: <><i className='fas fa-lg fa-fw me-2 fa-long-arrow-alt-up'></i>long-arrow-alt-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-low-vision', label: <><i className='fas fa-lg fa-fw me-2 fa-low-vision'></i>low-vision</>},
  { value:'fas fa-lg fa-fw me-2 fa-magic', label: <><i className='fas fa-lg fa-fw me-2 fa-magic'></i>magic</>},
  { value:'fas fa-lg fa-fw me-2 fa-magnet', label: <><i className='fas fa-lg fa-fw me-2 fa-magnet'></i>magnet</>},
  { value:'fas fa-lg fa-fw me-2 fa-male', label: <><i className='fas fa-lg fa-fw me-2 fa-male'></i>male</>},
  { value:'fas fa-lg fa-fw me-2 fa-map', label: <><i className='fas fa-lg fa-fw me-2 fa-map'></i>map</>},
  { value:'fas fa-lg fa-fw me-2 fa-map-marker', label: <><i className='fas fa-lg fa-fw me-2 fa-map-marker'></i>map-marker</>},
  { value:'fas fa-lg fa-fw me-2 fa-map-marker-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-map-marker-alt'></i>map-marker-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-map-pin', label: <><i className='fas fa-lg fa-fw me-2 fa-map-pin'></i>map-pin</>},
  { value:'fas fa-lg fa-fw me-2 fa-map-signs', label: <><i className='fas fa-lg fa-fw me-2 fa-map-signs'></i>map-signs</>},
  { value:'fas fa-lg fa-fw me-2 fa-mars', label: <><i className='fas fa-lg fa-fw me-2 fa-mars'></i>mars</>},
  { value:'fas fa-lg fa-fw me-2 fa-mars-double', label: <><i className='fas fa-lg fa-fw me-2 fa-mars-double'></i>mars-double</>},
  { value:'fas fa-lg fa-fw me-2 fa-mars-stroke', label: <><i className='fas fa-lg fa-fw me-2 fa-mars-stroke'></i>mars-stroke</>},
  { value:'fas fa-lg fa-fw me-2 fa-mars-stroke-h', label: <><i className='fas fa-lg fa-fw me-2 fa-mars-stroke-h'></i>mars-stroke-h</>},
  { value:'fas fa-lg fa-fw me-2 fa-mars-stroke-v', label: <><i className='fas fa-lg fa-fw me-2 fa-mars-stroke-v'></i>mars-stroke-v</>},
  { value:'fas fa-lg fa-fw me-2 fa-medkit', label: <><i className='fas fa-lg fa-fw me-2 fa-medkit'></i>medkit</>},
  { value:'fas fa-lg fa-fw me-2 fa-meh', label: <><i className='fas fa-lg fa-fw me-2 fa-meh'></i>meh</>},
  { value:'fas fa-lg fa-fw me-2 fa-mercury', label: <><i className='fas fa-lg fa-fw me-2 fa-mercury'></i>mercury</>},
  { value:'fas fa-lg fa-fw me-2 fa-microchip', label: <><i className='fas fa-lg fa-fw me-2 fa-microchip'></i>microchip</>},
  { value:'fas fa-lg fa-fw me-2 fa-microphone', label: <><i className='fas fa-lg fa-fw me-2 fa-microphone'></i>microphone</>},
  { value:'fas fa-lg fa-fw me-2 fa-microphone-slash', label: <><i className='fas fa-lg fa-fw me-2 fa-microphone-slash'></i>microphone-slash</>},
  { value:'fas fa-lg fa-fw me-2 fa-minus', label: <><i className='fas fa-lg fa-fw me-2 fa-minus'></i>minus</>},
  { value:'fas fa-lg fa-fw me-2 fa-minus-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-minus-circle'></i>minus-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-minus-square', label: <><i className='fas fa-lg fa-fw me-2 fa-minus-square'></i>minus-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-mobile-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-mobile-alt'></i>mobile-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-money-bill-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-money-bill-alt'></i>money-bill-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-moon', label: <><i className='fas fa-lg fa-fw me-2 fa-moon'></i>moon</>},
  { value:'fas fa-lg fa-fw me-2 fa-motorcycle', label: <><i className='fas fa-lg fa-fw me-2 fa-motorcycle'></i>motorcycle</>},
  { value:'fas fa-lg fa-fw me-2 fa-mouse-pointer', label: <><i className='fas fa-lg fa-fw me-2 fa-mouse-pointer'></i>mouse-pointer</>},
  { value:'fas fa-lg fa-fw me-2 fa-music', label: <><i className='fas fa-lg fa-fw me-2 fa-music'></i>music</>},
  { value:'fas fa-lg fa-fw me-2 fa-neuter', label: <><i className='fas fa-lg fa-fw me-2 fa-neuter'></i>neuter</>},
  { value:'fas fa-lg fa-fw me-2 fa-newspaper', label: <><i className='fas fa-lg fa-fw me-2 fa-newspaper'></i>newspaper</>},
  { value:'fas fa-lg fa-fw me-2 fa-object-group', label: <><i className='fas fa-lg fa-fw me-2 fa-object-group'></i>object-group</>},
  { value:'fas fa-lg fa-fw me-2 fa-object-ungroup', label: <><i className='fas fa-lg fa-fw me-2 fa-object-ungroup'></i>object-ungroup</>},
  { value:'fas fa-lg fa-fw me-2 fa-outdent', label: <><i className='fas fa-lg fa-fw me-2 fa-outdent'></i>outdent</>},
  { value:'fas fa-lg fa-fw me-2 fa-paint-brush', label: <><i className='fas fa-lg fa-fw me-2 fa-paint-brush'></i>paint-brush</>},
  { value:'fas fa-lg fa-fw me-2 fa-paper-plane', label: <><i className='fas fa-lg fa-fw me-2 fa-paper-plane'></i>paper-plane</>},
  { value:'fas fa-lg fa-fw me-2 fa-paperclip', label: <><i className='fas fa-lg fa-fw me-2 fa-paperclip'></i>paperclip</>},
  { value:'fas fa-lg fa-fw me-2 fa-paragraph', label: <><i className='fas fa-lg fa-fw me-2 fa-paragraph'></i>paragraph</>},
  { value:'fas fa-lg fa-fw me-2 fa-paste', label: <><i className='fas fa-lg fa-fw me-2 fa-paste'></i>paste</>},
  { value:'fas fa-lg fa-fw me-2 fa-pause', label: <><i className='fas fa-lg fa-fw me-2 fa-pause'></i>pause</>},
  { value:'fas fa-lg fa-fw me-2 fa-pause-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-pause-circle'></i>pause-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-paw', label: <><i className='fas fa-lg fa-fw me-2 fa-paw'></i>paw</>},
  { value:'fas fa-lg fa-fw me-2 fa-pen-square', label: <><i className='fas fa-lg fa-fw me-2 fa-pen-square'></i>pen-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-pencil-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-pencil-alt'></i>pencil-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-percent', label: <><i className='fas fa-lg fa-fw me-2 fa-percent'></i>percent</>},
  { value:'fas fa-lg fa-fw me-2 fa-phone', label: <><i className='fas fa-lg fa-fw me-2 fa-phone'></i>phone</>},
  { value:'fas fa-lg fa-fw me-2 fa-phone-square', label: <><i className='fas fa-lg fa-fw me-2 fa-phone-square'></i>phone-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-phone-volume', label: <><i className='fas fa-lg fa-fw me-2 fa-phone-volume'></i>phone-volume</>},
  { value:'fas fa-lg fa-fw me-2 fa-plane', label: <><i className='fas fa-lg fa-fw me-2 fa-plane'></i>plane</>},
  { value:'fas fa-lg fa-fw me-2 fa-play', label: <><i className='fas fa-lg fa-fw me-2 fa-play'></i>play</>},
  { value:'fas fa-lg fa-fw me-2 fa-play-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-play-circle'></i>play-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-plug', label: <><i className='fas fa-lg fa-fw me-2 fa-plug'></i>plug</>},
  { value:'fas fa-lg fa-fw me-2 fa-plus', label: <><i className='fas fa-lg fa-fw me-2 fa-plus'></i>plus</>},
  { value:'fas fa-lg fa-fw me-2 fa-plus-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-plus-circle'></i>plus-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-plus-square', label: <><i className='fas fa-lg fa-fw me-2 fa-plus-square'></i>plus-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-podcast', label: <><i className='fas fa-lg fa-fw me-2 fa-podcast'></i>podcast</>},
  { value:'fas fa-lg fa-fw me-2 fa-pound-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-pound-sign'></i>pound-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-power-off', label: <><i className='fas fa-lg fa-fw me-2 fa-power-off'></i>power-off</>},
  { value:'fas fa-lg fa-fw me-2 fa-print', label: <><i className='fas fa-lg fa-fw me-2 fa-print'></i>print</>},
  { value:'fas fa-lg fa-fw me-2 fa-puzzle-piece', label: <><i className='fas fa-lg fa-fw me-2 fa-puzzle-piece'></i>puzzle-piece</>},
  { value:'fas fa-lg fa-fw me-2 fa-qrcode', label: <><i className='fas fa-lg fa-fw me-2 fa-qrcode'></i>qrcode</>},
  { value:'fas fa-lg fa-fw me-2 fa-question', label: <><i className='fas fa-lg fa-fw me-2 fa-question'></i>question</>},
  { value:'fas fa-lg fa-fw me-2 fa-question-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-question-circle'></i>question-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-quote-left', label: <><i className='fas fa-lg fa-fw me-2 fa-quote-left'></i>quote-left</>},
  { value:'fas fa-lg fa-fw me-2 fa-quote-right', label: <><i className='fas fa-lg fa-fw me-2 fa-quote-right'></i>quote-right</>},
  { value:'fas fa-lg fa-fw me-2 fa-random', label: <><i className='fas fa-lg fa-fw me-2 fa-random'></i>random</>},
  { value:'fas fa-lg fa-fw me-2 fa-recycle', label: <><i className='fas fa-lg fa-fw me-2 fa-recycle'></i>recycle</>},
  { value:'fas fa-lg fa-fw me-2 fa-redo', label: <><i className='fas fa-lg fa-fw me-2 fa-redo'></i>redo</>},
  { value:'fas fa-lg fa-fw me-2 fa-redo-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-redo-alt'></i>redo-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-registered', label: <><i className='fas fa-lg fa-fw me-2 fa-registered'></i>registered</>},
  { value:'fas fa-lg fa-fw me-2 fa-reply', label: <><i className='fas fa-lg fa-fw me-2 fa-reply'></i>reply</>},
  { value:'fas fa-lg fa-fw me-2 fa-reply-all', label: <><i className='fas fa-lg fa-fw me-2 fa-reply-all'></i>reply-all</>},
  { value:'fas fa-lg fa-fw me-2 fa-retweet', label: <><i className='fas fa-lg fa-fw me-2 fa-retweet'></i>retweet</>},
  { value:'fas fa-lg fa-fw me-2 fa-road', label: <><i className='fas fa-lg fa-fw me-2 fa-road'></i>road</>},
  { value:'fas fa-lg fa-fw me-2 fa-rocket', label: <><i className='fas fa-lg fa-fw me-2 fa-rocket'></i>rocket</>},
  { value:'fas fa-lg fa-fw me-2 fa-rss', label: <><i className='fas fa-lg fa-fw me-2 fa-rss'></i>rss</>},
  { value:'fas fa-lg fa-fw me-2 fa-rss-square', label: <><i className='fas fa-lg fa-fw me-2 fa-rss-square'></i>rss-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-ruble-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-ruble-sign'></i>ruble-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-rupee-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-rupee-sign'></i>rupee-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-save', label: <><i className='fas fa-lg fa-fw me-2 fa-save'></i>save</>},
  { value:'fas fa-lg fa-fw me-2 fa-search', label: <><i className='fas fa-lg fa-fw me-2 fa-search'></i>search</>},
  { value:'fas fa-lg fa-fw me-2 fa-search-minus', label: <><i className='fas fa-lg fa-fw me-2 fa-search-minus'></i>search-minus</>},
  { value:'fas fa-lg fa-fw me-2 fa-search-plus', label: <><i className='fas fa-lg fa-fw me-2 fa-search-plus'></i>search-plus</>},
  { value:'fas fa-lg fa-fw me-2 fa-server', label: <><i className='fas fa-lg fa-fw me-2 fa-server'></i>server</>},
  { value:'fas fa-lg fa-fw me-2 fa-share', label: <><i className='fas fa-lg fa-fw me-2 fa-share'></i>share</>},
  { value:'fas fa-lg fa-fw me-2 fa-share-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-share-alt'></i>share-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-share-alt-square', label: <><i className='fas fa-lg fa-fw me-2 fa-share-alt-square'></i>share-alt-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-share-square', label: <><i className='fas fa-lg fa-fw me-2 fa-share-square'></i>share-square</>},
  { value:'fas fa-lg fa-fw me-2 fa-shekel-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-shekel-sign'></i>shekel-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-shield-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-shield-alt'></i>shield-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-ship', label: <><i className='fas fa-lg fa-fw me-2 fa-ship'></i>ship</>},
  { value:'fas fa-lg fa-fw me-2 fa-shopping-bag', label: <><i className='fas fa-lg fa-fw me-2 fa-shopping-bag'></i>shopping-bag</>},
  { value:'fas fa-lg fa-fw me-2 fa-shopping-basket', label: <><i className='fas fa-lg fa-fw me-2 fa-shopping-basket'></i>shopping-basket</>},
  { value:'fas fa-lg fa-fw me-2 fa-shopping-cart', label: <><i className='fas fa-lg fa-fw me-2 fa-shopping-cart'></i>shopping-cart</>},
  { value:'fas fa-lg fa-fw me-2 fa-shower', label: <><i className='fas fa-lg fa-fw me-2 fa-shower'></i>shower</>},
  { value:'fas fa-lg fa-fw me-2 fa-sign-in-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-sign-in-alt'></i>sign-in-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-sign-language', label: <><i className='fas fa-lg fa-fw me-2 fa-sign-language'></i>sign-language</>},
  { value:'fas fa-lg fa-fw me-2 fa-sign-out-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-sign-out-alt'></i>sign-out-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-signal', label: <><i className='fas fa-lg fa-fw me-2 fa-signal'></i>signal</>},
  { value:'fas fa-lg fa-fw me-2 fa-sitemap', label: <><i className='fas fa-lg fa-fw me-2 fa-sitemap'></i>sitemap</>},
  { value:'fas fa-lg fa-fw me-2 fa-sliders-h', label: <><i className='fas fa-lg fa-fw me-2 fa-sliders-h'></i>sliders-h</>},
  { value:'fas fa-lg fa-fw me-2 fa-smile', label: <><i className='fas fa-lg fa-fw me-2 fa-smile'></i>smile</>},
  { value:'fas fa-lg fa-fw me-2 fa-snowflake', label: <><i className='fas fa-lg fa-fw me-2 fa-snowflake'></i>snowflake</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort', label: <><i className='fas fa-lg fa-fw me-2 fa-sort'></i>sort</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-alpha-down', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-alpha-down'></i>sort-alpha-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-alpha-up', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-alpha-up'></i>sort-alpha-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-amount-down', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-amount-down'></i>sort-amount-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-amount-up', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-amount-up'></i>sort-amount-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-down', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-down'></i>sort-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-numeric-down', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-numeric-down'></i>sort-numeric-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-numeric-up', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-numeric-up'></i>sort-numeric-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-sort-up', label: <><i className='fas fa-lg fa-fw me-2 fa-sort-up'></i>sort-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-space-shuttle', label: <><i className='fas fa-lg fa-fw me-2 fa-space-shuttle'></i>space-shuttle</>},
  { value:'fas fa-lg fa-fw me-2 fa-spinner', label: <><i className='fas fa-lg fa-fw me-2 fa-spinner'></i>spinner</>},
  { value:'fas fa-lg fa-fw me-2 fa-square', label: <><i className='fas fa-lg fa-fw me-2 fa-square'></i>square</>},
  { value:'fas fa-lg fa-fw me-2 fa-star', label: <><i className='fas fa-lg fa-fw me-2 fa-star'></i>star</>},
  { value:'fas fa-lg fa-fw me-2 fa-star-half', label: <><i className='fas fa-lg fa-fw me-2 fa-star-half'></i>star-half</>},
  { value:'fas fa-lg fa-fw me-2 fa-step-backward', label: <><i className='fas fa-lg fa-fw me-2 fa-step-backward'></i>step-backward</>},
  { value:'fas fa-lg fa-fw me-2 fa-step-forward', label: <><i className='fas fa-lg fa-fw me-2 fa-step-forward'></i>step-forward</>},
  { value:'fas fa-lg fa-fw me-2 fa-stethoscope', label: <><i className='fas fa-lg fa-fw me-2 fa-stethoscope'></i>stethoscope</>},
  { value:'fas fa-lg fa-fw me-2 fa-sticky-note', label: <><i className='fas fa-lg fa-fw me-2 fa-sticky-note'></i>sticky-note</>},
  { value:'fas fa-lg fa-fw me-2 fa-stop', label: <><i className='fas fa-lg fa-fw me-2 fa-stop'></i>stop</>},
  { value:'fas fa-lg fa-fw me-2 fa-stop-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-stop-circle'></i>stop-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-stopwatch', label: <><i className='fas fa-lg fa-fw me-2 fa-stopwatch'></i>stopwatch</>},
  { value:'fas fa-lg fa-fw me-2 fa-street-view', label: <><i className='fas fa-lg fa-fw me-2 fa-street-view'></i>street-view</>},
  { value:'fas fa-lg fa-fw me-2 fa-strikethrough', label: <><i className='fas fa-lg fa-fw me-2 fa-strikethrough'></i>strikethrough</>},
  { value:'fas fa-lg fa-fw me-2 fa-subscript', label: <><i className='fas fa-lg fa-fw me-2 fa-subscript'></i>subscript</>},
  { value:'fas fa-lg fa-fw me-2 fa-subway', label: <><i className='fas fa-lg fa-fw me-2 fa-subway'></i>subway</>},
  { value:'fas fa-lg fa-fw me-2 fa-suitcase', label: <><i className='fas fa-lg fa-fw me-2 fa-suitcase'></i>suitcase</>},
  { value:'fas fa-lg fa-fw me-2 fa-sun', label: <><i className='fas fa-lg fa-fw me-2 fa-sun'></i>sun</>},
  { value:'fas fa-lg fa-fw me-2 fa-superscript', label: <><i className='fas fa-lg fa-fw me-2 fa-superscript'></i>superscript</>},
  { value:'fas fa-lg fa-fw me-2 fa-sync', label: <><i className='fas fa-lg fa-fw me-2 fa-sync'></i>sync</>},
  { value:'fas fa-lg fa-fw me-2 fa-sync-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-sync-alt'></i>sync-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-table', label: <><i className='fas fa-lg fa-fw me-2 fa-table'></i>table</>},
  { value:'fas fa-lg fa-fw me-2 fa-tablet-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-tablet-alt'></i>tablet-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-tag', label: <><i className='fas fa-lg fa-fw me-2 fa-tag'></i>tag</>},
  { value:'fas fa-lg fa-fw me-2 fa-tags', label: <><i className='fas fa-lg fa-fw me-2 fa-tags'></i>tags</>},
  { value:'fas fa-lg fa-fw me-2 fa-tasks', label: <><i className='fas fa-lg fa-fw me-2 fa-tasks'></i>tasks</>},
  { value:'fas fa-lg fa-fw me-2 fa-taxi', label: <><i className='fas fa-lg fa-fw me-2 fa-taxi'></i>taxi</>},
  { value:'fas fa-lg fa-fw me-2 fa-terminal', label: <><i className='fas fa-lg fa-fw me-2 fa-terminal'></i>terminal</>},
  { value:'fas fa-lg fa-fw me-2 fa-text-height', label: <><i className='fas fa-lg fa-fw me-2 fa-text-height'></i>text-height</>},
  { value:'fas fa-lg fa-fw me-2 fa-text-width', label: <><i className='fas fa-lg fa-fw me-2 fa-text-width'></i>text-width</>},
  { value:'fas fa-lg fa-fw me-2 fa-th', label: <><i className='fas fa-lg fa-fw me-2 fa-th'></i>th</>},
  { value:'fas fa-lg fa-fw me-2 fa-th-large', label: <><i className='fas fa-lg fa-fw me-2 fa-th-large'></i>th-large</>},
  { value:'fas fa-lg fa-fw me-2 fa-th-list', label: <><i className='fas fa-lg fa-fw me-2 fa-th-list'></i>th-list</>},
  { value:'fas fa-lg fa-fw me-2 fa-thermometer-empty', label: <><i className='fas fa-lg fa-fw me-2 fa-thermometer-empty'></i>thermometer-empty</>},
  { value:'fas fa-lg fa-fw me-2 fa-thermometer-full', label: <><i className='fas fa-lg fa-fw me-2 fa-thermometer-full'></i>thermometer-full</>},
  { value:'fas fa-lg fa-fw me-2 fa-thermometer-half', label: <><i className='fas fa-lg fa-fw me-2 fa-thermometer-half'></i>thermometer-half</>},
  { value:'fas fa-lg fa-fw me-2 fa-thermometer-quarter', label: <><i className='fas fa-lg fa-fw me-2 fa-thermometer-quarter'></i>thermometer-quarter</>},
  { value:'fas fa-lg fa-fw me-2 fa-thermometer-three-quarters', label: <><i className='fas fa-lg fa-fw me-2 fa-thermometer-three-quarters'></i>thermometer-three-quarters</>},
  { value:'fas fa-lg fa-fw me-2 fa-thumbs-down', label: <><i className='fas fa-lg fa-fw me-2 fa-thumbs-down'></i>thumbs-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-thumbs-up', label: <><i className='fas fa-lg fa-fw me-2 fa-thumbs-up'></i>thumbs-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-thumbtack', label: <><i className='fas fa-lg fa-fw me-2 fa-thumbtack'></i>thumbtack</>},
  { value:'fas fa-lg fa-fw me-2 fa-ticket-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-ticket-alt'></i>ticket-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-times', label: <><i className='fas fa-lg fa-fw me-2 fa-times'></i>times</>},
  { value:'fas fa-lg fa-fw me-2 fa-times-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-times-circle'></i>times-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-tint', label: <><i className='fas fa-lg fa-fw me-2 fa-tint'></i>tint</>},
  { value:'fas fa-lg fa-fw me-2 fa-toggle-off', label: <><i className='fas fa-lg fa-fw me-2 fa-toggle-off'></i>toggle-off</>},
  { value:'fas fa-lg fa-fw me-2 fa-toggle-on', label: <><i className='fas fa-lg fa-fw me-2 fa-toggle-on'></i>toggle-on</>},
  { value:'fas fa-lg fa-fw me-2 fa-trademark', label: <><i className='fas fa-lg fa-fw me-2 fa-trademark'></i>trademark</>},
  { value:'fas fa-lg fa-fw me-2 fa-train', label: <><i className='fas fa-lg fa-fw me-2 fa-train'></i>train</>},
  { value:'fas fa-lg fa-fw me-2 fa-transgender', label: <><i className='fas fa-lg fa-fw me-2 fa-transgender'></i>transgender</>},
  { value:'fas fa-lg fa-fw me-2 fa-transgender-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-transgender-alt'></i>transgender-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-trash', label: <><i className='fas fa-lg fa-fw me-2 fa-trash'></i>trash</>},
  { value:'fas fa-lg fa-fw me-2 fa-trash-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-trash-alt'></i>trash-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-tree', label: <><i className='fas fa-lg fa-fw me-2 fa-tree'></i>tree</>},
  { value:'fas fa-lg fa-fw me-2 fa-trophy', label: <><i className='fas fa-lg fa-fw me-2 fa-trophy'></i>trophy</>},
  { value:'fas fa-lg fa-fw me-2 fa-truck', label: <><i className='fas fa-lg fa-fw me-2 fa-truck'></i>truck</>},
  { value:'fas fa-lg fa-fw me-2 fa-tty', label: <><i className='fas fa-lg fa-fw me-2 fa-tty'></i>tty</>},
  { value:'fas fa-lg fa-fw me-2 fa-tv', label: <><i className='fas fa-lg fa-fw me-2 fa-tv'></i>tv</>},
  { value:'fas fa-lg fa-fw me-2 fa-umbrella', label: <><i className='fas fa-lg fa-fw me-2 fa-umbrella'></i>umbrella</>},
  { value:'fas fa-lg fa-fw me-2 fa-underline', label: <><i className='fas fa-lg fa-fw me-2 fa-underline'></i>underline</>},
  { value:'fas fa-lg fa-fw me-2 fa-undo', label: <><i className='fas fa-lg fa-fw me-2 fa-undo'></i>undo</>},
  { value:'fas fa-lg fa-fw me-2 fa-undo-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-undo-alt'></i>undo-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-universal-access', label: <><i className='fas fa-lg fa-fw me-2 fa-universal-access'></i>universal-access</>},
  { value:'fas fa-lg fa-fw me-2 fa-university', label: <><i className='fas fa-lg fa-fw me-2 fa-university'></i>university</>},
  { value:'fas fa-lg fa-fw me-2 fa-unlink', label: <><i className='fas fa-lg fa-fw me-2 fa-unlink'></i>unlink</>},
  { value:'fas fa-lg fa-fw me-2 fa-unlock', label: <><i className='fas fa-lg fa-fw me-2 fa-unlock'></i>unlock</>},
  { value:'fas fa-lg fa-fw me-2 fa-unlock-alt', label: <><i className='fas fa-lg fa-fw me-2 fa-unlock-alt'></i>unlock-alt</>},
  { value:'fas fa-lg fa-fw me-2 fa-upload', label: <><i className='fas fa-lg fa-fw me-2 fa-upload'></i>upload</>},
  { value:'fas fa-lg fa-fw me-2 fa-user', label: <><i className='fas fa-lg fa-fw me-2 fa-user'></i>user</>},
  { value:'fas fa-lg fa-fw me-2 fa-user-circle', label: <><i className='fas fa-lg fa-fw me-2 fa-user-circle'></i>user-circle</>},
  { value:'fas fa-lg fa-fw me-2 fa-user-md', label: <><i className='fas fa-lg fa-fw me-2 fa-user-md'></i>user-md</>},
  { value:'fas fa-lg fa-fw me-2 fa-user-plus', label: <><i className='fas fa-lg fa-fw me-2 fa-user-plus'></i>user-plus</>},
  { value:'fas fa-lg fa-fw me-2 fa-user-secret', label: <><i className='fas fa-lg fa-fw me-2 fa-user-secret'></i>user-secret</>},
  { value:'fas fa-lg fa-fw me-2 fa-user-times', label: <><i className='fas fa-lg fa-fw me-2 fa-user-times'></i>user-times</>},
  { value:'fas fa-lg fa-fw me-2 fa-users', label: <><i className='fas fa-lg fa-fw me-2 fa-users'></i>users</>},
  { value:'fas fa-lg fa-fw me-2 fa-utensil-spoon', label: <><i className='fas fa-lg fa-fw me-2 fa-utensil-spoon'></i>utensil-spoon</>},
  { value:'fas fa-lg fa-fw me-2 fa-utensils', label: <><i className='fas fa-lg fa-fw me-2 fa-utensils'></i>utensils</>},
  { value:'fas fa-lg fa-fw me-2 fa-venus', label: <><i className='fas fa-lg fa-fw me-2 fa-venus'></i>venus</>},
  { value:'fas fa-lg fa-fw me-2 fa-venus-double', label: <><i className='fas fa-lg fa-fw me-2 fa-venus-double'></i>venus-double</>},
  { value:'fas fa-lg fa-fw me-2 fa-venus-mars', label: <><i className='fas fa-lg fa-fw me-2 fa-venus-mars'></i>venus-mars</>},
  { value:'fas fa-lg fa-fw me-2 fa-video', label: <><i className='fas fa-lg fa-fw me-2 fa-video'></i>video</>},
  { value:'fas fa-lg fa-fw me-2 fa-volume-down', label: <><i className='fas fa-lg fa-fw me-2 fa-volume-down'></i>volume-down</>},
  { value:'fas fa-lg fa-fw me-2 fa-volume-off', label: <><i className='fas fa-lg fa-fw me-2 fa-volume-off'></i>volume-off</>},
  { value:'fas fa-lg fa-fw me-2 fa-volume-up', label: <><i className='fas fa-lg fa-fw me-2 fa-volume-up'></i>volume-up</>},
  { value:'fas fa-lg fa-fw me-2 fa-wheelchair', label: <><i className='fas fa-lg fa-fw me-2 fa-wheelchair'></i>wheelchair</>},
  { value:'fas fa-lg fa-fw me-2 fa-wifi', label: <><i className='fas fa-lg fa-fw me-2 fa-wifi'></i>wifi</>},
  { value:'fas fa-lg fa-fw me-2 fa-window-close', label: <><i className='fas fa-lg fa-fw me-2 fa-window-close'></i>window-close</>},
  { value:'fas fa-lg fa-fw me-2 fa-window-maximize', label: <><i className='fas fa-lg fa-fw me-2 fa-window-maximize'></i>window-maximize</>},
  { value:'fas fa-lg fa-fw me-2 fa-window-minimize', label: <><i className='fas fa-lg fa-fw me-2 fa-window-minimize'></i>window-minimize</>},
  { value:'fas fa-lg fa-fw me-2 fa-window-restore', label: <><i className='fas fa-lg fa-fw me-2 fa-window-restore'></i>window-restore</>},
  { value:'fas fa-lg fa-fw me-2 fa-won-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-won-sign'></i>won-sign</>},
  { value:'fas fa-lg fa-fw me-2 fa-wrench', label: <><i className='fas fa-lg fa-fw me-2 fa-wrench'></i>wrench</>},
  { value:'fas fa-lg fa-fw me-2 fa-yen-sign', label: <><i className='fas fa-lg fa-fw me-2 fa-yen-sign'></i>yen-sign</>},
  { value:'far fa-lg fa-fw me-2 fa-address-book', label: <><i className='far fa-lg fa-fw me-2 fa-address-book'></i>address-book</>},
  { value:'far fa-lg fa-fw me-2 fa-address-card', label: <><i className='far fa-lg fa-fw me-2 fa-address-card'></i>address-card</>},
  { value:'far fa-lg fa-fw me-2 fa-arrow-alt-circle-down', label: <><i className='far fa-lg fa-fw me-2 fa-arrow-alt-circle-down'></i>arrow-alt-circle-down</>},
  { value:'far fa-lg fa-fw me-2 fa-arrow-alt-circle-left', label: <><i className='far fa-lg fa-fw me-2 fa-arrow-alt-circle-left'></i>arrow-alt-circle-left</>},
  { value:'far fa-lg fa-fw me-2 fa-arrow-alt-circle-right', label: <><i className='far fa-lg fa-fw me-2 fa-arrow-alt-circle-right'></i>arrow-alt-circle-right</>},
  { value:'far fa-lg fa-fw me-2 fa-arrow-alt-circle-up', label: <><i className='far fa-lg fa-fw me-2 fa-arrow-alt-circle-up'></i>arrow-alt-circle-up</>},
  { value:'far fa-lg fa-fw me-2 fa-bell', label: <><i className='far fa-lg fa-fw me-2 fa-bell'></i>bell</>},
  { value:'far fa-lg fa-fw me-2 fa-bell-slash', label: <><i className='far fa-lg fa-fw me-2 fa-bell-slash'></i>bell-slash</>},
  { value:'far fa-lg fa-fw me-2 fa-bookmark', label: <><i className='far fa-lg fa-fw me-2 fa-bookmark'></i>bookmark</>},
  { value:'far fa-lg fa-fw me-2 fa-building', label: <><i className='far fa-lg fa-fw me-2 fa-building'></i>building</>},
  { value:'far fa-lg fa-fw me-2 fa-calendar', label: <><i className='far fa-lg fa-fw me-2 fa-calendar'></i>calendar</>},
  { value:'far fa-lg fa-fw me-2 fa-calendar-alt', label: <><i className='far fa-lg fa-fw me-2 fa-calendar-alt'></i>calendar-alt</>},
  { value:'far fa-lg fa-fw me-2 fa-calendar-check', label: <><i className='far fa-lg fa-fw me-2 fa-calendar-check'></i>calendar-check</>},
  { value:'far fa-lg fa-fw me-2 fa-calendar-minus', label: <><i className='far fa-lg fa-fw me-2 fa-calendar-minus'></i>calendar-minus</>},
  { value:'far fa-lg fa-fw me-2 fa-calendar-plus', label: <><i className='far fa-lg fa-fw me-2 fa-calendar-plus'></i>calendar-plus</>},
  { value:'far fa-lg fa-fw me-2 fa-calendar-times', label: <><i className='far fa-lg fa-fw me-2 fa-calendar-times'></i>calendar-times</>},
  { value:'far fa-lg fa-fw me-2 fa-caret-square-down', label: <><i className='far fa-lg fa-fw me-2 fa-caret-square-down'></i>caret-square-down</>},
  { value:'far fa-lg fa-fw me-2 fa-caret-square-left', label: <><i className='far fa-lg fa-fw me-2 fa-caret-square-left'></i>caret-square-left</>},
  { value:'far fa-lg fa-fw me-2 fa-caret-square-right', label: <><i className='far fa-lg fa-fw me-2 fa-caret-square-right'></i>caret-square-right</>},
  { value:'far fa-lg fa-fw me-2 fa-caret-square-up', label: <><i className='far fa-lg fa-fw me-2 fa-caret-square-up'></i>caret-square-up</>},
  { value:'far fa-lg fa-fw me-2 fa-chart-bar', label: <><i className='far fa-lg fa-fw me-2 fa-chart-bar'></i>chart-bar</>},
  { value:'far fa-lg fa-fw me-2 fa-check-circle', label: <><i className='far fa-lg fa-fw me-2 fa-check-circle'></i>check-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-check-square', label: <><i className='far fa-lg fa-fw me-2 fa-check-square'></i>check-square</>},
  { value:'far fa-lg fa-fw me-2 fa-circle', label: <><i className='far fa-lg fa-fw me-2 fa-circle'></i>circle</>},
  { value:'far fa-lg fa-fw me-2 fa-clipboard', label: <><i className='far fa-lg fa-fw me-2 fa-clipboard'></i>clipboard</>},
  { value:'far fa-lg fa-fw me-2 fa-clock', label: <><i className='far fa-lg fa-fw me-2 fa-clock'></i>clock</>},
  { value:'far fa-lg fa-fw me-2 fa-clone', label: <><i className='far fa-lg fa-fw me-2 fa-clone'></i>clone</>},
  { value:'far fa-lg fa-fw me-2 fa-closed-captioning', label: <><i className='far fa-lg fa-fw me-2 fa-closed-captioning'></i>closed-captioning</>},
  { value:'far fa-lg fa-fw me-2 fa-comment', label: <><i className='far fa-lg fa-fw me-2 fa-comment'></i>comment</>},
  { value:'far fa-lg fa-fw me-2 fa-comment-alt', label: <><i className='far fa-lg fa-fw me-2 fa-comment-alt'></i>comment-alt</>},
  { value:'far fa-lg fa-fw me-2 fa-comments', label: <><i className='far fa-lg fa-fw me-2 fa-comments'></i>comments</>},
  { value:'far fa-lg fa-fw me-2 fa-compass', label: <><i className='far fa-lg fa-fw me-2 fa-compass'></i>compass</>},
  { value:'far fa-lg fa-fw me-2 fa-copy', label: <><i className='far fa-lg fa-fw me-2 fa-copy'></i>copy</>},
  { value:'far fa-lg fa-fw me-2 fa-copyright', label: <><i className='far fa-lg fa-fw me-2 fa-copyright'></i>copyright</>},
  { value:'far fa-lg fa-fw me-2 fa-credit-card', label: <><i className='far fa-lg fa-fw me-2 fa-credit-card'></i>credit-card</>},
  { value:'far fa-lg fa-fw me-2 fa-dot-circle', label: <><i className='far fa-lg fa-fw me-2 fa-dot-circle'></i>dot-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-edit', label: <><i className='far fa-lg fa-fw me-2 fa-edit'></i>edit</>},
  { value:'far fa-lg fa-fw me-2 fa-envelope', label: <><i className='far fa-lg fa-fw me-2 fa-envelope'></i>envelope</>},
  { value:'far fa-lg fa-fw me-2 fa-envelope-open', label: <><i className='far fa-lg fa-fw me-2 fa-envelope-open'></i>envelope-open</>},
  { value:'far fa-lg fa-fw me-2 fa-eye-slash', label: <><i className='far fa-lg fa-fw me-2 fa-eye-slash'></i>eye-slash</>},
  { value:'far fa-lg fa-fw me-2 fa-file', label: <><i className='far fa-lg fa-fw me-2 fa-file'></i>file</>},
  { value:'far fa-lg fa-fw me-2 fa-file-alt', label: <><i className='far fa-lg fa-fw me-2 fa-file-alt'></i>file-alt</>},
  { value:'far fa-lg fa-fw me-2 fa-file-archive', label: <><i className='far fa-lg fa-fw me-2 fa-file-archive'></i>file-archive</>},
  { value:'far fa-lg fa-fw me-2 fa-file-audio', label: <><i className='far fa-lg fa-fw me-2 fa-file-audio'></i>file-audio</>},
  { value:'far fa-lg fa-fw me-2 fa-file-code', label: <><i className='far fa-lg fa-fw me-2 fa-file-code'></i>file-code</>},
  { value:'far fa-lg fa-fw me-2 fa-file-excel', label: <><i className='far fa-lg fa-fw me-2 fa-file-excel'></i>file-excel</>},
  { value:'far fa-lg fa-fw me-2 fa-file-image', label: <><i className='far fa-lg fa-fw me-2 fa-file-image'></i>file-image</>},
  { value:'far fa-lg fa-fw me-2 fa-file-pdf', label: <><i className='far fa-lg fa-fw me-2 fa-file-pdf'></i>file-pdf</>},
  { value:'far fa-lg fa-fw me-2 fa-file-powerpoint', label: <><i className='far fa-lg fa-fw me-2 fa-file-powerpoint'></i>file-powerpoint</>},
  { value:'far fa-lg fa-fw me-2 fa-file-video', label: <><i className='far fa-lg fa-fw me-2 fa-file-video'></i>file-video</>},
  { value:'far fa-lg fa-fw me-2 fa-file-word', label: <><i className='far fa-lg fa-fw me-2 fa-file-word'></i>file-word</>},
  { value:'far fa-lg fa-fw me-2 fa-flag', label: <><i className='far fa-lg fa-fw me-2 fa-flag'></i>flag</>},
  { value:'far fa-lg fa-fw me-2 fa-folder', label: <><i className='far fa-lg fa-fw me-2 fa-folder'></i>folder</>},
  { value:'far fa-lg fa-fw me-2 fa-folder-open', label: <><i className='far fa-lg fa-fw me-2 fa-folder-open'></i>folder-open</>},
  { value:'far fa-lg fa-fw me-2 fa-frown', label: <><i className='far fa-lg fa-fw me-2 fa-frown'></i>frown</>},
  { value:'far fa-lg fa-fw me-2 fa-futbol', label: <><i className='far fa-lg fa-fw me-2 fa-futbol'></i>futbol</>},
  { value:'far fa-lg fa-fw me-2 fa-gem', label: <><i className='far fa-lg fa-fw me-2 fa-gem'></i>gem</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-lizard', label: <><i className='far fa-lg fa-fw me-2 fa-hand-lizard'></i>hand-lizard</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-paper', label: <><i className='far fa-lg fa-fw me-2 fa-hand-paper'></i>hand-paper</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-peace', label: <><i className='far fa-lg fa-fw me-2 fa-hand-peace'></i>hand-peace</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-point-down', label: <><i className='far fa-lg fa-fw me-2 fa-hand-point-down'></i>hand-point-down</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-point-left', label: <><i className='far fa-lg fa-fw me-2 fa-hand-point-left'></i>hand-point-left</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-point-right', label: <><i className='far fa-lg fa-fw me-2 fa-hand-point-right'></i>hand-point-right</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-point-up', label: <><i className='far fa-lg fa-fw me-2 fa-hand-point-up'></i>hand-point-up</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-pointer', label: <><i className='far fa-lg fa-fw me-2 fa-hand-pointer'></i>hand-pointer</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-rock', label: <><i className='far fa-lg fa-fw me-2 fa-hand-rock'></i>hand-rock</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-scissors', label: <><i className='far fa-lg fa-fw me-2 fa-hand-scissors'></i>hand-scissors</>},
  { value:'far fa-lg fa-fw me-2 fa-hand-spock', label: <><i className='far fa-lg fa-fw me-2 fa-hand-spock'></i>hand-spock</>},
  { value:'far fa-lg fa-fw me-2 fa-handshake', label: <><i className='far fa-lg fa-fw me-2 fa-handshake'></i>handshake</>},
  { value:'far fa-lg fa-fw me-2 fa-hdd', label: <><i className='far fa-lg fa-fw me-2 fa-hdd'></i>hdd</>},
  { value:'far fa-lg fa-fw me-2 fa-heart', label: <><i className='far fa-lg fa-fw me-2 fa-heart'></i>heart</>},
  { value:'far fa-lg fa-fw me-2 fa-hospital', label: <><i className='far fa-lg fa-fw me-2 fa-hospital'></i>hospital</>},
  { value:'far fa-lg fa-fw me-2 fa-hourglass', label: <><i className='far fa-lg fa-fw me-2 fa-hourglass'></i>hourglass</>},
  { value:'far fa-lg fa-fw me-2 fa-id-badge', label: <><i className='far fa-lg fa-fw me-2 fa-id-badge'></i>id-badge</>},
  { value:'far fa-lg fa-fw me-2 fa-id-card', label: <><i className='far fa-lg fa-fw me-2 fa-id-card'></i>id-card</>},
  { value:'far fa-lg fa-fw me-2 fa-image', label: <><i className='far fa-lg fa-fw me-2 fa-image'></i>image</>},
  { value:'far fa-lg fa-fw me-2 fa-images', label: <><i className='far fa-lg fa-fw me-2 fa-images'></i>images</>},
  { value:'far fa-lg fa-fw me-2 fa-keyboard', label: <><i className='far fa-lg fa-fw me-2 fa-keyboard'></i>keyboard</>},
  { value:'far fa-lg fa-fw me-2 fa-lemon', label: <><i className='far fa-lg fa-fw me-2 fa-lemon'></i>lemon</>},
  { value:'far fa-lg fa-fw me-2 fa-life-ring', label: <><i className='far fa-lg fa-fw me-2 fa-life-ring'></i>life-ring</>},
  { value:'far fa-lg fa-fw me-2 fa-lightbulb', label: <><i className='far fa-lg fa-fw me-2 fa-lightbulb'></i>lightbulb</>},
  { value:'far fa-lg fa-fw me-2 fa-list-alt', label: <><i className='far fa-lg fa-fw me-2 fa-list-alt'></i>list-alt</>},
  { value:'far fa-lg fa-fw me-2 fa-map', label: <><i className='far fa-lg fa-fw me-2 fa-map'></i>map</>},
  { value:'far fa-lg fa-fw me-2 fa-meh', label: <><i className='far fa-lg fa-fw me-2 fa-meh'></i>meh</>},
  { value:'far fa-lg fa-fw me-2 fa-minus-square', label: <><i className='far fa-lg fa-fw me-2 fa-minus-square'></i>minus-square</>},
  { value:'far fa-lg fa-fw me-2 fa-money-bill-alt', label: <><i className='far fa-lg fa-fw me-2 fa-money-bill-alt'></i>money-bill-alt</>},
  { value:'far fa-lg fa-fw me-2 fa-moon', label: <><i className='far fa-lg fa-fw me-2 fa-moon'></i>moon</>},
  { value:'far fa-lg fa-fw me-2 fa-newspaper', label: <><i className='far fa-lg fa-fw me-2 fa-newspaper'></i>newspaper</>},
  { value:'far fa-lg fa-fw me-2 fa-object-group', label: <><i className='far fa-lg fa-fw me-2 fa-object-group'></i>object-group</>},
  { value:'far fa-lg fa-fw me-2 fa-object-ungroup', label: <><i className='far fa-lg fa-fw me-2 fa-object-ungroup'></i>object-ungroup</>},
  { value:'far fa-lg fa-fw me-2 fa-paper-plane', label: <><i className='far fa-lg fa-fw me-2 fa-paper-plane'></i>paper-plane</>},
  { value:'far fa-lg fa-fw me-2 fa-pause-circle', label: <><i className='far fa-lg fa-fw me-2 fa-pause-circle'></i>pause-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-play-circle', label: <><i className='far fa-lg fa-fw me-2 fa-play-circle'></i>play-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-plus-square', label: <><i className='far fa-lg fa-fw me-2 fa-plus-square'></i>plus-square</>},
  { value:'far fa-lg fa-fw me-2 fa-question-circle', label: <><i className='far fa-lg fa-fw me-2 fa-question-circle'></i>question-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-registered', label: <><i className='far fa-lg fa-fw me-2 fa-registered'></i>registered</>},
  { value:'far fa-lg fa-fw me-2 fa-save', label: <><i className='far fa-lg fa-fw me-2 fa-save'></i>save</>},
  { value:'far fa-lg fa-fw me-2 fa-share-square', label: <><i className='far fa-lg fa-fw me-2 fa-share-square'></i>share-square</>},
  { value:'far fa-lg fa-fw me-2 fa-smile', label: <><i className='far fa-lg fa-fw me-2 fa-smile'></i>smile</>},
  { value:'far fa-lg fa-fw me-2 fa-snowflake', label: <><i className='far fa-lg fa-fw me-2 fa-snowflake'></i>snowflake</>},
  { value:'far fa-lg fa-fw me-2 fa-square', label: <><i className='far fa-lg fa-fw me-2 fa-square'></i>square</>},
  { value:'far fa-lg fa-fw me-2 fa-star', label: <><i className='far fa-lg fa-fw me-2 fa-star'></i>star</>},
  { value:'far fa-lg fa-fw me-2 fa-star-half', label: <><i className='far fa-lg fa-fw me-2 fa-star-half'></i>star-half</>},
  { value:'far fa-lg fa-fw me-2 fa-sticky-note', label: <><i className='far fa-lg fa-fw me-2 fa-sticky-note'></i>sticky-note</>},
  { value:'far fa-lg fa-fw me-2 fa-stop-circle', label: <><i className='far fa-lg fa-fw me-2 fa-stop-circle'></i>stop-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-sun', label: <><i className='far fa-lg fa-fw me-2 fa-sun'></i>sun</>},
  { value:'far fa-lg fa-fw me-2 fa-thumbs-down', label: <><i className='far fa-lg fa-fw me-2 fa-thumbs-down'></i>thumbs-down</>},
  { value:'far fa-lg fa-fw me-2 fa-thumbs-up', label: <><i className='far fa-lg fa-fw me-2 fa-thumbs-up'></i>thumbs-up</>},
  { value:'far fa-lg fa-fw me-2 fa-times-circle', label: <><i className='far fa-lg fa-fw me-2 fa-times-circle'></i>times-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-trash-alt', label: <><i className='far fa-lg fa-fw me-2 fa-trash-alt'></i>trash-alt</>},
  { value:'far fa-lg fa-fw me-2 fa-user', label: <><i className='far fa-lg fa-fw me-2 fa-user'></i>user</>},
  { value:'far fa-lg fa-fw me-2 fa-user-circle', label: <><i className='far fa-lg fa-fw me-2 fa-user-circle'></i>user-circle</>},
  { value:'far fa-lg fa-fw me-2 fa-window-close', label: <><i className='far fa-lg fa-fw me-2 fa-window-close'></i>window-close</>},
  { value:'far fa-lg fa-fw me-2 fa-window-maximize', label: <><i className='far fa-lg fa-fw me-2 fa-window-maximize'></i>window-maximize</>},
  { value:'far fa-lg fa-fw me-2 fa-window-minimize', label: <><i className='far fa-lg fa-fw me-2 fa-window-minimize'></i>window-minimize</>},
  { value:'far fa-lg fa-fw me-2 fa-window-restore', label: <><i className='far fa-lg fa-fw me-2 fa-window-restore'></i>window-restore</>},
  { value:'fab fa-lg fa-fw me-2 fa-500px', label: <><i className='fab fa-lg fa-fw me-2 fa-500px'></i>500px</>},
  { value:'fab fa-lg fa-fw me-2 fa-accessible-icon', label: <><i className='fab fa-lg fa-fw me-2 fa-accessible-icon'></i>accessible-icon</>},
  { value:'fab fa-lg fa-fw me-2 fa-accusoft', label: <><i className='fab fa-lg fa-fw me-2 fa-accusoft'></i>accusoft</>},
  { value:'fab fa-lg fa-fw me-2 fa-adn', label: <><i className='fab fa-lg fa-fw me-2 fa-adn'></i>adn</>},
  { value:'fab fa-lg fa-fw me-2 fa-adversal', label: <><i className='fab fa-lg fa-fw me-2 fa-adversal'></i>adversal</>},
  { value:'fab fa-lg fa-fw me-2 fa-affiliatetheme', label: <><i className='fab fa-lg fa-fw me-2 fa-affiliatetheme'></i>affiliatetheme</>},
  { value:'fab fa-lg fa-fw me-2 fa-algolia', label: <><i className='fab fa-lg fa-fw me-2 fa-algolia'></i>algolia</>},
  { value:'fab fa-lg fa-fw me-2 fa-amazon', label: <><i className='fab fa-lg fa-fw me-2 fa-amazon'></i>amazon</>},
  { value:'fab fa-lg fa-fw me-2 fa-amazon-pay', label: <><i className='fab fa-lg fa-fw me-2 fa-amazon-pay'></i>amazon-pay</>},
  { value:'fab fa-lg fa-fw me-2 fa-amilia', label: <><i className='fab fa-lg fa-fw me-2 fa-amilia'></i>amilia</>},
  { value:'fab fa-lg fa-fw me-2 fa-android', label: <><i className='fab fa-lg fa-fw me-2 fa-android'></i>android</>},
  { value:'fab fa-lg fa-fw me-2 fa-angellist', label: <><i className='fab fa-lg fa-fw me-2 fa-angellist'></i>angellist</>},
  { value:'fab fa-lg fa-fw me-2 fa-angrycreative', label: <><i className='fab fa-lg fa-fw me-2 fa-angrycreative'></i>angrycreative</>},
  { value:'fab fa-lg fa-fw me-2 fa-angular', label: <><i className='fab fa-lg fa-fw me-2 fa-angular'></i>angular</>},
  { value:'fab fa-lg fa-fw me-2 fa-app-store', label: <><i className='fab fa-lg fa-fw me-2 fa-app-store'></i>app-store</>},
  { value:'fab fa-lg fa-fw me-2 fa-app-store-ios', label: <><i className='fab fa-lg fa-fw me-2 fa-app-store-ios'></i>app-store-ios</>},
  { value:'fab fa-lg fa-fw me-2 fa-apper', label: <><i className='fab fa-lg fa-fw me-2 fa-apper'></i>apper</>},
  { value:'fab fa-lg fa-fw me-2 fa-apple', label: <><i className='fab fa-lg fa-fw me-2 fa-apple'></i>apple</>},
  { value:'fab fa-lg fa-fw me-2 fa-apple-pay', label: <><i className='fab fa-lg fa-fw me-2 fa-apple-pay'></i>apple-pay</>},
  { value:'fab fa-lg fa-fw me-2 fa-asymmetrik', label: <><i className='fab fa-lg fa-fw me-2 fa-asymmetrik'></i>asymmetrik</>},
  { value:'fab fa-lg fa-fw me-2 fa-audible', label: <><i className='fab fa-lg fa-fw me-2 fa-audible'></i>audible</>},
  { value:'fab fa-lg fa-fw me-2 fa-autoprefixer', label: <><i className='fab fa-lg fa-fw me-2 fa-autoprefixer'></i>autoprefixer</>},
  { value:'fab fa-lg fa-fw me-2 fa-avianex', label: <><i className='fab fa-lg fa-fw me-2 fa-avianex'></i>avianex</>},
  { value:'fab fa-lg fa-fw me-2 fa-aviato', label: <><i className='fab fa-lg fa-fw me-2 fa-aviato'></i>aviato</>},
  { value:'fab fa-lg fa-fw me-2 fa-aws', label: <><i className='fab fa-lg fa-fw me-2 fa-aws'></i>aws</>},
  { value:'fab fa-lg fa-fw me-2 fa-bandcamp', label: <><i className='fab fa-lg fa-fw me-2 fa-bandcamp'></i>bandcamp</>},
  { value:'fab fa-lg fa-fw me-2 fa-behance', label: <><i className='fab fa-lg fa-fw me-2 fa-behance'></i>behance</>},
  { value:'fab fa-lg fa-fw me-2 fa-behance-square', label: <><i className='fab fa-lg fa-fw me-2 fa-behance-square'></i>behance-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-bimobject', label: <><i className='fab fa-lg fa-fw me-2 fa-bimobject'></i>bimobject</>},
  { value:'fab fa-lg fa-fw me-2 fa-bitbucket', label: <><i className='fab fa-lg fa-fw me-2 fa-bitbucket'></i>bitbucket</>},
  { value:'fab fa-lg fa-fw me-2 fa-bitcoin', label: <><i className='fab fa-lg fa-fw me-2 fa-bitcoin'></i>bitcoin</>},
  { value:'fab fa-lg fa-fw me-2 fa-bity', label: <><i className='fab fa-lg fa-fw me-2 fa-bity'></i>bity</>},
  { value:'fab fa-lg fa-fw me-2 fa-black-tie', label: <><i className='fab fa-lg fa-fw me-2 fa-black-tie'></i>black-tie</>},
  { value:'fab fa-lg fa-fw me-2 fa-blackberry', label: <><i className='fab fa-lg fa-fw me-2 fa-blackberry'></i>blackberry</>},
  { value:'fab fa-lg fa-fw me-2 fa-blogger', label: <><i className='fab fa-lg fa-fw me-2 fa-blogger'></i>blogger</>},
  { value:'fab fa-lg fa-fw me-2 fa-blogger-b', label: <><i className='fab fa-lg fa-fw me-2 fa-blogger-b'></i>blogger-b</>},
  { value:'fab fa-lg fa-fw me-2 fa-bluetooth', label: <><i className='fab fa-lg fa-fw me-2 fa-bluetooth'></i>bluetooth</>},
  { value:'fab fa-lg fa-fw me-2 fa-bluetooth-b', label: <><i className='fab fa-lg fa-fw me-2 fa-bluetooth-b'></i>bluetooth-b</>},
  { value:'fab fa-lg fa-fw me-2 fa-btc', label: <><i className='fab fa-lg fa-fw me-2 fa-btc'></i>btc</>},
  { value:'fab fa-lg fa-fw me-2 fa-buromobelexperte', label: <><i className='fab fa-lg fa-fw me-2 fa-buromobelexperte'></i>buromobelexperte</>},
  { value:'fab fa-lg fa-fw me-2 fa-buysellads', label: <><i className='fab fa-lg fa-fw me-2 fa-buysellads'></i>buysellads</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-amazon-pay', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-amazon-pay'></i>cc-amazon-pay</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-amex', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-amex'></i>cc-amex</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-apple-pay', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-apple-pay'></i>cc-apple-pay</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-diners-club', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-diners-club'></i>cc-diners-club</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-discover', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-discover'></i>cc-discover</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-jcb', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-jcb'></i>cc-jcb</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-mastercard', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-mastercard'></i>cc-mastercard</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-paypal', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-paypal'></i>cc-paypal</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-stripe', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-stripe'></i>cc-stripe</>},
  { value:'fab fa-lg fa-fw me-2 fa-cc-visa', label: <><i className='fab fa-lg fa-fw me-2 fa-cc-visa'></i>cc-visa</>},
  { value:'fab fa-lg fa-fw me-2 fa-centercode', label: <><i className='fab fa-lg fa-fw me-2 fa-centercode'></i>centercode</>},
  { value:'fab fa-lg fa-fw me-2 fa-chrome', label: <><i className='fab fa-lg fa-fw me-2 fa-chrome'></i>chrome</>},
  { value:'fab fa-lg fa-fw me-2 fa-cloudscale', label: <><i className='fab fa-lg fa-fw me-2 fa-cloudscale'></i>cloudscale</>},
  { value:'fab fa-lg fa-fw me-2 fa-cloudsmith', label: <><i className='fab fa-lg fa-fw me-2 fa-cloudsmith'></i>cloudsmith</>},
  { value:'fab fa-lg fa-fw me-2 fa-cloudversify', label: <><i className='fab fa-lg fa-fw me-2 fa-cloudversify'></i>cloudversify</>},
  { value:'fab fa-lg fa-fw me-2 fa-codepen', label: <><i className='fab fa-lg fa-fw me-2 fa-codepen'></i>codepen</>},
  { value:'fab fa-lg fa-fw me-2 fa-codiepie', label: <><i className='fab fa-lg fa-fw me-2 fa-codiepie'></i>codiepie</>},
  { value:'fab fa-lg fa-fw me-2 fa-connectdevelop', label: <><i className='fab fa-lg fa-fw me-2 fa-connectdevelop'></i>connectdevelop</>},
  { value:'fab fa-lg fa-fw me-2 fa-contao', label: <><i className='fab fa-lg fa-fw me-2 fa-contao'></i>contao</>},
  { value:'fab fa-lg fa-fw me-2 fa-cpanel', label: <><i className='fab fa-lg fa-fw me-2 fa-cpanel'></i>cpanel</>},
  { value:'fab fa-lg fa-fw me-2 fa-creative-commons', label: <><i className='fab fa-lg fa-fw me-2 fa-creative-commons'></i>creative-commons</>},
  { value:'fab fa-lg fa-fw me-2 fa-css3', label: <><i className='fab fa-lg fa-fw me-2 fa-css3'></i>css3</>},
  { value:'fab fa-lg fa-fw me-2 fa-css3-alt', label: <><i className='fab fa-lg fa-fw me-2 fa-css3-alt'></i>css3-alt</>},
  { value:'fab fa-lg fa-fw me-2 fa-cuttlefish', label: <><i className='fab fa-lg fa-fw me-2 fa-cuttlefish'></i>cuttlefish</>},
  { value:'fab fa-lg fa-fw me-2 fa-d-and-d', label: <><i className='fab fa-lg fa-fw me-2 fa-d-and-d'></i>d-and-d</>},
  { value:'fab fa-lg fa-fw me-2 fa-dashcube', label: <><i className='fab fa-lg fa-fw me-2 fa-dashcube'></i>dashcube</>},
  { value:'fab fa-lg fa-fw me-2 fa-delicious', label: <><i className='fab fa-lg fa-fw me-2 fa-delicious'></i>delicious</>},
  { value:'fab fa-lg fa-fw me-2 fa-deploydog', label: <><i className='fab fa-lg fa-fw me-2 fa-deploydog'></i>deploydog</>},
  { value:'fab fa-lg fa-fw me-2 fa-deskpro', label: <><i className='fab fa-lg fa-fw me-2 fa-deskpro'></i>deskpro</>},
  { value:'fab fa-lg fa-fw me-2 fa-deviantart', label: <><i className='fab fa-lg fa-fw me-2 fa-deviantart'></i>deviantart</>},
  { value:'fab fa-lg fa-fw me-2 fa-digg', label: <><i className='fab fa-lg fa-fw me-2 fa-digg'></i>digg</>},
  { value:'fab fa-lg fa-fw me-2 fa-digital-ocean', label: <><i className='fab fa-lg fa-fw me-2 fa-digital-ocean'></i>digital-ocean</>},
  { value:'fab fa-lg fa-fw me-2 fa-discord', label: <><i className='fab fa-lg fa-fw me-2 fa-discord'></i>discord</>},
  { value:'fab fa-lg fa-fw me-2 fa-discourse', label: <><i className='fab fa-lg fa-fw me-2 fa-discourse'></i>discourse</>},
  { value:'fab fa-lg fa-fw me-2 fa-dochub', label: <><i className='fab fa-lg fa-fw me-2 fa-dochub'></i>dochub</>},
  { value:'fab fa-lg fa-fw me-2 fa-docker', label: <><i className='fab fa-lg fa-fw me-2 fa-docker'></i>docker</>},
  { value:'fab fa-lg fa-fw me-2 fa-draft2digital', label: <><i className='fab fa-lg fa-fw me-2 fa-draft2digital'></i>draft2digital</>},
  { value:'fab fa-lg fa-fw me-2 fa-dribbble', label: <><i className='fab fa-lg fa-fw me-2 fa-dribbble'></i>dribbble</>},
  { value:'fab fa-lg fa-fw me-2 fa-dribbble-square', label: <><i className='fab fa-lg fa-fw me-2 fa-dribbble-square'></i>dribbble-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-dropbox', label: <><i className='fab fa-lg fa-fw me-2 fa-dropbox'></i>dropbox</>},
  { value:'fab fa-lg fa-fw me-2 fa-drupal', label: <><i className='fab fa-lg fa-fw me-2 fa-drupal'></i>drupal</>},
  { value:'fab fa-lg fa-fw me-2 fa-dyalog', label: <><i className='fab fa-lg fa-fw me-2 fa-dyalog'></i>dyalog</>},
  { value:'fab fa-lg fa-fw me-2 fa-earlybirds', label: <><i className='fab fa-lg fa-fw me-2 fa-earlybirds'></i>earlybirds</>},
  { value:'fab fa-lg fa-fw me-2 fa-edge', label: <><i className='fab fa-lg fa-fw me-2 fa-edge'></i>edge</>},
  { value:'fab fa-lg fa-fw me-2 fa-elementor', label: <><i className='fab fa-lg fa-fw me-2 fa-elementor'></i>elementor</>},
  { value:'fab fa-lg fa-fw me-2 fa-ember', label: <><i className='fab fa-lg fa-fw me-2 fa-ember'></i>ember</>},
  { value:'fab fa-lg fa-fw me-2 fa-empire', label: <><i className='fab fa-lg fa-fw me-2 fa-empire'></i>empire</>},
  { value:'fab fa-lg fa-fw me-2 fa-envira', label: <><i className='fab fa-lg fa-fw me-2 fa-envira'></i>envira</>},
  { value:'fab fa-lg fa-fw me-2 fa-erlang', label: <><i className='fab fa-lg fa-fw me-2 fa-erlang'></i>erlang</>},
  { value:'fab fa-lg fa-fw me-2 fa-ethereum', label: <><i className='fab fa-lg fa-fw me-2 fa-ethereum'></i>ethereum</>},
  { value:'fab fa-lg fa-fw me-2 fa-etsy', label: <><i className='fab fa-lg fa-fw me-2 fa-etsy'></i>etsy</>},
  { value:'fab fa-lg fa-fw me-2 fa-expeditedssl', label: <><i className='fab fa-lg fa-fw me-2 fa-expeditedssl'></i>expeditedssl</>},
  { value:'fab fa-lg fa-fw me-2 fa-facebook', label: <><i className='fab fa-lg fa-fw me-2 fa-facebook'></i>facebook</>},
  { value:'fab fa-lg fa-fw me-2 fa-facebook-f', label: <><i className='fab fa-lg fa-fw me-2 fa-facebook-f'></i>facebook-f</>},
  { value:'fab fa-lg fa-fw me-2 fa-facebook-messenger', label: <><i className='fab fa-lg fa-fw me-2 fa-facebook-messenger'></i>facebook-messenger</>},
  { value:'fab fa-lg fa-fw me-2 fa-facebook-square', label: <><i className='fab fa-lg fa-fw me-2 fa-facebook-square'></i>facebook-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-firefox', label: <><i className='fab fa-lg fa-fw me-2 fa-firefox'></i>firefox</>},
  { value:'fab fa-lg fa-fw me-2 fa-first-order', label: <><i className='fab fa-lg fa-fw me-2 fa-first-order'></i>first-order</>},
  { value:'fab fa-lg fa-fw me-2 fa-firstdraft', label: <><i className='fab fa-lg fa-fw me-2 fa-firstdraft'></i>firstdraft</>},
  { value:'fab fa-lg fa-fw me-2 fa-flickr', label: <><i className='fab fa-lg fa-fw me-2 fa-flickr'></i>flickr</>},
  { value:'fab fa-lg fa-fw me-2 fa-fly', label: <><i className='fab fa-lg fa-fw me-2 fa-fly'></i>fly</>},
  { value:'fab fa-lg fa-fw me-2 fa-font-awesome', label: <><i className='fab fa-lg fa-fw me-2 fa-font-awesome'></i>font-awesome</>},
  { value:'fab fa-lg fa-fw me-2 fa-font-awesome-alt', label: <><i className='fab fa-lg fa-fw me-2 fa-font-awesome-alt'></i>font-awesome-alt</>},
  { value:'fab fa-lg fa-fw me-2 fa-font-awesome-flag', label: <><i className='fab fa-lg fa-fw me-2 fa-font-awesome-flag'></i>font-awesome-flag</>},
  { value:'fab fa-lg fa-fw me-2 fa-fonticons', label: <><i className='fab fa-lg fa-fw me-2 fa-fonticons'></i>fonticons</>},
  { value:'fab fa-lg fa-fw me-2 fa-fonticons-fi', label: <><i className='fab fa-lg fa-fw me-2 fa-fonticons-fi'></i>fonticons-fi</>},
  { value:'fab fa-lg fa-fw me-2 fa-fort-awesome', label: <><i className='fab fa-lg fa-fw me-2 fa-fort-awesome'></i>fort-awesome</>},
  { value:'fab fa-lg fa-fw me-2 fa-fort-awesome-alt', label: <><i className='fab fa-lg fa-fw me-2 fa-fort-awesome-alt'></i>fort-awesome-alt</>},
  { value:'fab fa-lg fa-fw me-2 fa-forumbee', label: <><i className='fab fa-lg fa-fw me-2 fa-forumbee'></i>forumbee</>},
  { value:'fab fa-lg fa-fw me-2 fa-foursquare', label: <><i className='fab fa-lg fa-fw me-2 fa-foursquare'></i>foursquare</>},
  { value:'fab fa-lg fa-fw me-2 fa-free-code-camp', label: <><i className='fab fa-lg fa-fw me-2 fa-free-code-camp'></i>free-code-camp</>},
  { value:'fab fa-lg fa-fw me-2 fa-freebsd', label: <><i className='fab fa-lg fa-fw me-2 fa-freebsd'></i>freebsd</>},
  { value:'fab fa-lg fa-fw me-2 fa-get-pocket', label: <><i className='fab fa-lg fa-fw me-2 fa-get-pocket'></i>get-pocket</>},
  { value:'fab fa-lg fa-fw me-2 fa-gg', label: <><i className='fab fa-lg fa-fw me-2 fa-gg'></i>gg</>},
  { value:'fab fa-lg fa-fw me-2 fa-gg-circle', label: <><i className='fab fa-lg fa-fw me-2 fa-gg-circle'></i>gg-circle</>},
  { value:'fab fa-lg fa-fw me-2 fa-git', label: <><i className='fab fa-lg fa-fw me-2 fa-git'></i>git</>},
  { value:'fab fa-lg fa-fw me-2 fa-git-square', label: <><i className='fab fa-lg fa-fw me-2 fa-git-square'></i>git-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-github', label: <><i className='fab fa-lg fa-fw me-2 fa-github'></i>github</>},
  { value:'fab fa-lg fa-fw me-2 fa-github-alt', label: <><i className='fab fa-lg fa-fw me-2 fa-github-alt'></i>github-alt</>},
  { value:'fab fa-lg fa-fw me-2 fa-github-square', label: <><i className='fab fa-lg fa-fw me-2 fa-github-square'></i>github-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-gitkraken', label: <><i className='fab fa-lg fa-fw me-2 fa-gitkraken'></i>gitkraken</>},
  { value:'fab fa-lg fa-fw me-2 fa-gitlab', label: <><i className='fab fa-lg fa-fw me-2 fa-gitlab'></i>gitlab</>},
  { value:'fab fa-lg fa-fw me-2 fa-gitter', label: <><i className='fab fa-lg fa-fw me-2 fa-gitter'></i>gitter</>},
  { value:'fab fa-lg fa-fw me-2 fa-glide', label: <><i className='fab fa-lg fa-fw me-2 fa-glide'></i>glide</>},
  { value:'fab fa-lg fa-fw me-2 fa-glide-g', label: <><i className='fab fa-lg fa-fw me-2 fa-glide-g'></i>glide-g</>},
  { value:'fab fa-lg fa-fw me-2 fa-gofore', label: <><i className='fab fa-lg fa-fw me-2 fa-gofore'></i>gofore</>},
  { value:'fab fa-lg fa-fw me-2 fa-goodreads', label: <><i className='fab fa-lg fa-fw me-2 fa-goodreads'></i>goodreads</>},
  { value:'fab fa-lg fa-fw me-2 fa-goodreads-g', label: <><i className='fab fa-lg fa-fw me-2 fa-goodreads-g'></i>goodreads-g</>},
  { value:'fab fa-lg fa-fw me-2 fa-google', label: <><i className='fab fa-lg fa-fw me-2 fa-google'></i>google</>},
  { value:'fab fa-lg fa-fw me-2 fa-google-drive', label: <><i className='fab fa-lg fa-fw me-2 fa-google-drive'></i>google-drive</>},
  { value:'fab fa-lg fa-fw me-2 fa-google-play', label: <><i className='fab fa-lg fa-fw me-2 fa-google-play'></i>google-play</>},
  { value:'fab fa-lg fa-fw me-2 fa-google-plus', label: <><i className='fab fa-lg fa-fw me-2 fa-google-plus'></i>google-plus</>},
  { value:'fab fa-lg fa-fw me-2 fa-google-plus-g', label: <><i className='fab fa-lg fa-fw me-2 fa-google-plus-g'></i>google-plus-g</>},
  { value:'fab fa-lg fa-fw me-2 fa-google-plus-square', label: <><i className='fab fa-lg fa-fw me-2 fa-google-plus-square'></i>google-plus-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-google-wallet', label: <><i className='fab fa-lg fa-fw me-2 fa-google-wallet'></i>google-wallet</>},
  { value:'fab fa-lg fa-fw me-2 fa-gratipay', label: <><i className='fab fa-lg fa-fw me-2 fa-gratipay'></i>gratipay</>},
  { value:'fab fa-lg fa-fw me-2 fa-grav', label: <><i className='fab fa-lg fa-fw me-2 fa-grav'></i>grav</>},
  { value:'fab fa-lg fa-fw me-2 fa-gripfire', label: <><i className='fab fa-lg fa-fw me-2 fa-gripfire'></i>gripfire</>},
  { value:'fab fa-lg fa-fw me-2 fa-grunt', label: <><i className='fab fa-lg fa-fw me-2 fa-grunt'></i>grunt</>},
  { value:'fab fa-lg fa-fw me-2 fa-gulp', label: <><i className='fab fa-lg fa-fw me-2 fa-gulp'></i>gulp</>},
  { value:'fab fa-lg fa-fw me-2 fa-hacker-news', label: <><i className='fab fa-lg fa-fw me-2 fa-hacker-news'></i>hacker-news</>},
  { value:'fab fa-lg fa-fw me-2 fa-hacker-news-square', label: <><i className='fab fa-lg fa-fw me-2 fa-hacker-news-square'></i>hacker-news-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-hire-a-helper', label: <><i className='fab fa-lg fa-fw me-2 fa-hire-a-helper'></i>hire-a-helper</>},
  { value:'fab fa-lg fa-fw me-2 fa-hooli', label: <><i className='fab fa-lg fa-fw me-2 fa-hooli'></i>hooli</>},
  { value:'fab fa-lg fa-fw me-2 fa-hotjar', label: <><i className='fab fa-lg fa-fw me-2 fa-hotjar'></i>hotjar</>},
  { value:'fab fa-lg fa-fw me-2 fa-houzz', label: <><i className='fab fa-lg fa-fw me-2 fa-houzz'></i>houzz</>},
  { value:'fab fa-lg fa-fw me-2 fa-html5', label: <><i className='fab fa-lg fa-fw me-2 fa-html5'></i>html5</>},
  { value:'fab fa-lg fa-fw me-2 fa-hubspot', label: <><i className='fab fa-lg fa-fw me-2 fa-hubspot'></i>hubspot</>},
  { value:'fab fa-lg fa-fw me-2 fa-imdb', label: <><i className='fab fa-lg fa-fw me-2 fa-imdb'></i>imdb</>},
  { value:'fab fa-lg fa-fw me-2 fa-instagram', label: <><i className='fab fa-lg fa-fw me-2 fa-instagram'></i>instagram</>},
  { value:'fab fa-lg fa-fw me-2 fa-internet-explorer', label: <><i className='fab fa-lg fa-fw me-2 fa-internet-explorer'></i>internet-explorer</>},
  { value:'fab fa-lg fa-fw me-2 fa-ioxhost', label: <><i className='fab fa-lg fa-fw me-2 fa-ioxhost'></i>ioxhost</>},
  { value:'fab fa-lg fa-fw me-2 fa-itunes', label: <><i className='fab fa-lg fa-fw me-2 fa-itunes'></i>itunes</>},
  { value:'fab fa-lg fa-fw me-2 fa-itunes-note', label: <><i className='fab fa-lg fa-fw me-2 fa-itunes-note'></i>itunes-note</>},
  { value:'fab fa-lg fa-fw me-2 fa-jenkins', label: <><i className='fab fa-lg fa-fw me-2 fa-jenkins'></i>jenkins</>},
  { value:'fab fa-lg fa-fw me-2 fa-joget', label: <><i className='fab fa-lg fa-fw me-2 fa-joget'></i>joget</>},
  { value:'fab fa-lg fa-fw me-2 fa-joomla', label: <><i className='fab fa-lg fa-fw me-2 fa-joomla'></i>joomla</>},
  { value:'fab fa-lg fa-fw me-2 fa-js', label: <><i className='fab fa-lg fa-fw me-2 fa-js'></i>js</>},
  { value:'fab fa-lg fa-fw me-2 fa-js-square', label: <><i className='fab fa-lg fa-fw me-2 fa-js-square'></i>js-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-jsfiddle', label: <><i className='fab fa-lg fa-fw me-2 fa-jsfiddle'></i>jsfiddle</>},
  { value:'fab fa-lg fa-fw me-2 fa-keycdn', label: <><i className='fab fa-lg fa-fw me-2 fa-keycdn'></i>keycdn</>},
  { value:'fab fa-lg fa-fw me-2 fa-kickstarter', label: <><i className='fab fa-lg fa-fw me-2 fa-kickstarter'></i>kickstarter</>},
  { value:'fab fa-lg fa-fw me-2 fa-kickstarter-k', label: <><i className='fab fa-lg fa-fw me-2 fa-kickstarter-k'></i>kickstarter-k</>},
  { value:'fab fa-lg fa-fw me-2 fa-korvue', label: <><i className='fab fa-lg fa-fw me-2 fa-korvue'></i>korvue</>},
  { value:'fab fa-lg fa-fw me-2 fa-laravel', label: <><i className='fab fa-lg fa-fw me-2 fa-laravel'></i>laravel</>},
  { value:'fab fa-lg fa-fw me-2 fa-lastfm', label: <><i className='fab fa-lg fa-fw me-2 fa-lastfm'></i>lastfm</>},
  { value:'fab fa-lg fa-fw me-2 fa-lastfm-square', label: <><i className='fab fa-lg fa-fw me-2 fa-lastfm-square'></i>lastfm-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-leanpub', label: <><i className='fab fa-lg fa-fw me-2 fa-leanpub'></i>leanpub</>},
  { value:'fab fa-lg fa-fw me-2 fa-less', label: <><i className='fab fa-lg fa-fw me-2 fa-less'></i>less</>},
  { value:'fab fa-lg fa-fw me-2 fa-line', label: <><i className='fab fa-lg fa-fw me-2 fa-line'></i>line</>},
  { value:'fab fa-lg fa-fw me-2 fa-linkedin', label: <><i className='fab fa-lg fa-fw me-2 fa-linkedin'></i>linkedin</>},
  { value:'fab fa-lg fa-fw me-2 fa-linkedin-in', label: <><i className='fab fa-lg fa-fw me-2 fa-linkedin-in'></i>linkedin-in</>},
  { value:'fab fa-lg fa-fw me-2 fa-linode', label: <><i className='fab fa-lg fa-fw me-2 fa-linode'></i>linode</>},
  { value:'fab fa-lg fa-fw me-2 fa-linux', label: <><i className='fab fa-lg fa-fw me-2 fa-linux'></i>linux</>},
  { value:'fab fa-lg fa-fw me-2 fa-lyft', label: <><i className='fab fa-lg fa-fw me-2 fa-lyft'></i>lyft</>},
  { value:'fab fa-lg fa-fw me-2 fa-magento', label: <><i className='fab fa-lg fa-fw me-2 fa-magento'></i>magento</>},
  { value:'fab fa-lg fa-fw me-2 fa-maxcdn', label: <><i className='fab fa-lg fa-fw me-2 fa-maxcdn'></i>maxcdn</>},
  { value:'fab fa-lg fa-fw me-2 fa-medapps', label: <><i className='fab fa-lg fa-fw me-2 fa-medapps'></i>medapps</>},
  { value:'fab fa-lg fa-fw me-2 fa-medium', label: <><i className='fab fa-lg fa-fw me-2 fa-medium'></i>medium</>},
  { value:'fab fa-lg fa-fw me-2 fa-medium-m', label: <><i className='fab fa-lg fa-fw me-2 fa-medium-m'></i>medium-m</>},
  { value:'fab fa-lg fa-fw me-2 fa-medrt', label: <><i className='fab fa-lg fa-fw me-2 fa-medrt'></i>medrt</>},
  { value:'fab fa-lg fa-fw me-2 fa-meetup', label: <><i className='fab fa-lg fa-fw me-2 fa-meetup'></i>meetup</>},
  { value:'fab fa-lg fa-fw me-2 fa-microsoft', label: <><i className='fab fa-lg fa-fw me-2 fa-microsoft'></i>microsoft</>},
  { value:'fab fa-lg fa-fw me-2 fa-mix', label: <><i className='fab fa-lg fa-fw me-2 fa-mix'></i>mix</>},
  { value:'fab fa-lg fa-fw me-2 fa-mixcloud', label: <><i className='fab fa-lg fa-fw me-2 fa-mixcloud'></i>mixcloud</>},
  { value:'fab fa-lg fa-fw me-2 fa-mizuni', label: <><i className='fab fa-lg fa-fw me-2 fa-mizuni'></i>mizuni</>},
  { value:'fab fa-lg fa-fw me-2 fa-modx', label: <><i className='fab fa-lg fa-fw me-2 fa-modx'></i>modx</>},
  { value:'fab fa-lg fa-fw me-2 fa-monero', label: <><i className='fab fa-lg fa-fw me-2 fa-monero'></i>monero</>},
  { value:'fab fa-lg fa-fw me-2 fa-napster', label: <><i className='fab fa-lg fa-fw me-2 fa-napster'></i>napster</>},
  { value:'fab fa-lg fa-fw me-2 fa-node', label: <><i className='fab fa-lg fa-fw me-2 fa-node'></i>node</>},
  { value:'fab fa-lg fa-fw me-2 fa-node-js', label: <><i className='fab fa-lg fa-fw me-2 fa-node-js'></i>node-js</>},
  { value:'fab fa-lg fa-fw me-2 fa-npm', label: <><i className='fab fa-lg fa-fw me-2 fa-npm'></i>npm</>},
  { value:'fab fa-lg fa-fw me-2 fa-ns8', label: <><i className='fab fa-lg fa-fw me-2 fa-ns8'></i>ns8</>},
  { value:'fab fa-lg fa-fw me-2 fa-nutritionix', label: <><i className='fab fa-lg fa-fw me-2 fa-nutritionix'></i>nutritionix</>},
  { value:'fab fa-lg fa-fw me-2 fa-odnoklassniki', label: <><i className='fab fa-lg fa-fw me-2 fa-odnoklassniki'></i>odnoklassniki</>},
  { value:'fab fa-lg fa-fw me-2 fa-odnoklassniki-square', label: <><i className='fab fa-lg fa-fw me-2 fa-odnoklassniki-square'></i>odnoklassniki-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-opencart', label: <><i className='fab fa-lg fa-fw me-2 fa-opencart'></i>opencart</>},
  { value:'fab fa-lg fa-fw me-2 fa-openid', label: <><i className='fab fa-lg fa-fw me-2 fa-openid'></i>openid</>},
  { value:'fab fa-lg fa-fw me-2 fa-opera', label: <><i className='fab fa-lg fa-fw me-2 fa-opera'></i>opera</>},
  { value:'fab fa-lg fa-fw me-2 fa-optin-monster', label: <><i className='fab fa-lg fa-fw me-2 fa-optin-monster'></i>optin-monster</>},
  { value:'fab fa-lg fa-fw me-2 fa-osi', label: <><i className='fab fa-lg fa-fw me-2 fa-osi'></i>osi</>},
  { value:'fab fa-lg fa-fw me-2 fa-page4', label: <><i className='fab fa-lg fa-fw me-2 fa-page4'></i>page4</>},
  { value:'fab fa-lg fa-fw me-2 fa-pagelines', label: <><i className='fab fa-lg fa-fw me-2 fa-pagelines'></i>pagelines</>},
  { value:'fab fa-lg fa-fw me-2 fa-palfed', label: <><i className='fab fa-lg fa-fw me-2 fa-palfed'></i>palfed</>},
  { value:'fab fa-lg fa-fw me-2 fa-patreon', label: <><i className='fab fa-lg fa-fw me-2 fa-patreon'></i>patreon</>},
  { value:'fab fa-lg fa-fw me-2 fa-paypal', label: <><i className='fab fa-lg fa-fw me-2 fa-paypal'></i>paypal</>},
  { value:'fab fa-lg fa-fw me-2 fa-periscope', label: <><i className='fab fa-lg fa-fw me-2 fa-periscope'></i>periscope</>},
  { value:'fab fa-lg fa-fw me-2 fa-phabricator', label: <><i className='fab fa-lg fa-fw me-2 fa-phabricator'></i>phabricator</>},
  { value:'fab fa-lg fa-fw me-2 fa-phoenix-framework', label: <><i className='fab fa-lg fa-fw me-2 fa-phoenix-framework'></i>phoenix-framework</>},
  { value:'fab fa-lg fa-fw me-2 fa-pied-piper', label: <><i className='fab fa-lg fa-fw me-2 fa-pied-piper'></i>pied-piper</>},
  { value:'fab fa-lg fa-fw me-2 fa-pied-piper-alt', label: <><i className='fab fa-lg fa-fw me-2 fa-pied-piper-alt'></i>pied-piper-alt</>},
  { value:'fab fa-lg fa-fw me-2 fa-pied-piper-pp', label: <><i className='fab fa-lg fa-fw me-2 fa-pied-piper-pp'></i>pied-piper-pp</>},
  { value:'fab fa-lg fa-fw me-2 fa-pinterest', label: <><i className='fab fa-lg fa-fw me-2 fa-pinterest'></i>pinterest</>},
  { value:'fab fa-lg fa-fw me-2 fa-pinterest-p', label: <><i className='fab fa-lg fa-fw me-2 fa-pinterest-p'></i>pinterest-p</>},
  { value:'fab fa-lg fa-fw me-2 fa-pinterest-square', label: <><i className='fab fa-lg fa-fw me-2 fa-pinterest-square'></i>pinterest-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-playstation', label: <><i className='fab fa-lg fa-fw me-2 fa-playstation'></i>playstation</>},
  { value:'fab fa-lg fa-fw me-2 fa-product-hunt', label: <><i className='fab fa-lg fa-fw me-2 fa-product-hunt'></i>product-hunt</>},
  { value:'fab fa-lg fa-fw me-2 fa-pushed', label: <><i className='fab fa-lg fa-fw me-2 fa-pushed'></i>pushed</>},
  { value:'fab fa-lg fa-fw me-2 fa-python', label: <><i className='fab fa-lg fa-fw me-2 fa-python'></i>python</>},
  { value:'fab fa-lg fa-fw me-2 fa-qq', label: <><i className='fab fa-lg fa-fw me-2 fa-qq'></i>qq</>},
  { value:'fab fa-lg fa-fw me-2 fa-quora', label: <><i className='fab fa-lg fa-fw me-2 fa-quora'></i>quora</>},
  { value:'fab fa-lg fa-fw me-2 fa-ravelry', label: <><i className='fab fa-lg fa-fw me-2 fa-ravelry'></i>ravelry</>},
  { value:'fab fa-lg fa-fw me-2 fa-react', label: <><i className='fab fa-lg fa-fw me-2 fa-react'></i>react</>},
  { value:'fab fa-lg fa-fw me-2 fa-rebel', label: <><i className='fab fa-lg fa-fw me-2 fa-rebel'></i>rebel</>},
  { value:'fab fa-lg fa-fw me-2 fa-red-river', label: <><i className='fab fa-lg fa-fw me-2 fa-red-river'></i>red-river</>},
  { value:'fab fa-lg fa-fw me-2 fa-reddit', label: <><i className='fab fa-lg fa-fw me-2 fa-reddit'></i>reddit</>},
  { value:'fab fa-lg fa-fw me-2 fa-reddit-alien', label: <><i className='fab fa-lg fa-fw me-2 fa-reddit-alien'></i>reddit-alien</>},
  { value:'fab fa-lg fa-fw me-2 fa-reddit-square', label: <><i className='fab fa-lg fa-fw me-2 fa-reddit-square'></i>reddit-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-renren', label: <><i className='fab fa-lg fa-fw me-2 fa-renren'></i>renren</>},
  { value:'fab fa-lg fa-fw me-2 fa-replyd', label: <><i className='fab fa-lg fa-fw me-2 fa-replyd'></i>replyd</>},
  { value:'fab fa-lg fa-fw me-2 fa-resolving', label: <><i className='fab fa-lg fa-fw me-2 fa-resolving'></i>resolving</>},
  { value:'fab fa-lg fa-fw me-2 fa-rocketchat', label: <><i className='fab fa-lg fa-fw me-2 fa-rocketchat'></i>rocketchat</>},
  { value:'fab fa-lg fa-fw me-2 fa-rockrms', label: <><i className='fab fa-lg fa-fw me-2 fa-rockrms'></i>rockrms</>},
  { value:'fab fa-lg fa-fw me-2 fa-safari', label: <><i className='fab fa-lg fa-fw me-2 fa-safari'></i>safari</>},
  { value:'fab fa-lg fa-fw me-2 fa-sass', label: <><i className='fab fa-lg fa-fw me-2 fa-sass'></i>sass</>},
  { value:'fab fa-lg fa-fw me-2 fa-schlix', label: <><i className='fab fa-lg fa-fw me-2 fa-schlix'></i>schlix</>},
  { value:'fab fa-lg fa-fw me-2 fa-scribd', label: <><i className='fab fa-lg fa-fw me-2 fa-scribd'></i>scribd</>},
  { value:'fab fa-lg fa-fw me-2 fa-searchengin', label: <><i className='fab fa-lg fa-fw me-2 fa-searchengin'></i>searchengin</>},
  { value:'fab fa-lg fa-fw me-2 fa-sellcast', label: <><i className='fab fa-lg fa-fw me-2 fa-sellcast'></i>sellcast</>},
  { value:'fab fa-lg fa-fw me-2 fa-sellsy', label: <><i className='fab fa-lg fa-fw me-2 fa-sellsy'></i>sellsy</>},
  { value:'fab fa-lg fa-fw me-2 fa-servicestack', label: <><i className='fab fa-lg fa-fw me-2 fa-servicestack'></i>servicestack</>},
  { value:'fab fa-lg fa-fw me-2 fa-shirtsinbulk', label: <><i className='fab fa-lg fa-fw me-2 fa-shirtsinbulk'></i>shirtsinbulk</>},
  { value:'fab fa-lg fa-fw me-2 fa-simplybuilt', label: <><i className='fab fa-lg fa-fw me-2 fa-simplybuilt'></i>simplybuilt</>},
  { value:'fab fa-lg fa-fw me-2 fa-sistrix', label: <><i className='fab fa-lg fa-fw me-2 fa-sistrix'></i>sistrix</>},
  { value:'fab fa-lg fa-fw me-2 fa-skyatlas', label: <><i className='fab fa-lg fa-fw me-2 fa-skyatlas'></i>skyatlas</>},
  { value:'fab fa-lg fa-fw me-2 fa-skype', label: <><i className='fab fa-lg fa-fw me-2 fa-skype'></i>skype</>},
  { value:'fab fa-lg fa-fw me-2 fa-slack', label: <><i className='fab fa-lg fa-fw me-2 fa-slack'></i>slack</>},
  { value:'fab fa-lg fa-fw me-2 fa-slack-hash', label: <><i className='fab fa-lg fa-fw me-2 fa-slack-hash'></i>slack-hash</>},
  { value:'fab fa-lg fa-fw me-2 fa-slideshare', label: <><i className='fab fa-lg fa-fw me-2 fa-slideshare'></i>slideshare</>},
  { value:'fab fa-lg fa-fw me-2 fa-snapchat', label: <><i className='fab fa-lg fa-fw me-2 fa-snapchat'></i>snapchat</>},
  { value:'fab fa-lg fa-fw me-2 fa-snapchat-ghost', label: <><i className='fab fa-lg fa-fw me-2 fa-snapchat-ghost'></i>snapchat-ghost</>},
  { value:'fab fa-lg fa-fw me-2 fa-snapchat-square', label: <><i className='fab fa-lg fa-fw me-2 fa-snapchat-square'></i>snapchat-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-soundcloud', label: <><i className='fab fa-lg fa-fw me-2 fa-soundcloud'></i>soundcloud</>},
  { value:'fab fa-lg fa-fw me-2 fa-speakap', label: <><i className='fab fa-lg fa-fw me-2 fa-speakap'></i>speakap</>},
  { value:'fab fa-lg fa-fw me-2 fa-spotify', label: <><i className='fab fa-lg fa-fw me-2 fa-spotify'></i>spotify</>},
  { value:'fab fa-lg fa-fw me-2 fa-stack-exchange', label: <><i className='fab fa-lg fa-fw me-2 fa-stack-exchange'></i>stack-exchange</>},
  { value:'fab fa-lg fa-fw me-2 fa-stack-overflow', label: <><i className='fab fa-lg fa-fw me-2 fa-stack-overflow'></i>stack-overflow</>},
  { value:'fab fa-lg fa-fw me-2 fa-staylinked', label: <><i className='fab fa-lg fa-fw me-2 fa-staylinked'></i>staylinked</>},
  { value:'fab fa-lg fa-fw me-2 fa-steam', label: <><i className='fab fa-lg fa-fw me-2 fa-steam'></i>steam</>},
  { value:'fab fa-lg fa-fw me-2 fa-steam-square', label: <><i className='fab fa-lg fa-fw me-2 fa-steam-square'></i>steam-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-steam-symbol', label: <><i className='fab fa-lg fa-fw me-2 fa-steam-symbol'></i>steam-symbol</>},
  { value:'fab fa-lg fa-fw me-2 fa-sticker-mule', label: <><i className='fab fa-lg fa-fw me-2 fa-sticker-mule'></i>sticker-mule</>},
  { value:'fab fa-lg fa-fw me-2 fa-strava', label: <><i className='fab fa-lg fa-fw me-2 fa-strava'></i>strava</>},
  { value:'fab fa-lg fa-fw me-2 fa-stripe', label: <><i className='fab fa-lg fa-fw me-2 fa-stripe'></i>stripe</>},
  { value:'fab fa-lg fa-fw me-2 fa-stripe-s', label: <><i className='fab fa-lg fa-fw me-2 fa-stripe-s'></i>stripe-s</>},
  { value:'fab fa-lg fa-fw me-2 fa-studiovinari', label: <><i className='fab fa-lg fa-fw me-2 fa-studiovinari'></i>studiovinari</>},
  { value:'fab fa-lg fa-fw me-2 fa-stumbleupon', label: <><i className='fab fa-lg fa-fw me-2 fa-stumbleupon'></i>stumbleupon</>},
  { value:'fab fa-lg fa-fw me-2 fa-stumbleupon-circle', label: <><i className='fab fa-lg fa-fw me-2 fa-stumbleupon-circle'></i>stumbleupon-circle</>},
  { value:'fab fa-lg fa-fw me-2 fa-superpowers', label: <><i className='fab fa-lg fa-fw me-2 fa-superpowers'></i>superpowers</>},
  { value:'fab fa-lg fa-fw me-2 fa-supple', label: <><i className='fab fa-lg fa-fw me-2 fa-supple'></i>supple</>},
  { value:'fab fa-lg fa-fw me-2 fa-telegram', label: <><i className='fab fa-lg fa-fw me-2 fa-telegram'></i>telegram</>},
  { value:'fab fa-lg fa-fw me-2 fa-telegram-plane', label: <><i className='fab fa-lg fa-fw me-2 fa-telegram-plane'></i>telegram-plane</>},
  { value:'fab fa-lg fa-fw me-2 fa-tencent-weibo', label: <><i className='fab fa-lg fa-fw me-2 fa-tencent-weibo'></i>tencent-weibo</>},
  { value:'fab fa-lg fa-fw me-2 fa-themeisle', label: <><i className='fab fa-lg fa-fw me-2 fa-themeisle'></i>themeisle</>},
  { value:'fab fa-lg fa-fw me-2 fa-trello', label: <><i className='fab fa-lg fa-fw me-2 fa-trello'></i>trello</>},
  { value:'fab fa-lg fa-fw me-2 fa-tumblr', label: <><i className='fab fa-lg fa-fw me-2 fa-tumblr'></i>tumblr</>},
  { value:'fab fa-lg fa-fw me-2 fa-tumblr-square', label: <><i className='fab fa-lg fa-fw me-2 fa-tumblr-square'></i>tumblr-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-twitch', label: <><i className='fab fa-lg fa-fw me-2 fa-twitch'></i>twitch</>},
  { value:'fab fa-lg fa-fw me-2 fa-twitter', label: <><i className='fab fa-lg fa-fw me-2 fa-twitter'></i>twitter</>},
  { value:'fab fa-lg fa-fw me-2 fa-twitter-square', label: <><i className='fab fa-lg fa-fw me-2 fa-twitter-square'></i>twitter-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-typo3', label: <><i className='fab fa-lg fa-fw me-2 fa-typo3'></i>typo3</>},
  { value:'fab fa-lg fa-fw me-2 fa-uber', label: <><i className='fab fa-lg fa-fw me-2 fa-uber'></i>uber</>},
  { value:'fab fa-lg fa-fw me-2 fa-uikit', label: <><i className='fab fa-lg fa-fw me-2 fa-uikit'></i>uikit</>},
  { value:'fab fa-lg fa-fw me-2 fa-uniregistry', label: <><i className='fab fa-lg fa-fw me-2 fa-uniregistry'></i>uniregistry</>},
  { value:'fab fa-lg fa-fw me-2 fa-untappd', label: <><i className='fab fa-lg fa-fw me-2 fa-untappd'></i>untappd</>},
  { value:'fab fa-lg fa-fw me-2 fa-usb', label: <><i className='fab fa-lg fa-fw me-2 fa-usb'></i>usb</>},
  { value:'fab fa-lg fa-fw me-2 fa-ussunnah', label: <><i className='fab fa-lg fa-fw me-2 fa-ussunnah'></i>ussunnah</>},
  { value:'fab fa-lg fa-fw me-2 fa-vaadin', label: <><i className='fab fa-lg fa-fw me-2 fa-vaadin'></i>vaadin</>},
  { value:'fab fa-lg fa-fw me-2 fa-viacoin', label: <><i className='fab fa-lg fa-fw me-2 fa-viacoin'></i>viacoin</>},
  { value:'fab fa-lg fa-fw me-2 fa-viadeo', label: <><i className='fab fa-lg fa-fw me-2 fa-viadeo'></i>viadeo</>},
  { value:'fab fa-lg fa-fw me-2 fa-viadeo-square', label: <><i className='fab fa-lg fa-fw me-2 fa-viadeo-square'></i>viadeo-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-viber', label: <><i className='fab fa-lg fa-fw me-2 fa-viber'></i>viber</>},
  { value:'fab fa-lg fa-fw me-2 fa-vimeo', label: <><i className='fab fa-lg fa-fw me-2 fa-vimeo'></i>vimeo</>},
  { value:'fab fa-lg fa-fw me-2 fa-vimeo-square', label: <><i className='fab fa-lg fa-fw me-2 fa-vimeo-square'></i>vimeo-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-vimeo-v', label: <><i className='fab fa-lg fa-fw me-2 fa-vimeo-v'></i>vimeo-v</>},
  { value:'fab fa-lg fa-fw me-2 fa-vine', label: <><i className='fab fa-lg fa-fw me-2 fa-vine'></i>vine</>},
  { value:'fab fa-lg fa-fw me-2 fa-vk', label: <><i className='fab fa-lg fa-fw me-2 fa-vk'></i>vk</>},
  { value:'fab fa-lg fa-fw me-2 fa-vnv', label: <><i className='fab fa-lg fa-fw me-2 fa-vnv'></i>vnv</>},
  { value:'fab fa-lg fa-fw me-2 fa-vuejs', label: <><i className='fab fa-lg fa-fw me-2 fa-vuejs'></i>vuejs</>},
  { value:'fab fa-lg fa-fw me-2 fa-weibo', label: <><i className='fab fa-lg fa-fw me-2 fa-weibo'></i>weibo</>},
  { value:'fab fa-lg fa-fw me-2 fa-weixin', label: <><i className='fab fa-lg fa-fw me-2 fa-weixin'></i>weixin</>},
  { value:'fab fa-lg fa-fw me-2 fa-whatsapp', label: <><i className='fab fa-lg fa-fw me-2 fa-whatsapp'></i>whatsapp</>},
  { value:'fab fa-lg fa-fw me-2 fa-whatsapp-square', label: <><i className='fab fa-lg fa-fw me-2 fa-whatsapp-square'></i>whatsapp-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-whmcs', label: <><i className='fab fa-lg fa-fw me-2 fa-whmcs'></i>whmcs</>},
  { value:'fab fa-lg fa-fw me-2 fa-wikipedia-w', label: <><i className='fab fa-lg fa-fw me-2 fa-wikipedia-w'></i>wikipedia-w</>},
  { value:'fab fa-lg fa-fw me-2 fa-windows', label: <><i className='fab fa-lg fa-fw me-2 fa-windows'></i>windows</>},
  { value:'fab fa-lg fa-fw me-2 fa-wordpress', label: <><i className='fab fa-lg fa-fw me-2 fa-wordpress'></i>wordpress</>},
  { value:'fab fa-lg fa-fw me-2 fa-wordpress-simple', label: <><i className='fab fa-lg fa-fw me-2 fa-wordpress-simple'></i>wordpress-simple</>},
  { value:'fab fa-lg fa-fw me-2 fa-wpbeginner', label: <><i className='fab fa-lg fa-fw me-2 fa-wpbeginner'></i>wpbeginner</>},
  { value:'fab fa-lg fa-fw me-2 fa-wpexplorer', label: <><i className='fab fa-lg fa-fw me-2 fa-wpexplorer'></i>wpexplorer</>},
  { value:'fab fa-lg fa-fw me-2 fa-wpforms', label: <><i className='fab fa-lg fa-fw me-2 fa-wpforms'></i>wpforms</>},
  { value:'fab fa-lg fa-fw me-2 fa-xbox', label: <><i className='fab fa-lg fa-fw me-2 fa-xbox'></i>xbox</>},
  { value:'fab fa-lg fa-fw me-2 fa-xing', label: <><i className='fab fa-lg fa-fw me-2 fa-xing'></i>xing</>},
  { value:'fab fa-lg fa-fw me-2 fa-xing-square', label: <><i className='fab fa-lg fa-fw me-2 fa-xing-square'></i>xing-square</>},
  { value:'fab fa-lg fa-fw me-2 fa-y-combinator', label: <><i className='fab fa-lg fa-fw me-2 fa-y-combinator'></i>y-combinator</>},
  { value:'fab fa-lg fa-fw me-2 fa-yahoo', label: <><i className='fab fa-lg fa-fw me-2 fa-yahoo'></i>yahoo</>},
  { value:'fab fa-lg fa-fw me-2 fa-yandex', label: <><i className='fab fa-lg fa-fw me-2 fa-yandex'></i>yandex</>},
  { value:'fab fa-lg fa-fw me-2 fa-yandex-international', label: <><i className='fab fa-lg fa-fw me-2 fa-yandex-international'></i>yandex-international</>},
  { value:'fab fa-lg fa-fw me-2 fa-yelp', label: <><i className='fab fa-lg fa-fw me-2 fa-yelp'></i>yelp</>},
  { value:'fab fa-lg fa-fw me-2 fa-yoast', label: <><i className='fab fa-lg fa-fw me-2 fa-yoast'></i>yoast</>},
  { value:'fab fa-lg fa-fw me-2 fa-youtube', label: <><i className='fab fa-lg fa-fw me-2 fa-youtube'></i>youtube</>},
  
    
  ];