import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";

// import { getData, postData } from "../../Axios/axiosData";

const initialState = {
  Payrolls: [],
  loading: false,
  error: null,
  Payroll: [],
  ElementLinkDeatail: [],
  PaymentMethodList:[],
};

export const addPayroll = createAsyncThunk(
  "Pyrolls/AddPayroll",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;


    try {
      // const data = await postData( "Payroll/AddPayroll" , item);
      const {data} = await ntxAPI.post("Payroll/AddPayroll" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);


export const getPayrolls = createAsyncThunk(
  "Pyrolls/getPayrolls",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("Payroll/GetPayrollList");
      const {data} = await ntxAPI.get("Payroll/GetPayrollList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetElementLinkDeatail = createAsyncThunk(
  "Pyrolls/GetElementLinkDeatail",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;


    try {
      // const data = await getData(`Payroll/GetElementLinkDetail?PayrollId=${id}`);
      const {data} = await ntxAPI.get(`Payroll/GetElementLinkDetail?PayrollId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const AddElementLink = createAsyncThunk(
  "Pyrolls/AddElementLink",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
 

    try {
      // const data = await postData( "Payroll/AddElementLink", item);
      const {data} = await ntxAPI.post("Payroll/AddElementLink" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);



export const DeleteElementLink = createAsyncThunk(
  "Pyrolls/GetElementLinkDeatail",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData(`Payroll/DeleteElementLink?Id=${id}`);
      const {data} = await ntxAPI.post(`Payroll/DeleteElementLink?Id=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);





export const GetPaymentMethod = createAsyncThunk(
  "Pyrolls/GetPaymentMethod",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("Payroll/GetPaymentMethodList");
      const {data} = await ntxAPI.get("Payroll/GetPaymentMethodList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);



const PayrollSlice = createSlice({
  name: "Pyrolls",
  initialState,
  reducers: {},
  extraReducers: {
    /////// get all Payrolls
    [getPayrolls.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPayrolls.fulfilled]: (state, action) => {
      state.loading = false;
      state.Payrolls = action.payload;
    },
    [getPayrolls.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    /////// get ElementLinkDeatail
    [GetElementLinkDeatail.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetElementLinkDeatail.fulfilled]: (state, action) => {
      state.loading = false;
      state.ElementLinkDeatail = action.payload;
    },
    [GetElementLinkDeatail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    ////// AddElementLink
    [AddElementLink.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [AddElementLink.fulfilled]: (state, action) => {
      state.loading = false;
      state.ElementLinkDeatail = action.payload;
      // state.elementLinkDeatail.push(action.payload)
    },
    [AddElementLink.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },




    /////// get PaymentMethodList
    [GetPaymentMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetPaymentMethod.fulfilled]: (state, action) => {
      state.loading = false;
      state.PaymentMethodList = action.payload;
    },
    [GetPaymentMethod.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //
        /////// Add all Payrolls
        [addPayroll.pending]: (state) => {
          state.loading = true;
          state.error = null;
        },
        [addPayroll.fulfilled]: (state, action) => {
          state.loading = false;
          state.Payrolls.push(action.payload)
        },
        [addPayroll.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },


  },
});

export default PayrollSlice.reducer;
