import React, { useEffect, useState } from "react";
import Pagination from "../../../../components/Pagination/Pagination";
import { Card, CardBody } from "../../../../components/card/card";
import Loader from "../../../../components/Loader/Loader";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { GetEquipmentsList } from "../../../../store/FLEET/Equipments/equipmentsSlice";
import { useNavigate } from "react-router-dom";
import useGetEquipmentsList from "../../../../hooks/FLeet/useGetEquipmentsList";


const Index = () => {

    const [Show, setShow] = useState(false);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(10);
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const Navigate = useNavigate();

    const { EquipmentsList , loading} = useGetEquipmentsList()


    const filteredSearch = EquipmentsList?.slice()?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    });
  
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
    //________________________________
    var BcrumbList = [
      {
        name: "Home",
        link: "/",
      },
      {
        name: "EquipmentInspection",
        link: null,
      },
    ];
  



  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="EquipmentInspection" />

      <Card>
        {/* <Loading loading={loading} error={error} SkeletonType="Table"> */}
        <Loader loading={loading} />
        <CardBody>
          <div className=" p-2">
            <div >
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t("EquipmentName")}</th>
                      <th scope="col">{t("Plate_Number")}</th>
                      <th scope="col">{t('AssetNumber')}</th>
                      <th scope="col">{t('ServiceDate')}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          <div className="d-flex align-items-center">
                            <div className="w-50px h-50px bg-white bg-opacity-25 d-flex align-items-center justify-content-center">
                              {item.image === null ? (
                                <img
                                  alt=""
                                  className="mw-100 mh-100"
                                  src="/assets/img/Image/NoImg2.ico"
                                />
                              ) : (
                                <img
                                  alt=""
                                  className="mw-100 mh-100" //img-fluid
                                  src={item?.image?.filePath}
                                />
                              )}
                            </div>
                            <div className="ms-3">
                              {i18n.language === "en" ? item.name : item.name2}
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">{item.plateNo}</td>
                        <td className="align-middle">{item.code}</td>
                        <td className="align-middle">{item.serviceDate}</td>
                        <td className="pt-3">
                          <div className="btn-group">

                            <button
                              type="button"
                              className="btn btn-outline-secondary btn-lg m-1"
                              data-toggle="tooltip"
                              title="Scannig List"
                              onClick={() =>
                                Navigate(`${item.id}/ScanningList`)
                              }
                            >
                              <i className="fas fa-lg bi bi-list text-theme"></i>
                            </button>

                            <button
                              type="button"
                              className="btn btn-outline-secondary btn-lg m-1"
                              data-toggle="tooltip"
                              title="Scan"
                              onClick={() =>
                                Navigate(`${item.id}/AddScan`)
                              }
                            >
                              <i className="fas fa-lg bi bi-question-diamond text-warning"></i>
                            </button>
 
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) =>
                  setCurrentPage(pageNumber)
                }
                currentPage={currentPage}
              />
            </div>
          </div>
        </CardBody>
        {/* </Loading> */}
      </Card>

      {/* <ModalComponent title={t("AddEquipment")} id="modalِAddEquipments">
        {Show && <AddEquipments />}
      </ModalComponent> */}
    </div>
  );
};

export default Index;
