import React from "react";
import App from "../App";
import { Navigate, createBrowserRouter } from "react-router-dom";

// import Home from "./../pages/home/home.js";
import Login from "../pages/Login/Login.js";
import Dashboard from "../pages/dashboard/dashboard.js";
import Employees from "../pages/HR/Employees/HR-PERSON/Index/Index.js";

import PagesError from "../pages/PagesError/PagesError.js";
import Assignment from "../pages/HR/Employees/Assignment/Assignment.js";
import Absence from "../pages/HR/Employees/Absence/absence";
import HRRequests from "../pages/HR/HRRequest/HRRequests";

import ElementLink from "../pages/HR/payroll/payroll/ElementLink.js";

import EditJob from "../pages/HR/settings/Jobs/EditJob.js";
import EditJobGrade from "../pages/HR/settings/JobGrades/EditJobGrade.js";
import EditLocation from "../pages/HR/settings/Location/EditLocation/EditLocation.js";
import EditPosition from "../pages/HR/settings/position/EditPosition.js";
import EditDepartments from "../pages/HR/Departments/EditDepartments/EditDepartments.js";

import EditForm from "../pages/NTX/FlexValue/Forms/EditForm.js";
import { AddFlexValue } from "../pages/NTX/FlexValue/ListSystem/FlexValue/AddFlexValue.js";
import AddFormColumn from "../pages/NTX/FlexValue/FormColumn/AddFormColumn.js";
// import EditFormColumn from "../pages/NTX/FlexValue/FormColumn/EditFormColumn.js";

import AddAmeCondition from "../pages/Ame/AmeCondition/AddAmeCondition.js";
import EditApprovalGroup from "../pages/Ame/ApprovalGroup/EditApprovalGroup.js";
import AddApprovalMember from "../pages/Ame/ApprovalMember/AddApprovalMember.js";
import AddAmeRule from "../pages/Ame/AmeRule/AmeRule/AddAmeRule.js";
import AddAmeConditionUsage from "../pages/Ame/AmeRule/AmeConditionUsage/AddAmeConditionUsage.js";
import AddAmeAction from "../pages/Ame/AmeRule/AmeActionRule/AddAmeAction.js";

import EditUser from "../pages/NTX/Security/Users/EditUser.js";
import EditResponsibility from "../pages/NTX/Security/AuthResponsibility/EditResponsibility.js";
import ResponsibilityUser from "../pages/NTX/Security/ResponsibilityUser/ResponsibilityUser";
import ResponsibilityFunction from "../pages/NTX/Security/ResponsibilityFunction/ResponsibilityFunction.js";
import EditElementType from "../pages/HR/payroll/payrollElement/EditElementType.js";
import AddPayrollElementEntry from "../pages/HR/payroll/PayrollElementEntry/AddPayrollElementEntry.js";
import AddPayProposal from "../pages/HR/payroll/PayProposal/AddPayProposal.js";
import RotationPlan from "../pages/HR/Raqeeb/RotationPlan.js";
import RotationSetting from "../pages/HR/Raqeeb/RotationSetting/RotationSetting.js";

import AmeRequests from "../pages/Ame/Requests/AmeRequests.js";
import AttendanceAnalysis from "../pages/HR/Raqeeb/AttendanceAnalysis/AttendanceAnalysis.js";
import AnalysisTable from "../pages/HR/Raqeeb/AttendanceAnalysis/AnalysisTable.js";

import AddPayrollRun from "../pages/HR/payroll/PayrollRun/AddPayrollRun.js";
import AddQuickPay from "../pages/HR/payroll/PayrollRun/AddQuickPay.js";
import ActionList from "../pages/HR/payroll/PayrollRun/ActionList/ActionList.js";
import SalaryReport from "../pages/HR/payroll/PayrollRun/ActionList/AssignmentAction.js";
import PayrollForPerson from "../pages/HR/payroll/PayrollRun/Report/PayrollForPerson.js";
import PayrollForAll from "../pages/HR/payroll/PayrollRun/Report/PayrollForAll.js";
import PayrollElementEntry from "../pages/HR/payroll/PayrollElementEntry/PayrollElementEntry.js";
import Departments from "../pages/HR/Departments/Departments.js";
import OrganizationStructure from "../pages/HR/Departments/Structure/OrganizationStructure.js";
import Job from "../pages/HR/settings/Jobs/Job.js";
import JobGrades from "../pages/HR/settings/JobGrades/JobGrades.js";
import Location from "../pages/HR/settings/Location/Location.js";
import Position from "../pages/HR/settings/position/position.js";
import GroupEmployees from "../pages/HR/settings/GroupEmployees/GroupEmployees.js";
import EditGroupEmployees from "../pages/HR/settings/GroupEmployees/EditGroupEmployees.js";
import Payroll from "../pages/HR/payroll/payroll/payroll.js";
import PayrollElement from "../pages/HR/payroll/payrollElement/PayrollElement.js";
import PayrollRun from "../pages/HR/payroll/PayrollRun/PayrollRun.js";
import QuickPay from "../pages/HR/payroll/PayrollRun/QuickPay.js";
import Forms from "../pages/NTX/FlexValue/Forms/Forms.js";
import ListSystem from "../pages/NTX/FlexValue/ListSystem/ListSystem.js";
import Users from "../pages/NTX/Security/Users/Users.js";
import AuthResponsibility from "../pages/NTX/Security/AuthResponsibility/AuthResponsibility.js";
import AmeCondition from "../pages/Ame/AmeCondition/AmeCondition.js";
import ApprovalGroup from "../pages/Ame/ApprovalGroup/ApprovalGroup.js";
import MyRequests from "../pages/Ame/ViewRequests/MyRequests.js";
import AddRequests from "../pages/Ame/Requests/AddRequests.js";
import ViewRequests from "../pages/Ame/ViewRequests/ViewRequests.js";
import ViewAbsence from "../pages/HR/Employees/Absence/ViewAbsence/Index";
import OnlyViewRequests from "../pages/Ame/Requests/OnlyViewRequests.js";
import ExtraInformationForm from "../pages/NTX/FlexValue/ExtraInformationForm/ExtraInformationForm.js";
import EditExtraInformationForm from "../pages/NTX/FlexValue/ExtraInformationForm/EditExtraInformationForm.js";
import ExtraResponsibilities from "../pages/NTX/Security/Users/ExtraResponsibilities.js";
import { AddSubLists } from "../pages/NTX/FlexValue/ListSystem/FlexValue/AddSubLists.js";
import ExtraRecordsForm from "../pages/NTX/FlexValue/ExtraRecordsForm/ExtraRecordsForm.js";
import EditExtraRecordsForm from "../pages/NTX/FlexValue/ExtraRecordsForm/EditExtraRecordsForm.js";
import SUExtraInformationDynamicType from "../pages/SU/ExtraInformationDynamicType/SU_ExtraInformationDynamicType.js";

import ExtraRecordFormPerson from "../pages/HR/Employees/HR-PERSON/ExtraRecord/ExtraRecordFormPerson.js";
import RealEstate from "../pages/SU/RealEstate/index/RealEstate";
import Accomodation from "../pages/SU/RealEstate/Accomodation/Accomodation";
import EditExtraInformationDynamicType from "../pages/NTX/FlexValue/ExtraInformationDynamicType/EditExtraInformationDynamicType.js";
import UpdateRealEstate from "../pages/SU/RealEstate/Update/UpdateRealEstate.js";
import AddContracts from "../pages/SU/Contracts/Add/AddContracts.js";
import Contracts from "../pages/SU/Contracts/index/Contracts.js";
import Branches from "../pages/SU/Branches/Index";
import UpdateContract from "../pages/SU/Contracts/Update/UpdateContract.js";
import AddReport from "../pages/NTX/Reports/Report/Add/AddReport";
import Reports from "../pages/NTX/Reports/Report/Index/Index";
import UpdateReport from "../pages/NTX/Reports/Report/Update/UpdateReport";
import HRReports from "../pages/HR/HRReports/HRReports";

// import Dash from "../pages/NTX/Test/Dash";
import AddReportShape from "../pages/NTX/Reports/ReportChart/Add/AddReportShape";
import ReportsBuilder from "../pages/NTX/Reports/ReportsAllByModule/Reports/ReportsBuilder/ReportsBuilder";
import Lookup from "../pages/NTX/Lookup/Lookup";
import UpdatePerson from "../pages/HR/Employees/HR-PERSON/Edit/UpdatePerson";
import Applications from "../pages/Applications/Applications";
// import NtxPeoples from "../pages/NTX/Peoples/NtxPeoplesIndex";
import Workshop from "../pages/FLEET/FleetSettings/Workshop/Index";
import FleetLocations from "../pages/FLEET/FleetSettings/Locations/Index";
import UppdateWorkShop from "../pages/FLEET/FleetSettings/Workshop/UppdateWorkShop";
import EquipmentsModel from "../pages/FLEET/FleetSettings/Manufacturer/Index";
import EditManufacturer from "../pages/FLEET/FleetSettings/Manufacturer/EditManufacturer";
import Model from "../pages/FLEET/FleetSettings/Model/Index";
import EditModel from "../pages/FLEET/FleetSettings/Model/EditModel";



// Scanning Fleet 
import FleetElementScan from "../pages/FLEET/FleetScan/ScanElement/Index";
import AddElementScan from "../pages/FLEET/FleetScan/ScanElement/AddElement";
import EquipmentInspection from "../pages/FLEET/FleetScan/ScanEquipments/Index";
// import AddScan from "../pages/FLEET/FleetScan/ScanEquipments/AddScan";
import ScanningList from "../pages/FLEET/FleetScan/ScanEquipments/ScanningList";
import AddScan from "../pages/FLEET/FleetScan/ScanEquipments/AddScan";
import UpdateScan from "../pages/FLEET/FleetScan/ScanEquipments/UpdateScan";



import Equipments from "../pages/FLEET/Equipments/Index";
import Drivers from "../pages/FLEET/FleetSettings/Drivers/Index";
import Owners from "../pages/FLEET/FleetSettings/Owners/Index";
import Suppliers from "../pages/FLEET/FleetSettings/Suppliers/Index";
import EditEquipments from "../pages/FLEET/Equipments/EditEquipments";
import EquipmentsAssignment from "../pages/FLEET/EquipmentsAssignment/EquipmentsAssignment";
import EquipmentsOdometer from "../pages/FLEET/EquipmentsOdometer/EquipmentsOdometer";
import AddEquipmentRoutes from "../pages/FLEET/EquipmentRoutes/AddEquipmentRoutes";
import WorkRequests from "../pages/FLEET/WorkRequests/WorkRequestList";
import AddWorkRequest from "../pages/FLEET/WorkRequests/AddWorkRequest";
import FLEETRequest from "../pages/FLEET/FLEETRequest/FLEETRequest";
import MaintenanceOrder from "../pages/FLEET/MaintenanceOrder/Index";
import AddOrderMaintenance from "../pages/FLEET/MaintenanceOrder/AddOrderMaintenance";
import EditEquipmentRoutes from "../pages/FLEET/EquipmentRoutes/EditEquipmentRoutes";
import EditDrivers from "../pages/FLEET/FleetSettings/Drivers/EditDrivers";
import EditOwners from "../pages/FLEET/FleetSettings/Owners/EditOwners";
import EditSuppliers from "../pages/FLEET/FleetSettings/Suppliers/EditSuppliers";
import CloseOrder from "../pages/FLEET/MaintenanceOrder/CloseOrder";
import AssignmentRoutes from "../pages/FLEET/AssignmentRoutes/AssignmentRoutes";
import SUReports from "../pages/SU/SUReports/SUReports";
import FLEETReports from "../pages/FLEET/FLEETReports/FLEETReports";
import UppdateLocations from "../pages/FLEET/FleetSettings/Locations/UppdateLocations";
import UpdateBranches from "../pages/SU/Branches/UpdateBranches";
import InstallmentUnPaymentList from "../pages/SU/FollowPayments/InstallmentUnPayment/InstallmentUnPaymentList";
import InstallmentPaymentList from "../pages/SU/FollowPayments/InstallmentPayment/InstallmentPaymentList";
import TestComponent from "../pages/ExtraInformation/ExtraRecordsDynamicForm/BuilderExtraRecord/Tabs/TestComponent";
import ExtraRecordRealEstate from "../pages/SU/RealEstate/ExtraRecord/ExtraRecordRealEstate";





const AppRoute = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <PagesError />,
    children: [
      { path: "", element: <Navigate to="/login" /> },
      { path: "dashboard", element: <Dashboard /> },

      // ________ HR ___________
      // {
      //   path: "HR/Test",
      //   element: (
      //     <TestComponent />
      //   ),
      // },
      {
        path: "HR/Employees",
        element: <Employees />,
        children: [],
      },
      {
        path: "HR/Employees/:id/Edit",
        element: (
          <UpdatePerson />
        ),
      },
      {
        path: "HR/Employees/:id/Assignments",
        element: <Assignment />,
      },
      {
        path: "HR/Employees/absence/:personId",
        element: <Absence />,
      },
      {
        path: "HR/Employees/absence/:Id/ViewAbsence",
        element: <ViewAbsence />,
      },

      {
        path: "HR/Employees/:PersonId/ExtraRecordFormPerson",
        element: <ExtraRecordFormPerson />,
      },

      // ____ Department ___________________________

      {
        path: "Org/*",
        children: [
          { path: "Departments", element: <Departments /> },
          { path: "OrganizationStructure", element: <OrganizationStructure /> },
          { path: "Departments/:id/Edit", element: <EditDepartments /> },
        ],
      },

      //_______ Payrolls____________________________
      {
        path: "Pay/*",
        children: [
          { path: "Payrolls", element: <Payroll /> },
          { path: "payrolls/:id/ElementLink", element: <ElementLink /> },
          { path: "PayrollElement", element: <PayrollElement /> },
          {
            path: "PayrollElement/:id/EditElementType",
            element: <EditElementType />,
          },
          { path: "payrollsRun", element: <PayrollRun /> },
          {
            path: "payrollsRun/:payrollId/AddPayrollRun",
            element: <AddPayrollRun />,
          },
          {
            path: "payrollsRun/:payrollId/ActionList",
            element: <ActionList />,
          },
          {
            path: "payrollsRun/:payrollId/ActionList/:PayrollActionId/AssignmentActionList",
            element: <SalaryReport />,
          },
          { path: "QuickPay", element: <QuickPay /> },
          {
            path: "QuickPay/:payrollId/:assignmentId/AddQuickPay",
            element: <AddQuickPay />,
          },
          { path: "PayrollElementEntry", element: <PayrollElementEntry /> },
          {
            path: "PayrollElementEntry/:assignmentId/AddPayrollElementEntry",
            element: <AddPayrollElementEntry />,
          },
          {
            path: "PayrollElementEntry/:assignmentId/AddPayProposal",
            element: <AddPayProposal />,
          },
        ],
      },

      //________________________HRsettings________________________
      {
        path: "HRsettings/*",
        children: [
          { path: "Job", element: <Job /> },
          { path: "Job/:id/Edit", element: <EditJob /> },
          { path: "JobGrade", element: <JobGrades /> },
          { path: "JobGrade/:id/Edit", element: <EditJobGrade /> },
          { path: "Location", element: <Location /> },
          { path: "Location/:id/Edit", element: <EditLocation /> },
          { path: "Position", element: <Position /> },
          { path: "Position/:id/Edit", element: <EditPosition /> },
          { path: "StaffGroup", element: <GroupEmployees /> },
          { path: "StaffGroup/:id/Edit", element: <EditGroupEmployees /> },
        ],
      },

      //________________________HRReports________________________
      {
        path: "HR/Reports/*",
        children: [
          { path: "*", element: <HRReports /> },
          { path: ":ReportId/ReportsBuilder", element: <ReportsBuilder /> },
        ],
      },


      //________________________HRRequest________________________
      {
        path: "HRAme/Requests/*",
        children: [
          { path: "*", element: <HRRequests/> },
        ],
      },



















      //_____________________ NTX Routes ________________________________________________
      { path: "Forms", element: <Forms /> },
      {
        path: "NTX/*",
        children: [
          { path: "Forms", element: <Forms /> },
          { path: "Forms/:id/EditForm", element: <EditForm /> },
          { path: "Forms/:id/AddFormColumns", element: <AddFormColumn /> },
          // {
          //   path: "FlexValue/:id/EditFormColumns",
          //   element: <EditFormColumn />,
          // },
          { path: "ListSystem", element: <ListSystem /> },
          { path: "ListSystem/:id/AddFlexValue", element: <AddFlexValue /> },
          { path: "ListSystem/:id/AddSubLists", element: <AddSubLists /> },

          { path: "ExtraInformationForm", element: <ExtraInformationForm /> },
          {
            path: "ExtraInformationForm/:id/EditExtraInformationForm",
            element: <EditExtraInformationForm />,
          },

          { path: "ExtraRecordsForm", element: <ExtraRecordsForm /> },
          {
            path: "ExtraRecordsForm/:id/EditExtraRecordsForm",
            element: <EditExtraRecordsForm />,
          },
        ],
      },
      //_________________________________ Security NTX __________________________________________

      {
        path: "Security/*",
        children: [
          { path: "Users", element: <Users /> },
          { path: "Users/:userId/Edit", element: <EditUser /> },
          {
            path: "Users/:userId/ResponsibilityUser",
            element: <ResponsibilityUser />,
          },
          {
            path: "Users/:userId/ExtraResponsibilities",
            element: <ExtraResponsibilities />,
          },
          { path: "Responsibilities", element: <AuthResponsibility /> },
          {
            path: "Responsibilities/:id/Edit",
            element: <EditResponsibility />,
          },
          {
            path: "Responsibilities/:id/ResponsibilityFunction",
            element: <ResponsibilityFunction />,
          },
        ],
      },
      //
      { path: "NTX/*", children: [{ path: "Lookup", element: <Lookup /> }] },
      // { path: "NTX/*", children: [{ path: "Peoples", element: <NtxPeoples/> }] },
      //_________________________________ Reports NTX __________________________________________

      {
        path: "RPT/Reports/*",
        children: [
          { path: "*", element: <Reports /> },
          { path: "AddReport", element: <AddReport /> },
          { path: ":id/UpdateReport", element: <UpdateReport /> },

          { path: ":id/AddReportShape", element: <AddReportShape /> },

          // { path: "dash", element: <Dash /> },
        ],
      },


























      //_________________________________ Ame__________________________________________

      {
        path: "Ame/*",
        children: [
          { path: "RequestSetting", element: <AmeCondition /> },
          {
            path: "RequestSetting/:id/AddAmeCondition",
            element: <AddAmeCondition />,
          },
          { path: "RequestSetting/:id/AddAmeRule", element: <AddAmeRule /> },
          {
            path: "RequestSetting/:id/AddAmeRule/:ruleId/AddAmeConditionUsage",
            element: <AddAmeConditionUsage />,
          },
          {
            path: "RequestSetting/:id/AddAmeRule/:ruleId/AddAmeAction",
            element: <AddAmeAction />,
          },

          { path: "ApprovalGroup", element: <ApprovalGroup /> },
          {
            path: "ApprovalGroup/:id/AddApprovalMember",
            element: <AddApprovalMember />,
          },
          {
            path: "ApprovalGroup/:id/EditApprovalGroup",
            element: <EditApprovalGroup />,
          },
        ],
      },

      // ______________________________ SelfService __________________________________________________
      {
        path: "SelfService/*",
        children: [
          { path: "Requests", element: <AmeRequests /> },
          {
            path: "Requests/:FormId/AddRequest",
            element: (
              <AddRequests />
            ),
          },
          {
            path: "Requests/:TransactionHistoryId/ViewRequests",
            element: <ViewRequests />,
          },
          {
            path: "Requests/:TransactionHistoryId/OnlyViewRequests",
            element: <OnlyViewRequests />,
          },
          { path: "MyRequests", element: <MyRequests /> },
        ],
      },

      // RAQEEEEEEEB

      { path: "RAQ/RotationPlan", element: <RotationPlan /> },
      {
        path: "RAQ/RotationPlan/:id/RotationSetting",
        element: <RotationSetting />,
      },
      { path: "RAQ/AttendancAnalysis", element: <AttendanceAnalysis /> },
      {
        path: "RAQ/AttendancAnalysis/AnalysisTable",
        element: <AnalysisTable />,
      },

      //___________________ SU _____________________________
      {
        path: "SU/*",
        children: [
          { path: "Contracts", element: <Contracts /> },
          { path: "Contracts/AddContarcts", element: <AddContracts /> },
          { path: "Contracts/:id/UpdateContract", element: <UpdateContract /> },
          { path: "RealEstate", element: <RealEstate /> },
          
          {
            path: "RealEstate/:Id/UpdateRealEstate",
            element: <UpdateRealEstate />,
          },
          {
            path: "RealEstate/:RealEstateId/ExtraRecordRealEstate",
            element: <ExtraRecordRealEstate />,
          },
          {
            path: "RealEstate/:Id/Accomodation",
            element: <Accomodation />,
          },

          { path: "FollowPayments/InstallmentUnPayment", element: <InstallmentUnPaymentList/> },
          { path: "FollowPayments/InstallmentPayment", element: <InstallmentPaymentList/> },

          {
            path: "SU_ExtraInformationDynamicType",
            element: <SUExtraInformationDynamicType />,
          },
          {
            path: "SU_ExtraInformationDynamicType/:FormId/Edit",
            element: <EditExtraInformationDynamicType />,
          }, // ستكون لكل موديول صفحة تعديل خاصة بمسار خاص ممكن نهندله بطريقة أفضل 
          
           { path: "Reports", element: <SUReports /> },
           { path: "Reports/:ReportId/ReportsBuilder", element: <ReportsBuilder /> },
        ],
      },

      { path: "SUSettings/Branches", element: <Branches /> },
      { path: "SUSettings/Branches/:id/Edit", element: <UpdateBranches/> },
      { path: "SUSettings/Locations", element: <FleetLocations/> },
      { path: "SUSettings/Locations/:id/Edit", element: <UppdateLocations/>},



      //_______________ FLEET ________________________________
      {
        path: "FLEETSettings/*",
        children: [
          { path: "Workshop", element: <Workshop/> },
          { path: "Workshop/:id/Edit", element: <UppdateWorkShop/>},

          { path: "Locations", element: <FleetLocations/> },
          { path: "Locations/:id/Edit", element: <UppdateLocations/>},

          { path: "Manufacturers", element: <EquipmentsModel/> },
          { path: "Manufacturers/:id/Edit", element: <EditManufacturer/> },

          { path: "Model", element: <Model/> },
          { path: "Model/:id/Edit", element: <EditModel/> },
          
          { path: "FleetElementScan", element: <FleetElementScan/> },
          { path: "FleetElementScan/:lookupCode/AddScanElement", element: <AddElementScan/> },



          { path: "Drivers", element: <Drivers/> },
          { path: "Drivers/:Id/Edit", element: <EditDrivers/> },

          { path: "Owners", element: <Owners/> },
          { path: "Owners/:Id/Edit", element: <EditOwners/> },

          { path: "Suppliers", element: <Suppliers/> },
          { path: "Suppliers/:Id/Edit", element: <EditSuppliers/> },
        ],
      },

      {
        path: "FLEET/*",
        children: [

          { path: "EquipmentInspection", element: <EquipmentInspection/> },
          { path: "EquipmentInspection/:fleetEquipmentsId/AddScan", element: <AddScan/> },
          { path: "EquipmentInspection/:fleetEquipmentsId/ScanningList", element: <ScanningList/> },
          { path: "EquipmentInspection/:fleetEquipmentsId/ScanningList/:id/Edit", element: <UpdateScan/> },

          { path: "Equipments", element: <Equipments/> },
          { path: "Equipments/:Id/Edit", element: <EditEquipments/> },

          { path: "Equipments/:Id/EquipmentsAssignation", element: <EquipmentsAssignment/> },
          { path: "Equipments/:Id/EquipmentsOdometer", element: <EquipmentsOdometer/> },

          { path: "Equipments/:Id/EquipmentRoutes", element: <AddEquipmentRoutes/> },
          { path: "Equipments/:Id/EquipmentRoutes/:equipmentRoutesID/Edit", element: <EditEquipmentRoutes/> },
          { path: "Equipments/:Id/EquipmentRoutes/:equipmentRoutesID/AssignmentRoutes", element: <AssignmentRoutes/> },
          
          { path: "WorkRequests", element: <WorkRequests/> },
          { path: "WorkRequests/:Id/AddWorkRequest", element: <AddWorkRequest/> },
          { path: "WorkRequests/:Id/AddMaintenanceOrder/:fleetEquipmentsId", element: <AddOrderMaintenance/> },
          
          
          { path: "Maintenance/MaintenanceOrder", element: <MaintenanceOrder/> },
          { path: "Maintenance/MaintenanceOrder/:Id/AddMaintenanceOrder/:fleetEquipmentsId", element: <AddOrderMaintenance/>  },
          { path: "Maintenance/MaintenanceOrder/:Id/CloseOrder", element: <CloseOrder/> },
         
          { path: "Reports", element: <FLEETReports /> },
          { path: "Reports/:ReportId/ReportsBuilder", element: <ReportsBuilder /> },

        ],
      },
            //________________________FLEEET Request________________________
            {
              path: "FLEET/Maintenance/Requests/*",
              children: [
                { path: "*", element: <FLEETRequest/> },
              ],
            },





      ///________________ Application 
      {
        path: "Neatix/Applications",
        element: <Applications/>,
      },
      
    ],
  },
  //__ outSide <Outlit> ـــ
  {
    path: "/Report/:AssignmentActionId/PayrollForPerson",
    element: <PayrollForPerson />,
  },
  {
    path: "/Report/:PayrollActionId/PayrollForAll",
    element: <PayrollForAll />,
  },


  { path: "login/*", children: [{ path: "", element: <Login /> }] },
]);

export default AppRoute;
