import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";

// import { getData, postData } from "../../Axios/axiosData";

const initialState = {
  loading: false,
  error: null,
  ElementTypeList:[],
  ElementTypeListById:[],
  ClassificationList:[]
};


export const GetElementType = createAsyncThunk(
  "PyrollElement/GetElementType",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("Payroll/GetElementTypeList");
      const {data} = await ntxAPI.get( "Payroll/GetElementTypeList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);


export const GetElementTypeById = createAsyncThunk(
    "PyrollElement/GetElementTypeById",
    async (id, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await getData(`Payroll/GetElementTypeById?guid=${id}`);
        const {data} = await ntxAPI.get(`Payroll/GetElementTypeById?guid=${id}` );
        return data;
      } catch (error) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
  );




export const AddElementType  = createAsyncThunk(
  "PyrollElement/AddElementLink",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Payroll/AddElementType" , item);
      const {data} = await ntxAPI.post( "Payroll/AddElementType", item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const UpdateElementType  = createAsyncThunk(
    "PyrollElement/UpdateElementType",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await postData("Payroll/UpdateElementType" , item);
        const {data} = await ntxAPI.post("Payroll/UpdateElementType" , item)
        return data;
      } catch (error) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
  );

export const GetClassification = createAsyncThunk(
  "PyrollElement/GetClassification",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("Payroll/GetElementClassificationList");
      const {data} = await ntxAPI.get( "Payroll/GetElementClassificationList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);




const PyrollElementSlice = createSlice({
  name: "PyrollElement",
  initialState,
  reducers: {
    cleanElementTypeListById : (state) => {
     state.ElementTypeListById = []
    }
  },
  extraReducers: {
    /////// get ElementTypeList
    [GetElementType.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetElementType.fulfilled]: (state, action) => {
      state.loading = false;
      state.ElementTypeList = action.payload;
    },
    [GetElementType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

     /////// get ElementTypeListById
     [GetElementTypeById.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetElementTypeById.fulfilled]: (state, action) => {
        state.loading = false;
        state.ElementTypeListById = action.payload;
      },
      [GetElementTypeById.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

    ////// AddElementType
    [AddElementType.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [AddElementType.fulfilled]: (state, action) => {
      state.loading = false;
      state.ElementTypeList.push(action.payload);
    },
    [AddElementType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },



    /////// get PaymentMethodList
    [GetClassification.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetClassification.fulfilled]: (state, action) => {
      state.loading = false;
      state.ClassificationList = action.payload;
    },
    [GetClassification.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {cleanElementTypeListById} = PyrollElementSlice.actions

export default PyrollElementSlice.reducer;
