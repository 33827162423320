import {
  Card,
  CardHeader,
  CardBody,
  CardExpandToggler,
} from "../../../components/card/card.jsx";
import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import Pagination from "../../../components/Pagination/Pagination.jsx";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizations } from "../../../store/HR/Organizations/organizationSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import ModalComponent from "../../../components/Modal/ModalComponent";
import AddDepartment from "./AddDepartment.js";

const Departments = () => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  // Get Organizations from redux
  const { Organizations, loading, error } = useSelector(
    (state) => state.Organizations
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);
 


  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = Organizations?.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  )
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return b[sortBy] > a[sortBy] ? 1 : -1;
      }
    })
    .slice(indexOfFirstData, indexOfLastData);

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  // report to xls
  const handleReport = () => {
    const csv = Papa.unparse({
      fields: ["id", "name"],
      data: currentData,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  //______________________
  const [Show, setShow] = useState(false);

  return (
    <>
    <div className="row">
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active">{t("Departments")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Departments")}</h1>
        </div>
      </div>

      </div>
      
      <Card>
        <Loader loading={loading} />
        <CardBody>
          <div className="tab-content p-4">
            <div className="tab-pane fade show active" id="allTab">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={ (event) => setSearch(event.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end">
                  <a
                    href="#/"
                    className="btn btn-theme me-1"
                    data-bs-toggle="modal"
                    data-bs-target="#modalXl"
                    onClick={() => setShow(true)}
                  >
                    <i className="fa fa-plus-circle fa-fw "></i>{" "}
                    {t("Add Department")}
                  </a>
                  <button
                    onClick={handleReport}
                    className="btn btn-default text-nowrap rounded-2 me-2"
                    tabIndex={0}
                    aria-controls="datatable"
                    type="button"
                  >
                    <span>
                      <i className="fa fa-file-excel fa-fw " /> Export CSV
                    </span>
                  </button>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col" onClick={() => handleSort("name")}>
                        {t("Name")}
                      </th>

                      <th scope="col" onClick={() => handleSort("name")}>
                        {t("location")}
                      </th>
                      <th scope="col"></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={index}>
                        <td className="align-middle">
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </td>
                        <td className="align-middle">
                          {i18n.language === "ar"
                            ? item.locationName2
                            : item.locationName}
                        </td>
                        <td className="align-middle">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-outline-lime mb-1 me-1"
                              onClick={() => Navigate(`${item.id}/Edit`)} //Departments/:id/Edit
                              // hidden={hiddenEdit}
                            >
                              <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                              {t("Button.Edit")}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <Pagination
                filteredData={Organizations}
                dataPerPage={dataPerPage}
                handlePagination={handlePagination}
                currentPage={currentPage}
              />
            </div>
          </div>
        </CardBody>
      </Card>

      <ModalComponent id="modalXl" title={t("Add Department")}>
        {Show && <AddDepartment />}
      </ModalComponent>
    </>
  );
};

export default Departments;
