import React, { Fragment, memo, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const Step3 = ({ fields, setCheckValidationStep3, setDataStep3 }) => {
  const { t, i18n } = useTranslation();

  // console.log(fields);

  const [tableFields, setTableFields] = useState([]);
  const [sqlName, setsqlName] = useState("");
  const [inputType, setInputType] = useState("");
  const [aliasName, setAliasName] = useState("");
  const [label, setLabel] = useState("");

  console.log(fields);
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedItems = [...tableFields];
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    // Update the sequance property based on the new order
    updatedItems.forEach((item, index) => {
      item.Sequance = index + 1;
    });

    setTableFields(updatedItems);
  };

  const handleAddToTable = (e) => {
    e.preventDefault();
    if (sqlName && aliasName) {
      // Check if the already exists in the table array
      const Exists = tableFields?.some((item) => item.Name === sqlName);
      if (Exists) {
        alert("تمت إضافته مسبقًا");
        return;
      }

      const newData = {
        Name: sqlName,
        Label: label,
        AliasName: aliasName,
        Sequance: tableFields?.length + 1,
        inputType: inputType,
      };
      setTableFields([...tableFields, newData]);
      setsqlName("");
      setAliasName("");
      setLabel("");
      // console.log(tableFields);
    }
  };

  const handleDeleteRowClick = (index) => {
    const updatedTableFields = [...tableFields];
    updatedTableFields.splice(index, 1);

    // After deleting a row, update the Sequance property for the remaining rows
    updatedTableFields.forEach((item, newIndex) => {
      item.Sequance = newIndex + 1;
    });

    setTableFields(updatedTableFields);
  };

  //_______________________ Stard Validation Table  ______________________________________
  useEffect(() => {
    setCheckValidationStep3(tableFields?.length > 0);
    setDataStep3(tableFields);
  }, [tableFields, setCheckValidationStep3]);
  //_______________________ End Validation Table  ______________________________________

  return (
    <Fragment>
      <Form onSubmit={handleAddToTable}>
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("Column")}</label>
              <Form.Select
                className="form-select"
                value={sqlName}
                onChange={(e) => {
                  setsqlName(e.target.value);
                  setInputType(e.target.selectedOptions[0].dataset.inputType);
                  setLabel(e.target.selectedOptions[0].dataset.label);
                }}
                required
              >
                <option>{i18n.language === "ar" ? "إختر" : "Choose"}</option>
                {fields &&
                  fields.map((item, idx) => (
                    <option
                      value={item.name}
                      key={idx}
                      data-input-type={item.inputType}
                      data-label={item.label}
                    >
                      {i18n.language === "ar" ? item.label : item.label}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label">{t("Alias_Name")}</label>
              <Form.Control
                className="form-control"
                type="text"
                value={aliasName}
                onChange={(e) => setAliasName(e.target.value)}
                required
              />
            </div>
          </div>
        </div>

        <div className="text-center mb-4">
          <button type="submit" className="btn me-1 btn-outline-theme mb-1">
            <i className="fa fa-plus-circle fa-fw me-1"></i>
            {t("Add")}
          </button>
        </div>
      </Form>



      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive">
              <table className="table ">
                <thead className="table-dark">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">{t("Column")}</th>
                    <th scope="col">{t("Alias_Name")}</th>
                    {/* <th scope="col">Sequance</th> */}
                    <th scope="col"></th>
                  </tr>
                </thead>
                <Droppable droppableId="table">
                  {(provided) => (
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>

                      {tableFields.map((item, index) => (
                        <Draggable
                          key={item.Name}
                          draggableId={item.Name}
                          index={index}
                        >
                          {(provided) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              key={index}
                            >
                              <td className="align-middle">
                                {" "}
                                <a
                                  href="/#"
                                  title="مقبض السحب"
                                  style={{
                                    textDecoration: "none",
                                    cursor: "grab",
                                    pointerEvents: "none",
                                  }}
                                >
                                  ⁞⁞
                                </a>{" "}
                              </td>
                              <td>{item.Label}</td>
                              <td>{item.AliasName}</td>
                              {/* <td>{item.Sequance}</td> */}
                              <td>
                                <Link
                                  style={{ color: "red" }}
                                  onClick={() => handleDeleteRowClick(index)}
                                >
                                  <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                                </Link>
                              </td>
                            </tr>
                            
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </div>
          </div>
        </div>
      </DragDropContext>
    </Fragment>
  );
};

export default memo(Step3);
