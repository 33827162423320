import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axios-custom";


  
export const GetRealEstateCountDP = createAsyncThunk(
    "DashboardSU/GetRealEstateCountDP",
    async (_,thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        const {data} = await ntxAPI.get("RealState/GetRealEstateCountDP" );
        return data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );





const initialState = { DashBoardList: null ,loading: false, error: null };


const dashboardSUSlice = createSlice({
  name: "DashboardSU",
  initialState,
  reducers: {  },
  extraReducers: { 


       // Get List  
    [GetRealEstateCountDP.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetRealEstateCountDP.fulfilled]: (state, {payload}) => {
      state.loading = false;
      if(payload?.succeeded === true && payload?.data){
        state.DashBoardList = payload.data;
      }
    },
    [GetRealEstateCountDP.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },



  },
});

export default dashboardSUSlice.reducer;
