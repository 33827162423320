import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { GetReportChartByReportId, GetReportChartList } from "../../../../../store/NTX/Reports/ReportShape/reportShapeSlice";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../../../components/Loader/Loader";

interface props {
  setID: React.Dispatch<React.SetStateAction<string>>;  // For Update 
}


const ReportChartList : React.FC<props> = (props) => {
  const dispatch = useAppDispatch();
  // const Navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  

  const { t, i18n } = useTranslation();

  useEffect(() => {
    // dispatch(GetReportChartList());
    if(id){
      dispatch(GetReportChartByReportId(id));
    }
  }, []);

  const { ReportChartByReportIdList , loading } = useAppSelector((state) => state.ReportShape);
  // console.log(ReportChartByReportIdList);

  return (
    <>
     {/* <Loader loading={loading} /> */}
        <div className="row">
      <div className="col-lg-12">
        <table className="table">
          <thead className="table-dark">
            <tr>
              <th scope="col">{t('Current_Charts')} </th>
              <th scope="col"></th>
            </tr>
          </thead>

          <tbody>
            {ReportChartByReportIdList &&
              ReportChartByReportIdList.map((item, idx) => (
                <tr key={++idx}>
                  <td className="align-middle">
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </td>

                  <td className="align-middle">
                    <button
                      type="button"
                      className="btn btn-outline-lime"
                      data-bs-toggle="modal"
                      data-bs-target="#UpdateReportShape"
                      onClick={()=>props.setID(item.id)}
                    >
                      <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                      {t("Button.Edit")}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>

    </>

  );
};

export default ReportChartList;
