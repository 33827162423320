import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";

const initialState = { GroupEmps: [], loading: false, error: null, GroupEmp: [] };


export const getGroupEmp = createAsyncThunk("Groups/getGroupEmp", async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get("HRSetting/GetGroupList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }

  });

  export const AddGroupEmp = createAsyncThunk(
    "Groups/AddGroupEmp",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await postData( "HRSetting/AddGroup", item);
        const {data} = await ntxAPI.post("HRSetting/AddGroup" , item)
        return data;
      } catch (error) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }

  );






const groupSlice = createSlice({
    name: "Groups",
    initialState,
    reducers: {  },
    extraReducers: {

      /////// get all Group
      [getGroupEmp.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [getGroupEmp.fulfilled]: (state, action) => {
        state.loading = false;
        state.GroupEmps = action.payload;
      },
      [getGroupEmp.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

         /////// get all Group
         [AddGroupEmp.pending]: (state) => {
          state.loading = true;
          state.error = null;
        },
        [AddGroupEmp.fulfilled]: (state, action) => {
          state.loading = false;
          state.GroupEmps.push(action.payload);
        },
        [AddGroupEmp.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },

     
    },
  });
  
  export default groupSlice.reducer;