import React, { useEffect } from "react";
import CustomAlert from "../../../../components/Alert/CustomAlert";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetEquipmentsModelById,
  GetEquipmentsModelList,
  UpdateEquipmentsModel,
} from "../../../../store/FLEET/EquipmentsModel/equipmentsmodelSlice";
import { Form } from "react-bootstrap";
 
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../../components/card/card";
import { AddEquipmentsModelFLeetSchema } from "../../../ValidationForm/validationSchema";
import FileList from "../../../Attachments/FileList";
 

const EditManufacturer = () => {

  const Navigate = useNavigate();
  const { id } = useParams();

  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(GetEquipmentsModelById(id));
    }
  }, []);
  const { EquipmentsModelListById } = useAppSelector(
    (state) => state.EquipmentsModel
  );

  const formik = useFormik({
    initialValues: {
      id: EquipmentsModelListById ? EquipmentsModelListById.id : "",
      name: EquipmentsModelListById ? EquipmentsModelListById.name : "",
      name2: EquipmentsModelListById ? EquipmentsModelListById.name2 : "",
      description: EquipmentsModelListById
        ? EquipmentsModelListById.description
        : "",
      parentId: null,
      manufYear: EquipmentsModelListById
        ? EquipmentsModelListById.manufYear
        : "",
      barcode: EquipmentsModelListById ? EquipmentsModelListById.barcode : "",
      catagory: EquipmentsModelListById ? EquipmentsModelListById.catagory : "",
      manufCountry: EquipmentsModelListById
        ? EquipmentsModelListById.manufCountry
        : "",
      seatsNo: EquipmentsModelListById ? EquipmentsModelListById.seatsNo : "",
      doorsNo: EquipmentsModelListById ? EquipmentsModelListById.doorsNo : "",
      fuelType: EquipmentsModelListById ? EquipmentsModelListById.fuelType : "",
      motionVector: EquipmentsModelListById
        ? EquipmentsModelListById.motionVector
        : "",
      horsepower: EquipmentsModelListById
        ? EquipmentsModelListById.horsepower
        : "",
      horsepowerTax: EquipmentsModelListById
        ? EquipmentsModelListById.horsepowerTax
        : "",
        Img: "",
        IsUpdateIMG: false,
    },
    enableReinitialize: true,
    validationSchema: AddEquipmentsModelFLeetSchema(t),
    onSubmit: (values) => {
      // console.log(values);

      const formData = new FormData();

      formData.append("Id", values.id);
      formData.append("Name", values.name);
      formData.append("Name2", values.name2);
      formData.append("Description", values.description);
      formData.append("ParentId", "" );
      formData.append("ManufYear", values.manufYear);
      formData.append("Barcode", values.barcode);
      formData.append("Catagory", values.catagory);
      formData.append("ManufCountry", values.manufCountry);
      // formData.append("SeatsNo", values.seatsNo);
      // formData.append("DoorsNo", values.doorsNo);
      formData.append("FuelType", values.fuelType);
      formData.append("MotionVector", values.motionVector);
      // formData.append("Horsepower", values.horsepower);
      // formData.append("HorsepowerTax", values.horsepowerTax);
      formData.append("Img", values.Img);
      formData.append("IsUpdateIMG", values.IsUpdateIMG.toString());

      dispatch(
        UpdateEquipmentsModel(formData)
      )
        .unwrap()
        .then((res: any) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: "Edit" });
            Navigate("/FLEETSettings/Manufacturers", { replace: true });
            dispatch(GetEquipmentsModelList());
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  const handleImageUpload = (event: any) => {
    formik.setFieldValue("Img", event.currentTarget.files[0]);
    formik.setFieldValue("IsUpdateIMG", true);
  };

  //______________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Manufacturers",
      link: "/FLEET/Manufacturers",
    },
    {
      name: "EditManufacturers",
      link: null,
    },
  ];


  
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="EditManufacturers" />
     
      
      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Arabic Name")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2 || ""}
                    isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("English Name")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name || ""}
                    isInvalid={!!(formik.touched.name && formik.errors.name)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row">

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Image')}</label>
                  <Form.Control
                    className="form-control"
                    type="file"
                    name="Img"
                    placeholder=" أختر صورة"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e)}
                    isInvalid={!!(formik.touched.Img && formik.errors.Img)}
                  />
                  <FileList
                    Attachments={
                      EquipmentsModelListById?.image
                        ? [EquipmentsModelListById.image]
                        : []
                    }
                  />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Note")}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description || ""}
                  />
                  <div></div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-outline-theme mb-1"
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-2"></i>
                )}
                {t("Save")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-outline-default mb-1"
                onClick={() =>
                  Navigate("/FLEETSettings/Manufacturers", { replace: true })
                }
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>

              
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default EditManufacturer;
