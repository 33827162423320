import { useFormik } from "formik";
import React, { useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ConfirmDeleteDialog from "../../../../components/Alert/ConfirmDeleteDialog";
import { Card, CardBody } from "../../../../components/card/card.jsx";
import {
  AddElementLink,
  DeleteElementLink,
  GetElementLinkDeatail,
} from "../../../../store/HR/Payroll/PayrollSlice.js";
import Loader from "../../../../components/Loader/Loader.js";
import { Form } from "react-bootstrap";
import CustomAlert from "../../../../components/Alert/CustomAlert";

const ElementLink = () => {
  const { id } = useParams();
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetElementLinkDeatail(id));
  }, [dispatch, id]);
  const { ElementLinkDeatail, loading } = useSelector(
    (state) => state.Payrolls
  );
  const dataElementLinkList =
    ElementLinkDeatail &&
    ElementLinkDeatail?.elemntType?.map((item) => ({
      value: item.id,
      label: i18n.language === "ar" ? item.name2 : item.name,
    }));
  //______________________________________

  const handleDeleteElement = (item) => {
    dispatch(DeleteElementLink(item.id))
      .unwrap()
      .then(() => {
        CustomAlert({action:"Delete"})
        dispatch(GetElementLinkDeatail(id));
      })
      .catch((error) => {
        console.log(error);
        CustomAlert({action:"Info"})
      });
  };

  // add Element Link
  const formik = useFormik({
    initialValues: {
      selectValue: "",
    },
    onSubmit: (values) => {
      dispatch(
        AddElementLink({
          payrollId: id,
          elementTypeId: formik.values.selectValue,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({action:"Add"})
          dispatch(GetElementLinkDeatail(id));
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({action:"Info"})
          console.log(error);
        });
    },
    validate: (values) => {
      const errors = {};
      if (!values.selectValue) {
        errors.selectValue = "Please select an option";
      }
      return errors;
    },
  });

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">{t("Home")}</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="/Pay/payrolls">{t("Payrolls")}</Link>
            </li>
            <li className="breadcrumb-item active">
              {t("Link Payroll Elements")}
            </li>
          </ul>
          <h1 className="page-header mb-0">
            {t("Link Elements for")} {"  "}
            {i18n.language === "ar"
              ? ElementLinkDeatail?.payroll?.name2
              : ElementLinkDeatail?.payroll?.name}
          </h1>
        </div>
      </div>

      <Card>
        <CardBody>
          <Loader loading={loading} />
          <div className="row">
            <div className="col-xl-8 mx-auto">
              <Form onSubmit={formik.handleSubmit}>
                <div className="form-group mb-3">
                  <label className="form-label">{t("Elemente")}</label>
                  <Select
                  isLoading={loading}
                    classNamePrefix="react-select"
                    isRtl
                    isSearchable={true}
                    options={dataElementLinkList}
                    onChange={(option) =>
                      formik.setFieldValue("selectValue", option.value)
                    }
                    placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                  />
                  {formik.errors.selectValue && formik.touched.selectValue && (
                    <div >
                      {formik.errors.selectValue}
                    </div>
                  )}

                </div>

                <div className="text-center">

                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn me-1 btn-theme mb-1"
                >
                  <i className="fas fa-plus fa-fw me-1"></i>
                  {formik.isSubmitting ? "Adding..." : t("Add")}
                </button>
                <button
                  type="button"
                  className=" btn me-1 btn-default mb-1"
                  onClick={() => Navigate(-1, { replace: true })}
                >
                  <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
                </button></div>
              </Form>
            </div>
          </div>

          <hr className="mb-0" />

          <div className="row">
            <div className="col-lg-12">
              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th scope="col"> {t("Name")}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {ElementLinkDeatail?.elementLink?.map((item, idx) => (
                    <tr key={item.id}>
                      <td>
                        {i18n.language === "ar" ? item.name2 : item.name}{" "}
                      </td>
                      <td>
                        <ConfirmDeleteDialog
                          onConfirm={() => handleDeleteElement(item)}
                          title="Are you sure?"
                          message="This action cannot be undone."
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
export default ElementLink;
