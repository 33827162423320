import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomAlert from "../../../components/Alert/CustomAlert.tsx";
import { Card, CardBody } from "../../../components/card/card";
import useGetPersons from "../../../hooks/HR/use-get-persons.js";
import {
  GetApprovalMemberByGroupId,
  insertApprovalMember,
} from "../../../store/Ame/AprovalMemberSlice";
import ListApprovalMember from "./ListApprovalMember";

const AddApprovalMember = () => {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  //
  const { Employees } = useGetPersons();

  //
  const { ApprovalMemberList } = useSelector((state) => state.ApprovalMember);
  useEffect(() => {
    dispatch(GetApprovalMemberByGroupId(id));
  }, []);
  console.log(ApprovalMemberList);

  const formik = useFormik({
    initialValues: {
      approvalGroupId: "",
      approverId: "",
      tableName: "",
      orderNumber: 0,
      isInformation: false,
    },
    // validationSchema: AddFormColumnSchema(t),
    onSubmit: (values, { resetForm }) => {
      console.log(values);

      dispatch(
        insertApprovalMember({
          approvalGroupId: id,
          approverId: formik.values.approverId,
          tableName: "PERSON",
          orderNumber: formik.values.orderNumber,
          isInformation: formik.values.isInformation,
        })
      )
        .unwrap()
        .then(() => {
          resetForm();
          CustomAlert({ action: "Add" });
          dispatch(GetApprovalMemberByGroupId(id));
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: "Error" });
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <>
      {/* <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t('Home')}</a>
            </li>
            <li className="breadcrumb-item active">ApprovalMember</li>
          </ul>
          <h1 className="page-header mb-0"> {t('Group members')} </h1>
        </div>
      </div> */}

      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item">
              <Link to="/Ame/ApprovalGroup" replace>
                {t("Approval Management")}
              </Link>
            </li>
            <li className="breadcrumb-item active">{t("Add group Members")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Add group Members")}</h1>
        </div>
      </div>

      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    {t("Member")}
                  </label>
                  <Form.Select
                    className="form-select"
                    name="approverId"
                    onChange={formik.handleChange}
                    value={formik.values.approverId}
                    isInvalid={!!formik.errors.approverId}
                  >
                    <option value={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {Employees.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    {t("Order")}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="orderNumber"
                    onChange={formik.handleChange}
                    value={formik.values.orderNumber}
                    isInvalid={!!formik.errors.orderNumber}
                  />
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">is Information</label>
                  <Form.Check
                    style={{ margin: "10px 20px" }}
                    type="switch"
                    id="custom-switch"
                    label=""
                    name="isInformation"
                    onChange={formik.handleChange}
                    value={formik.values.isInformation}
                    isInvalid={!!formik.errors.isInformation}
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn me-1 btn-outline-theme mb-1"
                disabled={formik.isSubmitting}
              >
                <i class="fas fa-plus fa-fw me-1"></i>
                {t("Add")}
              </button>

              <button
                type="button"
                className=" btn me-1 btn-outline-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>

          <hr className="mb-4" />

          <div className="row">
            <div className="col-lg-12">
              <ListApprovalMember data={ApprovalMemberList} />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AddApprovalMember;
