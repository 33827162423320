import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../../components/card/card";
import Loader from "../../../../../components/Loader/Loader";
import Pagination from "../../../../../components/Pagination/Pagination";
import { ConfirmPayrollAction, DeletePayrollAction, GetPayrollActionList } from "../../../../../store/HR/Payroll/runPayrollSlice";
import ConfirmDeleteDialog from "../../../../../components/Alert/ConfirmDeleteDialog";
import ConfirmEditDialog from "../../../../../components/Alert/ConfirmEditDialog";
import CustomAlert from '../../../../../components/Alert/CustomAlert.tsx';

const ActionList = () => {
  const { payrollId } = useParams();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { PayrollActionList, loading } = useSelector(
    (state) => state.runPayroll
  );
  console.log(PayrollActionList);

  useEffect(() => {
    dispatch(GetPayrollActionList(payrollId));
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = PayrollActionList?.filter((item) =>
    item?.name?.toLowerCase().includes(search.toLowerCase())
  ).slice(indexOfFirstData, indexOfLastData);

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  // report to xls
  const handleReport = () => {
    const csv = Papa.unparse({
      fields: ["id", "name"],
      data: PayrollActionList,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //_________
  const handleNavigate = (item) => {
    localStorage.setItem("MonthName", item.name);
    Navigate(`${item.id}/AssignmentActionList`);
  };

  //___________________
    // Handele Delete Payroll Action
    const handleDeletePayrollAction = async (item) => {
      await dispatch(DeletePayrollAction(item.id))
        .unwrap()
        .then(() => {
          dispatch(GetPayrollActionList(payrollId));
          CustomAlert({ action: "Delete" });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: "Error" });
        });
    };
  
  //___________________
    // Handele Delete Payroll Action
    const handleConfirmPayrollAction = async (item) => {
      await dispatch(ConfirmPayrollAction(item.id))
        .unwrap()
        .then(() => {
          dispatch(GetPayrollActionList(payrollId));
          CustomAlert({ action: "Add" , msg: `تم إعتماد الراتب  لشهر ${item.name} بنجاح`  });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: "Error" });
        });
    };
  

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item">
              <a onClick={() => Navigate(-1, { replace: true })} href="#/">
              {t("Run Payrolls")}
              </a>
            </li>

            <li className="breadcrumb-item active">عرض المسيرات</li>
          </ul>
          <h1 className="page-header mb-0">
            {i18n.language === "ar"
              ? localStorage.PayrollName2
              : localStorage.PayrollName}
          </h1>
        </div>
      </div>

      <Card>
        <CardBody>
          <Loader loading={loading} />
          <div className="tab-content p-4">
            <div className="tab-pane fade show active">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={handleSearch}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end">
                  <button
                    onClick={() => Navigate(-1, { replace: true })}
                    type="button"
                    className=" btn me-1 btn-outline-default "
                  >
                    <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
                  </button>

                  <button
                    onClick={handleReport}
                    className="btn btn-outline-default text-nowrap rounded-2"
                    tabIndex={0}
                    aria-controls="datatable"
                    type="button"
                  >
                    <span>
                      <i className="fa fa-file-excel fa-fw me-1" /> Export CSV
                    </span>
                  </button>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">الفترة</th>
                      <th scope="col">نوع المسير</th>
                      <th scope="col">بداية الفترة</th>
                      <th scope="col">نهاية الفترة</th>
                      <th scope="col">تاريخ</th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {PayrollActionList &&
                      currentData.map((item) => (
                        <tr key={item.id}>
                          <td className="align-middle">{item.name}</td>
                          <td className="align-middle">{item.actionType}</td>
                          <td className="align-middle">{item.startDate}</td>
                          <td className="align-middle">{item.endDate}</td>
                          <td className="align-middle">{item.creationDate}</td>
                          <td>
                            <button
                              type="button"
                              onClick={() => handleNavigate(item)}
                              className="btn btn-outline-lime"
                            >
                              <i className="fas fa-fw me-2 fa-book"></i>
                              كشف الراتب
                            </button>
                            {
                              item.ameStatus === 0  &&
                            <div className="btn-group" style={{ padding: "15px" }} >
                              <ConfirmEditDialog
                                btnText={t("إعتماد")}
                                onConfirm={() => handleConfirmPayrollAction(item)}
                                title="هل أنت متــأكد من إعتماد الراتب ؟ "
                                message="لا يمكن التراجع عن هذا الإجــراء."
                                IconButton="fas fa-lg fa-fw me-2 fa-pen-square"
                              />

                              <ConfirmDeleteDialog
                                onConfirm={() => handleDeletePayrollAction(item)}
                                title={`هل تريد حذف  مسيـــر شهر \n  ${item.name}`}
                                message="لا يمكن التراجع عن هذا الإجــراء."
                              />
                            </div>
                            }

                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={PayrollActionList}
                // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                dataPerPage={dataPerPage}
                handlePagination={handlePagination}
                currentPage={currentPage}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ActionList;
