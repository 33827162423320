import { useFormik } from "formik";
import React, { useEffect } from "react";
import CustomAlert from '../.././../../components/Alert/CustomAlert.tsx';
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AddAuthUser, GetUserTypes, getUsers } from "../../../../store/NTX/Security/usersSlice";
import useGetPersons from "../../../../hooks/HR/use-get-persons.js";
import { formAddUserSchema } from "../../../ValidationForm/validationSchema";

function AddUser() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { Employees } = useGetPersons();
  //
  const { UserTypesList } = useSelector((state) => state.Users);
  useEffect(() => {
    dispatch(GetUserTypes());
  }, [dispatch]);
  console.log(UserTypesList);

  ///
  const formik = useFormik({
    initialValues: {
      usersName: "",
      emailAddress: "",
      usersMobile: "",
      description: "",
      userTypeId: "",
      referenceId: "",
      isActive: false,
      startDate: "",
      endDate: "",
      businessGroupId:""
    },

      validationSchema: formAddUserSchema(t),

    onSubmit: (values) => {
      console.log(values);

      dispatch(
        AddAuthUser({
            usersName: values.usersName,
            emailAddress: values.emailAddress,
            usersMobile: values.usersMobile,
            description: values.description,
            userTypeId: values.userTypeId,
            referenceId: values.referenceId,
            isActive: values.isActive,
            startDate: values.startDate,
            endDate: values.endDate,
            businessGroupId: "",
            //
        })
      )
        .unwrap()
        .then(() => {
          CustomAlert({ action: "Add" });
          document.getElementById("closeModalUser").click();
          formik.resetForm();
          dispatch(getUsers())
        })
        .catch((error) => {
          formik.setSubmitting(false)
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label" >
               {t('User Name')}
              </label>
              <Form.Control
                className="form-control"
                type="text"
                name="usersName"
                onChange={formik.handleChange}
                value={formik.values.usersName}
                isInvalid={
                  formik.touched.usersName && formik.errors.usersName
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.usersName}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">{t('Employee Name')}</label>
              <Form.Select
                className="form-select"
                name="referenceId"
                onChange={formik.handleChange}
                value={formik.values.referenceId}
                isInvalid={
                  formik.touched.referenceId && formik.errors.referenceId
                }
              >
                <option key={null}>
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {Employees.map((item) => (
                  <option key={item.id} value={item.id}>
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.referenceId}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-2">
            <div className="form-group mb-3">
              <label className="form-label">
                {t('Type of User')}
              </label>
              <Form.Select
                className="form-select"
                name="userTypeId"
                onChange={formik.handleChange}
                value={formik.values.userTypeId}
                isInvalid={
                  formik.touched.userTypeId && formik.errors.userTypeId
                }
              >
                <option value={null}>
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {UserTypesList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>


        </div>

        <div className="row">

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">
                {t('Email Address')}
              </label>
              <Form.Control
                className="form-control"
                type="text"
                name="emailAddress"
                onChange={formik.handleChange}
                value={formik.values.emailAddress}
                isInvalid={
                  formik.touched.emailAddress && formik.errors.emailAddress
                }
              />
               <Form.Control.Feedback type="invalid">
                {formik.errors.emailAddress}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-2">
            <div className="form-group mb-3">
              <label className="form-label">
                {t('Mobile Number')}
              </label>
              <Form.Control
                className="form-control"
                type="text"
                name="usersMobile"
                onChange={formik.handleChange}
                value={formik.values.usersMobile}
                isInvalid={
                  formik.touched.usersMobile && formik.errors.usersMobile
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.usersMobile}
              </Form.Control.Feedback>
            </div>
          </div>

        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label">
           {t('Start Date')}
            </label>
            <Form.Control
              type="date"
              className="form-control"
              name="startDate"
              onChange={formik.handleChange}
              value={formik.values.startDate}
              isInvalid={
                formik.touched.startDate && formik.errors.startDate
              }
            />
            <Form.Control.Feedback type="invalid">
            {formik.errors.startDate}
          </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="form-group mb-3">
            <label className="form-label" >
              {t('Date_TO')}
            </label>
           
            <Form.Control
              type="date"
             className="form-control"
              name="endDate"
              onChange={formik.handleChange}
              value={formik.values.endDate}
              isInvalid={
                formik.touched.endDate && formik.errors.endDate
              }
            />
            <Form.Control.Feedback type="invalid">
            {formik.errors.endDate}
          </Form.Control.Feedback>
          </div>
        </div>

                  <div className="col-xl-1">
            <div className="form-group mb-3">
              <label className="form-label"style={{ margin: "0px 22px" }} >{t('Active')}</label>
              <Form.Check
                style={{ margin: "10px 20px" }}
                type="switch"
                id="custom-switch"
                label=""
                name="isActive"
                onChange={formik.handleChange}
                value={formik.values.isActive}
                checked={formik.values.isActive}
              />
            </div>
          </div>

        </div>


        <div className="row">
          <div className="col-xl-12">
            <div className="form-group mb-3">
              <label className="form-label" >
                {t('Note')}
              </label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={3}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              <div></div>
            </div>
          </div>
        </div>

    

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-outline-theme mb-1"
            id="SaveSubmit"
          >
          {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t("Add")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalUser"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
          <button
            type="button"
            onClick={formik.resetForm}
            className="btn me-1 btn btn-danger mb-1"
          >
            <i className="fa fa-trash-alt fa-fw me-1"></i>
            {t("Clear")}
          </button>
        </div>
      </Form>
    </>
  );
}

export default AddUser;
