import React, { useEffect, useMemo,  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardExpandToggler,
} from "../../../components/card/card.jsx";       
// import { getPayrolls } from "../../../../store/Payroll/PayrollSlice.js";
import { useTranslation } from "react-i18next";

// import { GetDynamicForm } from "../../../store/FlexValue/Form/formSlice.js";
import { GetApprovalGroupList } from "../../../store/Ame/approvalGroupSlice.js";
import Pagination from "../../../components/Pagination/Pagination.jsx";
import useLookupModule from "../../../hooks/Lookups/use-Lookup-Module.js";
import ModalComponent from "../../../components/Modal/ModalComponent.jsx";
import AddAprovalGroup from "./AddAprovalGroup.js";


const ApprovalGroup = () => {
  const { t , i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { ApprovalGroupList, loading } = useSelector(
    (state) => state.ApprovalGroup) 
  
    useEffect(()=>{
      dispatch(GetApprovalGroupList())
    },[])
    
 
  
 const handleSearch = (event) => {
    setSearch(event.target.value);  
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData =ApprovalGroupList 
    ?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))

    const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  const [selectedValue, setSelectedValue] = useState('');

  const { Module } = useLookupModule();

  useEffect(() => {
      if (Module?.length) {
        setSelectedValue(Module[0].id);
      }
    }, [Module]);

     function handleSelectChange(event) {
    setSelectedValue(event.target.value);
  }

///////////////////////////////

  //filter data by <select>
  const filteredDataByModules = ApprovalGroupList?.filter(item => item.moduleId === selectedValue);



  

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t('Home')}</a>
            </li>
            <li className="breadcrumb-item active">{t('Approval Management')}</li>
          </ul>
          <h1 className="page-header mb-0">{t('Approval Management')}</h1>
        </div>
      </div>
    <Card>
    <div className="tab-content p-4">
        <div className="tab-pane fade show active">
          <div className="row">
            <div className="col-7 col-md-6 d-flex justify-content-start">
              <div className="input-group mb-4">
                <div className="flex-fill position-relative">
                 
                <div className="input-group mb-4">



          <select
          className="btn btn-outline-default dropdown-toggle"
          id="my-select"
          value={selectedValue}
          onChange={handleSelectChange}
          >
          {
           Module && Module.map(item=>(
             <option value={item.id}  key={item.id} className="dropdown-item">{i18n.language === 'ar' ? item.name2 : item.name}   </option>
            ))
          }
          </select>




        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
              style={{ zIndex: 1020,"right": "10px" }}
            >
              <i className="fa fa-search opacity-5"></i>
            </div>
            <input
              type="text"
              className="form-control ps-35px"
              placeholder={t("Search")}
              value={search}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

                </div>
              </div>
            </div>
            <div className="col-5 col-md-6 text-end">
            <a
            href="#/"
            className="btn btn-outline-theme"
            data-bs-toggle="modal"
            data-bs-target="#modalAddAprovalGroup"
            // hidden={hiddenAddApprovalGroup}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i>{t('Add Group')}
          </a>
          
            </div>
          </div>

          {/* //  START TABLE   */}
                       <div className="table-responsive">
                 <table className="table ">
                 <thead className="table-dark">
                     <tr>
                      <th
                        scope="col"
                        onClick={() => handleSort("name")}
                      >
                        {t('Name')}
                      </th>
                      

                      <th
                        scope="col"
                      >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <Loading loading={loading} error={error}> */}
                      {filteredDataByModules.map((item , index )=> (
                      <tr key={item.id}>
                      <td className="align-middle">{i18n.language === 'ar' ? item.name : item.name2 }</td>
                      <td>
                      
                        
                      <div className="btn-group">
                      <button type="button" 
                      // hidden={hiddenEditApprovalGroup} 
                      onClick={() => Navigate(`${item.id}/AddApprovalMember`)} className="btn btn-outline-lime" >
                      <i class="fas fa-lg fa-fw me-2 fa-users"></i>
                        {t('Group members')}
                      </button>
                      
                      <button type="button"
                      //  hidden={hiddenEditApprovalGroup} 
                       onClick={() => Navigate(`${item.id}/EditApprovalGroup`)} className="btn btn-outline-lime">
                      <i class="far fa-lg fa-fw me-2 fa-edit"></i>
                        {t('Button.Edit')}
                      </button></div>
                        
                      </td>
                    </tr>
                    ))}
                    {/* </Loading> */}
                    
                    
                  </tbody>
                </table>
              </div>
          {/* <Pagination
            filteredData={filteredData}
            // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
            dataPerPage={dataPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
          /> */}

          {/* </CardBody> */}

          
          
        </div>
      </div>
    </Card>
    <ModalComponent title={t('Add')} id="modalAddAprovalGroup">
        <AddAprovalGroup/>
     </ModalComponent>




</>
    
    
    
  );
};

export default ApprovalGroup;





