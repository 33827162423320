import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Printpdf.css";

const PrintpdfLandscape = ({ data }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handlePrint = () => {
      let printContents = document.getElementById("printId").innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    };

    const printButton = document.getElementById("printButton");
    printButton.addEventListener("click", handlePrint);

    return () => {
      printButton.removeEventListener("click", handlePrint);
    };
  }, []);

  //_______________________________________________________________
  // console.log((data[0]?.runResultsList));
  // console.log( JSON.stringify(data));

  // var kh = data.map((item) => (
  //   item.runResultsList.filter(
  //     f => f.elementTypeId !== '7835f737-e11c-48b5-9fb9-1eb465a431ff' &&
  //          f.elementTypeId !== '246e875e-7324-44cf-9a03-92679703d3c3' &&
  //          f.elementTypeId !== '246e875e-7324-44cf-9a03-92679703d3c3' && f.isEarning === true
  //   ).map(item => item.resultValue)
  // ));
  //______________________

  var excludedElementTypes = [
    "7835f737-e11c-48b5-9fb9-1eb465a431ff",
    "8f8a5ba6-38ec-4380-aa31-31ae75b09a7d",
    "246e875e-7324-44cf-9a03-92679703d3c3",
    "78b4f8a1-80d5-4247-ab77-fbdc78c7a620",
  ];
  var kh2 = data.map((item) =>
    item.runResultsList?.filter(
        (f) =>
          !excludedElementTypes.includes(f.elementTypeId) &&
          f.isEarning === true
      )
      ?.reduce((sum, item) => sum + item.resultValue, 0)
  );
  // .reduce((product, sum) => product * sum, 1);

  console.log(kh2);

  // console.log(kh);
  console.log(data);

  // console.log(data);

  return (
    <div>
      {/* <button id="printButton" className="ReportPP-print">
        Print
      </button> */}

      
    <button id="printButton" className="print-button"><span class="print-icon"></span></button>


      <div>
        <page id="printId" size1="A4" layout="landscape">
          <main className="ReportPl">
            <div className="ReportPl-wrapper ReportP-header">
              <img
                src="https://gist.githubusercontent.com/mondenoir/bab1c3ffbc9e6a6939d47d702be9a63f/raw/91e20eed0549f41528689256f2364e34edaceec3/logo.svg"
                alt="logo"
                className="ReportP-logo"
              />
              <div className="ReportP-company">
                <h2 className="ReportP-company-name">
                  حلول تخطيط الموارد نيتكس
                </h2>
                <p className="ReportP-company-address">
                  <span>المملكة العربية السعودية </span>
                  <span>الرياض</span>
                  <span>helpdesk@aljown.com</span>
                </p>
              </div>
            </div>

            <div
              className="ReportP-row"
              style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
            >
              <div className="ReportP-col-2 "> </div>
              <div
                className="ReportP-col-8 title-p"
                style={{ backgroundColor: " rgba(0,0,0,0.2" }}
              >
                مسير رواتب الموظفين السعوديين عن شهر أغسطس عام 2022
                لإيداعها في حساباتهم
              </div>
              <div className="ReportP-col-2 "></div>
            </div>

            <div className="ReportP-row">
              <div className="ReportPl-col-12">
                <table className="ReportP-table">
                  <thead className="ReportPl-table">
                    <tr>
                      <th rowSpan={2}>#</th>
                      <th rowSpan={2}>الاسم</th>
                      <th rowSpan={2}>الوظيفة</th>
                      <th colSpan={2}>الأساسي</th>

                      <th colSpan={6}>الاستحقاقات</th>

                      <th colSpan={2}>الخصوم</th>
                      <th> الصافي</th>
                      <th rowSpan={2}>البنك</th>
                      <th rowSpan={2}>الحساب</th>
                    </tr>
                    <tr>
                      <th>الراتب </th>
                      <th> بدل النقل</th>

                      <th>الراتب </th>
                      <th> بدل السكن</th>
                      <th>جمع راتب وسكن </th>
                      <th> بدل النقل</th>
                      <th>اخرى </th>
                      <th> المجموع</th>

                      <th>التأمينات </th>
                      <th>اخرى </th>
                      <th> الاستحقاق</th>
                    </tr>
                  </thead>
                  <tbody className="ReportPl-table">
                    {data &&
                      data?.map((item, idx) => (
                        <tr key={++idx}>
                          <td>{++idx}</td>
                          <td>
                            {i18n.language === "ar" ? item.name2 : item.name}
                          </td>

                          <td>
                            {i18n.language === "ar"? item.jobName2: item.jobName}
                          </td>

                          <td>
                            {item.runResultsList?.filter(
                                (f) =>
                                  f.elementTypeId ===
                                  "7835f737-e11c-48b5-9fb9-1eb465a431ff"
                              )
                              ?.map((item) => item.resultValue)}
                          </td>

                          <td>
                            {item.runResultsList?.filter(
                                (f) =>
                                  f.elementTypeId ===
                                  "8f8a5ba6-38ec-4380-aa31-31ae75b09a7d"
                              )
                              ?.reduce((sum, item) => sum + item.resultValue, 0)}
                          </td>

                          <td>
                            {item.runResultsList?.filter(
                                (f) =>
                                  f.elementTypeId ===
                                  "7835f737-e11c-48b5-9fb9-1eb465a431ff"
                              )
                              ?.map((item) => item.resultValue)}
                          </td>
                          <td>
                            {item.runResultsList
                              ?.filter(
                                (f) =>
                                  f.elementTypeId ===
                                  "246e875e-7324-44cf-9a03-92679703d3c3"
                              )
                              .reduce((sum, item) => sum + item.resultValue, 0)}
                          </td>

                          <td>
                            {item.runResultsList
                              ?.filter(
                                (f) =>
                                  f.elementTypeId ===
                                    "7835f737-e11c-48b5-9fb9-1eb465a431ff" ||
                                  f.elementTypeId ===
                                    "246e875e-7324-44cf-9a03-92679703d3c3"
                              )
                              ?.reduce((sum, item) => sum + item.resultValue, 0)}
                          </td>

                          <td>
                            {item.runResultsList
                              ?.filter(
                                (f) =>
                                  f.elementTypeId ===
                                  "8f8a5ba6-38ec-4380-aa31-31ae75b09a7d"
                              )
                              ?.reduce((sum, item) => sum + item.resultValue, 0)}
                          </td>

                          <td>
                            {item.runResultsList
                              ?.filter(
                                (f) =>
                                  !excludedElementTypes.includes(
                                    f.elementTypeId
                                  ) && f.isEarning
                              )
                              ?.reduce((sum, item) => sum + item.resultValue, 0)}
                          </td>

                          <td>
                            {item.runResultsList
                              ?.filter((f) => f.isEarning)
                              ?.reduce((sum, item) => sum + item.resultValue, 0)}
                          </td>

                          <td>
                            {item.runResultsList
                              ?.filter(
                                (f) =>
                                  f.elementTypeId ===
                                  "78b4f8a1-80d5-4247-ab77-fbdc78c7a620"
                              )
                              ?.reduce((sum, item) => sum + item.resultValue, 0)}
                          </td>

                          <td>
                            {item.runResultsList
                              ?.filter(
                                (f) =>
                                  !excludedElementTypes.includes(
                                    f.elementTypeId
                                  ) && !f.isEarning
                              )
                              ?.reduce((sum, item) => sum + item.resultValue, 0)}
                          </td>

                          <td>
                            {item.runResultsList?.reduce((diff, item) => {
                              if (item.isEarning) {
                                diff += item.resultValue;
                              } else {
                                diff -= item.resultValue;
                              }
                              return diff;
                            }, 0)}
                          </td>

                          <td>{i18n.language === "ar" ? item.bankName2 : item.bankName}</td>
                          <td>{item.iBan}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="ReportP-row"
              style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
            >
              <div className="ReportP-col-2 "> </div>
              <div
                className="ReportP-col-8 title-p"
                style={{ backgroundColor: " rgba(0,0,0,0.2" }}
              >
                فقط ثلاثة و ستون ألفاً و أربعمائة و واحد و تسعون ريالاً سعودياً
                و أربع عشرة هللة لا غير.
              </div>
              <div className="ReportP-col-2 "></div>
            </div>
          </main>
        </page>
      </div>
    </div>
  );
};

export default PrintpdfLandscape;
