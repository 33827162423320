import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Papa from "papaparse";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  GetMyRequest,
  GetRequestList,
} from "../../../store/Ame/ameRequestSlice.js";
import Loader from "../../../components/Loader/Loader.js";
import { Card, CardBody } from "../../../components/card/card.jsx";
import Pagination from "../../../components/Pagination/Pagination.jsx";

const RequestList = () => {
  const { t, i18n } = useTranslation();
  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetRequestList());
  }, [dispatch]);
  const { RequestList } = useSelector((state) => state.AmeRequest);
  console.log(RequestList);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = RequestList.slice(indexOfFirstData, indexOfLastData);

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {/* //  START TABLE   */}
      <div className="table-responsive">
        <table className="table table-striped table-borderless mb-2px small text-nowrap">
          <thead>
            <tr>
              <th scope="col">الطلب</th>
              <th scope="col">مقدم الطلب</th>
              <th scope="col">تاريخ الطلب</th>
              <th scope="col">حالة الطلب</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={item.transectionHistoryId}>
                <td className="align-middle">
                  {i18n.language === "ar"
                    ? item.requestName2
                    : item.requestName}
                </td>
                <td className="align-middle">
                  {i18n.language === "ar" ? item.personName2 : item.personName}
                </td>
                <td className="align-middle">{item.dateCreated}</td>
                <td className="align-middle">
                  {/* {item.status} */}
                  {item.status === "PENDING" ? (
                    <span className="badge d-block rounded-0 pt-5px w-70px bg-success text-theme-900">
                      {item.status}
                    </span>
                  ) : (
                    <span className="badge d-block rounded-0 pt-5px w-70px bg-white bg-opacity-25">
                      not Active
                    </span>
                  )}
                </td>
                <td>
                  <Link
                    className="text-decoration-none text-inverse"
                    onClick={() =>
                      Navigate(
                        `/SelfService/Requests/${item.transectionHistoryId}/ViewRequests`
                      )
                    }
                  >
                    <i className="bi bi-eye-fill fs-5 text-theme"></i>
                  </Link>

                  {/* <button
                    type="button"
                    className="btn btn-outline-theme "
                    data-toggle="tooltip"
                    title=""
                    onClick={() => Navigate(`/SelfService/Requests/${item.transectionHistoryId}/ViewRequests`)}
                  >
                    <i className=""></i>
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        filteredData={RequestList}
        // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
        dataPerPage={dataPerPage}
        handlePagination={handlePagination}
        currentPage={currentPage}
      />
    </>
  );
};

export default RequestList;
