import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  GetRotationPlan,
  GetRotationPlanById,
} from "../../../../store/HR/Raqeeb/raqeebSlice";

const useInitialValues = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Raqeeb.RotationPlanListById);
  const loading = useSelector((state) => state.Raqeeb.loading);
  useEffect(() => {
    dispatch(GetRotationPlanById(id));
  }, [dispatch]);




//   Employee ? Employee.employeeNumber : "",

  const initialValues = {
    raqRotationPlan: {
      // id: ,
      // businessGroupId: ,
      name: data ? data?.raqRotationPlansDto?.name : "",
      name2: data ? data?.raqRotationPlansDto?.name2 : "",
      startDate: data ? data?.raqRotationPlansDto?.startDate : "",
      endDate:  data ? data?.raqRotationPlansDto?.endDate : "",
      description:  data ? data?.raqRotationPlansDto?.description : "",
      allowIn: data ? data?.raqRotationPlansDto?.allowIn : "",
      allowOut: data ? data?.raqRotationPlansDto?.allowOut : "",
      count_Fingerprint:  data ? data?.raqRotationPlansDto?.count_Fingerprint : ""
    },
    sat: {
      startIn: data ? data?.sat?.startIn : "",
      endOut: data ? data?.sat?.endOut : "",
      fingerprintTime1: data ? data?.sat?.fingerprintTime1 : "" ,
      fingerprintTime2:  data ? data?.sat?.fingerprintTime2 : "",
      duration1: data ? data?.sat?.duration1 : "" ,
      fingerprintTime3: data ? data?.sat?.fingerprintTime3 : "" ,
      fingerprintTime4: data ? data?.sat?.fingerprintTime4 : "",
      duration2: data ? data?.sat?.duratioN2 : "",
      allowIn:data ? data?.sat?.allowIn : "" ,
      allowOut: data ? data?.sat?.allowOut : "" ,
      countFingerprint: data ? data?.sat?.countFingerprint : "",
      isExtraDay: data ? data?.sat?.isExtraDay : "",

      isFlexible: data ? data?.sat?.isFlexible :"",
      startFlexible: data ? data?.sat?.startFlexible : "",
      endFlexible: data ? data?.sat?.endFlexible : ""
    },
    sun: {
        startIn: data ? data?.sun?.startIn : "",
        endOut: data ? data?.sun?.endOut : "",
        fingerprintTime1: data ? data?.sun?.fingerprintTime1 : "" ,
        fingerprintTime2:  data ? data?.sun?.fingerprintTime2 : "",
        duration1: data ? data?.sun?.duration1 : "" ,
        fingerprintTime3: data ? data?.sun?.fingerprintTime3 : "" ,
        fingerprintTime4: data ? data?.sun?.fingerprintTime4 : "",
        duration2: data ? data?.sun?.duratioN2 : "",
        allowIn:data ? data?.sun?.allowIn : "" ,
        allowOut: data ? data?.sun?.allowOut : "" ,
        countFingerprint: data ? data?.sun?.countFingerprint : "",
        isExtraDay: data ? data?.sun?.isExtraDay : "",

        isFlexible: data ? data?.sun?.isFlexible :"",
        startFlexible: data ? data?.sun?.startFlexible : "",
        endFlexible: data ? data?.sun?.endFlexible : ""
      },
      mon: {
        startIn: data ? data?.mon?.startIn : "",
        endOut: data ? data?.mon?.endOut : "",
        fingerprintTime1: data ? data?.mon?.fingerprintTime1 : "" ,
        fingerprintTime2:  data ? data?.mon?.fingerprintTime2 : "",
        duration1: data ? data?.mon?.duration1 : "" ,
        fingerprintTime3: data ? data?.mon?.fingerprintTime3 : "" ,
        fingerprintTime4: data ? data?.mon?.fingerprintTime4 : "",
        duration2: data ? data?.mon?.duratioN2 : "",
        allowIn:data ? data?.mon?.allowIn : "" ,
        allowOut: data ? data?.mon?.allowOut : "" ,
        countFingerprint: data ? data?.mon?.countFingerprint : "",
        isExtraDay: data ? data?.mon?.isExtraDay : "",

        isFlexible: data ? data?.mon?.isFlexible :"",
        startFlexible: data ? data?.mon?.startFlexible : "",
        endFlexible: data ? data?.mon?.endFlexible : ""
      },
      tue: {
        startIn: data ? data?.tue?.startIn : "",
        endOut: data ? data?.tue?.endOut : "",
        fingerprintTime1: data ? data?.tue?.fingerprintTime1 : "" ,
        fingerprintTime2:  data ? data?.tue?.fingerprintTime2 : "",
        duration1: data ? data?.tue?.duration1 : "" ,
        fingerprintTime3: data ? data?.tue?.fingerprintTime3 : "" ,
        fingerprintTime4: data ? data?.tue?.fingerprintTime4 : "",
        duration2: data ? data?.tue?.duratioN2 : "",
        allowIn:data ? data?.tue?.allowIn : "" ,
        allowOut: data ? data?.tue?.allowOut : "" ,
        countFingerprint: data ? data?.tue?.countFingerprint : "",
        isExtraDay: data ? data?.tue?.isExtraDay : "",

        isFlexible: data ? data?.tue?.isFlexible :"",
        startFlexible: data ? data?.tue?.startFlexible : "",
        endFlexible: data ? data?.tue?.endFlexible : ""
      },
      wed: {
        startIn: data ? data?.wed?.startIn : "",
        endOut: data ? data?.wed?.endOut : "",
        fingerprintTime1: data ? data?.wed?.fingerprintTime1 : "" ,
        fingerprintTime2:  data ? data?.wed?.fingerprintTime2 : "",
        duration1: data ? data?.wed?.duration1 : "" ,
        fingerprintTime3: data ? data?.wed?.fingerprintTime3 : "" ,
        fingerprintTime4: data ? data?.wed?.fingerprintTime4 : "",
        duration2: data ? data?.wed?.duratioN2 : "",
        allowIn:data ? data?.wed?.allowIn : "" ,
        allowOut: data ? data?.wed?.allowOut : "" ,
        countFingerprint: data ? data?.wed?.countFingerprint : "",
        isExtraDay: data ? data?.wed?.isExtraDay : "",

        isFlexible: data ? data?.wed?.isFlexible :"",
        startFlexible: data ? data?.wed?.startFlexible : "",
        endFlexible: data ? data?.wed?.endFlexible : ""
      },
      thu: {
        startIn: data ? data?.thu?.startIn : "",
        endOut: data ? data?.thu?.endOut : "",
        fingerprintTime1: data ? data?.thu?.fingerprintTime1 : "" ,
        fingerprintTime2:  data ? data?.thu?.fingerprintTime2 : "",
        duration1: data ? data?.thu?.duration1 : "" ,
        fingerprintTime3: data ? data?.thu?.fingerprintTime3 : "" ,
        fingerprintTime4: data ? data?.thu?.fingerprintTime4 : "",
        duration2: data ? data?.thu?.duratioN2 : "",
        allowIn:data ? data?.thu?.allowIn : "" ,
        allowOut: data ? data?.thu?.allowOut : "" ,
        countFingerprint: data ? data?.thu?.countFingerprint : "",
        isExtraDay: data ? data?.thu?.isExtraDay : "",

        isFlexible: data ? data?.thu?.isFlexible :"",
        startFlexible: data ? data?.thu?.startFlexible : "",
        endFlexible: data ? data?.thu?.endFlexible : ""
      },
      fri: {
        startIn: data ? data?.fri?.startIn : "",
        endOut: data ? data?.fri?.endOut : "",
        fingerprintTime1: data ? data?.fri?.fingerprintTime1 : "" ,
        fingerprintTime2:  data ? data?.fri?.fingerprintTime2 : "",
        duration1: data ? data?.fri?.duration1 : "" ,
        fingerprintTime3: data ? data?.fri?.fingerprintTime3 : "" ,
        fingerprintTime4: data ? data?.fri?.fingerprintTime4 : "",
        duration2: data ? data?.fri?.duratioN2 : "",
        allowIn:data ? data?.fri?.allowIn : "" ,
        allowOut: data ? data?.fri?.allowOut : "" ,
        countFingerprint: data ? data?.fri?.countFingerprint : "",
        isExtraDay: data ? data?.fri?.isExtraDay : "",

        isFlexible: data ? data?.fri?.isFlexible :"",
        startFlexible: data ? data?.fri?.startFlexible : "",
        endFlexible: data ? data?.fri?.endFlexible : ""
      },
  };










  return { data , loading , initialValues};
};

export default useInitialValues;
