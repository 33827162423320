import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomAlert from '../../../components/Alert/CustomAlert.tsx';
import { Card, CardBody } from "../../../components/card/card";
import {
  GetApprovalGroupById,
  UpdateAprovalGroup,
} from "../../../store/Ame/approvalGroupSlice";
import { DynamicFormSchema } from "../../ValidationForm/validationSchema";

const EditApprovalGroup = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();



  useEffect(() => {
    dispatch(GetApprovalGroupById(id));
  }, []);
  const { ApprovalGroupListById } = useSelector((state) => state.ApprovalGroup);
  console.log(ApprovalGroupListById);

  const formik = useFormik({
    initialValues: {
      name2: ApprovalGroupListById ? ApprovalGroupListById.name2 : "",
      name: ApprovalGroupListById ? ApprovalGroupListById.name : "",
      description: ApprovalGroupListById
        ? ApprovalGroupListById.description
        : "",
    },
    enableReinitialize: true,
    validationSchema: DynamicFormSchema(t),
    onSubmit: (values, { resetForm }) => {
      //   console.log(values);

      dispatch(
        UpdateAprovalGroup({
          id: ApprovalGroupListById?.id,
          name: values.name,
          name2: values.name2,
          description: values.description,
        })
      )
        .unwrap()
        .then(() => {
          CustomAlert({ action: "Edit" });
          Navigate(-1, { replace: true });
          resetForm();
        })
        .catch((error) => {
          CustomAlert({ action: "Error" });
          console.log(error);
        });
    },
  });

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t('Home')}</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/"> {t('Approval Management')}</a>
            </li>
            <li className="breadcrumb-item active">{t('Button.Edit')}</li>
          </ul>
          <h1 className="page-header mb-0">{t('Approval Management')}</h1>
        </div>
      </div>

      
      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                     {t('Arabic Name')}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2 || ""}
                    isInvalid={!!formik.errors.name2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                     {t('English Name')}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name || ""}
                    isInvalid={!!formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-12">
        <div className="form-group mb-3">
          <label className="form-label" htmlFor="exampleFormControlSelect1">
          {t('Note')} 
          </label>
          <Form.Control
              className="form-control"
              as="textarea" rows={3}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description || ""}
            />

          <div>
          
        </div>
        </div>
      </div>
            </div>
            <div className="text-center">
            <button type="submit" className="btn me-1 btn-outline-theme mb-1">
              <i className="fa fa-save fa-fw me-1"></i> {t('Save')}
            </button>

            <button
              type="button"
              className=" btn me-1 btn-outline-default mb-1"
              onClick={() => Navigate(-1, { replace: true })}
            >
              <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
            </button></div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default EditApprovalGroup;
