

export const Index = "indexAction" ;

export const Create = "createAction";
export const Edit = "editAction";
export const  Delete = "deleteAction";
export const  Details =  "detailsAction";








