import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { getBanks } from "../../store/lookupSlice";
 

const useGetBanks = ()=>{

 
    const dispatch = useDispatch();

    const {Banks , loading , error} = useSelector(state => state.Lookup)

    useEffect(()=>{
      dispatch(getBanks())
    },[])
    
    return { Banks , loading , error } ;
};

export default useGetBanks ;