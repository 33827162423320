import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { GetRotationPlan } from "../../store/HR/Raqeeb/raqeebSlice";
 

const useGetRotationPlan = ()=>{

 
    const dispatch = useDispatch();

    const {RotationPlanList , loading , error} = useSelector(state => state.Raqeeb)

    useEffect(()=>{
      dispatch(GetRotationPlan())

    },[])
    
    return { RotationPlanList , loading , error } ;
};

export default useGetRotationPlan ;