import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
import { PeriodicallyScanning, PeriodicallyScanningById } from "./equipmentInspectionModels";
import { date } from "yup";



export const AddPeriodicallyScanning = createAsyncThunk('EquipmentInspection/AddPeriodicallyScanning',
async (item: any , {rejectWithValue , dispatch} ) => {

    // debugger
 try {
   const {data} = await ntxAPI.post( "Fleet/AddPeriodicallyScanning" , item)  
   return data; 
 } catch (error : any) {
     return rejectWithValue(error.message);
 }
});


export const GetScanningListByFleetEquipmentsId  = createAsyncThunk(
    "EquipmentInspection/GetScanningListByFleetEquipmentsId",
    async (FleetEquipmentsId : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetPeriodicallyScanningByFleetEquipmentsId?FleetEquipmentsId=${FleetEquipmentsId}`);
        return data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


export const  GetPeriodicallyScanningById = createAsyncThunk(
    "EquipmentInspection/GetPeriodicallyScanningById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetPeriodicallyScanningMasterById?Id=${id}`);
        return data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );



export const UpdatePeriodicallyScanning = createAsyncThunk('EquipmentInspection/UpdatePeriodicallyScanning',
async (item: any , {rejectWithValue , dispatch} ) => {

 try {
   const {data} = await ntxAPI.post( "Fleet/UpdatePeriodicallyScanning" , item)  
   return data; 
 } catch (error : any) {
     return rejectWithValue(error.message);
 }
});

const initialState: {
    PeriodicallyScanningById? : PeriodicallyScanningById
    PeriodicallyScanningList : PeriodicallyScanning[]
    loading: boolean;
    error: any | null;
  } = {
    PeriodicallyScanningById: {} as PeriodicallyScanningById,
    PeriodicallyScanningList: [],
    loading: false,
    error: null,
  };



  const equipmentInspectionSlice = createSlice({
    name: "EquipmentInspection",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder


      .addCase(AddPeriodicallyScanning.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(AddPeriodicallyScanning.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        if(action?.payload?.succeeded === true){
            // state.PeriodicallyScanningList.push(action.payload?.data); 
            // state.PeriodicallyScanningList = action.payload?.data;
        }
      })
      .addCase(AddPeriodicallyScanning.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })




      .addCase(GetScanningListByFleetEquipmentsId.pending, (state, action) => {
        state.loading = true;
         state.PeriodicallyScanningList = [];
      })
      .addCase(GetScanningListByFleetEquipmentsId.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        if(action?.payload?.succeeded === true){
            state.PeriodicallyScanningList = action?.payload?.data;
        }
      })
      .addCase(GetScanningListByFleetEquipmentsId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      
      .addCase(GetPeriodicallyScanningById.pending, (state, action) => {
        state.loading = true;
        state.PeriodicallyScanningById = {} as PeriodicallyScanningById
      })
      .addCase(GetPeriodicallyScanningById.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        // debugger
        if(action?.payload?.succeeded === true){
            state.PeriodicallyScanningById = action?.payload?.data;
        }
      })
      .addCase(GetPeriodicallyScanningById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


 


 
      
    },
  });
  
  export default equipmentInspectionSlice.reducer;
