import React, { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination/Pagination";

import { Card, CardBody } from "../../../components/card/card";
import Breadcrumb from "../../../components/Shared/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Link, useNavigate } from "react-router-dom";
import { GetEquipmentsWorkRequestsList } from "../../../store/FLEET/EquipmentsWorkRequests/equipmentsWorkRequestSlice";
import ModalComponent from "../../../components/Modal/ModalComponent";
import AddOrderMaintenance from "./AddOrderMaintenance";
import { GetEquipmentsWorkOrderList } from "../../../store/FLEET/EquipmentsWorkOrder/equipmentsworkorderSlice";
import OrderMaintenanceForm from "./OrderMaintenanceForm";
import { GetFleetRequestType } from "../../../store/lookupSlice";

const Index = () => {
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();

  useEffect(() => {
    dispatch(GetEquipmentsWorkOrderList());
    dispatch(GetFleetRequestType());
  }, []);
  const { EquipmentsWorkOrderList } = useAppSelector(
    (state) => state.EquipmentsWorkOrder
  );

  const { FleetRequestType } = useAppSelector((state) => state.Lookup);

  const filteredSearch = EquipmentsWorkOrderList?.slice()?.filter((item) => {
    const searchLowerCase = search.toLowerCase();
    return (
      item?.fleetEquipmentsId.toLowerCase().includes(searchLowerCase) ||
      item?.orderCode?.toLowerCase().toString().includes(searchLowerCase)
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);
  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "MaintenanceOrders",
      link: null,
    },
  ];

  // console.log( FleetRequestType[0]?.list.find((f )=> f.lookupCode === "Corrective_Maintenance"));

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="MaintenanceOrders" />

      <Card>
        {/* <Loading loading={loading} error={error} SkeletonType="Table"> */}

        <CardBody>
          <div className="tab-content p-4">
            <div className="tab-pane fade show active">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end">
                  <div
                    className="btn-group"
                    role="group"
                    style={{ direction: "ltr" }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-theme dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-plus-circle fa-fw ms-3 fs-5"></i>
                      {t("Add")}
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          className="dropdown-item"
                          to=""
                          data-bs-toggle="modal"
                          data-bs-target="#ModalAddOrderMaintenance"
                          onClick={() => setShow(true)}
                        >
                          <i className="bi bi-plus-square-dotted fs-5 ms-2"></i>
                          اضافة أمر صيانة مباشر
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/FLEET/WorkRequests"
                        >
                          <i className="fa fa-plus-circle fa-fw me-1 ms-2 fs-5"></i>
                          إضافة أمر من طلب صيانة
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">{t("OrderNumber")}</th>
                      <th scope="col">{t("Equipment")}</th>
                      <th scope="col">{t("TypeMaintenance")}</th>
                      <th scope="col">{t("Internal or external")}</th>
                      <th scope="col">
                        {t("Workshop")}\{t("Supplier")}{" "}
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">{item?.orderCode}</td>
                        <td className="align-middle">
                          {i18n.language === "ar"
                            ? item?.fleetEquipmentsName2
                            : item?.fleetEquipmentsName}
                        </td>

                        <td className="align-middle">{item.requestTypeName2}</td>
                        <td className="align-middle">{item.isInternal === true ? ( i18n.language === 'ar'?  "داخلي" : "Internal") : ( i18n.language === 'ar'?  "خارجي" : "External")}</td>
                        <td className="align-middle">{  item.isInternal === true ? ( i18n.language ==='ar' ? item.workshopName2 : item.workshopName) : ( i18n.language === 'ar'? item.supplierName2 : item.supplierName)}</td>

                        <td className="align-middle">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-outline-lime"
                              onClick={() => Navigate(`${item.id}/CloseOrder`)}
                            >
                               
                              <i className="fa-lg fa-fw me-2 bi bi-check2-square"></i>
                              {t("Close_Maintenance_Order")}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={filteredSearch}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: any) =>
                  setCurrentPage(pageNumber)
                }
                currentPage={currentPage}
              />
            </div>
          </div>
        </CardBody>
        {/* </Loading> */}
      </Card>

      <ModalComponent title={t("MaintenanceOrder")} id="ModalAddOrderMaintenance">
        {Show && <OrderMaintenanceForm />}
      </ModalComponent>
    </div>
  );
};

export default Index;
