import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import * as Yup from "yup";
import { Form } from 'react-bootstrap';
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card';
import { GetAmeRuleListByDynamicFormId, insertAmeRule } from '../../../../store/Ame/ameRuleSlice';
import AmeRuleList from './AmeRuleList';

function AddAmeRule() {

    const Navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    //
  
    //
    const { AmeRuleListByDynamicFormId } = useSelector((state) => state.AmeRule)
    useEffect(()=>{
      dispatch(GetAmeRuleListByDynamicFormId(id))
    },[])
    console.log(AmeRuleListByDynamicFormId);
  
  
    const formik = useFormik({
      initialValues: {
        dynamicFormId: "",
        name: "",
        name2: ""
      },
      validationSchema: Yup.object().shape({
        name2: Yup.string()
          .required(() => t("Required")),
        name: Yup.string()
          .required(() => t("Required")),
      }),
      onSubmit: (values) => {
        console.log(values);
  
          dispatch(
            insertAmeRule({
                dynamicFormId: id,
                name: formik.values.name,
                name2: formik.values.name2
            })
          )
            .unwrap()
            .then(() => {
                formik.resetForm();
                CustomAlert({ action: "Add" });
            })
            .catch((error) => {
                formik.setSubmitting(false)
                CustomAlert({ action: "Error" });
            });
      },
    });
  


  return (
    <>
    <div className="d-flex align-items-center mb-3">
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#/">PAGES</a>
          </li>
          <li className="breadcrumb-item active">Ame Rule</li>
        </ul>
        <h1 className="page-header mb-0"> Add Ame Rule </h1>
      </div>
    </div>

    <Card>
      <CardBody>
         

          <Form onSubmit={formik.handleSubmit}>

            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                   {t('Arabic Name')}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2}
                    isInvalid={ formik.touched.name2 && formik.errors.name2}
                  />
                </div>
              </div>
           
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    {t('Name English')}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={ formik.touched.name && formik.errors.name}
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
              <button   
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-outline-theme mb-1"
              >
                <i className="fas fa-plus fa-fw me-1"></i>
                {formik.isSubmitting ? "Adding..." : t('Add')}
              </button>
               <button
        type="button"
        className=" btn me-1 btn-outline-default mb-1"
        onClick={() => Navigate(-1, { replace: true })}
      >
        <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
      </button>
</div>
          </Form>


        
      <hr className="mb-4" />

      <div className="row">
        <div className="col-lg-12">
          <AmeRuleList data={AmeRuleListByDynamicFormId} />
        </div>
      </div>
      </CardBody>
     
    </Card>

   
  </>
  )
}

export default AddAmeRule