import { useFormik } from "formik";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import {
  AddDynamicForm,
} from "../../../../store/NTX/FlexValue/Form/formSlice";
// import {
//   AddDynamicForm,
// } from "../../../../../../store/FlexValue/Form/formSlice";
import {
  DynamicFormSchema,
} from "../../../ValidationForm/validationSchema";
import { useTranslation } from "react-i18next";

import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import { DataIcons } from "../../../../assets/icons";




const AddExtraInformationDynamicType = ({moduleId , TableId}) => {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(GetTablesForm());
  // }, []);
  // const { TablesFormList } = useSelector((state) => state.FlexValueSet);




  const formik = useFormik({
    initialValues: {
      isStatic: false,
      name2: "",
      name: "",
      icon: null,
      moduleId: moduleId , 
      dynamicFormTypesId : 4, // 4  Dynamic Form Type 4
      tableId : TableId
    },
    validationSchema: DynamicFormSchema(t),
    onSubmit: (values) => {
      console.log(values);

      dispatch(
        AddDynamicForm({
          name2: values.name2,
          name: values.name,
          moduleId: values.moduleId,
          icon: values.icon,
          isStatic: values.isStatic,
          dynamicFormTypesId : values.dynamicFormTypesId,
          tableId : values.tableId
        })
      )
        .unwrap()
        .then(() => {
          document.getElementById("closeModalForm").click();
          formik.resetForm();
          CustomAlert({ action: "Add" });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });




  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("Arabic Name")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={formik.touched.name2 && formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t("English Name")}</label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={formik.touched.name && formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name2}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row">

          <div className="col-xl-3"></div>

          <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label">{t('Icon')}</label>

            <Select
              classNamePrefix="react-select"
              // classNamePrefix="select"
              isSearchable={true}
              required
              options={DataIcons}
              onChange={(option) => formik.setFieldValue("icon", option.value)}
              placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
            />
          </div>
        </div>

        <div className="col-xl-3"></div>

      </div>

<hr/>
      <div className="text-center">
        <button
          // disabled={formik.isSubmitting}
          type="submit"
          className="btn me-1 btn-theme mb-1"
          id="SaveSubmit"
        >
          <i className="fa fa-save fa-fw me-1"></i> {t("Save")}
        </button>
        <button
          type="button"
          className=" btn me-1 btn-default mb-1"
          data-bs-dismiss="modal"
          id="closeModalForm"
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
        <button
          type="button"
          onClick={formik.resetForm}
          className="btn me-1 btn btn-danger mb-1"
        >
          <i className="fa fa-trash-alt fa-fw me-1"></i>
          {t("Clear")}
        </button>
      </div>
    </Form>
  );
};

export default AddExtraInformationDynamicType
