import React, { useState } from "react";
import { Card, CardBody } from "../../../../../components/card/card.jsx";
import { useTranslation } from "react-i18next";

import DataEmployees from "./DataEmployees.js";
import useGetPersons from "../../../../../hooks/HR/use-get-persons.js";
import ModalComponent from "../../../../../components/Modal/ModalComponent.jsx";
import Pagination from "../../../../../components/Pagination/Pagination.jsx";
import AddPerson from "../AddPerson/AddPerson.js";
import Loader from "../../../../../components/Loader/Loader.js";
import Loading from "../../../../../components/Loading.tsx";
import Breadcrumb from "../../../../../components/Shared/Breadcrumb";

function Index() {
  const [Show, setShow] = useState(false);

  const { t, i18n } = useTranslation();

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [EmpPerPage, setEmpPrPage] = useState(6);


  const { Employees, loading, error } = useGetPersons();
  
 

  const filteredEmp = Employees?.slice()?.filter((emp) => {
    const searchLowerCase = search.toLowerCase();
    return (
      emp.name2?.toLowerCase().includes(searchLowerCase) ||
      emp.name?.toLowerCase().toString().includes(searchLowerCase) ||
      emp.nationalIdentifier?.toString().includes(searchLowerCase) ||
      emp.employeeNumber?.toString().includes(searchLowerCase)
    );
  });
  
  // Get current page
  const IndexOfLastEmp = currentPage * EmpPerPage;
  const IndexOfFirstPost = IndexOfLastEmp - EmpPerPage;
  const currentEmp = filteredEmp?.slice(IndexOfFirstPost, IndexOfLastEmp);

  // paginage way with search
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  var BreadcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Employees",
      link: null,
    },
  ];


  return (
    <>
      <div className="row">

        <div className="d-flex align-items-center">
          <Breadcrumb BreadcrumbList={BreadcrumbList} PageName="Employees" />
          <div className="ms-auto">
            <a
              href="#/"
              className="btn btn-theme"
              data-bs-toggle="modal"
              data-bs-target="#modalXl"
              onClick={() => setShow(true)}
            >
              <i className="fa fa-plus-circle fa-fw me-1"></i>
              {t("Add New Employee")}
            </a>
          </div>
        </div>


        <Card>
          <div className="row">
            <div className="col-lg-12">
              <Loader loading={loading}/>
              <Loading loading={loading} error={error} SkeletonType="Grid">
                <CardBody>
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          placeholder={t("Search")}
                          value={search}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>

                  <DataEmployees
                    Employees={currentEmp}
                    loading={loading}
                    error={error}
                  />
                  {/* <Pagination EmpPerPage={EmpPerPage} totalPost={filteredEmp.length} paginate={paginate} currentPage={currentPage} />  */}
                  <Pagination
                    dataPerPage={EmpPerPage}
                    currentPage={currentPage}
                    handlePagination={paginate}
                    filteredData={filteredEmp}
                  />
                </CardBody>
              </Loading>
            </div>
          </div>
        </Card>

        <ModalComponent title={t("Add New Employee")} id="modalXl">
          {Show && <AddPerson />}
        </ModalComponent>
      </div>
    </>
  );
}
export default Index;
