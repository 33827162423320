import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChartShapesList, ListReportChartById, ReportChart, ReportChartByReportIdList, ReportChartList, ReportColumns } from "./reportShapeModels";
import { ntxAPI } from "../../../../API/axios-custom";
// import { getData, postData } from "../../../Axios/axiosData";



export const GetReportColumnsList = createAsyncThunk(
    "Reports/GetReportById",
    async (id: string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await getData(`Reports/GetNtxReportsById?Id=${id}`);
        const {data} = await ntxAPI.get( `Reports/GetNtxReportsById?Id=${id}`);
        return data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

  export const GetChartShapesList = createAsyncThunk(
    "ReportShape/GetChartShapesList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
  
      try {
        // const data = await getData("Reports/GetChartShapesList");
        const {data} = await ntxAPI.get("Reports/GetChartShapesList" );
        return data;
      } catch (error : any) {
        return rejectWithValue(error.message);
      }
    }
  )

  //___________________________________________________


  
export const AddReportChart = createAsyncThunk('ReportShape/AddReportChart',
   async (item:ReportChart , {rejectWithValue} ) => {

    try {
      // const response = await postData("Reports/AddReportChart" , item)
      const {data} = await ntxAPI.post( "Reports/AddReportChart" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });

  export const UpdatNtxReportChart = createAsyncThunk('ReportShape/AddReportChart',
   async (item:ReportChart , {rejectWithValue} ) => {
    console.log(JSON.stringify(item) );
    
    try {
      // const response = await postData("Reports/UpdatNtxReportChart" , item)
      const {data} = await ntxAPI.post( "Reports/UpdatNtxReportChart", item)
      return data.data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });



  export const GetReportChartList = createAsyncThunk(
    "ReportShape/GetReportChartList",
    async ( _ , thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
  
      try {
        // const data = await getData("Reports/GetReportChartList");
        const {data} = await ntxAPI.get("Reports/GetReportChartList" );
        return data.data;
      } catch (error : any) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
  )


  export const GetReportChartById = createAsyncThunk(
    "ReportShape/GetReportChartById",
    async ( id:string , thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
  
      try {
        // const data = await getData(`Reports/GetReportChartById?Id=${id}`);
        const {data} = await ntxAPI.get( `Reports/GetReportChartById?Id=${id}`);
        return data.data;
      } catch (error : any) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
  )


  export const GetReportChartByReportId = createAsyncThunk(
    "ReportShape/GetReportChartByReportId",
    async ( id:string , thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      
      try {
        // const data = await getData(`Reports/GetReportChartByReportIdList?ReportId=${id}`);
        const {data} = await ntxAPI.get(`Reports/GetReportChartByReportIdList?ReportId=${id}` );
        return data;
      } catch (error : any) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
  )





const initialState: {
    // ReportColumnsList: ReportColumnsList[];
    ReportColumns: ReportColumns | null;
    ChartShapesList: ChartShapesList[]; // اشكال التقرير المضافين Seeding 
    ReportChartList: ReportChartList[];
    ListReportChartById?: ListReportChartById | null
    ReportChartByReportIdList : ReportChartByReportIdList[]
    loading: boolean;
    loadingUpdate: boolean;
    error: any | null;
  } = {
    ReportChartList: [],
    ChartShapesList:[],
    // ListReportChartById:[],
    ReportChartByReportIdList:[],
    loading: false,
    loadingUpdate: false,
    error: null,
    // ReportColumnsList :[],
    ReportColumns : null
  };
  

const ReportShapeSlice = createSlice({
    name: "ReportShape",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(AddReportChart.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors when creating a post
      })
      .addCase(AddReportChart.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.ReportChartList.push(action.payload); 
      })
      .addCase(AddReportChart.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      .addCase(GetReportColumnsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetReportColumnsList.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.ReportColumns = action.payload.data;
      })
      .addCase(GetReportColumnsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })




      .addCase(GetChartShapesList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetChartShapesList.fulfilled, (state, action:PayloadAction<any> ) => {
        state.loading = false;
        state.ChartShapesList = action.payload.data;
      })
      .addCase(GetChartShapesList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


      .addCase(GetReportChartByReportId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetReportChartByReportId.fulfilled, (state, action:PayloadAction<any> ) => {
        state.loading = false;
        state.ReportChartByReportIdList = action.payload.data;
      })
      .addCase(GetReportChartByReportId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })




      .addCase(GetReportChartList.pending, (state, action) => {
        state.loading = true;

      })
      .addCase(GetReportChartList.fulfilled, (state, action: PayloadAction<ReportChartList[]>) => {
        state.loading = false;
        state.ReportChartList = action.payload;
      })
      .addCase(GetReportChartList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



      .addCase(GetReportChartById.pending, (state, action) => {
        state.loadingUpdate = true;
        if (state.ListReportChartById) {
          state.ListReportChartById = null   
        }
      })
      .addCase(GetReportChartById.fulfilled, (state, action: PayloadAction<ListReportChartById>) => {
        state.loadingUpdate = false;
        state.ListReportChartById = action.payload;
      })
      .addCase(GetReportChartById.rejected, (state, action: PayloadAction<any>) => {
        state.loadingUpdate = false;
        state.error = action.payload;
      })

      

      
    },
  });
  
  export default ReportShapeSlice.reducer;