import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axios-custom";



// GetAmeRuleList DynamicFormId
export const GetAmeRuleListByDynamicFormId = createAsyncThunk("AmeRule/GetAmeRuleListByDynamicFormId", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`AmeSetting/GetAmeRuleListByDynamicFormId?DynamicFormId=${id}`);
      const {data} = await ntxAPI.get(`AmeSetting/GetAmeRuleListByDynamicFormId?DynamicFormId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const insertAmeRule = createAsyncThunk("AmeRule/insertAmeRule", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("AmeSetting/AddAmeRule", item);
      const {data} = await ntxAPI.post("AmeSetting/AddAmeRule" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const DeleteAmeRule = createAsyncThunk("AmeRule/DeleteAmeRule", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

 
    try {
      // const data = await postData(`AmeSetting/DeleteAmeRule?Id=${id}`);
      const {data} = await ntxAPI.post(`AmeSetting/DeleteAmeRule?Id=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });









  const initialState = { AmeRuleListByDynamicFormId: [], loading: false, error: null };

  const AmeRuleSlice = createSlice({
    name: "AmeRule",
    initialState,
    reducers: {
    },
    extraReducers: {
      /////// get ame condition list                
      [GetAmeRuleListByDynamicFormId.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetAmeRuleListByDynamicFormId.fulfilled]: (state, action) => {
        state.loading = false;
        state.AmeRuleListByDynamicFormId = action.payload;
      },
      [GetAmeRuleListByDynamicFormId.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

       //// AddAmeRule
      [insertAmeRule.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [insertAmeRule.fulfilled]: (state, action) => {
        state.loading = false;
        state.AmeRuleListByDynamicFormId.push(action.payload);
      },
      [insertAmeRule.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

      /// Delete 
      [DeleteAmeRule.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [DeleteAmeRule.fulfilled]: (state, action) => {
        state.loading = false;
        state.AmeRuleListByDynamicFormId = state.AmeRuleListByDynamicFormId?.filter((el) => el.id !== action.payload)
      },
      [DeleteAmeRule.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
   
    },
  });
  
  export default AmeRuleSlice.reducer;