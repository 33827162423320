import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";

// import { getData, postData } from "../../Axios/axiosData";

export const GetAssignmentByPersonId = createAsyncThunk(
  "Assignments/GetAssignmentByPersonId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Persons/GetAssignmentByPersonId?PersonId=${id}`);
      const {data} = await ntxAPI.get( `Persons/GetAssignmentByPersonId?PersonId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const AddAssignment = createAsyncThunk(
  "Assignments/AddAssignment",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Persons/AddUpdateAssignment" , item);
      const {data} = await ntxAPI.post("Persons/AddUpdateAssignment" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetAssignmentById = createAsyncThunk(
  "Assignments/GetAssignmentById",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Persons/GetAssignmentById?Id=${id}`);
      const {data} = await ntxAPI.get(`Persons/GetAssignmentById?Id=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  AssignmentPerson: [],
  AssignmentListById: [],
  loading: false,
  isLoading: false,
  error: null,
};

const AssignmentSlice = createSlice({
  name: "Assignments",
  initialState,
  reducers: {
    cleanAssignmentListById: (state) => {
      state.AssignmentListById = [];
    },
  },
  extraReducers: {
    /////// get   AssignmentPerson
    [GetAssignmentByPersonId.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [GetAssignmentByPersonId.fulfilled]: (state, action) => {
      state.isLoading = false;
      // debugger
        state.AssignmentPerson = action.payload;
      
    },
    [GetAssignmentByPersonId.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    /////// get Assignment by id
    [GetAssignmentById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetAssignmentById.fulfilled]: (state, action) => {
      state.loading = false;
      state.AssignmentListById = action.payload;
    },
    [GetAssignmentById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { cleanAssignmentListById } = AssignmentSlice.actions;
export default AssignmentSlice.reducer;
