import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../components/card/card";
import {
  ActinRunPyroll,
  GetPeriodTimeByPayrollId,
} from "../../../../store/HR/Payroll/runPayrollSlice";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import ConfirmEditDialog from "../../../../components/Alert/ConfirmEditDialog";
import * as Yup from "yup";
import Loader from "../../../../components/Loader/Loader";

const AddPayrollRun = () => {
  const { payrollId } = useParams();
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  //________________________________________________________

  useEffect(() => {
    dispatch(GetPeriodTimeByPayrollId(payrollId));
  }, [dispatch, payrollId]);
  const { PeriodTimeList, loading } = useSelector((state) => state.runPayroll);
  console.log(PeriodTimeList);

  const formik = useFormik({
    initialValues: {
      timePeriodId: "",
    },
    validationSchema: Yup.object().shape({
      timePeriodId: Yup.string().required(() => t("Required")),
    }),

    onSubmit: (values) => {
      console.log(values);

      dispatch(
        ActinRunPyroll({
          payrollId: payrollId,
          assignmentId: null,
          actionType: "Run",
          timePeriodId: values.timePeriodId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.status === true) {
            CustomAlert({ action: "Add", msg: res.msg });
            Navigate(-1, { replace: true });
            formik.resetForm();
          } else if (res.status === false) {
            CustomAlert({ action: "Error", msg: res.msg });
            formik.setSubmitting(false);
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item">
              <a onClick={() => Navigate(-1, { replace: true })} href="#/">
                {t("Run Payrolls")}
              </a>
            </li>

            <li className="breadcrumb-item active">
              {t("Create Run Payrolls")}{" "}
            </li>
          </ul>
          <h1 className="page-header mb-0">{t("Create Run Payrolls")}</h1>
        </div>
      </div>

      <Card>
        <CardBody>
          <Loader loading={loading} />
          <Loader loading={formik.isSubmitting} />
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-3"></div>
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Period - Month")} </label>
                  <Form.Select
                    className="form-select form-select-lg"
                    name="timePeriodId"
                    onChange={formik.handleChange}
                    value={formik.values.timePeriodId}
                    isInvalid={
                      formik.touched.timePeriodId && formik.errors.timePeriodId
                    }
                  >
                    <option value={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {PeriodTimeList.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {item.periodName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.timePeriodId}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3"></div>
            </div>
          </Form>

          <div className="text-center">
            <ConfirmEditDialog
              btnText={t("Create Run")}
              onConfirm={formik.handleSubmit}
              title="هل أنت متــأكد ؟ "
              message="لا يمكن التراجع عن هذا الإجــراء."
              IconButton="fa fa-plus-circle fa-fw me-1"
            />

            <button
              type="button"
              className=" btn me-1 btn-default mb-1"
              onClick={() => Navigate(-1, { replace: true })}
            >
              <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AddPayrollRun;
