/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import { useTranslation } from "react-i18next";
import {
  AddAssignment,
} from "../../../../store/HR/Person/assignmentSlice.js";
import { AssignmentFormSchema } from "../../../ValidationForm/validationSchema.js";
import ConfirmEditDialog from "../../../../components/Alert/ConfirmEditDialog.js";
import { CardFooter } from "../../../../components/card/card.jsx";

function AssignmentForm({ AssignmentListById, AssignmentPerson }) {
  
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  // isCurrent  for  hide and show button
  const isCurrent = AssignmentPerson.allAssignment?.find(
    (item) => item.id === AssignmentListById.id
  )?.isCurrent;

  //______________________________________________________________________________________________________
  const formik = useFormik({
    initialValues: {
      id: "",
      startDate: AssignmentListById ? AssignmentListById?.startDate : "",
      // endDate: "",
      gradeId: AssignmentListById ? AssignmentListById.gradeId : null,
      positionId: AssignmentListById ? AssignmentListById.positionId : "",
      jobId: AssignmentListById ? AssignmentListById.jobId : "",
      assignmentStatusTypeId: AssignmentListById
        ? AssignmentListById.assignmentStatusTypeId
        : "",
      payrollId: AssignmentListById ? AssignmentListById.payrollId : "",
      locationId: AssignmentListById ? AssignmentListById.locationId : "",
      supervisorId: AssignmentListById ? AssignmentListById.supervisorId : "",
      // personId: "",
      organizationId: AssignmentListById
        ? AssignmentListById.organizationId
        : "",
      personGroupId: AssignmentListById ? AssignmentListById.personGroupId : "",
      // managerFlag: false,
      // normalHours: 2,
      // rotationPlanId: null,
      // isNewAssignment: true
    },
    enableReinitialize: true,
    validationSchema: AssignmentFormSchema(t),
    onSubmit: (values, { resetForm }) => {
      // console.log( );

      if (values.startDate <= AssignmentListById?.startDate) {
        CustomAlert({
          action: "info",
          msg: "لا يمكن تعيين الموظف في نفس تاريخ التعيين السابق او قبله ",
        });
        return;
      }

      dispatch(
        AddAssignment({
          id: null,
          startDate: values.startDate,
          endDate: null, // when add assingment always value = null  every case
          gradeId: values.gradeId,
          positionId: values.positionId,
          jobId: values.jobId,
          assignmentStatusTypeId: values.assignmentStatusTypeId,
          payrollId: values.payrollId,
          locationId: values.locationId,
          supervisorId: values.supervisorId,
          personId: id,
          organizationId: values.organizationId,
          personGroupId: values.personGroupId,
          managerFlag: values.managerFlag,
          normalHours: values.normalHours,
          rotationPlanId: null,
          isNewAssignment: true, // عندما يكون تعيين جديد تكون القيمة true
        })
      )
        .unwrap()
        .then(() => {
          // SuccessAlert({title : "تمت عملية التعيين بنجاح"})
          CustomAlert({ action: "Add", msg: "تمت عملية تعيين الموظف بنجاح" });
          resetForm();
          Navigate(-1, { replace: true });
        })
        .catch((error) => {
          // console.log(error);
          CustomAlert({ action: "Error" });
          formik.setSubmitting(false);
        });
    },
  });

  /// handle Update Assignment
  const handleUpdateAssignment = () => {
    console.log("dsdad");
    // e.preventDefault();
    dispatch(
      AddAssignment({
        id: AssignmentPerson.id,
        startDate: formik.values.startDate,
        endDate: null, // when add assingment always value = null  every case
        gradeId: formik.values.gradeId,
        positionId: formik.values.positionId,
        jobId: formik.values.jobId,
        assignmentStatusTypeId: formik.values.assignmentStatusTypeId,
        payrollId: formik.values.payrollId,
        locationId: formik.values.locationId,
        supervisorId: formik.values.supervisorId,
        personId: id,
        organizationId: formik.values.organizationId,
        personGroupId: formik.values.personGroupId,
        managerFlag: formik.values.managerFlag,
        normalHours: formik.values.normalHours,
        rotationPlanId: null,
        isNewAssignment: false, // عندما يكون تعيين جديد تكون القيمة true
      })
    )
      .unwrap()
      .then(() => {
        CustomAlert({ action: "Edit" });
        formik.resetForm();
        // Navigate(-1, { replace: true });
      })
      .catch((error) => {
        // console.log(error);
        CustomAlert({ action: "Error" });
        formik.setSubmitting(false);
      });
  };
  // ______________________________________________________________________________________________________
  // filter the position byJobID
  const [positions, setPositions] = useState([]);
  const handleJobChange = (event) => {
    const PositionByJobId = event.target.value;
    const filteredPositions = AssignmentPerson?.position?.list?.filter(
      (position) => position.jop === PositionByJobId
    );
    setPositions(filteredPositions);
  };
  //   Call handleJobChange once when the component appears to populate the Positions dropdown list
  useEffect(() => {
    handleJobChange({
      target: { value: formik.values.jobId },
    });
  }, [formik.values.jobId]);

  //____________________________________________________________________________________________________

  const defaultLocation = AssignmentPerson?.locations?.list?.find(
    (item) => item.id === formik.values.locationId
  );
  const defaultsupervisor = AssignmentPerson?.superVisor?.list?.find(
    (item) => item.id === formik.values.supervisorId
  );
  const defaultorganizationid = AssignmentPerson?.organization?.list?.find(
    (item) => item.id === formik.values.organizationId
  );
  const defaultgroup = AssignmentPerson?.group?.list?.find(
    (item) => item.id === formik.values.personGroupId
  );
  const defaultgradeId = AssignmentPerson?.grade?.list?.find(
    (item) => item.id === formik.values.gradeId
  );
  const defaultjobId = AssignmentPerson?.jobs?.list?.find(
    (item) => item.id === formik.values.jobId
  );
  const defaultpayrollId = AssignmentPerson?.payroll?.list?.find(
    (item) => item.id === formik.values.payrollId
  );
  const defaultassignmentStatusTypeId = AssignmentPerson?.status?.list?.find(
    (item) => item.id === formik.values.assignmentStatusTypeId
  );
  const defaultpositionId =
    positions && positions?.find((item) => item.id === formik.values.positionId);

  return (
    <>
      <div style={{ textAlign: "left" }}>
        {(isCurrent || isCurrent === undefined) && (
          <ConfirmEditDialog
            btnText={t('New Assignments')}
            onConfirm={formik.handleSubmit}
            title="هل أنت متــأكد ؟ "
            message="لا يمكن التراجع عن هذا الإجــراء."
            IconButton="fa fa-plus-circle fa-fw me-1"
          />
        )}
      </div>

      <Form>
        <div className="row">
          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label">{t("Department")}</label>
              <Select
                classNamePrefix="react-select"
                isSearchable={true}
                isClearable
                name="organizationId"
                required
                options={AssignmentPerson?.organization?.list.map((item) => ({
                  value: item.id,
                  label: i18n.language === "ar" ? item.name2 : item.name,
                }))}
                onChange={(option) =>
                  formik.setFieldValue("organizationId", option.value)
                }
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                value={ {
                        value: defaultorganizationid?.id,
                        label:
                          i18n.language === "ar"
                            ? defaultorganizationid?.name2
                            : defaultorganizationid?.name,
                      }

                }
                
              />
              {formik.errors.organizationId &&
                formik.touched.organizationId && (
                  <div style={{ color: "red" }}>
                    {formik.errors.organizationId}
                  </div>
                )}
            </div>
          </div>
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label" >
                {t("group")}
              </label>

              <Select
                classNamePrefix="react-select"
                isSearchable={true}
                name="personGroupId"
                required
                options={AssignmentPerson?.group?.list.map((item) => ({
                  value: item.id,
                  label: i18n.language === "ar" ? item.name2 : item.name,
                }))}
                onChange={(option) =>
                  formik.setFieldValue("personGroupId", option.value)
                }
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                value={ {
                        value: defaultgroup?.id,
                        label:
                          i18n.language === "ar"
                            ? defaultgroup?.name2
                            : defaultgroup?.name,
                      }
                
                }
              />
              {formik.errors.personGroupId && formik.touched.personGroupId && (
                <div style={{ color: "red" }}>
                  {formik.errors.personGroupId}
                </div>
              )}
            </div>
          </div>
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label" >
                {t("Grade")}
              </label>

              <Select
                classNamePrefix="react-select"
                isSearchable={true}
                name="gradeId"
                required
                options={AssignmentPerson?.grade?.list.map((item) => ({
                  value: item.id,
                  label: i18n.language === "ar" ? item.name2 : item.name,
                }))}
                onChange={(option) =>
                  formik.setFieldValue("gradeId", option.value)
                }
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                value={
 {
                        value: defaultgradeId?.id,
                        label:
                          i18n.language === "ar"
                            ? defaultgradeId?.name2
                            : defaultgradeId?.name,
                      }
                   
                }
              />
              {formik.errors.gradeId && formik.touched.gradeId && (
                <div style={{ color: "red" }}>{formik.errors.gradeId}</div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-7">
            <div className="form-group mb-3">
              <label className="form-label" >
                {t("Job")}
              </label>
              <Select
                classNamePrefix="react-select"
                isSearchable={true}
                name="jobId"
                required
                options={AssignmentPerson?.jobs?.list.map((item) => ({
                  value: item.id,
                  label: i18n.language === "ar" ? item.name2 : item.name,
                }))}
                onChange={(option) =>
                  formik.setFieldValue("jobId", option.value)
                }
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                value={
 {
                        value: defaultjobId?.id,
                        label:
                          i18n.language === "ar"
                            ? defaultjobId?.name2
                            : defaultjobId?.name,
                      }
                  
                }
              />
              {formik.errors.jobId && formik.touched.jobId && (
                <div style={{ color: "red" }}>{formik.errors.jobId}</div>
              )}
            </div>
          </div>

          <div className="col-xl-5">
            <div className="form-group mb-3">
              <label className="form-label" >
                {t("Position")}
              </label>

              <Form.Select
                className="form-select"
                id="exampleFormControlSelect1"
                name="positionId"
                onChange={formik.handleChange}
                value={formik.values.positionId}
                // isInvalid={
                //   formik.touched.positionId && formik.errors.positionId
                // }
              >
                <option key={null}>
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {positions &&
                  positions.map((position) => (
                    <option key={position.id} value={position.id}>
                      {position.name}
                    </option>
                  ))}
              </Form.Select>
              {formik.errors.positionId && formik.touched.positionId && (
                <div style={{ color: "red" }}>{formik.errors.positionId}</div>
              )}

             
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("location")}</label>
              <Select
                classNamePrefix="react-select"
                isSearchable={true}
                name="locationId"
                options={AssignmentPerson?.locations?.list.map((item) => ({
                  value: item.id,
                  label: i18n.language === "ar" ? item.name2 : item.name,
                }))}
                onChange={(option) =>
                  formik.setFieldValue("locationId", option.value)
                }
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                value={
 {
                        value: defaultLocation?.id,
                        label:
                          i18n.language === "ar"
                            ? defaultLocation?.name2
                            : defaultLocation?.name,
                      }
                  
                }
              />
              {formik.errors.locationId && formik.touched.locationId && (
                <div style={{ color: "red" }}>{formik.errors.locationId}</div>
              )}
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label" >
                {t("Super Visor")}
              </label>
              <Select
                classNamePrefix="react-select"
                isSearchable={true}
                name="supervisorId"
                options={AssignmentPerson?.superVisor?.list.map((item) => ({
                  value: item.id,
                  label: i18n.language === "ar" ? item.name2 : item.name,
                }))}
                onChange={(option) =>
                  formik.setFieldValue("supervisorId", option.value)
                }
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                value={
 {
                        value: defaultsupervisor?.id,
                        label:
                          i18n.language === "ar"
                            ? defaultsupervisor?.name2
                            : defaultsupervisor?.name,
                      }
                  
                }
              />
              {formik.errors.supervisorId && formik.touched.supervisorId && (
                <div style={{ color: "red" }}>{formik.errors.supervisorId}</div>
              )}
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label" >
                {t("Payroll")}
              </label>

              <Select
                classNamePrefix="react-select"
                isSearchable={true}
                name="payrollId"
                required
                options={AssignmentPerson?.payroll?.list.map((item) => ({
                  value: item.id,
                  label: i18n.language === "ar" ? item.name2 : item.name,
                }))}
                onChange={(option) =>
                  formik.setFieldValue("payrollId", option.value)
                }
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                value={
 {
                        value: defaultpayrollId?.id,
                        label:
                          i18n.language === "ar"
                            ? defaultpayrollId?.name2
                            : defaultpayrollId?.name,
                      }
                   
                }
              />
              {formik.errors.payrollId && formik.touched.payrollId && (
                <div style={{ color: "red" }}>{formik.errors.payrollId}</div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6">
            <div className="form-group mb-3">
              <label className="form-label" >
                {t("Status")}
              </label>
              <Select
                classNamePrefix="react-select"
                isSearchable={true}
                name="assignmentStatusTypeId"
                required
                options={AssignmentPerson?.status?.list.map((item) => ({
                  value: item.id,
                  label: i18n.language === "ar" ? item.name2 : item.name,
                }))}
                onChange={(option) =>
                  formik.setFieldValue("assignmentStatusTypeId", option.value)
                }
                placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                value={
        {
                        value: defaultassignmentStatusTypeId?.id,
                        label:
                          i18n.language === "ar"
                            ? defaultassignmentStatusTypeId?.name2
                            : defaultassignmentStatusTypeId?.name,
                      }
                   
                }
                
              />
              {formik.errors.assignmentStatusTypeId &&
                formik.touched.assignmentStatusTypeId && (
                  <div style={{ color: "red" }}>
                    {formik.errors.assignmentStatusTypeId}
                  </div>
                )}
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label" >
                {t("Start Date")}
              </label>
              <Form.Control
                type="date"
                className="form-c ontrol"
                name="startDate"
                onChange={formik.handleChange}
                value={formik.values.startDate}
                // isInvalid={!!formik.errors.startDate}
              />

              {formik.errors.startDate && formik.touched.startDate && (
                <div style={{ color: "red" }}>{formik.errors.startDate}</div>
              )}

              {/* <Form.Control.Feedback type="invalid">
                {formik.errors.startDate && formik.touched.startDate}
              </Form.Control.Feedback> */}
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label" >
                {t("End_Date")}
              </label>
              <Form.Control
                type="date"
                className="form-control"
                name="endDate"
                onChange={formik.handleChange}
                value={formik.values.endDate}
                isInvalid={formik.errors.endDate && formik.touched.endDate}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.endDate}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>
      </Form>

      <CardFooter>
        <div className="text-center">
          {(isCurrent || isCurrent === undefined) && (
            <>
              {AssignmentPerson.allAssignment?.length > 0 && (
                <>                   
                  <ConfirmEditDialog
                    btnText={t("Button.Edit_Aassignment")}
                    onConfirm={(e) => handleUpdateAssignment(e)}
                    title="هل أنت متــأكد ؟ "
                    message="لا يمكن التراجع عن هذا الإجــراء."
                    IconButton="fas fa-lg fa-fw me-2 fa-pen-square"
                  />
                </>
              )}
            </>
          )}

          <button
            onClick={() => Navigate("/HR/Employees", { replace: true })}
            type="button"
            className=" btn me-1 btn-default "
          >
            <i className="fa fa-power-off fa-fw me-1"></i>
            {t("Cancel")}
          </button>
        </div>
      </CardFooter>
    </>
  );
}

export default AssignmentForm;
