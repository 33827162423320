import React, { useEffect } from 'react'
import { GetRealEstateCountDP } from '../../../store/SU/dashboardSUSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const SUDashBoardData = () => {

  const dispatch = useAppDispatch();

  useEffect(()=>{
    dispatch(GetRealEstateCountDP());
  },[])

  const { DashBoardList } = useAppSelector(state => state.DashboardSU)

  console.log(DashBoardList);
  

  const Data = [
    {
      "title": "إجمالي عدد الوحدات العقارية",
      "total": DashBoardList?.realEstateCount,
      "info": [
        {
          "icon": "fa fa-chevron-up fa-fw me-1",
          "text": "33.3% NVC Head Office"
        },
        {
          "icon": "far fa-user fa-fw me-1",
          "text": "45.5% NVR King Abdul Azziz"
        },
        {
          "icon": "far fa-times-circle fa-fw me-1",
          "text": "3.25% AMR Olaya"
        }
      ],
      "chartData": [
        {
          "name": "Visitors",
          "data": [
            69, 34, 70, 73, 41, 32, 73, 72, 89, 57, 42, 86, 78
          ]
        }
      ],
      "chartType": "bar",
      "chartHeight": 30
    },
    {
      "title": "عدد الوحدات الشاغرة",
      "total": "35",
      "info": [
        {
          "icon": "fa fa-chevron-up fa-fw me-1",
          "text": "20.4% NVC Head Office"
        },
        {
          "icon": "fa fa-shopping-bag fa-fw me-1",
          "text": "33.5% NVR King Abdul Azziz"
        },
        {
          "icon": "fa fa-dollar-sign fa-fw me-1",
          "text": "6.21% NVR Takhassusi"
        }
      ],
      "chartData": [
        {
          "name": "Sales",
          "data": [
            81, 89, 71, 75, 53, 47, 78, 33, 71, 81, 45, 43, 68
          ]
        }
      ],
      "chartType": "line",
      "chartHeight": 30
    },
    {
      "title": "نسبة التسكين",
      "total": "47 %",
      "info": [
        {
          "icon": "fa fa-chevron-up fa-fw me-1",
          "text": "59.5% NVR King Abdul Azziz"
        },
        {
          "icon": "fab fa-facebook-f fa-fw me-1",
          "text": "45.5% SLE-Riyadh"
        },
        {
          "icon": "fab fa-youtube fa-fw me-1",
          "text": "15.25% AMR Olaya"
        }
      ],
      "chartData": [
        77, 80, 70
      ],
      "chartType": "pie",
      "chartHeight": 45
    },

    // {
    //   "title": "الاستإذان",
    //   "total": "3",
    //   "info": [
    //     {
    //       "icon": "fa fa-chevron-up fa-fw me-1",
    //       "text": "5.3% AMR Olaya"
    //     },
    //     {
    //       "icon": "far fa-hdd fa-fw me-1",
    //       "text": "10.5% NVC Head Office"
    //     },
    //     {
    //       "icon": "far fa-hand-point-up fa-fw me-1",
    //       "text": "0 SLE-Riyadht"
    //     }
    //   ],
    //   "chartData": [
    //     83, 74, 58, 70, 78
    //   ],
    //   "chartType": "donut",
    //   "chartHeight": 45
    // }
  ]


  return { Data , DashBoardList }
}

export default SUDashBoardData

// export const Data = [
//     {
//       "title": "إجمالي عدد الوحدات العقارية",
//       "total": "41",
//       "info": [
//         {
//           "icon": "fa fa-chevron-up fa-fw me-1",
//           "text": "33.3% NVC Head Office"
//         },
//         {
//           "icon": "far fa-user fa-fw me-1",
//           "text": "45.5% NVR King Abdul Azziz"
//         },
//         {
//           "icon": "far fa-times-circle fa-fw me-1",
//           "text": "3.25% AMR Olaya"
//         }
//       ],
//       "chartData": [
//         {
//           "name": "Visitors",
//           "data": [
//             69, 34, 70, 73, 41, 32, 73, 72, 89, 57, 42, 86, 78
//           ]
//         }
//       ],
//       "chartType": "bar",
//       "chartHeight": 30
//     },
//     {
//       "title": "عدد الوحدات الشاغرة",
//       "total": "35",
//       "info": [
//         {
//           "icon": "fa fa-chevron-up fa-fw me-1",
//           "text": "20.4% NVC Head Office"
//         },
//         {
//           "icon": "fa fa-shopping-bag fa-fw me-1",
//           "text": "33.5% NVR King Abdul Azziz"
//         },
//         {
//           "icon": "fa fa-dollar-sign fa-fw me-1",
//           "text": "6.21% NVR Takhassusi"
//         }
//       ],
//       "chartData": [
//         {
//           "name": "Sales",
//           "data": [
//             81, 89, 71, 75, 53, 47, 78, 33, 71, 81, 45, 43, 68
//           ]
//         }
//       ],
//       "chartType": "line",
//       "chartHeight": 30
//     },
//     {
//       "title": "نسبة التسكين",
//       "total": "47 %",
//       "info": [
//         {
//           "icon": "fa fa-chevron-up fa-fw me-1",
//           "text": "59.5% NVR King Abdul Azziz"
//         },
//         {
//           "icon": "fab fa-facebook-f fa-fw me-1",
//           "text": "45.5% SLE-Riyadh"
//         },
//         {
//           "icon": "fab fa-youtube fa-fw me-1",
//           "text": "15.25% AMR Olaya"
//         }
//       ],
//       "chartData": [
//         77, 80, 70
//       ],
//       "chartType": "pie",
//       "chartHeight": 45
//     },

//     // {
//     //   "title": "الاستإذان",
//     //   "total": "3",
//     //   "info": [
//     //     {
//     //       "icon": "fa fa-chevron-up fa-fw me-1",
//     //       "text": "5.3% AMR Olaya"
//     //     },
//     //     {
//     //       "icon": "far fa-hdd fa-fw me-1",
//     //       "text": "10.5% NVC Head Office"
//     //     },
//     //     {
//     //       "icon": "far fa-hand-point-up fa-fw me-1",
//     //       "text": "0 SLE-Riyadht"
//     //     }
//     //   ],
//     //   "chartData": [
//     //     83, 74, 58, 70, 78
//     //   ],
//     //   "chartType": "donut",
//     //   "chartHeight": 45
//     // }
//   ]