import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axios-custom";



export const GetApprovalGroupList = createAsyncThunk("ApprovalGroup/GetApprovalGroupList", async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`AmeApprovalGroup/GetApprovalGroupList`);
      const {data} = await ntxAPI.get(`AmeApprovalGroup/GetApprovalGroupList` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const GetApprovalGroupById = createAsyncThunk("ApprovalGroup/GetApprovalGroupById", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    
    try {
      // const data = await getData(`AmeApprovalGroup/GetApprovalGroupById?Id=${id}`);
      const {data} = await ntxAPI.get( `AmeApprovalGroup/GetApprovalGroupById?Id=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const insertAprovalGroup = createAsyncThunk("ApprovalGroup/insertAprovalGroup", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("AmeApprovalGroup/AddApprovalGroup" , item);
      const {data} = await ntxAPI.post("AmeApprovalGroup/AddApprovalGroup" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const UpdateAprovalGroup = createAsyncThunk("ApprovalGroup/UpdateAprovalGroup", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("AmeApprovalGroup/UpdateApprovalGroup" , item);
      const {data} = await ntxAPI.post("AmeApprovalGroup/UpdateApprovalGroup" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });
  





  const initialState = { ApprovalGroupList: [] , ApprovalGroupListById: [] ,  loading: false, error: null };

  const ApprovalGroupSlice = createSlice({
    name: "ApprovalGroup",
    initialState,
    reducers: {
    },
    extraReducers: {
      /////// get Approval Group list                
      [GetApprovalGroupList.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetApprovalGroupList.fulfilled]: (state, action) => {
        state.loading = false;
        state.ApprovalGroupList = action.payload;
      },
      [GetApprovalGroupList.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

      //// insert Aproval Group
      [insertAprovalGroup.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [insertAprovalGroup.fulfilled]: (state, action) => {
        state.loading = false;
        state.ApprovalGroupList.push(action.payload);
      },
      [insertAprovalGroup.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

       /////// get Approval Group By ID                
       [GetApprovalGroupById.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetApprovalGroupById.fulfilled]: (state, action) => {
        state.loading = false;
        state.ApprovalGroupListById = action.payload;
      },
      [GetApprovalGroupById.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

        /////// get Approval Group By ID                
        [UpdateAprovalGroup.pending]: (state) => {
            state.loading = true;
            state.error = null;
          },
          [UpdateAprovalGroup.fulfilled]: (state, action) => {
            state.loading = false;
            state.ApprovalGroupListById = action.payload;
          },
          [UpdateAprovalGroup.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
          },



   
    },
  });
  
  export default ApprovalGroupSlice.reducer;