import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";

import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from "react-i18next";
import { GetMyRequest } from '../../../store/Ame/ameRequestSlice.js';
import Loader from '../../../components/Loader/Loader.js';
import { Card, CardBody } from '../../../components/card/card.jsx';
import Pagination from '../../../components/Pagination/Pagination.jsx';

const MyRequests = () => {
  const [Show, setShow] = useState(false);
  const { t , i18n } = useTranslation();
  const Navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const {MyRequestList , loading} = useSelector((state) => state.AmeRequest);
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(GetMyRequest());    
  }, [dispatch]);


  const handleSearch = (event) => {
    setSearch(event.target.value);
  };


  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };


  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = MyRequestList
    ?.filter((item) => item?.requestName.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return b[sortBy] > a[sortBy] ? 1 : -1;
      }
    })
    .slice(indexOfFirstData, indexOfLastData);

    const handlePagination = (pageNumber) => setCurrentPage(pageNumber);



  // report to xls
  const handleReport = () => {
    const csv = Papa.unparse({
      fields: ["requestName2", "personName2", "dateCreated" , "status"],
      data: currentData,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
   
  console.log(MyRequestList);
  return (
    <>
        <div className="d-flex align-items-center mb-3">
        <div>
            <ul className="breadcrumb">
                <li className="breadcrumb-item"><a href="#/">{t('Home')}</a></li>
                <li className="breadcrumb-item active">{t('My Requests')}</li>
            </ul>
            <h1 className="page-header mb-0">{t('My Requests')}</h1>
        </div>
    </div>
    <Card>
          <Loader loading={loading}/>
          <CardBody>
                  <div className="tab-content p-4">
      <div className="tab-pane fade show active" id="allTab">
      <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-4">
                  <div className="flex-fill position-relative">
                    <div className="input-group">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                        style={{ zIndex: 1020, right: "10px" }}
                      >
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control ps-35px"
                        onChange={handleSearch}
                        placeholder={t("Search") + " ..."}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5 col-md-6 text-end">
                <button
                  onClick={handleReport}
                  className="btn btn-outline-default text-nowrap rounded-2"
                  tabIndex={0}
                  aria-controls="datatable"
                  type="button"
                >
                  <span>
                    <i className="fa fa-file-excel fa-fw me-1" /> Export CSV
                  </span>
                </button>
              </div>
            </div>
              
               {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table ">
                <thead className="table-dark">
                    <tr>
                      <th
                        scope="col"
                        onClick={() => handleSort("requestName2")}
                      >
                        {t('TypeOfRequest')}
                      </th>
                      <th
                        scope="col"
                        onClick={() => handleSort("personName2")}
                      >
                        {t('Name')}
                      </th>
                      <th
                        scope="col"
                        onClick={() => handleSort("dateCreated")}
                      >
                        {t('Date')}
                      </th>
                      <th
                        scope="col"
                        onClick={() => handleSort("status")}
                      >
                        {t('Status')}
                      </th>
                      <th scope="col"> </th>
                    </tr>
                  </thead>
                  <tbody>
                      {currentData.map((item , index )=> (
                      <tr key={item.id}>
                      <td className="align-middle"> {i18n.language === 'ar' ? item.requestName2 : item.requestName } </td>
                      <td className="align-middle">{i18n.language === 'ar' ? item.personName2 : item.personName }</td>
                      <td className="align-middle">{item.dateCreated }</td>
                      <td className="align-middle">{item.status }</td>
                      <td className="align-middle">
 

            <button 
            onClick={() => Navigate(`/SelfService/Requests/${item.transectionHistoryId}/OnlyViewRequests`)}
             type="button" className="btn btn-outline-theme">
              <i className="fas fa-lg fa-fw me-2 fa-info-circle"></i>
                View
              </button>
            </td>
                    </tr>
                    ))}
                    
                    
                  </tbody>
                </table>
              </div>
              <Pagination
              filteredData={MyRequestList}
                // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
              dataPerPage={dataPerPage}
              handlePagination={handlePagination}
              currentPage={currentPage}
               />
               
          </div>
        </div>
          </CardBody>

    </Card>


 
  </>
  )
}

export default MyRequests