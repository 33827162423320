import React, { useState, useEffect, useMemo } from "react";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import AddJob from "./AddJob.js";
import Pagination from "../../../../components/Pagination/Pagination.jsx";
import { Card, CardBody } from "../../../../components/card/card.jsx";
import Loader from "../../../../components/Loader/Loader.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "../../../../store/HR/HRSetting/jobSlice.js";
import ModalComponent from "../../../../components/Modal/ModalComponent.jsx";
import Loading from "../../../../components/Loading.tsx";

const Job = () => {
  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const { Jobs, loading, error } = useSelector((state) => state.Jobs);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getJobs());
  }, [dispatch]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

 
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = Jobs?.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  )
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return b[sortBy] > a[sortBy] ? 1 : -1;
      }
    })
    .slice(indexOfFirstData, indexOfLastData);
  // console.log(Jobs);
  // console.log( currentData);
  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  // report to xls
  const handleReport = () => {
    const csv = Papa.unparse({
      fields: ["id", "name"],
      data: currentData,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active">{t("Jobs")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Jobs")}</h1>
        </div>
      </div>
      <Card>
        <Loading loading={loading} error={error} SkeletonType="Table">

       
        <CardBody>

           
              <div className="tab-content p-4">
                <div className="tab-pane fade show active">
                  <div className="row">
                    <div className="col-7 col-md-6 d-flex justify-content-start">
                      <div className="input-group mb-4">
                        <div className="flex-fill position-relative">
                          <div className="input-group">
                            <div
                              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                              style={{ zIndex: 1020, right: "10px" }}
                            >
                              <i className="fa fa-search opacity-5"></i>
                            </div>
                            <input
                              type="text"
                              className="form-control ps-35px"
                              onChange={handleSearch}
                              placeholder={t("Search") + " ..."}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 col-md-6 text-end">
                      <a
                        href="#/"
                        className="btn me-1 btn-outline-theme"
                        data-bs-toggle="modal"
                        data-bs-target="#modalِAddJob"
                        onClick={() => setShow(true)}
                      >
                        <i className="fa fa-plus-circle fa-fw me-1"></i>
                        {t("Add new job")}
                      </a>
                      <button
                        onClick={handleReport}
                        className="btn btn-outline-default text-nowrap rounded-2"
                        tabIndex={0}
                        aria-controls="datatable"
                        type="button"
                      >
                        <span>
                          <i className="fa fa-file-excel fa-fw me-1" /> Export
                          CSV
                        </span>
                      </button>
                    </div>
                  </div>

                  <Loader loading={loading} />

                  {/* //  START TABLE   */}
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="table-dark">
                        <tr>
                          <th scope="col" onClick={() => handleSort("id")}>
                            #
                          </th>
                          <th scope="col" onClick={() => handleSort("name")}>
                            {t("Name")}
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((item, index) => (
                          <tr key={item.id}>
                            <td className="align-middle">{++index}</td>
                            <td className="align-middle">
                              {i18n.language === "en" ? item.name : item.name2}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-lime"
                                onClick={() => Navigate(`${item.id}/Edit`)} // Job/:id/Edit
                              >
                                <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                                {t("Button.Edit")}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    filteredData={Jobs}
                    // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                    dataPerPage={dataPerPage}
                    handlePagination={handlePagination}
                    currentPage={currentPage}
                  />
                </div>
              </div>
          
         
        </CardBody>
         </Loading>
      </Card>

      <ModalComponent title={t("Add job")} id="modalِAddJob">
        {Show && <AddJob />}
      </ModalComponent>
    </>
  );
};

export default Job;
