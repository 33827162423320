import { useFormik } from 'formik';
import React from 'react'
import Form from "react-bootstrap/Form";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { insertJob } from '../../../../store/HR/HRSetting/jobSlice';
import { formJob } from '../../../ValidationForm/validationSchema';
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx';

const AddJob = () => {

  const dispatch = useDispatch();
  const { t} = useTranslation();
  
  const formik = useFormik({
    initialValues: {
      name2: "",
      name: "",
      comments: "",
     
    },
    validationSchema: formJob(t),
    onSubmit: (values , {resetForm}  ) => {
      console.log(values );

      dispatch(
        insertJob({
          name2: values.name2,
          name: values.name,
          comments: values.comments,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: "Add" });
          // document.getElementById("closeModalJob").click();
        })
        .catch((error) => {
          formik.setSubmitting(false)
          CustomAlert({ action: "Error" });
        });
    },
  });



  return (
    <>
     <Form onSubmit={formik.handleSubmit} >
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label"  >
             {t('Arabic Name')}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={!!formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>
      
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label"  >
               {t('English Name')}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={!!formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name2}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>
      <div className="row">
      <div className="col-xl-12">
        <div className="form-group mb-3">
          <label className="form-label"  >
             {t('Note')}
          </label>
          <Form.Control
              className="form-control"
              as="textarea" rows={3}
              name="comments"
              onChange={formik.handleChange}
              value={formik.values.comments}
              isInvalid={!!formik.errors.comments}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.comments}
            </Form.Control.Feedback>
          <div>
          
        </div>
        </div>
      </div>
     

    </div>

    
    <div className="text-center">
      <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-theme mb-1" id='SaveSubmit'>
      <i className="fa fa-save fa-fw me-1"></i> {t("Save")}
      </button>
      <button type="button" className=" btn me-1 btn-default mb-1" data-bs-dismiss="modal" id="closeModalJob">
      <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
        <button type="button" onClick={formik.resetForm} className="btn me-1 btn btn-danger mb-1">
        <i className="fa fa-trash-alt fa-fw me-1"></i>
          {t("Clear")}</button>
          </div>
    </Form>
    </>
   
  )
}

export default AddJob