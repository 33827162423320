import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import React, { useEffect } from "react";
import Select from "react-select";
import { EditOrganization } from "../../../../store/HR/Organizations/organizationSlice";
import { formOrganization } from "../../../ValidationForm/validationSchema";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getLocations } from "../../../../store/HR/HRSetting/locationSlice";
import useLookupOrgType from "../../../../hooks/Lookups/use-Lookup-OrgType";
import useLookupOrgClass from "../../../../hooks/Lookups/use-Lookup-OrgClass";
// import ConfirmEditDialog from "../../../../components/Alert/ConfirmEditDialog";
import { Card, CardBody } from "../../../../components/card/card";
import Loader from "../../../../components/Loader/Loader";
// { Organization, loading }
const EditFormOrganization = (props) => {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { ORG_TYPE } = useLookupOrgType();
  const { dataORGCLASS } = useLookupOrgClass();

  const { Locations } = useSelector((state) => state.Locations);
  useEffect(() => {
    dispatch(getLocations());
  }, [dispatch]);

  // جلب قيم معلومات الادارة  والوصول الى مسمياتها من خلال القيم
  const LabelArray =
    props.Organization &&
    props.Organization?.organizationInformation?.split(",").map((value) => {
      const label = dataORGCLASS?.find((item) => item.value === value.trim());
      return label;
    });

  const formik = useFormik({
    initialValues: {
      // ID: "",
      name2: props.Organization ? props.Organization.name2 : "", // data?.name ?? '',
      name: props.Organization ? props.Organization.name : "",
      locationId: props.Organization ? props.Organization.locationId : "",
      externalFlag: props.Organization
        ? props.Organization.externalFlag
        : false,
      dateFrom: props.Organization ? props.Organization.dateFrom : "",
      description: props.Organization ? props.Organization.description : "",
      type: props.Organization ? props.Organization.type : "",
      organizationInformation: props.Organization
        ? props.Organization.organizationInformation
        : "",
    },

    // new Date(apiResponse.datetime).toISOString().slice(0, -1),
    enableReinitialize: true,
    validationSchema: formOrganization(t),

    onSubmit: (values, { resetForm }) => {
      // console.log(values );

      dispatch(
        EditOrganization({
          id: props.Organization.id,
          businessGroupId: null,
          locationId: values.locationId,
          dateFrom: values.dateFrom,
          name: values.name,
          name2: values.name2,
          externalFlag: values.externalFlag,
          description: values.description,
          type: values.type,
          organizationInformation: values.organizationInformation,
          dateTo: null,
        })
      )
        .unwrap()
        .then(() => {
          resetForm();
          CustomAlert({ action: "Edit" });
          Navigate(-1, { replace: true });
        })
        .catch((error) => {
          CustomAlert({ action: "Error" });
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <Card>
      <CardBody>
        <Loader loading={props.loading} />
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("Arabic Name")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name2"
                  onChange={formik.handleChange}
                  value={formik.values.name2 || ""}
                  isInvalid={formik.touched.name2 && formik.errors.name2}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name2}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("English Name")}</label>
                <Form.Control
                  className="form-control"
                  placeholder="الاسم بالانجليزي"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name || ""}
                  isInvalid={formik.touched.name && formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label"> {t("location")}</label>
                <Form.Select
                  className="form-select"
                  name="locationId"
                  onChange={formik.handleChange}
                  value={formik.values.locationId || ""}
                  isInvalid={
                    formik.touched.locationId && formik.errors.locationId
                  }
                >
                  <option key={null}>
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  {Locations.map((item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.locationId}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">
                  {t("Internal or external")}
                </label>
                <Form.Check
                  style={{ margin: "10px 20px" }}
                  className="form-check form-switch"
                  type="switch"
                  id="custom-switch"
                  // label={t("Internal or external")}
                  name="externalFlag"
                  checked={formik.values.externalFlag || false}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("Type")}</label>
                <Form.Select
                  disabled={props.type !== undefined}
                  className="form-select"
                  name="type"
                  onChange={formik.handleChange}
                  value={formik.values.type || ""}
                  isInvalid={formik.touched.type && formik.errors.type}
                >
                  <option key={null}>
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  {ORG_TYPE.map((item, idx) =>
                    item.list.map((list) => (
                      <option key={++idx} value={list.lookupCode}>
                        {i18n.language === "ar" ? list.meaning2 : list.meaning}
                      </option>
                    ))
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.type}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("Start Date")}</label>
                <Form.Control
                  type="date"
                  id="START_DATE"
                  className="form-control"
                  name="dateFrom"
                  onChange={formik.handleChange}
                  value={formik.values.dateFrom || ""}
                  isInvalid={!!formik.errors.dateFrom}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.dateFrom}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="form-group mb-3">
                <label className="form-label">{t("Note")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description || ""}
                  // isInvalid={!!formik.errors.name_ar}
                />
              </div>
            </div>
          </div>

          {!props.type && (
            <div className="col-xl-12">
              <div className="mb-5">
                <label className="form-label">
                  {t("Classification")} <span className="text-danger">*</span>
                </label>

                {LabelArray &&
                  LabelArray?.some((item) => item !== undefined) && (
                    <Select
                      required
                      isMulti
                      options={dataORGCLASS}
                      classNamePrefix="react-select"
                      name="organizationInformation"
                      isSearchable={true}
                      defaultValue={LabelArray}
                      onChange={(selectedOptions) => {
                        const formattedOptions = selectedOptions
                          .map((option) => option.value)
                          .join(",");
                        formik.setFieldValue(
                          "organizationInformation",
                          formattedOptions
                        );
                      }}
                    />
                  )}
              </div>
            </div>
          )}

          <div className="text-center">
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="btn me-1 btn-theme mb-1"
              id="SaveSubmit"
            >
              {formik.isSubmitting ? (
                <div className="spinner-border spinner-border-sm me-2"></div>
              ) : (
                <i className="fa fa-save fa-fw me-1"></i>
              )}
              {t("Save")}
            </button>

            <button
              type="button"
              className=" btn me-1 btn-default mb-1"
              onClick={() => Navigate(-1, { replace: true })}
            >
              <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
            </button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default EditFormOrganization;
