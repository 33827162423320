import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { GetExtraInformationWithValueById } from "../../store/ExtraInformation/extraInformationSlice";
 
const useGetExtraInformationValue = (extraInformationId)=>{
    const dispatch = useDispatch();
    
    useEffect(()=>{
      if(extraInformationId !== null){
        dispatch(GetExtraInformationWithValueById(extraInformationId))
      }
      },[dispatch ,extraInformationId ])

      const { ExtraInformationWithValueById, loading } =
      useSelector((state) => state.ExtraInformation);

    return { ExtraInformationWithValueById , loading } ;
};

export default useGetExtraInformationValue ;