import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../API/axios-custom";

const initialState = {
  TITLE: [],
  SEX: [],
  MAR_STATUS: [],
  NATIONALITY: [],
  ORG_TYPE: [],
  ORG_CLASS: [],
  Currency: [],
  Module: [],
  TableDataList: [],
  Banks: [],
  RELIGIONS: [],
  FleetCategory: [],
  FleetFuelType: [],
  FleetMotionVector: [],
  FleetRequestType: [],
  EngineStatues: [],
  PRIORITY: [],


  loading: false,
  NationalityLoading: false,
  error: null,
};

export const getTITLE = createAsyncThunk(
  "Lookup/getTITLE",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cachedTitle = getState().Lookup.TITLE;
    if (cachedTitle?.length > 0) {
      return cachedTitle;
    }
    try {
      // const data = await getData("Lookup/GetByTypes?LookupTypes=TITLE" );
      const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=TITLE");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSex = createAsyncThunk("Lookup/getSex", async (_, thunkAPI) => {
  const { rejectWithValue, getState } = thunkAPI;
  const cachedSex = getState().Lookup.SEX;
  if (cachedSex?.length > 0) {
    return cachedSex;
  }

  try {
    // const data = await getData("Lookup/GetByTypes?LookupTypes=SEX");
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=SEX");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getMarStatus = createAsyncThunk(
  "Lookup/getMarStatus",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cacheddata = getState().Lookup.MAR_STATUS;
    if (cacheddata?.length > 0) {
      return cacheddata;
    }

    try {
      // const data = await getData("Lookup/GetByTypes?LookupTypes=MAR_STATUS") ;
      const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=MAR_STATUS");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getNationality = createAsyncThunk(
  "Lookup/getNationality",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cachedData = getState().Lookup.NATIONALITY;
    if (cachedData?.length > 0) {
      return cachedData;
    }


    try {
      // const data = await getData("Lookup/GetByTypes?LookupTypes=NATIONALITY");
      const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=NATIONALITY");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getOrgType = createAsyncThunk(
  "Lookup/getOrgType",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cachedData = getState().Lookup.ORG_TYPE;
    if (cachedData?.length > 0) {
      return cachedData;
    }


    try {
      // const data = await getData("Lookup/GetByTypes?LookupTypes=ORG_TYPE");
      const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=ORG_TYPE");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getOrgClass = createAsyncThunk(
  "Lookup/getOrgClass",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // const data = await getData("Lookup/GetByTypes?LookupTypes=ORG_CLASS");
      const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=ORG_CLASS");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetCurrency = createAsyncThunk(
  "Lookup/GetCurrency",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cachedData = getState().Lookup.Currency;
      if (cachedData?.length > 0) {
        return cachedData;
      }

    try {
      const {data} = await ntxAPI.get("NtxSetting/GetCurrencyList");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetModule = createAsyncThunk(
  "Lookup/GetModule",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cachedData = getState().Lookup.Module;
      if (cachedData?.length > 0) {
        return cachedData;
      }
    try {
      const {data} = await ntxAPI.get("NtxSetting/GetModuleList");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
    // try {
    //   const data = await getData("NtxSetting/GetModuleList");
    //   return data;
    // } catch (error) {
    //   return rejectWithValue(error.message);
    // }
  }
);


export const GetTableData = createAsyncThunk(
  "Lookup/GetTableData",
  async (id, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    // const cachedData = getState().Lookup.Currency;
    //   if (cachedData.length > 0) {
    //     return cachedData;
    //   }
    try {
      // const data = await getData(`NtxSetting/GetDataByTabelId?TabelId=${id}`);
      const {data} = await ntxAPI.get(`NtxSetting/GetDataByTabelId?TabelId=${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getReligions = createAsyncThunk(
  "Lookup/getReligions",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cacheddata = getState().Lookup.RELIGIONS;
    if (cacheddata?.length > 0) {
      return cacheddata;
    }

    try {
      // const data = await getData("Lookup/GetByTypes?LookupTypes=RELIGIONS" , thunkAPI)
      const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=RELIGIONS");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getBanks = createAsyncThunk(
  "Lookup/getBanks",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const cacheddata = getState().Lookup.Banks;
    if (cacheddata?.length > 0) {
      return cacheddata;
    }

    try {
      // const data = await getData("NtxSetting/GetBankList" , thunkAPI) ;
      const {data} = await ntxAPI.get("NtxSetting/GetBankList" );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const GetFleetCategory = createAsyncThunk("Lookup/GetFleetCategory", async (_, thunkAPI) => {
  const { rejectWithValue, getState } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=FLEET_CATEGORY");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const GetFleetFuelType = createAsyncThunk("Lookup/GetFleetFuelType", async (_, thunkAPI) => {
  const { rejectWithValue, getState } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=FLEET_FUEL_TYPE");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const GetFleetMotionVector = createAsyncThunk("Lookup/GetFleetMotionVector", async (_, thunkAPI) => {
  const { rejectWithValue, getState } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=FLEET_MOTION_VECTOR");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const GetFleetRequestType = createAsyncThunk("Lookup/GetFleetRequestType", async (_, thunkAPI) => {
  const { rejectWithValue, getState } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=FLEET_REQUEST_TYPE");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const GetEngineStatues = createAsyncThunk("Lookup/GetEngineStatues", async (_, thunkAPI) => {
  const { rejectWithValue, getState } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=ENGINE_STATUS");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const GetLookupPRIORITY = createAsyncThunk("Lookup/GetLookupPRIORITY", async (_, thunkAPI) => {
  const { rejectWithValue, getState } = thunkAPI;

  try {
    const {data} = await ntxAPI.get("Lookup/GetByTypes?LookupTypes=PRIORITY");
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});




const lookupSlice = createSlice({
  name: "Lookup",
  initialState,
  reducers: {},
  extraReducers: {
    // getTITLE
    [getTITLE.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTITLE.fulfilled]: (state, action) => {
      state.loading = false;
      state.TITLE = action.payload;
    },
    [getTITLE.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // getReligions
    [getReligions.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getReligions.fulfilled]: (state, action) => {
      state.loading = false;
      state.RELIGIONS = action.payload;
    },
    [getReligions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get Sex
    [getSex.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSex.fulfilled]: (state, action) => {
      state.loading = false;
      state.SEX = action.payload;
    },
    [getSex.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get MAR_STATUS
    [getMarStatus.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getMarStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.MAR_STATUS = action.payload;
    },
    [getMarStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get NATIONALITY
    [getNationality.pending]: (state) => {
      state.NationalityLoading = true;
      state.error = null;
    },
    [getNationality.fulfilled]: (state, action) => {
      state.NationalityLoading = false;
      state.NATIONALITY = action.payload;
    },
    [getNationality.rejected]: (state, action) => {
      state.NationalityLoading = false;
      state.error = action.payload;
    },

    // get ORG_TYPE
    [getOrgType.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getOrgType.fulfilled]: (state, action) => {
      state.loading = false;
      state.ORG_TYPE = action.payload;
    },
    [getOrgType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get ORG_Class
    [getOrgClass.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getOrgClass.fulfilled]: (state, action) => {
      state.loading = false;
      state.ORG_CLASS = action.payload;
    },
    [getOrgClass.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get Currency
    [GetCurrency.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetCurrency.fulfilled]: (state, action) => {
      state.loading = false;
      state.Currency = action.payload;
    },
    [GetCurrency.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

        // get Banks
        [getBanks.pending]: (state) => {
          state.loading = true;
          state.error = null;
        },
        [getBanks.fulfilled]: (state, action) => {
          state.loading = false;
          state.Banks = action.payload;
        },
        [getBanks.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },

        //  Get Module
        [GetModule.pending]: (state) => {
          state.loading = true;
          state.error = null;
        },
        [GetModule.fulfilled]: (state, action) => {
          state.loading = false;
          state.Module = action.payload;
        },
        [GetModule.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },

        // Get Table Data 
        [GetTableData.pending]: (state) => {
          state.loading = true;
          state.error = null;
        },
        [GetTableData.fulfilled]: (state, action) => {
          state.loading = false;
          state.TableDataList = action.payload;
        },
        [GetTableData.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },



        // Get FleetCategory 
        [GetFleetCategory.pending]: (state) => {
          state.loading = true;
          state.error = null;
        },
        [GetFleetCategory.fulfilled]: (state, action) => {
          state.loading = false;
          state.FleetCategory = action.payload;
        },
        [GetFleetCategory.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },


        // Get GetFleetFuelType 
        [GetFleetFuelType.pending]: (state) => {
          state.loading = true;
          state.error = null;
        },
        [GetFleetFuelType.fulfilled]: (state, action) => {
          state.loading = false;
          state.FleetFuelType = action.payload;
        },
        [GetFleetFuelType.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },


        // Get GetFleetMotionVector 
        [GetFleetMotionVector.pending]: (state) => {
          state.loading = true;
          state.error = null;
        },
        [GetFleetMotionVector.fulfilled]: (state, action) => {
          state.loading = false;
          state.FleetMotionVector = action.payload;
        },
        [GetFleetMotionVector.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },


        // Get GetFleetMotionVector 
        [GetFleetRequestType.pending]: (state) => {
          state.loading = true;
          state.error = null;
        },
        [GetFleetRequestType.fulfilled]: (state, action) => {
          state.loading = false;
          state.FleetRequestType = action.payload;
        },
        [GetFleetRequestType.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },

        // Get 
        [GetEngineStatues.pending]: (state) => {
          state.loading = true;
          state.error = null;
        },
        [GetEngineStatues.fulfilled]: (state, action) => {
          state.loading = false;
          state.EngineStatues = action.payload;
        },
        [GetEngineStatues.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },

        
        // Get  
        [GetLookupPRIORITY.pending]: (state) => {
          state.loading = true;
          state.error = null;
        },
        [GetLookupPRIORITY.fulfilled]: (state, action) => {
          state.loading = false;
          state.PRIORITY = action.payload;
        },
        [GetLookupPRIORITY.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },



  },
});

export default lookupSlice.reducer;
