import React, { useState } from "react";
import AddAbsence from "./AddAbsence.jsx";

import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../components/card/card.jsx";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Absence = () => {
  const { personId} = useParams();
  const { AbsenceAttendanceList: data, loading } = useSelector(
    (state) => state.Absence
  );
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                href="#/"
                onClick={() => Navigate("/HR/Employees", { replace: true })}
              >
                {t("Employees")}
              </a>
            </li>
            <li className="breadcrumb-item active">{t("Absence")}</li>
          </ul>
          <h1 className="page-header mb-0">
            {/* {FormId ? t('Leave Request') : t('Absence')} */}
            {i18n.language === "ar" ? data.name2 : data.name}
          </h1>
        </div>
      </div>

      <Card>
        <CardBody>
          <AddAbsence personId={personId} />
        </CardBody>
      </Card>
    </div>
  );
};

export default Absence;
