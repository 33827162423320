import React, { useEffect, useState } from "react";
import AddEmployee from "./AddEmployee";
import ExtraInformationBuilderAdd from "../../../../ExtraInformation/ExtraInformationBuilder/Add/ExtraInformationBuilderAdd";
import { Form } from "react-bootstrap";
import CustomAlert from "../../../../../components/Alert/CustomAlert.tsx";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { getEmployees, insertEmployee } from "../../../../../store/HR/Person/employeeSlice";
import { formSchema } from "../../../../ValidationForm/validationSchema";
import { useTranslation } from "react-i18next";
import { GetDynamicFormColumnListByTableId } from "../../../../../store/NTX/FlexValue/Form/FormColumnSlice";
import { useSelector } from "react-redux";
// import { AddUpdateExtraInformation } from "../../../../../store/ExtraInformation/extraInformationSlice";
import useAddUpdateExtraInformation from "../../../../ExtraInformation/ExtraInformationBuilder/useAddUpdateExtraInformation";


const AddPerson = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [extraInfoFormData, setExtraInfoFormData] = useState({});
  const {AddUpdateExtraInformation} = useAddUpdateExtraInformation()

  //__________ جلب حقول المعلومات الإضافية ان وجدت __________
  const TableId = "cd5aa419-0e0b-48fe-a66f-f004d97964a2"; // جدول الموظفين id
  useEffect(() => {
    dispatch(GetDynamicFormColumnListByTableId(TableId));
  }, [dispatch]);
  const { FormColumnByTableIdList } = useSelector((state) => state.FormColumns);
  //_________________________________________________________
 
  const formik = useFormik({
    initialValues: {
      NAME2: "",
      NAME: "",
      NATIONAL_IDENTIFIER: "",
      START_DATE: "",
      DATE_OF_BIRTH: "",
      MARITAL_STATUS: "",
      GENDER: "",
      NATIONALITY: "",
      TITLE: "",
      COUNTRY_OF_BIRTH: "",
      TOWN_OF_BIRTH: "",
      Religion: "",
      extraInformationId: null,
    },
    validationSchema: formSchema(t),
    onSubmit: async (values) => {

      var extraInformationId;
      if (FormColumnByTableIdList?.columnList?.length > 0) {
        extraInformationId = await AddUpdateExtraInformation(extraInfoFormData)
      }
      if (
        FormColumnByTableIdList?.columnList?.length > 0 &&
        extraInformationId === null
      ) {
        CustomAlert({ action: "Error", msg: "حدث خطأ" });
        return;
      }

      dispatch(
        insertEmployee({
          extraInformationId: extraInformationId,
          employeeNumber: null,
          name2: values.NAME2,
          name: values.NAME,
          nationalIdentifier: values.NATIONAL_IDENTIFIER,
          startDate: values.START_DATE,
          dateOfBirth: values.DATE_OF_BIRTH,
          maritalStatus: values.MARITAL_STATUS,
          gender: values.GENDER,
          nationality: values.NATIONALITY,
          title: values.TITLE,
          countryOfBirth: values.COUNTRY_OF_BIRTH,
          townOfBirth: values.TOWN_OF_BIRTH,
          Religion: values.Religion,
          regionOfBirth: "",
          rotationPlanId: null,
          annualLeave: 0,
          iBan: "",
          bankId: null,
        })
      )
        .unwrap()
        .then((res) => {
          // console.log(res);
          if (res.succeeded === true) {
            dispatch(getEmployees())
            CustomAlert({ action: "Add" });
            formik.resetForm();
            document.getElementById("closeModalButton")?.click();
          } else {
            CustomAlert({ action: "Error" });
            formik.setSubmitting(false);
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          // CustomAlert({ action: "Error" });
        });
    },
  });


  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <AddEmployee formik={formik} />

        {FormColumnByTableIdList?.columnList?.length > 0 && (
          <ExtraInformationBuilderAdd
            FormColumnList={FormColumnByTableIdList}
            moduleId="0a6c6b89-1d52-4eaa-8d2c-000c74b1f6e0" // moduleId
            setExtraInfoFormData={setExtraInfoFormData}
          />
         )} 
         
        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
          >
            <i className="fa fa-save fa-fw me-1"></i>
            {formik.isSubmitting ? "Saving..." : "Save"}
          </button>
          <button
            disabled={formik.isSubmitting}
            type="button"
            className="btn me-1 btn-default mb-1"
            id="closeModalButton"
          >
            <i className="far fa-lg fa-fw me-2 fa-window-close"></i>
            {t('Cancel')}
          </button>
        </div>
      </Form>
    </>
  );
};

export default AddPerson;
