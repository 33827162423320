import React from 'react'
import ContentLoader from 'react-content-loader'

const SkeletonForm2 = () => {
  return (
<ContentLoader
    //   height={400}
    //   width={1000}
      viewBox="0 0 400 260"
      // viewBox="0 0 1300 500"
      backgroundColor="#7170701c"
      foregroundColor="##808080"
    >
         <rect x="15" y="15" rx="3" ry="3" width="160" height="130" />




      <rect x="190" y="15" rx="4" ry="4" width="100" height="10" />
      <rect x="300" y="15" rx="3" ry="3" width="100" height="10" />


      <rect x="190" y="50" rx="4" ry="4" width="100" height="10" />
      <rect x="300" y="50" rx="3" ry="3" width="100" height="10" />
    

      <rect x="190" y="88" rx="4" ry="4" width="100" height="10" />
      <rect x="300" y="88" rx="3" ry="3" width="100" height="10" />

      <rect x="190" y="125" rx="4" ry="4" width="100" height="10" />
      <rect x="300" y="125" rx="3" ry="3" width="100" height="10" />
     

      
      
      <rect x="15" y="168" rx="3" ry="3" width="130" height="10" />
      <rect x="160" y="168" rx="3" ry="3" width="120" height="10" />
      <rect x="290" y="168" rx="3" ry="3" width="95" height="10" />

      <rect x="15" y="200" rx="3" ry="3" width="130" height="10" />
      <rect x="160" y="200" rx="3" ry="3" width="120" height="10" />
      <rect x="290" y="200" rx="3" ry="3" width="95" height="10" />
      
      <rect x="15" y="235" rx="3" ry="3" width="130" height="10" />
      <rect x="160" y="235" rx="3" ry="3" width="120" height="10" />
      <rect x="290" y="235" rx="3" ry="3" width="95" height="10" />
  

    </ContentLoader>
  )
}

export default SkeletonForm2
