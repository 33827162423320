import React, { useEffect } from "react";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../../components/card/card";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import In from "./In";
import { useDispatch } from "react-redux";
import { GetPersonListToAccommodationByRealEstateId } from "../../../../store/SU/realEstateSlice";
import { useSelector } from "react-redux";
import Out from "./Out";

const Accomodation = () => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { Id } = useParams();
  useEffect(() => {
    dispatch(GetPersonListToAccommodationByRealEstateId(Id));
  }, []);
  const { ListAccommodation , loading} = useSelector((state) => state.RealState);
  const ListOut = ListAccommodation?.filter((f) => f.InOut === "Out");
  const ListIn = ListAccommodation?.filter((f) => f.InOut === "In");

  //_____________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "RealEstate",
      link: "/SU/RealEstate",
    },
    {
      name: "Accomodation",
      link: null,
    },
  ];
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Accomodation" />

      {/* <hr/> */}

          <div className="row">
            <div className="col-lg-6">
              <In List={ListIn} loading={loading}   />
            </div>

            <div className="col-lg-6">
              <Out List={ListOut} loading={loading}/>
            </div>
          </div>

    </div>
  );
};

export default Accomodation;
