import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { GetNtxPeoplesList } from "../../../store/NTX/Peoples/peoplesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../components/card/card";
import { Form } from "react-bootstrap";
import EquipmentsOdometerList from "./EquipmentsOdometerList";
import {
  GetEquipmentsOdometerList,
  InsertEquipmentsOdometer,
} from "../../../store/FLEET/EquipmentsOdometer/equipmentsOdometerSlice";
import CustomAlert from "../../../components/Alert/CustomAlert";
import EditEquipmentsOdometer from "./EditEquipmentsOdometer";
import MedModalComponent from "../../../components/Modal/MedModalComponent";
import { EquipmentsOdometerSchema } from "../../ValidationForm/validationSchema";
import Loader from "../../../components/Loader/Loader";

const EquipmentsOdometer = () => {
  const [ID, setID] = useState<string>(""); // لتعريفة من مكون القائمة ومن ثم استعماله في مكون التعديل
  const { Id } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();

  useEffect(() => {
    if (PeoplesList?.length === 0) {
      dispatch(GetNtxPeoplesList());
    }
  }, []);
  const { PeoplesList, loading } = useAppSelector((state) => state.Peoples);

  const formik = useFormik({
    initialValues: {
      fleetEquipmentsId: Id,
      peopleId: "",
      odometer: 0,
      readDate: "",
      description: "",
    },
    validationSchema: EquipmentsOdometerSchema(t),
    onSubmit: (values) => {
      console.log(values);

      dispatch(
        InsertEquipmentsOdometer({
          fleetEquipmentsId: values.fleetEquipmentsId,
          peopleId: values.peopleId,
          odometer: values.odometer,
          description: values.description,
          readDate: values.readDate,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            CustomAlert({ action: "Add" });
            dispatch(GetEquipmentsOdometerList());
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });
  //____


  // var name: string  = EquipmentsList.find(
  //   (f) => f.id === Id
  // )?.name;
  //____________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Assets_and_Equipment",
      link: "/FLEET/Equipments",
    },
    {
      name: "Odometer",
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Odometer" />

      <Card>
        <CardBody>
          <Loader loading={loading} />
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label"> {t("DriverName")}</label>
                  <Form.Select
                    className="form-select"
                    name="peopleId"
                    onChange={formik.handleChange}
                    value={formik.values.peopleId || ""}
                    isInvalid={
                      !!(formik.touched.peopleId && formik.errors.peopleId)
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {PeoplesList &&
                      PeoplesList?.filter((f) => f.typeCode === "DRIVERS").map(
                        (item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === "ar" ? item.name2 : item.name}
                          </option>
                        )
                      )}
                  </Form.Select>
                </div>
              </div>

              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Odometer")}</label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="odometer"
                    onChange={formik.handleChange}
                    value={formik.values.odometer}
                    isInvalid={
                      !!(formik.touched.odometer && formik.errors.odometer)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.odometer}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("DateOfReading")}</label>
                  <Form.Control
                    className="form-control"
                    type="datetime-local"
                    name="readDate"
                    onChange={formik.handleChange}
                    value={formik.values.readDate}
                    isInvalid={
                      !!(formik.touched.readDate && formik.errors.readDate)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.readDate}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Note")}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={1}
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />
                  <div></div>
                </div>
              </div>

            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
                id="SaveSubmit"
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
                )}
                {t("Add")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate("/FLEET/Equipments", { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>

          <hr />
          <div className=" bg-inverse bg-opacity-5 rounded-4 pt-1">
          <EquipmentsOdometerList setID={setID} />
          </div>
        </CardBody>
      </Card>

      <MedModalComponent
        title={t("Update_Odometer")}
        id="UpdateEquipmentsOdometer"
      >
        {
          ID && <EditEquipmentsOdometer ID={ID} />
        }
      </MedModalComponent>
    </div>
  );
};

export default EquipmentsOdometer;
