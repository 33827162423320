import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Card, CardBody } from '../../../../components/card/card';
import { GetAmeCondition } from '../../../../store/Ame/ameSlice';
import { GetAmeConditionUsageListByRuleId, insertAmeConditionUsage } from '../../../../store/Ame/ameConditionUsageSlice';
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx';
import AmeConditionUsageList from './AmeConditionUsageList';

const AddAmeConditionUsage = () => {

    const Navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { ruleId } = useParams();
    const { id } = useParams();
    const dispatch = useDispatch();
    console.log(ruleId);
    console.log(id);
    //
  
    // Get Ame Condition List for fill <Select>
    const { AmeConditionList } = useSelector((state) => state.Ame)
    useEffect(()=>{
      dispatch(GetAmeCondition(id))
    },[])

      // Get Ame Condition List for fill <Select>
      const { ConditionUsageListByRuleId } = useSelector((state) => state.AmeConditionUsage)
      useEffect(()=>{
        dispatch(GetAmeConditionUsageListByRuleId(ruleId))
      },[])
      console.log(ConditionUsageListByRuleId);
  
  
    const formik = useFormik({
      initialValues: {
        ruleId: ruleId,
        conditionId: ""
      },
      validationSchema: Yup.object().shape({
        conditionId: Yup.string()
          .required(() => t("Required"))
      }),
      onSubmit: (values) => {
        console.log(values);

        const existingUsage = ConditionUsageListByRuleId?.find(
          (usage) => usage.conditionId === values.conditionId
        );
        console.log(existingUsage);
        if(existingUsage){
          // ErrorAlert({
          //   title: "مضاف مسبقاً ",
          // });
          CustomAlert({ action: "Error" , msg:'مضاف مسبقاً' });
          formik.setSubmitting(false)
          return
        }

  
          dispatch(
            insertAmeConditionUsage({
              ruleId: ruleId,
              conditionId: formik.values.conditionId
            })
          )
            .unwrap()
            .then(() => {
                formik.resetForm();
                CustomAlert({ action: "Add" });
                dispatch(GetAmeConditionUsageListByRuleId(ruleId))
            })
            .catch((error) => {
                formik.setSubmitting(false)
                CustomAlert({ action: "Error" });
            });
      },
    });
  

  return (
    <>
    <div className="d-flex align-items-center mb-3">
      <div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#/">PAGES</a>
          </li>
          <li className="breadcrumb-item active">Ame Rule</li>
        </ul>
        <h1 className="page-header mb-0"> Add Ame Condition Usage  </h1>
      </div>
    </div>

    <Card>
      <CardBody>
       

          <Form onSubmit={formik.handleSubmit}>



          <div className="row">
            <div className="col-xl-3"></div>
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlSelect1">
            {t('Condition')}
            </label>
            <Form.Select
              className="form-select"
              name="conditionId"
              onChange={formik.handleChange}
              value={formik.values.conditionId}
              isInvalid={!!formik.errors.conditionId}
            >
              <option value={null}>
                  {i18n.language === "ar" ? 'إختر' : 'Choose'}
                </option>
              {AmeConditionList.map((item) => (
                <option key={item.id} value={item.id}>
                {i18n.language === "ar" ? item.name2 : item.name}
              </option>
            ))}

            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.conditionId}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-3"></div>


      </div>
      <div className="text-center">      
      <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-outline-theme mb-1"
              >
                <i className="fas fa-plus fa-fw me-1"></i>
                {formik.isSubmitting ? "Adding..." : "Add"}
              </button>
              <button
        type="button"
        className=" btn me-1 btn-outline-default mb-1"
        onClick={() => Navigate(-1, { replace: true })}
      >
        <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}      </button>
</div>
          </Form>


       

      <hr className="mb-4" />

      <div className="row">
        <div className="col-lg-12">
          <AmeConditionUsageList data={ConditionUsageListByRuleId}/>
        </div>
      </div>
      </CardBody>
     
    </Card>

    
  </>
  )
}

export default AddAmeConditionUsage