import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";




//_________________________________________
export const getEmployees = createAsyncThunk(
  "Employees/getEmployees",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    // const cachedData = getState().Employees.Employees;
    // if (cachedData.length > 0) {
    //   return cachedData;
    // }
    // const cachedData = localStorage.getItem("employeesData");
    // if (cachedData) {
    //   // Parse the cached data and return it
    //   return JSON.parse(cachedData);
    // }

    try {
      const { data } = await ntxAPI.get("Persons/GetPersonList");
      // localStorage.setItem("employeesData", JSON.stringify(data));
      return data?.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getEmployee = createAsyncThunk(
  "Employees/getEmployee",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.get(`Persons/GetPerson?guid=${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const insertEmployee = createAsyncThunk(
  "Employees/insertEmployee",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post("Persons/AddPerson", item);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const editEmployee = createAsyncThunk(
  "Employees/editEmployee",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const { data } = await ntxAPI.post(`Persons/UpdatePerson`, item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);


const initialState = {
  Employees: [],
  loading: false,
  error: null,
  Employee: [],
};

// const persistConfig = {
//   key: "root", // The key under which your state will be saved in local storage
//   storage, // The storage engine (localStorage by default)
//   // whitelist: ["Employees"], // An array of reducer names to persist
// };

const employeeSlice = createSlice({
  name: "Employees",
  initialState,
  reducers: {},
  extraReducers: {
    // get all employees
    [getEmployees.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getEmployees.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.Employees = payload;
    },
    [getEmployees.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get one employee by id
    [getEmployee.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.Employee = [];
    },
    [getEmployee.fulfilled]: (state, action) => {
      state.loading = false;
      state.Employee = action.payload.data;
    },
    [getEmployee.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    ///////insert
    [insertEmployee.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [insertEmployee.fulfilled]: (state, action) => {
      state.loading = false;
      state.Employees.push(action.payload.data);
    },
    [insertEmployee.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Edit
    [editEmployee.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [editEmployee.fulfilled]: (state, action) => {
      state.loading = false;
      // state.Employees = action.payload.data;
    },
    [editEmployee.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// const persistedReducer = persistReducer(persistConfig, employeeSlice.reducer);


// export default persistedReducer;
export default employeeSlice.reducer;
