import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../components/Pagination/Pagination.jsx";
import Papa from "papaparse";
import {
  Card,
  CardHeader,
  CardBody,
  CardExpandToggler,
} from "../../../../components/card/card.jsx";
import { useTranslation } from "react-i18next";
import { GetElementType } from "../../../../store/HR/Payroll/payrollElementSlice.js";
import Loader from "../../../../components/Loader/Loader.js";
import ModalComponent from "../../../../components/Modal/ModalComponent.jsx";
import AddPayrollElement from "./AddPayrollElement.js";

const PayrollElement = () => {
  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();

  // Get all Payrolls from store
  const dispatch = useDispatch();
  const { ElementTypeList, loading } = useSelector((state) => state.PyrollElement) // memoize the selector function
  useEffect(() => {
    dispatch(GetElementType());
  }, [dispatch]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = ElementTypeList?.filter((item) =>
    item.name2.toLowerCase().includes(search.toLowerCase())
  )
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return b[sortBy] > a[sortBy] ? 1 : -1;
      }
    })
    .slice(indexOfFirstData, indexOfLastData);

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  console.log(ElementTypeList);

  // report to xls
  const handleReport = () => {
    const csv = Papa.unparse({
      fields: ["id", "name"],
      data: currentData,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  return (
    <>
          <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active"> {t("Payroll Elements")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Payroll Elements")}</h1>
        </div>
      </div>
    <Card>
      <CardBody>
            <Loader loading={loading} />
      <div className="tab-content p-4">
        <div className="tab-pane fade show active">
          <div className="row">
            <div className="col-7 col-md-6 d-flex justify-content-start">
              <div className="input-group mb-4">
                <div className="flex-fill position-relative">
                  <div className="input-group">
                    <div
                      className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                      style={{ zIndex: 1020, right: "10px" }}
                    >
                      <i className="fa fa-search opacity-5"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control ps-35px"
                      onChange={handleSearch}
                      placeholder={t("Search") + " ..."}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5 col-md-6 text-end">
              <a
                href="#/"
                className="btn btn-theme me-1"
                data-bs-toggle="modal"
                data-bs-target="#modalAddPayrollElement"
                onClick={()=>setShow(true)}
                // hidden={hiddenAddElement}
              >
                <i className="fa fa-plus-circle fa-fw me-1"></i> {t('Add Payroll Element')} </a>
              <button
                onClick={handleReport}
                className="btn btn-outline-default text-nowrap rounded-2"
                tabIndex={0}
                aria-controls="datatable"
                type="button"
              >
                <span>
                  <i className="fa fa-file-excel fa-fw me-1" /> Export CSV
                </span>
              </button>
            </div>
          </div>

          {/* //  START TABLE   */}
          <div className="table-responsive">
            <table className="table">
            <thead className="table-dark">
                <tr>
                  <th scope="col" onClick={() => handleSort("name")}>
                  {t("Name")}
                  </th>

                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((item, index) => (
                  <tr key={item.id}>
                    <td className="align-middle">
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </td>
                    <td>
                      <div className="btn-group">
                        <button
                          type="button"
                          onClick={() => Navigate(`${item.id}/EditElementType`)}
                          className="btn btn-outline-lime mb-1 me-1"
                          // hidden={hiddenEditElement}
                        >
                          <i className="far fa-lg fa-fw me-2 fa-edit"></i> 
                         {t('Button.Edit')}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            filteredData={ElementTypeList}
            // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
            dataPerPage={dataPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        </div>
      </div>
      </CardBody>
    </Card>
    

    <ModalComponent id="modalAddPayrollElement" title={t('Add Payroll Element')}>
            {
              Show && 
              <AddPayrollElement/>
            }
    </ModalComponent>
    </>
  );
};
export default PayrollElement;
