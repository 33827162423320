import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import useGetDynamicForm from "../../../../hooks/Forms/useGetDynamicForm";
import Select from "react-select";
import useGetOrganizations from "../../../../hooks/HR/use-get-organizations.js";
import { EditRrealEstate, GetRealEstateList } from "../../../../store/SU/realEstateSlice";
import { Card, CardBody } from "../../../../components/card/card.jsx";
import Map from "../../../../components/Map/Map.js";
import useLookupNationality from "../../../../hooks/Lookups/use-Lookup-Nationality.js";
import ExtraInformationBuilderUpdate from "../../../ExtraInformation/ExtraInformationBuilder/Update/ExtraInformationBuilderUpdate.js";
import { AddRealEstateSchema } from "../../../ValidationForm/validationSchema.js";
import useGetRealEstateList from "../../../../hooks/SU/useGetRealEstateList.js";
import useAddUpdateExtraInformation from "../../../ExtraInformation/ExtraInformationBuilder/useAddUpdateExtraInformation.js";

function UpdateRealEstateForm({ RealEstateById, loading }) {
  const Navigate = useNavigate();
  const [extraInfoFormData, setExtraInfoFormData] = useState({});
  //////////////////////////// State For Map
  const [center, setCenter] = useState({
    lat: parseFloat(RealEstateById.latitude),
    lng: parseFloat(RealEstateById.longitude),
  });
  // const [center, setCenter] = useState({ lat: 24.7135517, lng: 46.6752957 });
  const [lat, setLat] = useState(center?.lat);
  const [lng, setLng] = useState(center?.lng);
  //////////////////////////// State For Map
  const { Organizations } = useGetOrganizations();
  const dataOrg = Organizations?.filter((f) => f.type === "150");
  const { t, i18n } = useTranslation();
  const { dataNATIONALITY } = useLookupNationality();
  const dispatch = useDispatch();
  const { AddUpdateExtraInformation } = useAddUpdateExtraInformation();
  const {RealEstateList} = useGetRealEstateList()

  //__________________________________________________

  const formik = useFormik({

    initialValues: {
      id: RealEstateById?.id,
      latitude: RealEstateById ? RealEstateById.latitude : 0,
      longitude: RealEstateById ? RealEstateById.longitude : 0,
      name: RealEstateById ? RealEstateById.name : "",
      name2: RealEstateById ? RealEstateById.name2 : "",
      code: RealEstateById ? RealEstateById.code : "",
      annual_rent: RealEstateById ? RealEstateById.annualRent : 0,
      watermeter: RealEstateById ? RealEstateById.watermeter : 0,
      gascounter: RealEstateById ? RealEstateById.gascounter : 0,
      electricitymeter: RealEstateById ? RealEstateById.electricitymeter : 0,
      attitudesmeter: RealEstateById ? RealEstateById.attitudesmeter : "",
      number_meter: RealEstateById ? RealEstateById.numberMeter : "",
      capacity: RealEstateById ? RealEstateById.capacity : "",
      parent_id:
        RealEstateById && RealEstateById.parentId === null
          ? "Main"
          : RealEstateById.parentId,
      description: RealEstateById ? RealEstateById.description : "",
      country: RealEstateById ? RealEstateById.country : "",
      address_line: RealEstateById ? RealEstateById.addressLine : "",
      town_or_city: RealEstateById ? RealEstateById.townOrCity : "",
      postal_code: RealEstateById ? RealEstateById.postalCode : "",
      region: RealEstateById ? RealEstateById.region : "",
      telephone_number: RealEstateById ? RealEstateById.telephoneNumber : "",
      extraInformationId: RealEstateById ? RealEstateById.extraInformation : "",
      realEstateTypeId: RealEstateById ? RealEstateById.realEstateTypeId : "",
      organizationId: RealEstateById ? RealEstateById.organizationId : "",
    },

    validationSchema: AddRealEstateSchema(t),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      values.latitude = lat.toString();
      values.longitude = lng.toString();
      let respons;
      if (extraInfoFormData?.values?.length > 0) {
        respons = await AddUpdateExtraInformation(extraInfoFormData);
      }

      if (respons === null) {
        CustomAlert({ action: "Error" });
        return;
      }
      await new Promise(resolve => setTimeout(resolve, 500));
     await dispatch(
        EditRrealEstate({
          id: RealEstateById?.id,
          extraInformationId: RealEstateById?.extraInformationId,
          realEstateTypeId: values.realEstateTypeId,
          organizationId: values.organizationId,
          latitude: values.latitude,
          longitude: values.longitude,
          name: values.name,
          name2: values.name2,
          code: values.code,
          annualRent: values.annual_rent,
          watermeter: values.watermeter,
          gascounter: values.gascounter,
          electricitymeter: values.electricitymeter,
          attitudesmeter: values.attitudesmeter,
          numberMeter: values.number_meter,
          capacity: values.capacity,
          parentId:  values.parent_id === 'Main' ? null : values.parent_id,
          description: values.description,
          country: values.country,
          AddressLine: values.address_line,
          TownOrCity: values.town_or_city,
          PostalCode: values.postal_code,
          region: values.region,
          TelephoneNumber: values.telephone_number,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.succeeded === true) {
            CustomAlert({ action: "Edit" });
            Navigate(-1, { replace: true });
          } else {
            CustomAlert({ action: "Error" });
            return;
          }
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  //__________________________________________________
  const { DynamicFormList } = useGetDynamicForm();
  const DynamicFormListType4 = DynamicFormList?.filter(
    (f) =>
      f.dynamicFormTypesId === 4 &&
      f.moduleId === "0a6c6b89-1d52-4eaa-8d2c-030c74b1f6e0"
  ).map((item) => ({
    value: item.id,
    label: (
      <>
        <i className={item.icon}></i>
        {i18n.language === "ar" ? item.name2 : item.name}{" "}
      </>
    ),
  }));
  //_____________________________________________________
  return (

      <Form onSubmit={formik.handleSubmit}>
        <>
          <div className="row">
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Arabic Name")}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="name2"
                      onChange={formik.handleChange}
                      value={formik.values.name2 || ""}
                      isInvalid={formik.touched.name2 && formik.errors.name2}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name2}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("English Name")}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name || ""}
                      isInvalid={formik.touched.name && formik.errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">رقم الوحدة</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      value={formik.values.code || ""}
                      onChange={formik.handleChange}
                      name="code"
                      isInvalid={formik.touched.code && formik.errors.code}
                    />
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Latitude")}</label>

                    <Form.Control
                      className="form-control"
                      type="text"
                      value={lat }
                      name="latitude"
                      onChange={(e) => setLat(parseFloat(e.target.value))}
                    />
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Longitude")}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      value={lng}
                      onChange={(e) => setLng(parseFloat(e.target.value))}
                      name="longitude"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-8">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Branch")}</label>
                    <Form.Select
                      className="form-select"
                      name="organizationId"
                      onChange={formik.handleChange}
                      value={formik.values.organizationId || ""}
                      isInvalid={
                        formik.touched.organizationId &&
                        formik.errors.organizationId
                      }
                    >
                      <option>
                        {i18n.language === "ar" ? "إختر" : "Choose"}
                      </option>
                      {Organizations &&
                        dataOrg.map((item, idx) => (
                          <option key={++idx} value={item.id}>
                            {i18n.language === "ar" ? item.name2 : item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.organizationId}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Type_Real_Estate")}</label>
                      <Select
                        classNamePrefix="react-select"
                        isSearchable={true}
                        options={DynamicFormListType4}
                        onChange={(option) =>
                          formik.setFieldValue("realEstateTypeId", option.value)
                        }
                        isDisabled
                        placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                        value={DynamicFormListType4?.find(
                          (f) => f.value === formik.values.realEstateTypeId
                        )}
                      />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Annual_Rent")}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      value={formik.values.annual_rent || ""}
                      onChange={formik.handleChange}
                      name="annual_rent"
                      // value={formik.values.longitude}
                      isInvalid={
                        formik.touched.annual_rent && formik.errors.annual_rent
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.annual_rent}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Water_Meter")}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      value={formik.values.watermeter || ""}
                      onChange={formik.handleChange}
                      name="watermeter"
                      isInvalid={
                        formik.touched.watermeter && formik.errors.watermeter
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.watermeter}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Gas_Counter")}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      value={formik.values.gascounter || ""}
                      onChange={formik.handleChange}
                      name="gascounter"
                      // value={formik.values.longitude}
                      isInvalid={
                        formik.touched.gascounter && formik.errors.gascounter
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.gascounter}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Electricity_Meter")}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      value={formik.values.electricitymeter || ""}
                      onChange={formik.handleChange}
                      name="electricitymeter"
                      isInvalid={
                        formik.touched.electricitymeter &&
                        formik.errors.electricitymeter
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.electricitymeter}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Attitudes_Meter")}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      value={formik.values.attitudesmeter || ""}
                      onChange={formik.handleChange}
                      name="attitudesmeter"
                      // value={formik.values.longitude}
                      isInvalid={
                        formik.touched.attitudesmeter &&
                        formik.errors.attitudesmeter
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.attitudesmeter}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="form-group mb-3">
                    <label className="form-label">{t('LandArea')}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      value={formik.values.number_meter || ""}
                      onChange={formik.handleChange}
                      name="number_meter"
                      isInvalid={
                        formik.touched.number_meter &&
                        formik.errors.number_meter
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.number_meter}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <Card>
                <CardBody>
                  {!isNaN(lat) && !isNaN(lng) && (
                    <Map
                      center={center}
                      lat={lat}
                      lng={lng}
                      setCenter={setCenter}
                      setLat={setLat}
                      setLng={setLng}
                    />
                 )} 
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label">{t("Capacity")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  value={formik.values.capacity || ""}
                  onChange={formik.handleChange}
                  name="capacity"
                  // value={formik.values.longitude}
                  isInvalid={formik.touched.capacity && formik.errors.capacity}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.capacity}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-3">
              <div className="form-group mb-3">
                <label className="form-label">الوحدة الأساسية</label>
                <Form.Select
                  className="form-select"
                  name="parent_id"
                  onChange={formik.handleChange}
                  value={formik.values.parent_id || ""}
                  isInvalid={
                    formik.touched.parent_id && formik.errors.parent_id
                  }
                >
                  <option value={null} key={null}>
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  <option value="Main">
                    {i18n.language === "ar" ? "رئيسي" : "Main"}
                  </option>
                  {RealEstateList &&
                    RealEstateList.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    ))}
                </Form.Select>
                {formik.errors.parent_id && formik.touched.parent_id && (
                  <div style={{ color: "red" }}>{formik.errors.parent_id}</div>
                )}
              </div>
            </div>
            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label">{t("Address")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="address_line"
                  onChange={formik.handleChange}
                  value={formik.values.address_line || ""}
                  isInvalid={
                    formik.touched.address_line && formik.errors.address_line
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.address_line}
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="form-group mb-3">
                <label className="form-label">{t("Country")}</label>
                {dataNATIONALITY?.find(
                  (f) => f?.value === formik?.values?.country
                ) && (
                  <Select
                    classNamePrefix="react-select"
                    isRtl
                    isSearchable={true}
                    options={dataNATIONALITY}
                    onChange={(option) =>
                      formik.setFieldValue("country", option.value)
                    }
                    placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                    defaultValue={dataNATIONALITY?.find(
                      (f) => f?.value === formik?.values?.country
                    )}
                  />
                )}

                {formik.errors.country && formik.touched.country && (
                  <div style={{ color: "red" }}>{formik.errors.country}</div>
                )}
              </div>
            </div>

            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label">{t("City")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="town_or_city"
                  onChange={formik.handleChange}
                  value={formik.values.town_or_city || ""}
                  isInvalid={
                    formik.touched.town_or_city && formik.errors.town_or_city
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.town_or_city}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label">{t("Postal Code")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="postal_code"
                  onChange={formik.handleChange}
                  value={formik.values.postal_code || ""}
                  isInvalid={
                    formik.touched.postal_code && formik.errors.postal_code
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.postal_code}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label">{t("Region")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="region"
                  onChange={formik.handleChange}
                  value={formik.values.region || ""}
                  isInvalid={formik.touched.region && formik.errors.region}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.region}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label">{t("Mobile Number")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="telephone_number"
                  onChange={formik.handleChange}
                  value={formik.values.telephone_number || ""}
                  isInvalid={
                    formik.touched.telephone_number &&
                    formik.errors.telephone_number
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.telephone_number}
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label className="form-label">{t("Note")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description || ""}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.description}
                </Form.Control.Feedback>
                <div></div>
              </div>
            </div>
          </div>

          <hr />
          <ExtraInformationBuilderUpdate
            extraInformationId={RealEstateById?.extraInformationId}
            referenceId={RealEstateById.id}
            setExtraInfoFormData={setExtraInfoFormData}
          />



          <div className="text-center">
          <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-2"></i>
                )}
                {t("Save")}
              </button>
            <button
              type="button"
              className=" btn me-1 btn-default mb-1"
              onClick={() => Navigate(-1, { replace: true })}
            >
              <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
            </button>

          </div>
        </>
      </Form>

  );
}

export default UpdateRealEstateForm;
