import React, { Fragment, useState } from "react";
import ExtraInformationBuilderAdd from "../../../ExtraInformationBuilder/Add/ExtraInformationBuilderAdd";
import useGetFormColumn from "../../../../../hooks/Forms/use-get-FColumn";
import { Form } from "react-bootstrap";
import useAddUpdateExtraInformation from "../../../ExtraInformationBuilder/useAddUpdateExtraInformation";
import CustomAlert from "../../../../../components/Alert/CustomAlert";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../components/Loader/Loader";
// import SkeletonForm3 from "../../../../../components/SkeletonLoader/SkeletonForm3";

const AddExtraRecord = ({ FormId, ReferenceId , setRefreshData}) => {
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const [extraInfoFormData, setExtraInfoFormData] = useState({});
  const { FormColumnList , loading} = useGetFormColumn(FormId);
  const { AddUpdateExtraInformation } = useAddUpdateExtraInformation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddExtraInfo = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    var respons = await AddUpdateExtraInformation(extraInfoFormData);
    if (respons === null) {
      CustomAlert({ action: "Error" });
      setIsSubmitting(false);
      return;
    } else {
      setIsSubmitting(false);
      CustomAlert({
        action: "Add",
        msg: "تم إضافة معلومات جديدة بنجاح",
      });
      setRefreshData(true)
      // window.location.reload();
    }
  };

  
  return (
    <Fragment>
      <Loader loading={loading}/>
    <Form onSubmit={handleAddExtraInfo}>
      <ExtraInformationBuilderAdd
        FormColumnList={FormColumnList}
        setExtraInfoFormData={setExtraInfoFormData}
        ReferenceId={ReferenceId}
      />
 
      <div className="text-end mt-2">
        <button
          type="submit"
          className="btn me-1 btn-theme mb-1"
          disabled={isSubmitting}
        >
          {
            isSubmitting ? <div className="spinner-border spinner-border-sm me-2"></div> :
          <i className="fas fa-lg fa-fw me-2 fa-plus"></i>
          }
           {t("Add")}
        </button>

        {/* <button
          type="button"
          className="btn me-1 btn-default mb-1"
          onClick={() => Navigate(-1, { replace: true })}
        >
          <i className="far fa-lg fa-fw me-2 fa-window-close"></i>
          {t("Cancel")}
        </button> */}
      </div>
    </Form> </Fragment>
  );
};

export default AddExtraRecord;
