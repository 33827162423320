import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "../../../../components/card/card";
import useGetPersons from "../../../../hooks/HR/use-get-persons";
  import Pagination from "../../../../components/Pagination/Pagination.jsx";

const AnalysisTable = () => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const { Employees, loading, error } = useGetPersons();


  //_________________________________________________________________________________


const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
   
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;

  const currentData = Employees
    ?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return b[sortBy] > a[sortBy] ? 1 : -1;
      }
    })
    .slice(indexOfFirstData, indexOfLastData);

    const handlePagination = (pageNumber) => setCurrentPage(pageNumber);
  
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active">تحليل الحضور</li>
          </ul>
          <h1 className="page-header mb-0">تحليل الحضور</h1>
        </div>
      </div>
      <Card>
        <CardBody>
          <div className="tab-content p-4">
            <div className="tab-pane fade show active" id="allTab">
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Code</th>
                      <th scope="col">Name</th>
                      <th scope="col">Work Time</th>
                      <th scope="col">Entry Time</th>
                      <th scope="col">End Time</th>
                      <th scope="col">Exit Time</th>
                      <th scope="col">Variance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Employees &&
                      currentData.map((emp , idx) => (
                        <tr key={++idx}>
                          <td className="align-middle">09-03-2023</td>
                          <td className="align-middle">{emp.employeeNumber}</td>
                          <td className="align-middle"> {i18n.language === "ar" ? emp.name2 : emp.name}</td>
                          <td className="align-middle">07:00</td>
                          <td className="align-middle">00:00</td>
                          <td className="align-middle">22:00</td>
                          <td className="align-middle">00:00</td>
                          <td className="align-middle">15:00</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Pagination
              filteredData={Employees}
              dataPerPage={dataPerPage}
              handlePagination={handlePagination}
              currentPage={currentPage}
            />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AnalysisTable;
