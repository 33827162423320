import { useFormik } from 'formik';
import React from 'react'
import { useEffect } from 'react';
import Form from "react-bootstrap/Form";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import {
    Card,
    CardBody,
  } from "../../../../components/card/card.jsx";
import useGetOrganizations from '../../../../hooks/HR/use-get-organizations.js';
import usePositionDetails from '../../../../hooks/HRSetting/use-position-details.js';
// import { getLocations } from '../../../../store/locationSlice.js';
import { editPosition } from '../../../../store/HR/HRSetting/positionSlice.js';
import { formPosition } from '../../../ValidationForm/validationSchema.js';
import  Loader  from "../../../../components/Loader/Loader.js";
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx';

function EditPosition() {
  const Navigate = useNavigate();

  const { t, i18n } = useTranslation();
  
  const dispatch = useDispatch();

  const { Organizations } = useGetOrganizations();
  const {Jobs  } = useSelector((state) => state.Jobs);
  ///
  const { Locations } = useSelector((state) => state.Locations);

  const { Position ,loading } = usePositionDetails();
  console.log(Position);


  const formik = useFormik({
    initialValues: {
      jobId: Position ? Position.jobId :"",
      organizationId: Position ? Position.organizationId :"",
      locationId: Position ? Position.locationId :"",
      name: Position ? Position.name :"",
      name2: Position ? Position.name2 :"",
      comments: Position ? Position.comments :""
    },
    enableReinitialize:true,
    validationSchema: formPosition(t),

    onSubmit: (values, { resetForm }) => {
      console.log(values );

      dispatch(
        editPosition({
          id: Position.id,
          jobId: values.jobId,
          organizationId: values.organizationId,
          locationId: values.locationId,
          name: values.name,
          name2: values.name2,
          comments: values.comments
        })
      )
        .unwrap()
        .then(() => {
          
          CustomAlert({ action: "Add" });
          
          Navigate (-1, {replace:true})
        })
        .catch((error) => {
          alert(error);
          CustomAlert({ action: "Error" });
        });
    },
  });
  return (
    <>
    <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/"> {t("Home")}</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/" onClick={()=> Navigate(-1 , {replace:true})}> {t("Positions")}</a>
            </li>
            <li className="breadcrumb-item active">{t("Button.Edit")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Edit position")}</h1>
        </div>

       
      </div>

      
      <Card>
      <Loader loading={loading}/>
        <CardBody>
        <Form onSubmit={formik.handleSubmit} >
      <div className="row">
            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label
                  className="form-label"
                  htmlFor="exampleFormControlSelect1"
                >
{t("Arabic Name")}
                </label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name2"
                  onChange={formik.handleChange}
                  value={formik.values.name2 || " "}
                  isInvalid={!!formik.errors.name2}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name2}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group mb-3">
                <label
                  className="form-label"
                  htmlFor="exampleFormControlSelect1"
                >
                  {t("English Name")}
                </label>
                <Form.Control
                  className="form-control"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name || " "}
                  isInvalid={!!formik.errors.name} 
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlSelect1">
            {t("Department")}
            </label>
            <Form.Select
              className="form-select"
              id="exampleFormControlSelect1"
              name="organizationId"
              onChange={formik.handleChange}
              value={formik.values.organizationId || " "}
              isInvalid={!!formik.errors.organizationId}
            >
              <option value={null}>
                  {i18n.language === "ar" ? 'إختر' : 'Choose'}
                </option>
              { 
              Organizations.map((item) => (
                <option key={item.id} value={item.id}>
                {i18n.language === "ar" ? item.name2 : item.name}
              </option>
           
            ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.organizationId}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlSelect1">
            {t("Job")}
            </label>
            <Form.Select
              className="form-select"
              id="exampleFormControlSelect1"
              name="jobId"
              onChange={formik.handleChange}
              value={formik.values.jobId || " "}
              isInvalid={!!formik.errors.jobId}
            >
              <option key={null}>
                  {i18n.language === "ar" ? 'إختر' : 'Choose'}
                </option>
              {Jobs.map((item) => (
              <option key={item.id} value={item.id}>
              {i18n.language === "ar" ? item.name2 : item.name}
            </option>
         
          ))}
       
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.jobId}
            </Form.Control.Feedback>
          </div>
        </div>  
      </div>
      
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlSelect1">
            {t("Location")}
            </label>
            <Form.Select
              className="form-select"
              id="exampleFormControlSelect1"
              name="locationId"
              onChange={formik.handleChange}
              value={formik.values.locationId || " "}
              isInvalid={!!formik.errors.locationId}
            >

<option key={null}>
                  {i18n.language === "ar" ? 'إختر' : 'Choose'}
                </option>
             
             {Locations.map((item) => (
              <option key={item.id} value={item.id}>
              {i18n.language === "ar" ? item.name2 : item.name}
            </option>
         
          ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.locationId}
            </Form.Control.Feedback>
          </div>
        </div>

        {/* <div className="col-xl-6">
        <div className="form-group mb-3">
          <label className="form-label" htmlFor="exampleFormControlSelect1">
            Date
          </label>
          <Form.Control
            type="datetime-local"
            id="START_DATE"
            className="form-control"
            // name="START_DATE"
            // onChange={formik.handleChange}
            // value={formik.values.START_DATE}
            // isInvalid={!!formik.errors.START_DATE}
          />
         
          <div>
        </div>
        </div>
      </div> */}

      </div>

      <div className="row">
      <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlSelect1">
            {t("Note")}
            </label>
            <Form.Control
              className="form-control"
              as="textarea"
              rows={3}
              name="comments"
              onChange={formik.handleChange}
              value={formik.values.comments || " "}
              isInvalid={!!formik.errors.comments}
            />

            <div></div>
          </div>
        </div>
     

    </div>
    <div className="text-center">
    <button type="submit" className="btn me-1 btn-theme mb-1">
      <i className="fa fa-save fa-fw me-1"></i> {t("Save")} 
      </button>
   
      
      <button type="button" className=" btn me-1 btn-default mb-1" onClick={()=> Navigate (-1 , {replace:true}) } >
      <i className="fa fa-power-off fa-fw me-1"></i>  {t('Cancel')}
        </button>
     
    </div>
      
    </Form>
        </CardBody>
      </Card>
   
  </>
  )
}

export default EditPosition;