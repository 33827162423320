import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";

const initialState = { Grades: [], loading: false, error: null, Grade: [] };

export const getGrades = createAsyncThunk(
  "Grades/getGrades",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
 
   try {
      // const data = await getData( "HRSetting/GetGradeList" , thunkAPI);
      const {data} = await ntxAPI.get("HRSetting/GetGradeList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const insertGrade = createAsyncThunk(
  "Grades/insertGrade",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData( "HRSetting/AddGrade" , item);
      const {data} = await ntxAPI.post("HRSetting/AddGrade" , item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const editGrade = createAsyncThunk(
  "Grades/editGrade ",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    // console.log(item);
  
    try {
      // const data = await postData( "HRSetting/UpdateGrade" , item);
      const {data} = await ntxAPI.post("HRSetting/UpdateGrade" , item);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getGrade = createAsyncThunk(
  "Grades/getGrade",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {

      // const data = await getData(`HRSetting/GetGradeById?Id=${id}`);
      const {data} = await ntxAPI.get(`HRSetting/GetGradeById?Id=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);




const gradeSlice = createSlice({
  name: "Grades",
  initialState,
  reducers: {},
  extraReducers: {
    /////// get
    [getGrades.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getGrades.fulfilled]: (state, action) => {
      state.loading = false;
      state.Grades = action.payload;
    },
    [getGrades.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // get one grade by id
    [getGrade.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getGrade.fulfilled]: (state, action) => {
      state.loading = false;
      state.Grade = action.payload;
    },
    [getGrade.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    ///////insert
    [insertGrade.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [insertGrade.fulfilled]: (state, action) => {
      state.loading = false;
      state.Grades.push(action.payload);
    },
    [insertGrade.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    /////Edit
    [editGrade.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [editGrade.fulfilled]: (state, action) => {
      state.loading = false;
      state.Grade = action.payload;
    },
    [editGrade.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default gradeSlice.reducer;
