import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../../components/card/card";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import Pagination from "../../../../components/Pagination/Pagination";
import useLookupModule from "../../../../hooks/Lookups/use-Lookup-Module";
import { GetResponsibility } from "../../../../store/NTX/Security/responsibilitySlice";
import AddResponsibility from "./AddResponsibility";
import MedModalComponent from "../../../../components/Modal/MedModalComponent";
import Loader from "../../../../components/Loader/Loader";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";

function AuthResponsibility() {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(5);

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { ResponsibilityList , loading } = useSelector((state) => state.Responsibility);

  useEffect(() => {
    dispatch(GetResponsibility());
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = ResponsibilityList?.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  const [selectedValue, setSelectedValue] = useState("");

  const { Module } = useLookupModule();
  useEffect(() => {
    if (Module?.length) {
      setSelectedValue(Module[0].id);
    }
  }, [Module]);

  function handleSelectChange(event) {
    setSelectedValue(event.target.value);
  }

  //filter data by <select>
  const filteredDataByModules = ResponsibilityList?.filter(
    (item) => item.moduleId === selectedValue
  );


    //_______________
    var BcrumbList = [
      {
        name: "Home",
        link: "/",
      },
      {
        name: "Responsibilities",
        link: null,
      },
      // {
      //   name: "User Responsibility",
      //   link: null,
      // },
    ];

  return (
    <>

      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Responsibilities" />

      <Card>
        <Loader loading={loading}/>
        <div className="tab-content p-4">
          <div className="tab-pane fade show active">
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-4">
                  <div className="flex-fill position-relative">
                    <div className="input-group mb-4">
                      <select
                        className="btn btn-outline-default p-0 dropdown-toggle"
                        id="my-select"
                        value={selectedValue}
                        onChange={handleSelectChange}
                      >
                        {Module &&
                          Module.map((item) => (
                            <option
                              value={item.id}
                              key={item.id}
                              className="dropdown-item"
                            >
                              {item.name2}
                            </option>
                          ))}
                      </select>

                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: "10px" }}
                          >
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            placeholder={t("Search")}
                            value={search}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5 col-md-6 text-end">
                <a
                  href="#/"
                  className="btn btn-outline-theme"
                  data-bs-toggle="modal"
                  data-bs-target="#modalAddResponsibility"
                >
                  <i className="fa fa-plus-circle fa-fw me-1"></i>
                  {t("Add Responsibility")}
                </a>
              </div>
            </div>

            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table ">
                <thead className="table-dark">
                  <tr>
                    {/* <th scope="col" onClick={() => handleSort("id")}>
                      الرقم
                    </th> */}
                    <th scope="col" onClick={() => handleSort("name")}>
                      {t("Name")}
                    </th>

                    <th
                      scope="col"
                      // onClick={() => handleSort("name")}
                    >
                      {t("Icon")}
                    </th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {/* <Loading loading={loading} error={error}> */}
                  {filteredDataByModules.map((item, index) => (
                    <tr key={item.id}>
                      {/* <td className="align-middle">{++index}</td> */}
                      <td className="align-middle">
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </td>

                      <td className="align-middle">
                        <i className={item.icon}></i>
                      </td>

                      <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            onClick={() => Navigate(`${item.id}/Edit`)}
                            className="btn btn-outline-lime"
                          >
                            <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                            {t("Button.Edit")}
                          </button>

                          <button
                            type="button"
                            onClick={() =>
                              Navigate(`${item.id}/ResponsibilityFunction`)
                            }
                            className="btn btn-outline-lime"
                          >
                            <i class="fas fa-lg fa-fw me-2 fa-tasks"></i>
                            Finction
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {/* </Loading> */}
                </tbody>
              </table>
            </div>
            {/* <Pagination
            filteredData={}
            // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
            dataPerPage={dataPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
          /> */}

            {/* </CardBody> */}
          </div>
        </div>
      </Card>

      <MedModalComponent
        title= {t("Add Responsibility")}
        id="modalAddResponsibility"
      >
        <AddResponsibility />
      </MedModalComponent>
    </>
  );
}

export default AuthResponsibility;
