import React, { useState } from "react";

import defaultImage from "../../assets/favicon1.ico";

const PersonImage = ({ file }) => {
  // console.log(file);

  const [preview, setPreview] = useState(file);

  if (file && file instanceof Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }

  


  return (
    <>
{/* <div
  style={{
    width: "240px",
    height: "275px",
    backgroundImage: `url(${preview})`,
    backgroundSize : "contain",
    backgroundRepeat : 'no-repeat',
    backgroundPosition : 'center',
    // borderRadius: "50%"
  }}
>

</div> */}

<div className="mb-3" style={{padding:'5px' , overflow: 'hidden' , position:'relative'}}>
  <img src={preview} alt="" className="img-fluid" />
</div>
    </>
    // <div
    //   style={{
    //     maxWidth: "300px",
    //     maxHeight: "275px",
    //     overflow: "hidden",
    //     // display: "flex",
    //     // justifyContent: "center",
    //     // alignItems: "center",
    //   }}
    // >
    //   {file !== undefined ? ( 
    //     <img 
    //       style={{ width: "100%", height: "100%" , objectFit :"cover" }}
    //       src={preview}
    //       alt=""
    //     />
    //   ) : (
    //     <img
    //       src={"../../../assets/img/user/manEmp.png"}
    //       alt=""
    //       style={{ maxWidth: "100%", height: "auto" }}
    //       className="rounded-circle"
    //     />
    //   )}
    // </div>
  );
};

export default PersonImage;
