import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import CustomAlert from "../../../components/Alert/CustomAlert";
import {
  GetEquipmentsAssignmentById,
  GetEquipmentsAssignmentList,
  UpdatEquipmentsAssignment,
} from "../../../store/FLEET/EquipmentsAssignment/equipmentsAssignmentSlice";
import { useFormik } from "formik";
import { GetNtxPeoplesList } from "../../../store/NTX/Peoples/peoplesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EquipmentsAssignmentSchema } from "../../ValidationForm/validationSchema";

interface props {
  ID: string;
}

const EditEquipmentsAssignment: React.FC<props> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetNtxPeoplesList());
  }, []);

  useEffect(() => {
    if (props.ID) {
      dispatch(GetEquipmentsAssignmentById(props.ID));
    }
  }, [props.ID]);

  const { EquipmentsAssignationById } = useAppSelector(
    (state) => state.EquipmentsAssignment
  );

  const { PeoplesList } = useAppSelector((state) => state.Peoples);

  const formik = useFormik({
    initialValues: {
      id: EquipmentsAssignationById ? EquipmentsAssignationById.id : "",
      fleetEquipmentsId: EquipmentsAssignationById
        ? EquipmentsAssignationById.fleetEquipmentsId
        : "",
      peopleId: EquipmentsAssignationById
        ? EquipmentsAssignationById.peopleId
        : "",
      deliveryDateFrom: EquipmentsAssignationById
        ? EquipmentsAssignationById.deliveryDateFrom
        : "",
      deliveryDateTo: EquipmentsAssignationById
        ? EquipmentsAssignationById.deliveryDateTo
        : "",
      description: EquipmentsAssignationById
        ? EquipmentsAssignationById.description
        : "",
    },
    enableReinitialize: true,
    validationSchema: EquipmentsAssignmentSchema(t),
    onSubmit: (values) => {
      console.log(values);

      dispatch(
        UpdatEquipmentsAssignment({
          id: values.id,
          fleetEquipmentsId: values.fleetEquipmentsId,
          peopleId: values.peopleId,
          deliveryDateFrom: values.deliveryDateFrom,
          deliveryDateTo: values.deliveryDateTo,
          description: values.description,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            document.getElementById("closeModalForm")?.click();
            CustomAlert({ action: "Edit" });
            dispatch(GetEquipmentsAssignmentList());
            dispatch(GetEquipmentsAssignmentById(props.ID));
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  console.log(formik.values);

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label"> {t("DriverName")}</label>
              <Form.Select
                className="form-select"
                name="peopleId"
                onChange={formik.handleChange}
                value={formik.values.peopleId || ""}
                isInvalid={
                  !!(formik.touched.peopleId && formik.errors.peopleId)
                }
              >
                <option value="">
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {PeoplesList &&
                  PeoplesList?.filter((f) => f.typeCode === "DRIVERS").map(
                    (item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    )
                  )}
              </Form.Select>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("Start Date")}</label>
              <Form.Control
                className="form-control"
                type="date"
                name="deliveryDateFrom"
                onChange={formik.handleChange}
                value={formik.values.deliveryDateFrom}
                isInvalid={
                  !!(
                    formik.touched.deliveryDateFrom &&
                    formik.errors.deliveryDateFrom
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.deliveryDateFrom}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("End_Date")}</label>
              <Form.Control
                className="form-control"
                type="date"
                name="deliveryDateTo"
                onChange={formik.handleChange}
                value={formik.values.deliveryDateTo}
                isInvalid={
                  !!(
                    formik.touched.deliveryDateTo &&
                    formik.errors.deliveryDateTo
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.deliveryDateTo}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="form-group mb-3">
              <label className="form-label">{t("Note")}</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={3}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t("Save")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditEquipmentsAssignment;
