import { useFormik } from "formik";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
// import { AddDynamicForm } from "../../../store/FlexValue/Form/formSlice";
// import { DynamicFormSchema } from "../../ValidationForm/validationSchema";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../components/card/card";
import { GetFormColumn } from "../../../store/NTX/FlexValue/Form/FormColumnSlice";
// import { GetFlexValue } from "../../../store/FlexValue/FlixValueSlice";
// import axios from "axios";
import variables from "../../../APIURL/variables";
import {
  GetAmeCondition,
  insertAmeCondition,
} from "../../../store/Ame/ameSlice";
import ConditionList from "./ConditionList";
import * as Yup from "yup";
import Loader from "../../../components/Loader/Loader";
import CustomAlert from "../../../components/Alert/CustomAlert.tsx";
import { GetTableData } from "../../../store/lookupSlice";
import { ntxAPI } from "../../../API/axios-custom";

// valiedation scheme
const validationSchema = Yup.object().shape({
  name2: Yup.string().required(),

  name: Yup.string().required(),

  dynamicFormColumnID: Yup.string()
    .required()
    .notOneOf(["إختر", "Choose"], "Required"),

  // param1: Yup.string()
  // .required(),

  includeLowerLimit: Yup.string().when("validationType", {
    is: (val) => val === "TIME" || val === "DATE" || val === "NUMBER",
    then:()=> Yup.string()
      .required("Please select an option")
      .notOneOf(["إختر", "Choose"], "Required"),
    // otherwise: Yup.string(),
  }),

  // .required(() => t("Required")),
});

const AddAmeCondition = () => {
  const [dynamicData, setDynamicData] = useState([]);
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  // GetFormColumn By DynamicFormId
  useEffect(() => {
    dispatch(GetFormColumn(id));
  }, [dispatch, id]);
  const { FormColumnList, loading } = useSelector((state) => state.FormColumns);
  console.log(FormColumnList);
  // Filter the data to only include items with a certain property
  const filteredData = FormColumnList?.columnList?.filter(
    (item) => !["TEXT", "ATTACHED", "NOTE"].includes(item.validationType)
  );
  console.log(filteredData);
  //

  const formik = useFormik({
    initialValues: {
      formatType: null, // for handle with show and hide elements only and validation Dynamic
      validationType: null, // for handle with show and hide elements only and validation Dynamic

      CheckBoolean: false,
      multiSelect: "",
      param1: "",
      param2: "",
      //
      name: "",
      name2: "",
      includeLowerLimit: "",
      includeUpperLimit: "",
      valueType: "",
      parameterOne: "",
      parameterTwo: "",
      description: "",
      dynamicFormId: "",
      dynamicFormColumnID: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values);

      dispatch(
        insertAmeCondition({
          name: formik.values.name,
          name2: formik.values.name2,
          includeLowerLimit: formik.values.includeLowerLimit || "E",
          includeUpperLimit: formik.values.includeUpperLimit || null,
          valueType: formik.values.formatType, //  اذا كانت null  ماذا نحفظ

          parameterOne:
            (formik.values.formatType === "D") &
            (formik.values.validationType === null)
              ? formik.values.multiSelect
              : (formik.values.formatType === "S") &
                (formik.values.validationType === "BOOLEAN")
              ? formik.values.CheckBoolean === true
                ? "1"
                : "0"
              : formik.values.param1.toString(),
          parameterTwo:
            formik.values.includeUpperLimit === ""
              ? ""
              : formik.values.param2.toString(),
          description: "", //
          dynamicFormId: id,
          dynamicFormColumnID: formik.values.dynamicFormColumnID,
        })
      )
        .unwrap()
        .then(() => {
          resetForm();
          CustomAlert({ action: "Add" });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          console.log(error);
          CustomAlert({ action: "Error" });
        });
    },
  });

  function handleSelectChange(e) {
    const selectedOption = e.target.selectedOptions[0];
    console.log(selectedOption);
    const selectedValidationType = selectedOption.dataset.validationType;
    const selectedformatType = selectedOption.dataset.formatType;
    const selectedflexValueSetId = selectedOption.dataset.flexValueSetId;
    const selectedtableId = selectedOption.dataset.tableId;
    const FormColumnID = e.target.value;

    formik.setFieldValue("validationType", selectedValidationType || null);
    formik.setFieldValue("formatType", selectedformatType || null);
    formik.setFieldValue("dynamicFormColumnID", FormColumnID);

    let FlexValueSetId = selectedflexValueSetId;
     let TableId = selectedtableId

    if (selectedformatType === "D") {
      ntxAPI
        .get(
          variables.API_URL +
            `FlexValueSet/GetFlexValueList?FlexValueSetId=${FlexValueSetId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.Token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          let transformedData = response.data.map((item) => ({
            value: item.id,
            label: i18n.language === "ar" ? item.name2 : item.name,
          }));
          setDynamicData(transformedData);
          console.log(transformedData);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (selectedformatType === "T") {
      dispatch(GetTableData(TableId)).unwrap()
      .then((res) => {
        // console.log(res);
          let transformedData2 = res.map((item) => ({
            value: item.id,
            label: i18n.language === "ar" ? item.name2 : item.name,
          }));
          setDynamicData(transformedData2);
          console.log(transformedData2);
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      setDynamicData([]);
    }
  }

  console.log(formik.values.formatType, formik.values.validationType);

  // handel multiSelect to this data ship (value1,value2,value3,value4)
  const handleMultiSelectChange = (selectedOptions) => {
    const formattedOptions = selectedOptions
      .map((option) => option.value)
      .join(",");
    formik.setFieldValue("multiSelect", formattedOptions);
  };

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item">
              <Link to="/Ame/RequestSetting" replace>
                {t("Requests settings")}
              </Link>
            </li>
            <li className="breadcrumb-item active">{t("Conditions")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Conditions")}</h1>
        </div>
      </div>

      <Card>
        <Loader loading={loading} />
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Arabic Name")}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="name2"
                      onChange={formik.handleChange}
                      value={formik.values.name2 || ""}
                      isInvalid={formik.touched.name2 && formik.errors.name2}
                    />
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("English Name")}</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name || ""}
                      isInvalid={formik.touched.name && formik.errors.name}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Field")}</label>
                    <Form.Select
                      className="form-select"
                      name="dynamicFormColumnID"
                      onChange={handleSelectChange}
                      isInvalid={
                        formik.touched.dynamicFormColumnID &&
                        formik.errors.dynamicFormColumnID
                      }
                    >
                      <option value="" key={null}>
                        {i18n.language === "ar" ? "إختر" : "Choose"}
                      </option>
                      {filteredData &&
                        filteredData.map((item) => (
                          <option
                            key={item.id}
                            value={item.id}
                            data-flex-value-set-id={item.flexValueSetId}
                            data-validation-type={item.validationType}
                            data-format-type={item.formatType}
                            data-table-id={item.tableId}
                          >
                            {i18n.language === "ar" ? item.lable2 : item.lable}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                </div>
              </div>

              {(formik.values.validationType === null) &
              (formik.values.formatType === null) ? (
                ""
              ) : (
                <div className="row">
                  {formik.values.validationType === "BOOLEAN" ? (
                    <div className="form-group mb-3">
                      <label className="form-label">Boolean</label>
                      <Form.Check
                        style={{ margin: "10px 20px" }}
                        type="switch"
                        id="custom-switch"
                        label=""
                        name="CheckBoolean"
                        onChange={formik.handleChange}
                        value={formik.values.CheckBoolean}
                        isInvalid={
                          formik.touched.CheckBoolean &&
                          formik.errors.CheckBoolean
                        }
                      />
                    </div>
                  ) : (formik.values.validationType === null) &
                      (formik.values.formatType === "D") ||
                    formik.values.formatType === "T" ? (
                    <div className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">multiSelect</label>
                        <Select
                          isSearchable
                          isMulti
                          options={dynamicData}
                          classNamePrefix="react-select"
                          name="multiSelect"
                          onChange={handleMultiSelectChange}
                          required
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-xl-6">
                        <div className="form-group mb-3">
                          <label className="form-label">{t("Value")}</label>
                          <Form.Select
                            className="form-select"
                            name="includeLowerLimit"
                            onChange={formik.handleChange}
                            // onChange={handleSelectChange}
                            value={formik.values.includeLowerLimit}
                            isInvalid={!!formik.errors.includeLowerLimit}
                          >
                            <option key={null} value={null}>
                              {i18n.language === "ar" ? "إختر" : "Choose"}
                            </option>
                            <option value="E">Is Equal</option>
                            <option value="G">Greater Than</option>
                            <option value="GE">Greater Than Or Equal</option>
                          </Form.Select>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group mb-3">
                          <label className="form-label">
                            {t("The first variable ")}
                          </label>
                          <Form.Control
                            className="form-control"
                            type={
                              formik.values.validationType === "NUMBER"
                                ? "number"
                                : formik.values.validationType === "DATE"
                                ? "date"
                                : "time"
                            }
                            name="param1"
                            onChange={formik.handleChange}
                            value={formik.values.param1}
                            isInvalid={
                              formik.touched.param1 && formik.errors.param1
                            }
                          />
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group mb-3">
                          <label className="form-label">{t("Value")}</label>
                          <Form.Select
                            className="form-select"
                            name="includeUpperLimit"
                            onChange={formik.handleChange}
                            // onChange={handleMultiSelectChange}
                            value={formik.values.includeUpperLimit}
                            isInvalid={
                              formik.touched.includeLowerLimit &&
                              formik.errors.includeUpperLimit
                            }
                          >
                            <option value={""}>
                              {i18n.language === "ar" ? "إختر" : "Choose"}
                            </option>
                            <option value="L">Less Than</option>
                            <option value="LE">Less Than Or Equal</option>
                          </Form.Select>
                        </div>
                      </div>
                      {formik.values.includeUpperLimit !== "" ? (
                        <div className="col-xl-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              {t("The Second variable ")}
                            </label>
                            <Form.Control
                              className="form-control"
                              type={
                                formik.values.validationType === "NUMBER"
                                  ? "number"
                                  : formik.values.validationType === "DATE"
                                  ? "date"
                                  : "time"
                              }
                              name="param2"
                              onChange={formik.handleChange}
                              value={formik.values.param2}
                              isInvalid={
                                formik.touched.param2 && formik.errors.param2
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-outline-theme mb-1"
                id="SaveSubmit"
              >
                <i className="fas fa-plus fa-fw me-1"></i>{" "}
                {formik.isSubmitting ? "..." : t("Add")}
              </button>

              <button
                type="button"
                className=" btn me-1 btn-outline-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>

          <hr className="mb-4" />
          <div className="row">
            <div className="col-lg-12">
              <ConditionList />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AddAmeCondition;
