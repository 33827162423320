import React, { Fragment, useState } from "react";
import ExtraInformationBuilderUpdate from "../../../ExtraInformationBuilder/Update/ExtraInformationBuilderUpdate";
import useAddUpdateExtraInformation from "../../../ExtraInformationBuilder/useAddUpdateExtraInformation";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomAlert from "../../../../../components/Alert/CustomAlert";
import Loader from "../../../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import ConfirmDeleteDialog from "../../../../../components/Alert/ConfirmDeleteDialog";
import { useDispatch } from "react-redux";
import { ntxAPI } from "../../../../../API/axios-custom";

const UpdateExtraRecord = (props) => {
  const { extraInformationId, ReferenceId , setRefreshData } = props;

  const { t } = useTranslation();

  const [extraInfoFormData, setExtraInfoFormData] = useState({});
  const { AddUpdateExtraInformation } = useAddUpdateExtraInformation();
  const { loading } = useSelector((state) => state.ExtraInformation);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddExtraInfo = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    var respons = await AddUpdateExtraInformation(extraInfoFormData);
    if (respons === null) {
      setIsSubmitting(false);
      CustomAlert({ action: "Error" });
      return;
    } else {
      setIsSubmitting(false);
      CustomAlert({
        action: "Edit",
        msg: "تم تحديث البيانات بنجاح",
      });
    }
  };

  // Handle Delete Record
  const handleDeleteRecord = async (id) => {
    try {
      const { data } = await ntxAPI.post(`ExtraInformation/DeleteExtraInformation?ExtraInformationId=${id}`);
      if (data?.succeeded === true) {
        CustomAlert({ action: "Delete" , msg:"تم حذف السجل بنجـاح" });
        setRefreshData(true)
      } else {
        CustomAlert({ action: "Error" });
        return null;
      }
    } catch (err) {
      CustomAlert({ action: "Error" });
      return null;
    }
  };

  return (
    <Fragment>
      <Loader loading={loading} />
      <Form onSubmit={handleAddExtraInfo}>
        <ExtraInformationBuilderUpdate
          extraInformationId={extraInformationId}
          setExtraInfoFormData={setExtraInfoFormData}
          referenceId={ReferenceId}
        />
        <div className="text-end mt-2">
          <button
            type="submit"
            className="btn me-1 btn-theme "
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-1"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t("Save")}
          </button>

          <ConfirmDeleteDialog
            onConfirm={() => handleDeleteRecord(extraInformationId)}
            // title="هل انت متأكد من حذف هذا السجـل ؟" // لا يتم حذف الحقل تقريبا ؟؟؟
            message="هل انت متأكد من حذف هذا السجـل ؟" // لا يتم حذف الحقل تقريبا ؟؟؟
            // message="This action cannot be undone."
            // iconType={2}
          />
        </div>
      </Form>
    </Fragment>
  );
};

export default UpdateExtraRecord;
