import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import CustomAlert from "../../../../../components/Alert/CustomAlert.tsx";
import { editEmployee } from "../../../../../store/HR/Person/employeeSlice";
import { EditPersonSchema } from "../../../../ValidationForm/validationSchema";
import { useDispatch } from "react-redux";
import ExtraInformationBuilderUpdate from "../../../../ExtraInformation/ExtraInformationBuilder/Update/ExtraInformationBuilderUpdate";
import EditEmployees from "./EditEmployees";
import useEmpDetails from "../../../../../hooks/HR/use-employee-details.js";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "../../../../../components/card/card.jsx";
import SkeletonForm from "../../../../../components/SkeletonLoader/SkeletonForm.js";
import useAddUpdateExtraInformation from "../../../../ExtraInformation/ExtraInformationBuilder/useAddUpdateExtraInformation.js";
import { GetDynamicFormColumnListByTableId } from "../../../../../store/NTX/FlexValue/Form/FormColumnSlice.js";
import { useSelector } from "react-redux";
import ExtraInformationBuilderAdd from "../../../../ExtraInformation/ExtraInformationBuilder/Add/ExtraInformationBuilderAdd.js";

const UpdatePerson = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { AddUpdateExtraInformation } = useAddUpdateExtraInformation();
  const [extraInfoFormData, setExtraInfoFormData] = useState({});
  const { loading: dataLoading, error: DataRrror, Employee } = useEmpDetails();

  //__________ جلب حقول المعلومات الإضافية ان وجدت __________
  const TableId = "cd5aa419-0e0b-48fe-a66f-f004d97964a2"; // جدول الموظفين id
  useEffect(() => {
    if (Employee?.extraInformationId === null) {
      dispatch(GetDynamicFormColumnListByTableId(TableId));
    }
  }, [Employee, dispatch]);
  const { FormColumnByTableIdList } = useSelector((state) => state.FormColumns);
  console.log(FormColumnByTableIdList);
  //_________________________________________________________

  const formik = useFormik({
    initialValues: {
      id: Employee ? Employee.id : null,
      employeeNumber: Employee ? Employee.employeeNumber : "",
      NAME2: Employee ? Employee.name2 : "",
      NAME: Employee ? Employee.name : "",
      NATIONAL_IDENTIFIER: Employee ? Employee?.nationalIdentifier : "",
      START_DATE: Employee ? Employee?.startDate : "",
      DATE_OF_BIRTH: Employee ? Employee?.dateOfBirth : "",
      MARITAL_STATUS: Employee ? Employee?.maritalStatus : "",
      GENDER: Employee ? Employee?.gender : "",
      NATIONALITY: Employee ? Employee?.nationality : "",
      TITLE: Employee ? Employee?.title : "",
      COUNTRY_OF_BIRTH: Employee ? Employee?.countryOfBirth : "",
      TOWN_OF_BIRTH: Employee ? Employee?.townOfBirth : "",

      BankId: Employee ? Employee?.bankId : "",
      IBan: Employee ? Employee?.iBan : "",

      Religion: Employee ? Employee?.religion : "",
      RotationPlanId: Employee ? Employee?.rotationPlanId : "",
      AnnualLeave: Employee ? Employee?.annualLeave : 0,
      Person_Img: Employee ? Employee?.personImage?.filePath : "",

      Is_Update_IMG: false,
    },
    enableReinitialize: true,
    validationSchema: EditPersonSchema(t),
    onSubmit: async (values) => {
      // console.log(values);

      const formData = new FormData();
      formData.append("Id", values.id);
      // formData.append("EmployeeNumber", values.employeeNumber);
      formData.append("Name2", values.NAME2);
      formData.append("Name", values.NAME);
      formData.append("NationalIdentifier", values.NATIONAL_IDENTIFIER);
      formData.append("StartDate", values.START_DATE);
      formData.append("DateOfBirth", values.DATE_OF_BIRTH);
      formData.append("MaritalStatus", values.MARITAL_STATUS);
      formData.append("Gender", values.GENDER);
      formData.append("Nationality", values.NATIONALITY);
      formData.append("Title", values.TITLE);
      formData.append("CountryOfBirth", values.COUNTRY_OF_BIRTH);
      formData.append("TownOfBirth", values.TOWN_OF_BIRTH);
      formData.append("PERSON_IMG", values.Person_Img);
      formData.append("IBan", values.IBan);
      formData.append("Religion", values.Religion);
      formData.append(
        "AnnualLeave",
        values.AnnualLeave === null ? "" : values.AnnualLeave
      );
      formData.append("Religion", values.Religion);
      formData.append("BankId", values.BankId === null ? "" : values.BankId);
      formData.append(
        "RotationPlanId",
        values.RotationPlanId === null ? "" : values.RotationPlanId
      );
      formData.append("Is_Update_IMG", values.Is_Update_IMG);


      
      let respons;
      if (extraInfoFormData?.values?.length > 0) {
        respons = await AddUpdateExtraInformation(extraInfoFormData);
      }
      if (respons === null) {
        CustomAlert({ action: "Error" });
        return;
      }
      // debugger
      formData.append("ExtraInformationId", respons);
      dispatch(editEmployee(formData))
        .unwrap()
        .then((res) => {
          Navigate("/HR/Employees", { replace: true });
          // console.log(res);
          CustomAlert({ action: "Edit" });
          formik.resetForm();
        })
        .catch((error) => {
          // console.log(error);
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/" onClick={() => Navigate(-1, { replace: true })}>
                {t("Employees")}
              </a>
            </li>
            <li className="breadcrumb-item active">
              {t("Employee modification")}
            </li>
          </ul>
          <h1 className="page-header mb-0">{t("Employee modification")}</h1>
        </div>
      </div>

      <Card>
        {/* <Loader loading={dataLoading} /> */}
        <CardBody>
          {dataLoading ? (
            <SkeletonForm />
          ) : (
            <Form onSubmit={formik.handleSubmit}>
              <EditEmployees formik={formik} />

              {Employee?.extraInformationId ? (
                <ExtraInformationBuilderUpdate
                  extraInformationId={Employee?.extraInformationId}
                  referenceId={Employee?.id}
                  setExtraInfoFormData={setExtraInfoFormData}
                />
              ) : (
                <ExtraInformationBuilderAdd
                  FormColumnList={FormColumnByTableIdList}
                  setExtraInfoFormData={setExtraInfoFormData}
                  referenceId={Employee?.id}
                />
              )}

              <div className="text-center">
                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="btn me-1 btn-theme mb-1"
                >
                  <i className="fa fa-save fa-fw me-1"></i>
                  {formik.isSubmitting ? "Saving..." : "Save"}
                </button>
              </div>
            </Form>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default UpdatePerson;
