import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  UpdatScanningElement,
  GetScanningElementById,
  GetScanningElementList,
} from "../../../../store/FLEET/FleetScan/fleetScanSlice";
import CustomAlert from "../../../../components/Alert/CustomAlert";

import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "../../../../components/Loader/Loader";
import { AddFleetElementSchema } from "../../../ValidationForm/validationSchema";

interface props {
  ID: string;
}

const EditElement: React.FC<props>  = (props) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { FleetCategory, PRIORITY } = useAppSelector((state) => state.Lookup);
  const { ScanElement , loadingById } = useAppSelector((state) => state.FleetScan);

  useEffect(()=>{
    if(props.ID){
      dispatch(GetScanningElementById(props.ID))
    }
  },[props.ID])   

  const formik = useFormik({
    initialValues: {
      id:  ScanElement ? ScanElement.id : "",
      name: ScanElement ? ScanElement.name : "",
      name2:  ScanElement ? ScanElement.name2 : "",
      description:  ScanElement ? ScanElement.description : "",
      description2:  ScanElement ? ScanElement.description2 : "",
      sort:  ScanElement ? ScanElement.sort : "",
      catagory:  ScanElement ? ScanElement.catagory : "",
      priority:  ScanElement ? ScanElement.priority : "",
    },
    enableReinitialize:true,
    validationSchema: AddFleetElementSchema(t),

    onSubmit: (values) => {
      // console.log(values);

      dispatch(
        UpdatScanningElement({
          id: props.ID,
          name: values.name,
          name2: values.name2,
          description: values.description,
          description2: values.description2,
          catagory: values.catagory,
          sort: values.sort,
          priority: values.priority,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            document.getElementById("closeModalForm")?.click();
            formik.resetForm();
            CustomAlert({ action: "Edit" });
            dispatch(GetScanningElementList());
            dispatch(GetScanningElementById(props.ID))
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

 

  return (
    <div>
      <Loader loading={loadingById}/>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Catagory")}</label>
                  <Form.Select
                    disabled
                    className="form-select pt-"
                    name="catagory"
                    onChange={formik.handleChange}
                    value={formik.values.catagory}
                    isInvalid={
                      !!(formik.touched.catagory && formik.errors.catagory)
                    }
                  >
                    <option key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {FleetCategory &&
                      FleetCategory.map((item: any, idx) =>
                        item.list.map((list: any) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === "ar"
                              ? list.meaning2
                              : list.meaning}
                          </option>
                        ))
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.catagory}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label className="form-label"> {t("Priority")} </label>
                  <Form.Select
                    className="form-select"
                    name="priority"
                    onChange={formik.handleChange}
                    value={formik.values.priority}
                    isInvalid={
                      !!(formik.touched.priority && formik.errors.priority)
                    }
                  >
                    <option key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {PRIORITY &&
                      PRIORITY.map((item: any, idx) =>
                        item.list.map((list: any) => (
                          <option key={++idx} value={list.lookupCode}>
                            {i18n.language === "ar"
                              ? list.meaning2
                              : list.meaning}
                          </option>
                        ))
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.priority}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Arabic Name")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2}
                    isInvalid={!!(formik.touched.name2 && formik.errors.name2)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">{t("English Name")}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={!!(formik.touched.name && formik.errors.name)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-2">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    {t("Order")}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="sort"
                    onChange={formik.handleChange}
                    value={formik.values.sort}
                    isInvalid={!!(formik.touched.sort && formik.errors.sort)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.sort}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("DescriptionAR")}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={3}
                    name="description2"
                    onChange={formik.handleChange}
                    value={formik.values.description2}
                  />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("DescriptionEN")}</label>
                  <Form.Control
                  style={{direction : "ltr"}}
                    className="form-control"
                    as="textarea"
                    rows={3}
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
            id="SaveSubmit"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t("Save")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
          </Form>
  

       
    

    </div>
  );
};

export default EditElement;
