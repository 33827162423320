
import React from 'react'
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../../components/Shared/Breadcrumb';
import EditFormOrganization from '../../HR/Departments/EditDepartments/EditFormDepartments';
import useOrganizationDetails from '../../../hooks/HR/use-organization-details';

const UpdateBranches = () => {

 

    const { Organization, loading } = useOrganizationDetails();


      //______________________________
      var BcrumbList = [
        {
          name: "Home",
          link: "/",
        },
        {
          name: "Branches",
          link: '/FLEET/Branches',
        },
        {
          name: "Edit",
          link: null,
        },
      ];
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="EditBranche" />
      
        <EditFormOrganization Organization={Organization} loading={loading}  type="150" />

    </div>
  )
}

export default UpdateBranches
