import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { GetInstallmentUnPayment } from "../../../../store/SU/installmentPaymentSlice";
import { GetContractLease } from "../../../../store/SU/contractLeaseSlice";
import { Link, useNavigate } from "react-router-dom";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../../components/card/card";
import { Form } from "react-bootstrap";
import AddInstallmentPayment from "./AddInstallmentPayment";
import Swal from "sweetalert2";
import CustomAlert from "../../../../components/Alert/CustomAlert";
import { Modal } from "bootstrap";

const InstallmentUnPaymentList = () => {
  const [date, setDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [contractCode, setContractCode] = useState("");
  const [dataPerPage] = useState(10000);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const [selectedInstallments, setSelectedInstallments] = useState<
    string[] | any
  >([]);

  useEffect(() => {
    // Set the initial date to today
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setDate(formattedDate);
  }, []); // Empty dependency array ensures this effect runs once on mount

  useEffect(() => {
    dispatch(GetInstallmentUnPayment());
    dispatch(GetContractLease());
  }, []);
  const { InstallmentUnPaymentList } = useAppSelector(
    (state) => state.InstallmentPayment
  );
  const { ContractLeaseList } = useAppSelector((state) => state.Contracts);

  const filteredSearch = date
    ? InstallmentUnPaymentList?.slice()?.filter((item: any) => {
        const searchLowerCase = date.toLowerCase();
        const itemDate = new Date(item.installmentDate);
        const searchDate = new Date(date);
        // return itemDate > searchDate;
        return (
          itemDate < searchDate &&
          (contractCode || item.contrcatCode === contractCode)
        );
      })
    : InstallmentUnPaymentList?.slice();

  // const filteredSearch = InstallmentUnPaymentList?.slice().filter((item : any) => {
  //   const searchLowerCase = contractCode.toLowerCase();
  //   return (
  //     // item.name?.toLowerCase().includes(searchLowerCase) ||
  //     item.contrcatCode?.toLowerCase().toString().includes(searchLowerCase)
  //   );
  // });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  const handleCheckboxChange = (row: any) => {
    setSelectedInstallments((prevSelectedRows: any) => {
      const isSelected = prevSelectedRows?.some(
        (item: any) => item.id === row.id
      );

      if (isSelected) {
        return prevSelectedRows?.filter((item: any) => item.id !== row.id);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  const handleOpenAddPayment = () => {
    if (selectedInstallments?.length > 0) {
      let flag: boolean = selectedInstallments?.some(
        (s: any) =>
          s.contractLeaseId !== selectedInstallments[0].contractLeaseId
      );

      if (flag) {
        CustomAlert({
          action: "Error",
          Title: "",
          Timer: 10000,
          msg: "لا يمكن دفع أقساط لأكثر من عقد في دفعة واحدة .. اختر دفعات لعقد واحد فقط",
        });
      } else {
        const modal: HTMLElement | null = document.getElementById(
          "modalِInstallmentPayment"
        );

        // Open the modal using Bootstrap's modal method
        if (modal) {
          const bootstrapModal = new Modal(modal);
          bootstrapModal.show();
        }
      }
    }
  };
  // console.log(selectedInstallments);

  //_____________________
  var BcrumbList = [
    {
      name: t("Home"),
      link: "/",
    },
    {
      name: "متابعة الإيجارات",
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={"متابعة الإيجارات"} />

      <Card>
        <CardBody>
          <div className="row">
            <div className="col-7 col-md-6 d-flex justify-content-start">
              <div className="input-group mb-2">
                <div className="flex-fill position-relative">
                  <div className="row">
                    <div className="col-xl-5 mx-xl-auto">
                      <div className="form-group mb-3">
                        <label className="form-label"> قبل تاريخ </label>
                        <Form.Control
                          className="form-control"
                          type="date"
                          value={date}
                          onChange={(e) => {
                            const selectedDate = e.target.value;
                            // Prevent clearing the date
                            if (selectedDate !== "") {
                              setDate(selectedDate);
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-xl-5 mx-xl-auto">
                      <div className="form-group mb-3">
                        <label className="form-label">رقم العقد</label>
                        <Form.Select
                          className="form-select"
                          onChange={(e) => {
                            setContractCode(e.target.value);
                            setSelectedInstallments([]);
                          }}
                        >
                          <option value=" ">
                            {i18n.language === "ar" ? "إختر" : "Choose"}
                          </option>
                          {ContractLeaseList &&
                            ContractLeaseList?.map((item: any) => (
                              <option
                                key={item?.id}
                                value={item?.contractLeaseCode}
                              >
                                {item?.contractLeaseCode}
                              </option>
                            ))}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5 col-md-6 text-end pt-4">
              <Link
                to=""
                className={`btn me-1 btn-outline-theme ${
                  selectedInstallments?.length === 0 ? "disabled" : ""
                }`}
                // data-bs-toggle="modal"
                // data-bs-target="#modalِInstallmentPayment"
                onClick={() => handleOpenAddPayment()}
              >
                <i className="far fa-lg fa-fw me-2 fa-money-bill-alt"></i>
                دفع
              </Link>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover text-nowrap">
              <thead className="table-dark">
                <tr>
                  <th className="pt-2 pb-2"></th>
                  <th className="pt-2 pb-2">رقم العقد</th>
                  <th className="pt-2 pb-2">رقم الدفعة</th>
                  <th className="pt-2 pb-2">{t("Date")}</th>
                  <th className="pt-2 pb-2">{t("InstallmentValue")}</th>
                  <th className="pt-2 pb-2">{t("TypeInstallment")}</th>
                  <th className="pt-2 pb-2">{t("Date_from")}</th>
                  <th className="pt-2 pb-2">{t("End_Date")}</th>
                </tr>
              </thead>

              <tbody>
                {currentData.map((item: any) => (
                  <tr key={item.id}>
                    <td className="w-10px align-middle">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`checkbox-${item.id}`}
                          checked={selectedInstallments?.some(
                            (row: any) => row.id === item.id
                          )}
                          onChange={() => handleCheckboxChange(item)}
                        />
                      </div>
                    </td>
                    <td className="align-middle">{item.contrcatCode}</td>
                    <td className="align-middle">{item.installmentNo}</td>
                    <td className="align-middle">{item.installmentDate}</td>
                    <td className="align-middle">{item.installmentValue}</td>
                    <td className="align-middle">
                      {/* {item.contractInstallmentType} */}
                      {item.contractInstallmentType === 4
                        ? "تأمين"
                        : item.contractInstallmentType === 2
                        ? "خدمات"
                        : item.contractInstallmentType === 3
                        ? "سعي المكتب"
                        : "قسط عادي"}
                    </td>
                    <td className="align-middle">{item.installmentDateForm}</td>
                    <td className="align-middle">{item.installmentDateTo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <Pagination
        filteredData={filteredSearch}
        dataPerPage={dataPerPage}
        handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
        currentPage={currentPage}
      /> */}
        </CardBody>
      </Card>

      <ModalComponent title="دفع الأقساط" id="modalِInstallmentPayment">
        {selectedInstallments?.length > 0 && (
          <AddInstallmentPayment selectedInstallments={selectedInstallments} />
        )}
      </ModalComponent>
    </div>
  );
};

export default InstallmentUnPaymentList;
