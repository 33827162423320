
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import variables from "../../APIURL/variables";
import { ntxAPI } from "../../API/axios-custom";



  const Token = localStorage.getItem("Token") ? localStorage.getItem("Token") : null
  const RefrechToken = localStorage.getItem("RefrechToken") ? localStorage.getItem("RefrechToken") : null

  

  const initialState = {
    Token ,
    RefrechToken,
    loading: false,
    error: null,
    success : false
 };



  export const LoginAction = createAsyncThunk("Login/LoginAction", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;



    try {
      // debugger
      // const {data} = await ntxAPI.post("Authenticate/login" , item);
      const {data} = await axios.post(variables.API_URL + "Authenticate/login" , item);
      // debugger
      // const res = await axios.post(variables.API_URL + "Authenticate/login" , item ,  {
      //   headers: {
      //     "Content-Type": "application/json ; charset=UTF-8",
      //   },
      // });

      localStorage.setItem("Token", data?.token );
      localStorage.setItem("RefrechToken", data?.refreshToken);
      localStorage.setItem("personId", data?.personId);
      const permissionList = JSON.stringify(data?.permission);
      localStorage.setItem("permission", permissionList);
      localStorage.setItem("CurrentSystem", "NTX");

      return data;
    } catch (error) {
      console.log(error.response.status);
      if (error.response) {
        const status = error.response.status;
        if (status === 401) {
          // Unauthorized
          localStorage.removeItem("Token");
          localStorage.removeItem("RefrechToken");
          localStorage.removeItem("Expiration");
          return rejectWithValue("Unauthorized");
        }
      }
      // Network error or other error
        return rejectWithValue("NetworkError");

    }
  });


  
  // export const RefrechTokenAction = createAsyncThunk("Login/RefrechTokenAction", async (_, thunkAPI) => {
  //   const { rejectWithValue ,getState ,dispatch } = thunkAPI;
  //   const token = localStorage.Token;
  //   const refrechToken = localStorage.RefrechToken;
  //   const item = {
  //     accessToken: token ,
  //     refreshToken: refrechToken,
  //   };
    
  //   try {
  //     const res = await axios.post(variables.API_URL + 'Authenticate/refresh-token' , item );
  //    dispatch(setToken(res.data.token))
  //    dispatch(setRefreshToken(res.data.refreshToken))
  //    console.log('Refresh Token Done');
  //     // localStorage.setItem('Token' , res.data.token)
  //     // localStorage.setItem('RefrechToken' , res.data.refreshToken)
  //     return res.data;
  //   } catch (error) {
  //     console.log("RefrechTokenAction Error");
  //       return rejectWithValue(error);
  //   }
  // });






const LoginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    Logout: (state) => {
        // // localStorage.removeItem('Token');
        // // localStorage.removeItem('RefrechToken');
        // localStorage.removeItem("Token");
        // localStorage.removeItem("RefrechToken");
        // localStorage.removeItem("Expiration");
        // localStorage.removeItem("permission");
        // localStorage.removeItem("NtxMenu");
        // localStorage.removeItem("moduleId");
        localStorage.clear()
        state.Token = null;
        state.RefrechToken = null;
        state.loading = false;
        state.error = null;
    } , 
    setToken: (state, action) => {
      localStorage.setItem("Token", action.payload);
      state.Token = action.payload;
    },
    setRefreshToken: (state, action) => {
      localStorage.setItem("RefrechToken", action.payload);
      state.RefrechToken = action.payload;
    },
    
   
  },
  extraReducers: {

     /////// Login User Action
     [LoginAction.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [LoginAction.fulfilled]: (state, { payload } ) => {
        state.loading = false;
        state.error = null;
        state.Token = payload.token;
        state.RefrechToken = payload.refreshToken;
      },
      [LoginAction.rejected]: (state, {payload}) => {
        state.loading = false;
        state.error = payload;
      },

    //        /////// Login RefreashToken
    //  [RefrechTokenAction.pending]: (state) => {
    //   state.loading = true;
    //   state.error = null;
    // },
    // [RefrechTokenAction.fulfilled]: (state, { payload } ) => {
    //   state.loading = false;
    //   state.Token = payload.token;
    //   state.RefrechToken = payload.refreshToken;
    // },
    // [RefrechTokenAction.rejected]: (state, {payload}) => {
    //   state.loading = false;
    //   state.error = payload;
    // },
 
   
  },
});

export const { Logout , setToken , setRefreshToken } = LoginSlice.actions;

export default LoginSlice.reducer;
