import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card } from "../../../../components/card/card";
import ModalComponent from "../../../../components/Modal/ModalComponent";

import AddRealEstate from "../Add/AddRealEstate";
import useGetRealEstateList from "../../../../hooks/SU/useGetRealEstateList";
import Loader from "../../../../components/Loader/Loader";
// import Loading from "../../../../components/Loading";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";

const RealEstate = () => {
  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const { RealEstateList, loading } = useGetRealEstateList();

  //_____________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "RealEstate",
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="RealEstate" />

      <Card>
        <Loader loading={loading} />
        {/* <Loading loading={loading}  SkeletonType="Grid"> */}
        <>
          <div className="tab-content p-4">
            <div className="tab-pane fade show active">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          //   onChange={handleSearch}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end">
                  <a
                    href="#/"
                    className="btn btn-outline-theme"
                    data-bs-toggle="modal"
                    data-bs-target="#modalAddRealEstate"
                    onClick={() => setShow(true)}
                  >
                    <i className="fa fa-plus-circle fa-fw me-1"></i>
                    {t("Add_Real_Estate")}
                  </a>
                </div>
              </div>

              {/* //  START TABLE   */}

              <div className="mb-5">
                <div className="row">
                  {RealEstateList &&
                    RealEstateList.map((item) => (
                      <div className="col-xl-4" key={item.id}>
                        <Card className="m-2">
                          {/* <CardBody> */}

                          <div className="m-1 bg-white bg-opacity-15">
                            <iframe
                              title="Real EState"
                              src={`http://www.google.com/maps/embed/v1/place?q=${item.latitude},${item.longitude}&key=AIzaSyCG3Kt_jO8o7x67EsdV9RuTJP5IxSEwmeo`}
                              style={{
                                border: 0,
                                width: "100%",
                                height: "10rem",
                              }}
                              allowFullScreen
                            ></iframe>
                            <span className="w-20px h-20px p-0 d-flex align-items-center justify-content-center badge bg-theme text-theme-color position-absolute start-0 top-0 fw-bold fs-12px rounded-pill mt-n2 me-n2">
                              {item.capacity}
                            </span>
                            <div className="list-group list-group-flush ">
                              <div className="list-group-item d-flex">
                                <div className="w-30px h-40px d-flex align-items-center justify-content-center">
                                  <i className="fa-lg me-2 bi bi-buildings-fill text-theme fs-1"></i>
                                </div>

                                <div className="flex-fill px-2">
                                  <div className="fw-bold">
                                    {i18n.language === "ar"
                                      ? item.name2
                                      : item.name}
                                  </div>
                                </div>

                                <div className="flex-grow-0">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary me-1"
                                    data-toggle="tooltip"
                                    title="تعديل بيانات العقار"
                                    onClick={() =>
                                      Navigate(`${item.id}/UpdateRealEstate`)
                                    }
                                  >
                                    <i className="fas fa-edit fa-lg"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-theme me-1"
                                    data-toggle="tooltip"
                                    title="تسكين الموظفين"
                                    onClick={() =>
                                      Navigate(`${item.id}/Accomodation`)
                                    }
                                  >
                                    <i className="bi bi-building-fill-up fa-lg"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-theme me-1"
                                    data-toggle="tooltip"
                                    title="معلومات اضافية"
                                    onClick={() =>
                                      {
                                        localStorage.ReferenceName2 = item.name2;
                                        localStorage.ReferenceName = item.name;
                                        Navigate(`${item.id}/ExtraRecordRealEstate`);
                                      }
                                    }
                                  >
                                    <i className="bi bi-info-circle fa-lg"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </CardBody> */}
                        </Card>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
        {/* </Loading> */}
      </Card>

      <ModalComponent title={t("Add_Real_Estate")} id="modalAddRealEstate">
        {Show && <AddRealEstate />}
      </ModalComponent>
    </>
  );
};

export default RealEstate;




// {/* <div className="text-nowrap">
//   <div className="dropdown dropdown-icon ms-auto">
//     <a
//       href="#/"
//       className="text-white"
//       data-bs-toggle="dropdown"
//     >
//       <i className="fa fa-ellipsis-h"></i>
//     </a>

//     <div className="dropdown-menu dropdown-menu-end">
//       <Link
//         to={`${item.id}/UpdateRealEstate`} //
//         className="dropdown-item"
//       >
//         {t("Edit")}
//       </Link>
//       <Link
//         to={`${item.id}/Accomodation`} //
//         className="dropdown-item"
//       >
//         {t("Accomodation")}
//       </Link>
//     </div>

//   </div>
// </div> */}
