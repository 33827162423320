import React from "react";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../components/card/card";
import {
  cleanResponsibilityFunction,
  GetResponsibilityFunction,
} from "../../../../store/NTX/Security/responsibilityFunctionSlice";
import FunctionTable from "./FunctionTable";
import useLookupModule from "../../../../hooks/Lookups/use-Lookup-Module";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";

function ResponsibilityFunction() {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const { Module } = useLookupModule();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState("");

  const { ResponsibilityFunctionList } = useSelector(
    (state) => state.ResponsibilityFunction
  );

  useEffect(() => {
    dispatch(cleanResponsibilityFunction());
    dispatch(GetResponsibilityFunction(id));
  }, [dispatch, id]);




  
  useEffect(() => {
    if (Module?.length) {
      setSelectedValue(Module[0].id);
    }
  }, [Module]);

  function handleSelectChange(event) {
    setSelectedValue(event.target.value);
  }

  //filter data by <select>
  const filteredDataByModules = ResponsibilityFunctionList?.filter(
    (item) => item.moduleId === selectedValue
  );



  
  //_______________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Responsibilities",
      link: "/Security/Responsibilities",
    },
    {
      name: "Responsibility Function",
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb
        BreadcrumbList={BcrumbList}
        PageName="Responsibility Function"
      />

      <Card>
        <CardBody>
          <div className="tab-content p-4">
            <div className="tab-pane fade show active">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group mb-4">
                        <select
                          className="btn btn-outline-default p-0 dropdown-toggle"
                          id="my-select"
                          value={selectedValue}
                          onChange={handleSelectChange}
                        >
                          {Module &&
                            Module.map((item) => (
                              <option
                                value={item.id}
                                key={item.id}
                                className="dropdown-item"
                              >
                                {" "}
                                {i18n.language === "ar"
                                  ? item.name2
                                  : item.name2}{" "}
                              </option>
                            ))}
                        </select>

                        <div className="flex-fill position-relative">
                          <div className="input-group">
                            <div
                              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                              style={{ zIndex: 1020, right: "10px" }}
                            >
                              <i className="fa fa-search opacity-5"></i>
                            </div>
                            <input
                              type="text"
                              className="form-control ps-35px"
                              placeholder={t("Search")}
                              value={search}
                              onChange={(e)=>setSearch(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* //  START TABLE   */}

              {ResponsibilityFunctionList?.length > 0 && (
                <FunctionTable
                  ResponsibilityFunctionList={filteredDataByModules}
                />
              )}
              {/* </CardBody> */}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default ResponsibilityFunction;
