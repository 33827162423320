import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";
// import { getPayrolls } from "../../../../store/Payroll/PayrollSlice.js";
import { useTranslation } from "react-i18next";

import AddExtraRecordsForm from "./AddExtraRecordsForm.js";
import { GetDynamicForm } from "../../../../store/NTX/FlexValue/Form/formSlice.js";
import Pagination from "../../../../components/Pagination/Pagination.jsx";
import useLookupModule from "../../../../hooks/Lookups/use-Lookup-Module";
import { Card } from "../../../../components/card/card";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import Loading from "../../../../components/Loading";

const ExtraRecordsForm = () => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("");
  const { Module } = useLookupModule();
  //__________________________________________________
  const { DynamicFormList, loading, error } = useSelector(
    (state) => state.Forms
  );
  useEffect(() => {
    dispatch(GetDynamicForm());
  }, [dispatch]);

  // useEffect(() => {
  //   if (Module.length) {
  //     setSelectedValue(Module[0].id);
  //   }
  // }, [Module]);

  //_________________________________________________________________________________________________

  const filtered = DynamicFormList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => f.dynamicFormTypesId === 3)
    ?.filter((item) => (selectedValue === "" ? true : item.moduleId === selectedValue));
    // .filter((item) => item.moduleId === selectedValue);



  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filtered?.slice(indexOfFirstData, indexOfLastData);

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active">
              {t("Extra Information Forms")}
            </li>
          </ul>
          <h1 className="page-header mb-0">نماذج السجلات الإضافية</h1>
        </div>
      </div>

      <Card>
        <Loading loading={loading} error={error} SkeletonType="Table">
          <div className="tab-content p-4">
            <div className="tab-pane fade show active">
              <div className="row mb-3">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-2">
                    <div className="flex-fill position-relative">
                      <div className="input-group ">
                        <select
                          className="btn btn-outline-default dropdown-toggle p-0"
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.target.value)}
                        >
                          <option
                            className="dropdown-item"
                            value={""}
                          >
                            الكل
                          </option>
                          {Module &&
                            Module.map((item) => (
                              <option
                                value={item.id}
                                key={item.id}
                                className="dropdown-item"
                              >
                                {i18n.language === "ar"
                                  ? item.name2
                                  : item.name}
                              </option>
                            ))}
                        </select>

                        <div className="flex-fill position-relative">
                          <div className="input-group">
                            <div
                              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                              style={{ zIndex: 1020, right: "10px" }}
                            >
                              <i className="fa fa-search opacity-5"></i>
                            </div>
                            <input
                              type="text"
                              className="form-control ps-35px"
                              placeholder={t("Search")}
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end">
                  <a
                    href="#/"
                    className="btn btn-outline-theme"
                    data-bs-toggle="modal"
                    data-bs-target="#modalAddForm"
                    // hidden={hiddenAddForm}
                  >
                    <i className="fa fa-plus-circle fa-fw me-1"></i>
                    {t("Add Form")}
                  </a>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col" style={{width : "20%"}}>{t("Name")}</th>
                      <th scope="col" style={{width : "20%"}}>الجدول</th>
                      <th scope="col" style={{width : "20%"}}>اسم النظام</th>
                      <th scope="col" style={{width : "20%"}}>{t("Icon")}</th>

                      <th scope="col" style={{width : "20%"}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </td>
                        <td className="align-middle">
                          {i18n.language === "ar"
                            ? item.tableName2
                            : item.tableName}
                        </td>
                        <td className="align-middle">
                          {i18n.language === "ar"
                            ? item.moduleName2
                            : item.moduleName}
                        </td>
                        <td className="align-middle">
                          <div className="text-theme">
                            <i className={item.icon}></i>
                          </div>
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              type="button"
                              onClick={() =>
                                Navigate(`/NTX/Forms/${item.id}/AddFormColumns`)
                              }
                              className="btn btn-lg btn-outline-theme"
                              data-toggle="tooltip"
                              title={t("fields Form")}
                            >
                              <i className="fab fa-lg fa-fw fa-elementor"></i>
                              {/* {t("fields Form")} */}
                            </button>

                            <button
                              type="button"
                              onClick={() =>
                                Navigate(`${item.id}/EditExtraRecordsForm`)
                              }
                              className="btn btn-lg btn-outline-theme"
                              data-toggle="tooltip"
                              title={t("Button.Edit")}
                            >
                              <i className="far fa-lg fa-fw fa-edit"></i>
                              {/* {t("Button.Edit")} */}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <Pagination
                filteredData={filtered}
                // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber) => setCurrentPage(pageNumber)}
                currentPage={currentPage}
              />
            </div>
          </div>
        </Loading>
      </Card>

      <ModalComponent title="إضافة نموذج السجلات الاضافية" id="modalAddForm">
        <AddExtraRecordsForm />
      </ModalComponent>
    </>
  );
};

export default ExtraRecordsForm;
