import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
// import axios from "axios";
// import variables from "../../../APIURL/variables";
// import { getData, postData } from "../../Axios/axiosData";



const initialState = { FlexValueList: [], loading: false, error: null , TablesFormList:[]  };

  export const GetFlexValue = createAsyncThunk("FlexValue/GetFlexValue", async (id, thunkAPI) => {  
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`FlexValueSet/GetFlexValueList?FlexValueSetId=${id}`);
      const {data} = await ntxAPI.get(`FlexValueSet/GetFlexValueList?FlexValueSetId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });



  export const insertFlexValue = createAsyncThunk("FlexValue/AddFlexValue", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("FlexValueSet/AddFlexValue" , item);
      const {data} = await ntxAPI.post( "FlexValueSet/AddFlexValue", item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const DeleteFlexValue = createAsyncThunk("FlexValue/DeleteFlexValue", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData(`FlexValueSet/DeleteFlexValue?FlexValueId=${id}`);
      const {data} = await ntxAPI.post(`FlexValueSet/DeleteFlexValue?FlexValueId=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });







  const FlexValueSlice = createSlice({
    name: "FlexValue",
    initialState,
    reducers: { },
    extraReducers: {
      /////// get FlexValueList
      [GetFlexValue.pending]: (state) => {
        state.loading = true;
        state.error = null;
        state.FlexValueList = [];
      },
      [GetFlexValue.fulfilled]: (state, action) => {
        state.loading = false;
        state.FlexValueList = action.payload;
      },
      [GetFlexValue.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

          /////// Add FlexValueList
          [insertFlexValue.pending]: (state) => {
            state.loading = true;
            state.error = null;
          },
          [insertFlexValue.fulfilled]: (state, action) => {
            state.loading = false;
            state.FlexValueList.push(action.payload)
          },
          [insertFlexValue.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
          },

          // Delete

          [DeleteFlexValue.pending]: (state) => {
            state.loading = true;
            state.error = null;
          },
          [DeleteFlexValue.fulfilled]: (state, action) => {
            state.loading = false;
            state.FlexValueList = state.FlexValueList?.filter((el) => el.id !== action.payload)
            console.log(state.FlexValueList);
          },
          [DeleteFlexValue.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
          }
       
        
        
    
    },
  });

  export default FlexValueSlice.reducer;