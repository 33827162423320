import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteAmeRule, GetAmeRuleListByDynamicFormId } from '../../../../store/Ame/ameRuleSlice';
import ConfirmDeleteDialog from '../../../../components/Alert/ConfirmDeleteDialog';
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx';



function AmeRuleList({data}) {

    const { id } = useParams();
    
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();




    // Handele Delete AmeCondition
    const handleDeleteAmeRule = (item)=>{
        dispatch(DeleteAmeRule(item.id)).unwrap()
      .then(() => {
          dispatch(GetAmeRuleListByDynamicFormId(id));
          CustomAlert({ action: "Delete" });
      })
      .catch((error) => {
        console.log(error);
        CustomAlert({ action: "Error" });
      });
    
      }


  return (
    <>
    <table className="table">
      <thead className="table-dark">
        <tr>
          {/* <th scope="col">الرقم</th> */}
          <th scope="col">{t('Name')}</th>
          <th scope="col"></th>
        </tr>
      </thead>

      <tbody>
        {data.map((item, idx) => (
          <tr key={item.id}>
            {/* <td className="align-middle">{++idx}</td> */}
            <td className="align-middle">
              {i18n.language === "ar" ? item.name2 : item.name}
            </td>
            <td className="align-middle">

              {/* <button onClick={()=> handleDeleteAmeRule(item)} type="button" className="btn btn-outline-danger">
                حذف
              </button> */}
 <div className="btn-group">
              

              <button onClick={() => Navigate(`${item.id}/AddAmeConditionUsage`)}  type="button" className="btn btn-outline-lime">
              <i class="fas fa-lg fa-fw me-2 fa-question"></i>
                {t('Conditions')}  
              </button>

              <button onClick={() => Navigate(`${item.id}/AddAmeAction`)}  type="button" className="btn btn-outline-lime">
              <i class="fas fa-lg fa-fw me-2 fa-list-ol"></i>
                 {t('Approval Group')}  
              </button>

              <ConfirmDeleteDialog
                onConfirm={()=>handleDeleteAmeRule(item)}
                title="Are you sure?"
                message="This action cannot be undone."
                  />

</div>

            </td>
          </tr>
        ))}
      </tbody>
    </table>

</>
  )
}

export default AmeRuleList
