import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { getOrganizations } from "../../store/HR/Organizations/organizationSlice";

const useGetOrganizations = ()=>{

 
    const dispatch = useDispatch();

    const { Organizations , loading } = useSelector(state => state.Organizations)

    useEffect(()=>{
        dispatch(getOrganizations());
      },[dispatch])

    return { Organizations , loading } ;
};

export default useGetOrganizations ;