import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import variables from "../../APIURL/variables";
// import {  getData, postData  } from "../Axios/axiosData";
import { ntxAPI } from "../../API/axios-custom";



export const CreateRrealEstate = createAsyncThunk(
  "RealEstate/AddRrealEstate",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    console.log(JSON.stringify(item));
    try {
      // const data = await postData("RealState/AddRrealEstate" , item);
      const {data} = await ntxAPI.post( "RealState/AddRrealEstate" , item)
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetRealEstateList = createAsyncThunk("RealEstate/GetRealEstateList", async (_, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;

  try {
    // const data = await getData("RealState/GetRealEstateList");
    const {data} = await ntxAPI.get("RealState/GetRealEstateList" );
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const GetRealEstateById = createAsyncThunk("RealEstate/GetRealEstateById", async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    // const data = await getData(`RealState/GetRealEstateById?Id=${id}`);
    const {data} = await ntxAPI.get( `RealState/GetRealEstateById?Id=${id}`);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const EditRrealEstate = createAsyncThunk(
  "RealEstate/AddRrealEstate",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("RealState/UpdatRealEstate" , item);
      const {data} = await ntxAPI.post("RealState/UpdatRealEstate" , item)
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetPersonListToAccommodationByRealEstateId = createAsyncThunk("RealEstate/GetPersonListToAccommodationByRealEstateId", async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const {data} = await ntxAPI.get( `RealState/GetPersonListToAccommodationByRealEstateId?RealEstateId=${id}`);
    return data.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const DeleteRealEstateAssignment = createAsyncThunk(
  "RealEstate/DeleteRealEstateAssignment",
  async (RealEstateAssignmentId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {

      const {data} = await ntxAPI.post(`RealState/DeleteRealEstateAssignment?RealEstateAssignmentId=${RealEstateAssignmentId}`)
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const AddRealEstateAssignment = createAsyncThunk(
  "RealEstate/AddRealEstateAssignment",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const {data} = await ntxAPI.post( "RealState/AddSuRealEstateAssignment" , item)
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);


const initialState = { RealEstateList: [] , RealEstateById: [] , ListAccommodation :[],loading: false, error: null };


const realEstateSlice = createSlice({
  name: "RealEstate",
  initialState,
  reducers: {  },
  extraReducers: { 


       ///////Add 
    [CreateRrealEstate.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [CreateRrealEstate.fulfilled]: (state, action) => {
      state.loading = false;
      state.RealEstateList.push(action.payload);
    },
    [CreateRrealEstate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


    // get all 
    [GetRealEstateList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetRealEstateList.fulfilled]: (state, {payload}) => {
      state.loading = false;
      state.RealEstateList = payload;
    },
    [GetRealEstateList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    

    // get one employee by id
    [GetRealEstateById.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.RealEstateById = [];
    },
    [GetRealEstateById.fulfilled]: (state, action) => {
      state.loading = false;
      state.RealEstateById = action.payload;
    },
    [GetRealEstateById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },



    // Edit 
    [EditRrealEstate.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [EditRrealEstate.fulfilled]: (state, action) => {
      state.loading = false;
      state.RealEstateById = action.payload;
    },
    [EditRrealEstate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


        // get 
        [GetPersonListToAccommodationByRealEstateId.pending]: (state) => {
          state.loading = true;
          state.error = null;
          state.ListAccommodation = [];
        },
        [GetPersonListToAccommodationByRealEstateId.fulfilled]: (state, action) => {
          state.loading = false;
          state.ListAccommodation = action.payload;
        },
        [GetPersonListToAccommodationByRealEstateId.rejected]: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },
    
  },
});

export default realEstateSlice.reducer;
