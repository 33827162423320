import React from 'react'
import { useTranslation } from 'react-i18next'


function PayProposalTableList({PayProposalList}) {

    const {t} = useTranslation()
    
  return (
<>
<table className="table">
      <thead className="table-dark">
        <tr>
          <th scope="col">{t("Value")}</th>
          <th scope="col"> {t("Start Date")}</th>
          <th scope="col"> {t("Date_TO")}</th>
          <th scope="col">{t("Reason")}</th>
        </tr>
      </thead>

      <tbody>
                    {PayProposalList[0] && PayProposalList[0].map((item )=> (
                      <tr key={item.id}>
                      <td className="align-middle">{item.proposedSalaryN}</td>
                      <td className="align-middle">{ item.changeDate }</td>
                      <td className="align-middle">{ item.dateTo ? item.dateTo : " __ حتى الأن __"}</td>
                      <td className="align-middle">{item.proposalReason }</td>
                    </tr>
                    ))}
      </tbody>
    </table>
</>
  )
}

export default PayProposalTableList
