import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { useParams } from "react-router-dom";
import { getLocations } from "../../store/HR/HRSetting/locationSlice";


const useGetLocations = ()=>{

    const dispatch = useDispatch();
    const { Locations, loading , error } = useSelector((state) => state.Locations) 

    useEffect(() => {
            dispatch(getLocations());

        }, [dispatch]);

    return { loading , error , Locations } ;
};

export default useGetLocations ;