

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
// import { getData, postData } from "../../Axios/axiosData";



export const GetTableViewName = createAsyncThunk(
    "Reports/GetTableViewName",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await postData("Test/Query" , item);
        const {data} = await ntxAPI.post("Test/Query" , item)
        return data;
      } catch (error) {
        console.error(error);
        return rejectWithValue(error.message);
      }
    }
 );

 export const AddNtxReports = createAsyncThunk(
  "Reports/AddNtxReports",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // debugger
      // const data = await postData("Reports/AddNtxReports" , item);
      const {data} = await ntxAPI.post("Reports/AddNtxReports" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

 export const UpdatNtxReports = createAsyncThunk(
  "Reports/UpdatNtxReports",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Reports/UpdatNtxReports" , item);
      const {data} = await ntxAPI.post("Reports/UpdatNtxReports"  , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetReportsList = createAsyncThunk(
  "Reports/GetReportsList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("Reports/GetNtxReportsList");
      const {data} = await ntxAPI.get( "Reports/GetNtxReportsList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetReportById = createAsyncThunk(
  "Reports/GetReportById",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Reports/GetNtxReportsById?Id=${id}`);
      const {data} = await ntxAPI.get(`Reports/GetNtxReportsById?Id=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
)


export const GetReportSourceList = createAsyncThunk(
  "Reports/GetReportSourceList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get( "Reports/GetReportSourceList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
); 

  

const initialState = { ListTableViewName: [], ReportsList: [], ReportsListById: [] , ReportSourceList :[] ,loading: false, error: null };

const ReportSlice = createSlice({
    name: "Reports",
    initialState,
    reducers: {
      cleanReportsListById: (state) => {
        state.ReportsListById = [];
      },
    },
    extraReducers: {

      [GetTableViewName.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetTableViewName.fulfilled]: (state, action) => {
        state.loading = false;
        state.ListTableViewName = action.payload;
      },
      [GetTableViewName.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },


      // Get Report List 
      [GetReportsList.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetReportsList.fulfilled]: (state, {payload}) => {
        state.loading = false;
        // console.log(payload);
        state.ReportsList =  payload.data;
      },
      [GetReportsList.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

      // Get Report ListBy ID 
      [GetReportById.pending]: (state) => {
        state.loading = true;
        state.error = null;
        state.ReportsListById = [];
      },
      [GetReportById.fulfilled]: (state, {payload}) => {
        state.loading = false;
        // console.log(payload);
        state.ReportsListById =  payload.data;
      },
      [GetReportById.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

      // Get Report Source List 
      [GetReportSourceList.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetReportSourceList.fulfilled]: (state, {payload}) => {
        state.loading = false;
        if(payload.succeeded === true){
          state.ReportSourceList =  payload.data;
        }
      },
      [GetReportSourceList.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
  
    },
  });

  export const { cleanReportsListById } = ReportSlice.actions;
  
  export default ReportSlice.reducer;