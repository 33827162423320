/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TestComponent from "../../../ExtraInformation/ExtraRecordsDynamicForm/BuilderExtraRecord/Tabs/TestComponent";
import { GetFormRecordWithValue } from "../../../../store/ExtraInformation/extraInformationSlice";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";

const ExtraRecordRealEstate = () => {
  const { t, i18n } = useTranslation();
  const { RealEstateId } = useParams(); // ReferenceId
  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(true);
  //__________________________________________________
  const tableId = "ffe70bb0-1f6a-475e-945f-94faa967f80e"; // جدول الوحدات العقارية

  useEffect(() => {
    if (refreshData) {
      dispatch(
        GetFormRecordWithValue({
          TableId: tableId,
          ReferenceId: RealEstateId,
        })
      );
      setRefreshData(false);
    }
  }, [refreshData]);
  const { FormRecordWithValueList } = useSelector(
    (state) => state.ExtraInformation
  );

  //_____________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "RealEstate",
      link: "/SU/RealEstate",
    },
    {
      name:
        i18n.language === "ar"
          ? localStorage.ReferenceName2
          : localStorage.ReferenceName,
      link: null,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb BreadcrumbList={BcrumbList} />
      {/* PageName="السجلات الإضافية" */}
      <TestComponent
        setRefreshData={setRefreshData}
        FormRecordWithValueList={FormRecordWithValueList}
        ReferenceId={RealEstateId}
      />
    </Fragment>
  );
};

export default ExtraRecordRealEstate;
