

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
import { EquipmentsModel } from "./equipmentsmodelModels";



export const GetEquipmentsModelList = createAsyncThunk(
    "EquipmentsModel/GetEquipmentsModelList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Fleet/GetEquipmentsModelList');
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const InsertEquipmentsModel = createAsyncThunk('EquipmentsModel/AddEquipmentsModel',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/AddEquipmentsModel" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });
  
  
  export const UpdateEquipmentsModel = createAsyncThunk('EquipmentsModel/UpdateEquipmentsModel',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/UpdatEquipmentsModel" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


export const GetEquipmentsModelById = createAsyncThunk(
    "EquipmentsModel/GetEquipmentsModelById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetEquipmentsModelById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );




  





const initialState: {
    EquipmentsModelList : EquipmentsModel[]
    EquipmentsModelListById? : EquipmentsModel
    loading: boolean;
    error: any | null;
  } = {
    EquipmentsModelList: [],
    loading: false,
    error: null,

  };


  

const equipmentsmodelSlice = createSlice({
    name: "EquipmentsModel",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(InsertEquipmentsModel.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(InsertEquipmentsModel.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;        
        if(action?.payload?.data){
          state.EquipmentsModelList.push(action?.payload?.data); 
        }
      })
      .addCase(InsertEquipmentsModel.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetEquipmentsModelList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsModelList.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.EquipmentsModelList = action.payload;
      })
      .addCase(GetEquipmentsModelList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetEquipmentsModelById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEquipmentsModelById.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.EquipmentsModelListById = action.payload;
      })
      .addCase(GetEquipmentsModelById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



    // .addCase(GetWorkShopById.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(GetWorkShopById.fulfilled, (state, action: PayloadAction<WorkShopById> ) => {
    //     state.loading = false;
    //     state.WorkshopById = action.payload;
    //   })
    //   .addCase(GetWorkShopById.rejected, (state, action: PayloadAction<any>) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   })

      
    },
  });
  
  export default equipmentsmodelSlice.reducer;