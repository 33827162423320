import Swal from "sweetalert2";
import i18next from "i18next";

interface CustomAlertProps {
  action: string;
  Title?: string;
  msg?: string | any;
  Timer?: number;
}

export default function CustomAlert({ action, msg , Title , Timer }: CustomAlertProps) {
  let title = "";
  let icon = "";
  let text = "";
  // console.log(msg);

  switch (action) {
    case "Edit":
      title = Title ?? "" ;
      text = msg ?? "تمت عملية التعديل بنجاح" //i18next.language === "ar" ? : "";
      icon = "success";
      break;
    case "Delete":
      title = "حذف";
      text = "تمت عمية الحــذف بنجاح"
      icon = "warning";
      break;
    case "Add":
      title = Title ?? "" ;
      icon = "success";
      text = msg ?? "تمت الإضافة بنجاح";
      break;
    case "Error":
      // title =  "فشلت العملية";
      title = Title ?? "فشلت العملية"  ;
      text = msg ?? "";
      icon = "warning";
      break;
    default:
      title = title ?? "";
      text = msg ?? "";
      icon = "info";
  }

  Swal.fire({
    icon: icon as any,
    title: title,
    text: text,
    timer: Timer? Timer: 1500,  
  });
}
