import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody } from "../../../components/card/card";
import Loader from "../../../components/Loader/Loader";
import { GetDynamicForm } from "../../../store/NTX/FlexValue/Form/formSlice";

const AmeRequests = ({ FormList }) => {
  const { t, i18n } = useTranslation();

  const Navigate = useNavigate();
  // const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.Forms);

  //__________________________________________________
  // const { DynamicFormList, loading } = useSelector((state) => state.Forms);
  // useEffect(() => {
  //   dispatch(GetDynamicForm());
  // }, [dispatch]);
  // //_____________________________________________________
  // var FormList = DynamicFormList?.filter(f=>f.dynamicFormTypesId === 1)

  return (
    <>
      <div className="row">
        <Card style={{ minHeight: "70vh" }}>
          <CardBody>
            <Loader loading={loading} />
            <div className="tab-content p-4">
              <div className="tab-pane fade show active">
                {FormList &&
                  FormList?.map((item, index) => (
                    <div className="mailbox-detail-content" key={item.id}>
                      <div className="mailbox-attachment1" style={{ margin: "10px" }}>
                        <Link
                          onClick={() => Navigate(`/SelfService/Requests/${item.id}/AddRequest`)}
                          to=""
                        >
                          <div className="document-file1">
                            <i
                              className={
                                item.icon
                                  ? item.icon.replace("fa-lg fa-fw me-2", "")
                                  : "fas fa-umbrella"
                              }
                            ></i>
                          </div>
                          <div className="document-name">
                            {i18n.language === "ar" ? item.name2 : item.name}
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </CardBody>
        </Card>

        <div />
      </div>
    </>
  );
};

export default AmeRequests;
