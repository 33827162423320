import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { useParams } from "react-router-dom";
import { getEmployee } from "../../store/HR/Person/employeeSlice";

const useEmpDetails = ()=>{
    const { id } =  useParams();
    const dispatch = useDispatch();
    const { loading , error , Employee} = useSelector((state) => state.Employees); 
    useEffect(()=>{
        dispatch(getEmployee(id))
    },[dispatch , id])

    return { loading , error , Employee } ;
};

export default useEmpDetails ;