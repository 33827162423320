import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import variables from "../../../../APIURL/variables";

// import { getData, postData } from "../../../Axios/axiosData";
import { ntxAPI } from "../../../../API/axios-custom";

const initialState = {
  DynamicFormList: [],
  StaticFormList: [],
  loading: false,
  error: null,
  DynamicFormListByID: [],
  ExtraInformationTablesFormList: [],
  ExtraInformationRecordTablesFormList: [],
};

export const AddDynamicForm = createAsyncThunk(
  "Forms/AddDynamicForm",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("DynamicForm/AddDynamicForm", item);
      const {data} = await ntxAPI.post("DynamicForm/AddDynamicForm" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetDynamicForm = createAsyncThunk(
  "Forms/GetDynamicForm",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData("DynamicForm/GetDynamicFormList", thunkAPI);
      const {data} = await ntxAPI.get("DynamicForm/GetDynamicFormList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetDynamicFormByID = createAsyncThunk(
  "Forms/GetDynamicFormByID",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`DynamicForm/GetDynamicFormById?Id=${id}`);
      const {data} = await ntxAPI.get(`DynamicForm/GetDynamicFormById?Id=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const UodateDynamicForm = createAsyncThunk(
  "Forms/UodateDynamicForm",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("DynamicForm/UpdateDynamicForm", item);
      const {data} = await ntxAPI.post( "DynamicForm/UpdateDynamicForm", item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetStaticForm = createAsyncThunk(
  "Forms/GetStaticForm",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(
      //   "DynamicForm/GetUnEnableStaticDynamicFormList",
      //   thunkAPI
      // );
      const {data} = await ntxAPI.get("DynamicForm/GetUnEnableStaticDynamicFormList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetExtraInformationTablesFormList = createAsyncThunk(
  "Forms/GetExtraInformationTablesFormList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(
      //   "DynamicForm/GetExtraInformationTablesFormList",
      //   thunkAPI
      // );
      const {data} = await ntxAPI.get("DynamicForm/GetExtraInformationTablesFormList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetExtraInformationRecordTablesFormList = createAsyncThunk(
  "Forms/GetExtraInformationRecordTablesFormList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get( "DynamicForm/GetExtraInformationRecordTablesFormList");
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);



const FormSlice = createSlice({
  name: "Forms",
  initialState,
  reducers: {},
  extraReducers: {
    /////// get all Dynamic Forms
    [GetDynamicForm.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetDynamicForm.fulfilled]: (state, action) => {
      state.loading = false;
      state.DynamicFormList = action.payload;
    },
    [GetDynamicForm.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    ///////insert
    [AddDynamicForm.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [AddDynamicForm.fulfilled]: (state, action) => {
      state.loading = false;
      state.DynamicFormList.push(action.payload);
    },
    [AddDynamicForm.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    /////// get one Dynamic Forms by id
    [GetDynamicFormByID.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.DynamicFormListByID = [];
    },
    [GetDynamicFormByID.fulfilled]: (state, action) => {
      state.loading = false;
      state.DynamicFormListByID = action.payload;
    },
    [GetDynamicFormByID.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    ///

    /////// Update Dynamic Forms by id
    [UodateDynamicForm.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [UodateDynamicForm.fulfilled]: (state, action) => {
      state.loading = false;
      state.DynamicFormListByID = action.payload;
    },
    [UodateDynamicForm.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    /////// get StaticForm
    [GetStaticForm.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetStaticForm.fulfilled]: (state, action) => {
      state.loading = false;
      state.StaticFormList = action.payload;
    },
    [GetStaticForm.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    /////// get ExtraInformationTablesFormList
    [GetExtraInformationTablesFormList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetExtraInformationTablesFormList.fulfilled]: (state, action) => {
      state.loading = false;
      state.ExtraInformationTablesFormList = action.payload;
    },
    [GetExtraInformationTablesFormList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    /////// get ExtraInformationRecordTablesFormList
    [GetExtraInformationRecordTablesFormList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetExtraInformationRecordTablesFormList.fulfilled]: (state, action) => {
      state.loading = false;
      state.ExtraInformationRecordTablesFormList = action.payload;
    },
    [GetExtraInformationRecordTablesFormList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default FormSlice.reducer;
