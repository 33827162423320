import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { getData, postData } from "../../Axios/axiosData";
import { ntxAPI } from "../../../API/axios-custom";

const initialState = { Organizations: [], loading: false, error: null, Organization: [] };

export const insertOrganization = createAsyncThunk(
  "Organizations/insertOrganization",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Organization/AddOrganization" , item);
      const {data} = await ntxAPI.post("Organization/AddOrganization" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getOrganizations = createAsyncThunk("Organizations/getOrganizations", async (_, thunkAPI) => {
  const { rejectWithValue , getState } = thunkAPI;

  //   const cachedOrg = getState().Organizations.Organizations
  // if (cachedOrg.length > 0) {
  //   return cachedOrg;
  // }


  try {
    const {data} = await ntxAPI.get("Organization/GetOrganizationList");
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }

});

export const getOrganization = createAsyncThunk("Organizations/getOrganization", async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    // const data = await getData(`Organization/GetOrganizationById?guid=${id}`);
    const {data} = await ntxAPI.get(`Organization/GetOrganizationById?guid=${id}` );
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const  EditOrganization = createAsyncThunk(
  "Organizations/EditOrganization",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Organization/UpdateOrganization" , item);
      const {data} = await ntxAPI.post( "Organization/UpdateOrganization", item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

const OrganizationSlice = createSlice({
    name: "Organizations",
    initialState,
    reducers: { 
      cleanOrganization: (state) => {
        state.Organization=[]
      }
    },
    extraReducers: {
      /////// get all Organizations
    [getOrganizations.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getOrganizations.fulfilled]: (state, action) => {
      state.loading = false;
      state.Organizations = action.payload;
    },
    [getOrganizations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get one Org by id
    [getOrganization.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getOrganization.fulfilled]: (state, action) => {              
      state.loading = false;                                                                                                  
      state.Organization = action.payload; 
    },
    [getOrganization.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


      ///////insert
      [insertOrganization.pending]: (state) => {
        // state.loading = true;
        state.error = null;
      },
      [insertOrganization.fulfilled]: (state, action) => {
        // state.loading = false;
        state.Organizations.push(action.payload);
      },
      [insertOrganization.rejected]: (state, action) => {
        // state.loading = false;
        state.error = action.payload;
      },

          ///////Edit
          [EditOrganization.pending]: (state) => {
            state.loading = true;
            state.error = null;
          },
          [EditOrganization.fulfilled]: (state, action) => {
            state.loading = false;
            state.Organization = action.payload; 
          },
          [EditOrganization.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
          },
    },
  });

  export const {cleanOrganization} = OrganizationSlice.actions;
  export default OrganizationSlice.reducer;

 
  