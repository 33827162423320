/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import Multi_Chart from "./Multi_Chart";
import Single_chart from "./Single_Chart";

//  export const chartType={
//     Line:"line",//multi
//     bar:"bar",//multi
//     Area:"area",//multi
//     column:"column",//multi
//     radar:"radar",//multi
//   donut:"donut",//1
//     pie:"pie",//1
//     radialBar:"radialBar",//1
// }
const Index = ({ DataChartReport }) => {
  // const [chartData,setChartData]=useState({
          // type:chartType.Area,
          // title:"TAreq",
          // data :[
          //     { name: 'High - 2021', data: [28, 29, 33, 36, 32, 32, 33]	},
          //     { name: 'Mid - 2021', data: [20, 19, 25, 30, 17, 20, 23]	},
          //     { name: 'Low - 2021', data: [12, 11, 14, 18, 17, 13, 13] }
          // ],

          // labels:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],

  //     });

  // const [chartDataSingle,setChartDataSingle]=useState({
  //         type:chartType.donut,
  //         title:"TAreq",
  //          data: [76, 67, 61, 90],
  //          labels: ['Team A', 'Team B', 'Team C', 'Team D'],

  //     });


  return (
    <div>
      {/* <select onChange={handleChangeType}>
                <option value="line">Line</option>
                <option value="bar">bar</option>
                <option value="area">area</option>
                <option value="radar">radar</option>         
            </select> */}

      {DataChartReport.type === "donut" ||
      DataChartReport.type === "pie" ||
      DataChartReport.type === "radialBar" ? (
        <Single_chart chartData={DataChartReport} />
      ) : (
        <Multi_Chart chartData={DataChartReport} />
      )}
    </div>
  );
};

export default Index;
