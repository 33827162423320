
import React from 'react'
import { useTranslation } from 'react-i18next';
 
 
 
import Breadcrumb from '../../../../components/Shared/Breadcrumb';
import EditFormOrganization from '../../../HR/Departments/EditDepartments/EditFormDepartments';
import useOrganizationDetails from '../../../../hooks/HR/use-organization-details';

const UppdateWorkShop = () => {

 

    const { Organization, loading } = useOrganizationDetails();
    // const { Organization, loading } = useOrganizationDetails();



      //______________________________
      var BcrumbList = [
        {
          name: "Home",
          link: "/",
        },
        {
          name: "Workshops",
          link: '/FLEETSettings/WorkShop',
        },
        {
          name: "Edit",
          link: null,
        },
      ];
  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="EditWorkshop" />
      
        <EditFormOrganization Organization={Organization} loading={loading}  type="160" />

    </div>
  )
}

export default UppdateWorkShop
