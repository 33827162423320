
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
// import axios from "axios";
// import variables from "../../../APIURL/variables";
// import { getData, postData } from "../../Axios/axiosData";




// Get Responsibility Function by Responsibility Id
export const GetResponsibilityFunction = createAsyncThunk("ResponsibilityFunction/GetResponsibilityFunction", async (id, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;


  try {
    // const data = await getData(`Security/GetResponsibilityFunctionListbyResponsibilityId?ResponsibilityId=${id}`);
    const {data} = await ntxAPI.get(`Security/GetResponsibilityFunctionListbyResponsibilityId?ResponsibilityId=${id}` );
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const AddResponsibilityFunction = createAsyncThunk("ResponsibilityFunction/AddResponsibilityFunction", async (item, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;

  try {
    // const data = await postData("Security/AddAuthResponsibilityFunction" , item);
    const {data} = await ntxAPI.post( "Security/AddAuthResponsibilityFunction", item)
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});








const initialState = { ResponsibilityFunctionList: [], loading: false, error: null  };

const ResponsibilityFunctionSlice = createSlice({
  name: "ResponsibilityFunction", 
  initialState,
  reducers: {
    cleanResponsibilityFunction: (state)=>{
        state.ResponsibilityFunctionList = [];
      }
  },
  extraReducers: {

   
   // Get Responsibility Function by Responsibility Id
    [GetResponsibilityFunction.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetResponsibilityFunction.fulfilled]: (state, action) => {
      state.loading = false;
      state.ResponsibilityFunctionList = action.payload;
    },
    [GetResponsibilityFunction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

     /////insert
    [AddResponsibilityFunction.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [AddResponsibilityFunction.fulfilled]: (state, action) => {
      state.loading = false;
      state.ResponsibilityFunctionList.push(action.payload);
    },
    [AddResponsibilityFunction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },





  
  },
});

export const {cleanResponsibilityFunction} = ResponsibilityFunctionSlice.actions
export default ResponsibilityFunctionSlice.reducer;
