import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteAmeActionRule, GetAmeActionRule } from '../../../../store/Ame/ameActionRuleSlice';
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx';
import ConfirmDeleteDialog from '../../../../components/Alert/ConfirmDeleteDialog';

const ActionRuleList = ({data}) => {

    const { ruleId } = useParams();
    
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();


  
      // Handele Delete Action Rule
      const handleDeleteActionRule = (item)=>{
          dispatch(DeleteAmeActionRule(item.id)).unwrap()
        .then(() => {
            dispatch(GetAmeActionRule(ruleId));
            CustomAlert({ action: "Add" });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: "Error" });
        });
        }
  
  

  return (
    <>
    <table className="table">
      <thead className="table-dark">
        <tr>
          {/* <th scope="col">الرقم</th> */}
          <th scope="col"> {t('Name')}</th>
          <th scope="col"></th>
        </tr>
      </thead>

      <tbody>
        {data.map((item, idx) => (
          <tr key={item.id}>
            {/* <td className="align-middle">{++idx}</td> */}
            <td className="align-middle">
              {i18n.language === "ar" ? item.name2 : item.name}
            </td>
            <td className="align-middle">

              {/* <button onClick={()=> handleDeleteActionRule(item)} type="button" className="btn btn-outline-danger">
                حذف
              </button> */}

              <ConfirmDeleteDialog
                 onConfirm={()=>handleDeleteActionRule(item)}
                 title="Are you sure?"
                 message="This action cannot be undone."
               />



            </td>
          </tr>
        ))}
      </tbody>
    </table>

</>
  )
}

export default ActionRuleList