import React, { useEffect, useState } from "react";

import Chart from "react-apexcharts";
// import { Card, CardBody } from "../card/card";

function Multi_Chart({ chartData }) {
  const [lineChartOptions, setLineChartOptions] = useState(
    getLineChartOptions()
  );

  // var lineChartData = [
  // 	{ name: 'High - 2021', data: [28, 29, 33, 36, 32, 32, 33]	},
  // 	{ name: 'Mid - 2021', data: [20, 19, 25, 30, 17, 20, 23]	},
  // 	{ name: 'Low - 2021', data: [12, 11, 14, 18, 17, 13, 13] }
  // ];

  function getLineChartOptions() {
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeFont = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-font-family")
      .trim();
    var themeFontWeight = getComputedStyle(document.body)
      .getPropertyValue("--bs-body-font-weight")
      .trim();
    var gray500 = getComputedStyle(document.body)
      .getPropertyValue("--bs-gray-500")
      .trim();
    var inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    var inverseRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse-rgb")
      .trim();
    var borderColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-border-color")
      .trim();
    var gray300Rgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-gray-300-rgb")
      .trim();

    return {
      chart: {
        shadow: {
          enabled: true,
          color: "rgba(" + inverseRgb + ", .5)",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 1,
        },
        toolbar: { show: true },
      },
      plotOptions:
        chartData.type === "bar"
          ? { bar: { horizontal: true, dataLabels: { position: "top" } } }
          : chartData.type === "radar"
          ? {
              radar: {
                // size: 140,
                polygons: {
                  strokeColors: "rgba(" + inverseRgb + ", .25)",
                  strokeWidth: 1,
                  connectorColors: "rgba(" + inverseRgb + ", .25)",
                  fill: {
                    colors: [
                      "rgba(" + gray300Rgb + ", .25)",
                      "rgba(" + inverseRgb + ", .25)",
                    ],
                  },
                },
              },
            }
          : {},
      title: {
        text: chartData.title,
        align: "center",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: themeFont,
          color: inverse,
        },
      },
      // colors: [themeColor, gray500],
      dataLabels: {
        enabled: chartData.type === "line",
        background: {
          enabled: true,
          padding: 4,
          borderRadius: 2,
          borderWidth: 0,
          opacity: 0.9,
          dropShadow: { enabled: false },
        },
      },
      stroke: { curve: "smooth", width: 3 },
      grid:
        chartData.type === "line"
          ? {
              borderColor: borderColor,
              row: {
                colors: ["rgba(" + inverseRgb + ", .25)", "transparent"],
                opacity: 0.3,
              },
            }
          : {},
      markers: { size: 4 },
      xaxis: {
        categories: chartData.labels,
        axisBorder: {
          show: true,
          color: "rgba(" + inverseRgb + ", .25)",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: -1,
        },

        axisTicks: {
          show: true,
          borderType: "solid",
          color: "rgba(" + inverseRgb + ", .25)",
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },

        labels: {
          style: {
            colors: inverse,
            fontSize: "12px",
            fontFamily: themeFont,
            fontWeight: themeFontWeight,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      yaxis: {
        //min: chartData.yaxis.min, max:chartData.yaxis.max ,
        labels: {
          style: {
            colors: inverse,
            fontSize: "12px",
            fontFamily: themeFont,
            fontWeight: themeFontWeight,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      //legend: { show: true, position: 'top', offsetY: -20, horizontalAlign: 'right', floating: true, fontFamily: themeFont, labels: { colors: inverse } }
      legend: { fontFamily: themeFont, labels: { colors: inverse } },
    };
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="row">
          <div className="col-xl-12">
            <div id="apexChartLineChart" className="mb-5">
              <Chart
                type={chartData.type === "column" ? "bar" : chartData.type}
                options={lineChartOptions}
                series={chartData.data}
              />
            </div>
            {/* 							
							<div id="apexChartColumnChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>COLUMN CHART</h6>
										<Chart type="bar" options={columnChartOptions} series={columnChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code2}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartAreaChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>AREA CHART</h6>
										<Chart type="area" options={areaChartOptions} series={areaChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code3}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartBarChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>BAR CHART</h6>
										<Chart type="bar" options={barChartOptions} series={barChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code4}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartMixedChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>MIXED CHART</h6>
										<Chart type="line" options={mixedChartOptions} series={mixedChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code5}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartCandlestickChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>CANDLESTICK CHART</h6>
										<Chart type="candlestick" options={candlestickChartOptions} series={candlestickChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code6}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartBubbleChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>BUBBLE CHART</h6>
										<Chart type="bubble" options={bubbleChartOptions} series={bubbleChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code7}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartScatterChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>SCATTER CHART</h6>
										<Chart type="scatter" options={scatterChartOptions} series={scatterChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code8}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartHeatmapChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>HEATMAP CHART</h6>
										<Chart type="heatmap" options={heatmapChartOptions} series={heatmapChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code9}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartPieChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>PIE CHART</h6>
										<Chart type="pie" options={pieChartOptions} series={pieChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code10}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartRadialBarChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>RADIAL BAR CHART</h6>
										<Chart type="radialBar" options={radialBarChartOptions} series={radialBarChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code11}</Highlight>
									</div>
								</Card>
							</div>
							
							<div id="apexChartRadarChart" className="mb-5">
								<Card>
									<CardBody>
										<h6>RADAR CHART</h6>
										<Chart type="radar" options={radarChartOptions} series={radarChartData} />
									</CardBody>
									<div className="hljs-container">
										<Highlight className='javascript'>{code12}</Highlight>
									</div>
								</Card>
							</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Multi_Chart;
