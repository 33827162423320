import * as Yup from 'yup';

const validationSchema = (t)=>{ return Yup.object().shape({
  raqRotationPlan: Yup.object().shape({
    name: Yup.string().required(() => t("Required")),
    name2: Yup.string().required(() => t("Required")),
    startDate: Yup.string().required(' is required'),
    endDate: Yup.string().required(() => t("Required")),
    description: Yup.string().required(() => t("Required")),
    allowIn: Yup.string().required(() => t("Required")),
    allowOut: Yup.string().required(() => t("Required")),
    count_Fingerprint: Yup.string().required(() => t("Required")),
  }),
  // sat: Yup.object().shape({
  //   startIn: Yup.string().required(' is required'),
  //   endOut: Yup.string().required('is required'),
  //   fingerprintTime1: Yup.string().required(' is required'),
  //   fingerprintTime2: Yup.string().required('required'),
  //   fingerprintTime3: Yup.string().required(' required'),
  //   fingerprintTime4: Yup.string().required('required'),
  //   allowIn: Yup.string().required('required'),
  //   allowOut: Yup.string().required(' required'),
  //   countFingerprint: Yup.string().required('required'),
  // }),
})}

export default validationSchema;