import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Link, useNavigate } from "react-router-dom";
import {
  DeleteInstallmentPayment,
  GetInstallmentPayment,
} from "../../../../store/SU/installmentPaymentSlice";
import { GetContractLease } from "../../../../store/SU/contractLeaseSlice";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import { Card, CardBody } from "../../../../components/card/card";
import { Form } from "react-bootstrap";
import ViewPaymentDetails from "./ViewPaymentDetails";
import Loader from "../../../../components/Loader/Loader";
import ConfirmDeleteDialog from "../../../../components/Alert/ConfirmDeleteDialog";
import CustomAlert from "../../../../components/Alert/CustomAlert";
import { ntxAPI } from "../../../../API/axios-custom";
import useGetPaymentMethod from "../../../../hooks/Payrolls/use-Get-PaymentMethod";

const InstallmentPaymentList = () => {
  const [contractCode, setContractCode] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10000);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const [ID, setID] = useState<string>("");

  useEffect(() => {
    dispatch(GetInstallmentPayment());
    dispatch(GetContractLease());
  }, []);
  const { InstallmentPaymentList, loading } = useAppSelector(
    (state) => state.InstallmentPayment
  );
  const { PaymentMethodList } = useGetPaymentMethod();

  const { ContractLeaseList } = useAppSelector((state) => state.Contracts);


  const filteredSearch = InstallmentPaymentList?.slice()?.filter((item: any) => {
    const searchLowerCase = contractCode.toLowerCase();
    return (
      item.contrcatCode?.toLowerCase().includes(searchLowerCase) 
    );
  });

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  // Handele Delete Installment Payment
  const handleDeleteInstallmentPayment = async (id: any) => {
    try {
      const { data } = await ntxAPI.post(
        `RealState/DeleteSuContractInstallmentPayment?ContractInstallmentPaymentId=${id}`
      );
      if (data.succeeded === true) {
        CustomAlert({ action: "Delete" });
        dispatch(GetInstallmentPayment());
      } else {
        CustomAlert({ action: "Error" });
      }
    } catch {
      CustomAlert({ action: "Error" });
    }

    //    await dispatch(DeleteInstallmentPayment(id))
    // .unwrap()
    // .then((res) => {
    //   if(res.succeeded === true){
    //     CustomAlert({ action: "Delete" });
    //   }else{
    //     CustomAlert({ action: "Error" });
    //   }
    // })
    // .catch((error) => {
    //   console.log(error);
    //   CustomAlert({ action: "Error" });
    // });
  };
 
  //_____________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "متابعة الإيجارات",
      link: null,
    },
  ];

  return (
    <div>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="متابعة الإيجارات" />

      <Card>
        <Loader loading={loading} />
        <CardBody>
          <div className="row">
            <div className="col-7 col-md-6 d-flex justify-content-start">
              <div className="col-xl-6  ">
                      <div className="form-group mb-3">
                        <label className="form-label">رقم العقد</label>
                        <Form.Select
                          className="form-select"
                          onChange={(e) => 
                            setContractCode(e.target.value)
                          }
                        >
                          <option value=" ">
                            {i18n.language === "ar" ? "إختر" : "Choose"}
                          </option>
                          {ContractLeaseList &&
                            ContractLeaseList?.map((item: any) => (
                              <option
                                key={item?.id}
                                value={item?.contractLeaseCode}
                              >
                                {item?.contractLeaseCode}
                              </option>
                            ))}
                        </Form.Select>
                      </div>
                    </div>

              
            </div>
          </div>
          <div className="table-responsive text-center">
            <table className="table table-hover text-nowrap">
              <thead className="table-dark">
                <tr>
                  <th className="pt-2 pb-2">رقم العقد</th>
                  <th className="pt-2 pb-2">تاريخ الدفع</th>
                  <th className="pt-2 pb-2">{t("Payment method")}</th>
                  {/* <th className="pt-2 pb-2">{t("Currency")}</th> */}
                  <th className="pt-2 pb-2">{t("Total")}</th>
                  <th className="pt-2 pb-2"> </th>
                </tr>
              </thead>

              <tbody>
                {currentData.map((item: any) => (
                  <tr key={item.id}>
                    <td className="align-middle">{item.contrcatCode}</td>
                    <td className="align-middle">{item.paymentDate}</td>
                    <td className="align-middle">
                      {
                        PaymentMethodList?.find(
                          (f: any) => f.id === item.paymentMethodId
                        )?.name
                      }
                    </td>
                    {/* <td className="align-middle">{item.currencyId}</td> */}
                    <td className="align-middle">{item.amount}</td>
                    <td className="align-middle">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-lg me-1"
                        data-bs-toggle="modal"
                        data-bs-target="#modalِInstallmentPayment"
                        data-toggle="tooltip"
                        title="عرض التفاصيل"
                        onClick={() => setID(item.id)}
                      >
                        <i className="bi bi-info-circle  fa-lg"></i>
                      </button>
                      <ConfirmDeleteDialog
                        onConfirm={() =>
                          handleDeleteInstallmentPayment(item.id)
                        }
                        title={`هل تريد حذف  هذا الدفع مع تفاصيله`}
                        message="لا يمكن التراجع عن هذا الإجــراء."
                        iconType={2}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <Pagination
        filteredData={filteredSearch}
        dataPerPage={dataPerPage}
        handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
        currentPage={currentPage}
      /> */}
        </CardBody>
      </Card>

      <ModalComponent title="عرض تفاصيل الدفع" id="modalِInstallmentPayment">
        {ID && <ViewPaymentDetails ID={ID} />}
      </ModalComponent>
    </div>
  );
};

export default InstallmentPaymentList;
