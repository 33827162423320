/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../../components/card/card.jsx";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanAssignmentListById,
  GetAssignmentById,
  GetAssignmentByPersonId,
} from "../../../../store/HR/Person/assignmentSlice.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import AssignmentForm from "./AssignmentForm.js";
import Loader from "../../../../components/Loader/Loader.js";

const Assignment = () => {
  // const [ID, setID ] = useState('5c5865a5-4d8d-5555-4444-32ae8a7b302a'); // Default assignment_ID

  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { AssignmentPerson, isLoading } = useSelector(
    (state) => state.Assignments
  );

  const { AssignmentListById, loading } = useSelector(
    (state) => state.Assignments
  );

  const currentId =
    Object.keys(AssignmentPerson)?.length > 0 &&
    AssignmentPerson?.allAssignment?.find((item) => item.isCurrent === true)?.id;

  const sortedList =
    AssignmentPerson.allAssignment &&
    AssignmentPerson.allAssignment.slice().sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateA - dateB;
    });

  useEffect(() => {
    dispatch(cleanAssignmentListById());
    dispatch(GetAssignmentByPersonId(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (AssignmentPerson?.allAssignment?.length) {
      dispatch(GetAssignmentById(currentId));
    }
  }, [dispatch, currentId]);

  //______________________________________________________________________________________________________
  // تهيئة القيمة الافتراضية ل أكتيف
  // const idCurrent = AssignmentPerson?.allAssignment?.find(f => f?.isCurrent=== true)?.id
  const [active, setActive] = useState();
  useEffect(() => {
    if (AssignmentPerson?.allAssignment?.length > 0) {
      setActive(
        AssignmentPerson.allAssignment?.find((f) => f.isCurrent === true).id
      );
    }
  }, [AssignmentPerson]);
  console.log(active);
  //________________________________________________________________________________________________
  const handlebutton = async (item) => {
    dispatch(cleanAssignmentListById());
    dispatch(GetAssignmentById(item.id));
    setActive(item.id);
  };

  return (
    <>
      <div className="row">
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a
                  href="#/"
                  onClick={() => Navigate("/HR/Employees", { replace: true })}
                >
                  {t("Employees")}
                </a>
              </li>
              <li className="breadcrumb-item active">
                {t("Employee Aassignment")}{" "}
              </li>
            </ul>
            <h1 className="page-header mb-0">
              {i18n.language === "ar"
                ? AssignmentPerson.name2
                : AssignmentPerson.name}
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <Card>
              <div className="mb-2">
                <Card>
                  <CardBody>
                    <div
                      style={{ direction: "ltr" }}
                      className="nav-wizards-container"
                    >
                      <nav className="nav nav-wizards-2 mb-3">
                        {Object.keys(AssignmentPerson)?.length
                          ? sortedList.map((item) => (
                              <div key={item.id} className="nav-item col">
                                <Link
                                  className={`nav-link ${
                                    active === item.id ? "active" : ""
                                  }`}
                                  onClick={() => handlebutton(item)}
                                >
                                  <div className={`nav-text `}>
                                    {item.isCurrent ? (
                                      <i className="far fa-lg fa-fw me-2 fa-clock"></i>
                                    ) : (
                                      ""
                                    )}
                                    {item.startDate}
                                  </div>
                                </Link>
                              </div>
                            ))
                          : ""}
                      </nav>
                    </div>
                  </CardBody>
                </Card>
              </div>

              <CardBody>
                <Loader loading={isLoading} />
                <Loader loading={loading} />
                {Object.keys(AssignmentPerson)?.length
                  ? (Object.keys(AssignmentListById)?.length > 0 ||
                      AssignmentPerson.allAssignment?.length === 0) && (
                      <AssignmentForm
                        AssignmentListById={AssignmentListById || {}}
                        AssignmentPerson={AssignmentPerson}
                      />
                    )
                  : ""}
              </CardBody>
            </Card>
          </div>
        </div>

        <div />
      </div>
    </>
  );
};

export default Assignment;
