import React, { useEffect, useState } from "react";
import Select from "react-select";
import { CardBody, Card } from "../../../components/card/card";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { GetLookupManagList } from "../../../store/NTX/Lookup/lookupsSlice";
import { useTranslation } from "react-i18next";
import Pagination from "../../../components/Pagination/Pagination";
import SmallModalComponent from "../../../components/Modal/SmallModalComponent";
import AddLookup from "./AddLookup";
import Loader from "../../../components/Loader/Loader";
import UpdateLookup from "./UpdateLookup";
import { Form } from "react-bootstrap";
import useLookupModule from "../../../hooks/Lookups/use-Lookup-Module";
import Breadcrumb from "../../../components/Shared/Breadcrumb";

const Lookup = () => {
  const [search, setSearch] = useState("");
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [categoryValue, setCategoryValue] = useState<string>();
  const [categoryName, setCategoryName] = useState<string>();
  const [moduleId, setModuleId] = useState<string>();
  const [ID, setID] = useState<string>(); //      استعماله في مكون التعديل
  const [optionSelect, setOptionSelect] = useState<any>(); //      استعماله في مكون التعديل
  const { Module } = useLookupModule();
  const { LookupData, loading } = useAppSelector((state) => state.LookupData);
  useEffect(() => {
    dispatch(GetLookupManagList());
  }, []);

 

  useEffect(() => {
    if (Module?.length) {
      setModuleId(Module[0].id);
    }
  }, [Module]);

  useEffect(() => {
    const options= LookupData.map((item) => ({
      value: item.categoryValue,
      label: item.categoryName,
      moduleId: item.moduleId,
    }))?.filter(f => f.moduleId === moduleId);
    setOptionSelect(options);
  }, [moduleId]);

   
  const FilterByCategory = LookupData?.filter(
    (f) => f.categoryValue === categoryValue
  )[0]?.lookupsList;
 
  //____________________
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = FilterByCategory?.filter(
    (item) =>
      item?.meaning.toLowerCase().includes(search.toLowerCase()) ||
      item?.meaning2.toLowerCase().includes(search.toLowerCase())
  )?.slice(indexOfFirstData, indexOfLastData);

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "GeneralSystemLists",
      link: null,
    },
  ];

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="GeneralSystemLists" />

      <Card>
        <Loader loading={loading} />
        <CardBody>
          <div className="" style={{ minHeight: "100vh" }}>
            <div className="row">
              <div className="col-xl-3 mx-auto">
                <div className="form-group mb-3">
                  <label className="form-label">بحث</label>
                  <input
                    type="text"
                    className="form-control ps-35px"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setCurrentPage(1);
                    }}
                    placeholder={t("Search") + " ..."}
                  />
                </div>
              </div>

              <div className="col-xl-2 mx-auto">
                <div className="form-group mb-3">
                  <label className="form-label">التطبيق</label>
                  <Form.Select
                    className="form-select"
                    onChange={(e)=>  setModuleId(e.target.value)}
                    // value={formik.values.TITLE}
                  >
                    <option key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {Module &&
                      Module.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </div>

              <div className="col-xl-5 mx-auto">
                <div className="form-group mb-3">
                  <label className="form-label">النوع</label>
                  {/* {LookupData.length > 0 && ( */}
                    <Select
                      isSearchable
                      options={optionSelect}
                      classNamePrefix="react-select"
                      name="Select"
                      onChange={(option) => {
                        setCategoryValue(option?.value);
                        setCurrentPage(1);
                        setCategoryName(option?.label);
                      }}
                      placeholder="اخـتــر ... "
                      defaultValue={{
                        value: LookupData[0]?.categoryValue,
                        label: LookupData[0]?.categoryName,
                      }}
                    />
                  {/* // )} */}
                </div>
              </div>

              <div className="col-xl-2 mx-auto" style={{ paddingTop: "28px" }}>
                <button
                  type="button"
                  className="btn btn-theme"
                  data-bs-toggle="modal"
                  data-bs-target="#AddLookup"
                >
                  <i className="far fa-lg fa-fw me-2 fa-plus"></i>
                  {t("Add")}
                </button>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th scope="col" style={{ width: "25%" }}>
                      {t("Arabic Name")}
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      {t("English Name")}
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      {t("Code")}
                    </th>
                    <th scope="col" style={{ width: "25%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">{item?.meaning2}</td>
                      <td className="align-middle">{item?.meaning}</td>
                      <td className="align-middle">{item?.lookupCode}</td>
                      <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-outline-lime"
                            data-bs-toggle="modal"
                            data-bs-target="#UpdateLookup"
                            onClick={() => setID(item.id)}
                          >
                            <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                            {t("Button.Edit")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {FilterByCategory?.length > 0 && (
              <Pagination
                filteredData={FilterByCategory}
                dataPerPage={dataPerPage}
                handlePagination={(pageNumber: number) =>
                  setCurrentPage(pageNumber)
                }
                currentPage={currentPage}
              />
            )}
          </div>
        </CardBody>
      </Card>

      <SmallModalComponent id={"AddLookup"} title={"اضافة"}>
        {categoryValue && categoryName && (
          <AddLookup
            categoryName={categoryName}
            categoryValue={categoryValue}
            FilterByCategory={FilterByCategory}
          />
        )}
      </SmallModalComponent>

      <SmallModalComponent id={"UpdateLookup"} title={"تعديل"}>
        {ID && categoryValue && categoryName && (
          <UpdateLookup
            categoryName={categoryName}
            categoryValue={categoryValue}
            FilterByCategory={FilterByCategory}
            ID={ID}
          />
        )}
      </SmallModalComponent>
    </>
  );
};

export default Lookup;
