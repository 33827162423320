import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "../../../../components/card/card";
import Loader from "../../../../components/Loader/Loader";
import Form from "react-bootstrap/Form";
import PIECHART from "../../../../components/Charts/PIECHART";
import POLARCHART from "../../../../components/Charts/POLARCHART";
import DOUGHNUTCHART from "../../../../components/Charts/DOUGHNUTCHART";
import PIECHART2 from "../../../../components/Charts/PIECHART2";
import RADIALBARCHART from "../../../../components/Charts/RADIALBARCHART";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Link } from "react-router-dom";

const AttendanceAnalysis = () => {
  const { t, i18n } = useTranslation();
  //__________________________________________________

  return (
    <>
      <div className="row">
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#/">{t("Home")}</a>
              </li>
              <li className="breadcrumb-item active">تحليل الحضور</li>
            </ul>
            <h1 className="page-header mb-0">تحليل الحضور</h1>
          </div>
        </div>

        <Card>
          <CardBody>
            <Loader />
            <div className="row">
              <div className="col-xl-5">
                <div className="input-group mb-3 ">
                  <span className="input-group-text">من</span>

                  <Form.Control
                    type="date"
                    className="form-control"
                    name="dateStart"
                  />
                </div>
              </div>
              

              <div className="col-xl-5">
                <div className="input-group mb-3">
                  <span className="input-group-text">إلى</span>
                  <Form.Control
                    type="date"
                    className="form-control"
                    name="dateEnd"
                  />
                </div>
              </div>

              <div className="col-xl-2">
                <button type="submit" className="btn btn-outline-theme mb-1">
                  <i className="fas fa-lg fa-fw me-2 fa-filter"></i> بــحـث
                </button>
              </div>
            </div>

            <hr className="mb-3"></hr>

            <div className="row">
              <div className="col-xl-4">
                  <Card>
                <Link to="AnalysisTable">
                    <CardHeader>
                      <div className="text-center">ABSENT</div>
                    </CardHeader>
                </Link>
                    <PIECHART />
                  </Card>
              </div>

              <div className="col-xl-4">
                <Card>
                <Link to="AnalysisTable">

                  <CardHeader>
                    <div className="text-center">EARLY</div>
                  </CardHeader>
                </Link>
                  {/* <PIECHART2 /> */}
                  <POLARCHART/>
                </Card>
              </div>

              <div className="col-xl-4">
                <Card>
                <Link to="AnalysisTable">

                  <CardHeader>
                    <div className="text-center">LATE</div>
                  </CardHeader>
                </Link>
                  <DOUGHNUTCHART />
                </Card>
              </div>
            </div>

            <div className="mb-3"></div>

            <div className="row">
              <div className="col-xl-6">
                <Card>
                <Link to="AnalysisTable">

                  <CardHeader>
                    <div className="text-center">Over Time</div>
                  </CardHeader>
                </Link>
                  <PIECHART2 />
                </Card>
              </div>

              <div className="col-xl-6"  >
                <Card style={{direction:'ltr'}}>
                <Link to="AnalysisTable">

                  <CardHeader>
                    <div className="text-center">Missed Punches</div>
                  </CardHeader>
                </Link>
                  <RADIALBARCHART />
                </Card>
              </div>
            </div>
          </CardBody>
        </Card>

        <div />
      </div>
    </>
  );
};

export default AttendanceAnalysis;
