import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "../../../../components/card/card";
import * as Yup from "yup";

import {
  AddOrganizationStructure,
  GetOrganizationStructure,
  GetOrganizationWithoutStructure,
} from "../../../../store/HR/Organizations/OrganizationStructureSice";
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx';

const ListForm = ({ orgData, data }) => {
  // console.log(orgData);

  //   console.log(data);

  var ListChildOrg =
    orgData &&
    data?.filter(
      (f) =>
        (f.organizationIdParent === orgData.organizationIdChild) &
        (f.organizationIdChild !== f.organizationIdParent)
    );
  // console.log(ListChildOrg);

  // const { id } = useParams();
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // Get Organizations from redux
  const { Organizations } = useSelector((state) => state.Organizations);
  const { OrgStructureList } = useSelector(
    (state) => state.OrganizationStructure
  );
  const { OrganizationListWithoutStructure } = useSelector(
    (state) => state.OrganizationStructure
  );

  //   console.log(OrganizationListWithoutStructure);
  useEffect(() => {
    dispatch(GetOrganizationWithoutStructure());
  }, [dispatch]);

  // add
  const formik = useFormik({
    initialValues: {
      //   businessGroupId: "",
      organizationIdParent: "",
      organizationIdChild: "",
    },
    validationSchema: Yup.object().shape({
      organizationIdChild: Yup.string().required(() => t("Required")),
    }),
    onSubmit: (values) => {
      dispatch(
        AddOrganizationStructure({
          // businessGroupId: null ,
          organizationIdParent: orgData.organizationIdChild,
          organizationIdChild: values.organizationIdChild,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: "Add" });
          dispatch(GetOrganizationStructure());
          dispatch(GetOrganizationWithoutStructure())
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
          console.log(error);
        });
    },
  });



  return (
    <Card>
      <CardBody>
        <div className="row">
         
<Form className="text-center" onSubmit={formik.handleSubmit} >
          <div className="col-xl-12">
            <div className="form-group row mb-3">
              <label className=" col-sm-2 col-form-label">{t("Parent")}</label>
              <div className="col-sm-10">
              <Form.Control
                className="form-control"
                type="text"
                value={orgData?.childName || ""}
                disabled={true}
              />
              </div>
            </div>
          </div>

          <div className="col-xl-12">
            

              <div className="form-group row mb-3">
                <label className="col-sm-2 col-form-label">{t("child")}</label>
                <div className="col-sm-10">
                  <Form.Select
                    className="form-select form-select-lg"
                    name="organizationIdChild"
                    value={formik.values.organizationIdChild}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.organizationIdChild &&
                      formik.errors.organizationIdChild
                    }
                  >
                     <option value="">
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>

                  {OrganizationListWithoutStructure?.map((item, idx) => (
                    <option key={++idx} value={item.id}>
                      {i18n.language === "ar" ? item.name2 : item.name1}
                    </option>
                  ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.organizationIdChild}
                  </Form.Control.Feedback>
                </div>
              </div>
              
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-outline-theme mb-1"
                // hidden={hiddenAddStructure}
              >
                <i className="fa fa-plus-circle fa-fw me-1"></i>
                {formik.isSubmitting ? "Adding..." : "Add"}
              </button>


           
          </div>
 </Form>
          
        </div>

        <div className="mb-3"></div>

    

        <div className="row">
          <div className="col-lg-12">
            <table className="table text-center">
              <thead className="table-dark">
                <tr>
                  <th scope="col">{t('Name')}</th>
                </tr>
              </thead>
              <tbody>
                {ListChildOrg?.map((item, idx) => (
                  <tr key={item.id}>
                    <td>
                      {i18n.language === "ar"
                        ? item.childName2
                        : item.childName}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>


      </CardBody>
    </Card>
  );
};

export default ListForm;
