/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import SkeletonLine from "../../../../../../components/SkeletonLoader/SkeletonLine";
import { ntxAPI } from "../../../../../../API/axios-custom";
// import SkeletonLoader from "../../../../components/SkeletonLoader/SkeletonLine";

const TypeT = ({ item, formData, setFormData }) => {
  const { t, i18n } = useTranslation();
  const [TableDataList, setTableDataList] = useState([]);

  const [isLoading, setIsLoading] = useState(true); // New state for loading indicator

  useEffect(() => {
    async function GetTableData() {
      try {
        const { data } = await ntxAPI.get(
          `NtxSetting/GetDataByTabelId?TabelId=${item?.tableId}`
        );

        var DataList = data.map((item) => ({
          value: item.id,
          label: i18n.language === "ar" ? item.name2 : item.name,
        }));

        setTableDataList(DataList);
        setIsLoading(false); // Data has been loaded, set loading indicator to false
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false); // In case of an error, set loading indicator to false
      }
    }

    GetTableData();
  }, [item?.tableId]);

  return (
    <>
      <div className="form-group mb-3">
        <label className="form-label">
          {i18n.language === "ar" ? item.lableText2 : item.lableText}
        </label>
        <div className="row">
          {isLoading ? ( 
          <SkeletonLine/>
        ) : (
          TableDataList?.length > 0 && (
          <Select
            isLoading={isLoading}
            isClearable
            classNamePrefix="react-select"
            isSearchable={true}
            options={TableDataList}
            name={item.id}
            required={item.requiredFlag}
            onChange={(option) =>
              setFormData({
                ...formData,
                [item.id]: option == null ? "" : option.label,
              })
            }
            placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
          />
           )
        )} 
        </div>
      </div>
    </>
  );
};

export default TypeT;
