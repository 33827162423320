import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
// import { getData, postData } from "../../Axios/axiosData";

const initialState = {
  PeriodTimeList: [],
  PersonlListWithPayrol: [],
  PayrollActionList:[],
  AssignmentActionList:[],
  RunResultList:[],
  loading: false,
  error: null,
};




export const ActinRunPyroll = createAsyncThunk(
      "RunPyroll/ActinRunPyroll",
      async (item, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
 
        try {
          // const data = await postData('Payroll/RunPayroll' , item);
          const {data} = await ntxAPI.post('Payroll/RunPayroll' , item)
          console.log(data);
          return data;
        } catch (error) {
          console.error(error);
          return rejectWithValue(error.message);
        }
      }
    );

export const GetPeriodTimeByPayrollId = createAsyncThunk(
  "RunPyroll/GetPeriodTimeByPayrollId",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Payroll/GetPeriodTimeByPayrollId?PayrollId=${id}`);
      const {data} = await ntxAPI.get( `Payroll/GetPeriodTimeByPayrollId?PayrollId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);


export const GetPersonlListWithPayrol = createAsyncThunk(
  "RunPyroll/GetPersonlListWithPayrol",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Payroll/GetPersonlListWithPayrol`);
      const {data} = await ntxAPI.get(`Payroll/GetPersonlListWithPayrol` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetPayrollActionList = createAsyncThunk(
  "RunPyroll/GetPayrollActionList",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    console.log(id);
    try {
      // const data = await getData(`Payroll/GetPayrollActionListByPayrollId?PayrollId=${id}`);
      const {data} = await ntxAPI.get( `Payroll/GetPayrollActionListByPayrollId?PayrollId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetAssignmentActionList = createAsyncThunk(
  "RunPyroll/GetAssignmentActionList",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // const data = await getData(`Payroll/GetAssignmentActionListByPayrollActionId?PayrollActionId=${id}`);
      const {data} = await ntxAPI.get( `Payroll/GetAssignmentActionListByPayrollActionId?PayrollActionId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetRunResultList = createAsyncThunk(
  "RunPyroll/GetRunResultList",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    console.log(id);
    try {
      // const data = await getData(`Payroll/GetRunResultListByAssignmentActionId?AssignmentActionId=${id}`);
      const {data} = await ntxAPI.get(`Payroll/GetRunResultListByAssignmentActionId?AssignmentActionId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const DeletePayrollAction = createAsyncThunk(
  "RunPyroll/DeletePayrollAction",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // const data = await postData(`Payroll/DeletePayrollAction?PayrollActionId=${id}`);
      const {data} = await ntxAPI.post( `Payroll/DeletePayrollAction?PayrollActionId=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const ConfirmPayrollAction = createAsyncThunk(
  "RunPyroll/ConfirmPayrollAction",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // const data = await postData(`Payroll/ConfirmPayrollAction?PayrollActionId=${id}`);
      const {data} = await ntxAPI.post(`Payroll/ConfirmPayrollAction?PayrollActionId=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);





const RunPayrollSlice = createSlice({
  name: "RunPyroll",
  initialState,
  reducers: {},
  extraReducers: {
    /////// get GetPeriodTimeByPayrollId
    [GetPeriodTimeByPayrollId.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.PeriodTimeList= [];
    },
    [GetPeriodTimeByPayrollId.fulfilled]: (state, action) => {
      state.loading = false;
      state.PeriodTimeList= action.payload;
    },
    [GetPeriodTimeByPayrollId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

       ///////  GetPersonlListWithPayrol
      [GetPersonlListWithPayrol.pending]: (state) => {
        state.loading = true;
        state.error = null;
        state.PersonlListWithPayrol = []
      },
      [GetPersonlListWithPayrol.fulfilled]: (state, action) => {
        state.loading = false;
        state.PersonlListWithPayrol= action.payload;
      },
      [GetPersonlListWithPayrol.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

             ///////  Get Payroll Action List
             [GetPayrollActionList.pending]: (state) => {
              state.loading = true;
              state.error = null;
              state.PayrollActionList = []
            },
            [GetPayrollActionList.fulfilled]: (state, action) => {
              state.loading = false;
              state.PayrollActionList= action.payload;
            },
            [GetPayrollActionList.rejected]: (state, action) => {
              state.loading = false;
              state.error = action.payload;
            },

            
             /////// Get Assignment Action List
             [GetAssignmentActionList.pending]: (state) => {
              state.loading = true;
              state.error = null;
              state.AssignmentActionList = [];
            },
            [GetAssignmentActionList.fulfilled]: (state, action) => {
              state.loading = false;
              state.AssignmentActionList= action.payload;
            },
            [GetAssignmentActionList.rejected]: (state, action) => {
              state.loading = false;
              state.error = action.payload;
            },

                     /////// Get Run Result List
                    [GetRunResultList.pending]: (state) => {
                      state.loading = true;
                      state.error = null;
                      state.AssignmentActionList = [];
                    },
                    [GetRunResultList.fulfilled]: (state, action) => {
                      state.loading = false;
                      state.RunResultList= action.payload;
                    },
                    [GetRunResultList.rejected]: (state, action) => {
                      state.loading = false;
                      state.error = action.payload;
                    },

  },
});

export default RunPayrollSlice.reducer;
