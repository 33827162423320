import React from 'react'
import { memo } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConfirmDeleteDialog from '../../../components/Alert/ConfirmDeleteDialog';
import CustomAlert from '../../../components/Alert/CustomAlert.tsx';
import { DeleteAmeCondition, GetAmeCondition } from '../../../store/Ame/ameSlice';

const ConditionList = () => {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  // GetAmeConditionListBy DynamicFormId
  useEffect(() => {
    dispatch(GetAmeCondition(id));
  }, [dispatch , id]);
  const { AmeConditionList } = useSelector((state) => state.Ame);
  console.log(AmeConditionList);
  // //

    // Handele Delete AmeCondition
    const handleDeleteAmeCondition = (item)=>{
        dispatch(DeleteAmeCondition(item.id)).unwrap()
      .then(() => {
          dispatch(GetAmeCondition(id));
          CustomAlert({ action: "Add" });
      })
      .catch((error) => {
        console.log(error);
        CustomAlert({ action: "Error" });
      });
      }

  return (
    <>
            <table className="table">
              <thead className="table-dark">
                <tr>
                  <th scope="col">الرقم</th>
                  <th scope="col">الاسم</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {AmeConditionList &&  AmeConditionList.map((item, idx) => (
                  <tr key={++idx}>
                    <td className="align-middle">{++idx}</td>
                    <td className="align-middle">
                      {i18n.language === "ar" ? item?.name : item?.name2}
                    </td>
                    <td className="align-middle">

                      <ConfirmDeleteDialog
                           onConfirm={()=>handleDeleteAmeCondition(item)}
                            title="Are you sure?"
                            message="This action cannot be undone."
                        />


                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

    </>
  )
}

export default memo(ConditionList) ;