import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axios-custom";




export const GetApprovalMemberByGroupId = createAsyncThunk("ApprovalMember/GetApprovalMemberByGroupId", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`AmeApprovalGroup/GetApprovalGroupMemberListByGroupId?Id=${id}`);
      const {data} = await ntxAPI.get(`AmeApprovalGroup/GetApprovalGroupMemberListByGroupId?Id=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });



  export const insertApprovalMember = createAsyncThunk("ApprovalMember/insertApprovalMember", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("AmeApprovalGroup/AddApprovalGroupMember" , item);
      const {data} = await ntxAPI.post("AmeApprovalGroup/AddApprovalGroupMember" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });



  export const DeleteAprovalMember = createAsyncThunk("ApprovalMember/DeleteAprovalMember", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData(`AmeApprovalGroup/DeleteApprovalGroupMember?Id=${id}`);
      const {data} = await ntxAPI.post( `AmeApprovalGroup/DeleteApprovalGroupMember?Id=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });
  



  const initialState = { ApprovalMemberList: [] ,  loading: false, error: null };

  const ApprovalMemberSlice = createSlice({
    name: "ApprovalMember",
    initialState,
    reducers: { },
    extraReducers: {
      /////// get Approval Member List               
      [GetApprovalMemberByGroupId.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetApprovalMemberByGroupId.fulfilled]: (state, action) => {
        state.loading = false;
        state.ApprovalMemberList = action.payload;
      },
      [GetApprovalMemberByGroupId.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

      //// insert Approval Member
      [insertApprovalMember.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [insertApprovalMember.fulfilled]: (state, action) => {
        state.loading = false;
        state.ApprovalMemberList.push(action.payload);
      },
      [insertApprovalMember.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

    /// Delete 
    [DeleteAprovalMember.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [DeleteAprovalMember.fulfilled]: (state, action) => {
      state.loading = false;
      state.ApprovalMemberList = state.ApprovalMemberList?.filter((el) => el.id !== action.payload)
    },
    [DeleteAprovalMember.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },



   
    },
  });
  
  export default ApprovalMemberSlice.reducer;