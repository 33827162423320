import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardExpandToggler,
} from "../../../../components/card/card.jsx";
import { getPayrolls } from "../../../../store/HR/Payroll/PayrollSlice.js";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../components/Pagination/Pagination.jsx";
import Loader from "../../../../components/Loader/Loader";
import ModalComponent from "../../../../components/Modal/ModalComponent.jsx";
import { AddPayroll } from "./AddPayroll.js";

const Payroll = () => {
  const [Show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();
  const dispatch = useDispatch();
  /// Get all Payrolls from store
  const { Payrolls, loading } = useSelector((state) => state.Payrolls);
;
  useEffect(() => {
    dispatch(getPayrolls());
  }, [dispatch]);
 



  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = Payrolls?.filter(
    (item) =>
      item.name.toLowerCase().includes(search.toLowerCase()) ||
      item.name2.toLowerCase().includes(search.toLowerCase())
  )
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return b[sortBy] > a[sortBy] ? 1 : -1;
      }
    })
    .slice(indexOfFirstData, indexOfLastData);

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);

  // report to xls
  const handleReport = () => {
    const csv = Papa.unparse({
      fields: ["id", "name"],
      data: currentData,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  


  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active">{t('Payrolls')}</li>
          </ul>
          <h1 className="page-header mb-0">{t('Payrolls')}</h1>
        </div>
      </div>
      <Card>
        <CardBody>
          <Loader loading={loading} />
          <div className="tab-content p-4">
            <div className="tab-pane fade show active">
              <div className="row">
                <div className="col-7 col-md-6 d-flex justify-content-start">
                  <div className="input-group mb-4">
                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          onChange={(e)=> setSearch(e.target.value)}
                          placeholder={t("Search") + " ..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6 text-end">
                  <a
                    href="#/"
                    className="btn btn-theme me-1"
                    data-bs-toggle="modal"
                    data-bs-target="#modalAddPayroll"
                    onClick={()=>setShow(true)}
                    // hidden={hiddenAddElement}
                  >
                    <i className="fa fa-plus-circle fa-fw me-1"></i>
                    {t("Add Payroll")}
                  </a>
                  <button
                    onClick={handleReport}
                    className="btn btn-outline-default text-nowrap rounded-2"
                    tabIndex={0}
                    aria-controls="datatable"
                    type="button"
                  >
                    <span>
                      <i className="fa fa-file-excel fa-fw me-1" /> Export CSV
                    </span>
                  </button>
                </div>
              </div>

              {/* //  START TABLE   */}
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col" onClick={() => handleSort("name")}>
                        {t("Name")}
                      </th>
                      <th scope="col" onClick={() => handleSort("periodType")}>
                        {t("Type of duration")}
                      </th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={item.id}>
                        <td className="align-middle">
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </td>
                        <td className="align-middle">
                          {item.periodType === "M" ? "شهري" : "أسبوعي"}
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              type="button"
                              onClick={() => Navigate(`${item.id}/ElementLink`)}
                              className="btn btn-outline-lime"
                              //  hidden={hiddenElementLink}
                            >
                              <i className="fas fa-lg fa-fw me-2 fa-link"></i>
                              {t("Link Payroll Elements")}
                            </button>

                            <button
                              type="button"
                              className="btn btn-outline-lime"
                              // hidden={hiddenEditPayroll}
                            >
                              <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                              {t("Button.Edit")}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                filteredData={Payrolls}
                // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                dataPerPage={dataPerPage}
                handlePagination={handlePagination}
                currentPage={currentPage}
              />

              {/* </CardBody> */}
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="modal fade" id="modalِAPayroll">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t('Add Payroll')}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div className="modal-body">

            
          </div>
        </div>
      </div>
    </div>

      <ModalComponent id="modalAddPayroll" title={t('Add Payroll')} >
    
              <AddPayroll/>

      </ModalComponent>
    </>
  );
};

export default Payroll;
