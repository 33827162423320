import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../../../components/Loader/Loader";
// import { getPayrolls } from "../../../../store/Payroll/PayrollSlice.js";
import { useTranslation } from "react-i18next";
import { GetDynamicForm } from "../../../../../store/NTX/FlexValue/Form/formSlice.js";
import Pagination from "../../../../../components/Pagination/Pagination.jsx";
import useLookupModule from "../../../../../hooks/Lookups/use-Lookup-Module";
import { Card, CardBody } from "../../../../../components/card/card";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { GetReportsList } from "../../../../../store/NTX/Reports/reportSlice";

interface Report {
  id: string;
  name: string;
  name2: string;
  moduleId: string;
  tableName: string;
  query: string;
  description: string;
  pageType: string;
  reportType: string;
}

interface ReportsListState {
  ReportsList: Report[];
  loading: boolean;
  // Add any other properties you have in the ReportsList
}

interface LookupModule {
  Module: any[];
}

function Index() {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("asc");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataPerPage] = useState<number>(10);
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();

  //__________________________________________________
  const { ReportsList, loading } = useAppSelector(
    (state) => state.Reports
  ) as ReportsListState;
  useEffect(() => {
    dispatch(GetReportsList());
  }, [dispatch]);

    // console.log(ReportsList);

  //_____________________________________________________
  // جلب بيانات الموديول من الستور ووضع الخيار الاول من السلكت في
  // التخزين المحلي وايضا لفلترة البيانات حسب لموديول تلقائياً
  const [selectedValue, setSelectedValue] = useState("");

  const { Module }: LookupModule = useLookupModule();

  useEffect(() => {
    if (Module?.length) {
      setSelectedValue(Module[0].id);
    }
  }, [Module]);


  const filteredDataByModuleId = ReportsList?.filter(
    (item) => item.moduleId === selectedValue
  );
  //_________________________________________________________________________________________________

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredDataByModuleId
    ?.filter((item) => item.name2.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortBy as keyof Report] > b[sortBy as keyof Report] ? 1 : -1;
      } else {
        return b[sortBy as keyof Report] > a[sortBy as keyof Report] ? 1 : -1;
      }
    })
    .slice(indexOfFirstData, indexOfLastData);

  // console.log(filteredDataByModuleId );

  const handlePagination = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active">{t("Reports")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Reports_Management")}</h1>
        </div>
      </div>
      <Card>
        <Loader loading={loading} />
        <div className="tab-content p-4">
          <div className="tab-pane fade show active">
            <div className="row">
              <div className="col-7 col-md-6 d-flex justify-content-start">
                <div className="input-group mb-4">
                  <div className="flex-fill position-relative">
                    <div className="input-group mb-4">
                      <select
                        className="btn btn-outline-default p-0 dropdown-toggle"
                        value={selectedValue}
                        onChange={(event) =>
                          setSelectedValue(event.target.value)
                        }
                      >
                        {Module &&
                          Module.map((item) => (
                            <option
                              value={item.id}
                              key={item.id}
                              className="dropdown-item"
                            >
                              {i18n.language === "ar" ? item.name2 : item.name}{" "}
                            </option>
                          ))}
                      </select>

                      <div className="flex-fill position-relative">
                        <div className="input-group">
                          <div
                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                            style={{ zIndex: 1020, right: "10px" }}
                          >
                            <i className="fa fa-search opacity-5"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control ps-35px"
                            placeholder={t("Search")}
                            value={search}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5 col-md-6 text-end">
                <button className="btn btn-theme" onClick={() => Navigate(`AddReport`)}>
                  <i className="fa fa-plus-circle fa-fw me-1"></i>
                  {t('Add_Reports')}
                </button>
              </div>
            </div>


            {/* //  START TABLE   */}
            <div className="table-responsive">
              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th scope="col" onClick={() => handleSort("Name")}>
                      {t("Reports_Name")}
                    </th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={item.id}>
                      <td className="align-middle">
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            onClick={() => Navigate(`${item.id}/AddReportShape`)}
                            className="btn btn-outline-lime"
                          >
                            <i className="fab fa-lg fa-fw me-2 fa-elementor"></i>
                            أشكال التقرير
                          </button>

                          <button
                            type="button"
                            className="btn btn-outline-lime"
                            // onClick={() => Navigate(`${item.id}/UpdateReport`)}
                            onClick={() => {
                              const newLocation = `Reports/${item.id}/UpdateReport`;
                              window.location.href = newLocation;
                            }}
                          >
                            <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                            {t("Edit_Reports")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              filteredData={filteredDataByModuleId}
              // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
              dataPerPage={dataPerPage}
              handlePagination={handlePagination}
              currentPage={currentPage}
            />
          </div>
        </div>
      </Card>
    </>
  );
}

export default Index;
