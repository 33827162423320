import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardExpandToggler,
} from "../../../../components/card/card.jsx";
import { getPayrolls } from "../../../../store/HR/Payroll/PayrollSlice.js";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../components/Pagination/Pagination.jsx";
import Loader from "../../../../components/Loader/Loader";

const PayrollRun = () => {
    const { t , i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();
  const dispatch = useDispatch();
 /// Get all Payrolls from store
  const { Payrolls, loading } = useSelector((state) => state.Payrolls) 
  
  useEffect(() => {
      dispatch(getPayrolls());
  }, [dispatch]);
console.log(Payrolls);

  
 const handleSearch = (event) => {
    setSearch(event.target.value);  
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData =Payrolls 
    ?.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase()) || 
    item.name2.toLowerCase().includes(search.toLowerCase()) 
     )
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return b[sortBy] > a[sortBy] ? 1 : -1;
      }
    })
    .slice(indexOfFirstData, indexOfLastData);

    const handlePagination = (pageNumber) => setCurrentPage(pageNumber);



    const handleNavigate = (item) => {
      localStorage.setItem("PayrollName2", item.name2);
      localStorage.setItem("PayrollName", item.name);
      Navigate(`${item.id}/ActionList`)
    };




  return (
    <>
          <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active">{t("Run Payrolls")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Run Payrolls")}</h1>
        </div>
      </div>
    <Card>
      <CardBody>
            <Loader loading={loading} />
     <div className="tab-content p-4">
        <div className="tab-pane fade show active">
          <div className="row">
            <div className="col-7 col-md-6 d-flex justify-content-start">
              <div className="input-group mb-4">
                <div className="flex-fill position-relative">
                  <div className="input-group">
                    <div
                      className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                      style={{ zIndex: 1020, right: "10px" }}
                    >
                      <i className="fa fa-search opacity-5"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control ps-35px"
                      onChange={handleSearch}
                      placeholder={t("Search") + " ..."}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //  START TABLE   */}
                       <div className="table-responsive">
                 <table className="table">
                 <thead className="table-dark">
                     <tr>
                      <th
                        scope="col"
                      >
                       {t('Name')}
                      </th>
                      <th
                        scope="col"
                      >
                     {t('Type of duration')}
                      </th>

                      <th
                       scope="col"
                      >
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                      {currentData.map((item , index )=> (
                      <tr key={item.id}>
                      <td className="align-middle">{i18n.language === 'ar' ? item.name2 : item.name }</td>
                      <td className="align-middle">{item.periodType ==='M'? t("Monthly") : t("Weekly") }</td>
                      <td>
                      
                        
                      <div className="btn-group">
                      <button type="button" onClick={() => Navigate(`${item.id}/AddPayrollRun`)} className="btn btn-outline-lime"  >
                        <i className="fas fa-lg fa-fw me-2 fa-money-bill-alt"></i>
                        {t("Create Run Payrolls")}
                      </button>
                      {/* Pay/payrollsRun/:payrollId/ */}
                      <button type="button" onClick={() => handleNavigate(item)} className="btn btn-outline-lime" >
                      <i className="fab fa-lg fa-fw me-2 fa-windows"></i>
                      {t("Show All Runs")}
                      </button></div>
                        
                      </td>
                    </tr>
                    ))}
                    
                  </tbody>
                </table>
              </div>
          <Pagination
            filteredData={Payrolls}
            dataPerPage={dataPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />

          {/* </CardBody> */}
        </div>
      </div>
      </CardBody>
    </Card>

    </>
  )
}

export default PayrollRun