
import { ntxAPI } from '../../../API/axios-custom';

const useAddUpdateExtraInformation = () => {

    const AddUpdateExtraInformation = async (extraInfoFormData) => {
      console.log(extraInfoFormData);
        const formData = new FormData();
        formData.append("dynamic_form_id", extraInfoFormData.dynamic_form_id);
        formData.append("extra_information_id", extraInfoFormData.extra_information_id);
        formData.append("reference_id", extraInfoFormData.reference_id);
        formData.append("module_id", extraInfoFormData.module_id);
    
        for (let i = 0; i < extraInfoFormData?.values?.length; i++) {
          const value = extraInfoFormData?.values[i];
          formData.append(`Values[${i}].data_type`, value.data_type);
          formData.append(`Values[${i}].Value`, value.value);
          formData.append(`Values[${i}].lable_text`, value.lable_text);
          formData.append(`Values[${i}].Id`, value.id);
          formData.append(`Values[${i}].dynamic_form_column_id`, value.dynamic_form_column_id);
          formData.append(`Values[${i}].lable_text2`, value.lable_text2);
          formData.append(`Values[${i}].fileAttachment`, value.fileAttachment);
          formData.append(`Values[${i}].isUpdateAttach`, value.isUpdateAttach);
        }
        try {
          const { data } = await ntxAPI.post(
            "ExtraInformation/AddUpdateExtraInformation",
            formData,
            { headers: { 'Content-Type': 'multipart/form-data;'} }
          );
          if (data?.succeeded === true) {
            return data.data;
          } else {
            return null;
          }
        } catch (err) {
          return null;
        }
      };

      return { AddUpdateExtraInformation };
}

export default useAddUpdateExtraInformation
