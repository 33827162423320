import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
// import CustomAlert from "../../../components/Alert/CustomAlert.tsx";
import Loader from "../../../components/Loader/Loader";

import { Card, CardBody } from "../../../components/card/card";
import useGetFormColumn from "../../../hooks/Forms/use-get-FColumn";
import AddStaticForm from "./AddStaticForm";
import BuilderRequest from "./BuilderRequest/BuilderRequest";
import { Form } from "react-bootstrap";
import useAddRequest from "./useAddRequest";
import CustomAlert from "../../../components/Alert/CustomAlert";

const AddRequests = () => {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { FormId } = useParams();
  const [referenceId, setReferenceId] = useState(null);
  const { FormColumnList, loading } = useGetFormColumn(FormId);
  // const [requestFormData, setRequestFormData] = useState({});
  const [formData, setFormData] = useState({});
  const { AddRequest } = useAddRequest();

  const handleAddRequest = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const RequestData = {
      selectedPersonId: "",
      isPersonaly: true,
      referenceId: referenceId || "",
      dynamicFormId: FormColumnList?.dynamicFormId,
      values: FormColumnList?.columnList?.map((item) => ({
        dynamicFormColumnID: item.id,
        dataType: item.validationType || item.formatType,

        value:
          item.validationType === "BOOLEAN" && !formData[item.id]
            ? "0"
            : item.validationType === "ATTACHED"
            ? null
            : formData[item.id],

        lableText: i18n.language === "ar" ? item.lable2 : item.lable,
        lableText2: i18n.language === "ar" ? item.lable2 : item.lable,
        fileAttachment:
          item.validationType === "ATTACHED" && formData[item.id]
            ? formData[item.id]
            : null,
        isUpdateAttach:
          item.validationType === "ATTACHED" && formData[item.id]
            ? true
            : false,
      })),
    };
    var respons = await AddRequest(RequestData);
    // data?.succeeded === true
    if (respons?.succeeded === true) {
      CustomAlert({ action: "Add", msg: "تم ارسال الطلب بنجاح" });
    } else {
      CustomAlert({ action: "Info", msg: respons?.message });
    }
  };

  useEffect(() => {
    if (referenceId !== null) {
      handleAddRequest();
    }
  }, [referenceId]);

  // console.log(FormColumnList);
  return (
    <>
      <Loader loading={loading} />
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                href="#/"
                onClick={() => Navigate("/Requests", { replace: true })}
              >
                {t("Home")}
              </a>
            </li>
            <li className="breadcrumb-item">
              <a
                href="#/"
                onClick={() => Navigate("/Requests", { replace: true })}
              >
                {t("selfService")}
              </a>
            </li>
            <li className="breadcrumb-item active">
              {i18n.language === "ar"
                ? FormColumnList.dynamicFormName2
                : FormColumnList.dynamicFormName}
            </li>
          </ul>
          <h1 className="page-header mb-0">
            {i18n.language === "ar"
              ? FormColumnList.dynamicFormName2
              : FormColumnList.dynamicFormName}
          </h1>
        </div>
      </div>

      <Card>
        <CardBody>
          <Form onSubmit={handleAddRequest}>
            {FormColumnList?.isStatic && (
              <AddStaticForm
                StaticFormId={FormColumnList.dynamicFormId}
                setReferenceId={setReferenceId}
              />
            )}
            {FormColumnList?.dynamicFormId && (
              <BuilderRequest
                FormColumnList={FormColumnList}
                setFormData={setFormData}
                formData={formData}
              />
            )}

            {!FormColumnList?.isStatic && (
              <div className="text-center">
                <button
                  // disabled={formik.isSubmitting}
                  type="submit"
                  className="btn me-1 btn-theme mb-1"
                  // onClick={()=>handleAddRequest}
                >
                  <i className="fa fa-save fa-fw me-1"></i> {t("Save")}
                </button>

                <button
                  type="button"
                  className=" btn me-1 btn-outline-default mb-1"
                  onClick={() => Navigate(-1, { replace: true })}
                >
                  <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
                </button>
              </div>
            )}
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default AddRequests;
