import React from 'react'

import ContentLoader from 'react-content-loader';


const SkeletonForm3 = () => {
  return (
    <ContentLoader
      speed={2}
      // width={200}
      // height={30}
      viewBox="0 0 400 120"
      backgroundColor="#7170701c"
      foregroundColor="#808080"
    >
      <rect x="5" y="5" rx="4" ry="4" width="190" height="13" />
      <rect x="208" y="5" rx="4" ry="4" width="190" height="13" />

      <rect x="5" y="35" rx="4" ry="4" width="190" height="13" />
      <rect x="208" y="35" rx="4" ry="4" width="190" height="13" />
    </ContentLoader>
  )
}

export default SkeletonForm3
