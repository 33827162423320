import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { GetEquipmentsRoutesList } from "../../../store/FLEET/EquipmentsRoutes/equipmentsRoutesSlice";
import Pagination from "../../../components/Pagination/Pagination";

interface props {
  // setID: React.Dispatch<React.SetStateAction<string>>; // For Update
}

const RoutesList: React.FC<props> = (props) => {
  const { Id } = useParams();
  const [Show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();

  useEffect(() => {
    dispatch(GetEquipmentsRoutesList());
  }, []);
  const { EquipmentsRoutesList } = useAppSelector(
    (state) => state.EquipmentsRoutes
  );

  const filteredSearch = EquipmentsRoutesList?.slice()
    ?.filter((item) => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name?.toLowerCase().includes(searchLowerCase) ||
        item.name2?.toLowerCase().toString().includes(searchLowerCase)
      );
    })
    ?.filter((f) => f.fleetEquipmentsId === Id);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  return (
    <div>
            <div className="col-3 mx-auto mb-2">
        <div className="input-group">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
              style={{ zIndex: 1020, right: "10px" }}
            >
              <i className="fa fa-search opacity-5"></i>
            </div>
            <input
              type="text"
              className="form-control ps-35px"
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t("Search") + " ..."}
            />
          </div>
        </div>
      </div>
      <div className="table-responsive  rounded-3">
        <table className="table">
          <thead className="table-dark">
            <tr>
              <th scope="col"></th>
              <th scope="col">{t("Name")}</th>
              <th scope="col">{t("StartTime")}</th>
              <th scope="col">{t("EndTime")}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={item.id}>
                <td className="align-middle">{++index}</td>
                <td className="align-middle">
                  {i18n.language === "en" ? item.name : item.name2}
                </td>
                <td className="align-middle">
                {  item.startTime  }
                </td>
                <td className="align-middle">
                {  item.endTime  }
                </td>
                <td>
                <div className="btn-group">

               
                  <button
                    type="button"
                    className="btn btn-outline-theme"
                    onClick={() => Navigate(`${item.id}/Edit`)}  
                  >
                    <i className="far fa-lg fa-fw me-2 fa-edit"></i>
                    {t("Button.Edit")}
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-theme"
                    onClick={() => Navigate(`${item.id}/AssignmentRoutes`)}  
                  >
                    <i className="far fa-lg fa-fw me-2 bi bi-pin-fill"></i>
                    {t("BookingSeats")}
                  </button>
                   </div>
                </td>
              </tr>
            ))}
          </tbody>

        </table>
      </div>
      <Pagination
        filteredData={filteredSearch}
        dataPerPage={dataPerPage}
        handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
        currentPage={currentPage}
      />
    </div>
  );
};

export default RoutesList;
