import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { GetScanningElementList } from "../../../../store/FLEET/FleetScan/fleetScanSlice";
import Pagination from "../../../../components/Pagination/Pagination";
// import { useAppDispatch, useAppSelector } from "../../../store/hooks";
// import { GetEquipmentsOdometerList } from "../../../store/FLEET/EquipmentsOdometer/equipmentsOdometerSlice";
// import Pagination from "../../../components/Pagination/Pagination";

interface props {
  setID: React.Dispatch<React.SetStateAction<string>>; // For Update
}

const ScanElementList: React.FC<props> = (props) => {
  const { Id } = useParams();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();

  useEffect(() => {
    // if (ScanElementList.length === 0) {
      dispatch(GetScanningElementList());
    // }
  }, []);
  
  const { ScanElementList } = useAppSelector((state) => state.FleetScan);

  const filteredSearch = ScanElementList?.slice()?.filter((item) => {
    const searchLowerCase = search?.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLowerCase) ||
      item.name2?.toLowerCase().toString().includes(searchLowerCase)
    );
  })
   .sort((a , b) => a.sort - b.sort);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredSearch?.slice(indexOfFirstData, indexOfLastData);

  return (
    <div>
      <div className="col-3 mx-auto mb-2">
        <div className="input-group">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
              style={{ zIndex: 1020, right: "10px" }}
            >
              <i className="fa fa-search opacity-5"></i>
            </div>
            <input
              type="text"
              className="form-control ps-35px"
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t("Search") + " ..."}
            />
          </div>
        </div>
      </div>
      <div className="table-responsive rounded-3">
        <table className="table table-striped table-bordered">
          <thead className="table-dark">
            <tr>
              <th scope="col" style={{width : "2%"}}></th>
              <th scope="col" style={{width : "20%"}}>{t("Item")}</th>
              <th scope="col" style={{width : "45%"}}>{t("Descriptions")}</th>
              <th scope="col" style={{width : "15%"}}>{t("Priority")}</th>
              {/* <th scope="col" style={{width : "10%"}}>{t("Order")}</th> */}
              <th scope="col" style={{width : "10%"}}></th>
            </tr>
          </thead>
          <tbody>
            {currentData?.map((item, index) => (
              <tr key={item.id}>
                <td className="align-middle">
                  {++index}
                </td>
                <td className="align-middle">
                  {i18n.language === "en" ? item.name : item.name2}
                </td>

                <td className="align-middle">
                  {i18n.language === "en"
                    ? item.description
                    : item.description2}
                </td>
                <td className="align-middle">{item.priority}</td>
                {/* <td className="align-middle">{item.sort}</td> */}
                <td className="text-center align-middle">
                  <button
                    type="button"
                    className="btn btn-lg btn-outline-lime"
                    data-bs-toggle="modal"
                    data-bs-target="#EditElementModal"
                    onClick={() => props.setID(item.id)}
                  >
                    <i className="far fa-lg fa-fw  fa-edit"></i>
                    {/* {t("Button.Edit")} */}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="me-3 pb-2 ms-2">
        <Pagination
          filteredData={filteredSearch}
          dataPerPage={dataPerPage}
          handlePagination={(pageNumber: any) => setCurrentPage(pageNumber)}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default ScanElementList;
