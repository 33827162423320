import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { GetDynamicForm } from "../../store/NTX/FlexValue/Form/formSlice";


const useGetDynamicForm = ()=>{

    const dispatch = useDispatch();
    const { DynamicFormList, loading } = useSelector((state) => state.Forms);
    useEffect(() => {
      dispatch(GetDynamicForm());
    }, [dispatch]);

    return { loading  , DynamicFormList } ;
};

export default useGetDynamicForm ;