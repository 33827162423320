import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
// import axios from "axios";
// import variables from "../../APIURL/variables";
// import { getData, postData } from "../../Axios/axiosData";



export const GetResponsibilityUserByUserId = createAsyncThunk("ResponsibilityUser/GetResponsibilityUserByUserId", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`Security/GetAuthResponsibilityUserByUserIdList?UserId=${id}`);
      const {data} = await ntxAPI.get(`Security/GetAuthResponsibilityUserByUserIdList?UserId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const AddResponsibilityUser = createAsyncThunk("ResponsibilityUser/AddResponsibilityUser", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("Security/AddAuthResponsibilityUser" , item);
      const {data} = await ntxAPI.post("Security/AddAuthResponsibilityUser" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });



export const DeleteAuthResponsibilityUser = createAsyncThunk("ResponsibilityUser/DeleteAuthResponsibilityUser", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
 
    try {
      // const data = await postData(`Security/DeleteAuthResponsibilityUser?Id=${id}`);
      const {data} = await ntxAPI.post(`Security/DeleteAuthResponsibilityUser?Id=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });






  const initialState = { ResponsibilityUserList: [] ,  loading: false, error: null };

  const ResponsibilityUserSlice = createSlice({
    name: "ResponsibilityUser",
    initialState,
    reducers: { },
    extraReducers: {
      /////// get Approval Member List               
      [GetResponsibilityUserByUserId.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetResponsibilityUserByUserId.fulfilled]: (state, action) => {
        state.loading = false;
        state.ResponsibilityUserList = action.payload;
      },
      [GetResponsibilityUserByUserId.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

      //// insert Approval Member
      [AddResponsibilityUser.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [AddResponsibilityUser.fulfilled]: (state, action) => {
        state.loading = false;
        console.log(action.payload);
        state.ResponsibilityUserList.table.push(action.payload);
      },
      [AddResponsibilityUser.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

    // /// Delete 
    // [DeleteAprovalMember.pending]: (state) => {
    //   state.loading = true;
    //   state.error = null;
    // },
    // [DeleteAprovalMember.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.ApprovalMemberList = state.ApprovalMemberList.filter((el) => el.id !== action.payload)
    // },
    // [DeleteAprovalMember.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },



   
    },
  });
  
  export default ResponsibilityUserSlice.reducer;