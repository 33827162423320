import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { GetNtxPeoplesList } from "../../../store/NTX/Peoples/peoplesSlice";
import {
  GetEquipmentsOdometerById,
  GetEquipmentsOdometerList,
  UpdateEquipmentsOdometer,
} from "../../../store/FLEET/EquipmentsOdometer/equipmentsOdometerSlice";
import CustomAlert from "../../../components/Alert/CustomAlert";

interface props {
  ID: string;
}

const EditEquipmentsOdometer: React.FC<props> = (props) => {
  const { Id } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetNtxPeoplesList());
  }, []);

  useEffect(() => {
    if (props.ID) {
      dispatch(GetEquipmentsOdometerById(props.ID));
    }
  }, [props.ID]);

  const { PeoplesList } = useAppSelector((state) => state.Peoples);

  const { EquipmentsOdometerById } = useAppSelector(
    (state) => state.EquipmentsOdometer
  );

  const formik = useFormik({
    initialValues: {
      id: EquipmentsOdometerById ? EquipmentsOdometerById.id : "",
      fleetEquipmentsId: EquipmentsOdometerById
        ? EquipmentsOdometerById.fleetEquipmentsId
        : "",
      peopleId: EquipmentsOdometerById ? EquipmentsOdometerById.peopleId : "",
      odometer: EquipmentsOdometerById ? EquipmentsOdometerById.odometer : "",
      readDate: EquipmentsOdometerById ? EquipmentsOdometerById.readDate : "",
      description: EquipmentsOdometerById
        ? EquipmentsOdometerById.description
        : "",
    },
    enableReinitialize: true,
    // validationSchema: AddWorkShopFLeetSchema(t),
    onSubmit: (values) => {
      dispatch(
        UpdateEquipmentsOdometer({
          id: values.id,
          fleetEquipmentsId: values.fleetEquipmentsId,
          peopleId: values.peopleId,
          odometer: values.odometer,
          description: values.description,
          readDate: values.readDate,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.succeeded === true) {
            document.getElementById("closeModalForm")?.click();
            CustomAlert({ action: "Edit" });
            dispatch(GetEquipmentsOdometerList());
            formik.resetForm();
          } else {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error", msg: res?.message });
          }
        })
        .catch((error: any) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label"> {t("DriverName")}</label>
              <Form.Select
                className="form-select"
                name="peopleId"
                onChange={formik.handleChange}
                value={formik.values.peopleId || ""}
                isInvalid={
                  !!(formik.touched.peopleId && formik.errors.peopleId)
                }
              >
                <option value="">
                  {i18n.language === "ar" ? "إختر" : "Choose"}
                </option>
                {PeoplesList &&
                  PeoplesList?.filter((f) => f.typeCode === "DRIVERS").map(
                    (item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    )
                  )}
              </Form.Select>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("Odometer")}</label>
              <Form.Control
                className="form-control"
                type="number"
                name="odometer"
                onChange={formik.handleChange}
                value={String(formik.values.odometer)}
                isInvalid={
                  !!(formik.touched.odometer && formik.errors.odometer)
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.odometer}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="form-group mb-3">
              <label className="form-label">{t("DateOfReading")}</label>
              <Form.Control
                className="form-control"
                type="date"
                name="readDate"
                onChange={formik.handleChange}
                value={formik.values.readDate}
                isInvalid={
                  !!(formik.touched.readDate && formik.errors.readDate)
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.readDate}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="form-group mb-3">
              <label className="form-label">{t("Note")}</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={2}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              <div></div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
          >
            {formik.isSubmitting ? (
              <div className="spinner-border spinner-border-sm me-2"></div>
            ) : (
              <i className="fa fa-save fa-fw me-1"></i>
            )}
            {t("Save")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="closeModalForm"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditEquipmentsOdometer;
