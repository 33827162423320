import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../components/card/card";
import Loader from "../../../../components/Loader/Loader";
import {
  cleanElementEntryToAddList,
  GetElementEntryToAdd,
  InsertPayElementEntry,
} from "../../../../store/HR/Payroll/payrollElementEntrySlice";
import { AddPayrollElementEntrySchema } from "../../../ValidationForm/validationSchema";
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx'

function AddPayrollElementEntry() {
  const { assignmentId } = useParams();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(cleanElementEntryToAddList());
    dispatch(GetElementEntryToAdd(assignmentId));
  }, [dispatch, assignmentId]);

  const { ElementEntryToAddList, loading } = useSelector(
    (state) => state.PayElementEntry
  );
  console.log( ElementEntryToAddList)

  const formik = useFormik({
    initialValues: {
      assignmentId: assignmentId,
      startDate: "",
      endDate: "",
      elementLinkId: "",
      elementTypeId: "",
      screenEntryValue: "",
      isRecurring: null, // Only for handle hide and show and validation  based on  isRecurring
      TypeUom: null, // Only for handle with change Type Elemnent based on Uom
    },
    validationSchema : AddPayrollElementEntrySchema(t),

    onSubmit: (values) => {
      console.log(values);

      dispatch(
        InsertPayElementEntry({
          assignmentId: assignmentId,
          startDate: values.startDate,
          endDate:
             values.isRecurring === 'false'
              ? values.endDate
              : null,
          elementLinkId: values.elementLinkId,
          elementTypeId: values.elementTypeId,
          screenEntryValue: String(values.screenEntryValue),
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          dispatch(GetElementEntryToAdd(assignmentId));
          CustomAlert({action : "Add"})
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({action:"Error" , msg:error})
        });
    },
  });

  const [IsRecurring, setIsRecurring] = useState();
  async function handleSelectChange(e) {
    const selectedOption = e.target.selectedOptions[0];

    // debugger
    const ElementLinkID = e.target.value;
    const selected_Uom_Type = selectedOption.dataset.uomType;
    const selected_isRecurring = selectedOption.dataset.isRecurring;
    const selected_elementTypeId = selectedOption.dataset.elementType;
    console.log(selected_isRecurring);
    console.log(selected_elementTypeId);

    formik.setFieldValue("elementLinkId", ElementLinkID);
    formik.setFieldValue("TypeUom", selected_Uom_Type );
    formik.setFieldValue("isRecurring", selected_isRecurring );
    formik.setFieldValue("elementTypeId", selected_elementTypeId );
  }
  //_______________
  var personName = localStorage.personName;
  var personName2 = localStorage.personName2;

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            {/* <li className="breadcrumb-item">
              <Link to="-1">إدخالات الراتب</Link>
            </li> */}
            <li className="breadcrumb-item active">{t("Payroll Element Entry")}</li>
          </ul>
          <h1 className="page-header mb-0"> {t("Payroll Element Entry")} " {i18n.language === "ar"
              ? personName2
              : personName} "</h1>
        </div>
      </div>

      <Card>
        <Loader loading={loading} />
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Elemente")}</label>
                  <Form.Select
                    className="form-select"
                    name="elementLinkId"
                    // onChange={formik.handleChange}
                    onChange={handleSelectChange}
                    value={formik.values.elementLinkId}
                    isInvalid={
                      formik.touched.elementLinkId &&
                      formik.errors.elementLinkId
                    }
                  >
                    <option key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {ElementEntryToAddList &&
                      ElementEntryToAddList?.elementLink?.map((item) => (
                        <option
                          key={item.elementLinkId}
                          value={item.elementLinkId}
                          data-uom-type={item.uom}
                          data-is-recurring={item.isRecurring}
                          data-element-type={item.elementTypeId}
                        >
                          {i18n.language === "ar"
                            ? item.elementTypeName2
                            : item.elementTypeName}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.elementLinkId}
                  </Form.Control.Feedback>
                </div>
              </div>

              {/* <div className="row">
                    <div className="col-xl-5">
                      <div className="form-group mb-3">
                        <label
                          className="form-label"
                          
                        >
                          elementTypeId 
                        </label>
                        <Form.Select
                          className="form-select"
                          name="elementTypeId"
                          onChange={formik.handleChange}
                          value={formik.values.elementTypeId}
                          isInvalid={ formik.touched.elementTypeId && formik.errors.elementTypeId}
                        >
                            <option key={null}>
                          {i18n.language === "ar" ? 'إختر' : 'Choose'}
                          </option>
                          {
                          ElementEntryToAddList?.elementLink?.map(item =>(
                            <option key={item.elementLinkId} value={item.elementLinkId}>
                              {i18n.language === "ar" ? item.elementTypeName2: item.elementTypeName}
                            </option>
                          ))
                        }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                           {formik.errors.elementTypeId}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    
                  </div> */}

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Start Date")}</label>
                  <Form.Control
                    className="form-control"
                    type="date"
                    name="startDate"
                    onChange={formik.handleChange}
                    value={formik.values.startDate}
                    isInvalid={
                      formik.touched.startDate && formik.errors.startDate
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.startDate}
                  </Form.Control.Feedback>
                </div>
              </div>

              {
                // formik.values.elementLinkId  && ElementEntryToAddList?.elementLink?.find(item => item.elementLinkId === formik.values.elementLinkId).isRecurring === false

                formik.values.elementLinkId &&
                  (ElementEntryToAddList?.elementLink?.find(
                    (item) => item.elementLinkId === formik.values.elementLinkId
                  )?.isRecurring === false ) && (
                    <div className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">{t("Date_TO")}</label>
                        <Form.Control
                          className="form-control"
                          type="date"
                          name="endDate"
                          onChange={formik.handleChange}
                          value={formik.values.endDate}
                          isInvalid={
                            formik.touched.endDate && formik.errors.endDate
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.endDate}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  )
              }

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Value")}</label>
                  <Form.Control
                    className="form-control"
                    type={
                      formik.values.TypeUom === "Text"
                        ? "text"
                        : formik.values.TypeUom === "Date"
                        ? "date"
                        : formik.values.TypeUom === "Money" || "Day"
                        ? "number"
                        : formik.values.TypeUom
                    }
                    name="screenEntryValue"
                    onChange={formik.handleChange}
                    value={formik.values.screenEntryValue}
                    isInvalid={
                      formik.touched.screenEntryValue &&
                      formik.errors.screenEntryValue
                    }
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
                id="SaveSubmit"
              >
                <i className="fa fa-save fa-fw me-1"></i>{" "}
                {formik.isSubmitting ? "..." : t("Save")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>

          <hr className="mb-4" />

          <div className="row">
            <div className="col-lg-12">
              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t("Name")}</th>
                    <th scope="col">{t("Value")}</th>
                    <th scope="col">{t("Start Date")}</th>
                    <th scope="col">{t("Date_TO")}</th>
                  </tr>
                </thead>

                <tbody>
                  {ElementEntryToAddList?.elemntEntry &&
                    ElementEntryToAddList?.elemntEntry.map((item) => (
                      <tr key={item.elementEntryId}>
                        <td className="align-middle">
                          {i18n.language === "en"
                            ? item.elementTypeName
                            : item.elementTypeName2}
                        </td>
                        <td className="align-middle">
                          {item.screenEntryValue}
                        </td>
                        <td className="align-middle">{item.startDate}</td>
                        <td className="align-middle">{item.endDate}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default AddPayrollElementEntry;
