import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { getTITLE } from "../../store/lookupSlice";

const useLookupTitle = ()=>{

    const dispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(getTITLE())
      },[])

    const { TITLE } = useSelector((state) => state.Lookup); 

    return { TITLE } ;
};

export default useLookupTitle ;