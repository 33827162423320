import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import useGetRealEstateList from "../../../../hooks/SU/useGetRealEstateList";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const RealEstateListContract = ({
    Total,
    tableDataRealEstate,
    setTableDataRealEstate,
  }) => {

 

    const { t, i18n } = useTranslation();
    const { RealEstateList } = useGetRealEstateList();

  
    const [selectedName, setSelectedName] = useState("");
    const [selectedName2, setSelectedName2] = useState("");
    const [selectedId, setSelectedId] = useState();
    const [enteredAmount, setEnteredAmount] = useState("");
    const [electricityMeter, setElectricityMeter] = useState("");
    const [waterMeter, setWaterMeter] = useState("");
  
    const [totalAmount, setTotalAmount] = useState(0);
    //  const [tableDataRealEstate, setTableDataRealEstate] = useState([]);

  
    const handlechange = (e) => {
      const selectedOption = e.target.selectedOptions[0];
      // console.log(selectedOption);
      const selectedName = selectedOption.dataset.name;
      const selectedName2 = selectedOption.dataset.name2;
      setSelectedId(e.target.value);
      setSelectedName(selectedName);
      setSelectedName2(selectedName2);
    };
  
    const handleDeleteRowClick = (selectedId) => {
      const updatedTableData = tableDataRealEstate?.filter(
        (item) => item.realEstateId !== selectedId
      );
      
      setTableDataRealEstate(updatedTableData);
  
      // Recalculate the Total
      const newTotal = updatedTableData?.reduce((acc, item) => acc + item.annualRent, 0);
      setTotalAmount(newTotal);
    };
  
    const handleAddToTable = (e) => {
      e.preventDefault();
      if (selectedName && enteredAmount && selectedId) {
        // Check if the already exists in the tableDataRealEstate array
        const Exists = tableDataRealEstate?.some(
          (item) => item.realEstateId === selectedId
        );
        if (Exists) {
          Swal.fire("هذا العقار تمت إضافته مسبقًا")
          return;
        }
  
        let sum = tableDataRealEstate?.reduce((acc, item) => acc + item.annualRent, 0);
        let amount = parseFloat(enteredAmount);
        let TotalR = parseFloat(Total);
        // debugger
        if (sum + amount > TotalR) {
            Swal.fire("المجموع اكبر من الإجمالي")
          return;
        }
  
        const newData = {
            name: selectedName,
            name2: selectedName2,
            annualRent: parseFloat(enteredAmount),
            realEstateId: selectedId,
            waterMeter: waterMeter,
            electricityMeter: electricityMeter,
        };
        setTableDataRealEstate([...tableDataRealEstate, newData]);
        setSelectedId("")
        setSelectedName("");
        setEnteredAmount("");
        setWaterMeter("");
        setElectricityMeter("");
      }
    };
  
    useEffect(() => {
      const total = tableDataRealEstate?.reduce(
        (acc, item) => acc + item.annualRent,
        0
      );
      setTotalAmount(total);
    }, [tableDataRealEstate]);

  return (
    <Fragment>

   
      <Form onSubmit={handleAddToTable}>
        <div className="row">
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">اسم العقار</label>
              <Form.Select
                className="form-select"
                value={selectedId}
                onChange={(e) => handlechange(e)}
                required={selectedId === null}
              >
                <option>{i18n.language === "ar" ? "إختر" : "Choose"}</option>
                {RealEstateList &&
                  RealEstateList.map((item) => (
                    <option
                      value={item.id}
                      key={item.id}
                      data-name2={item.name2}
                      data-name={item.name}
                    >
                      {i18n.language === "ar" ? item.name2 : item.name}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">مبلغ الإيجار</label>
              <Form.Control
                className="form-control"
                type="number"
                value={enteredAmount}
                onChange={(e) => {
                  setEnteredAmount(e.target.value)
                } }
                required
              />
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">قراءة عداد الكهرباء</label>
              <Form.Control
                className="form-control"
                type="number"
                name="electricity_meter"
                value={electricityMeter}
                onChange={(e) => setElectricityMeter(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="col-xl-3">
            <div className="form-group mb-3">
              <label className="form-label">قراءة عداد الماء</label>
              <Form.Control
                className="form-control"
                type="number"
                name="waterMeter"
                value={waterMeter}
                onChange={(e) => setWaterMeter(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="text-center mb-4">
            <button type="submit" className="btn btn-primary">
              إضافة عقار
            </button>
            {alert}
          </div>
        </div>
      </Form>

      <div className="table-responsive">
        <table className="table">
          <thead className="table-dark">
            <tr>
              <th>اسم العقار</th>
              <th>مبلغ الإيجار</th>
              <th>قراءة عداد الماء</th>
              <th>قراءة عداد الكهرباء</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tableDataRealEstate.map((data, index) => (
              <tr key={index}>
                <td>
                  {i18n.language === "ar"
                    ? data.name
                    : data.name2}
                </td>
                <td>{data.annualRent}</td>
                <td>{data.waterMeter}</td>
                <td>{data.electricityMeter}</td>
                <td>
                  <Link
                    style={{ color: "red" }}
                    onClick={() => handleDeleteRowClick(data.realEstateId)} 
                  >
                    <i className="fas fa-lg fa-fw me-3 fa-trash-alt"></i>
                  </Link>
                </td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td>المجموع</td>
              <td>{totalAmount}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
     </Fragment>
  )
}

export default RealEstateListContract
