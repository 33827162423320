

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
import { AssignmentByFleetEquipmentsId, AssignmentRoutes } from "./assignmentroutesModels";
// import { EquipmentsOdometer } from "./equipmentsOdometerModels";




export const  GetRoutesAssignmentList = createAsyncThunk(
    "AssignmentRoutes/GetRoutesAssignmentList",
    async (_, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get('Fleet/GetAssignmentList');
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

export const  GetAssignmentById = createAsyncThunk(
    "AssignmentRoutes/GetAssignmentById",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetAssignmentById?Id=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );

export const  GetAssignmentByFleetEquipmentsId = createAsyncThunk(
    "AssignmentRoutes/GetAssignmentByFleetEquipmentsId",
    async (id : string, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const {data} = await ntxAPI.get(`Fleet/GetAssignmentByFleetEquipmentsId?FleetEquipmentsId=${id}`);
        return data.data;
      } catch (error:any) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const AddRoutesAssignment = createAsyncThunk('AssignmentRoutes/AddRoutesAssignment',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/AddAssignment" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });


  export const UpdatAssignment = createAsyncThunk('AssignmentRoutes/UpdatAssignment',
   async (item: any , {rejectWithValue} ) => {

    try {
      const {data} = await ntxAPI.post( "Fleet/UpdatAssignment" , item)
      return data; 
    } catch (error : any) {
        return rejectWithValue(error.message);
    }
  });

  


const initialState: {
    AssignmentRoutesList : AssignmentRoutes[]
    AssignmentRoutesById? : AssignmentRoutes
    AssignmentByFleetEquipmentsId? : AssignmentByFleetEquipmentsId
    loading: boolean;
    error: any | null;
  } = {
    AssignmentRoutesList: [],
    loading: false,
    error: null,

  };


  

const assignmentroutesSlice = createSlice({
    name: "AssignmentRoutes",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder

      .addCase(AddRoutesAssignment.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(AddRoutesAssignment.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.AssignmentRoutesList.push(action.payload.data); 
      })
      .addCase(AddRoutesAssignment.rejected, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetRoutesAssignmentList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetRoutesAssignmentList.fulfilled, (state, action: PayloadAction<any> ) => {
        state.loading = false;
        state.AssignmentRoutesList = action.payload;
      })
      .addCase(GetRoutesAssignmentList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })



    .addCase(GetAssignmentById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetAssignmentById.fulfilled, (state, action: PayloadAction<AssignmentRoutes> ) => {
        state.loading = false;
        state.AssignmentRoutesById = action.payload;
      })
      .addCase(GetAssignmentById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })


    .addCase(GetAssignmentByFleetEquipmentsId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetAssignmentByFleetEquipmentsId.fulfilled, (state, action: PayloadAction<AssignmentByFleetEquipmentsId> ) => {
        state.loading = false;
        if(action.payload){
          state.AssignmentByFleetEquipmentsId = action.payload;
        }
      })
      .addCase(GetAssignmentByFleetEquipmentsId.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      
    },
  });
  
  export default assignmentroutesSlice.reducer;