import React from "react";
import ViewStaticForm from "./ViewStaticForm";
import BuilderValue from "./BuilderValue";

const ContentRequest = ({ data }) => {
  return (
    <>
      <div className="row">
        <div className="col-xl-10 mx-auto">
          {data?.is_Static && (
            <ViewStaticForm
              id={data.dynamicFormId}
              ReferenceId={data.staticRefrenceId}
            />
          )}

          {data?.values?.length > 0 && <BuilderValue data={data.values} />}
        </div>
      </div>
    </>
  );
};

export default ContentRequest;
