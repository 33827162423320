import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import {
  Card,

} from "../../../components/card/card.jsx";
import { useTranslation } from "react-i18next";

import { GetDynamicForm } from "../../../store/NTX/FlexValue/Form/formSlice.js";
// import Pagination from "../../../components/Pagination/Pagination.jsx";
import Loader from "../../../components/Loader/Loader.js";
import useLookupModule from "../../../hooks/Lookups/use-Lookup-Module.js";

const AmeCondition = () => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { DynamicFormList, loading } = useSelector((state) => state.Forms);

  useEffect(() => {
    dispatch(GetDynamicForm());
  }, []);
  // console.log(DynamicFormList);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = DynamicFormList?.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  const handlePagination = (pageNumber) => setCurrentPage(pageNumber);


  const [selectedValue, setSelectedValue] = useState("");

  const { Module } = useLookupModule();
  useEffect(() => {
    if (Module?.length) {
      setSelectedValue(Module[0].id);
    }
  }, [Module]);
  //

  ///////////////////////////////

  const filteredDataByModules = currentData?.filter(
    (item) => item.moduleId === selectedValue
  )?.filter((f)=> f.dynamicFormTypesId === 1);



  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t('Home')}</a>
            </li>
            <li className="breadcrumb-item active">{t('Requests settings')}</li>
          </ul>
          <h1 className="page-header mb-0">{t('Requests settings')}</h1>
        </div>
      </div>
    <Card>
            <div className="tab-content p-4">
        <div className="tab-pane fade show active">
          <div className="row">
            <div className="col-7 col-md-6 d-flex justify-content-start">
              <div className="input-group mb-4">
                <div className="flex-fill position-relative">
                  <div className="input-group mb-4">
                    <select
                      className="btn btn-outline-default dropdown-toggle p-0"
                      id="my-select"
                      value={selectedValue}
                      onChange={(e) => setSelectedValue(e.target.value)}
                    >
                      {Module &&
                        Module.map((item) => (
                          <option
                            value={item.id}
                            key={item.id}
                            className="dropdown-item"
                          >
                            {i18n.language === "ar" ? item.name2 : item.name}
                          </option>
                        ))}
                    </select>

                    <div className="flex-fill position-relative">
                      <div className="input-group">
                        <div
                          className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                          style={{ zIndex: 1020, right: "10px" }}
                        >
                          <i className="fa fa-search opacity-5"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control ps-35px"
                          placeholder={t("Search")}
                          value={search}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //  START TABLE   */}
          <Loader loading={loading} />
          <div className="table-responsive">
            <table className="table">
            <thead className="table-dark">
                <tr>
                  <th scope="col" onClick={() => handleSort("name")}>
                    {/* {t('Name')} */}
                    النموذج
                  </th>

                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {filteredDataByModules.map((item, index) => (
                  <tr key={item.id}>
                    <td className="align-middle">
                      {i18n.language === "ar" ? item.name2 : item.name}

                    </td>
                    <td>
                      <div className="btn-group">
                        <button
                          type="button"
                          onClick={() => Navigate(`${item.id}/AddAmeCondition`)}
                          className="btn btn-outline-lime"
                          // hidden={hiddenEditRequestSetting}
                        >
                          <i className="fas fa-lg fa-fw me-2 fa-question"></i>
                          {t('Conditions')}
                        </button>
                        <button
                          type="button"
                          onClick={() => Navigate(`${item.id}/AddAmeRule`)}
                          className="btn btn-outline-lime"
                          // hidden={hiddenEditRequestSetting}
                        >
                          <i className="fas fa-lg fa-fw me-2 fa-filter"></i>
                          {t('Rules')}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <Pagination
            filteredData={filteredDataByModules}
            // .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
            dataPerPage={dataPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
          /> */}

          {/* </CardBody> */}
        </div>
      </div>
    </Card>

    </>
  );
};

export default AmeCondition;
