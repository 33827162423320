import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ntxAPI } from "../../API/axios-custom";



  export const AddAmeActionRule = createAsyncThunk("AmeActionRule/AddAmeActionRule", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    console.log(JSON.stringify(item));
 
    try {
      // const data = await postData("AmeSetting/AddAmeActionRule", item);
      const {data} = await ntxAPI.post("AmeSetting/AddAmeActionRule" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const GetAmeActionRule = createAsyncThunk("AmeActionRule/GetAmeActionRule", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`AmeSetting/GetAmeActionRuleListByRuleId?RuleId=${id}`);
      const {data} = await ntxAPI.get(`AmeSetting/GetAmeActionRuleListByRuleId?RuleId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const DeleteAmeActionRule = createAsyncThunk("AmeActionRule/DeleteAmeActionRule", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData(`AmeSetting/DeleteAmeActionRule?Id=${id}`);
      const {data} = await ntxAPI.post(`AmeSetting/DeleteAmeActionRule?Id=${id}` )
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });












  const initialState = { ActionRuleListByRuleId: [], loading: false, error: null };

  const AmeActionRuleSlice = createSlice({
    name: "AmeActionRule",
    initialState,
    reducers: {
    },
    extraReducers: {

      /////// get ame Action Rule             
      [GetAmeActionRule.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetAmeActionRule.fulfilled]: (state, action) => {
        state.loading = false;
        state.ActionRuleListByRuleId = action.payload;
      },
      [GetAmeActionRule.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },


       //// insert AmeConditionUsage
      [AddAmeActionRule.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [AddAmeActionRule.fulfilled]: (state, action) => {
        state.loading = false;
        state.ActionRuleListByRuleId.push(action.payload);
      },
      [AddAmeActionRule.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

      /// Delete 
      [DeleteAmeActionRule.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [DeleteAmeActionRule.fulfilled]: (state, action) => {
        state.loading = false;
        state.ActionRuleListByRuleId = state.ActionRuleListByRuleId?.filter((el) => el.id !== action.payload)
      },
      [DeleteAmeActionRule.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
   
    },
  });
  
  export default AmeActionRuleSlice.reducer;