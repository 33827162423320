import * as permissionType from "./PermissionType";


export const HasPermission = (permission, type) => {
  let result = false;

  const PermisstionsList = localStorage.permission ? JSON.parse( localStorage.permission) :[]


//   const PermisstionsListBySysName =  PermisstionsList.find(f=>f.sysName === permission)

//   console.log(PermisstionsListBySysName);





  const HAS = PermisstionsList?.some(
    (element) =>
      element.sysName === permission &&
      ((permissionType.Create === type && element.createAction === true) ||
      (permissionType.Index === type && element.indexAction === true)   ||
      (permissionType.Delete === type && element.deleteAction === true) ||
      (permissionType.Details === type && element.detailsAction === true) ||
      (permissionType.Edit === type && element.editAction === true))
  );
  // console.log(HAS);
  if (HAS) {
    result = true;
  }

  return result;
};

















// export const HasPermission = (permission, type) => {
//     let result = false;
  
//     const PermisstionsList = JSON.parse(localStorage.permission)
  
//     console.log(PermisstionsList);
  
//     const PermisstionsListBySysName =  PermisstionsList
  
//     const HAS = PermisstionsList.some(
//       (element) =>
//         element.sysName === permission ||
//         (permissionType.Create === type && element.createAction === true) ||
//         (permissionType.Index === type && element.indexAction === true)   ||
//         (permissionType.Delete === type && element.deleteAction === true) ||
//         (permissionType.Details === type && element.detailsAction === true) ||
//         (permissionType.Edit === type && element.editAction === true)
//     );
//     console.log(HAS);
//     if (HAS) {
//       result = true;
//     }
  
//     return result;
//   };
  
