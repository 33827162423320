import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axios-custom";
// import axios from "axios";
// import variables from "../../APIURL/variables";
// import {  getData, postData  } from "../Axios/axiosData";


export const AddContractLease = createAsyncThunk(
    "ContractLease/AddContractLease",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await postData("RealState/AddContractLease" , item);
        const {data} = await ntxAPI.post("RealState/AddContractLease" , item)
        return data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );

  
export const GetContractLease = createAsyncThunk(
    "ContractLease/GetContractLease",
    async (_,thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        const {data} = await ntxAPI.get("RealState/GetContractLeaseList" );
        return data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );

export const GetContractLeaseById = createAsyncThunk(
    "ContractLease/GetContractLeaseById",
    async (id,thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        // const data = await getData(`RealState/GetContractLaseById?Id=${id}`);
        const {data} = await ntxAPI.get( `RealState/GetContractLaseById?Id=${id}`);
        return data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );


export const UpdateContractLease = createAsyncThunk(
    "ContractLease/UpdateContractLease",
    async (item, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;

      try {
        const {data} = await ntxAPI.post("RealState/UpdateContractLease" , item)
        return data;
      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );
  




  const initialState = { ContractLeaseList: [] , ListContractLeaseById: [] ,loading: false, error: null };


const contractLeaseSlice = createSlice({
  name: "ContractLease",
  initialState,
  reducers: {  },
  extraReducers: { 


       // Get List  
    [GetContractLease.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetContractLease.fulfilled]: (state, {payload}) => {
      state.loading = false;
      if(payload?.succeeded === true && payload?.data){
        state.ContractLeaseList = payload.data;
      }
    },
    [GetContractLease.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

       // Get List  By Id
    [GetContractLeaseById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetContractLeaseById.fulfilled]: (state, {payload}) => {
      state.loading = false;
      state.ListContractLeaseById = payload.data;
    },
    [GetContractLeaseById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // [CreateRrealEstate.pending]: (state) => {
    //   state.loading = true;
    //   state.error = null;
    // },
    // [CreateRrealEstate.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.RealEstateList.push(action.payload);
    // },
    // [CreateRrealEstate.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },

  },
});

export default contractLeaseSlice.reducer;
