import React from "react";
import { useTranslation } from "react-i18next";

const HeaderRequest = ({ data }) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="row">
        <div className="col-xl-6">
          <div className="input-group flex-nowrap m-2">
            <span className="input-group-text">نوع الطلب</span>
            <input
              type="text"
              className="form-control"
              defaultValue={
                i18n.language === "en" ? data?.requestName : data?.requestName2
              }
              readOnly
            />
          </div>
        </div>

        <div className="col-xl-6">
          <div className="input-group flex-nowrap m-2">
            <span className="input-group-text">رقم الطلب</span>
            <input
              type="text"
              className="form-control"
              defaultValue={data?.requestNumber}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-xl-6">
          <div className="input-group flex-nowrap m-2">
            <span className="input-group-text">صاحب الطلب</span>
            <input
              type="text"
              className="form-control"
              defaultValue={
                i18n.language === "en"
                  ? data?.selectPersonName
                  : data?.selectPersonName2
              }
              readOnly
            />
          </div>
        </div>

        <div className="col-xl-6">
          <div className="input-group flex-nowrap m-2">
            <span className="input-group-text">تاريخ الطلب</span>
            <input
              type="text"
              className="form-control"
              defaultValue={data?.dateCreated}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderRequest;
