import { useFormik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import useGetPersons from "../../../../hooks/HR/use-get-persons.js";
import { ActionRequest } from "../../../../store/Ame/ameRequestSlice.js";

const ActionRequestForm = ({ data }) => {
  const { TransactionHistoryId } = useParams();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { Employees } = useGetPersons();

  const formik = useFormik({
    initialValues: {
      transectionHistoryId: TransactionHistoryId,
      action: "",
      personId: null,
      toPersonId: "",
      userComment: "",
    },
    validationSchema: Yup.object().shape({
      action: Yup.string()
        .required(() => t("Required"))
        .notOneOf(["إختر", "Choose"], "Required"),

      toPersonId: Yup.string().when("action", {
        is: (action) => ["QUESTION", "TRANSFER"]?.includes(action),
        then: () => Yup.string().required(() => t("Required")),
      }),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);

      dispatch(
        ActionRequest({
          transectionHistoryId: values.transectionHistoryId,
          action: values.action,
          personId: null,
          toPersonId:
            values.action === "TRANSFER" || values.action === "QUESTION"
              ? values.toPersonId
              : null,
          userComment: values.userComment,
        })
      )
        .unwrap()
        .then((res) => {
          console.log(res);
          resetForm();
          CustomAlert({ action: "Add" });
          Navigate(-1, { replace: true });
        })
        .catch((error) => {
          console.log(error);
          CustomAlert({ action: "Error" });
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row mx-auto ">
        
        <div className="row">
          <div className="col-xl-6 mx-auto">
            <div className="form-group mb-3">
              <label className="form-label">الإجـراء</label>

              <Form.Select
                className="form-select"
                name="action"
                onChange={formik.handleChange}
                value={formik.values.action}
                isInvalid={formik.touched.action && formik.errors.action}
              >
                {data.isAnswer ? (
                  <>
                    <option>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    <option value="ANSWER">
                      {i18n.language === "ar" ? "إجــابة" : "ANSWER"}
                    </option>
                  </>
                ) : (
                  <>
                    <option>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    <option value="APPROVED">
                      {i18n.language === "ar" ? "قبول" : "APPROVED"}
                    </option>
                    <option value="REJECTED">
                      {i18n.language === "ar" ? "رفض" : "REJECTED"}
                    </option>
                    <option value="TRANSFER">
                      {i18n.language === "ar" ? "تحويـل" : "TRANSFER"}
                    </option>
                    <option value="QUESTION">
                      {i18n.language === "ar" ? "سـؤال" : "QUESTION"}
                    </option>
                  </>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.action}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>
        <div className="row ">
          {(formik.values.action === "TRANSFER" ||
            formik.values.action === "QUESTION") && (
            <div className="col-xl-6 position-relative mx-auto">
              <div className="form-group mb-3">
                <label className="form-label">إلـى</label>

                <Select
                  classNamePrefix="react-select"
                  isSearchable={true}
                  name="toPersonId"
                  options={Employees.map((item) => ({
                    value: item.id,
                    label: i18n.language === "ar" ? item.name2 : item.name,
                  }))}
                  onChange={(option) =>
                    formik.setFieldValue("toPersonId", option.value)
                  }
                  placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                />
                {formik.errors.toPersonId && formik.touched.toPersonId && (
                  <div style={{ color: "red" }}>{formik.errors.toPersonId}</div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-xl-6 mx-auto">
            <div className="form-group mb-3">
              <label className="form-label">{t("Note")}</label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={3}
                name="userComment"
                onChange={formik.handleChange}
                value={formik.values.userComment}
              />
            </div>
          </div>
        </div>

      </div>

      <div className="text-center">
        <button
          type="submit"
          className="btn me-1 btn-theme mb-1"
          disabled={formik.isSubmitting}
        >
          <i className="fas fa-plus fa-fw me-1"></i>
          {/* {t("Add")} */}
          إرســال
        </button>

        <button
          type="button"
          className=" btn me-1 btn-outline-default mb-1"
          onClick={() => Navigate(-1, { replace: true })}
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
      </div>
    </Form>
  );
};

export default ActionRequestForm;
