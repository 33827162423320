import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../components/card/card";
import { GetApprovalGroupList } from "../../../../store/Ame/approvalGroupSlice";
import {
  AddAmeActionRule,
  GetAmeActionRule,
} from "../../../../store/Ame/ameActionRuleSlice";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import ActionRuleList from "./ActionRuleList";

function AddAmeAction() {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { ruleId } = useParams();
  // const { id } = useParams();
  const dispatch = useDispatch();

  //

  // Get Ame ApprovalGroup List for fill <Select>
  const { ApprovalGroupList } = useSelector((state) => state.ApprovalGroup);
  useEffect(() => {
    dispatch(GetApprovalGroupList());
  }, []);
  // console.log(ApprovalGroupList);

  // Get
  const { ActionRuleListByRuleId } = useSelector(
    (state) => state.AmeActionRule
  );
  useEffect(() => {
    dispatch(GetAmeActionRule(ruleId));
  }, []);
  // console.log(ActionRuleListByRuleId);

  const formik = useFormik({
    initialValues: {
      ApprovalType: "", // hide show Element  and validation Dynamic Only
      supperVisorLevel: "",
      approvalGroupId: "",
      order: 0,
    },
    // validationSchema: Yup.object().shape({
    //   ApprovalType: Yup.string()
    //     .required(() => t("Required"))
    //     .notOneOf(["إختر", "Choose"], "Required"),

    //   supperVisorLevel: Yup.string().when("ApprovalType", {
    //     is: "0",
    //     then: Yup.string().required().notOneOf(["إختر", "Choose"], "Required"),
    //   }),

    //   approvalGroupId: Yup.string().when("ApprovalType", {
    //     is: "1",
    //     then: Yup.string().required().notOneOf(["إختر", "Choose"], "Required"),
    //   }),

    //   order: Yup.string().when("ApprovalType", {
    //     is: "1",
    //     then: Yup.string().required(),
    //   }),
    // }),
    onSubmit: (values) => {
      console.log(values);

      dispatch(
        AddAmeActionRule({
          ruleId: ruleId,
          supperVisorLevel:
            formik.values.ApprovalType === "0"
              ? formik.values.supperVisorLevel
              : null,
          approvalGroupId:
            formik.values.ApprovalType === "1"
              ? formik.values.approvalGroupId
              : null,
          order:
            formik.values.ApprovalType === "1" ? formik.values.order : null,
        })
      )
        .unwrap()
        .then(() => {
          formik.resetForm();
          CustomAlert({ action: "Add" });
          dispatch(GetAmeActionRule(ruleId));
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  var isSupervisor = false;
  ActionRuleListByRuleId.map((item) => {
    if (item.supperVisorLevel !== null) {
      isSupervisor = true;
    }
  });
  // console.log(isSupervisor);

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">PAGES</a>
            </li>
            <li className="breadcrumb-item active">Ame Rule</li>
          </ul>
          <h1 className="page-header mb-0"> Add Ame Action </h1>
        </div>
      </div>


      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-3"></div>
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    {t("Approval Group")}
                  </label>
                  <Form.Select
                    className="form-select"
                    name="ApprovalType"
                    onChange={formik.handleChange}
                    value={formik.values.ApprovalType}
                    isInvalid={
                      formik.touched.ApprovalType && formik.errors.ApprovalType
                    }
                  >
                    <option value={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>

                    {!isSupervisor && (
                      <option value="0">
                        {i18n.language === "ar" ? "مدير مباشر " : "supervisor"}
                      </option>
                    )}

                    <option value="1">
                      {i18n.language === "ar"
                        ? "قائمة موافقات"
                        : "list Approvals"}
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.conditionId}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-3"></div>
            </div>

            <div className="row">
              {formik.values.ApprovalType === "0" ? (
                <div className="col-xl-6">
                  <div className="form-group mb-3">
                    <label className="form-label">
                      {t("Super Visor Level")}
                    </label>
                    <Form.Select
                      className="form-select"
                      name="supperVisorLevel"
                      onChange={formik.handleChange}
                      value={formik.values.supperVisorLevel}
                      isInvalid={
                        formik.touched.supperVisorLevel &&
                        formik.errors.supperVisorLevel
                      }
                    >
                      <option value={null}>
                        {i18n.language === "ar" ? "إختر" : "Choose"}
                      </option>

                      <option value="1">
                        {i18n.language === "ar" ? " الأول " : "the first"}
                      </option>

                      <option value="2">
                        {i18n.language === "ar" ? "الثاني " : "the second "}
                      </option>

                      <option value="3">
                        {i18n.language === "ar" ? "الثالث" : "the third"}
                      </option>

                      <option value="4">
                        {i18n.language === "ar" ? "الرابع " : "the fourth"}
                      </option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.conditionId}
                    </Form.Control.Feedback>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            {formik.values.ApprovalType === "1" ? (
              <>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        {t("Approval Group")}
                      </label>
                      <Form.Select
                        className="form-select"
                        name="approvalGroupId"
                        onChange={formik.handleChange}
                        value={formik.values.approvalGroupId}
                        isInvalid={
                          formik.touched.approvalGroupId &&
                          formik.errors.approvalGroupId
                        }
                      >
                        <option value={null}>
                          {i18n.language === "ar" ? "إختر" : "Choose"}
                        </option>

                        {ApprovalGroupList.map((item) => (
                          <option key={item.id} value={item.id}>
                            {i18n.language === "ar" ? item.name2 : item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.conditionId}
                      </Form.Control.Feedback>
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <div className="form-group mb-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        {t("Order")}
                      </label>
                      <Form.Control
                        className="form-control"
                        type="number"
                        name="order"
                        onChange={formik.handleChange}
                        value={formik.values.order}
                        isInvalid={formik.touched.order && formik.errors.order}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-outline-theme mb-1"
              >
                <i className="fas fa-plus fa-fw me-1"></i>
                {formik.isSubmitting ? "Adding..." : t("Add")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-outline-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>

          <hr className="mb-4" />

          <div className="row">
            <div className="col-lg-12">
              <ActionRuleList data={ActionRuleListByRuleId} />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default AddAmeAction;
