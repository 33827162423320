import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import { AddUpdateUserFunction } from "../../../../store/NTX/Security/usersSlice";

function UserFunctionTable({ UserFunctionList, selectedValue }) {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleExtractSelectedFunctions = () => {
    setIsSubmitting(true);
    const selectedFunctions = [];
    const tableRows = document.querySelectorAll("table tbody tr");

    tableRows.forEach((row) => {
      const functionId = row.getAttribute("data-function-id");
      const checkboxes = row.querySelectorAll('input[type="checkbox"]');
      const checkedValues = {};

      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          checkedValues[checkbox.getAttribute("name")] = true;
        }
        //  else {
        //   checkedValues[checkbox.getAttribute('name')] = false;
        // }
      });

      const existingFunctionIndex = selectedFunctions?.findIndex(
        (func) => func.functionId === functionId
      );

      if (Object.keys(checkedValues)?.length > 0) {
        if (existingFunctionIndex >= 0) {
          // Merge checked values with existing function object
          selectedFunctions[existingFunctionIndex].functions[0] = {
            ...selectedFunctions[existingFunctionIndex].functions[0],
            ...checkedValues,
          };
        } else {
          // Create new function object
          const newFunction = {
            functionId: functionId,
            indexAction: false,
            createAction: false,
            editAction: false,
            deleteAction: false,
            detailsAction: false,
            ...checkedValues,
          };
          selectedFunctions.push(newFunction);
        }
      } else {
        if (existingFunctionIndex >= 0) {
          // Remove function object from list
          selectedFunctions.splice(existingFunctionIndex, 1);
        }
      }
    });
    console.log(JSON.stringify(selectedFunctions));

    const list = {
      functions: selectedFunctions,
      userId: userId,
    };
    // console.log(JSON.stringify(list));
    // Post to Tariq
    dispatch(AddUpdateUserFunction(list))
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        CustomAlert({ action: "Add" });
        Navigate(-1, { replace: true });
      })
      .catch((error) => {
        setIsSubmitting(false);
        CustomAlert({ action: "Error" });
      });
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table ">
          <thead className="table-dark">
            <tr>
              <th className="w-25" >{t("Name")}</th>

              <th className="w-auto">{t("Add")}</th>

              <th className="w-auto">{t("Button.Delete")}</th>

              <th className="w-auto">{t("Button.Edit")}</th>

              <th className="w-auto">{t("Show")}</th>

              <th className="w-auto">{t("Watch")}</th>

            </tr>
          </thead>
          <tbody>
            {UserFunctionList.map((item, index) => (
              <tr
                key={item.functionId}
                data-function-id={item.functionId}
                hidden={selectedValue !== item.moduleId}
              >
                <td className="align-middle">
                  {i18n.language === "ar" ? item.name2 : item.name}
                </td>

                <td className="align-middle">
                  <Form.Check
                    type="switch"
                    name="createAction"
                    //  defaultValue={item.createAction}
                    defaultChecked={item.createAction}
                  />
                </td>

                <td className="align-middle">
                  <Form.Check
                    type="switch"
                    name="deleteAction"
                    //  defaultValue={item.deleteAction}
                    defaultChecked={item.deleteAction}
                  />
                </td>

                <td className="align-middle">
                  <Form.Check
                    type="switch"
                    name="editAction"
                    //  defaultValue={item.editAction}
                    defaultChecked={item.editAction}
                  />
                </td>

                <td className="align-middle">
                  <Form.Check
                    type="switch"
                    name="detailsAction"
                    //  defaultValue={item.detailsAction}
                    defaultChecked={item.detailsAction}
                  />
                </td>

                <td className="align-middle">
                  <Form.Check
                    type="switch"
                    name="indexAction"
                    //  defaultValue={item.indexAction}
                    defaultChecked={item.indexAction}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="text-center">
        <button
          disabled={isSubmitting}
          type="submit"
          className="btn me-1 btn-outline-theme mb-1"
          onClick={() => handleExtractSelectedFunctions()}
        >
                { isSubmitting? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-2"></i>
                )}
                {t("Save")}
        </button>

        <button
          type="button"
          className=" btn me-1 btn-outline-default mb-1"
          onClick={() => Navigate(-1, { replace: true })}
        >
          <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button>
      </div>
    </>
  );
}

export default UserFunctionTable;
