import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { getReligions } from "../../store/lookupSlice";

const useLookupReligions = ()=>{

    const dispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(getReligions())
      },[])

    const { RELIGIONS } = useSelector((state) => state.Lookup); 

    return { RELIGIONS } ;
};

export default useLookupReligions ;