
import { ntxAPI } from '../../../API/axios-custom';
import CustomAlert from '../../../components/Alert/CustomAlert';

const useAddRequest = () => {

    const AddRequest = async (requestFormData) => {
        const formData = new FormData();
        formData.append("SelectedPersonId", requestFormData.selectedPersonId);
        formData.append("IsPersonaly", requestFormData.isPersonaly);
        formData.append("DynamicFormId", requestFormData.dynamicFormId);
        formData.append("ReferenceId", requestFormData.referenceId);
    
        
        for (let i = 0; i < requestFormData?.values?.length; i++) {
          const value = requestFormData?.values[i];
          formData.append(`Values[${i}].dynamicFormColumnID`, value.dynamicFormColumnID);
          formData.append(`Values[${i}].dataType`, value.dataType);
          formData.append(`Values[${i}].value`, value.value);
          formData.append(`Values[${i}].lableText`, value.lableText);
          formData.append(`Values[${i}].lableText2`, value.lableText2);

          // console.log(value.fileAttachment?.length);
          for(let j=0 ; j < value?.fileAttachment?.length ; j++ ){
            formData.append(`Values[${i}].fileAttachment`, value.fileAttachment[j]);
          }
          
          formData.append(`Values[${i}].isUpdateAttach`, value.isUpdateAttach);
        }
        try {

          const { data } = await ntxAPI.post(
            "AmeRequest/AddRequest",
            formData,
            { headers: { 'Content-Type': 'multipart/form-data;' } }
          );
          return data
          // if (data?.succeeded === true) {
          //   return data;
          //   // return true;
          // } else {
          //   debugger
          //   CustomAlert({ action: "Info", msg: data?.message });
          //   return false;
          // }
        } catch (err) {
          return false;
        }
      };

      return { AddRequest };
}

export default useAddRequest
