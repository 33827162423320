import { useFormik } from 'formik';
import React from 'react'
import Form from "react-bootstrap/Form";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { insertAprovalGroup } from '../../../store/Ame/approvalGroupSlice';
import * as Yup from 'yup';
import CustomAlert from '../../../components/Alert/CustomAlert.tsx';
import useLookupModule from '../../../hooks/Lookups/use-Lookup-Module';




// valiedation scheme
const validationSchema = Yup.object().shape({
    name2: Yup.string()
    .required(),
  
    name: Yup.string()
    .required(), 

    moduleId: Yup.string()
    .notOneOf(["إختر", "Choose"], "Required")
    .required(() => ("Required")),

  });
  
const AddAprovalGroup = () => {

  const { Module } = useLookupModule();
   const { t, i18n } = useTranslation();
   const dispatch = useDispatch();
   const formik = useFormik({
     initialValues: {
       name2: "",
       name: "",
       description: "",
       moduleId:""
     },
     validationSchema: validationSchema,
     onSubmit: (values, { resetForm }) => {
       console.log(values);
 
         dispatch(
           insertAprovalGroup({
            name2: values.name2,
            name: values.name,
            moduleId: values.moduleId,
            description : values.description
           })
         )
           .unwrap()
           .then(() => {
            document.getElementById("closeModalButton").click();
            CustomAlert({ action: "Add" });
            
             resetForm();
           })
           .catch((error) => {
            formik.setSubmitting(false);
             console.log(error);
             CustomAlert({ action: "Error" });
           });
     },
   });

  return (
    <>
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label" >
              {t('Arabic Name')}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="name2"
              onChange={formik.handleChange}
              value={formik.values.name2}
              isInvalid={!!formik.errors.name2}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">
              {t('English Name')}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={!!formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name2}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="form-group mb-3">
            <label className="form-label">{t('Application')}</label>
            <Form.Select
              className="form-select"
              name="moduleId"
              value={formik.values.moduleId}
              onChange={formik.handleChange}
              isInvalid={formik.touched.moduleId && formik.errors.moduleId}
            >
              <option value="">
                {i18n.language === "ar" ? "إختر" : "Choose"}
              </option>
              {Module &&
                Module.map((item, idx) => (
                  <option key={++idx} value={item.id}>
                    {i18n.language === "ar" ? item.name2 : item.name}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.moduleId}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-xl-12">
        <div className="form-group mb-3">
          <label className="form-label">
            {t('Note')} 
          </label>
          <Form.Control
              className="form-control"
              as="textarea" rows={3}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />

          <div>
          
        </div>
        </div>
      </div>
        
      </div>
      <div className="text-center">
      <button disabled={formik.isSubmitting} type="submit" className="btn me-1 btn-outline-theme mb-1" id='SaveSubmit'>
      <i className="fa fa-save fa-fw me-1"></i> {formik.isSubmitting ? '...' : t('Button.Save')}
      </button>

      <button type="button" className=" btn me-1 btn-outline-default mb-1" data-bs-dismiss="modal" id="closeModalButton">
      <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
        </button></div>
    </Form>
    </>
  )
}

export default AddAprovalGroup