/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import TypeD from "./TypeD";
import TypeT from "./TypeT";
import TypeL from "./TypeL";
// import FileList from "../../../FileList";


const BuilderRequest = (props) => {
  const {
    FormColumnList,
    setFormData,
    formData
  } = props;

 

  const { t, i18n } = useTranslation();
  // const [formData, setFormData] = useState({});
  const sortedList = FormColumnList?.columnList
    ?.slice()
    .sort((a, b) => a.sequenceNum - b.sequenceNum);
  //
  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : null;
    setFormData({ ...formData, [name]: value });
  };
  //

  const handleInputChange = (e, selectedOption) => {
    // console.log(selectedOption);
    const { name, type, files, value, checked } = e.target;

    if (type === "checkbox") {
      const checkboxValue = checked ? "1" : "0";
      setFormData({ ...formData, [name]: checkboxValue });
    } else if (type === "file") {
      setFormData({ ...formData, [name]: files });
      console.log( (files) );
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  // useEffect(() => {
  //   const updatedData = {
  //     selectedPersonId:  "",
  //     isPersonaly: true,
  //     referenceId: referenceId || "",
  //     dynamicFormId: FormColumnList?.dynamicFormId,
  //     values: sortedList?.map((item) => ({
  //       dynamicFormColumnID: item.id,
  //       dataType: item.validationType || item.formatType,

  //       value: item.validationType === "BOOLEAN" && !formData[item.id]  ? "0" : 
  //       item.validationType === 'ATTACHED' ? null
  //           : formData[item.id] ,

  //       lableText: i18n.language === "ar" ? item.lable2 : item.lable,
  //       lableText2: i18n.language === "ar" ? item.lable2 : item.lable,
  //       fileAttachment : (item.validationType === 'ATTACHED' && formData[item.id]) ? formData[item.id] : null  ,
  //       isUpdateAttach : (item.validationType === 'ATTACHED' && formData[item.id]) ? true : false
  //     })),
  //   };
  //   setRequestFormData(updatedData);
  // }, [formData , referenceId ]);

  //______________________________________________________________________________________

  return (
    <div> 
      {/* <Form> */}
        <div className="row">
          {sortedList?.map((item, index) => {
            if (item.formatType === "S") {
              // eslint-disable-next-line default-case
              switch (item.validationType) {
                case "DATE":
                  return (
                    <div key={item.id} className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {i18n.language === "ar" ? item.lable2 : item.lable}
                        </label>
                        <Form.Control
                          className="form-control"
                          required={item.requiredFlag}
                          type="Date"
                          name={item.id}
                          onChange={handleInputChange}
                          value={formData[item.id] || ""}
                        />
                      </div>
                    </div>
                  );
                case "NUMBER":
                  return (
                    <div key={item.id} className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {i18n.language === "ar" ? item.lable2 : item.lable}
                        </label>
                        <Form.Control
                          className="form-control"
                          required={item.requiredFlag}
                          type="number"
                          name={item.id}
                          onChange={handleInputChange}
                          value={formData[item.id] || ""}
                        />
                      </div>
                    </div>
                  );
                case "TEXT":
                  return (
                    <div key={item.id} className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {i18n.language === "ar" ? item.lable2 : item.lable}
                        </label>
                        <Form.Control
                          className="form-control"
                          required={item.requiredFlag}
                          type="text"
                          name={item.id}
                          onChange={handleInputChange}
                          value={formData[item.id] || ""}
                        />
                      </div>
                    </div>
                  );
                case "TIME":
                  return (
                    <div key={item.id} className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {i18n.language === "ar" ? item.lable2 : item.lable}
                        </label>
                        <Form.Control
                          className="form-control"
                          required={item.requiredFlag}
                          type="time"
                          name={item.id}
                          onChange={handleInputChange}
                          value={formData[item.id] || ""}
                        />
                      </div>
                    </div>
                  );
              
                  case "ATTACHED":
                  return (
                    <div key={item.id} className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {i18n.language === "ar" ? item.lable2 : item.lable}
                        </label>
                        <Form.Control
                          className="form-control"
                          type="file"
                          name={item.id}
                          onChange={handleInputChange}
                          // onChange={handlTestChange}
                          // value={formData[item.id] | ""}
                          multiple 
                        />
                         {/* <FileList uploadedFiles={uploadedFiles} onDeleteFile={deleteFile} /> */}
                      </div>
                    </div>
                  );
                case "BOOLEAN":
                  return (
                    <div key={item.id} className="col-xl-6">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {i18n.language === "ar" ? item.lable2 : item.lable}
                        </label>
                        <Form.Check
                          style={{ margin: "10px 20px" }}
                          type="switch"
                          name={item.id}
                          checked={formData[item.id] === "1"}
                          // defaultChecked={formData[item.id] === "1"}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  );
              }
            } else if (item.formatType === "D") {
              return (
                <div key={item.id} className="col-xl-6">
                  <TypeD item={item} handleSelectChange={handleSelectChange} />
                </div>
              );
            } else if (item.formatType === "T") { 
              return (
                <div key={item.id} className="col-xl-6">
                  <TypeT key={item.id} item={item} handleSelectChange={handleSelectChange} tableId={item.tableId} />
                </div>
              );
            } 
             else if (item.formatType === "L") { 
              return (
                <div key={item.id} className="col-xl-6">
                  <TypeL key={item.id} item={item} handleSelectChange={handleSelectChange} tableId={item.tableId} />
                </div>
              );
            } 
          })}
        </div>
    </div>
  );
};

export default BuilderRequest ;


