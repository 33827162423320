import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface BreadcrumbList {
  name: any;
  link: string | null;
}

interface Props {
  BreadcrumbList: BreadcrumbList[];
  PageName?: any;
}

const Breadcrumb: React.FC<Props> = ({ BreadcrumbList, PageName }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center mb-3">
      <div>
        <ul className="breadcrumb">
          {BreadcrumbList.map((item , idx) => (
            <li className="breadcrumb-item" key={idx}>
              {item.link !== null ? <Link to={item.link} replace >{ t(item.name)}</Link> : t(item.name)}
            </li>
          ))}
        </ul>
        <h1 className="page-header mb-0">{ t(PageName) }</h1>
      </div>
    </div>
  );
};

export default Breadcrumb;
