import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import { getNationality } from "../../store/lookupSlice";
import { useTranslation } from "react-i18next";



const useLookupNationality = ()=>{

  const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(getNationality())
      },[])
    const { NATIONALITY , NationalityLoading } = useSelector((state) => state.Lookup); 

    const dataNATIONALITY =
    NATIONALITY &&
      NATIONALITY.map((item, idx) =>
        item.list.map((list) => (
          {
             value: list.lookupCode,
              label: i18n.language === "ar" ? list.meaning : list.meaning2
          }
        ))
      ).flat()

    return { NATIONALITY , dataNATIONALITY  , NationalityLoading} ;
};

export default useLookupNationality ;