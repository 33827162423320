import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const HistoryList = ({List}) => {

     const { t, i18n } = useTranslation();
    const Navigate = useNavigate();


    
  return (
    <>

    <div className="table-responsive  bg-inverse bg-opacity-10 rounded-4">

    
    <table className="table">
      <thead className="table-dark">
        <tr>
          <th scope="col">{t('Name')}</th>
          <th scope="col">التاريخ</th>
          <th scope="col">الإجـراء</th>
          <th scope="col">التعليقات</th>
        </tr>
      </thead>

      <tbody>
        {List && List.map((item, idx) => (
          <tr key={idx++}>
            <td className="align-middle">
              {i18n.language === "ar" ? item.name2 : item.name}
            </td>
            <td className="align-middle">{item.date}</td>
            <td className="align-middle">{item.action}</td>
            <td className="align-middle">{item.userComment}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>


</>
  )
}

export default HistoryList