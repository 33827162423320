import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from '../../../../components/card/card';
import Loader from '../../../../components/Loader/Loader';
import Pagination from '../../../../components/Pagination/Pagination';
import { GetPersonlListWithPayrol } from '../../../../store/HR/Payroll/runPayrollSlice';

const QuickPay = () => {
    const { t , i18n } = useTranslation();
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(10);
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    
    const { PersonlListWithPayrol, loading } = useSelector( (state) => state.runPayroll) 
    console.log(PersonlListWithPayrol);

    useEffect(() => {
        dispatch(GetPersonlListWithPayrol());

    }, [dispatch]);

  
    
   const handleSearch = (event) => {
      setSearch(event.target.value);  
    };

    const filteredPerson = PersonlListWithPayrol.slice()?.filter((item) => {
        const searchLowerCase = search.toLowerCase();
        return (
          item.personName?.toLowerCase().includes(searchLowerCase) ||
          item.personName2?.toLowerCase().toString().includes(searchLowerCase) ||
          item.employeeNumber?.toString().includes(searchLowerCase)
        );
      });
  
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData =filteredPerson.slice(indexOfFirstData, indexOfLastData);
  
      const handlePagination = (pageNumber) => setCurrentPage(pageNumber);
  //_____________________

  const handleNavigate = (item) => {
    localStorage.setItem("personName2", item.personName2);
    localStorage.setItem("personName", item.personName);
    Navigate(`${item.payrollId}/${item.assignmentId}/AddQuickPay`);
  };
  
  

  
  return (
    <>
          <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active"> مسير راتب افرادي</li>
          </ul>
          <h1 className="page-header mb-0"> مسير راتب إفرادي</h1>
        </div>
      </div>
    <Card>
      <CardBody>
            <Loader loading={loading} />
     <div className="tab-content p-4">
        <div className="tab-pane fade show active">
          <div className="row">
            <div className="col-7 col-md-6 d-flex justify-content-start">
              <div className="input-group mb-4">
                <div className="flex-fill position-relative">
                  <div className="input-group">
                    <div
                      className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                      style={{ zIndex: 1020, right: "10px" }}
                    >
                      <i className="fa fa-search opacity-5"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control ps-35px"
                      onChange={handleSearch}
                      placeholder={t("Search") + " ..."}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //  START TABLE   */}
                       <div className="table-responsive">
                 <table className="table">
                 <thead className="table-dark">
                     <tr>
                      <th
                        scope="col"
                      >
                     {t("Employee Name")}
                      </th>
                      <th
                        scope="col"
                      >
                       {t("Employee Number")}
                      </th>
                      <th
                        scope="col"
                      >
                       {t("Payroll")}
                      </th>

                      <th
                       scope="col"
                      >
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                      {currentData.map((item , index )=> (
                      <tr key={++index}>
                      <td className="align-middle">{i18n.language === 'ar' ? item.personName2 : item.personName }</td>
                      <td className="align-middle">{item.employeeNumber}</td>
                      <td className="align-middle">{i18n.language === 'ar' ? item.payrollName2 : item.payrollName }</td>
                      <td>
                      
                        
                      <div className="btn-group">  
                      <button type="button" onClick={() => handleNavigate(item) } className="btn btn-outline-lime"  >
                        <i className="fas fa-lg fa-fw me-2 fa-money-bill-alt"></i>
                         مسيــر إفرادي
                      </button>
                      
                      </div>
                        
                      </td>
                    </tr>
                    ))}
                    
                  </tbody>
                </table>
              </div>
          <Pagination
            filteredData={PersonlListWithPayrol}
            dataPerPage={dataPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />

          {/* </CardBody> */}
        </div>
      </div>
      </CardBody>
    </Card>

    </>
  )
}

export default QuickPay