import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../components/card/card";
import Loader from "../../../../components/Loader/Loader";
import {
  GetOrganizationStructure,
} from "../../../../store/HR/Organizations/OrganizationStructureSice";
import ListForm from "./ListForm";
import TreeBuilder from "./TreeBuilder";

const OrganizationStructure = () => {
  const { id } = useParams();
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [orgData, setOrgData] = useState(null)


  const { loading } = useSelector(
    (state) => state.OrganizationStructure
  );

  const data =  useSelector((state) => state.OrganizationStructure.OrgStructureList );

  useEffect(() => {
    // dispatch(getOrganizations());
    dispatch(GetOrganizationStructure());
  }, [dispatch]);
  // console.log(OrgStructureList , loading);


  var BG = !loading && data?.filter(
    (item) => item.organizationIdParent === item.organizationIdChild
  );
  console.log(BG);




  useEffect(()=>{
     setOrgData(BG[0])
  },[loading])
   
  
   

  return (
    <>
          <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item active">{t("Organizational Structure")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Organizational Structure")}</h1>
        </div>
      </div>
    <Card>
      <CardBody>
              <div className="tab-content p-4">
        <div className="tab-pane fade show active" id="allTab">
        <div className="row">

           <div className="col-xl-6">
            {
              !loading && 
              <>
              <>
                <details open="open">
                  <summary onClick={() =>setOrgData(BG[0])}>{ i18n.language ==="ar" ? BG[0]?.childName2 : BG[0]?.childName}</summary>

                  <TreeBuilder
                    All={data}
                    parentId={BG[0]?.organizationIdChild}
                    List={data?.filter((item) => {
                      return (
                         item.organizationIdParent === BG[0]?.organizationIdChild &&
                         item.organizationIdChild !== item.organizationIdParent
                      );
                    })} 
                    setOrgData={setOrgData}
                    
                  />
                </details>
              </>


          </>
            }
          
        </div>
        

          <Loader loading={loading}/>
     
        

        <div className="col-xl-6">
          {
            data?.length > 0 && 
            <ListForm
           data={data}
           orgData={orgData} />
          }
          
          
          

        </div>

        </div>
       
          
        </div>
      </div>
      </CardBody>
    </Card>

    </>
  );
};

export default OrganizationStructure;





          //   {/* <div className="col-xl-6">
          //   <details open="open">
          //     <summary>myproject</summary>
          //     <div class="folder">
          //       <p>app.py</p>
          //       <p>package.json</p>
          //       <details open="open">
          //         <summary>static</summary>
          //         <div class="folder">
          //           <details>
          //             <summary>css</summary>
          //             <div class="folder">
          //               <p>index.css</p>
          //               <p>template.css</p>
          //             </div>
          //           </details>
          //           <details open="open">
          //             <summary>js</summary>
          //             <div class="folder">
          //               <p>index.js</p>
          //               <p>jquery-min.js</p>
          //             </div>
          //           </details>
          //           <details>
          //             <summary>img</summary>
          //             <div class="folder">
          //               <p>banner.png</p>
          //               <p>foo.png</p>
          //             </div>
          //           </details>
          //         </div>
          //       </details>
          //       <details open="open">
          //         <summary>templates</summary>
          //         <div class="folder">
          //           <p>index.html</p>
          //           <p>template.html</p>
          //         </div>
          //       </details>
          //     </div>
          //   </details>
          //   <details open="open">
          //     <summary>myproject</summary>
          //   </details>
          // </div> */}