import { useFormik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "../../../../components/card/card";
import {
  GetRotationPlanById,
  UpdateRotationPlan,
} from "../../../../store/HR/Raqeeb/raqeebSlice";
import DaysCard from "./DaysCard";
import validationSchema from "../validationSchema";
import useInitialValues from "./useInitialValues";
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx';
import Loader from "../../../../components/Loader/Loader.js";

function RotationSetting() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { initialValues, data, loading } = useInitialValues();
  // console.log(initialValues);
  const formik = useFormik({
    // initialValues
    initialValues: initialValues,
    validationSchema: validationSchema(t),

    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);

      dispatch(
        UpdateRotationPlan(
          {
            raqRotationPlansDto: {
              id: id ,
              // businessGroupId: ,
              name: values.raqRotationPlan.name ,
              name2: values.raqRotationPlan.name2 ,
              startDate: values.raqRotationPlan.startDate ,
              endDate:  values.raqRotationPlan.endDate,
              description:  values.raqRotationPlan.description,
              allowIn:  values.raqRotationPlan.allowIn ,
              allowOut:  values.raqRotationPlan.allowOut,
              count_Fingerprint:  values.raqRotationPlan.count_Fingerprint
            },
            sat: {
              startIn: values.sat.startIn,
              endOut: values.sat.endOut,
              fingerprintTime1: values.sat.fingerprintTime1,
              fingerprintTime2:  values.sat.fingerprintTime2,
              duration1: values.sat.duration1 ,
              fingerprintTime3: values.sat.fingerprintTime3,
              fingerprintTime4: values.sat.fingerprintTime4,
              duration2: values.sat.duration2,
              allowIn:values.sat.allowIn ,
              allowOut:values.sat.allowOut ,
              countFingerprint: values.sat.countFingerprint,
              isExtraDay: values.sat.isExtraDay,
            },
            sun: {
              startIn: values.sun.startIn,
              endOut: values.sun.endOut,
              fingerprintTime1: values.sun.fingerprintTime1,
              fingerprintTime2:  values.sun.fingerprintTime2,
              duration1: values.sun.duration1 ,
              fingerprintTime3: values.sun.fingerprintTime3,
              fingerprintTime4: values.sun.fingerprintTime4,
              duration2: values.sun.duration2,
              allowIn:values.sun.allowIn ,
              allowOut:values.sun.allowOut ,
              countFingerprint: values.sun.countFingerprint,
              isExtraDay: values.sun.isExtraDay,
              },
              mon: {
                startIn: values.mon.startIn,
                endOut: values.mon.endOut,
                fingerprintTime1: values.mon.fingerprintTime1,
                fingerprintTime2:  values.mon.fingerprintTime2,
                duration1: values.mon.duration1 ,
                fingerprintTime3: values.mon.fingerprintTime3,
                fingerprintTime4: values.mon.fingerprintTime4,
                duration2: values.mon.duration2,
                allowIn:values.mon.allowIn ,
                allowOut:values.mon.allowOut ,
                countFingerprint: values.mon.countFingerprint,
                isExtraDay: values.mon.isExtraDay,
              },
              tue: {
                startIn: values.tue.startIn,
                endOut: values.tue.endOut,
                fingerprintTime1: values.tue.fingerprintTime1,
                fingerprintTime2:  values.tue.fingerprintTime2,
                duration1: values.tue.duration1 ,
                fingerprintTime3: values.tue.fingerprintTime3,
                fingerprintTime4: values.tue.fingerprintTime4,
                duration2: values.tue.duration2,
                allowIn:values.tue.allowIn ,
                allowOut:values.tue.allowOut ,
                countFingerprint: values.tue.countFingerprint,
                isExtraDay: values.tue.isExtraDay,
              },
              wed: {
                startIn: values.wed.startIn,
                endOut: values.wed.endOut,
                fingerprintTime1: values.wed.fingerprintTime1,
                fingerprintTime2:  values.wed.fingerprintTime2,
                duration1: values.wed.duration1 ,
                fingerprintTime3: values.wed.fingerprintTime3,
                fingerprintTime4: values.wed.fingerprintTime4,
                duration2: values.wed.duration2,
                allowIn:values.wed.allowIn ,
                allowOut:values.wed.allowOut ,
                countFingerprint: values.wed.countFingerprint,
                isExtraDay: values.wed.isExtraDay,
              },
              thu: {
                startIn: values.thu.startIn,
                endOut: values.thu.endOut,
                fingerprintTime1: values.thu.fingerprintTime1,
                fingerprintTime2:  values.thu.fingerprintTime2,
                duration1: values.thu.duration1 ,
                fingerprintTime3: values.thu.fingerprintTime3,
                fingerprintTime4: values.thu.fingerprintTime4,
                duration2: values.thu.duration2,
                allowIn:values.thu.allowIn ,
                allowOut:values.thu.allowOut ,
                countFingerprint: values.thu.countFingerprint,
                isExtraDay: values.thu.isExtraDay,
              },
              fri: {
                startIn: values.fri.startIn,
                endOut: values.fri.endOut,
                fingerprintTime1: values.fri.fingerprintTime1,
                fingerprintTime2:  values.fri.fingerprintTime2,
                duration1: values.fri.duration1 ,
                fingerprintTime3: values.fri.fingerprintTime3,
                fingerprintTime4: values.fri.fingerprintTime4,
                duration2: values.fri.duration2,
                allowIn:values.fri.allowIn ,
                allowOut:values.fri.allowOut ,
                countFingerprint: values.fri.countFingerprint,
                isExtraDay: values.fri.isExtraDay,
              },
          }

        )
      )
        .unwrap()
        .then(() => {
            formik.resetForm();
            dispatch(GetRotationPlanById(id))
            CustomAlert({ action: "Edit" });
        })
        .catch((error) => {
            formik.setSubmitting(false)
            CustomAlert({ action: "Error" });
        });
    },
  });

  // __________________________


  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">{t('Home')}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/RAQ/RotationPlan">{t('Rotation plan')}</Link>
            </li>
            <li className="breadcrumb-item active">
            {t('Plan settings')}
            </li>
          </ul>
          <h1 className="page-header mb-0">
          {t('Rotation Plan settings')}
          </h1>
        </div>
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <Card>
          <Loader loading={loading} />
          <CardBody>
            <div className="row">
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">
                  {t('Arabic Name')}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="raqRotationPlan.name2"
                    onChange={formik.handleChange}
                    value={formik.values.raqRotationPlan.name2 || ""}
                    isInvalid={
                      formik.errors?.raqRotationPlan?.name2 &&
                      formik.touched?.raqRotationPlan?.name2
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.raqRotationPlan?.name2}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">
                  {t('English Name')}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="raqRotationPlan.name"
                    onChange={formik.handleChange}
                    value={formik.values.raqRotationPlan.name || ""}
                    isInvalid={
                      formik.errors?.raqRotationPlan?.name &&
                      formik.touched?.raqRotationPlan?.name
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.raqRotationPlan?.name2}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">
                  {t('Number of fingerprints')}
                  </label>

                  <Form.Select
                    className="form-select"
                    name="raqRotationPlan.count_Fingerprint"
                    onChange={formik.handleChange}
                    value={
                      formik.values.raqRotationPlan.count_Fingerprint || ""
                    }
                    isInvalid={
                      formik.errors?.raqRotationPlan?.count_Fingerprint &&
                      formik.touched?.raqRotationPlan?.count_Fingerprint
                    }
                  >
                    <option value="">
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>

                    <option value="1">بصمة واحدة</option>

                    <option value="2">بصمتــــان</option>

                    <option value="4">4 بصمــات</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.raqRotationPlan?.count_Fingerprint}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">
                  {t('Allow In')}
                  </label>
                  <Form.Control
                    type="time"
                    className="form-c ontrol"
                    name="raqRotationPlan.allowIn"
                    onChange={formik.handleChange}
                    value={formik.values.raqRotationPlan.allowIn || ""}
                    isInvalid={
                      formik.errors?.raqRotationPlan?.allowIn &&
                      formik.touched?.raqRotationPlan?.allowIn
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.raqRotationPlan?.allowIn}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">
                  {t('Allow Out')}
                  </label>
                  <Form.Control
                    type="time"
                    className="form-c ontrol"
                    name="raqRotationPlan.allowOut"
                    onChange={formik.handleChange}
                    value={formik.values.raqRotationPlan.allowOut || ""}
                    isInvalid={
                      formik.errors?.raqRotationPlan?.allowOut &&
                      formik.touched?.raqRotationPlan?.allowOut
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.raqRotationPlan?.allowOut}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">
                  {t('Start Date')}
                  </label>
                  <Form.Control
                    type="date"
                    className="form-c ontrol"
                    name="raqRotationPlan.startDate"
                    onChange={formik.handleChange}
                    value={formik.values.raqRotationPlan.startDate || ""}
                    isInvalid={
                      formik.errors?.raqRotationPlan?.startDate &&
                      formik.touched?.raqRotationPlan?.startDate
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.raqRotationPlan?.startDate}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">
                  {t('Date_from')}
                  </label>
                  <Form.Control
                    type="date"
                    className="form-c ontrol"
                    name="raqRotationPlan.endDate"
                    onChange={formik.handleChange}
                    value={formik.values.raqRotationPlan.endDate || ""}
                    isInvalid={
                      formik.errors?.raqRotationPlan?.endDate &&
                      formik.touched?.raqRotationPlan?.endDate
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.raqRotationPlan?.endDate}
                  </Form.Control.Feedback>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="form-group mb-3">
                  <label className="form-label">
                  {t('Note')}
                  </label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={3}
                    name="raqRotationPlan.description"
                    onChange={formik.handleChange}
                    value={formik.values.raqRotationPlan.description || ""}
                  />
                  <div></div>
                </div>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-xl-6">
                <DaysCard
                  dayName={t('Saturday')}
                  day="sat"
                  formik={formik}
                  touched={formik.touched}
                  errors={formik.errors}
                />
              </div>

              <div className="col-xl-6">
                <DaysCard dayName={t('Sunday')} day="sun" formik={formik} />
              </div>

              <div className="col-xl-6">
                <DaysCard dayName={t('Monday')} day="mon" formik={formik} />
              </div>

              <div className="col-xl-6">
                <DaysCard dayName={t('Tuesday')} day="tue" formik={formik} />
              </div>

              <div className="col-xl-6">
                <DaysCard dayName={t('Wednesday')} day="wed" formik={formik} />
              </div>

              <div className="col-xl-6">
                <DaysCard dayName={t('Thursday')} day="thu" formik={formik} />
              </div>

              <div className="col-xl-6">
                <DaysCard dayName={t('Friday')} day="fri" formik={formik} />
              </div>
            </div>
            <hr />
            <div className="text-center">
              <button
                // disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
              >
                <i className="fa fa-save fa-fw me-1"></i>{" "}
                {formik.isSubmitting ? "Saving..." : "Save"}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
              <button
          type="button"
          onClick={formik.resetForm}
          className="btn me-1 btn btn-danger mb-1"
        >
          <i className="fa fa-trash-alt fa-fw me-1"></i>
          {t("Clear")}
        </button>
            </div>
          </CardBody>
        </Card>
      </Form>
    </>
  );
}

export default RotationSetting;
