
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../API/axios-custom";
// import axios from "axios";
// import variables from "../../APIURL/variables";
// import { getData, postData } from "../../Axios/axiosData";




export const GetResponsibility = createAsyncThunk("Responsibility/GetResponsibility", async (_, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;

  try {
    // const data = await getData("Security/GetAuthResponsibilityList");
    const {data} = await ntxAPI.get("Security/GetAuthResponsibilityList" );
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});


 export const insertResponsibility = createAsyncThunk("Responsibility/insertResponsibility", async (item, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;

  try {
      // const data = await postData("Security/AddAuthResponsibility" , item);
      const {data} = await ntxAPI.post("Security/AddAuthResponsibility" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
}); 


export const GetResponsibilityById = createAsyncThunk("Responsibility/GetResponsibilityById", async (id, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;

  try {
    // const data = await getData(`Security/GetAuthResponsibilityById?Id=${id}`);
    const {data} = await ntxAPI.get( `Security/GetAuthResponsibilityById?Id=${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

 export const UpdateResponsibility = createAsyncThunk("Responsibility/UpdateResponsibility", async (item, thunkAPI) => {
  const { rejectWithValue   } = thunkAPI;


  try {
    // const data = await postData("Security/UpdateAuthResponsibility" , item);
    const {data} = await ntxAPI.post("Security/UpdateAuthResponsibility" , item)
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
}); 



const initialState = { ResponsibilityList: [], loading: false, error: null , ResponsibilityById : [] };

const ResponsibilitySlice = createSlice({
  name: "Responsibility",
  initialState,
  reducers: { 
    cleanResponsibilityById: (state)=>{
      state.ResponsibilityById = [];
    }
  },
  extraReducers: {

   
    /////// get all Users
    [GetResponsibility.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetResponsibility.fulfilled]: (state, action) => {
      state.loading = false;
      state.ResponsibilityList = action.payload;
    },
    [GetResponsibility.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

     /////insert
    [insertResponsibility.pending]: (state) => {
      // state.loading = true;
      state.error = null;
    },
    [insertResponsibility.fulfilled]: (state, action) => {
      // state.loading = false;
      state.ResponsibilityList.push(action.payload);
    },
    [insertResponsibility.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


    // get one Responsibility by id
    [GetResponsibilityById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [GetResponsibilityById.fulfilled]: (state, action) => {
      state.loading = false;
      state.ResponsibilityById = action.payload;
    },
    [GetResponsibilityById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

        // edit 
    [UpdateResponsibility.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [UpdateResponsibility.fulfilled]: (state, action) => {
      state.loading = false;
      state.ResponsibilityById = action.payload;
    },
    [UpdateResponsibility.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

  
  },
});

export const {cleanResponsibilityById} = ResponsibilitySlice.actions
export default ResponsibilitySlice.reducer;
