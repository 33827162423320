import React from "react";
import { useTranslation } from "react-i18next";
import FileList from "../../../Attachments/FileList";

const BuilderValue = ({ data }) => {
  const { t, i18n } = useTranslation();
  console.log(data);

  return (
    <div className="table-responsive rounded-end-3">
      
      <table className="table table-bordered">
        <tbody>
          {data?.map((item, idx) => (
            <tr key={idx}>
              <th colSpan={1} scope="row" className="table-active" style={{ maxWidth: "100%", textAlign: "center" }}>
                {i18n.language === "ar" ? item.lableText2 : item.lableText}
              </th>
              <td colSpan={4}>
                {
                  item.dataType === "ATTACHED" ? 
                  <FileList Attachments={item.attachment}/> :
                  item.value
                }
                </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BuilderValue;
