import React, { memo } from "react";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../../../components/card/card.jsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Loading from "../../../../components/Loading";
// import Loader from "../../../../components/Loader/Loader.js";

const DataEmployees = ({ Employees, loading, error }) => {
  // const Navigate = useNavigate();
  const [t, i18n] = useTranslation();

  return (
    <>
      {/* <Loader loading={loading} /> */}

      <div className="row">
        {Employees &&
          Employees.map((item) => (
            <div
              className="col-lg-4 col-md-4 col-sm-6 col-xs-12 pb-3"
              key={item.id}
            >
              <Card className="m-2" key={item.id}>
                <CardHeader className="fw-bold small bg-theme bg-opacity-25">
                  {i18n.language === "ar" ? item.name2 : item.name} -{" "}
                  {item.employeeNumber}
                </CardHeader>
                <CardBody>
                  <div className="flex-fill">
                    <div className="d-flex align-items-center">
                      <h6>
                        <i className="fas fa-lg fa-fw me-2 fa-id-card"></i>{" "}
                        {item.nationalIdentifier}
                      </h6>
                      <div className="dropdown dropdown-icon ms-auto">
                        <a
                          href="#/"
                          className="text-inverse text-opacity-50"
                          data-bs-toggle="dropdown"
                        >
                          <i className="fa fa-ellipsis-h"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <Link
                            to={`${item.id}/Edit`}
                            className="dropdown-item"
                          >
                            {t("Button.Edit")}
                          </Link>
                          <a
                            href={`/HR/Employees/${item.id}/Assignments`}
                            className="dropdown-item"
                          >
                            {t("Button.assign")}
                          </a>

                          <Link
                            to={`absence/${item.id}`}
                            className="dropdown-item"
                          >
                            {t("Button.Absence")}
                          </Link>
                          <Link
                            to={`${item.id}/ExtraRecordFormPerson`}
                            className="dropdown-item"
                            onClick={() => {
                              localStorage.ReferenceName2 = item.name2;
                              localStorage.ReferenceName = item.name;
                            }}
                          >
                            {t("Extra Information")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-center bg-dark-transparent-5 mb-2">
                    {item?.personImage === null ? (
                      <img
                        src={
                          item.gender === "M"
                            ? "../../../assets/img/user/manEmp.png"
                            : "../../../assets/img/user/WomanEmp.png"
                        }
                        alt=""
                        width={120}
                        height={120}
                        className="rounded-circle"
                        loading="lazy"
                      />
                    ) : (
                      <img
                        src={item?.personImage?.filePath}
                        alt=""
                        width={120}
                        height={120}
                        className="rounded-circle"
                        loading="lazy"
                      />
                    )}
                  </div>

                  <h5 className="card-title">
                    {/* {item.organizatioN_NAME ?  item.organizatioN_NAME2  : "غير معين"} */}
                    {item.organizatioN_NAME
                      ? i18n.language === "ar"
                        ? item.organizatioN_NAME2
                        : item.organizatioN_NAME
                      : "لم يتم التعيين"}
                  </h5>
                </CardBody>
              </Card>
            </div>
          ))}
      </div>
      {/* </Loading> */}
    </>
  );
};

export default memo(DataEmployees);
