import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { AppSettings } from "../../config/app-settings";

import "./style.css";
import Logo2 from "./Logo2";

function Applications() {
  //__________________________________________________________________________
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //_____________________________________________________________________________________
  const context = useContext(AppSettings);

  function handleBackBtn() {
    navigate(-1);
  }

  // useEffect(() => {
  //   context.setAppHeaderNone(true);
  //   context.setAppSidebarNone(true);
  //   context.setAppContentClass("p-0");

  //   return function cleanUp() {
  //     context.setAppHeaderNone(false);
  //     context.setAppSidebarNone(false);
  //     context.setAppContentClass("");
  //   };

  //   // eslint-disable-next-line
  // }, []);

  const [CurrentSystem, setCurrentSystem] = useState(
    localStorage && typeof localStorage.CurrentSystem !== "undefined"
      ? localStorage.CurrentSystem
      : "HR"
  );

  const changeSystem = (Code) => {
    localStorage.setItem("CurrentSystem", Code);
    setCurrentSystem(Code);
    navigate("/dashboard", { replace: true });
    window.location.reload();
  };

  return (
    <>
      <div className="login" style={{ minHeight: "50vh" }}>
        <div className="login-content" style={{ maxWidth: "50rem" }}>
          <div style={{ marginBottom: "40px" }}>
            <Logo />
          </div>
          <hr />

          <div>
            <div className="row">
              <div
                className="col-md-3  col-sm-6 mb-4"
                onClick={() => changeSystem("HR")}
              >
                <div className="d-flex flex-column align-items-center text-center">
                  <div
                    className="w-70px h-70px d-flex align-items-center justify-content-center bg-gradient-orange text-white rounded-2 ms-n1 shadow-lg"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fab fa-lg fa-fw bi bi-person-fill-exclamation"></i>
                  </div>
                  <div className="fw-bold fs-5 m-2">بيانات الموظفين</div>
                </div>
              </div>

              <div
                className="col-md-3 col-sm-6  mb-4"
                onClick={() => changeSystem("AME")}
              >
                <div className="d-flex flex-column align-items-center text-center">
                  <div
                    className="w-70px h-70px d-flex align-items-center justify-content-center bg-gradient-custom-teal text-white rounded-2 ms-n1 shadow-lg"
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className="bi bi-recycle fa-lg"
                      // style={{ fontSize: "34px" }}
                    ></i>
                  </div>
                  <div className="fw-bold fs-5 m-2">
                    إدارة سير العمل للطلبات
                  </div>
                </div>
              </div>

              <div
                className="col-md-3 col-sm-6  mb-4"
                onClick={() => changeSystem("SU")}
              >
                <div className="d-flex flex-column align-items-center text-center">
                  <div
                    className="w-70px h-70px d-flex align-items-center justify-content-center bg-gradient-custom-indigo text-white rounded-2 ms-n1 shadow-lg"
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className="fa bi bi-buildings-fill fa-lg"
                      // style={{ fontSize: "34px" }}
                    ></i>
                  </div>
                  <div className="fw-bold fs-5 m-2">الخدمات المساندة</div>
                </div>
              </div>

              <div
                className="col-md-3 col-sm-6  mb-4"
                onClick={() => changeSystem("FLEET")}
              >
                <div className="d-flex flex-column align-items-center text-center">
                  <div
                    className="w-70px h-70px d-flex align-items-center justify-content-center bg-gradient-custom-indigo text-white rounded-2 ms-n1 shadow-lg"
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className="fas fa-lg fa-fw fa-truck"
                      // style={{ fontSize: "34px" }}
                    ></i>
                  </div>
                  <div className="fw-bold fs-5 m-2">إدارة الأسطــول</div>
                </div>
              </div>





              {/* <div className="col-md-3 col-sm-6  mb-4">
                <div className="d-flex flex-column align-items-center text-center">
                  <div
                    className="w-70px h-70px d-flex align-items-center justify-content-center bg-gradient-custom-teal text-white rounded-2 ms-n1 shadow-lg"
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className="bi bi-cash-coin fa-lg"
                      // style={{ fontSize: "34px" }}
                    ></i>
                  </div>
                  <div className="fw-bold fs-5 m-2">الإدارة المالية</div>
                </div>
              </div> */}

              {/* <div className="col-md-3 col-sm-6  mb-4">
                <div className="d-flex flex-column align-items-center text-center">
                  <div
                    className="w-70px h-70px d-flex align-items-center justify-content-center bg-gradient-custom-indigo text-white rounded-2 ms-n1 shadow-lg"
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className="fas fa-lg fa-fw bi bi-shop"
                      // style={{ fontSize: "34px" }}
                    ></i>
                  </div>
                  <div className="fw-bold fs-5 m-2">إدارة المخازن</div>
                </div>
              </div> */}

              {/* <div className="col-md-3  col-sm-6 mb-4">
                <div className="d-flex flex-column align-items-center text-center">
                  <div
                    className="w-70px h-70px d-flex align-items-center justify-content-center bg-gradient-orange text-white rounded-2 ms-n1 shadow-lg"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACsElEQVR4nO2YPWsVQRSGVwkqMQpq7iVqJVjYptHOxKSxECTBLp34A1SUYKfeJp2lhaCtqQxa6A8QbDR+3ICFIFhFsYiJIirqIyc5S8Z1d3Z29647F+aFhZsz787MszNzZiZRFBQUFBTkk4A20AGeA1/0WQSuA60a23WWS2VngDVLHavAtNcgbED8Vu89YAzYqc84sKBlv4CpOmDSBAwAD5xAdDrFI3HZ4ptVzydgOKpZwBbgjrb50QWkE4+EEdsO3ALmE9776r1WM0cEzGlbsk6PuoC8UM+YAREP53LCe0LjizVDXNB2fgAnNZYLEk+rIWNe7gE+pIDsUu9ahU7KVG5bymd0vcozY8SLgRjx5RSQ3epdLQnRAl4Br4H9KeWTwHdt42KiLBdE9gzRuAPIhHqflRyJrpGAuubIAMd0PYjmUt7PBZHNTrSQiM+nLPZ47VytANFN/G4DR4zMdFsyVhmQlm52olmL74p6VoB9FSDaidgS8E5/y4cayKjHDqKmad3s0BQr2WlInwljJMRzugpERpnoMTDoWret0Snd7LK00iuIFM+SZMrKEEbFw7LZyWIGPmtGeyprwpxOwN6cFJoLkfAeNP5eV8+gLA2PAC8tKTROsahnpGD96+ppp1MaOQS8YVN/wVSFEMUVR47xwgJGgfda35OcTGSdTo2BAMeNRPBIj/fWvaFCW/WAAKeAr8ZdZUfGVEqdTlkd6FXcSXqAkxOo6CawNef89M+aaBwEOG/cGjtlT7SNgrB5GxSQS4Ve9gGEjevlDX3nJ3C2PEJDIMA24K76v2X9x6ToEP9XEGAQeKheOZpMNtUxyoLotVZOnuiGN5pp9hzknHreAodtEF6DGKn2QOQgr0H6ocMEEM++PBnxXPUdCJ51jADi2RemYLzxDgSQoKCgoKhf9Qc1zhb5J2pRKAAAAABJRU5ErkJggg=="
                      alt=""
                    />
                  </div>
                  <div className="fw-bold fs-5 m-2">إدارة المبيعات</div>
                </div>
              </div> */}

              {/* <div
                className="col-md-3 col-sm-6  mb-4"
              >
                <div className="d-flex flex-column align-items-center text-center">
                  <div
                    className="w-70px h-70px d-flex align-items-center justify-content-center bg-gradient-blue-cyan text-white rounded-2 ms-n1 shadow-lg"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWX
MAAAsTAAALEwEAmpwYAAAB70lEQVR4nO2Zu04CQRSGt7IxWkhtiZVoLaK+gJTGB5DYa3wCFG/4Am
pj9B28lOoTiC1WBk0ojLdGE/zMmIOZkGV2l4VlNPMlk5CZc87Mz5nb7nqew+GIBHCF/VyGEdLkyd
LyQ2ghnqUQdnxOyH/ICDAA7AAPhKcGbClfm4Rs0zmbNgmpidtUBJ9sMzM2CamIWzaCz7T43NgkZD
HG1FqwRogCWIu42JXtqhcRei0kKXBCLAOXEcvAZcQycBmxDFxGLAOXEcvAZeSvguXX+MSE0Cc8J6
QN+j8E5LXH2rQXEyANPErMfGt/bQfSYWdNcsCt/F6JqeEX9ZwvMSvSR2JTqwoMGezngAvgFXgBzo
FZg/0wcJfkGqkD60DKYLsMNHzEN4CCwS8lsetJCBkJsJsEPoEvYBcYlVKWOtWWCYiR6rmQEHYnYr
rv03YgbUcd99cLIepFNfAOlHzeF4/7xMhI270pRj+EvEn1q1anpo5i0CfGoLR9mGL0Q0hJBrIRIy
Ol1hiJC2ljd2xYI4fWrBHTtit2E9quVdZ2rT2p+/DLVuRdKyo+/mqPLwacIwXDObIUIKAYdI5cd0
lI2JN9BjgDnqWcqqtHwMlebdd/19Bi57QvWcnfteKiBwbmtdvvWBdij2m33/nW/roKfcL7L0K+AQ
yN0f5NMPHNAAAAAElFTkSuQmCC"
                      alt=""
                    />
                  </div>
                  <div className="fw-bold fs-5 m-2">إدارة المشتريات </div>
                </div>
              </div> */}

              <div
                className="col-md-3 col-sm-6  mb-4"
                onClick={() => changeSystem("NTX")}
              >
                <div className="d-flex flex-column align-items-center text-center">
                  <div
                    className="w-70px h-70px d-flex align-items-center justify-content-center bg-gradient-blue-cyan text-white rounded-2 ms-n1 shadow-lg"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      style={{ width: "80%" }}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAAXNSR0
IB2cksfwAAAAlwSFlzAAAWJQAAFiUBSVIk8AAAAt9QTFRFAAAA//////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////dplpdAAAAPV0Uk5TAB8+1u1AG93Ts/I2HcXOJQ
+r4jsRyeUi5DIS3icQvSkMv+AsDRXH2ySsOLrRpfE/z/80BhbG7hwImEkowReeRhPDhlrX2bH5Aw
cZ8ASE9WSqGDzmeGgKoMpBo3ZtSOlq6o7AAU+VZ/OC+rBbAlyFBbiHV0XcC1lO+JN/6y7QqWl56H
T2R5tCVFE5+3yBcK/+tMwet8vaK168otIqiC9L2I9TRM21daHvxOG5TK01X4qu/VD0kSbVTfx79z
prbiCD374xkmIJpLtYqGZs5z0UQzPjwjchLXoac7JWnzBSY2HsZSOWDn6Jl41VXXJvmYCnkLac1E
o0J8UoAAAJB0lEQVR4nO2Z/UNT1xnHLyUDGt4yJAFBMMQAUkroNYhBbIuAEiRDK5o5bCka20hLhz
hfwlQEo7Ui1QqBUbRTihOmrW/UysCy2lU3p8WyTa3dOl3Xl3Vt99o/YOd5zk1yEgIkIekv8/mBc8
+59z6ffO89L9974Lh7cS/cioD7vgVGoOg7/mYEBYfcLw71LyMsPCJS8t2oKf5kREtlMRwnCY+d6j
9GnDR+GpQJibHT/cWQJ8mm0aOEcEWMfxgJiVEzSKGE4+SU1Jn+YMjTZA+QIiYd/nJB0gf9oEWeoc
okheQhPmoW1GNSpT6nqNNUMNKzZvM8n41vZk7qg3G+ZWhyRHNJIQEGz9M+lhs472FfMtSPqB4lRd
58noYMegA3TTHfh1o0+aICUkju561RGAntoQsW+kyLOr+oQMtxxWm8PUIWIaVkoY+06L5XupgUxU
t4NkIeg3NLy9LkPmEsK1lOdOi/zzvGimg4+4Oych9QdCtLHydFcQbvHCkBcP6JikcmTdE9WblSTX
SsGsXg+dWgxbBG9tRkKU8b1xIGt6rKBYR/BinPGnOqJ4Oofq7oh4RRs5bJzOLWaYBSoKpVTwKy3l
iLDCZxxY82MJSMjUDZVJpv8FrHZlMdKWp+zGTdspUL28bU63GcbDc2eKtlc2Md+aE1O8z2nJU7SX
tMPEPZBVqqt4sbvNPyvKgeHvluhsG/gKn2GO0tTXuhydBc+qI3lPvE+MC5hH377SlXwDhXv8RgD7
Tg1dUNpqc9p0wV7dPQo+hWJqdFyal3MfW2duF6Q7P5J55SQkXPaKzHD3cwWpLCXmYYnQdtdxgOmQ
s8o7wSvA4G2k+pVwzrYPIeZo43UEbec9C3tEdUXZ5QZqamJJPiVVVbGNXSzbuKqD2UcdT8Myg1x3
qed58REIxT7E4RzwfmYkuLxQWjYgb9BaATJmpOe0zsthkPC8eFLzQWMvVST3JwwyjGli48o8Q1IB
uWTk6yzl2bLJfiEj795zTX8Txs3RPlxDBtxhegz2nCqngN1JLDY90ySnFJaEZm2pJ20HV8a4UDw3
gCW9VPWRvMYGeIzVS4YZM1SSJgtKTa872Gg5J7XcQwqk6iDvWpJluTGJ9UQojisYkYCaepQUxOsd
18pk6Lp8722Rl9J6mONxhu/Dlsy0p5cwKbLE8TPUCPgqRWxjEJNpwvYXQ8TnXsZRiyWUKOoMDxbb
I6QzXXehwjPLD6BMpgXkn/DroU/mLA3qYatGXJTZWOY5TUx9DwxmVhbQ5Q9u+jjCmMjv4LqEP7Fq
uDMpRD9M7uMSm6HOwh+tmdlJIbyPNH0fhwmWUM45d08niS0WHOxCbNS4JNls1TjqHjiAr6et5Rno
9Af8hNe/tiMR68wz6rvfistBcYHY078TL5aSIJ30euItGlFk1+KYxaPS4W86iW6XSxCGX6c/+v6P
t418ToOI/aNOVQiaA2ucylTc4vWky66qXL9L5fy+1nXmljfvQbl7BtPdOfzXTMcDn9WA3BJ7y0ZO
Eoo6RrQMOrKy9R0UirsZ6CV2ON/ZfpnWcZHSW/QYYux9qwQrDJV+ROkGUmnEd1c2xhhUR2MjouUx
1PMDqKfkt11PbbmgSb3FfuBKk3T4FrqzfaQiuceZSds65ia1cp03TtPbys5rh9+ZThXsJQsMUJMr
wkGKYedV2SELs2CmcMaxjKwFuk5bqYZ6Pwfbwub7W1YQSxQ7+z5Dq/FEniyFZSnFtAL4yaYTtjeJ
YxRQO7ucxG3jEK6bWLhOWz9PdQi5O2/cGZQbSkV9yAS3GKb6T36XGYELPLUG6KeeeQRdIMEVDJxi
U468Dh9tEM+LRVALudUCrpE1B2HKFaNhWNSuwQEXRGhInIhAx5Yuw5VwxC6VXAmZmyCvouh8kAbq
ZDfrtxfMrhAEp5s2c3vp/E+DEYZHG3RMDFg3QiUhIGb6KU6u2jtfSzy/5qSrmBPlbYIRkjhgq7b1
mP9fRD1LSSPrHmJmfIyy2stUyJtmW5dGXL4OjcjJbUBXn0SG01iqYPdKilod8Bsf+ylthkpmFFsp
BDfVv13ngMMr5Te5HCGF7TckGLA+U2jP1oKdOSTkeWOkf16vgMokVhAeFTmV5rob9x0dtMxiq62L
I2uepDZPxxIh0Qr8fDLoOhwDYCN9CXOHzR4XG1URsclmRt6McPFXWtav3EDI77U08r6VGGk8KHYt
QenACHW3nHKKT9KdJqkw9hNzxl+si9T+HBylY9ofwZKRW0P+s7eOcQhnTLAag01cJP0Z4auOMWAi
g99aRHGa6SsVFxHXUol4xiEDNO31U7rGn0I3uZ6Y77n/RL714BLX+pqqT+WX/aBYPnuz/Gswc7m3
C7QLvMfR0QJ7L/ClpefAFrn7AfcIw34mdTLQc/lUNxoe8D7ZgZXUWX+Qj5cQZq3Hcxo73t/XCG0k
op+IwWm296xuC4D82fCY+3+HMm60gQMe5M/aLNXW8yr/WUwXEnTc14U/HfGB0jsOQkhDCU04KL+8
j8mecMjlteegi0fMHOJufxzDvMGn/mJjb9XVVXM06useNL1VfkxylzGEogrJjR7Fs5ih5uruhrLz
dXar7s+4oUynIm54IsTsluSMxHjzn32tdjeF834lPzHdK/Prlyxp61MzKJYdDt59Ds+klseNX8w4
iU15i8PcxxZx5cNWXknxLvGWSg/KvvXULR/ZvZ+LCHBUd8Ztlx/WQYsMN57QtCubXOBSMQGbMKb0
96m1v/+V3ot8nSUYxgNKM3Dqf7YCtd/5/GnURLUIoTIwq/cc/9d/WiyTMIJaMSDFtWiANDhqvJx9
tm35rgdjdDssQIlj/rIZaBa/xQm8WF4fUuhtNTYc7Kldn7MTW86a4Nr3chsShgRd96TWCY0IzKE2
VjmlFvIqw3AvKFUopgeNPixzGjXlG2oU3OhCc20EUNb5mPGRw3o7Ab1qdBM1+Ee7PE8H7jawbYZE
seKa5X4s6se0bR84hU9IKBDUCGG4bXu2hXHBe+D9SrVGf9wyA2eYT+M05dK3bL8HoXS3ta4WPllC
dG0fMYrKzXe2oUvaD01d8s8qsOiG/umnb4GUHixFVvTNy9+H+M/wG6XUxANHXSuwAAAABJRU5Erk
Jggg=="
                      alt=""
                    />
                    {/* <Logo2/> */}
                  </div>
                  <div className="fw-bold fs-5 m-2">إدارة النظام</div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Applications;
