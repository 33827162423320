import React from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { AddNtxLookup } from "../../../store/NTX/Lookup/lookupsSlice";
import CustomAlert from "../../../components/Alert/CustomAlert";
import { AddLookupSchema } from "../../ValidationForm/validationSchema";
import * as Yup from "yup";

interface Props {
  categoryName : string | undefined
  categoryValue : string | undefined
  FilterByCategory: { lookupCode: string; meaning: string; meaning2: string; }[];
}

const AddLookup: React.FC<Props> = ({categoryName , categoryValue ,FilterByCategory }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch()
 const ListValidate = FilterByCategory.map(item => item.lookupCode)
  
//_____________________ Code Validation __________________________________
 // Define the type for the validation function
type ValidationFunction = (value: string) => string | null;

const validateLookupCode: ValidationFunction = (value) => {
  // Convert both the input value and the list items to lowercase and remove spaces
  const normalizedValue = value.toLowerCase().replace(/\s/g, '');
  const normalizedList = ListValidate.map((item) => item.toLowerCase().replace(/\s/g, ''));

  if (normalizedList.includes(normalizedValue)) {
    return 'Lookup code already exists. Please choose a different code.';
  }
  return null; // Return null for no error
};

 

  const formik = useFormik({
    initialValues: {
      lookupType: categoryValue,
      lookupCode: "",
      meaning: "",
      meaning2: "",
      enabledFlag: false,
      description: categoryName,
      // "language": "string",
      // "securityGroupId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    },
    // validationSchema: AddLookupSchema(t),
    validationSchema: Yup.object().shape({
      lookupCode: Yup.string()
      .required(() => t("Required"))
        .test('lookupCodeExists', 'Lookup code already exists', (value) => validateLookupCode(value) === null),
        meaning2: Yup.string().required(() => t("Required")),
        meaning: Yup.string().required(() => t("Required")),
        lookupType: Yup.string().required(() => t("Required")),
        description: Yup.string().required(() => t("Required")),
    }),
    onSubmit: (values) => {
      console.log(values);
        dispatch(
          AddNtxLookup(
            {
              lookupType: categoryValue,
              lookupCode: values.lookupCode,
              meaning: values.meaning,
              meaning2: values.meaning2,
              enabledFlag: true,
              language: null,
              description: values.description,
              securityGroupId: null
            }
          )
        )
          .unwrap()
          .then(() => {
            formik.resetForm();
            CustomAlert({ action: "Add" });
            document.getElementById("CancelAddLookup")?.click();
          })
          .catch((error:any) => {
            formik.setSubmitting(false);
            CustomAlert({ action: "Error" });
          });
    },
  });


  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.submitForm();
        }}
      >
        <div className="col-xl-12 mx-auto">
          
          <div className="text-center">

          <h5 >{categoryName} </h5>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlSelect1">
              {t('Arabic Name')}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="meaning2"
              onChange={formik.handleChange}
              value={formik.values.meaning2 || ""}
              isInvalid={!!(formik.touched.meaning2 && formik.errors.meaning2)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.meaning2}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlSelect1">
            {t('English Name')}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="meaning"
              onChange={formik.handleChange}
              value={formik.values.meaning || ""}
              isInvalid={!!(formik.touched.meaning && formik.errors.meaning)}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.meaning}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="exampleFormControlSelect1">
            {t('Code')}
            </label>
            <Form.Control
              className="form-control"
              type="text"
              name="lookupCode"
              onChange={formik.handleChange}
              value={formik.values.lookupCode || ""}
              isInvalid={
                !!(formik.touched.lookupCode && formik.errors.lookupCode)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.lookupCode}
            </Form.Control.Feedback>
          </div>
        </div>

        <hr />

        <div className="text-center">
          <button
            disabled={formik.isSubmitting}
            type="submit"
            className="btn me-1 btn-theme mb-1"
          >
            <i className="fa fa-save fa-fw me-1"></i> {t("Save")}
          </button>
          <button
            type="button"
            className=" btn me-1 btn-default mb-1"
            data-bs-dismiss="modal"
            id="CancelAddLookup"
          >
            <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
          </button>
          <button
            type="button"
            onClick={() => formik.resetForm()}
            className="btn me-1 btn btn-danger mb-1"
          >
            <i className="fa fa-trash-alt fa-fw me-1"></i>
            {t("Clear")}
          </button>
        </div>
      </Form>
    </>
  );
};

export default AddLookup;
