/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Logout } from "../../store/Login/loginSlice";
import Logo from "./Logo";
import useNetworkStatus from "../../useNetworkStatus";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { slideToggle } from "../../composables/slideToggle";

function Header({ themeClass }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const notificationData = [
    {
      icon: "bi bi-person-circle text-theme",
      title: " طلب إجازة من الموظف عادل ",
      time: "18 مايو 2023 ",
    },
    {
      icon: "fas fa-comment-alt text-theme",
      title: "طلب استأذان من الموظف مايلين ",
      time: "18 مايو 2023 ",
    },
    {
      icon: "bi bi-person-circle text-theme",
      title: " طلب إجازة من الموظف نيترا  ",
      time: "18 مايو 2023 ",
    },
    {
      icon: "bi bi-person-circle text-theme",
      title: " طلب إجازة من الموظف محمد  ",
      time: "18 مايو 2023 ",
    },
  ];

  const toggleAppSidebarDesktop = () => {
    var elm = document.querySelector(".app");
    elm.classList.toggle("app-sidebar-collapsed");
  };

  const toggleAppSidebarMobile = () => {
    var elm = document.querySelector(".app");
    elm.classList.toggle("app-sidebar-mobile-toggled");
  };

  const AppLicationNavigate = () => {
    var elm = document.querySelector(".app");
    elm.classList.toggle("app-header-menu-search-toggled");
  };

  // handle Logout
  const handleLogout = () => {
    dispatch(Logout());
  };

  const status = useNetworkStatus();

  // handle change System
  const [CurrentSystem, setCurrentSystem] = useState(
    localStorage && typeof localStorage.CurrentSystem !== "undefined"
      ? localStorage.CurrentSystem
      : "NTX"
  );
  const Navigate = useNavigate();

  const changeSystem = (item) => {
    localStorage.setItem("CurrentSystem", item.Code);
    setCurrentSystem(item.Code);
    localStorage.setItem("moduleId", item.id);
    // window.location.replace('/dashboard');
    Navigate("/dashboard", { replace: true });
    // window.location.reload()
  };

  console.log(CurrentSystem);

  ///____________________________

  const Module = [
    {
      id: "0a6c6b89-1d52-4eaa-8d2c-000c74b1f6e0",
      name: "General System",
      name2: "النظام العام",
      Code: "NTX",
    },
    {
      id: "0a6c6b89-1d52-4eaa-8d2c-010c74b1f6e0",
      name: "Human Resources",
      name2: "الموارد البشرية",
      Code: "HR",
    },
    {
      id: "0a6c6b89-1d52-4eaa-8d2c-020c74b1f6e0",
      name: "Approvals management",
      name2: "إدارة الموافقات",
      Code: "AME",
    },
    {
      id: "0a6c6b89-1d52-4eaa-8d2c-030c74b1f6e0",
      name: "Supporting Units",
      name2: "الوحدات المساندة",
      Code: "SU",
    },
  ];

  return (
    <div id="header" className="app-header">
      <div className="desktop-toggler">
        <button
          type="button"
          className="menu-toggler"
          onClick={toggleAppSidebarDesktop}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
      </div>

      <div className="mobile-toggler">
        <button
          type="button"
          className="menu-toggler"
          onClick={toggleAppSidebarMobile}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
      </div>

      <div className="brand">
        <Link to="/" className="brand-logo">
          <Logo themeClass={themeClass} />
        </Link>
      </div>
      <div className="brand  mt-1 text-theme ">
        <h5 className="text-theme">
          {CurrentSystem === "NTX"
            ? "النظام العام"
            : CurrentSystem === "HR"
            ? "نظام الموظفين"
            : CurrentSystem === "AME"
            ? "نظام الموافقات"
            : CurrentSystem === "SU"
            ? "نظام الخدمات المساندة "
            : CurrentSystem === "FLEET"
            ? "نظام إدارة الأسطول"
            : ""}
        </h5>
      </div>

      <div className="menu">
        <div className="menu-item dropdown">
          <Link
            to="/Neatix/Applications"
            onClick={AppLicationNavigate}
            className="menu-link"
          >
            <div className="menu-icon">
              <i className="bi bi-grid-3x3-gap nav-icon"></i>
            </div>
          </Link>
        </div>

        {/* //_________ */}

        {/* <div className="menu-item dropdown dropdown-mobile-full">
          <a
            href="#/"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            className="menu-link"
          >
            <div className="menu-icon">
              <i className="bi bi-grid-3x3-gap nav-icon"></i>
            </div>
          </a>
          <div className="dropdown-menu fade dropdown-menu-end w-300px text-center p-0 mt-1 rounded-4" style={{direction: "ltr"}}>
            <div className="row row-grid gx-0 justify-content-center">
              {Module.map((item , idx) => (
                <div className="col-6" key={idx}>
                  <Link
                    className="dropdown-item text-decoration-none p-3 bg-none"
                    onClick={(e) =>   changeSystem(item)}
                  >
                    <div>
                      <i className="bi bi-terminal h2 opacity-5 d-block my-1"></i>
                    </div>
                    <div className="fw-500 fs-10px text-inverse">
                      { i18n.language === 'ar' ? item.name2 : item.name}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* //_________ */}

        <div className="menu-item dropdown dropdown-mobile-full">
          <a
            href="#/"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            className="menu-link"
          >
            <div className="menu-icon">
              <i className="bi bi-bell nav-icon"></i>
            </div>
            <div className="menu-badge bg-theme"></div>
          </a>
          <div className="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1">
            <h6 className="dropdown-header fs-10px mb-1">NOTIFICATIONS</h6>
            <div className="dropdown-divider mt-1"></div>
            {notificationData?.length > 0 ? (
              notificationData.map((notification, index) => (
                <a
                  href="#/"
                  key={index}
                  className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold"
                >
                  <div className="fs-20px">
                    <i className={notification.icon}></i>
                  </div>
                  <div className="flex-1 flex-wrap ps-3">
                    <div className="mb-1 text-inverse">
                      {notification.title}
                    </div>
                    <div className="small text-inverse text-opacity-50">
                      {notification.time}
                    </div>
                  </div>
                  <div className="ps-2 fs-16px">
                    <i className="bi bi-chevron-right"></i>
                  </div>
                </a>
              ))
            ) : (
              <div className="dropdown-notification-item p-3 text-center">
                No record found
              </div>
            )}
            <hr className="mb-0 mt-2" />
            <div className="py-10px mb-n2 text-center">
              <a href="#/" className="text-decoration-none fw-bold">
                SEE ALL
              </a>
            </div>
          </div>
        </div>

        <div className="menu-item dropdown dropdown-mobile-full">
          <a
            href="#/"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            className="menu-link"
          >
            <div className="menu-img online">
              <div className="d-flex align-items-center justify-content-center w-100 h-100 bg-inverse bg-opacity-25 text-inverse text-opacity-50 rounded-circle overflow-hidden">
                <img
                  src="../../../assets/img/user/manEmp.png"
                  alt=""
                  width={100}
                  className="rounded-circle"
                />
              </div>
            </div>
            <div className="menu-text d-sm-block d-none w-170px">
              شـركة هرفي
            </div>
          </a>
          <div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
            <Link to="#" className="dropdown-item d-flex align-items-center">
              PROFILE
              <i className="bi bi-person-circle ms-auto text-theme fs-16px my-n1"></i>
            </Link>

            <Link to="#" className="dropdown-item d-flex align-items-center">
              SETTINGS{" "}
              <i className="bi bi-gear ms-auto text-theme fs-16px my-n1"></i>
            </Link>
            <div className="dropdown-divider"></div>
            <Link
              to="login"
              onClick={() => handleLogout()}
              className="dropdown-item d-flex align-items-center"
            >
              LOGOUT{" "}
              <i className="bi bi-toggle-off ms-auto text-theme fs-16px my-n1"></i>
            </Link>
          </div>
        </div>

        <div className="menu-item dropdown dropdown-mobile-full">
          <a
            href="#"
            title={status ? "متصل" : "عير متصل"}
            className="menu-link"
          >
            <div className="menu-icon">
              <i
                className="fas fa-lg fa-fw me-2 fa-lightbulb"
                style={{ color: status ? "#e8fd32" : "#3c4f59" }}
              ></i>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
{
  /* {
                Module.map(item => (
              <div className="col-4" key={item.id}>
								<Link
                //  to="/dashboard"
                 className="dropdown-item text-decoration-none p-3 bg-none"
                 onClick={()=> changeSystem(item.Code)}
                 >
									<div><i className="bi bi-terminal h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-10px text-inverse">{i18n.language === 'ar' ? item.name2 : item.name}</div>
								</Link>
							</div>
                ))
              } */
}
