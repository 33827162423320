import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import useLookupTitle from "../../../../../hooks/Lookups/use-Lookup-Title";
import useLookupSex from "../../../../../hooks/Lookups/use-Lookup-Sex";
import useLookupMarStatus from "../../../../../hooks/Lookups/use-Lookup-MarStatus";
import useLookupNationality from "../../../../../hooks/Lookups/use-Lookup-Nationality";
import PersonImage from "../../../../../components/PersonImage/PersonImage";
import { Card, CardBody } from "../../../../../components/card/card";
import { useNavigate } from "react-router-dom";
import useGetBanks from "../../../../../hooks/Lookups/use-get-Banks";
import useGetRotationPlan from "../../../../../hooks/HR/use-get-RotationPlan";
import useLookupReligions from "../../../../../hooks/Lookups/use-Lookup-Religions";

const EditEmployees = ({ formik }) => {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { TITLE } = useLookupTitle();
  const { SEX } = useLookupSex();
  const { MAR_STATUS } = useLookupMarStatus();
  const { dataNATIONALITY } = useLookupNationality();
  const { Banks } = useGetBanks();
  const { RotationPlanList } = useGetRotationPlan();
  const { RELIGIONS } = useLookupReligions();

  const handleImageUpload = (event) => {
    formik.setFieldValue("Person_Img", event.currentTarget.files[0]);
    formik.setFieldValue("Is_Update_IMG", true);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-9">
          <div className="row">
            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label">{t("title")}</label>
                <Form.Select
                  className="form-select"
                  name="TITLE"
                  onChange={formik.handleChange}
                  value={formik.values.TITLE || ""}
                  isInvalid={formik.touched.TITLE && formik.errors.TITLE}
                >
                  <option key={null}>
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  {TITLE.map((item, idx) =>
                    item.list.map((list) => (
                      <option key={++idx} value={list.lookupCode}>
                        {i18n.language === "ar" ? list.meaning2 : list.meaning}
                      </option>
                    ))
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.TITLE}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-5">
              <div className="form-group mb-3 has-validation">
                <label className="form-label">{t("Arabic Name")}</label>

                <Form.Control
                  type="text"
                  className="form-control"
                  name="NAME2"
                  onChange={formik.handleChange}
                  value={formik.values.NAME2 || ""}
                  isInvalid={formik.touched.NAME2 && formik.errors.NAME2}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.NAME2}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-5">
              <div className="form-group mb-3">
                <label className="form-label">{t("English Name")}</label>
                <Form.Control
                  style={{ direction: "ltr" }}
                  type="text"
                  className="form-control"
                  name="NAME"
                  onChange={formik.handleChange}
                  value={formik.values.NAME || ""}
                  isInvalid={formik.touched.NAME && formik.errors.NAME}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.NAME}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t("Employee number")}</label>
                <Form.Control
                  readOnly
                  className="form-control"
                  type="text"
                  name="employeeNumber"
                  onChange={formik.handleChange}
                  value={formik.values.employeeNumber || ""}
                  isInvalid={
                    formik.touched.employeeNumber &&
                    formik.errors.employeeNumber
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.employeeNumber}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t("Identity")}</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  placeholder="الهوية"
                  name="NATIONAL_IDENTIFIER"
                  onChange={formik.handleChange}
                  value={formik.values.NATIONAL_IDENTIFIER || ""}
                  isInvalid={
                    formik.touched.NATIONAL_IDENTIFIER &&
                    formik.errors.NATIONAL_IDENTIFIER
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.NATIONAL_IDENTIFIER}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label">{t("Marital Status")}</label>
                <Form.Select
                  className="form-select"
                  name="MARITAL_STATUS"
                  onChange={formik.handleChange}
                  value={formik.values.MARITAL_STATUS || ""}
                  isInvalid={
                    formik.touched.MARITAL_STATUS &&
                    formik.errors.MARITAL_STATUS
                  }
                >
                  {MAR_STATUS.map((item, idx) =>
                    item.list.map((list) => (
                      <option key={++idx} value={list.lookupCode}>
                        {i18n.language === "ar" ? list.meaning2 : list.meaning}
                      </option>
                    ))
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.MARITAL_STATUS}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-2">
              <div className="form-group mb-3">
                <label className="form-label">{t("Gender")}</label>
                <Form.Select
                  className="form-select"
                  name="GENDER"
                  onChange={formik.handleChange}
                  value={formik.values.GENDER || ""}
                  isInvalid={formik.touched.GENDER && formik.errors.GENDER}
                >
                  {SEX.map((item, idx) =>
                    item.list.map((list) => (
                      <option key={++idx} value={list.lookupCode}>
                        {i18n.language === "ar" ? list.meaning2 : list.meaning}
                      </option>
                    ))
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.GENDER}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-3">
              <div className="form-group mb-3">
                <label className="form-label">{t("Nationality")}</label>
                  <Select
                    classNamePrefix="react-select"
                    isRtl
                    isSearchable={true}
                    required
                    options={dataNATIONALITY}
                    onChange={(option) =>
                      formik.setFieldValue("NATIONALITY", option.value)
                    }
                    value={dataNATIONALITY?.find(
                      (f) => f?.value === formik?.values?.NATIONALITY
                    )}
                    placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                  />
                

                <Form.Control.Feedback type="invalid">
                  {formik.errors.NATIONALITY}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-3">
              <div className="form-group mb-3">
                <label className="form-label">{t("Religion")}</label>
                <Form.Select
                  className="form-select"
                  name="Religion"
                  onChange={formik.handleChange}
                  value={formik.values.Religion || ""}
                  isInvalid={formik.touched.Religion && formik.errors.Religion}
                >
                  <option key={null}>
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  {RELIGIONS.map((item, idx) =>
                    item.list.map((list) => (
                      <option key={++idx} value={list.lookupCode}>
                        {i18n.language === "ar" ? list.meaning2 : list.meaning}
                      </option>
                    ))
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Religion}
                </Form.Control.Feedback>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="form-group mb-3">
                <label className="form-label">{t("Hiring Date")}</label>
                <input
                  type="date"
                  id="START_DATE"
                  className="form-control"
                  name="START_DATE"
                  onChange={formik.handleChange}
                  value={formik.values.START_DATE || ""}
                ></input>
                <div>{formik.errors.START_DATE}</div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="form-group mb-3">
                <label className="form-label">{t("Date of Birth")}</label>
                <input
                  type="date"
                  id="birthdaytime"
                  className="form-control"
                  name="DATE_OF_BIRTH"
                  onChange={formik.handleChange}
                  value={formik.values.DATE_OF_BIRTH || ""}
                ></input>
                <div>{formik.errors.DATE_OF_BIRTH}</div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t("Birth Country")}</label>
 
                  <Select
                    classNamePrefix="react-select"
                    isRtl
                    isSearchable={true}
                    required
                    options={dataNATIONALITY}
                    onChange={(option) =>
                      formik.setFieldValue("COUNTRY_OF_BIRTH", option.value)
                    }
                    value={dataNATIONALITY?.find(
                        (f) => f.value === formik.values.COUNTRY_OF_BIRTH
                      )}
                    placeholder={i18n.language === "ar" ? "إختر" : "Choose"}
                  />
               

                <Form.Control.Feedback type="invalid">
                  {formik.errors.COUNTRY_OF_BIRTH}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t("City Of birth")}</label>
                <Form.Control
                  type="text"
                  className="form-control"
                  name="TOWN_OF_BIRTH"
                  onChange={formik.handleChange}
                  value={formik.values.TOWN_OF_BIRTH || ""}
                  isInvalid={
                    formik.touched.TOWN_OF_BIRTH && formik.errors.TOWN_OF_BIRTH
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.TOWN_OF_BIRTH}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t("Annual Leave")}</label>
                <Form.Select
                  className="form-select"
                  name="AnnualLeave"
                  onChange={formik.handleChange}
                  value={formik.values.AnnualLeave || ""}
                  isInvalid={
                    formik.touched.AnnualLeave && formik.errors.AnnualLeave
                  }
                >
                  <option value="0" key={null}>
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  <option value="30"> 30 </option>
                  <option value="21"> 21 </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.AnnualLeave}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t("Rotation plan")}</label>
                <Form.Select
                  className="form-select"
                  name="RotationPlanId"
                  onChange={formik.handleChange}
                  value={formik.values.RotationPlanId || ""}
                  isInvalid={
                    formik.touched.RotationPlanId &&
                    formik.errors.RotationPlanId
                  }
                >
                  <option value="">
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  {RotationPlanList &&
                    RotationPlanList.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.RotationPlanId}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="form-group mb-3">
                <label className="form-label">{t("Name of the bank")}</label>
                <Form.Select
                  className="form-select"
                  name="BankId"
                  onChange={formik.handleChange}
                  value={formik.values.BankId || ""}
                  isInvalid={formik.touched.BankId && formik.errors.BankId}
                >
                  <option value="">
                    {i18n.language === "ar" ? "إختر" : "Choose"}
                  </option>
                  {Banks &&
                    Banks.map((item, idx) => (
                      <option key={++idx} value={item.id}>
                        {i18n.language === "ar" ? item.name2 : item.name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.BankId}
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="col-xl-4">
              <label className="form-label">IBAN</label>
              <div className="input-group flex-nowrap">
                <Form.Control
                  type="text"
                  style={{ direction: "ltr" }}
                  className="form-control"
                  name="IBan"
                  onChange={formik.handleChange}
                  value={formik.values.IBan || ""}
                  isInvalid={formik.touched.IBan && formik.errors.IBan}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.IBan}
                </Form.Control.Feedback>
                <span className="input-group-text">SA</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3">
          <div className="row">
            <div className="col-xl-12">
              <div className="form-group mb-3">
                <label className="form-label">{t("Choose photo")} </label>
                <Form.Control
                  type="file"
                  className="form-control"
                  placeholder=" أختر صورة"
                  name="Person_Img"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e)}
                  // isInvalid={!!formik.errors.persoN_IMG}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Person_Img}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          {/* style={{ minHeight: "auto", height: "auto" }} */}

          <div className="col-xl-12">
            <Card>
              <CardBody>
                {formik.values.Person_Img && (
                  <PersonImage file={formik.values.Person_Img} />
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default EditEmployees;

// <div className="text-center">
//               {/* <ConfirmEditDialog
//                    onConfirm={formik.handleSubmit}
//                    title="هل أنت متــأكد ؟ "
//                    message="لا يمكن التراجع عن هذا الإجــراء."
//               /> */}

//               <button
//                 disabled={formik.isSubmitting}
//                 type="submit"
//                 className="btn me-1 btn-outline-theme mb-1"
//               >
//                 <i className="fa fa-save fa-fw me-1"></i>
//                 {formik.isSubmitting ? "..." : t("Save")}
//               </button>

//               <button
//                 type="button"
//                 className=" btn me-1 btn-outline-default mb-1"
//                 onClick={() => Navigate(-1, { replace: true })}
//               >
//                 <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
//               </button>
//             </div>

// <div className="text-center">
// {/* <ConfirmEditDialog
//      onConfirm={formik.handleSubmit}
//      title="هل أنت متــأكد ؟ "
//      message="لا يمكن التراجع عن هذا الإجــراء."
// /> */}

// <button
//   disabled={formik.isSubmitting}
//   type="submit"
//   className="btn me-1 btn-outline-theme mb-1"
// >
//   <i className="fa fa-save fa-fw me-1"></i>
//   {formik.isSubmitting ? "..." : t("Save")}
// </button>

// <button
//   type="button"
//   className=" btn me-1 btn-outline-default mb-1"
//   onClick={() => Navigate(-1, { replace: true })}
// >
//   <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
// </button>
// </div>
