import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { GetEquipmentsWorkRequestsById } from '../../../../store/FLEET/EquipmentsWorkRequests/equipmentsWorkRequestSlice';
import Loader from '../../../../components/Loader/Loader';


interface Props {
    isAme?:boolean 
    ReferenceId? : string
  }

const ViewWorkRequest: React.FC<Props> = ({isAme = false , ReferenceId}) => {


    const { t, i18n } = useTranslation();

    const dispatch = useAppDispatch();
  
    const { Id } = useParams();
  
    // var ID = isAme ? ReferenceId : Id
  
    useEffect(() => {
        if (ReferenceId) {
            dispatch(GetEquipmentsWorkRequestsById(ReferenceId));
        }
    }, []);

    const { EquipmentsWorkRequestById : data , loading} = useAppSelector(
      (state) => state.EquipmentsWorkRequest
    );



  return (
     <>
      <Loader loading={loading} />
      <div className="row">
        <div className="col-xl-2"></div>

        <div className="col-xl-8">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                 {t("TypeOfRequest")}
                </th>
                <td>
                  
                  {i18n.language === "ar"
                    ? data?.requestTypeName2
                    : data?.requestTypeName}
                </td>
              </tr>

              <tr>
                <th
                  colSpan={1}
                  scope="row"
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                 {t("RequestDate")}
                </th>
                <td colSpan={4}>{data?.requestDate}</td>
              </tr>

              <tr>
                <th
                  colSpan={1}
                  scope="row"
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                 {t("RequestDescription")}
                </th>
                <td colSpan={4}>{data?.requestTitle}</td>
              </tr>

              <tr>
                <th
                  colSpan={1}
                  scope="row"
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                 {t("Equipment")}
                </th>
                <td colSpan={4}>{ i18n.language ==='ar'  ? data?.fleetEquipmentsName2 : data?.fleetEquipmentsName}</td>
              </tr>

              <tr>
                <th
                  colSpan={1}
                  scope="row"
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                  {t("Notes")}
                </th>
                <td colSpan={4}>
                  {i18n.language === "ar"
                    ? data?.description
                    : data?.description}
                </td>
              </tr>

              {/* <tr>
                <th
                  colSpan={1}
                  scope="row"
                  className="table-active"
                  style={{ maxWidth: "50px", textAlign: "center" }}
                >
                  {t("Notes")}
                </th>
                <td colSpan={4}>{data?.comments}</td>
              </tr> */}
            </tbody>
          </table>
        </div>

        <div className="col-xl-2"></div>
      </div>

      <div />
    </>
  )
}

export default ViewWorkRequest
