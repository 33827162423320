import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axios-custom";


// GetAmeConditionListBy DynamicFormId
export const GetAmeCondition = createAsyncThunk("Ame/GetAmeConditionList", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`AmeCondition/GetAmeConditionListByDynamicFormId?DynamicFormId=${id}`);
      const {data} = await ntxAPI.get(`AmeCondition/GetAmeConditionListByDynamicFormId?DynamicFormId=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const insertAmeCondition = createAsyncThunk("Ame/insertAmeCondition", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    
    try {
      // const data = await postData("AmeCondition/AddAmeCondition" , item );
      const {data} = await ntxAPI.post("AmeCondition/AddAmeCondition" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const DeleteAmeCondition = createAsyncThunk("Ame/DeleteAmeCondition", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData(`DynamicForm/DeleteAmeCondition?Id=${id}`);
      const {data} = await ntxAPI.post( `DynamicForm/DeleteAmeCondition?Id=${id}`)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });






  const initialState = { AmeConditionList: [], loading: false, error: null };

  const AmeSlice = createSlice({
    name: "Ame",
    initialState,
    reducers: {
    },
    extraReducers: {
      /////// get ame condition list                
      [GetAmeCondition.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetAmeCondition.fulfilled]: (state, action) => {
        state.loading = false;
        state.AmeConditionList = action.payload;
      },
      [GetAmeCondition.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

       //// AddAmeCondition
      [insertAmeCondition.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [insertAmeCondition.fulfilled]: (state, action) => {
        state.loading = false;
        state.AmeConditionList.push(action.payload);
      },
      [insertAmeCondition.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

      /// Delete 
      [DeleteAmeCondition.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [DeleteAmeCondition.fulfilled]: (state, action) => {
        state.loading = false;
        state.AmeConditionList = state.AmeConditionList?.filter((el) => el.id !== action.payload)
      },
      [DeleteAmeCondition.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
   
    },
  });
  
  export default AmeSlice.reducer;