import React from 'react';
import Swal from 'sweetalert2';

function ConfirmEditDialog({ btnText , onConfirm, title, message , IconButton }) {
  const handleConfirm = () => {
    Swal.fire({
      title: title,
      text: message,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
        // Swal.fire(
        //     {
        //         timer: 1000,
        //         icon: 'success',
        //         title: 'Edite!',
        //         text: 'تم حفظ التغييرات بنجاح.',
                   
        //           },
        //   )
      }
    })
  };

  return (
      <button onClick={handleConfirm} type="submit" className="btn me-1 btn-theme ">
          <i className={IconButton}></i>
          {btnText ? btnText :"Save"}
      </button>
  );
}

export default ConfirmEditDialog;