import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
// import { useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomAlert from "../../../../components/Alert/CustomAlert.tsx";
import { Card, CardBody } from "../../../../components/card/card";
import ConfirmDeleteDialog from "../../../../components/Alert/ConfirmDeleteDialog";
import Loader from "../../../../components/Loader/Loader";
import { GetFlexValueSetList } from "../../../../store/NTX/FlexValue/flexValueSetSlice";
import {
  DeleteFormColumn,
  GetFormColumn,
  insertFormColumn,
} from "../../../../store/NTX/FlexValue/Form/FormColumnSlice";
import { AddFormColumnSchema } from "../../../ValidationForm/validationSchema";
import Breadcrumb from "../../../../components/Shared/Breadcrumb";
import ModalComponent from "../../../../components/Modal/ModalComponent.jsx";
import EditFormColumn from "./EditFormColumn.js";

const AddFormColumn = () => {
  const Navigate = useNavigate();
  const [ID, setID] = useState(""); 
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  //
  const { FlexValueSetList, loading } = useSelector(
    (state) => state.FlexValueSet
  );
  // console.log(FlexValueSetList);
  useEffect(() => {
    dispatch(GetFlexValueSetList());
  }, [dispatch]);

  //

  //
  const { FormColumnList } = useSelector((state) => state.FormColumns);
  useEffect(() => {
    dispatch(GetFormColumn(id));
  }, [dispatch, id]);

  const formik = useFormik({
    initialValues: {
      lable: "",
      lable2: "",
      sequenceNum: "",
      // enabledFlag: true,
      requiredFlag: false,
      flexValueSetId: "",
      tableId: "",
      lookupType: "",
      defaultValue: "",
      //______________________ not for post
      validationType: "",
      formatType: "S",
      FlexValueSetId: "",
    },
    validationSchema: AddFormColumnSchema(t),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        insertFormColumn({
          dynamicFormId: id,
          // columnName: "",   // تجاهل
          lable: values.lable,
          lable2: values.lable2,
          sequenceNum: values.sequenceNum,
          enabledFlag: true,
          requiredFlag: values.requiredFlag,
          flexValueSetId: values.flexValueSetId,
          tableId: values.tableId,
          lookupType: values.lookupType,
          defaultValue: values.defaultValue.toString(),
        })
      )
        .unwrap()
        .then((res) => {
          formik.resetForm();
          dispatch(GetFormColumn(id));
          CustomAlert({ action: "Add" });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  function handleSelectChange(e) {
    const selectedOption = e.target.selectedOptions[0];

    const tableId = selectedOption.dataset.tableId;
    const validationType = selectedOption.dataset.validationType;
    const formatType = selectedOption.dataset.formatType;
    const FlexValueSetId = selectedOption.dataset.id;
    const lookupType = selectedOption.dataset.lookupType;

    formik.setFieldValue("flexValueSetId", e.target.value);
    formik.setFieldValue("tableId", tableId || null);
    formik.setFieldValue("lookupType", lookupType || null);
    formik.setFieldValue("validationType", validationType || null);
    formik.setFieldValue("formatType", formatType || null);

    formik.setFieldValue("defaultValue", "");
    formik.setFieldValue("FlexValueSetId", FlexValueSetId || null);
  }

  // Handele Delete Column
  const handleDeleteColumn = (item) => {
    dispatch(DeleteFormColumn(item.id))
      .unwrap()
      .then((res) => {
        // console.log(res);
        dispatch(GetFormColumn(id));
        CustomAlert({ action: "Delete" });
      })
      .catch((error) => {
        // console.log(error);
        CustomAlert({ action: "Error" });
      });
  };

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: t("Forms"),
      link: "",
    },
    {
      name:
        i18n.language === "ar"
          ? FormColumnList.dynamicFormName2
          : FormColumnList.dynamicFormName,
      link: null,
    },
  ];

  console.log(FlexValueSetList);
  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName="Add fields Form" />

      <Card>
        <Loader loading={loading} />
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-5">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    {t("Arabic Name")}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="lable2"
                    onChange={formik.handleChange}
                    value={formik.values.lable2}
                    isInvalid={formik.touched.lable2 && formik.errors.lable2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.lable2}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-5">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    {t("English Name")}
                  </label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="lable"
                    onChange={formik.handleChange}
                    value={formik.values.lable}
                    isInvalid={formik.touched.lable && formik.errors.lable}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.lable}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-1">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    الترتيب
                  </label>
                  <Form.Control
                    className="form-control"
                    type="number"
                    name="sequenceNum"
                    onChange={formik.handleChange}
                    value={formik.values.sequenceNum}
                    isInvalid={
                      formik.touched.sequenceNum && formik.errors.sequenceNum
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.sequenceNum}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-1">
                <div className="form-group mb-3">
                  <label className="form-label">{t("Required")}</label>
                  <Form.Check
                    style={{ margin: "10px 20px" }}
                    type="checkbox"
                    id="custom-switch"
                    name="requiredFlag"
                    onChange={formik.handleChange}
                    value={formik.values.requiredFlag}
                  />
                </div>
              </div>
            </div>

            <div className="row"></div>

            <div className="row">
              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label
                    className="form-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    نوع الحقل
                  </label>
                  <Form.Select
                    className="form-select"
                    name="flexValueSetId"
                    onChange={handleSelectChange}
                    isInvalid={
                      formik.touched.flexValueSetId &&
                      formik.errors.flexValueSetId
                    }
                  >
                    <option key={null}>
                      {i18n.language === "ar" ? "إختر" : "Choose"}
                    </option>
                    {FlexValueSetList &&
                      FlexValueSetList.map((item, idx) => (
                        <option
                          key={++idx}
                          value={item.id}
                          data-table-id={item.tableId}
                          data-validation-type={item.validationType}
                          data-format-type={item.formatType}
                          data-lookup-type={item.lookupType}
                          data-id={item.id}
                        >
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.flexValueSetId}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="form-group mb-3">
                  <label className="form-label">القيمة الافتراضية</label>

                  <Form.Control
                    className="form-control"
                    disabled={formik.values.formatType !== 'S'}
                    type={
                      formik.values.validationType === "DATE"
                        ? "date"
                        : formik.values.validationType === "TIME"
                        ? "time"
                        : formik.values.validationType === "NUMBER"
                        ? "number"
                        : formik.values.validationType === "NOTE"
                        ? "text"
                        : "text"
                    }
                    name="defaultValue"
                    onChange={formik.handleChange}
                    value={formik.values.defaultValue}
                    isInvalid={!!formik.errors.defaultValue}
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
                // hidden={hiddenAddFormColumn}
              >
                <i className="fas fa-plus fa-fw me-1"></i>
                {formik.isSubmitting ? "..." : t("Add")}
              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t("Cancel")}
              </button>
            </div>
          </Form>

          <div className="mb-4" />

          <div className="row text-center">
            <div className="col-lg-10 mx-auto p-0 m-0 bg-inverse bg-opacity-10 rounded-4">
              <div className="table-responsive rounded-4 ">
              <table className="table ">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">{t("Field")}</th>
                    <th scope="col">{t("Type")}</th>
                    <th scope="col">{t("Order")}</th>
                    <th scope="col">{t("Enabled")}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>

                <tbody>
                  {FormColumnList &&
                    FormColumnList?.columnList?.map((item, idx) => (
                      <tr key={++idx}>
                        <td className="align-middle">
                          {i18n.language === "ar" ? item.lable2 : item.lable}
                        </td>
                        <td className="align-middle">
                          {(i18n.language === "ar"
                            ? item.validationType
                            : item.validationType) || item.formatType}
                        </td>
                                                <td className="align-middle">
                          {  item.sequenceNum}
                        </td>
                        <td className="align-middle">
                          {item.enabledFlag ? (
                            <span className="badge bg-primary">true</span>
                          ) : (
                            <span className="badge bg-danger">false</span>
                          )}
                        </td>



                        <td className="align-middle">
                          {/* <div className="btn-group"> */}
                            <ConfirmDeleteDialog
                              onConfirm={() => handleDeleteColumn(item)}
                              title="Are you sure?" // لا يتم حذف الحقل تقريبا ؟؟؟
                              message="This action cannot be undone."
                              iconType={2}
                            />

                            <button
                              // onClick={() =>
                              //   Navigate(
                              //     `/NTX/FlexValue/${item.id}/EditFormColumns`
                              //   )
                              // }
                              data-bs-toggle="modal"
                              data-bs-target="#EditFormColumn"
                              onClick={() => setID(item.id)}
                              type="button"
                              className="btn btn-lg btn-outline-lime ms-1"
                            >
                              <i className="far fa-lg fa-fw  fa-edit"></i>
                              {/* {t("Button.Edit")} */}
                            </button>
                          {/* </div> */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <ModalComponent
        title={t("Edit fields Form")}
        id="EditFormColumn"
      >
        {
          ID && <EditFormColumn ID={ID} />
        }
      </ModalComponent>
    </>
  );
};

export default AddFormColumn;
