import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Card, CardBody } from "../../../../../components/card/card";
import useLocationDetails from "../../../../../hooks/Lookups/use-location-details";

import { useNavigate } from "react-router-dom";
import EditFormLocation from "./EditFormLocation";
// import Loader from "../../../../../components/Loader/Loader.js";

const EditLocation = (props) => {
  //  get Location by id
  const { Location, loading , error } = useLocationDetails();
 

  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
 

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t("Home")}</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/" onClick={() => Navigate(-1, { replace: true })}>
                {t("Locations")}
              </a>
            </li>
            <li className="breadcrumb-item active">{t("Button.Edit")}</li>
          </ul>
          <h1 className="page-header mb-0">{t("Edit Location")}</h1>
        </div>
      </div>

      <Card>
        {/* <Loader loading={loading} /> */}
        
        <CardBody>
          {Object.keys(Location)?.length > 0 && (
             <EditFormLocation Location={Location} loading={loading} />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default EditLocation;
