import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { Card, CardBody } from '../../components/card/card.jsx';
import Loader from "../../../components/Loader/Loader.js"

const RotationList = ({RotationPlanList , loading}) => {

    const { t, i18n } = useTranslation();
    // const dispatch = useDispatch();
    const Navigate = useNavigate();


    
  return (
    <>
    <div className='card'>
          <Loader loading={loading}/>
    <table className="table">
      <thead className="table-dark">
        <tr>
          <th scope="col">{t('Name')}</th>
          <th scope="col">{t('Start Date')}</th>
          <th scope="col">{t('Date_from')}</th>
          <th scope="col"></th>
        </tr>
      </thead>

      <tbody>
        {RotationPlanList.map((item, idx) => (
          <tr key={item.id}>
            <td className="align-middle">
              {i18n.language === "ar" ? item.name2 : item.name}
            </td>
            <td className="align-middle">{item.startDate}</td>
            <td className="align-middle">{item.endDate}</td>
            <td className="align-middle">

              <button  type="button"
              //  hidden={hiddenEditRotation}  
               onClick={() => Navigate(`${item.id}/RotationSetting`)} className="btn btn-outline-lime mb-1">
              <i className="fab fa-lg fa-fw me-2 fa-whmcs"></i>
              {t('Plan settings')}
              </button>

            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>


</>
  )
}

export default RotationList