import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../API/axios-custom";

export const AddUpdateExtraInformation = createAsyncThunk(
  "ExtraInformation/AddUpdateExtraInformation",
  async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.post("ExtraInformation/AddUpdateExtraInformation" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetExtraInformationTabs = createAsyncThunk(
  "ExtraInformation/GetExtraInformationTabs",
  async ({ DynamicFormId, ReferenceId }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const {data} = await ntxAPI.get(`ExtraInformation/GetExtraInformationTabs?DynamicFormId=${DynamicFormId}&ReferenceId=${ReferenceId}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const GetExtraInformationWithValueById = createAsyncThunk(
  "ExtraInformation/GetExtraInformationWithValueById",
  async (ExtraInformationId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const {data} = await ntxAPI.get(`ExtraInformation/GetExtraInformationWithValueById?ExtraInformationId=${ExtraInformationId}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);



export const GetFormRecordWithValue = createAsyncThunk(
  "ExtraInformation/GetFormRecordWithValue",
  async ({ TableId, ReferenceId }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const {data} = await ntxAPI.get(`ExtraInformation/GetFormRecordWithValue?ReferenceId=${ReferenceId}&TableId=${TableId}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);




const initialState = {
  ExtraInformationList: [],
  ExtraInformationTabList: [], //  FormRecordWithValue هذه ماعد استخدمتها بدلت بدالة اللي تحته
  FormRecordWithValueList: [],
  ExtraInformationWithValueById: [],
  loading: false,
  loadingRecordWithValueList: false,
  error: null,
};

const ExtraInformationSlice = createSlice({
  name: "ExtraInformation",
  initialState,
  reducers: {},
  extraReducers: {


    //   ///////ExtraInformationTabList
    [GetExtraInformationTabs.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.ExtraInformationTabList = [];
    },
    [GetExtraInformationTabs.fulfilled]: (state, action) => {
      state.loading = false;
      state.ExtraInformationTabList = action.payload;
    },
    [GetExtraInformationTabs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


    

    //   ///////Get Form Record With Value
    [GetFormRecordWithValue.pending]: (state) => {
      state.loadingRecordWithValueList = true;
      state.error = null;
      // state.ExtraInformationTabList = [];
    },
    [GetFormRecordWithValue.fulfilled]: (state, action) => {
      state.loadingRecordWithValueList = false;
      state.FormRecordWithValueList = action.payload;
    },
    [GetFormRecordWithValue.rejected]: (state, action) => {
      state.loadingRecordWithValueList = false;
      state.error = action.payload;
    },







    //   ///////GetExtraInformationWithValueById
    [GetExtraInformationWithValueById.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.ExtraInformationWithValueById = [];
    },
    [GetExtraInformationWithValueById.fulfilled]: (state, action) => {
      state.loading = false;
      state.ExtraInformationWithValueById = action.payload;
    },
    [GetExtraInformationWithValueById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },









    //// Add
    [AddUpdateExtraInformation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [AddUpdateExtraInformation.fulfilled]: (state, action) => {
      state.loading = false;
      state.ExtraInformationList.push(action.payload);
    },
    [AddUpdateExtraInformation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default ExtraInformationSlice.reducer;
