import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ntxAPI } from "../../../../API/axios-custom";
// import axios from "axios";
// import variables from "../../../APIURL/variables";
// import { getData, postData } from "../../../Axios/axiosData";


const initialState = { FormColumnList: [], loading: false, error: null , FormColumnByIdList: [] , FormColumnByTableIdList: [] } ;


export const GetFormColumn = createAsyncThunk("FormColumn/GetFormColumn", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`DynamicForm/GetDynamicFormColumnList?DynamicFormId=${id}`);
      const {data} = await ntxAPI.get(`DynamicForm/GetDynamicFormColumnList?DynamicFormId=${id}` );
      // console.log(data.data);
      return data.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });



  export const GetFormColumnById = createAsyncThunk("FormColumn/GetFormColumnById", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await getData(`DynamicForm/GetDynamicFormColumnById?Id=${id}`);
      const {data} = await ntxAPI.get(`DynamicForm/GetDynamicFormColumnById?Id=${id}` );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });


  export const insertFormColumn = createAsyncThunk("FormColumn/insertFormColumn", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const {data} = await ntxAPI.post("DynamicForm/AddDynamicFormColumn" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const DeleteFormColumn = createAsyncThunk("FormColumn/DeleteFormColumn", async (id, thunkAPI) => {
    const { rejectWithValue  } = thunkAPI;

    try {
      const {data} = await ntxAPI.post(`DynamicForm/DeleteDynamicFormColumn?DynamicFormColumnId=${id}` )
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

  export const UpdateFormColumn = createAsyncThunk("FormColumn/UpdateFormColumn", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const {data} = await ntxAPI.post("DynamicForm/UpdateDynamicFormColumn" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });

   export const GetDynamicFormColumnListByTableId = createAsyncThunk("FormColumn/GetDynamicFormColumnListByTableId", async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
 
    try {
      // const data = await getData(`DynamicForm/GetDynamicFormColumnListByTableId?TableId=${id}`);
      const {data} = await ntxAPI.get( `DynamicForm/GetDynamicFormColumnListByTableId?TableId=${id}`);
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  });





  const FormColumnSlice = createSlice({
    name: "FormColumn",
    initialState,
    reducers: {
    
    },
    extraReducers: {
      /////// get all Dynamic Culomn Forms 
      [GetFormColumn.pending]: (state) => {
        state.loading = true;
        state.error = null;
        state.FormColumnList = []
      },
      [GetFormColumn.fulfilled]: (state, action) => {
        state.loading = false;
        state.FormColumnList = action.payload;
      },
      [GetFormColumn.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

      /////// get Dynamic Culomn Forms By Id
      [GetFormColumnById.pending]: (state) => {
        state.loading = true;
        state.error = null;
        state.FormColumnByIdList = []
      },
      [GetFormColumnById.fulfilled]: (state, action) => {
        state.loading = false;
        state.FormColumnByIdList = action.payload;
      },
      [GetFormColumnById.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
    
      ///////insert
      [insertFormColumn.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [insertFormColumn.fulfilled]: (state, action) => {
        state.loading = false;
        state.FormColumnList.columnList.push(action.payload);
      },
      [insertFormColumn.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

       /// Delete 
       [DeleteFormColumn.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [DeleteFormColumn.fulfilled]: (state, action) => {
        state.loading = false;
        // state.FormColumnList = state.FormColumnList.filter((el) => el.id !== action.payload)
      },
      [DeleteFormColumn.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

       /////// Update Dynamic Forms by id
       [UpdateFormColumn.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [UpdateFormColumn.fulfilled]: (state, action) => {
        state.loading = false;
        // state.DynamicFormListByID = action.payload;
      },
      [UpdateFormColumn.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },


            /////// get Dynamic Culomn Forms By Table Id
            [GetDynamicFormColumnListByTableId.pending]: (state) => {
              state.loading = true;
              state.error = null;
              state.FormColumnByTableIdList = []
            },
            [GetDynamicFormColumnListByTableId.fulfilled]: (state, action) => {
              state.loading = false;
              state.FormColumnByTableIdList = action.payload.data;
            },
            [GetDynamicFormColumnListByTableId.rejected]: (state, action) => {
              state.loading = false;
              state.error = action.payload;
            },



    
    },
  });

  export default FormColumnSlice.reducer;