import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ntxAPI } from "../../API/axios-custom";




  export const AddRequest = createAsyncThunk("AmeRequest/AddRequest", async (item, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      // const data = await postData("AmeRequest/AddRequest" , item );
      const {data} = await ntxAPI.post( "AmeRequest/AddRequest" , item)
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  });



    export const GetRequestList = createAsyncThunk("AmeRequest/GetRequestList", async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // const data = await getData("AmeRequest/GetRequestList" );
      const {data} = await ntxAPI.get("AmeRequest/GetRequestList" );
      return data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  });


export const GetTransactionHistoryWithValueById = createAsyncThunk("AmeRequest/GetTransactionHistoryWithValueById", async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
   const {data} = await ntxAPI.get( `AmeRequest/GetTransactionHistoryWithValueById?TransactionHistoryId=${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error);
  }
});

export const ActionRequest = createAsyncThunk("AmeRequest/ActionRequest", async (item, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  // console.log(JSON.stringify(item));
  // debugger
  try {
    const {data} = await ntxAPI.post("AmeRequest/ActionRequest" , item)
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error);
  }
});



export const GetMyRequest = createAsyncThunk("AmeRequest/GetMyRequest", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    // const data = await getData("AmeRequest/GetMyRequests" );
    const {data} = await ntxAPI.get( "AmeRequest/GetMyRequests");
    return data;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error);
  }
});


  const initialState = { RequestList: [] , MyRequestList: [],  TransactionHistoryWithValueList: [] ,  loading: false, error: null };

  const AmeRequestSlice = createSlice({
    name: "AmeRequest",
    initialState,
    reducers: {
      cleanTransactionHistoryList: (state)=>{
        state.TransactionHistoryWithValueList = [];
      }
    },
    extraReducers: {
      ///// get Request list                
      [GetRequestList.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetRequestList.fulfilled]: (state, action) => {
        state.loading = false;
        state.RequestList = action.payload;
      },
      [GetRequestList.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

      ///// get MyRequest list                
      [GetMyRequest.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [GetMyRequest.fulfilled]: (state, action) => {
        state.loading = false;
        state.MyRequestList = action.payload;
      },
      [GetMyRequest.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

            ///// get Transaction History With Value List                 
            [GetTransactionHistoryWithValueById.pending]: (state) => {
              state.loading = true;
              state.error = null;
              state.TransactionHistoryWithValueList = []
            },
            [GetTransactionHistoryWithValueById.fulfilled]: (state, action) => {
              state.loading = false;
              state.TransactionHistoryWithValueList = action.payload;
            },
            [GetTransactionHistoryWithValueById.rejected]: (state, action) => {
              state.loading = false;
              state.error = action.payload;
            },

       ////
      [AddRequest.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [AddRequest.fulfilled]: (state, action) => {
        state.loading = false;
        state.RequestList.push(action.payload);
      },
      [AddRequest.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },

 
    },
  });

  export const {cleanTransactionHistoryList} = AmeRequestSlice.actions
  
  export default AmeRequestSlice.reducer;