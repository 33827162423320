import { useFormik } from "formik";
import React, { useEffect } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomAlert from '../../../../components/Alert/CustomAlert.tsx';
import { Card, CardBody } from "../../../../components/card/card";
import {
  cleanResponsibilityById,
  GetResponsibilityById,
  UpdateResponsibility,
} from "../../../../store/NTX/Security/responsibilitySlice";
import { DataIcons } from "../../../../assets/icons";

function EditResponsibility() {

  const Navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(cleanResponsibilityById())
    dispatch(GetResponsibilityById(id));
  }, [dispatch, id]);
  const { ResponsibilityById } = useSelector((state) => state.Responsibility);
  console.log(ResponsibilityById);

  ///
  const formik = useFormik({
    initialValues: {
      name: ResponsibilityById ? ResponsibilityById.name : "",
      name2: ResponsibilityById ? ResponsibilityById.name2 : "",
      description: ResponsibilityById ? ResponsibilityById.description : "",
      //   moduleId: "",
      icon: ResponsibilityById ? ResponsibilityById.icon : "",
    },
    enableReinitialize: true,
    // validationSchema: formPosition(t),

    onSubmit: (values) => {
      console.log(values);

      dispatch(
        UpdateResponsibility({
          id: id,
          name: values.name,
          name2: values.name2,
          description: values.description,
          //   moduleId: localStorage.getItem('moduleId'),
          icon: values.icon,

          startDate: "2023-03-17T20:18:33.538Z",
          endDate: "2023-03-17T20:18:33.538Z",
        })
      )
        .unwrap()
        .then(() => {
          Navigate(-1, { replace: true });
          CustomAlert({ action: "Edit" });
        })
        .catch((error) => {
          formik.setSubmitting(false);
          CustomAlert({ action: "Error" });
        });
    },
  });

  // var x = DataIcons.find((option) => option.value === ResponsibilityById.icon)?.label.props.children[0].props.className ;

  // var y = DataIcons.find((option) => option.value === ResponsibilityById.icon)?.label.props.children[1] ;

  // console.log(x);
  // console.log(y);

  // useEffect(() => {
  //   // set initial form values once data is available
  //   setInitialFormValues({
  //     value: ResponsibilityById.icon,
  //     label: `<><i className='${
  //       DataIcons.find((option) => option.value === ResponsibilityById.icon)
  //         ?.label.props.children[0].props.className
  //     }'></i> ${
  //       DataIcons.find((option) => option.value === ResponsibilityById.icon)
  //         ?.label.props.children[1]
  //     }</>`,
  //   });
  // }, [ResponsibilityById]);


  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">{t('Home')}</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#/" onClick={()=> Navigate(-1 , {replace:true})}>{t('Responsibilities')}</a>
            </li>
            <li className="breadcrumb-item active">{t('Button.Edit')}</li>
          </ul>
          <h1 className="page-header mb-0">{t('Edit Responsibility')}</h1>
        </div>
      </div>

      
      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Arabic Name')}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name2"
                    onChange={formik.handleChange}
                    value={formik.values.name2 || ""}
                    isInvalid={formik.touched.name2 && formik.errors.name2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name2}
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">{t('English Name')}</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name || ""}
                    isInvalid={formik.touched.name && formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="form-group mb-3">
                  <label className="form-label">Icons</label>

                  {/* {DataIcons.find((option) => option.value === ResponsibilityById.icon)?.label.props.children[1] && */}
                  <Select
                    classNamePrefix="react-select"
                    isRtl
                    // classNamePrefix="select"
                    isSearchable={true}
                    required
                    options={DataIcons}
                    onChange={(option) =>
                      formik.setFieldValue("icon", option.value)
                    }
                    placeholder={i18n.language === "ar" ? "إختر" : "Choose"}

                    value={{
                      value: ResponsibilityById && ResponsibilityById.icon,
                      label: <> <i className={`${DataIcons?.find((option)=>option.value === ResponsibilityById.icon)?.label.props.children[0].props.className}`} >
                      </i> {DataIcons?.find((option) => option.value === ResponsibilityById.icon)
                              ?.label.props.children[1]}  </>,
                    }}
                  />
                  {/* } */}

                </div>
              </div>

               <div className="col-xl-12">
                <div className="form-group mb-3">
                  <label className="form-label">{t('Note')}</label>
                  <Form.Control
                    className="form-control"
                    as="textarea"
                    rows={3}
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description || ""}
                  />

                  <div></div>
                </div>
              </div>

            </div>

            <div className="text-center">
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn me-1 btn-theme mb-1"
              >
                {formik.isSubmitting ? (
                  <div className="spinner-border spinner-border-sm me-2"></div>
                ) : (
                  <i className="fa fa-save fa-fw me-2"></i>
                )}
                {t("Save")}

              </button>
              <button
                type="button"
                className=" btn me-1 btn-default mb-1"
                onClick={() => Navigate(-1, { replace: true })}
              >
                <i className="fa fa-power-off fa-fw me-1"></i> {t('Cancel')}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

export default EditResponsibility;
