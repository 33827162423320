/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader } from "../../../../../components/card/card";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./card.css";
import Breadcrumb from "../../../../../components/Shared/Breadcrumb";
import { AppSettings } from "../../../../../config/app-settings";
import Loader from "../../../../../components/Loader/Loader";
// import './card2.css'

interface Props {
  ReportsList: any[];
  loading? : boolean
}

const Index: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  const context = useContext(AppSettings);

  //________________________________
  var BcrumbList = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: t("Reports"),
      link: null,
    },
  ];

  useEffect(() => {
    context.setAppContentFullHeight(true);
    return function cleanUp() {
      context.setAppContentFullHeight(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumb BreadcrumbList={BcrumbList} PageName={t("Reports")} />
      <div className="row" style={{ height: "90%" }}>
        <Card>
          {/* <CardBody> */}
          <Loader loading={props.loading}/>
          <PerfectScrollbar className="h-100">
            <section className="card__container">
              {props.ReportsList &&
                props.ReportsList?.map((item, index) => (
                  <div
                    className="card__bx card__1"
                    key={item.id}
                    onClick={() => Navigate(`${item.id}/ReportsBuilder`)}
                  >
                    <div className="card__data">
                      <div className="card__icon">
                        <div className="card__icon-bx">
                          <i
                            className={
                              item.icon
                                ? item.icon.replace("fa-lg fa-fw me-2", "")
                                : "bi bi-file-bar-graph"
                            }
                          ></i>
                        </div>
                      </div>
                      <div className="card__content">
                        {/* <Link to="" onClick={() => Navigate(`${item.id}/ReportsBuilder`)}>عرض التقرير</Link> */}
                      </div>
                      <div className="card__content2">
                        <h5>
                          {i18n.language === "ar" ? item.name2 : item.name}
                        </h5>
                      </div>
                    </div>
                  </div>
                ))}
            </section>
          </PerfectScrollbar>
          {/* </CardBody> */}
        </Card>
      </div>
    </>
  );
};

export default Index;
