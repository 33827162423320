import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
 
import { GetCurrency } from "../../store/lookupSlice";

const useLookupCurrency = ()=>{

    const dispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(GetCurrency())
      },[dispatch])

    const { Currency } = useSelector((state) => state.Lookup); 

    return { Currency } ;
};

export default useLookupCurrency ;